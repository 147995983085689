import Vue from "vue";
import { Component } from "vue-property-decorator";
import { stringMaxLength } from "../../../../assets/validation/common";
import CostTypeRestModel from "../../../../models/rest/register/costTypeRestModel";

@Component
export default class CostTypeDialog extends Vue {
    validation = {
        code: [stringMaxLength(this.$i18n, this.$t("common.code"), 3), (value: string) => !!value || this.$t("common.validationErrorRequired")],
        name: [stringMaxLength(this.$i18n, this.$t("common.name"), 128), (value: string) => !!value || this.$t("common.validationErrorRequired")],
    };

    get showDialog(): boolean {
        return this.$store.direct.state.register.showCostTypeDialog;
    }

    get model(): CostTypeRestModel {
        return this.$store.direct.state.register.costType;
    }

    async saveClick() {
        const valid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
        if (valid) {
            await this.$store.direct.dispatch.register.updateCostType();
        }
    }

    async backClick() {
        await this.$store.direct.dispatch.register.closeCostTypeDialog();
    }
}
