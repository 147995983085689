import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Roles } from "../../../models/emums/roles";

@Component
export default class SaveSupervisorDialog extends Vue {
    get showDialog(): boolean {
        return this.$store.direct.state.supervisor.showSaveSupervisorDialog;
    }

    get titleText(): string {
        return this.$store.direct.state.supervisor.titleSaveSupervisorDialog;
    }

    get messageText(): string {
        return this.$store.direct.state.supervisor.messageSaveSupervisorDialog;
    }

    get currentUserRoles(): string[] {
        return this.$store.direct.state.auth.userRoles;
    }

    created() {
        window.addEventListener('keydown', (e) => {
            if (e.key == 'Enter') {
                this.okClick();
            };
        });
    }

    async okClick() {
        if (this.showDialog == true) {
            await this.$store.direct.dispatch.supervisor.isConfirmedSaveSupervisorDialog({
                isConfirmed: true
            });
        }
    }

    async backToListClick() {
        await this.$store.direct.dispatch.supervisor.isNotConfirmedSaveSupervisorDialog();
    }
}
