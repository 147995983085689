import Vue from "vue";
import Vuex from "vuex";

import admin from "@/store/modules/admin";
import auth from "@/store/modules/auth";
import common from "@/store/modules/common";
import confirm from "@/store/modules/confirm";
import contractor from "@/store/modules/contractor";
import dashboard from "@/store/modules/dashboard";
import forum from "@/store/modules/forum";
import freightExchange from "@/store/modules/freight-exchange";
import locationDialog from "@/store/modules/location-dialog";
import onlineTracking from "@/store/modules/online-tracking";
import operation from "@/store/modules/operation";
import register from "@/store/modules/register";
import settlementCommon from "@/store/modules/settlement-common";
import settlementCostDocument from "@/store/modules/settlement-cost-document";
import settlementCostDocumentPaymentObligation from "@/store/modules/settlement-cost-document-payment-obligation";
import settlementCostDocumentTransferOrder from "@/store/modules/settlement-cost-document-transfer-order";
import settlementCostDocumentTransferOrderPackage from "@/store/modules/settlement-cost-document-transfer-order-package";
import settlementIncomeDocument from "@/store/modules/settlement-income-document";
import settlementIncomeDocumentPaymentObligation from "@/store/modules/settlement-income-document-payment-obligation";
import settlementPayment from "@/store/modules/settlement-payment";
import supervisor from "@/store/modules/supervisor";
import supervisorAllOrders from "@/store/modules/supervisor-all-orders";
import supervisorEncory from "@/store/modules/supervisor-encory";
import supervisorForSettlement from "@/store/modules/supervisor-for-settlement";
import supervisorReport from "@/store/modules/supervisor-report";
import supervisorTransportDocument from "@/store/modules/supervisor-transport-document";
import tracking from "@/store/modules/tracking";
import transportOrder from "@/store/modules/transport-order";
import vehicle from "@/store/modules/vehicle";

import { createDirectStore } from "direct-vuex";

Vue.use(Vuex);

export const rootState = {
    appSettings: undefined,
    requests: [] as string[],
    loading: false,
    doNotSetIsLoadingForUrlList: ["/api/supervisor/updateSupervisorLockedForEditingUntilValue"] as string[],
};

const { store, rootActionContext, moduleActionContext, rootGetterContext, moduleGetterContext } = createDirectStore({
    modules: {
        admin,
        auth,
        common,
        confirm,
        contractor,
        dashboard,
        forum,
        freightExchange,
        locationDialog,
        onlineTracking,
        operation,
        register,
        settlementCommon,
        settlementCostDocument,
        settlementCostDocumentPaymentObligation,
        settlementCostDocumentTransferOrder,
        settlementCostDocumentTransferOrderPackage,
        settlementIncomeDocument,
        settlementIncomeDocumentPaymentObligation,
        settlementPayment,
        supervisor,
        supervisorAllOrders,
        supervisorEncory,
        supervisorForSettlement,
        supervisorReport,
        supervisorTransportDocument,
        tracking,
        transportOrder,
        vehicle,
    },
    state: rootState,
    mutations: {
        addRequest(state, url: string | undefined) {
            state.requests.push(String(url));
            if (url != undefined) {
                const doesContain = state.doNotSetIsLoadingForUrlList.some(element => url.includes(element));
                if (!doesContain) {
                    state.loading = true;
                }
            }
        },
        removeRequest(state, url: string | undefined) {
            state.requests.splice(state.requests.indexOf(String(url)), 1);
            state.loading = state.requests.length > 0;
        }
    },
    actions: {
        request(context, url: string | undefined) {
            const { commit } = rootActionContext(context);
            commit.addRequest(url);
        },
        response(context, url: string | undefined) {
            const { commit } = rootActionContext(context);
            commit.removeRequest(url);
        }
    }
});

export { store as vuexStore };
// The following exports will be used to enable types in the
// implementation of actions and getters.
export { rootActionContext, moduleActionContext, rootGetterContext, moduleGetterContext };
export default store;


// The following lines enable types in the injected store '$store'.
export type AppStore = typeof store;
declare module "vuex" {
    //eslint-disable-next-line
    interface Store<S> {
        direct: AppStore;
    }
}
