import Vue from "vue";
import { Component } from "vue-property-decorator";
import ContractorOperationRestModel from "@/models/rest/contractor/contractorOperationRestModel";
import ListItemNumberModel from "../../../models/listItemNumberModel";
import { Roles } from "../../../models/emums/roles";

@Component

export default class OperationTab extends Vue {
    operationListHeaders = [
        { text: this.$t("contractor.priority"), align: "start", value: "priority" },
        { text: this.$t("contractor.ready"), align: "start", value: "isReady" },
        { text: this.$t("contractor.assignedToUserName"), align: "start", value: "assignedToUserName" },
        { text: this.$t("contractor.description"), align: "start", value: "description" },
        { text: this.$t("contractor.nextContact"), align: "start", value: "reminderDate" },
        { text: this.$t("common.createdAt"), align: "start", value: "createdAt" },
        { text: this.$t("common.createdBy"), align: "start", value: "createdBy" },
        { text: this.$t("contractor.actions"), align: "right", value: "actions", width: "1%" }
    ];

    get selectedTags(): number[] {
        return this.$store.direct.state.contractor.operationSearchOperationBySelectedTags;
    }

    set selectedTags(val: number[]) {
        this.$store.direct.commit.contractor.setOperationSearchOperationBySelectedTags(val);
    }

    get isContractorNew(): boolean {
        return this.$store.direct.state.contractor.isContractorNew;
    }

    get allowContractorEdit(): boolean {
        return this.$store.direct.state.contractor.allowContractorEdit;
    }

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get operationList(): ContractorOperationRestModel[] {
        return this.$store.direct.state.contractor.operationList;
    }

    set operationList(val: ContractorOperationRestModel[]) {
        this.$store.direct.commit.contractor.setOperationList(val);
    }

    get tags(): ListItemNumberModel[] {
        const tags = [] as ListItemNumberModel[];
        this.operationList.forEach((x: ContractorOperationRestModel) => {
            (x.tags.forEach((y: ListItemNumberModel) => {
                tags.push(y);
            }));
        });
        return tags;
    }

    get currentUserRoles() {
        return this.$store.direct.state.auth.userRoles;
    }

    isUserCrmOrCrmLowerOrCrmAdmin(): boolean {
        if (![Roles.CrmAdmin, Roles.Crm, Roles.CrmLower].some(x => this.currentUserRoles.includes(x))) {
            return false;
        }
        return true;
    }

    get allowContractorOperationAddOrEdit() {
        return this.allowContractorEdit || this.isUserCrmOrCrmLowerOrCrmAdmin;
    }

    async operationCreateClick() {
        if (this.isContractorNew == false) {
            await this.$store.direct.dispatch.contractor.loadOperation();
        }
        else {
            this.$store.direct.dispatch.confirm.openConfirmDialog(
                {
                    showConfirmDialog: true,
                    title: this.$t("contractor.savingContractorIsRequired").toString(),
                    message: this.$t("contractor.saveContractorIsRequiredForThisAction").toString(),
                    links: [],
                    options: { buttonColor: "error", layoutColor: "error" },
                    buttonType: "ok"
                });
        }
    }

    async operationEditClick(model: ContractorOperationRestModel) {
        await this.$store.direct.dispatch.contractor.loadOperation({ id: model.id });
    }

    searchOprationByTagClick() {
        this.$store.direct.dispatch.contractor.loadOperationList({ searchTagIdcsArray: this.selectedTags });
    }

    destroyed() {
        this.operationList = [] as ContractorOperationRestModel[];
    }
}
