import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";

@Component({
    props: {
        selectedItems: {
            default: []
        },
        items: {
            default: () => []
        },
        label: {
            default: "Date"
        },
        itemText: {
            default: "text"
        },
        itemValue: {
            default: "value"
        }
    }
})
export default class MultipleSelect extends Vue {
    selectedItems!: [];
    items!: [];
    itemText!:string ;
    itemValue!: string | number;

    localSelectedItems: any = [];
    selectAllItems = false;

    created() {
        this.localSelectedItems = [...this.selectedItems];
    }

    @Watch("localSelectedItems")
    localSelectedItemsHandler(val: any) {
        this.$emit("update:selectedItems", val);
    }

    toggleSelectAll(value: any) {
        if (value) {
            if (this.itemValue == "id") {
                this.localSelectedItems = this.items.map((item: any) => item.id);
            }
            else if (this.itemValue == "code") {
                this.localSelectedItems = this.items.map((item: any) => item.code);
            }
            else {
                this.localSelectedItems = this.items.map((item: any) => item.value);
            }
        } else {
            this.localSelectedItems = [];
        }
    }
}
