import Vue from "vue";
import { Component } from "vue-property-decorator";
import { stringMaxLength } from "../../assets/validation/common";
import ConfirmDialog from "../../layouts/confirmDialog/confirmDialog.vue";
import ListItemNumberModel from "../../models/listItemNumberModel";
import ListItemStringModel from "../../models/listItemStringModel";
import CourierNetFreightRestModel from "../../models/rest/freight-exchange/courier-net/courierNetFreightRestModel";
import CourierNetPackageRestModel from "../../models/rest/freight-exchange/courier-net/courierNetPackageRestModel";
import TransEuFreightRestModel from "../../models/rest/freight-exchange/transeu/transEuFreightRestModel";
import TransEuOperationFreightRestModel from "../../models/rest/freight-exchange/transeu/transEuOperationFreightRestModel";
import TransEuRequirementsFreightRestModel from "../../models/rest/freight-exchange/transeu/transEuRequirementsFreightRestModel";
import TransEuSpotFreightRestModel from "../../models/rest/freight-exchange/transeu/transEuSpotFreightRestModel";
import CurrencyRestModel from "../../models/rest/register/currencyRestModel";
import SupervisorRestModel from "../../models/rest/supervisor/supervisorRestModel";

@Component({
    components: {
        "confirm-dialog": ConfirmDialog,
    }
})

export default class FreightDialog extends Vue {
    dateAndTimeLabel = this.$t("freightExchange.dateAndTime");
    deliveryBeginDateLabel = this.$t("freightExchange.deliveryBeginDate");
    deliveryEndDateLabel = this.$t("freightExchange.deliveryEndDate");
    loadingBeginDateLabel = this.$t("freightExchange.loadingBeginDate");
    loadingEndDateLabel = this.$t("freightExchange.loadingEndDate");
    sendToTransEu = true;
    sendToCourierNet = false;

    validation = {
        country: [
            stringMaxLength(this.$i18n, this.$t("common.code"), 2),
            (value: string) => !this.sendToTransEu || !!value || this.$t("common.validationErrorRequired")
        ],
        postalCode: [
            stringMaxLength(this.$i18n, this.$t("common.code"), 10),
            (value: string) => !this.sendToTransEu || !!value || this.$t("common.validationErrorRequired")
        ],
        city: [
            (value: string) => !this.sendToTransEu || !!value || this.$t("common.validationErrorRequired")
        ],
        vehicle_size: [
            (value: string) => !this.sendToTransEu || !!value || this.$t("common.validationErrorRequired")
        ],
        required_truck_bodies: [
            (value: string[]) => !this.sendToTransEu || (value && value.length > 0) || this.$t("common.validationErrorRequired")
        ],
        capacity: [
            (value: number) => !this.sendToTransEu || !!value || this.$t("common.validationErrorRequired")
        ],
        is_ftl: [
            (value: boolean) => !this.sendToTransEu || (value === true || value === false) || this.$t("common.validationErrorRequired")
        ],
        vehicleTypeId: [
            (value: number) => !this.sendToCourierNet || !!value || this.$t("common.validationErrorRequired")
        ],
        weight: [
            (value: number) => !this.sendToCourierNet || !!value || this.$t("common.validationErrorRequired")
        ],
        packageType: [
            (value: string[]) => !this.sendToCourierNet || (value && value.length > 0) || this.$t("common.validationErrorRequired")
        ],
        courierNetComment: [
            stringMaxLength(this.$i18n, this.$t("freightExchange.comment"), 400),
        ],
    };

    get transEuVehicleBodyTypes(): ListItemStringModel[] {
        return this.$store.direct.state.freightExchange.transEuVehicleBodyTypes;
    }

    get transEuVehicleSizeTypes(): ListItemStringModel[] {
        return this.$store.direct.state.freightExchange.transEuVehicleSizeTypes;
    }

    get transEuRequiredWaysOfLoadingTypes(): ListItemStringModel[] {
        return this.$store.direct.state.freightExchange.transEuRequiredWaysOfLoadingTypes;
    }

    get transEuOtherRequirementTypes(): ListItemStringModel[] {
        return this.$store.direct.state.freightExchange.transEuOtherRequirementTypes;
    }

    get courierNetVehicleTypes(): ListItemNumberModel[] {
        return this.$store.direct.state.freightExchange.courierNetVehicleTypes;
    }

    get courierNetPackageTypes(): ListItemStringModel[] {
        return this.$store.direct.state.freightExchange.courierNetPackageTypes;
    }

    get courierNetDangerousGoods(): ListItemStringModel[] {
        return this.$store.direct.state.freightExchange.courierNetDangerousGoods;
    }

    get yesOrNoTypeList(): ListItemStringModel[] {
        return this.$store.direct.state.common.yesOrNoTypeList;
    }

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get showDialog(): boolean {
        return this.$store.direct.state.freightExchange.showSendFreightToFreightExchangesDialog;
    }

    get supervisor(): SupervisorRestModel {
        return this.$store.direct.state.supervisor.supervisor;
    }

    get currencies(): CurrencyRestModel[] {
        return this.$store.direct.state.contractor.contractorAssets.currencies;
    }

    get transEuFreight(): TransEuFreightRestModel {
        const transEuFreight = this.$store.direct.state.freightExchange.transEuFreight;
        if (transEuFreight.requirements == undefined) {
            transEuFreight.requirements = {} as TransEuRequirementsFreightRestModel;
        }
        if (transEuFreight.requirements.required_truck_bodies == undefined) {
            transEuFreight.requirements.required_truck_bodies = [] as string[];
        }

        return transEuFreight;
    }

    set transEuFreight(val: TransEuFreightRestModel) {
        this.$store.direct.commit.freightExchange.setTransEuFreight(val);
    }

    get transEuFreightLoadings(): TransEuSpotFreightRestModel[] {
        if (this.showDialog == true) {
            return this.transEuFreight.spots.filter(spot =>
                spot.operations.some((operation: TransEuOperationFreightRestModel) => operation.type === "loading"));
        }
        return [];
    }

    get transEuFreightUnloadings(): TransEuSpotFreightRestModel[] {
        if (this.showDialog == true) {
            return this.transEuFreight.spots.filter(spot =>
                spot.operations.some((operation: TransEuOperationFreightRestModel) => operation.type === "unloading"));
        }
        return [];
    }

    get courierNetFreight(): CourierNetFreightRestModel {
        return this.$store.direct.state.freightExchange.courierNetFreight;
    }

    set courierNetFreight(val: CourierNetFreightRestModel) {
        this.$store.direct.commit.freightExchange.setCourierNetFreight(val);
    }

    changeCommaToDot(val: any, valueName: string) {
        switch (valueName) {
            case "transEucapacity":
                {
                    this.transEuFreight.capacity = parseFloat(val.toString().replace(",", "."));
                    break;
                }
            case "transEuloading_meters":
                {
                    this.transEuFreight.loading_meters = parseFloat(val.toString().replace(",", "."));
                    break;
                }
            case "transEuPrice":
                {
                    this.transEuFreight.loading_meters = parseFloat(val.toString().replace(",", "."));
                    break;
                }
            case "courierNetweight":
                {
                    this.courierNetFreight.packages[0].weight = parseFloat(val.toString().replace(",", "."));
                    break;
                }
            case "courierNetPrice":
                {
                    this.courierNetFreight.price = parseFloat(val.toString().replace(",", "."));
                    break;
                }
        }
    }

    convertTransEuFreightTimespansToCorrectFormat() {
        this.transEuFreight.spots.forEach(x => {
            x.operations.forEach((x: TransEuOperationFreightRestModel) => {
                const offset = "+0000";
                x.timespans.endAsAsString = x.timespans.beginAsString;
                if (x.timespans.beginAsString != undefined) {
                    const date = new Date(x.timespans.beginAsString);
                    const formattedDate = `${date.toISOString().slice(0, 19)}${offset}`;
                    x.timespans.begin = formattedDate;
                }
                if (x.timespans.endAsAsString != undefined) {
                    const date = new Date(x.timespans.endAsAsString);
                    const formattedDate = `${date.toISOString().slice(0, 19)}${offset}`;
                    x.timespans.end = formattedDate;
                }
            })
        });
    }

    convertIsoCountryCodeToCourierNetCountryCode(countryCode: string): string {
        let result = "";
        switch (countryCode) {
            // ISO country codes:
            case "AT":
                result = "A";
                break;
            case "AL":
                result = "AL";
                break;
            case "AD":
                result = "AND";
                break;
            case "BE":
                result = "B";
                break;
            case "BG":
                result = "BG";
                break;
            case "BA":
                result = "BIH";
                break;
            case "BY":
                result = "BY";
                break;
            case "CH":
                result = "CH";
                break;
            case "CY":
                result = "CY";
                break;
            case "CZ":
                result = "CZ";
                break;
            case "DE":
                result = "D";
                break;
            case "DK":
                result = "DK";
                break;
            case "DZ":
                result = "DZ";
                break;
            case "ES":
                result = "E";
                break;
            case "EE":
                result = "EST";
                break;
            case "FR":
                result = "F";
                break;
            case "FI":
                result = "FIN";
                break;
            case "LI":
                result = "FL";
                break;
            case "FO":
                result = "FO";
                break;
            case "GB":
                result = "GB";
                break;
            case "IM":
                result = "GBM";
                break;
            case "GI":
                result = "GBZ";
                break;
            case "GR":
                result = "GR";
                break;
            case "HU":
                result = "H";
                break;
            case "HR":
                result = "HR";
                break;
            case "IT":
                result = "I";
                break;
            case "IE":
                result = "IRL";
                break;
            case "IS":
                result = "ISL";
                break;
            case "KZ":
                result = "KZ";
                break;
            case "LU":
                result = "L";
                break;
            case "LT":
                result = "LT";
                break;
            case "LV":
                result = "LV";
                break;
            case "MT":
                result = "M";
                break;
            case "MA":
                result = "MA";
                break;
            case "MC":
                result = "MC";
                break;
            case "MD":
                result = "MD";
                break;
            case "MK":
                result = "MK";
                break;
            case "ME":
                result = "MNE";
                break;
            case "NO":
                result = "N";
                break;
            case "NL":
                result = "NL";
                break;
            case "PT":
                result = "P";
                break;
            case "PL":
                result = "PL";
                break;
            case "RO":
                result = "RO";
                break;
            case "SM":
                result = "RSM";
                break;
            case "RU":
                result = "RUS";
                break;
            case "SE":
                result = "S";
                break;
            case "SK":
                result = "SK";
                break;
            case "SI":
                result = "SLO";
                break;
            case "RS":
                result = "SRB";
                break;
            case "TN":
                result = "TN";
                break;
            case "TR":
                result = "TR";
                break;
            case "UA":
                result = "UA";
                break;
            // Courier.net country codes:
            case "A":
                result = "A";
                break;
            case "AND":
                result = "AND";
                break;
            case "B":
                result = "B";
                break;
            case "BIH":
                result = "BIH";
                break;
            case "D":
                result = "D";
                break;
            case "E":
                result = "E";
                break;
            case "EST":
                result = "EST";
                break;
            case "F":
                result = "F";
                break;
            case "FIN":
                result = "FIN";
                break;
            case "FL":
                result = "FL";
                break;
            case "GBM":
                result = "GBM";
                break;
            case "GBZ":
                result = "GBZ";
                break;
            case "H":
                result = "H";
                break;
            case "I":
                result = "I";
                break;
            case "IRL":
                result = "IRL";
                break;
            case "ISL":
                result = "ISL";
                break;
            case "L":
                result = "L";
                break;
            case "M":
                result = "M";
                break;
            case "MNE":
                result = "MNE";
                break;
            case "N":
                result = "N";
                break;
            case "P":
                result = "P";
                break;
            case "RSM":
                result = "RSM";
                break;
            case "RUS":
                result = "RUS";
                break;
            case "S":
                result = "S";
                break;
            case "SLO":
                result = "SLO";
                break;
            case "SRB":
                result = "SRB";
                break;
            default:
                result = "";
                break;
        }
        return result;
    }

    convertCourierNetFreightDates() {
        if (this.courierNetFreight.loadingEndDate == undefined || this.courierNetFreight.loadingEndDate == "") {
            this.courierNetFreight.loadingEndDate = this.courierNetFreight.loadingBeginDate;
        }

        if (this.courierNetFreight.deliveryEndDate == undefined || this.courierNetFreight.deliveryEndDate == "") {
            this.courierNetFreight.deliveryEndDate = this.courierNetFreight.deliveryBeginDate;
        }
    }

    cancelClick() {
        this.$store.direct.dispatch.freightExchange.isNotConfirmedSendFreightToFreightExchangesDialog();
    }

    async saveClick() {
        const valid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
        if (valid) {

            let isUserLoggedToTransEu = "";
            if (this.sendToTransEu) {
                isUserLoggedToTransEu = await this.$store.direct.dispatch.freightExchange.checkIfUserIsLoggedToTransEu();
            }
            let isUserLoggedToCourierNet = "";
            if (this.sendToCourierNet) {
                isUserLoggedToCourierNet = await this.$store.direct.dispatch.freightExchange.checkIfUserIsLoggedToCourierNet();
            }

            if (isUserLoggedToTransEu == "Unauthorized") {
                this.$store.direct.dispatch.freightExchange.getTransEuAuthUrl();
                this.showConfirmDialog("TransEU", "Please log in to TransEU");
            }
            else if (isUserLoggedToCourierNet == "Unauthorized") {
                this.$store.direct.dispatch.freightExchange.getCourierNetToken();
                this.showConfirmDialog("Courier.net", "You have been logged in to Courier.net");
            }
            else {
                this.convertTransEuFreightTimespansToCorrectFormat();
                this.convertCourierNetFreightDates();

                this.courierNetFreight.startCountryCode = this.convertIsoCountryCodeToCourierNetCountryCode(this.courierNetFreight.startCountryCode);
                this.courierNetFreight.destinationCountryCode = this.convertIsoCountryCodeToCourierNetCountryCode(this.courierNetFreight.destinationCountryCode);

                const isSent = await this.$store.direct.dispatch.freightExchange.sendFreightToFreightExchanges({ sendFreightToTransEu: this.sendToTransEu, sendFreightToCourierNet: this.sendToCourierNet }) as any;

                if (isSent.isSuccess) {
                    await this.$store.direct.dispatch.freightExchange.isConfirmedSendFreightToFreightExchangesDialog();
                }
            }
        }
    }

    showConfirmDialog(title: string, message: string) {
        this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: title,
                message: message,
                links: [],
                options: { buttonColor: "primary", layoutColor: "primary" },
                buttonType: "ok"
            });
    }

    addNewPackage() {
        this.courierNetFreight.packages.push({} as CourierNetPackageRestModel);
    }

    removePackage(index: number) {
        this.courierNetFreight.packages.splice(index, 1)
    }
}
