import dayjs from "dayjs";
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import i18n from "../../../assets/i18n";
import { SettlementPaymentStatusEnum } from "../../../models/emums/settlement/settlementPaymentStatusEnum";
import ListItemNumberModel from "../../../models/listItemNumberModel";
import ContractorSearchItemModel from "../../../models/rest/contractor/contractorSearchItemModel";
import CurrencyRestModel from "../../../models/rest/register/currencyRestModel";
import SettlementIncomeDocumentPaymentObligationListItemRestModel from "../../../models/rest/settlement/income-document/payment-obligation/settlementIncomeDocumentPaymentObligationListItemRestModel";
import SettlementIncomeDocumentPaymentObligationListRequestModel from "../../../models/rest/settlement/income-document/settlementIncomeDocumentPaymentObligationListRequestModel";
import SettlementIncomeDocumentRestModel from "../../../models/rest/settlement/income-document/settlementIncomeDocumentRestModel";
import SettlementPaymentIncomeDocumentRestModel from "../../../models/rest/settlement/payment/settlementPaymentIncomeDocumentRestModel";
import SettlementPaymentRestModel from "../../../models/rest/settlement/payment/settlementPaymentRestModel";
import SettlementAmountSummaryRestModel from "../../../models/rest/settlement/settlementAmountSummaryRestModel";
import SettlementDocumentStatusRestModel from "../../../models/rest/settlement/settlementDocumentStatusRestModel";
import CreatePaymentOutsideTheSystemDialog from "../create-payment-outside-the-system-dialog.vue";
import IncomePaymentDialog from "../income-payment/income-payment-dialog.vue";
import SettlementAmountSummaryRow from "../settlement-amount-summary-row.vue";

@Component({
    components: {
        "income-payment-dialog": IncomePaymentDialog,
        "settlement-amount-summary-row": SettlementAmountSummaryRow,
        "create-payment-outside-the-system-dialog": CreatePaymentOutsideTheSystemDialog,
    }
})

export default class IncomeDocumentPaymentObligationList extends Vue {
    contractorSearch = "";
    paymentTermDateFromLabel = this.$t("costDocument.paymentTermDateFrom");
    paymentTermDateToLabel = this.$t("costDocument.paymentTermDateTo");
    mainCheckboxIsSelected = false;
    issueDateFromLabel = this.$t("costDocument.issueDateFrom");
    issueDateToLabel = this.$t("costDocument.issueDateTo");
    saleDateFromLabel = this.$t("costDocument.saleDateFrom");
    saleDateToLabel = this.$t("costDocument.saleDateTo");
    searchOnlyExpired = false;
    disableAddPaymentOutsideTheSystemButton = false;
    showAmountSummaryRowForCalculatedSelectedList = false;

    paymentObligationListHeaders = [
        {
            align: "start",
            value: "checkbox",
            width: "1%",
            sortable: false,
        },
        {
            text: "Id",
            align: "center",
            value: "id",
            width: "1%",
            sortable: true,
        },
        {
            text: this.$t("paymentObligation.documentNumber"),
            align: "start",
            value: "documentNumber",
            sortable: true,
        },
        {
            text: this.$t("paymentObligation.documentStatus"),
            align: "start",
            value: "incomeDocumentStatusName",
            sortable: true,
        },
        {
            text: this.$t("paymentObligation.correction"),
            align: "start",
            value: "isCorrection",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.paymentType"),
            align: "start",
            value: "paymentTypeId",
            sortable: true,
        },
        {
            text: this.$t("supervisor.orderId"),
            align: "start",
            value: "supervisorOrderIdcsString",
            sortable: false,
        },
        {
            text: this.$t("incomeDocument.customerOrderId"),
            align: "start",
            value: "supervisorCustomerOrderIdcsString",
            sortable: false,
        },
        {
            text: this.$t("contractor.branch"),
            align: "start",
            value: "branchId",
            sortable: true,
        },
        {
            text: this.$t("paymentObligation.contractorName"),
            align: "start",
            value: "contractorName",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.contractorSymbol"),
            align: "start",
            value: "contractorSymbol",
            sortable: true,
        },
        {
            text: this.$t("paymentObligation.contractorTin"),
            align: "start",
            value: "contractorTinWithPrefix",
            sortable: true,
        },
        {
            text: this.$t("paymentObligation.vatInPln"),
            align: "start",
            value: "isVatInPln",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.saleDate"),
            align: "start",
            value: "saleDate",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.issueDate"),
            align: "start",
            value: "issueDate",
            sortable: true,
        },
        {
            text: this.$t("paymentObligation.paymentTermDate"),
            align: "start",
            value: "paymentTermDate",
            sortable: true,
        },
        {
            text: "",
            align: "center",
            value: "paymentStatus",
            sortable: true,
        },
        {
            text: this.$t("paymentObligation.amount"),
            align: "end",
            value: "paymentAmount",
            sortable: true,
        },
        {
            text: this.$t("paymentObligation.currency"),
            align: "start",
            value: "currencyCode",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.paymentDaysLeft"),
            align: "start",
            value: "paymentDaysLeft",
            width: "1%",
            sortable: true,
        },
        {
            text: this.$t("common.actions"),
            align: "right",
            value: "actions",
            width: "1%",
            sortable: false,
        }
    ];

    incomePaymentListHeaders = [
        {
            text: "Id",
            align: "start",
            value: "id",
            width: "1%",
            sortable: true,
        },
        {
            text: this.$t("costDocument.paymentType"),
            align: "start",
            value: "paymentTypeId",
            sortable: false,
        },
        {
            text: this.$t("payment.amount"),
            align: "start",
            value: "paymentAmount",
            sortable: false,
        },
        {
            text: this.$t("payment.currency"),
            align: "start",
            value: "currencyCode",
            sortable: false,
        },
        {
            text: this.$t("common.createdBy"),
            align: "start",
            value: "createdByUserName",
            sortable: false,
        },
        {
            text: this.$t("payment.paymentDate"),
            align: "start",
            value: "paymentDate",
            sortable: false,
        },
    ];

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get paymentObligationListLoading(): boolean {
        return this.$store.direct.state.settlementIncomeDocumentPaymentObligation.paymentObligationListLoading;
    }

    get paymentObligationList(): SettlementIncomeDocumentPaymentObligationListItemRestModel[] {
        return this.$store.direct.state.settlementIncomeDocumentPaymentObligation.paymentObligationList;
    }

    get paymentObligationListTotalCount(): number {
        return this.$store.direct.state.settlementIncomeDocumentPaymentObligation.paymentObligationListTotalCount;
    }

    get pagerOptions(): any {
        return this.$store.direct.state.settlementIncomeDocumentPaymentObligation.paymentObligationListPagerOptions;
    }

    set pagerOptions(value: any) {
        this.$store.direct.commit.settlementIncomeDocumentPaymentObligation.setPaymentObligationListPagerOptions(value);
    }

    get paymentObligationListSearchText(): string {
        return this.$store.direct.state.settlementIncomeDocumentPaymentObligation.paymentObligationListSearchText;
    }

    set paymentObligationListSearchText(value: string) {
        this.$store.direct.commit.settlementIncomeDocumentPaymentObligation.setPaymentObligationListSearchText(value);
    }

    get searchContractors(): ContractorSearchItemModel[] {
        return this.$store.direct.state.settlementIncomeDocumentPaymentObligation.paymentObligationListSearchContractors;
    }

    set searchContractors(value: ContractorSearchItemModel[]) {
        this.$store.direct.commit.settlementIncomeDocumentPaymentObligation.setPaymentObligationListSearchContractors(value);
    }

    get searchContractorIdcs(): number[] {
        return this.$store.direct.state.settlementCostDocumentPaymentObligation.paymentObligationListSearchContractorIdcs;
    }

    set searchContractorIdcs(value: number[]) {
        this.$store.direct.commit.settlementCostDocumentPaymentObligation.setPaymentObligationListSearchContractorIdcs(value);
    }

    get selectedCurrency(): CurrencyRestModel {
        return this.$store.direct.state.settlementIncomeDocumentPaymentObligation.paymentObligationListSearchCurrency;
    }

    set selectedCurrency(value: CurrencyRestModel) {
        this.$store.direct.commit.settlementIncomeDocumentPaymentObligation.setPaymentObligationListSearchCurrency(value);
    }

    get searchPaymentTermDateFrom(): string {
        return this.$store.direct.state.settlementIncomeDocumentPaymentObligation.paymentObligationListSearchPaymentTermDateFrom;
    }

    set searchPaymentTermDateFrom(value: string) {
        this.$store.direct.commit.settlementIncomeDocumentPaymentObligation.setPaymentObligationListSearchPaymentTermDateFrom(value);
    }

    get searchPaymentTermDateTo(): string {
        return this.$store.direct.state.settlementIncomeDocumentPaymentObligation.paymentObligationListSearchPaymentTermDateTo;
    }

    set searchPaymentTermDateTo(value: string) {
        this.$store.direct.commit.settlementIncomeDocumentPaymentObligation.setPaymentObligationListSearchPaymentTermDateTo(value);
    }

    get searchBranchId(): number {
        return this.$store.direct.state.settlementIncomeDocumentPaymentObligation.paymentObligationListSearchBranchId;
    }

    set searchBranchId(value: number) {
        this.$store.direct.commit.settlementIncomeDocumentPaymentObligation.setPaymentObligationListSearchBranchId(value);
    }

    get searchDocumentStatusIdcs(): number[] {
        return this.$store.direct.state.settlementIncomeDocumentPaymentObligation.paymentObligationListSearchDocumentStatusIdcs;
    }

    set searchDocumentStatusIdcs(value: number[]) {
        this.$store.direct.commit.settlementIncomeDocumentPaymentObligation.setPaymentObligationListSearchDocumentStatusIdcs(value);
    }

    get searchIssueDateFrom(): string {
        return this.$store.direct.state.settlementIncomeDocumentPaymentObligation.paymentObligationListSearchIssueDateFrom;
    }

    set searchIssueDateFrom(value: string) {
        this.$store.direct.commit.settlementIncomeDocumentPaymentObligation.setPaymentObligationListSearchIssueDateFrom(value);
    }

    get searchIssueDateTo(): string {
        return this.$store.direct.state.settlementIncomeDocumentPaymentObligation.paymentObligationListSearchIssueDateTo;
    }

    set searchIssueDateTo(value: string) {
        this.$store.direct.commit.settlementIncomeDocumentPaymentObligation.setPaymentObligationListSearchIssueDateTo(value);
    }

    get searchSaleDateFrom(): string {
        return this.$store.direct.state.settlementIncomeDocumentPaymentObligation.paymentObligationListSearchSaleDateFrom;
    }

    set searchSaleDateFrom(value: string) {
        this.$store.direct.commit.settlementIncomeDocumentPaymentObligation.setPaymentObligationListSearchSaleDateFrom(value);
    }

    get searchSaleDateTo(): string {
        return this.$store.direct.state.settlementIncomeDocumentPaymentObligation.paymentObligationListSearchSaleDateTo;
    }

    set searchSaleDateTo(value: string) {
        this.$store.direct.commit.settlementIncomeDocumentPaymentObligation.setPaymentObligationListSearchSaleDateTo(value);
    }

    get searchExpiredDaysSince(): string {
        return this.$store.direct.state.settlementIncomeDocumentPaymentObligation.paymentObligationListSearchExpiredDaysSince;
    }

    set searchExpiredDaysSince(value: string) {
        this.$store.direct.commit.settlementIncomeDocumentPaymentObligation.setPaymentObligationListSearchExpiredDaysSince(value);
    }

    get searchExpiredDaysTo(): string {
        return this.$store.direct.state.settlementIncomeDocumentPaymentObligation.paymentObligationListSearchExpiredDaysTo;
    }

    set searchExpiredDaysTo(value: string) {
        this.$store.direct.commit.settlementIncomeDocumentPaymentObligation.setPaymentObligationListSearchExpiredDaysTo(value);
    }

    get footerPropsForSettlement(): any {
        return this.$store.direct.state.settlementCommon.footerPropsForSettlement;
    }

    get incomeDocumentPaymentObligationListAmountSummaryList(): SettlementAmountSummaryRestModel[] {
        let amountSummary = this.$store.direct.state.settlementIncomeDocumentPaymentObligation.paymentObligationListAmountSummaryList;

        if (amountSummary == undefined) {
            amountSummary = [] as SettlementAmountSummaryRestModel[];
        }

        return amountSummary;
    }

    get contractorFound(): ContractorSearchItemModel[] {
        return this.$store.direct.state.common.contractorSearch;
    }

    get currencies(): CurrencyRestModel[] {
        const currencies = this.$store.direct.state.contractor.contractorAssets.currencies;
        if (currencies == undefined) {
            this.$store.direct.dispatch.contractor.loadContractorAssets();
        }
        else {
            currencies.push({ code: i18n.t("common.all"), name: "" } as CurrencyRestModel)
        }

        return currencies;
    }

    get branches(): ListItemNumberModel[] {
        const branches = this.$store.direct.state.contractor.contractorAssets.branches;
        if (branches == undefined) {
            this.$store.direct.dispatch.contractor.loadContractorAssets();
        }
        return branches;
    }

    get branchList(): ListItemNumberModel[] {
        const branchList = this.branches;
        let clonedBranchList = [] as ListItemNumberModel[];
        if (branchList == undefined) {
            this.$store.direct.dispatch.contractor.loadContractorAssets();
        }
        else {
            clonedBranchList = [...branchList];
            clonedBranchList.push({ value: 0, text: i18n.t("common.all") } as ListItemNumberModel);
        }
        return clonedBranchList;
    }

    get incomeDocumentStatuses(): SettlementDocumentStatusRestModel[] {
        const statusesList = this.$store.direct.state.settlementCommon.settlementAssets.incomeDocumentStatuses;

        let clonedStatusesList = [] as SettlementDocumentStatusRestModel[];
        if (statusesList == undefined) {
            this.$store.direct.dispatch.settlementCommon.loadSettlementAssets();
        }
        else {
            clonedStatusesList = [...statusesList];
            clonedStatusesList.push({ id: 0, name: i18n.t("common.all") } as SettlementDocumentStatusRestModel);
        }
        return clonedStatusesList
    }

    get paymentTypeList(): ListItemNumberModel[] {
        return this.$store.direct.state.settlementCommon.settlementAssets.paymentTypes;
    }

    get incomeDocument(): SettlementIncomeDocumentRestModel {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocument;
    }

    get incomePayment(): SettlementPaymentRestModel {
        return this.$store.direct.state.settlementPayment.payment;
    }

    set incomePayment(val: SettlementPaymentRestModel) {
        this.$store.direct.commit.settlementPayment.setPayment(val);
    }

    get selectedPaymentObligationList(): SettlementIncomeDocumentPaymentObligationListItemRestModel[] {
        return this.$store.direct.state.settlementIncomeDocumentPaymentObligation.selectedPaymentObligationList;
    }

    set selectedPaymentObligationList(val: SettlementIncomeDocumentPaymentObligationListItemRestModel[]) {
        this.$store.direct.commit.settlementIncomeDocumentPaymentObligation.setSelectedPaymentObligationList(val);
    }

    get calculatedSelectedListForAmountSummaryRow(): SettlementAmountSummaryRestModel[] {
        return this.$store.direct.state.settlementCommon.calculatedSelectedListForAmountSummaryRow;
    }

    @Watch("pagerOptions", { deep: true })
    pagerOptionsHandles() {
        this.loadPaymentObligationList();
    }

    @Watch("contractorSearch")
    async contractorSearchLoadItems(val: string) {
        await this.$store.direct.dispatch.common.searchContractors({ searchText: val, contractorTypes: [], contractorPropertiesEnabled: [], source: "" });
        if (this.searchContractors.length > 0) {
            this.searchContractors.forEach((x: any) => {
                if (!this.contractorFound.includes(x)) {
                    this.contractorFound.push(x);
                }
            })
        }
    }

    async created() {
        this.$store.direct.dispatch.contractor.loadContractorAssets();
        this.$store.direct.dispatch.settlementCommon.loadSettlementAssets();
        this.loadPaymentObligationList();
    }

    getListItemStyle(item: SettlementIncomeDocumentPaymentObligationListItemRestModel) {
        if (item.paymentDaysLeft == null) {
            return;
        }
        else if (item.paymentDaysLeft < 0) {
            return "red--text"
        }
        else if (item.paymentDaysLeft == 0) {
            return "green--text"
        }
        else if (item.paymentDaysLeft < 4) {
            return "blue--text"
        }
        else {
            return;
        }
    }

    itemRowTextStyle(item: SettlementIncomeDocumentPaymentObligationListItemRestModel) {
        let style = ""
        item.isCorrection == true ? style += "font-italic font-weight-black " : "";
        item.hasCorrection == true ? style += "font-italic " : "";
        return style;
    }

    formatDate(dateTime: any) {
        if (dateTime != null) {
            return dayjs(dateTime).format("YYYY-MM-DD");
        }
    }

    formatBranchIdToBranchName(branchId: any) {
        if (this.branches != undefined) {
            return this.branches.find(x => x.value == branchId)?.text;
        }
    }

    formatPaymentTypeIdToPaymentName(paymentTypeId: any) {
        if (this.paymentTypeList != undefined) {
            return this.paymentTypeList.find(x => x.value == paymentTypeId)?.text;
        }
    }

    getPaymentStatusIconColor(item: SettlementIncomeDocumentPaymentObligationListItemRestModel): string {
        if (item.incomeDocumentPaymentStatusId == SettlementPaymentStatusEnum.Niezaplacona) {
            return "red--text";
        }
        if (item.incomeDocumentPaymentStatusId == SettlementPaymentStatusEnum.ZaplataCzesciowa) {
            return "blue--text";
        }
        else if (item.incomeDocumentPaymentStatusId == SettlementPaymentStatusEnum.Zaplacona) {
            return "green--text";
        }
        else if (item.incomeDocumentPaymentStatusId == SettlementPaymentStatusEnum.Nadplata) {
            return "yellow--text";
        }
        else {
            return "";
        }
    }

    searchClick() {
        this.loadPaymentObligationList();
    }

    clearSearchContractorClick() {
        this.searchContractorIdcs = [] as number[];
        this.loadPaymentObligationList();
    }

    clearDateTimePicker(clearableValue: string) {
        if (clearableValue == "searchPaymentTermDateFrom") {
            this.searchPaymentTermDateFrom = "";
        }
        if (clearableValue == "searchPaymentTermDateTo") {

            this.searchPaymentTermDateTo = "";
        }
        if (clearableValue == "searchIssueDateFrom") {

            this.searchIssueDateFrom = "";
        }
        if (clearableValue == "searchIssueDateTo") {

            this.searchIssueDateTo = "";
        }
        if (clearableValue == "searchSaleDateFrom") {

            this.searchSaleDateFrom = "";
        }
        if (clearableValue == "searchSaleDateTo") {

            this.searchSaleDateTo = "";
        }
        this.loadPaymentObligationList();
    }

    clearAllFilters() {
        this.paymentObligationListSearchText = "";
        this.searchBranchId = 0;
        this.searchDocumentStatusIdcs = [] as number[];
        this.searchContractorIdcs = [] as number[];
        this.searchContractors = [] as ContractorSearchItemModel[];
        this.selectedCurrency = {} as CurrencyRestModel;
        this.searchPaymentTermDateFrom = "";
        this.searchPaymentTermDateTo = "";
        this.searchIssueDateFrom = "";
        this.searchIssueDateTo = "";
        this.searchSaleDateFrom = "";
        this.searchSaleDateTo = "";
        this.searchOnlyExpired = false;
        this.searchExpiredDaysSince = "";
        this.searchExpiredDaysTo = "";

        this.loadPaymentObligationList();
    }

    showPaymentObligationIncomeDocument(item: SettlementIncomeDocumentPaymentObligationListItemRestModel) {
        if (item.isCorrection) {
            return "/settlement/income-document/correction/edit/" + item.incomeDocumentId;

        }
        else {
            return "/settlement/income-document/edit/" + item.incomeDocumentId;
        }
    }

    async loadPaymentObligationList() {
        if (!this.selectedCurrency.name) {
            this.selectedCurrency = {} as CurrencyRestModel;
        }

        await this.$store.direct.dispatch.settlementIncomeDocumentPaymentObligation.loadPaymentObligationList({
            requestModel: this.prepareIncomeDocumentPaymentObligationRequestModel()
        });
    }

    createXlsReportClick() {
        this.$store.direct.dispatch.settlementIncomeDocumentPaymentObligation.downloadXlsReportSettlementIncomeDocumentPaymentObligationList({
            requestModel: this.prepareIncomeDocumentPaymentObligationRequestModel()
        });
    }

    prepareIncomeDocumentPaymentObligationRequestModel(): SettlementIncomeDocumentPaymentObligationListRequestModel {
        return {
            searchText: this.paymentObligationListSearchText,
            page: this.pagerOptions.page,
            pageSize: this.pagerOptions.itemsPerPage,
            sortBy: this.pagerOptions.sortBy[0],
            sortDesc: this.pagerOptions.sortDesc[0],
            searchBranchId: this.searchBranchId,
            searchDocumentStatusIdcs: this.searchDocumentStatusIdcs,
            searchContractorIdcs: (this.searchContractors != undefined && this.searchContractors.length > 0) ? this.searchContractors.map(x => x.value) : [],
            searchCurrencyCode: this.selectedCurrency.code,
            searchPaymentTermDateFrom: this.searchPaymentTermDateFrom,
            searchPaymentTermDateTo: this.searchPaymentTermDateTo,
            searchIssueDateFrom: this.searchIssueDateFrom,
            searchIssueDateTo: this.searchIssueDateTo,
            searchSaleDateFrom: this.searchSaleDateFrom,
            searchSaleDateTo: this.searchSaleDateTo,
            searchOnlyExpired: this.searchOnlyExpired,
            searchExpiredDaysSince: this.searchExpiredDaysSince,
            searchExpiredDaysTo: this.searchExpiredDaysTo,
            idList: this.selectedPaymentObligationList.map((x: SettlementIncomeDocumentPaymentObligationListItemRestModel) => {
                return x.id;
            }),
        } as SettlementIncomeDocumentPaymentObligationListRequestModel;
    }

    async createPaymentClick(paymentObligation: SettlementIncomeDocumentPaymentObligationListItemRestModel) {
        await this.$store.direct.dispatch.settlementIncomeDocument.loadIncomeDocument({ incomeDocumentId: paymentObligation.incomeDocumentId });

        const totalPaymentAmount = this.incomeDocument.paymentObligationList.length == 1 ? this.incomeDocument.paymentObligationList[0].paymentAmount : 0;
        const paymentCurrencyCode = this.incomeDocument.paymentObligationList.length == 1 ? this.incomeDocument.paymentObligationList[0].paymentCurrencyCode : undefined;

        await this.$store.direct.dispatch.settlementPayment.loadIncomePayment({ paymentGroupId: "" });

        this.incomePayment.paymentAmount = totalPaymentAmount;
        if (paymentCurrencyCode != undefined) {
            this.incomePayment.currencyCode = paymentCurrencyCode;
        }

        this.incomePayment.paymentIncomeDocumentRequestList = [{ incomeDocument: { ...this.incomeDocument } as any as SettlementIncomeDocumentRestModel, paymentAmount: 0 } as SettlementPaymentIncomeDocumentRestModel];

        this.$store.direct.dispatch.settlementPayment
            .openIncomePaymentDialog({
                title: `${this.$t("payment.addIncomePayment")}`,
            })
            .then(async confirm => {
                if (confirm) {
                    this.loadPaymentObligationList();
                }
            })
    }

    addSelectedPaymentObligationToList() {
        this.selectedPaymentObligationList = this.paymentObligationList.filter((x: SettlementIncomeDocumentPaymentObligationListItemRestModel) => {
            if (x.isCheckedForCreatingPayment) {
                return x.id;
            }
            else {
                this.mainCheckboxIsSelected = false;
            }
        });

        this.disableAddPaymentOutsideTheSystemButton = !this.checkIfIsAllowToCreatePaymentOutsideTheSystem(this.selectedPaymentObligationList);

        if (this.showAmountSummaryRowForCalculatedSelectedList) {
            this.$store.direct.dispatch.settlementCommon.calculateSelectedListWithCurrencyCodeAndPaymentAmountValuesForAmountSummaryRow({ list: this.selectedPaymentObligationList });
        }
    }

    checkIfIsAllowToCreatePaymentOutsideTheSystem(list: SettlementIncomeDocumentPaymentObligationListItemRestModel[]): boolean {
        for (const item of list) {
            if (item.allowCreatePaymentOutsideTheSystem === false) {
                return false;
            }
        }
        return true;
    }

    async addPaymentOutsideTheSystemFromSettlementIncomeDocumentPaymentObligationList() {
        this.$store.direct.dispatch.settlementCommon
            .openCreatePaymentOutsideTheSystemDialog({
                title: `${this.$t("costDocument.addPaymentOutsideTheSystem")}`,
                source: "incomeDocumentPaymentObligationList",
            })
            .then(async confirm => {
                if (confirm) {
                    await this.$store.direct.dispatch.settlementPayment.addPaymentOutsideTheSystemFromSettlementIncomeDocumentPaymentObligationList({ selectedPaymentObligationList: this.selectedPaymentObligationList });
                    this.selectedPaymentObligationList = [] as SettlementIncomeDocumentPaymentObligationListItemRestModel[];
                    this.loadPaymentObligationList();
                }
            });
    }

    async createPaymentsFromPaymentObligationList() {
        await this.$store.direct.dispatch.settlementIncomeDocumentPaymentObligation.createPaymentsFromPaymentObligationList({ selectedPaymentObligationList: this.selectedPaymentObligationList });
        this.selectedPaymentObligationList = [] as SettlementIncomeDocumentPaymentObligationListItemRestModel[];
        this.mainCheckboxIsSelected = false;
        this.loadPaymentObligationList();
    }

    selectAllItemsOnThePage() {
        this.paymentObligationList.forEach((x: SettlementIncomeDocumentPaymentObligationListItemRestModel) => {
            x.isCheckedForCreatingPayment = this.mainCheckboxIsSelected;
        });
        this.$forceUpdate();

        this.selectedPaymentObligationList = this.paymentObligationList.filter((x: SettlementIncomeDocumentPaymentObligationListItemRestModel) => {
            if (x.isCheckedForCreatingPayment) {
                return x.id;
            }
        });

        this.$store.direct.dispatch.settlementCommon.calculateSelectedListWithCurrencyCodeAndPaymentAmountValuesForAmountSummaryRow({ list: this.selectedPaymentObligationList });
    }

    currentPageInput = this.pagerOptions.page;
    goToPage() {
        if (this.currentPageInput > 0) {
            this.pagerOptions.page = this.currentPageInput;
        }
    }

    destroyed() {
        this.selectedPaymentObligationList = [] as SettlementIncomeDocumentPaymentObligationListItemRestModel[];
    }
}
