import Vue from "vue";
import { Component } from "vue-property-decorator";
import ContractorNoteRestModel from "../../../models/rest/contractor/contractorNoteRestModel";

@Component
export default class NoteTab extends Vue {
    noteListHeaders = [
        { text: this.$t("contractor.description"), align: "start", value: "description" },
        { text: this.$t("common.createdAt"), align: "start", value: "createdAt" },
        { text: this.$t("common.createdBy"), align: "start", value: "createdBy" },
        { text: this.$t("contractor.actions"), align: "right", value: "actions", width: "1%" },
    ];

    get noteList(): ContractorNoteRestModel[] {
        return this.$store.direct.state.contractor.noteList;
    }

    set noteList(val: ContractorNoteRestModel[]) {
        this.$store.direct.commit.contractor.setNoteList(val);
    }

    get isContractorNew(): boolean {
        return this.$store.direct.state.contractor.isContractorNew;
    }

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    async noteCreateClick() {
        if (this.isContractorNew == false) {
            this.$store.direct.commit.contractor.setNote({} as ContractorNoteRestModel);
            this.$store.direct.commit.contractor.setShowNoteDialog(true);
        }
        else {
            this.$store.direct.dispatch.confirm.openConfirmDialog(
                {
                    showConfirmDialog: true,
                    title: this.$t("contractor.savingContractorIsRequired").toString(),
                    message: this.$t("contractor.saveContractorIsRequiredForThisAction").toString(),
                    links: [],
                    options: { buttonColor: "error", layoutColor: "error" },
                    buttonType: "ok"
                });
        }
    }

    async noteEditClick(model: ContractorNoteRestModel) {
        await this.$store.direct.dispatch.contractor.loadNote({ id: model.id });
    }

    noteDeleteClick(note: ContractorNoteRestModel) {
        const noteId = note.id;
        this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: `${this.$t("common.delete")}`,
                message: `${this.$t("contractor.noteDeleteMessage")}`,
                links: [],
                options: { buttonColor: "error", layoutColor: "red" },
                buttonType: "yes/no"
            })
            .then(confirm => {
                if (confirm) {
                    this.$store.direct.dispatch.contractor.deleteNote({ id: noteId });
                }
            });
    }

    destroyed() {
        this.noteList = [] as ContractorNoteRestModel[];
    }
}
