import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import BaseCriterionModel from "../../models/baseCriterionModel";
import { BaseCriterionNameEnum } from "../../models/emums/baseCriterionNameEnum";
import { ContractorTypes } from "../../models/emums/contractorTypes";

@Component({
    props: {
        criterion: {
            type: Object,
            default() {
                return {} as BaseCriterionModel;
            }
        }
    },
})

export default class AutocompleteCriterion extends Vue {
    criterion!: BaseCriterionModel;
    criterionMutated = {} as BaseCriterionModel;
    searchValue = "";
    items = [] as any[];

    get isMultiple(): boolean {
        return this.isArray(this.criterionMutated.value);
    }

    get allowSearchContractorCarriers(): boolean {
        return this.$store.direct.state.supervisorReport.allowSearchContractorCarriers;
    }

    isArray(a: any) {
        return (!!a) && (a.constructor === Array);
    };

    @Watch("searchValue")
    async onChangeSearchValue() {
        if (this.criterionMutated.name == BaseCriterionNameEnum.contractorSenders) {
            await this.$store.direct.dispatch.common.searchContractors({ searchText: this.searchValue, contractorTypes: [ContractorTypes.Client], contractorPropertiesEnabled: [], source: "supervisor" });
            this.items = this.$store.direct.state.supervisor.contractorSenderFound;
        }
        else if (this.criterionMutated.name == BaseCriterionNameEnum.contractorPayers) {
            await this.$store.direct.dispatch.common.searchContractors({ searchText: this.searchValue, contractorTypes: [ContractorTypes.Payer], contractorPropertiesEnabled: [], source: "supervisor" });
            this.items = this.$store.direct.state.supervisor.contractorPayerFound;
        }
        else if (this.criterionMutated.name == BaseCriterionNameEnum.contractorCarriers && this.allowSearchContractorCarriers) {
            await this.$store.direct.dispatch.common.searchContractors({ searchText: this.searchValue, contractorTypes: [ContractorTypes.Carrier], contractorPropertiesEnabled: [], source: "supervisor" });
            this.items = this.$store.direct.state.supervisor.contractorCarrierFound;
        }

        if (this.criterionMutated.value != null) {
            this.criterionMutated.value.forEach((x: any) => {
                if (!this.items.includes(x)) {
                    this.items.push(x);
                }
            })
        }
    }

    created() {
        this.criterionMutated = this.criterion;
    }

    updateCriterionValue() {
        this.criterion.value = this.criterionMutated.value;
    }
}
