import { defineModule } from "direct-vuex";
import { moduleActionContext } from "@/store/index";
import ContractorOperationRestModel from "../../models/rest/contractor/contractorOperationRestModel";
import { AxiosResponse, AxiosError } from "axios";
import { axiosInstance } from "../../plugins/axios";
import ListItemStringModel from "../../models/listItemStringModel";
import router from "../../router";

export interface OperationModuleStateInterface {
    operationList: ContractorOperationRestModel[];
    operationListLoading: false | boolean;
    operationListTotalCount: number;
    operationListSearchAccountManagerIdList: any;
    operationListSearchAssignedToUserIdList: any;
    operationListSearchText: string;
    operationListPagerOptions: any;
    operationListTags: ListItemStringModel[];
}

const operationModule = defineModule({
    namespaced: true,
    state: {
        operationList: [] as ContractorOperationRestModel[],
        operationListLoading: false,
        operationListTotalCount: 0,
        operationListSearchAccountManagerIdList: [],
        operationListSearchAssignedToUserIdList: [],
        operationListSearchText: "",
        operationListPagerOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ["editedAt"],
            sortDesc: [true]
        } as any,
        operationListTags: [] as ListItemStringModel[],
    } as OperationModuleStateInterface,
    mutations: {
        setOperationList(state, value: ContractorOperationRestModel[]) {
            state.operationList = value;
        },
        setOperationListLoading(state, value: boolean) {
            state.operationListLoading = value;
        },
        setOperationListTotalCount(state, value: number) {
            state.operationListTotalCount = value;
            if ((state.operationListPagerOptions.page * state.operationListPagerOptions.itemsPerPage) >= (value + state.operationListPagerOptions.itemsPerPage)) {
                state.operationListPagerOptions.page = 1;
            }
        },
        setOperationListSearchAccountManagerIdList(state, value: string[]) {
            state.operationListSearchAccountManagerIdList = value;
        },
        setOperationListSearchAssignedToUserIdList(state, value: string[]) {
            state.operationListSearchAssignedToUserIdList = value;
        },
        setOperationListSearchText(state, value: string) {
            state.operationListSearchText = value;
        },
        setOperationListPagerOptions(state, value: any) {
            state.operationListPagerOptions = value;
        },
        setOperationListPagerTags(state, value: ListItemStringModel[]) {
            state.operationListTags = value;
        },
    },
    actions: {
        operationListCleanFilters(context) {
            const { commit } = _moduleActionContext(context);
            const filterOptions = {
                page: 1,
                itemsPerPage: 10,
                sortBy: ["editedAt"],
                sortDesc: [true]
            }
            commit.setOperationListSearchText("");
            commit.setOperationListPagerOptions(filterOptions);
            commit.setOperationListSearchAccountManagerIdList([]);
            commit.setOperationListSearchAssignedToUserIdList([]);
            commit.setOperationListPagerTags([]);
        },
        loadOperationList(
            context,
            payload: {
                searchText: string;
                searchTags: string[];
                page: number;
                itemsPerPage: number;
                sortBy: string;
                sortDesc: boolean;
                searchAccountManagerIdList: string[];
                searchAssignedToUserIdList: string[];
            }
        ) {
            const { commit, dispatch, rootDispatch } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                commit.setOperationListLoading(true);
                const params = {
                    searchText: payload.searchText,
                    searchTags: payload.searchTags,
                    page: payload.page,
                    pageSize: payload.itemsPerPage,
                    sortBy: payload.sortBy,
                    sortDesc: payload.sortDesc,
                    searchAccountManagers: payload.searchAccountManagerIdList,
                    searchAssignedToUser: payload.searchAssignedToUserIdList
                };
                axiosInstance
                    .get("/api/operation/list", { params })
                    .then((resp: AxiosResponse) => {
                        commit.setOperationList(resp.data.items);
                        commit.setOperationListTotalCount(resp.data.totalItemsCount);
                        rootDispatch.contractor.loadContractorAssets();
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setOperationListLoading(false);
                    });
            });
        }
    }
});

export default operationModule;
const _moduleActionContext = (context: any) => moduleActionContext(context, operationModule);
