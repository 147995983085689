import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import SupervisorIntegrationRestModel from "../../../models/rest/supervisor/supervisorIntegrationRestModel";

@Component
export default class IntegrationDialog extends Vue {

    integrations: SupervisorIntegrationRestModel[] = [];

    get integrationStates(): any[] {
        return this.$store.direct.state.supervisor.integrationStates;
    }

    get showDialog(): boolean {
        return this.$store.direct.state.supervisor.showIntegrationDialog;
    }

    get title(): string {
        return this.$store.direct.state.supervisor.integrationDialogTitle;
    }

    get supervisorIntegrations(): SupervisorIntegrationRestModel[] {
        return this.$store.direct.state.supervisor.supervisorIntegrations;
    }

    set supervisorIntegrations(value: SupervisorIntegrationRestModel[]) {
        this.supervisorIntegrations = value;
    }

    @Watch("supervisorIntegrations", { deep: true })
    supervisorIntegrationsHandles() {
        this.integrations = this.supervisorIntegrations;
    }

    checkGuid(guid: string) {
        if (guid != undefined) {
            return true;
        }
        return false;
    }

    integrationPath(guid: string): string {
        const baseUrl = window.location.origin
        return baseUrl + "/online-tracking/" + guid;
    }

    openIntegrationLink(path: string) {
        window.open(path, '_blank');
    }

    addNewSupervisorIntegration() {
        this.supervisorIntegrations.push({} as SupervisorIntegrationRestModel);
    }

    deleteSupervisorIntegration(index: number) {
        this.supervisorIntegrations.splice(index, 1);
    }

    cancelClick() {
        this.$store.direct.dispatch.supervisor.isNotConfirmedIntegrationDialog();
    }

    async saveClick() {
        await this.$store.direct.dispatch.supervisor.isConfirmedIntegrationDialog();
        this.$store.direct.commit.supervisor.setSupervisorIntegrations(this.supervisorIntegrations);
    }
}
