export enum Roles {
    Accountant = "Accountant",
    AccountantAdmin = "AccountantAdmin",
    Admin = "Admin",
    Client = "Client",
    CrmAdmin = "CrmAdmin",
    CrmLower = "CrmLower",
    Crm = "Crm",
    Disponent = "Disponent",
    DisponentAdmin = "Disponent Admin",
    FullEditingDisponentAdmin = "FullEditingDisponentAdmin",
    HigherDisponentAdmin = "HigherDisponentAdmin",
    Partner = "Partner",
    TrackingClient = "Tracking Client",
    TrackingClientAdmin = "Tracking Client Admin",
    User = "User"
}
