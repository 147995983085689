import { moduleActionContext } from "@/store/index";
import { AxiosError, AxiosResponse } from "axios";
import { defineModule } from "direct-vuex";
import ListItemNumberModel from "../../models/listItemNumberModel";
import { axiosInstance } from "../../plugins/axios";

export interface SupervisorTransportDocumentModuleStateInterface {
    transportDocumentTypeList: ListItemNumberModel[];
    showDialog: boolean;
    title: string;
    resolve: any;
    currentSupervisorLoadingPlaceId: undefined | number;
    currentSupervisorUnloadingPlaceId: undefined | number;
}

const supervisorTransportDocumentModule = defineModule({
    namespaced: true,
    state: {
        transportDocumentTypeList: [] as ListItemNumberModel[],
        showDialog: false,
        title: "",
        resolve: null,
        currentSupervisorLoadingPlaceId: undefined,
        currentSupervisorUnloadingPlaceId: undefined
    } as SupervisorTransportDocumentModuleStateInterface,
    mutations: {
        setTransportDocumentTypeList(state, value: ListItemNumberModel[]) {
            state.transportDocumentTypeList = value;
        },
        setShowDialog(state, value: boolean) {
            state.showDialog = value;
        },
        setTitle(state, value: string) {
            state.title = value;
        },
        setResolve(state, value: any) {
            state.resolve = value;
        },
        setCurrentSupervisorLoadingPlaceId(state, value: undefined | number) {
            state.currentSupervisorLoadingPlaceId = value;
        },
        setCurrentSupervisorUnloadingPlaceId(state, value: undefined | number) {
            state.currentSupervisorUnloadingPlaceId = value;
        }
    },
    actions: {
        loadTransportDocumentTypeList(context) {
            const { commit } = _moduleActionContext(context)
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/supervisorTransportDocument/loadTransportDocumentTypeList")
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                        commit.setTransportDocumentTypeList(resp.data)
                    })
            })
        },
        generateAndGetSupervisorTransporDocumentFile(context, payload: { transportDocumentTypeId: null | number }) {
            const { state } = _moduleActionContext(context)
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/supervisorTransportDocument/generateAndGetSupervisorTransporDocumentFile/" + payload.transportDocumentTypeId + "/" + state.currentSupervisorLoadingPlaceId+ "/" + state.currentSupervisorUnloadingPlaceId, {
                        responseType: "blob"
                    })
                    .then((resp: AxiosResponse) => {
                        const blob = new Blob([resp.data], {
                            type: resp.headers["content-type"]
                        });
                        const contentDisposition = resp.headers["content-disposition"];
                        const filename = contentDisposition
                            .split(";")[1]
                            .split("=")[1]
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement("a");
                        a.style.display = "none";
                        document.body.appendChild(a);
                        a.download = filename;
                        a.href = url;
                        a.click();
                        window.URL.revokeObjectURL(url);

                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        isConfirmed(context) {
            const { state, commit } = _moduleActionContext(context);
            state.resolve(true);
            commit.setShowDialog(false);
        },
        isNotConfirmed(context) {
            const { commit } = _moduleActionContext(context);
            commit.setShowDialog(false);
        },
        openDialog(
            context,
            payload: {
                showDialog: boolean;
                title: string;
            }
        ) {
            const { commit } = _moduleActionContext(context);
            commit.setShowDialog(payload.showDialog);
            commit.setTitle(payload.title);
            return new Promise(resolve => {
                commit.setResolve(resolve);
            });
        }
    }
});

export default supervisorTransportDocumentModule;
const _moduleActionContext = (context: any) => moduleActionContext(context, supervisorTransportDocumentModule)