import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({
    props: {
        country: {
            type: String,
            required: true
        }
    },
})
export default class FlagIcon extends Vue {
    country!: string;

    get flagName(): any {
        if (this.country != '') {
            return this.country.toLowerCase() + ".svg"
        }
        else {
            return null;
        }
    }
};
