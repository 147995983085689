import Vue from "vue";
import { Component } from "vue-property-decorator";
import Menu from "@/layouts/menu/menu.vue";

@Component({ components: { Menu } })

export default class Layout extends Vue {

    get localStorageApplicationStateData(): any {
        return this.$store.direct.state.common.localStorageApplicationStateData;
    }

    get padding() {
        if (this.isLogged) {
            return (this.localStorageApplicationStateData.expandMenu != true) ? 256 : 75;
        }
        return 1;
    }

    get isLogged(): boolean {
        return this.$store.direct.state.auth.isLogged;
    }

    get loading(): boolean {
        return this.$store.direct.state.loading;
    }

}
