import { AxiosResponse } from "axios";

function saveFile(axiosResponse: AxiosResponse) {
    const blob = new Blob([axiosResponse.data], {
        type: axiosResponse.headers["content-type"]
    });
    const blobURL = (window.URL && window.URL.createObjectURL) ? window.URL.createObjectURL(blob) : window.webkitURL.createObjectURL(blob);
    const contentDisposition = axiosResponse.headers["content-disposition"];

    const filenameParts = contentDisposition.match(/filename\*=UTF-8''([^;]+)/);
    const filename = filenameParts && filenameParts.length > 1 ? filenameParts[1] : contentDisposition.split(";")[1].split("=")[1].replace(/^"|"$/g, '');

    const a = document.createElement('a');
    a.style.display = "none";
    a.setAttribute('download', filename);
    a.href = blobURL;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
        window.URL.revokeObjectURL(a.href);
        document.body.removeChild(a);
    }, 5000) // 5s
}
export { saveFile };