import ListItemNumberModel from "@/models/listItemNumberModel";
import BranchRestModel from "@/models/rest/register/branchRestModel";
import { moduleActionContext } from "@/store/index";
import { AxiosError, AxiosResponse } from "axios";
import { defineModule } from "direct-vuex";
import CostTypeRestModel from "../../models/rest/register/costTypeRestModel";
import CountryRestModel from "../../models/rest/register/countryRestModel";
import CurrencyRestModel from "../../models/rest/register/currencyRestModel";
import SafeLocationRestModel from "../../models/rest/register/safeLocationRestModel";
import SupervisorCarrierDocumentOrderRequirementRestModel from "../../models/rest/supervisor/supervisorCarrierDocumentOrderRequirementRestModel";
import { axiosInstance } from "../../plugins/axios";

export interface RegisterModuleStateInterface {
    branch: BranchRestModel;
    branches: BranchRestModel[];
    showBranchDialog: boolean,
    countries: CountryRestModel[],
    country: CountryRestModel,
    showCountryDialog: boolean,
    showCountryTaxRatesDialog: boolean,
    countryTaxRateArray: number[],
    costTypes: CostTypeRestModel[],
    costType: CostTypeRestModel,
    showCostTypeDialog: boolean,
    currencies: CurrencyRestModel[],
    currency: CurrencyRestModel,
    showCurrencyDialog: boolean,
    personRoles: ListItemNumberModel[],
    personRole: ListItemNumberModel,
    showPorsonRoleDialog: boolean,
    supervisorCarrierDocumentOrderRequirement: SupervisorCarrierDocumentOrderRequirementRestModel,
    showSupervisorCarrierDocumentOrderRequirementDialog: boolean,
    branchesLoading: boolean;
    safeLocationList: SafeLocationRestModel[],
    safeLocation: SafeLocationRestModel,
    showSafeLocationDialog: boolean,
    tags: ListItemNumberModel[],
    tag: ListItemNumberModel,
    showTagDialog: boolean,
}

const registerModule = defineModule({
    namespaced: true,
    state: {
        branch: {} as BranchRestModel,
        branches: [] as BranchRestModel[],
        showBranchDialog: false,
        countries: [] as CountryRestModel[],
        country: {} as CountryRestModel,
        showCountryDialog: false,
        showCountryTaxRatesDialog: false,
        countryTaxRateArray: [] as number[],
        costTypes: [] as CostTypeRestModel[],
        costType: {} as CostTypeRestModel,
        showCostTypeDialog: false,
        currencies: [] as CurrencyRestModel[],
        currency: {} as CurrencyRestModel,
        showCurrencyDialog: false,
        personRoles: [] as ListItemNumberModel[],
        personRole: {} as ListItemNumberModel,
        showPorsonRoleDialog: false,
        supervisorCarrierDocumentOrderRequirement: {} as SupervisorCarrierDocumentOrderRequirementRestModel,
        showSupervisorCarrierDocumentOrderRequirementDialog: false,
        branchesLoading: false,
        safeLocationList: [] as SafeLocationRestModel[],
        safeLocation: {} as SafeLocationRestModel,
        showSafeLocationDialog: false,
        tags: [] as ListItemNumberModel[],
        tag: {} as ListItemNumberModel,
        showTagDialog: false,
    } as RegisterModuleStateInterface,
    mutations: {
        setBranch(state, value: BranchRestModel) {
            state.branch = value;
        },
        setBranches(state, props: { branches: BranchRestModel[] }) {
            state.branches = props.branches;
        },
        setShowBranchDialog(state, value: boolean) {
            state.showBranchDialog = value;
        },
        setBranchesLoading(state, value: boolean) {
            state.branchesLoading = value;
        },
        setCountries(state, value: CountryRestModel[]) {
            state.countries = value;
        },
        setCountry(state, value: CountryRestModel) {
            state.country = value;
        },
        setShowCountryDialog(state, value: boolean) {
            state.showCountryDialog = value;
        },
        setShowCountryTaxRatesDialog(state, value: boolean) {
            state.showCountryTaxRatesDialog = value;
        },
        setCountryTaxRateArray(state, value: number[]) {
            state.countryTaxRateArray = value;
        },
        setCostTypes(state, value: CostTypeRestModel[]) {
            state.costTypes = value;
        },
        setCostType(state, value: CostTypeRestModel) {
            state.costType = value;
        },
        setShowCostTypeDialog(state, value: boolean) {
            state.showCostTypeDialog = value;
        },
        setCurrencies(state, value: CurrencyRestModel[]) {
            state.currencies = value;
        },
        setCurrency(state, value: CurrencyRestModel) {
            state.currency = value;
        },
        setShowCurrencyDialog(state, value: boolean) {
            state.showCurrencyDialog = value;
        },
        setPersonRoles(state, value: ListItemNumberModel[]) {
            state.personRoles = value;
        },
        setPersonRole(state, value: ListItemNumberModel) {
            state.personRole = value;
        },
        setShowPersonRoleDialog(state, value: boolean) {
            state.showPorsonRoleDialog = value;
        },
        setSupervisorCarrierDocumentOrderRequirement(state, value: SupervisorCarrierDocumentOrderRequirementRestModel) {
            state.supervisorCarrierDocumentOrderRequirement = value;
        },
        setShowSupervisorCarrierDocumentOrderRequirementDialog(state, value: boolean) {
            state.showSupervisorCarrierDocumentOrderRequirementDialog = value;
        },
        setSafeLocationList(state, value: SafeLocationRestModel[]) {
            state.safeLocationList = value;
        },
        setSafeLocation(state, value: SafeLocationRestModel) {
            state.safeLocation = value;
        },
        setShowSafeLocationDialog(state, value: boolean) {
            state.showSafeLocationDialog = value;
        },
        setTags(state, value: ListItemNumberModel[]) {
            state.tags = value;
        },
        setTag(state, value: ListItemNumberModel) {
            state.tag = value;
        },
        setShowTagDialog(state, value: boolean) {
            state.showTagDialog = value;
        },
    },
    actions: {
        async loadCountries(context) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/admin/register/countries")
                    .then((resp: AxiosResponse) => {
                        commit.setCountries(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        async updateCountry(context) {
            const { commit, state, dispatch, rootCommit, rootDispatch } = _moduleActionContext(context);

            return new Promise((reject) => {
                axiosInstance
                    .post("/api/admin/register/country/update", state.country)
                    .then(() => {
                        dispatch.loadCountries();
                        commit.setShowCountryDialog(false);
                        rootCommit.contractor.setContractorAssetsLoaded(false);
                        rootDispatch.contractor.loadContractorAssets();
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        async showCountryDialog(context, payload: CountryRestModel) {
            const { commit } = _moduleActionContext(context);
            commit.setCountry(payload);
            commit.setShowCountryDialog(true);
        },
        async closeCountryDialog(context,) {
            const { commit } = _moduleActionContext(context);
            commit.setShowCountryDialog(false);
        },
        async showCountryTaxRatesDialog(context) {
            const { commit } = _moduleActionContext(context);
            commit.setShowCountryTaxRatesDialog(true);
        },
        async closeCountryTaxRatesDialog(context,) {
            const { commit } = _moduleActionContext(context);
            commit.setShowCountryTaxRatesDialog(false);
        },
        updateCountryTaxRates(context) {
            const { commit, state, dispatch, rootCommit, rootDispatch } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/admin/register/country/" + state.country.code + "/tax-rate-list/update", state.countryTaxRateArray)
                    .then((resp: AxiosResponse) => {
                        dispatch.loadCountries();
                        commit.setShowCountryTaxRatesDialog(false);
                        rootCommit.contractor.setContractorAssetsLoaded(false);
                        rootDispatch.contractor.loadContractorAssets();
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        async setIsCountryInUE(context, payload: { countryCode: string, isInUE: boolean }) {
            const { rootCommit, rootDispatch } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/admin/register/country/" + payload.countryCode + "/set-is-in-UE/" + payload.isInUE)
                    .then((resp: AxiosResponse) => {
                        rootCommit.contractor.setContractorAssetsLoaded(false);
                        rootDispatch.contractor.loadContractorAssets();
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        async loadBranchList(context) {
            const { commit, state } = _moduleActionContext(context);
            if (!state.branchesLoading) {
                return new Promise((resolve, reject) => {
                    axiosInstance
                        .get("/api/admin/register/branch/list")
                        .then((resp: AxiosResponse) => {
                            commit.setBranches({ branches: resp.data });
                            resolve(resp.data);
                        })
                        .catch((err: AxiosError) => reject(err));
                });
            }
        },
        async loadBranch(context, payload: { branchId: number }) {
            const { commit, state } = _moduleActionContext(context);
            if (!state.branchesLoading) {
                return new Promise((resolve, reject) => {
                    axiosInstance
                        .get("/api/admin/register/branch/" + payload.branchId)
                        .then((resp: AxiosResponse) => {
                            commit.setBranch(resp.data);
                            resolve(resp.data);
                        })
                        .catch((err: AxiosError) => reject(err));
                });
            }
        },
        async updateBranch(context) {
            const { commit, state, dispatch, rootCommit, rootDispatch } = _moduleActionContext(context);
            return await new Promise((reject) => {
                axiosInstance
                    .post("/api/admin/register/branch/update", state.branch)
                    .then(() => {
                        dispatch.loadBranchList();
                        commit.setShowBranchDialog(false);
                        rootCommit.contractor.setContractorAssetsLoaded(false);
                        rootDispatch.contractor.loadContractorAssets();
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        async showBranchDialog(context) {
            const { commit } = _moduleActionContext(context);
            commit.setShowBranchDialog(true);
        },
        async closeBranchDialog(context,) {
            const { commit } = _moduleActionContext(context);
            commit.setShowBranchDialog(false);
        },
        async loadCostTypes(context) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/admin/register/cost-types")
                    .then((resp: AxiosResponse) => {
                        commit.setCostTypes(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        async updateCostType(context) {
            const { commit, state, dispatch, rootCommit, rootDispatch } = _moduleActionContext(context);

            return new Promise((reject) => {
                axiosInstance
                    .post("/api/admin/register/cost-type/update", state.costType)
                    .then(() => {
                        dispatch.loadCostTypes();
                        commit.setShowCostTypeDialog(false);
                        rootCommit.contractor.setContractorAssetsLoaded(false);
                        rootDispatch.contractor.loadContractorAssets();
                        rootCommit.settlementCommon.setSettlementAssetsLoaded(false);
                        rootDispatch.settlementCommon.loadSettlementAssets();
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        async deleteCostType(context, payload: CostTypeRestModel) {
            const { rootCommit, rootDispatch, dispatch } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/admin/register/cost-type/delete", payload)
                    .then(() => {
                        dispatch.loadCostTypes();
                        rootCommit.settlementCommon.setSettlementAssetsLoaded(false);
                        rootDispatch.settlementCommon.loadSettlementAssets();
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        async showCostTypeDialog(context, payload: CostTypeRestModel) {
            const { commit } = _moduleActionContext(context);
            commit.setCostType(payload);
            commit.setShowCostTypeDialog(true);
        },
        async closeCostTypeDialog(context,) {
            const { commit } = _moduleActionContext(context);
            commit.setShowCostTypeDialog(false);
        },
        async loadCurrencies(context) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/admin/register/currencies")
                    .then((resp: AxiosResponse) => {
                        commit.setCurrencies(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        async updateCurrency(context) {
            const { commit, state, dispatch, rootCommit, rootDispatch } = _moduleActionContext(context);

            return new Promise((reject) => {
                axiosInstance
                    .post("/api/admin/register/currency/update", state.currency)
                    .then(() => {
                        dispatch.loadCurrencies();
                        commit.setShowCurrencyDialog(false);
                        rootCommit.contractor.setContractorAssetsLoaded(false);
                        rootDispatch.contractor.loadContractorAssets();
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        async showCurrencyDialog(context, payload: CurrencyRestModel) {
            const { commit } = _moduleActionContext(context);
            commit.setCurrency(payload);
            commit.setShowCurrencyDialog(true);
        },
        async closeCurrencyDialog(context,) {
            const { commit } = _moduleActionContext(context);
            commit.setShowCurrencyDialog(false);
        },
        async loadPersonRoles(context) {
            const { commit, state } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/admin/register/person-roles")
                    .then((resp: AxiosResponse) => {
                        commit.setPersonRoles(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        async updatePersonRole(context) {
            const { commit, state, dispatch, rootCommit, rootDispatch } = _moduleActionContext(context);

            return new Promise((reject) => {
                axiosInstance
                    .post("/api/admin/register/person-roles/update", state.personRole)
                    .then(() => {
                        dispatch.loadPersonRoles();
                        commit.setShowPersonRoleDialog(false);
                        rootCommit.contractor.setContractorAssetsLoaded(false);
                        rootDispatch.contractor.loadContractorAssets();
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        async showPersonRoleDialog(context, payload: ListItemNumberModel) {
            const { commit } = _moduleActionContext(context);
            commit.setPersonRole(payload);
            commit.setShowPersonRoleDialog(true);
        },
        async closePersonRoleDialog(context,) {
            const { commit } = _moduleActionContext(context);
            commit.setShowPersonRoleDialog(false);
        },
        async updateSupervisorCarrierDocumentOrderRequirement(context) {
            const { commit, state, rootDispatch } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/admin/register/supervisor-carrier-document-order-requirement/update", state.supervisorCarrierDocumentOrderRequirement)
                    .then((resp: AxiosResponse) => {
                        rootDispatch.supervisor.getSupervisorCarrierDocumentOrderRequirementList({ forSupervisor: false });
                        commit.setShowSupervisorCarrierDocumentOrderRequirementDialog(false);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        async hideSupervisorCarrierDocumentOrderRequirement(context, payload: SupervisorCarrierDocumentOrderRequirementRestModel) {
            const { rootDispatch } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/admin/register/supervisor-carrier-document-order-requirement/hide", payload)
                    .then((resp: AxiosResponse) => {
                        rootDispatch.supervisor.getSupervisorCarrierDocumentOrderRequirementList({ forSupervisor: false });
                        resolve(resp.data)
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        async showSupervisorCarrierDocumentOrderRequirementDialog(context, payload: SupervisorCarrierDocumentOrderRequirementRestModel) {
            const { commit } = _moduleActionContext(context);
            commit.setSupervisorCarrierDocumentOrderRequirement(payload);
            commit.setShowSupervisorCarrierDocumentOrderRequirementDialog(true);
        },
        async closeSupervisorCarrierDocumentOrderRequirementDialog(context,) {
            const { commit } = _moduleActionContext(context);
            commit.setShowSupervisorCarrierDocumentOrderRequirementDialog(false);
        },
        async loadSafeLocationList(context) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/admin/register/safeLocationList")
                    .then((resp: AxiosResponse) => {
                        commit.setSafeLocationList(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        async updateSafeLocation(context) {
            const { commit, state, dispatch } = _moduleActionContext(context);

            return new Promise((reject) => {
                axiosInstance
                    .post("/api/admin/register/safeLocation/update", state.safeLocation)
                    .then(() => {
                        dispatch.loadSafeLocationList();
                        commit.setShowSafeLocationDialog(false);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        async deleteSafeLocation(context, payload: SafeLocationRestModel) {
            const { state, dispatch } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/admin/register/safeLocation/delete", payload)
                    .then(() => {
                        dispatch.loadSafeLocationList();
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        async showSafeLocationDialog(context, payload: SafeLocationRestModel) {
            const { commit } = _moduleActionContext(context);
            commit.setSafeLocation(payload);
            commit.setShowSafeLocationDialog(true);
        },
        async closeSafeLocationDialog(context,) {
            const { commit } = _moduleActionContext(context);
            commit.setShowSafeLocationDialog(false);
        },
        async loadTags(context) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/admin/register/tags")
                    .then((resp: AxiosResponse) => {
                        commit.setTags(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        async updateTag(context) {
            const { commit, state, dispatch } = _moduleActionContext(context);

            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/admin/register/tag/update", state.tag)
                    .then((resp: AxiosResponse) => {
                        dispatch.loadTags();
                        commit.setShowTagDialog(false);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        async deleteTag(context, payload: ListItemNumberModel) {
            const { dispatch } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/admin/register/tag/delete", payload)
                    .then((resp: AxiosResponse) => {
                        dispatch.loadTags();
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        async showTagDialog(context, payload: ListItemNumberModel) {
            const { commit } = _moduleActionContext(context);
            commit.setTag(payload);
            commit.setShowTagDialog(true);
        },
        async closeTagDialog(context,) {
            const { commit } = _moduleActionContext(context);
            commit.setShowTagDialog(false);
        },
    }
});

export default registerModule;
const _moduleActionContext = (context: any) => moduleActionContext(context, registerModule);
