import Vue from "vue";
import { Component } from "vue-property-decorator";
import ListItemNumberModel from "../../../models/listItemNumberModel";
import ContractorBankAccountRestModel from "../../../models/rest/contractor/contractorBankAccountRestModel";
import ContractorRestModel from "../../../models/rest/contractor/contractorRestModel";
import CountryRestModel from "../../../models/rest/register/countryRestModel";
import CurrencyRestModel from "../../../models/rest/register/currencyRestModel";

@Component
export default class BankAccountDialog extends Vue {
    rules = {
        accountNumber: [
            () => {
                const error = this.bankAccountValidationError.AccountNumber != undefined ? this.bankAccountValidationError.AccountNumber[0] : "";
                return error == "" || error;
            }],
        countryCode: [
            () => {
                const error = this.bankAccountValidationError.CountryCode != undefined ? this.bankAccountValidationError.CountryCode[0] : "";
                return error == "" || error;
            }],
        currencyCode: [
            () => {
                const error = this.bankAccountValidationError.CurrencyCode != undefined ? this.bankAccountValidationError.CurrencyCode[0] : "";
                return error == "" || error;
            }],
    };

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get bankAccountValidationError(): any {
        return this.$store.direct.state.contractor.bankAccountValidationError;
    }

    set bankAccountValidationError(val: any) {
        this.$store.direct.commit.contractor.setBankAccountValidationError(val);
    }

    get bankAccount(): ContractorBankAccountRestModel {
        return this.$store.direct.state.contractor.bankAccount;
    }

    get showDialog(): boolean {
        const showBankAccountDialog = this.$store.direct.state.contractor.showBankAccountDialog;

        if (showBankAccountDialog == true && this.bankAccount.countryCode == undefined) {
            this.bankAccount.countryCode = this.$store.direct.state.contractor.contractor.country;
        }

        return showBankAccountDialog;
    }

    get currencies(): CurrencyRestModel[] {
        return this.$store.direct.state.contractor.contractorAssets.currencies;
    }

    get countryList(): CountryRestModel[] {
        return this.$store.direct.state.contractor.contractorAssets.countries;
    }

    get bankAccountPurposeTypeList(): ListItemNumberModel[] {
        return this.$store.direct.state.contractor.bankAccountPurposeTypeList;
    }

    get contractor(): ContractorRestModel {
        return this.$store.direct.state.contractor.contractor;
    }

    async saveClick(): Promise<void> {
        this.bankAccount.contractorId = this.contractor.id;
        await this.$store.direct.dispatch.contractor.saveBankAccount();

        if (this.$refs.form != undefined) {
            const valid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
        }

        if (Object.keys(this.bankAccountValidationError).length === 0) {
            this.$store.direct.commit.contractor.setBankAccount({} as ContractorBankAccountRestModel);
            this.bankAccountValidationError = {} as any;
        }
    }
    closeClick(): void {
        this.$store.direct.dispatch.contractor.closeBankAccountDialog();
        this.$store.direct.commit.contractor.setBankAccount({} as ContractorBankAccountRestModel);
    }
}
