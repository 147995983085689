import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import VForm from "@/models/v-form";
import { stringMaxLength } from "../../../assets/validation/common";

@Component
export default class ForumDialog extends Vue {

    validation = {
        textArea: [stringMaxLength(this.$i18n, "", this.textLength), (value: string) => !!value || this.$t("common.validationErrorRequired")]
    }

    get textLength(): number {
        return this.$store.direct.state.forum.forumDialogTextLength;
    }

    get showDialog(): boolean {
        return this.$store.direct.state.forum.showForumDialog;
    }

    get title(): string {
        return this.$store.direct.state.forum.forumDialogTitle;
    }

    get fieldTitle(): string {
        return this.$store.direct.state.forum.forumDialogFieldTitle;
    }

    get content(): string {
        return this.$store.direct.state.forum.forumDialogContent;
    }

    set content(value: string) {
        this.$store.direct.commit.forum.setForumDialogContent(value);
    }

    async saveClick() {
        const valid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
        if (valid) {
            await this.$store.direct.dispatch.forum.isConfirmedForumDialog();
            await this.$store.direct.commit.forum.setForumDialogContent(this.content);
        }
    }

    async backClick() {
        await this.$store.direct.dispatch.forum.isNotConfirmedForumDialog();
    }
}
