import Vue from "vue";
import { Component } from "vue-property-decorator";
import CountryDialog from "./country-dialog.vue";
import CountryTaxRatesDialog from "./country-tax-rates-dialog.vue";
import CountryRestModel from "../../../../models/rest/register/countryRestModel";
import ConfirmDialog from "../../../../layouts/confirmDialog/confirmDialog.vue";

@Component({
    components: {
        "country-dialog": CountryDialog,
        "country-tax-rates-dialog": CountryTaxRatesDialog,
        "confirm-dialog": ConfirmDialog,
    }
})
export default class Admin extends Vue {
    searchText = "";

    get items(): CountryRestModel[] {
        return this.$store.direct.state.register.countries;
    }

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    itemsHeaders = [
        {
            text: this.$t("common.englishName"),
            value: "englishName"
        },
        {
            text: this.$t("common.polishName"),
            value: "polishName"
        },
        {
            text: this.$t("common.localName"),
            value: "localName"
        },
        {
            text: this.$t("common.code"),
            value: "code"
        },
        {
            text: this.$t("common.taxRates"),
            value: "taxRates",
            sortable: false,
        },
        {
            text: "UE",
            value: "isInUE",
            align: "center",
            sortable: false,
        },
        {
            text: this.$t("common.actions"),
            align: "right",
            value: "actions",
            width: "1%",
            sortable: false,
        }
    ];

    pagerOptions = {
        page: 1,
        itemsPerPage: 10
    };

    mounted() {
        this.loadCountries();
    }

    async setIsCountryInUE(item: CountryRestModel) {
        await this.$store.direct.dispatch.register.setIsCountryInUE({ countryCode: item.code, isInUE: !item.isInUE });
        this.loadCountries()
    }

    async editCountryTaxRates(item: CountryRestModel) {
        this.$store.direct.commit.register.setCountry(item);
        await this.$store.direct.dispatch.common.loadCountryTaxRateList();
        this.$store.direct.dispatch.register.showCountryTaxRatesDialog();
    }

    itemCreateClick() {
        this.$store.direct.dispatch.register.showCountryDialog({} as CountryRestModel);
    }

    loadCountries() {
        this.$store.direct.dispatch.register.loadCountries();
    }
}
