import Sortable from "sortablejs";

let sortable: Sortable;

const SortableTable = {
    bind(el: HTMLElement, binding: any, vnode: any) {
        const sortableElement = el.getElementsByTagName("tbody")[0];
        const options = {
            handle: ".sortHandle",
            animation: 150,
            onUpdate: function (event: any) {
                vnode.child.$emit("sorted", event);
            }
        };
        sortable = Sortable.create(sortableElement, options);
    },
};

export default SortableTable;