import { defineModule } from "direct-vuex";
import { moduleActionContext } from "@/store/index";
import { AxiosResponse, AxiosError } from "axios";
import { axiosInstance } from "../../plugins/axios";
import SupervisorIntegrationRestModel from "../../models/rest/supervisor/supervisorIntegrationRestModel";


export interface OnlineTrackingModuleStateInterface {
    integration: SupervisorIntegrationRestModel;
}

const onlineTrackingModule = defineModule({
    namespaced: true,
    state: {
        integration: {} as SupervisorIntegrationRestModel,
    } as OnlineTrackingModuleStateInterface,
    mutations: {
        setIntegration(state, value: SupervisorIntegrationRestModel) {
            state.integration = value;
        },
    },
    actions: {
        getIntegraionByGuid(context, payload) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/OnlineTracking/ByIntegrationGuid/" + payload)
                    .then((resp: AxiosResponse) => {
                        commit.setIntegration(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        }
    }
});

export default onlineTrackingModule;
const _moduleActionContext = (context: any) => moduleActionContext(context, onlineTrackingModule);
