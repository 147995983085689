import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Roles } from "../../../models/emums/roles";
import VForm from "../../../models/v-form";

@Component
export default class AttachmentDialog extends Vue {
    requiredRule = (value: string) => !!value || this.$t("common.validationErrorRequired");
    isCmr = false;

    validation = {
        files: [
            (files: any) => {
                return !files || !files.some((file: any) => file.size > 20_971_520) || 'Attachment size should be less than 20 MB!'
            }
        ],
    };

    get attachmentDialogAttachments(): any[] {
        return this.$store.direct.state.supervisor.attachmentDialogAttachments;
    }

    set attachmentDialogAttachments(value: any[]) {
        this.$store.direct.commit.supervisor.setAttachmentDialogAttachments(value);
    }

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get showDialog(): boolean {
        return this.$store.direct.state.supervisor.showAttachmentDialog;
    }

    get currentUserRoles() {
        return this.$store.direct.state.auth.userRoles;
    }

    showIsCmr(): boolean {
        if (this.currentUserRoles.includes(Roles.Admin) || this.currentUserRoles.includes(Roles.AccountantAdmin) || this.currentUserRoles.includes(Roles.Accountant)) {
            return true;
        }
        else {
            return false
        }
    }

    updated() {
        this.$nextTick(function () {
            if (this.$refs.form) (this.$refs.form as VForm).resetValidation();
        });
    }

    async saveClick(): Promise<void> {
        if ((this.$refs.form as VForm).validate()) {
            await this.$store.direct.dispatch.supervisor.saveAttachments({ files: this.attachmentDialogAttachments, isCmr: this.isCmr });
            await this.$store.direct.dispatch.supervisor.isConfirmedAttachmentDialog();
        }
    }

    closeClick(): void {
        this.$store.direct.dispatch.supervisor.isNotConfirmedAttachmentDialog();
    }
}
