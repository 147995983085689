import dayjs from "dayjs";
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import i18n from "../../../assets/i18n";
import ConfirmDialog from "../../../layouts/confirmDialog/confirmDialog.vue";
import { BranchEnum } from "../../../models/emums/branchEnum";
import ListItemNumberModel from "../../../models/listItemNumberModel";
import ContractorAssetsRestModel from "../../../models/rest/contractor/contractorAssetsRestModel";
import ContractorBankAccountRestModel from "../../../models/rest/contractor/contractorBankAccountRestModel";
import ContractorDetailsForSettlementRestModel from "../../../models/rest/contractor/contractorDetailsForSettlementRestModel";
import ContractorSearchItemModel from "../../../models/rest/contractor/contractorSearchItemModel";
import CurrencyRestModel from "../../../models/rest/register/currencyRestModel";
import SettlementCostDocumentTransferOrderRestModel from "../../../models/rest/settlement/cost-document/transfer-order/settlementCostDocumentTransferOrderRestModel";
import SettlementAssetRestModel from "../../../models/rest/settlement/settlementAssetRestModel";
import router from "../../../router";

@Component({
    components: {
        "confirm-dialog": ConfirmDialog
    }
})

export default class CostDocumentTransferOrder extends Vue {
    isTransferOrderNew = false;
    recipientSearch = "";
    senderSearch = "";
    transferDate: string | undefined = "";
    transferOrderDateLabel = this.$t("transferOrder.date")

    rules = {
        branchId: [
            () => {
                const error = this.validationError.BranchId != undefined ? this.validationError.BranchId[0] : "";
                return error == "" || error;
            }],
        transferDate: [
            () => {
                const error = this.validationError.TransferDate != undefined ? this.validationError.TransferDate[0] : "";
                return error == "" || error;
            }],
        transferAmount: [
            () => {
                const error = this.validationError.TransferAmount != undefined ? this.validationError.TransferAmount[0] : "";
                return error == "" || error;
            }],
        transferCurrencyCode: [
            () => {
                const error = this.validationError.TransferCurrencyCode != undefined ? this.validationError.TransferCurrencyCode[0] : "";
                return error == "" || error;
            }],
        transferTitle: [
            () => {
                const error = this.validationError.TransferTitle != undefined ? this.validationError.TransferTitle[0] : "";
                return error == "" || error;
            }],
        sender: [
            () => {
                const error = this.validationError.Sender != undefined ? this.validationError.Sender[0] : "";
                return error == "" || error;
            },
            () => {
                const error = this.validationError.SenderName != undefined ? this.validationError.SenderName[0] : "";
                return error == "" || error;
            }],
        senderCountryCode: [
            () => {
                const error = this.validationError.SenderCountryCode != undefined ? this.validationError.SenderCountryCode[0] : "";
                return error == "" || error;
            }],
        senderCity: [
            () => {
                const error = this.validationError.SenderCity != undefined ? this.validationError.SenderCity[0] : "";
                return error == "" || error;
            }],
        senderPostalCode: [
            () => {
                const error = this.validationError.SenderPostalCode != undefined ? this.validationError.SenderPostalCode[0] : "";
                return error == "" || error;
            }],
        senderStreetAndNumber: [
            () => {
                const error = this.validationError.SenderStreetAndNumber != undefined ? this.validationError.SenderStreetAndNumber[0] : "";
                return error == "" || error;
            }],
        senderBankAccountNumber: [
            () => {
                const error = this.validationError.SenderBankAccountNumber != undefined ? this.validationError.SenderBankAccountNumber[0] : "";
                return error == "" || error;
            }],
        senderBankAccountCurrencyCode: [
            () => {
                const error = this.validationError.SenderBankAccountCurrencyCode != undefined ? this.validationError.SenderBankAccountCurrencyCode[0] : "";
                return error == "" || error;
            }],
        recipient: [
            () => {
                const error = this.validationError.Recipient != undefined ? this.validationError.Recipient[0] : "";
                return error == "" || error;
            },
            () => {
                const error = this.validationError.RecipientName != undefined ? this.validationError.RecipientName[0] : "";
                return error == "" || error;
            }],
        recipientCountryCode: [
            () => {
                const error = this.validationError.RecipientCountryCode != undefined ? this.validationError.RecipientCountryCode[0] : "";
                return error == "" || error;
            }],
        recipientCity: [
            () => {
                const error = this.validationError.RecipientCity != undefined ? this.validationError.RecipientCity[0] : "";
                return error == "" || error;
            }],
        recipientPostalCode: [
            () => {
                const error = this.validationError.RecipientPostalCode != undefined ? this.validationError.RecipientPostalCode[0] : "";
                return error == "" || error;
            }],
        recipientStreetAndNumber: [
            () => {
                const error = this.validationError.RecipientStreetAndNumber != undefined ? this.validationError.RecipientStreetAndNumber[0] : "";
                return error == "" || error;
            }],
        recipientBankAccountNumber: [
            () => {
                const error = this.validationError.RecipientBankAccountNumber != undefined ? this.validationError.RecipientBankAccountNumber[0] : "";
                return error == "" || error;
            }],
        recipientBankAccountCurrencyCode: [
            () => {
                const error = this.validationError.RecipientBankAccountCurrencyCode != undefined ? this.validationError.RecipientBankAccountCurrencyCode[0] : "";
                return error == "" || error;
            }],
    }

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get validationError(): any {
        return this.$store.direct.state.settlementCostDocumentTransferOrder.transferOrderValidationError;
    }

    set validationError(val: any) {
        this.$store.direct.commit.settlementCostDocumentTransferOrder.setTransferOrderValidationError(val);
    }

    get transferOrder(): SettlementCostDocumentTransferOrderRestModel {
        const transferOrder = this.$store.direct.state.settlementCostDocumentTransferOrder.transferOrder;

        if (transferOrder.sender != undefined) {
            this.senderFound.push(transferOrder.sender);
        }

        if (transferOrder.recipient != undefined) {
            this.recipientFound.push(transferOrder.recipient);
        }

        if (transferOrder.transferDate && transferOrder.transferDate != null && transferOrder.transferDate != undefined) {
            const formattedTransferDate = this.formatDateTime(transferOrder.transferDate);
            this.transferDate = formattedTransferDate
        }


        return transferOrder;
    }

    set transferOrder(val: SettlementCostDocumentTransferOrderRestModel) {
        this.$store.direct.commit.settlementCostDocumentTransferOrder.setTransferOrder(val);
    }

    get settlementAssets(): SettlementAssetRestModel {
        return this.$store.direct.state.settlementCommon.settlementAssets;
    }

    get transferSystemTypes(): ListItemNumberModel[] {
        const transferSystemTypes = this.settlementAssets.transferSystemTypes;
        if (transferSystemTypes == undefined) {
            this.$store.direct.dispatch.settlementCommon.loadSettlementAssets();
        }
        else {
            transferSystemTypes.push({ text: i18n.t("transferOrder.undefined"), value: 0 } as ListItemNumberModel)
        }

        return transferSystemTypes;
    }

    get senderFound(): ContractorSearchItemModel[] {
        return this.$store.direct.state.settlementCostDocumentTransferOrder.contractorSenderFound;
    }

    set senderFound(val: ContractorSearchItemModel[]) {
        this.$store.direct.commit.settlementCostDocumentTransferOrder.setContractorSenderFound(val);
    }

    get recipientFound(): ContractorSearchItemModel[] {
        return this.$store.direct.state.settlementCostDocumentTransferOrder.contractorRecipientFound;
    }

    set recipientFound(val: ContractorSearchItemModel[]) {
        this.$store.direct.commit.settlementCostDocumentTransferOrder.setContractorRecipientFound(val);
    }

    get contractorAssets(): ContractorAssetsRestModel {
        return this.$store.direct.state.contractor.contractorAssets;
    }

    get contractorDetailsForSettlement(): ContractorDetailsForSettlementRestModel {
        const contractorDetailsForSettlement = this.$store.direct.state.contractor.contractorDetailsForSettlement;
        this.showAccountingInfoDialog(contractorDetailsForSettlement);

        return contractorDetailsForSettlement;
    }

    set contractorDetailsForSettlement(val: ContractorDetailsForSettlementRestModel) {
        this.$store.direct.commit.contractor.setContractorDetailsForSettlement(val);
    }

    get currencies(): CurrencyRestModel[] {
        return this.$store.direct.state.contractor.contractorAssets.currencies;
    }

    get contractorBankAccounts(): ContractorBankAccountRestModel[] {
        return this.$store.direct.state.contractor.bankAccountList;
    }

    set contractorBankAccounts(val: ContractorBankAccountRestModel[]) {
        this.$store.direct.commit.contractor.setBankAccountList(val);
    }

    @Watch("senderSearch")
    async senderSearchLoadItems(val: string) {
        if (this.senderSearch) {
            await this.$store.direct.dispatch.common.searchContractors({ searchText: val, contractorTypes: ["Sender"], contractorPropertiesEnabled: [], source: "settlementTransfer" });
        }
    }

    @Watch("recipientSearch")
    async recipientSearchLoadItems(val: string) {
        if (this.recipientSearch) {
            await this.$store.direct.dispatch.common.searchContractors({ searchText: val, contractorTypes: ["Recipient"], contractorPropertiesEnabled: [], source: "settlementTransfer" });
        }
    }

    async created() {
        await this.$store.direct.dispatch.contractor.loadContractorAssets();
        await this.$store.direct.dispatch.settlementCommon.loadSettlementAssets();

        if (this.$route.params.id == undefined) {
            this.isTransferOrderNew = true;
            this.transferDate = dayjs().format("YYYY-MM-DD");
        }
    }

    branchChanged() {
        if (this.transferOrder.branchId == BranchEnum.DE) {
            this.transferOrder.transferSystemTypeId = 1;
        }
        else if (this.transferOrder.branchId == BranchEnum.PL) {
            this.transferOrder.transferSystemTypeId = 2;
        }
        else {
            this.transferOrder.transferSystemTypeId = null;
        }
    }

    formatDateTime(dateTime: any) {
        if (dateTime != null) {
            return dayjs(dateTime).format("YYYY-MM-DD");
        }
    }

    allowNumbersOnly(evt: any) {
        return this.$store.direct.dispatch.settlementCommon.allowNumbersOnly(evt);
    }

    changeCommaToDot(val: any) {
        this.transferOrder.transferAmount = parseFloat(val.toString().replace(",", "."));
    }

    showAccountingInfoDialog(contractorDetailsForSettlement: ContractorDetailsForSettlementRestModel) {
        if (contractorDetailsForSettlement.accountingInfo != undefined) {
            this.$store.direct.dispatch.confirm
                .openConfirmDialog({
                    showConfirmDialog: true,
                    title: `${this.$t("contractor.accountingInfo")}` + ": " + contractorDetailsForSettlement.name,
                    message:  contractorDetailsForSettlement.accountingInfo,
                    links: [],
                    options: { buttonColor: "error", layoutColor: "red" },
                    buttonType: "ok"
                });
        }
    }

    async getContractorBankAccountList(contractorType: string) {
        this.contractorBankAccounts = [] as ContractorBankAccountRestModel[];
        if (contractorType == "sender" && this.transferOrder.sender?.value != undefined) {
            await this.$store.direct.dispatch.contractor.loadContractorBankAccountList({ contractorId: this.transferOrder.sender.value });
        }
        if (contractorType == "recipient" && this.transferOrder.recipient?.value != undefined) {
            await this.$store.direct.dispatch.contractor.loadContractorBankAccountList({ contractorId: this.transferOrder.recipient.value });
        }
    }

    selectedContractorSenderAccountNumberChanged(item: ContractorBankAccountRestModel) {
        const contractorBankAccountFound = this.contractorBankAccounts.find(x => x.accountNumber == item.accountNumber);
        if (contractorBankAccountFound != undefined) {
            this.transferOrder.senderBankAccountCurrencyCode = contractorBankAccountFound.currencyCode
        }
    }

    selectedContractorRecipientAccountNumberChanged(item: ContractorBankAccountRestModel) {
        const contractorBankAccountFound = this.contractorBankAccounts.find(x => x.accountNumber == item.accountNumber);
        if (contractorBankAccountFound != undefined) {
            this.transferOrder.recipientBankAccountCurrencyCode = contractorBankAccountFound.currencyCode
        }
    }

    async selectedContractorSenderChanged(transferOrder: SettlementCostDocumentTransferOrderRestModel | undefined = undefined) {
        if (transferOrder == undefined) {
            transferOrder = this.transferOrder;
        }

        if (transferOrder.sender != null) {
            const senderId = transferOrder.sender.value;

            await this.$store.direct.dispatch.contractor.getContractorDetailsForSettlement({ contractorId: senderId });

            transferOrder.senderStreetAndNumber = this.contractorDetailsForSettlement.streetAndNumber;
            transferOrder.senderCountryCode = this.contractorDetailsForSettlement.country;
            transferOrder.senderPostalCode = this.contractorDetailsForSettlement.postalCode;
            transferOrder.senderCity = this.contractorDetailsForSettlement.city;
        }
        this.$forceUpdate();
    }

    async selectedContractorRecipientChanged(transferOrder: SettlementCostDocumentTransferOrderRestModel | undefined = undefined) {
        if (transferOrder == undefined) {
            transferOrder = this.transferOrder;
        }

        if (transferOrder.recipient != null) {
            const recipientId = transferOrder.recipient.value;

            await this.$store.direct.dispatch.contractor.getContractorDetailsForSettlement({ contractorId: recipientId });

            transferOrder.recipientStreetAndNumber = this.contractorDetailsForSettlement.streetAndNumber;
            transferOrder.recipientCountryCode = this.contractorDetailsForSettlement.country;
            transferOrder.recipientPostalCode = this.contractorDetailsForSettlement.postalCode;
            transferOrder.recipientCity = this.contractorDetailsForSettlement.city;
        }
        this.$forceUpdate();
    }

    cancelClick() {
        router.push({ path: "/settlement/transfer-order/list" });
    }

    async saveClick() {
        if (this.transferOrder.sender != undefined) {
            this.transferOrder.senderName = this.transferOrder.sender.text;
        }
        if (this.transferOrder.recipient != undefined) {
            this.transferOrder.recipientName = this.transferOrder.recipient.text;
        }

        if (this.transferOrder.senderBankAccountNumber && typeof this.transferOrder.senderBankAccountNumber != "string") {
            const senderBankAccountNumber = this.transferOrder.senderBankAccountNumber as ContractorBankAccountRestModel;
            this.transferOrder.senderBankAccountNumber = senderBankAccountNumber.accountNumber;
        }

        if (this.transferOrder.recipientBankAccountNumber && typeof this.transferOrder.recipientBankAccountNumber != "string") {
            const recipientBankAccountNumber = this.transferOrder.recipientBankAccountNumber as ContractorBankAccountRestModel;
            this.transferOrder.recipientBankAccountNumber = recipientBankAccountNumber.accountNumber;
        }

        this.transferOrder.transferDate = this.transferDate as string;
        this.transferOrder.transferSystemTypeId = this.transferOrder.transferSystemTypeId == 0 ? null : this.transferOrder.transferSystemTypeId;

        await this.$store.direct.dispatch.settlementCostDocumentTransferOrder.saveTransferOrder();

        if (this.$refs.form != undefined) {
            const valid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
        }

        if (Object.keys(this.validationError).length === 0) {
            this.transferOrder = {} as SettlementCostDocumentTransferOrderRestModel;
            router.push({ path: "/settlement/transfer-order/list" });
        }
    }

    destroyed() {
        this.transferOrder = {} as SettlementCostDocumentTransferOrderRestModel;
        this.contractorBankAccounts = [] as ContractorBankAccountRestModel[]
    }
}
