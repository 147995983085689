import { RouteConfig } from "vue-router";

const routes: Array<RouteConfig> = [
  {
    path: "/dashboards",
    component: require("@/layouts/layout.vue").default,
    children: [
      {
        path: "",
        name: "Dashboards",
            component: require("@/components/dashboard/dashboard.vue").default
      }
    ]
  }
];

export default routes;
