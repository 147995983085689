import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { Loader } from "@googlemaps/js-api-loader";
import ContractorLocationRestModel from "../../models/rest/contractor/contractorLocationRestModel";

@Component
export default class LocationsMap extends Vue {
    markers: any = [];
    map: google.maps.Map | undefined;
    infowindow: google.maps.InfoWindow | undefined;

    loader = new Loader({
        apiKey: this.googleMapsApiKey,
        libraries: ["places"]
    });

    get googleMapsApiKey(): string {
        return this.$store.direct.state.auth.googleMapsApiKey;
    }

    get contractorLocationDetails(): ContractorLocationRestModel {
        return this.$store.direct.state.contractor.contractorsLocationDetails;
    }

    get contractorsLocationList(): ContractorLocationRestModel[] {
        return this.$store.direct.state.contractor.contractorsLocationList;
    }

    get searchContractorTypeList(): any {
        return this.$store.direct.state.contractor.contractorListSearchContractorTypeList;
    }

    set searchContractorTypeList(value: any) {
        this.$store.direct.commit.contractor.setContractorListSearchContractorTypeList(value);
    }

    get contractorTypeList(): any[] {
        return this.$store.direct.state.contractor.contractorTypeList;
    }

    async created() {
        await this.loadContractorsLocationList();
    }

    @Watch("contractorsLocationList")
    async googleMapsHandles() {
        await this.loadMap();
    }

    getMap(callback: (arg0: google.maps.Map<Element>) => void): void {
        const self = this;
        function checkForMap(): void {
            if (self.map) callback(self.map);
            else setTimeout(checkForMap, 200);
        }
        checkForMap();
    }

    async loadMap() {
        await this.loader.load().then(() => {
            this.map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
                center: {
                    lat: 50,
                    lng: 15
                },
                zoom: 5,
                mapTypeControl: true
            });

            this.infowindow = new google.maps.InfoWindow();

            this.contractorsLocationList.forEach((c) => {
                const marker = new google.maps.Marker({
                    position: { lat: c.lat as number, lng: c.lng as number },
                    map: this.map,
                    title: c.name,
                });

                marker.addListener("click", async () => {
                    await this.$store.direct.dispatch.contractor.loadContractorLocationDetails({id: c.contractorId});

                    const contentString = `<div>` +
                        '<table>' +
                        `<tr><td><b>${this.$t('contractor.name')}: </b></td><td><b>` + this.contractorLocationDetails.name + '</b></td></tr>' +
                        `<tr><td><b>${this.$t('contractor.address')}: </b></td><td>` + this.contractorLocationDetails.cityAndPostalCode + '</td></tr>' +
                        `<tr><td><b>${this.$t('common.createdBy')}: </b></td><td>` + this.contractorLocationDetails.createdByUserName + '</td></tr>' +
                        `<tr><td><b>${this.$t('contractor.contactPerson')}: </b></td><td>` + this.contractorLocationDetails.contactPerson + '</td></tr>' +
                        `<tr><td><b>${this.$t('contractor.telephone')}: </b></td><td>` + this.contractorLocationDetails.telephone + '</td></tr>' +
                        `<tr><td><b>${this.$t('contractor.comments')}: </b></td><td>` + this.contractorLocationDetails.comments + '</td></tr>' +
                        '</table>' +
                        '</div>';
                    this.infowindow?.setContent(contentString);
                    this.infowindow?.open(marker.get("map"), marker);
                });
            })
        })
    }

    searchClick() {
        this.loadContractorsLocationList();
    }

    async loadContractorsLocationList() {
        await this.$store.direct.dispatch.contractor.loadContractorsLocationList({ searchContractorTypeList: this.searchContractorTypeList });
    }
}
