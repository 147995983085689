import { moduleActionContext } from "@/store/index";
import { AxiosError, AxiosResponse } from "axios";
import { defineModule } from "direct-vuex";
import CurrencyRateRestModel from "../../models/rest/register/currencyRateRestModel";
import SettlementCostDocumentPaymentObligationListItemRestModel from "../../models/rest/settlement/cost-document/payment-obligation/settlementCostDocumentPaymentObligationListItemRestModel";
import SettlementCostDocumentCorrectionListItemRestModel from "../../models/rest/settlement/cost-document/settlementCostDocumentCorrectionListItemRestModel";
import SettlementCostDocumentItemRestModel from "../../models/rest/settlement/cost-document/settlementCostDocumentItemRestModel";
import SettlementCostDocumentListItemRestModel from "../../models/rest/settlement/cost-document/settlementCostDocumentListItemRestModel";
import SettlementCostDocumentTransferOrderPackageRestModel from "../../models/rest/settlement/cost-document/transfer-order-package/settlementCostDocumentTransferOrderPackageRestModel";
import SettlementCostDocumentTransferOrderRestModel from "../../models/rest/settlement/cost-document/transfer-order/settlementCostDocumentTransferOrderRestModel";
import SettlementIncomeDocumentPaymentObligationListItemRestModel from "../../models/rest/settlement/income-document/payment-obligation/settlementIncomeDocumentPaymentObligationListItemRestModel";
import SettlementIncomeDocumentCorrectionListItemRestModel from "../../models/rest/settlement/income-document/settlementIncomeDocumentCorrectionListItemRestModel";
import SettlementIncomeDocumentItemRestModel from "../../models/rest/settlement/income-document/settlementIncomeDocumentItemRestModel";
import SettlementIncomeDocumentListItemRestModel from "../../models/rest/settlement/income-document/settlementIncomeDocumentListItemRestModel";
import SettlementAmountSummaryRestModel from "../../models/rest/settlement/settlementAmountSummaryRestModel";
import SettlementAssetRestModel from "../../models/rest/settlement/settlementAssetRestModel";
import SettlementClosedMonthRestModel from "../../models/rest/settlement/settlementClosedMonthRestModel";
import SupervisorForSettlementRestModel from "../../models/rest/supervisor/supervisorForSettlementRestModel";
import { axiosInstance } from "../../plugins/axios";

export interface SettlementCommonModuleStateInterface {
    settlementAssets: SettlementAssetRestModel,
    settlementAssetsLoaded: boolean,
    currencyRate: CurrencyRateRestModel,
    showAssignSupervisorDialog: boolean,
    assignSupervisorDialogTitle: string,
    assignSupervisorDialogResolve: any;
    supervisorList: SupervisorForSettlementRestModel[],
    closedMonth: SettlementClosedMonthRestModel,
    closedMonthList: SettlementClosedMonthRestModel[],
    closedMonthListLoading: boolean;
    closedMonthListTotalCount: number,
    closedMonthListPagerOptions: any,
    showClosedMonthDialog: boolean,
    closedMonthDialogTitle: string,
    closedMonthDialogResolve: any,
    showRegisterExportDialog: boolean,
    registerExportDialogTitle: string,
    registerExportDialogRegisterType: string,
    registerExportDialogResolve: any,
    footerPropsForSettlement: any,
    showCreatePaymentOutsideTheSystemDialog: boolean,
    createPaymentOutsideTheSystemDialogComment: string,
    createPaymentOutsideTheSystemDialogPaymentDate: string,
    createPaymentOutsideTheSystemDialogTitle: string,
    createPaymentOutsideTheSystemDialogResolve: any,
    createPaymentOutsideTheSystemDialogSource: string,
    calculatedSelectedListForAmountSummaryRow: SettlementAmountSummaryRestModel[];
    previousUrlName: string | null | undefined;
    allowSelectionOfAllItemsOnPageWithCheckbox: boolean;
    registerExportListLoading: boolean;
    outstandingRegisterExportListLoading: boolean;
    openOutstandingRegisterExportDialog: boolean;
    showOutstandingRegisterExportDialog: boolean,
    outstandingRegisterExportDialogResolve: any,
    searchAccountantIdList: any;
}

const settlementCommonModule = defineModule({
    namespaced: true,
    state: {
        settlementAssets: {} as SettlementAssetRestModel,
        settlementAssetsLoaded: false,
        currencyRate: {} as CurrencyRateRestModel,
        showAssignSupervisorDialog: false,
        assignSupervisorDialogTitle: "",
        assignSupervisorDialogResolve: null,
        supervisorList: [] as SupervisorForSettlementRestModel[],
        closedMonth: {} as SettlementClosedMonthRestModel,
        closedMonthList: [] as SettlementClosedMonthRestModel[],
        closedMonthListLoading: false,
        closedMonthListTotalCount: 0,
        closedMonthListPagerOptions: {
            page: 1,
            itemsPerPage: 30,
            sortBy: ["id"],
            sortDesc: [false]
        },
        showClosedMonthDialog: false,
        closedMonthDialogTitle: "",
        closedMonthDialogResolve: null,
        showRegisterExportDialog: false,
        registerExportDialogTitle: "",
        registerExportDialogRegisterType: "",
        registerExportDialogResolve: null,
        footerPropsForSettlement: {
            'items-per-page-options': [30, 100, 500]
        },
        showCreatePaymentOutsideTheSystemDialog: false,
        createPaymentOutsideTheSystemDialogComment: "",
        createPaymentOutsideTheSystemDialogPaymentDate: "",
        createPaymentOutsideTheSystemDialogTitle: "",
        createPaymentOutsideTheSystemDialogResolve: null,
        createPaymentOutsideTheSystemDialogSource: "",
        calculatedSelectedListForAmountSummaryRow: [] as SettlementAmountSummaryRestModel[],
        previousUrlName: null,
        allowSelectionOfAllItemsOnPageWithCheckbox: false,
        registerExportListLoading: false,
        outstandingRegisterExportListLoading: false,
        openOutstandingRegisterExportDialog: false,
        showOutstandingRegisterExportDialog: false,
        outstandingRegisterExportDialogResolve: null,
        searchAccountantIdList: [],
    } as SettlementCommonModuleStateInterface,
    mutations: {
        setSettlementAssets(state, value: SettlementAssetRestModel) {
            state.settlementAssets = value;
            state.settlementAssetsLoaded = true;
        },
        setCurrencyRate(state, value: CurrencyRateRestModel) {
            state.currencyRate = value;
        },
        setSettlementAssetsLoaded(state, value: boolean) {
            state.settlementAssetsLoaded = value;
        },
        setShowAssignSupervisorDialog(state, value: boolean) {
            state.showAssignSupervisorDialog = value;
        },
        setAssignSupervisorDialogTitle(state, value: string) {
            state.assignSupervisorDialogTitle = value;
        },
        setAssignSupervisorDialogResolve(state, value: any) {
            state.assignSupervisorDialogResolve = value;
        },
        setSupervisorList(state, value: SupervisorForSettlementRestModel[]) {
            state.supervisorList = value;
        },
        setClosedMonth(state, value: SettlementClosedMonthRestModel) {
            state.closedMonth = value;
        },
        setClosedMonthList(state, value: SettlementClosedMonthRestModel[]) {
            state.closedMonthList = value;
        },
        setClosedMonthListLoading(state, value: boolean) {
            state.closedMonthListLoading = value;
        },
        setClosedMonthListTotalCount(state, value: number) {
            state.closedMonthListTotalCount = value;
            if ((state.closedMonthListPagerOptions.page * state.closedMonthListPagerOptions.itemsPerPage) >= (value + state.closedMonthListPagerOptions.itemsPerPage)) {
                state.closedMonthListPagerOptions.page = 1;
            }
        },
        setClosedMonthListPagerOptions(state, value) {
            state.closedMonthListPagerOptions = value;
        },
        setShowClosedMonthDialog(state, value: boolean) {
            state.showClosedMonthDialog = value;
        },
        setClosedMonthDialogTitle(state, value: string) {
            state.closedMonthDialogTitle = value;
        },
        setClosedMonthDialogResolve(state, value: any) {
            state.closedMonthDialogResolve = value;
        },
        setShowRegisterExportDialog(state, value: boolean) {
            state.showRegisterExportDialog = value;
        },
        setRegisterExportDialogTitle(state, value: string) {
            state.registerExportDialogTitle = value;
        },
        setRegisterExportDialogRegisterType(state, value: string) {
            state.registerExportDialogRegisterType = value;
        },
        setRegisterExportDialogResolve(state, value: any) {
            state.registerExportDialogResolve = value;
        },
        setShowCreatePaymentOutsideTheSystemDialog(state, value: boolean) {
            state.showCreatePaymentOutsideTheSystemDialog = value;
        },
        setCreatePaymentOutsideTheSystemDialogComment(state, value: string) {
            state.createPaymentOutsideTheSystemDialogComment = value;
        },
        setCreatePaymentOutsideTheSystemDialogPaymentDate(state, value: string) {
            state.createPaymentOutsideTheSystemDialogPaymentDate = value;
        },
        setCreatePaymentOutsideTheSystemDialogTitle(state, value: string) {
            state.createPaymentOutsideTheSystemDialogTitle = value;
        },
        setCreatePaymentOutsideTheSystemDialogResolve(state, value: any) {
            state.createPaymentOutsideTheSystemDialogResolve = value;
        },
        setCreatePaymentOutsideTheSystemDialogSource(state, value: string) {
            state.createPaymentOutsideTheSystemDialogSource = value;
        },
        setCalculatedSelectedListForAmountSummaryRow(state, value: SettlementAmountSummaryRestModel[]) {
            state.calculatedSelectedListForAmountSummaryRow = value;
        },
        setPreviousUrlName(state, value: string | null | undefined) {
            state.previousUrlName = value;
        },
        setAllowSelectionOfAllItemsOnPageWithCheckbox(state, value: boolean) {
            state.allowSelectionOfAllItemsOnPageWithCheckbox = value;
        },
        setRegisterExportListLoading(state, value: boolean) {
            state.registerExportListLoading = value;
        },
        setOutstandingRegisterExportListLoading(state, value: boolean) {
            state.openOutstandingRegisterExportDialog = value;
        },
        setShowOutstandingRegisterExportDialog(state, value: boolean) {
            state.showOutstandingRegisterExportDialog = value;
        },
        setOutstandingRegisterExportDialogResolve(state, value: any) {
            state.outstandingRegisterExportDialogResolve = value;
        },
        setSearchAccountantIdList(state, value: string[]) {
            state.searchAccountantIdList = value;
        },
    },
    actions: {
        loadSettlementAssets(context) {
            const { commit, state } = _moduleActionContext(context);
            if (state.settlementAssetsLoaded) {
                return new Promise(resolve => {
                    resolve(state.settlementAssets);
                });
            }
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/settlementCommon/assets")
                    .then((resp: AxiosResponse) => {
                        commit.setSettlementAssets(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        async getCurrencyRate(context, payload: { currencyCode: string, date: string }) {
            const { commit } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                const params = {
                    currencyCode: payload.currencyCode,
                    date: payload.date
                };
                axiosInstance
                    .get("/api/settlementCommon/get-currency-rate", { params })
                    .then((resp: AxiosResponse) => {
                        commit.setCurrencyRate(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        openAssignSupervisorDialog(context, payload: { title: string; }) {
            const { commit } = _moduleActionContext(context);
            commit.setShowAssignSupervisorDialog(true);
            commit.setAssignSupervisorDialogTitle(payload.title);
            return new Promise(resolve => {
                commit.setAssignSupervisorDialogResolve(resolve);
            });
        },
        isConfirmedAssignSupervisorDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.assignSupervisorDialogResolve(true);
            commit.setShowAssignSupervisorDialog(false);
        },
        isNotConfirmedAssignSupervisorDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.assignSupervisorDialogResolve(false);
            commit.setShowAssignSupervisorDialog(false);
        },
        allowNumbersOnly(context, payload: { evt: any }) {
            const { state } = _moduleActionContext(context);
            let evt = payload.evt;
            evt = (evt) ? evt : window.event;
            const charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode !== 44) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        roundValueToTwoPlaces(context, value: number) {
            return Math.round(value * 100) / 100;
        },
        async calculateNettoValue(context, payload: { item: SettlementCostDocumentItemRestModel | SettlementIncomeDocumentItemRestModel, discount: number | undefined, parentComponentName: string }) {
            const { rootState, dispatch } = _moduleActionContext(context);
            if (payload.discount == undefined) {
                if (payload.parentComponentName === "income-document") {
                    payload.discount = rootState.settlementIncomeDocument.incomeDocument.discount;
                }
                else {
                    payload.discount = rootState.settlementCostDocument.costDocument.discount;
                }
            }

            if (payload.item.price != undefined) {
                payload.item.netto = await dispatch.roundValueToTwoPlaces((payload.item.price - (payload.item.price * payload.discount / 100)) * payload.item.quantity);
            }
        },
        async calculateBruttoValue(context, payload: { item: SettlementCostDocumentItemRestModel | SettlementIncomeDocumentItemRestModel }) {
            const { dispatch } = _moduleActionContext(context);
            if (payload.item.netto != undefined && payload.item.taxRate != undefined) {
                payload.item.brutto = await dispatch.roundValueToTwoPlaces(payload.item.netto + (payload.item.netto * payload.item.taxRate / 100));
            }
        },
        async calculateTaxValue(context, payload: { item: SettlementCostDocumentItemRestModel | SettlementIncomeDocumentItemRestModel }) {
            const { dispatch } = _moduleActionContext(context);
            if (payload.item.netto != undefined && payload.item.taxRate != undefined) {
                payload.item.tax = await dispatch.roundValueToTwoPlaces(payload.item.netto * payload.item.taxRate / 100);
            }
        },
        async calculateNettoBruttoAndTaxValue(context, payload: { item: SettlementCostDocumentItemRestModel | SettlementIncomeDocumentItemRestModel, parentComponentName: string }) {
            const { dispatch } = _moduleActionContext(context);
            await dispatch.calculateNettoValue({ item: payload.item, discount: undefined, parentComponentName: payload.parentComponentName });
            await dispatch.calculateBruttoValue({ item: payload.item });
            await dispatch.calculateTaxValue({ item: payload.item });
        },
        async calculateNettoValueInPln(context, payload: { item: SettlementCostDocumentItemRestModel | SettlementIncomeDocumentItemRestModel, discount: number | undefined, parentComponentName: string }) {
            const { rootState, dispatch } = _moduleActionContext(context);
            if (payload.discount == undefined) {
                if (payload.parentComponentName === "income-document") {
                    payload.discount = rootState.settlementIncomeDocument.incomeDocument.discount;
                }
                else if (payload.parentComponentName === "cost-document") {
                    payload.discount = rootState.settlementCostDocument.costDocument.discount;
                }
            }
            else {
                payload.discount = 0;
            }

            if (payload.item.pricePln != undefined && payload.discount != undefined) {
                payload.item.nettoPln = await dispatch.roundValueToTwoPlaces((payload.item.pricePln - (payload.item.pricePln * payload.discount / 100)) * payload.item.quantity);
            }
        },
        async calculateBruttoValueInPln(context, payload: { item: SettlementCostDocumentItemRestModel | SettlementIncomeDocumentItemRestModel }) {
            const { dispatch } = _moduleActionContext(context);
            if (payload.item.nettoPln != undefined && payload.item.taxRate != undefined) {
                payload.item.bruttoPln = await dispatch.roundValueToTwoPlaces(payload.item.nettoPln + payload.item.taxPln);
            }
        },
        async calculateTaxValueInPln(context, payload: { item: SettlementCostDocumentItemRestModel | SettlementIncomeDocumentItemRestModel, parentComponentName: string }) {
            const { state, dispatch } = _moduleActionContext(context);
            if (payload.item.tax != undefined && state.currencyRate.rate != undefined) {
                payload.item.taxPln = await dispatch.roundValueToTwoPlaces(payload.item.tax * state.currencyRate.rate);
            }
            else if (payload.item.tax != undefined && (state.currencyRate.id == 0 || state.currencyRate.id == undefined)) {
                payload.item.taxPln = await dispatch.roundValueToTwoPlaces(payload.item.tax)
            }
        },
        async calculateNettoBruttoAndTaxValueInPln(context, payload: { item: SettlementCostDocumentItemRestModel | SettlementIncomeDocumentItemRestModel, parentComponentName: string }) {
            const { dispatch } = _moduleActionContext(context);
            await dispatch.calculateNettoValueInPln({ item: payload.item, discount: undefined, parentComponentName: payload.parentComponentName });
            await dispatch.calculateTaxValueInPln({ item: payload.item, parentComponentName: payload.parentComponentName });
            await dispatch.calculateBruttoValueInPln({ item: payload.item });
        },
        loadClosedMonthList(context,
            payload: {
                page: number;
                itemsPerPage: number;
            }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                commit.setClosedMonthListLoading(true);
                const params = {
                    page: payload.page,
                    pageSize: payload.itemsPerPage,
                };
                axiosInstance
                    .get("/api/settlementCommon/closed-month/list", { params })
                    .then((resp: AxiosResponse) => {
                        commit.setClosedMonthList(resp.data.items);
                        commit.setClosedMonthListTotalCount(resp.data.totalItemsCount)

                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setClosedMonthListLoading(false);
                    })
            })
        },
        async loadClosedMonth(context, payload: { closedMonthId: number }) {
            const { commit } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/settlementCommon/closed-month/" + payload.closedMonthId)
                    .then((resp: AxiosResponse) => {
                        commit.setClosedMonth(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err))
            })
        },
        async saveClosedMonth(context) {
            const { state, rootCommit, rootDispatch } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/settlementCommon/closed-month/save", state.closedMonth)
                    .then((resp: AxiosResponse) => {
                        rootCommit.settlementCommon.setSettlementAssetsLoaded(false);
                        rootDispatch.settlementCommon.loadSettlementAssets();
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err))
            })
        },
        openClosedMonthDialog(context, payload: { title: string; }) {
            const { commit } = _moduleActionContext(context);
            commit.setShowClosedMonthDialog(true);
            commit.setClosedMonthDialogTitle(payload.title);
            return new Promise(resolve => {
                commit.setClosedMonthDialogResolve(resolve);
            });
        },
        isConfirmedClosedMonthDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.closedMonthDialogResolve(true);
            commit.setShowClosedMonthDialog(false);
        },
        isNotConfirmedClosedMonthDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.closedMonthDialogResolve(false);
            commit.setShowClosedMonthDialog(false);
        },
        openRegisterExportDialog(context, payload: { title: string, registerType: string }) {
            const { commit } = _moduleActionContext(context);
            commit.setShowRegisterExportDialog(true);
            commit.setRegisterExportDialogTitle(payload.title);
            commit.setRegisterExportDialogRegisterType(payload.registerType);
            return new Promise(resolve => {
                commit.setRegisterExportDialogResolve(resolve);
            });
        },
        isConfirmedRegisterExportDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.registerExportDialogResolve(true);
            commit.setShowRegisterExportDialog(false);
        },
        isNotConfirmedRegisterExportDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.registerExportDialogResolve(false);
            commit.setShowRegisterExportDialog(false);
        },
        async saveRegisterExport(context, payload: { datesRange: string[], registerType: string }) {
            const { commit, rootDispatch } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                commit.setRegisterExportListLoading(true);
                axiosInstance
                    .get("/api/settlementCommon/register-export/save/" + payload.registerType + "/" + payload.datesRange[0] + "/" + payload.datesRange[1])
                    .then((resp: AxiosResponse) => {
                        if (resp.data.isSuccess == false) {
                            rootDispatch.confirm.openConfirmDialog({
                                showConfirmDialog: true,
                                title: "Błąd podczas tworzenia pliku exportu rejestrów zakupów dla danego miesiąca",
                                message: resp.data.errorMessage,
                                links: [],
                                options: { buttonColor: "error", layoutColor: "error" },
                                buttonType: "ok"
                            });
                        }
                        else {
                            const blob = new Blob([resp.data], {
                                type: resp.headers["content-type"]
                            });
                            const contentDisposition = resp.headers["content-disposition"];
                            const filename = contentDisposition
                                .split(";")[1]
                                .split("=")[1]
                                .replace('"', "")
                                .replace('"', "");

                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement("a");
                            a.style.display = "none";
                            document.body.appendChild(a);
                            a.download = filename;
                            a.href = url;
                            a.click();
                            window.URL.revokeObjectURL(url);
                        }
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setRegisterExportListLoading(false);
                    })
            });
        },
        openOutstandingRegisterExportDialog(context) {
            const { commit } = _moduleActionContext(context);
            commit.setShowOutstandingRegisterExportDialog(true);
            return new Promise(resolve => {
                commit.setOutstandingRegisterExportDialogResolve(resolve);
            });
        },
        isConfirmedOutstandingRegisterExportDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.outstandingRegisterExportDialogResolve(true);
            commit.setShowOutstandingRegisterExportDialog(false);
        },
        isNotConfirmedOutstandingRegisterExportDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.outstandingRegisterExportDialogResolve(false);
            commit.setShowOutstandingRegisterExportDialog(false);
        },
        async saveOutstandingRegisterExport(context, payload: { monthAndYear: string }) {
            const { commit, rootDispatch } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                commit.setOutstandingRegisterExportListLoading(true);
                axiosInstance
                    .get("/api/settlementCommon/outstanding-register-export/save/" + payload.monthAndYear)
                    .then((resp: AxiosResponse) => {
                        if (resp.data.isSuccess == false) {
                            rootDispatch.confirm.openConfirmDialog({
                                showConfirmDialog: true,
                                title: "Błąd podczas tworzenia pliku exportu zaległych rejestrów zakupów dla danego miesiąca",
                                message: resp.data.errorMessage,
                                links: [],
                                options: { buttonColor: "error", layoutColor: "error" },
                                buttonType: "ok"
                            });
                        }
                        else {
                            const blob = new Blob([resp.data], {
                                type: resp.headers["content-type"]
                            });
                            const contentDisposition = resp.headers["content-disposition"];
                            const filename = contentDisposition
                                .split(";")[1]
                                .split("=")[1]
                                .replace('"', "")
                                .replace('"', "");

                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement("a");
                            a.style.display = "none";
                            document.body.appendChild(a);
                            a.download = filename;
                            a.href = url;
                            a.click();
                            window.URL.revokeObjectURL(url);
                        }
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setOutstandingRegisterExportListLoading(false);
                    })
            });
        },
        openCreatePaymentOutsideTheSystemDialog(context, payload: { title: string, source: string }) {
            const { commit } = _moduleActionContext(context);
            commit.setShowCreatePaymentOutsideTheSystemDialog(true);
            commit.setCreatePaymentOutsideTheSystemDialogTitle(payload.title);
            commit.setCreatePaymentOutsideTheSystemDialogSource(payload.source);
            return new Promise(resolve => {
                commit.setCreatePaymentOutsideTheSystemDialogResolve(resolve);
            });
        },
        isConfirmedCreatePaymentOutsideTheSystemDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.createPaymentOutsideTheSystemDialogResolve(true);
            commit.setShowCreatePaymentOutsideTheSystemDialog(false);
        },
        isNotConfirmedCreatePaymentOutsideTheSystemDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.createPaymentOutsideTheSystemDialogResolve(false);
            commit.setShowCreatePaymentOutsideTheSystemDialog(false);
        },
        calculateSelectedCostOrIncomeDocumentListForAmountSummaryRow(context, payload: { list: any[] }) {
            const { commit, dispatch } = _moduleActionContext(context);
            const uniqueCurrencyCodeList = Array.from(new Set(payload.list.map((item) => item.currencyCode)));
            const amountSummaryList = [] as SettlementAmountSummaryRestModel[];

            uniqueCurrencyCodeList.forEach(async (currencyCode: string) => {
                const itemListForSelectedCurrency = payload.list.filter((y: SettlementCostDocumentListItemRestModel | SettlementIncomeDocumentListItemRestModel) => {
                    return currencyCode == y.currencyCode;
                });

                const totalNetto = await dispatch.roundValueToTwoPlaces(itemListForSelectedCurrency.map((x) => {
                    return x.totalNetto;
                }).reduce((sum, current) => sum + current, 0));

                const totalBrutto = await dispatch.roundValueToTwoPlaces(itemListForSelectedCurrency.map((x) => {
                    return x.totalBrutto;
                }).reduce((sum, current) => sum + current, 0));

                const totalTax = await dispatch.roundValueToTwoPlaces(itemListForSelectedCurrency.map((x) => {
                    return x.totalTax;
                }).reduce((sum, current) => sum + current, 0));

                const totalNettoPln = await dispatch.roundValueToTwoPlaces(itemListForSelectedCurrency.map((x) => {
                    return x.totalNettoPln;
                }).reduce((sum, current) => sum + current, 0));

                const totalBruttoPln = await dispatch.roundValueToTwoPlaces(itemListForSelectedCurrency.map((x) => {
                    return x.totalBruttoPln;
                }).reduce((sum, current) => sum + current, 0));

                const totalTaxPln = await dispatch.roundValueToTwoPlaces(itemListForSelectedCurrency.map((x) => {
                    return x.totalTaxPln;
                }).reduce((sum, current) => sum + current, 0));

                const amoutSummary = { netto: totalNetto, brutto: totalBrutto, tax: totalTax, nettoPln: totalNettoPln, bruttoPln: totalBruttoPln, taxPln: totalTaxPln, currencyCode: "Zaznaczone " + currencyCode } as SettlementAmountSummaryRestModel;

                amountSummaryList.push(amoutSummary);
            });

            commit.setCalculatedSelectedListForAmountSummaryRow(amountSummaryList);
        },
        calculateSelectedCostOrIncomeDocumentCorrectionListForAmountSummaryRow(context, payload: { list: any[] }) {
            const { commit, dispatch } = _moduleActionContext(context);
            const uniqueCurrencyCodeList = Array.from(new Set(payload.list.map((item) => item.currencyCode)));
            const amountSummaryList = [] as SettlementAmountSummaryRestModel[];

            uniqueCurrencyCodeList.forEach(async (currencyCode: string) => {
                const itemListForSelectedCurrency = payload.list.filter((y: SettlementCostDocumentCorrectionListItemRestModel | SettlementIncomeDocumentCorrectionListItemRestModel) => {
                    return currencyCode == y.currencyCode;
                });

                const totalNetto = await dispatch.roundValueToTwoPlaces(itemListForSelectedCurrency.map((x) => {
                    return x.totalNettoDifference;
                }).reduce((sum, current) => sum + current, 0));

                const totalBrutto = await dispatch.roundValueToTwoPlaces(itemListForSelectedCurrency.map((x) => {
                    return x.totalBruttoDifference;
                }).reduce((sum, current) => sum + current, 0));

                const totalTax = await dispatch.roundValueToTwoPlaces(itemListForSelectedCurrency.map((x) => {
                    return x.totalTaxDifference;
                }).reduce((sum, current) => sum + current, 0));

                const totalNettoPln = await dispatch.roundValueToTwoPlaces(itemListForSelectedCurrency.map((x) => {
                    return x.totalNettoPlnDifference;
                }).reduce((sum, current) => sum + current, 0));

                const totalBruttoPln = await dispatch.roundValueToTwoPlaces(itemListForSelectedCurrency.map((x) => {
                    return x.totalBruttoPlnDifference;
                }).reduce((sum, current) => sum + current, 0));

                const totalTaxPln = await dispatch.roundValueToTwoPlaces(itemListForSelectedCurrency.map((x) => {
                    return x.totalTaxPlnDifference;
                }).reduce((sum, current) => sum + current, 0));

                const amoutSummary = { netto: totalNetto, brutto: totalBrutto, tax: totalTax, nettoPln: totalNettoPln, bruttoPln: totalBruttoPln, taxPln: totalTaxPln, currencyCode: "Zaznaczone " + currencyCode } as SettlementAmountSummaryRestModel;

                amountSummaryList.push(amoutSummary);
            });

            commit.setCalculatedSelectedListForAmountSummaryRow(amountSummaryList);
        },
        calculateSelectedListWithCurrencyCodeAndPaymentAmountValuesForAmountSummaryRow(context, payload: { list: any[] }) {
            const { commit, dispatch } = _moduleActionContext(context);
            const uniqueCurrencyCodeList = Array.from(new Set(payload.list.map((item) => item.currencyCode)));
            const amountSummaryList = [] as SettlementAmountSummaryRestModel[];

            uniqueCurrencyCodeList.forEach(async (currencyCode: string) => {
                const itemListForSelectedCurrency = payload.list.filter((y: SettlementCostDocumentPaymentObligationListItemRestModel | SettlementIncomeDocumentPaymentObligationListItemRestModel) => {
                    return currencyCode == y.currencyCode;
                });

                const totalNetto = await dispatch.roundValueToTwoPlaces(itemListForSelectedCurrency.map((x) => {
                    return x.paymentAmount;
                }).reduce((sum, current) => sum + current, 0));

                const amoutSummary = { netto: totalNetto, brutto: 0, tax: 0, nettoPln: 0, bruttoPln: 0, taxPln: 0, currencyCode: "Zaznaczone " + currencyCode } as SettlementAmountSummaryRestModel;

                amountSummaryList.push(amoutSummary);
            });

            commit.setCalculatedSelectedListForAmountSummaryRow(amountSummaryList);
        },
        calculateSelectedCostTransferOrderListForAmountSummaryRow(context, payload: { list: SettlementCostDocumentTransferOrderRestModel[] }) {
            const { commit, dispatch } = _moduleActionContext(context);
            const uniqueCurrencyCodeList = Array.from(new Set(payload.list.map((item) => item.transferCurrencyCode)));
            const amountSummaryList = [] as SettlementAmountSummaryRestModel[];

            uniqueCurrencyCodeList.forEach(async (currencyCode: string) => {
                const itemListForSelectedCurrency = payload.list.filter((y: SettlementCostDocumentTransferOrderRestModel) => {
                    return currencyCode == y.transferCurrencyCode;
                });

                const totalNetto = await dispatch.roundValueToTwoPlaces(itemListForSelectedCurrency.map((x) => {
                    return x.transferAmount;
                }).reduce((sum, current) => sum + current, 0));

                const amoutSummary = { netto: totalNetto, brutto: 0, tax: 0, nettoPln: 0, bruttoPln: 0, taxPln: 0, currencyCode: "Zaznaczone " + currencyCode } as SettlementAmountSummaryRestModel;

                amountSummaryList.push(amoutSummary);
            });

            commit.setCalculatedSelectedListForAmountSummaryRow(amountSummaryList);
        },
        calculateSelectedCostTransferOrderPackageListForAmountSummaryRow(context, payload: { list: SettlementCostDocumentTransferOrderPackageRestModel[] }) {
            const { commit, dispatch } = _moduleActionContext(context);
            const uniqueCurrencyCodeList = Array.from(new Set(payload.list.map((item) => item.currencyCode)));
            const amountSummaryList = [] as SettlementAmountSummaryRestModel[];

            uniqueCurrencyCodeList.forEach(async (currencyCode: string) => {
                const itemListForSelectedCurrency = payload.list.filter((y: SettlementCostDocumentTransferOrderPackageRestModel) => {
                    return currencyCode == y.currencyCode;
                });

                const totalNetto = await dispatch.roundValueToTwoPlaces(itemListForSelectedCurrency.map((x) => {
                    return x.allOperationsValue;
                }).reduce((sum, current) => sum + current, 0));

                const amoutSummary = { netto: totalNetto, brutto: 0, tax: 0, nettoPln: 0, bruttoPln: 0, taxPln: 0, currencyCode: "Zaznaczone " + currencyCode } as SettlementAmountSummaryRestModel;

                amountSummaryList.push(amoutSummary);
            });

            commit.setCalculatedSelectedListForAmountSummaryRow(amountSummaryList);
        },
    }
});

export default settlementCommonModule;
const _moduleActionContext = (context: any) => moduleActionContext(context, settlementCommonModule)