import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({
    props: {
        itemClass: {
            type: String,
            default: "",
        },
        item: {
            type: Object,
            default: () => {
                return {};
            },
        },
        headers: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
})
export default class DraggableTableRowHandler extends Vue {
    itemClass!: any;

    get getClass(): any {
        return this.itemClass;
    };

    columnName(header: any) {
        return `item.${header.value}`;
    };
    getAlignment(header: any) {
        const align = header.align ? header.align : "right";
        return `text-align: ${align}`;
    };
    getNonSlotValue(item: any, header: any) {
        const val = item[header.value];

        if (val) {
            return val;
        }

        return "";
    };
};
