import Vue from "vue";
import { Component } from "vue-property-decorator";
import SafeLocationRestModel from "../../../../models/rest/register/safeLocationRestModel";

@Component
export default class SafeLocationDialog extends Vue {
    validation = {
        name: [(value: string) => !!value || this.$t("common.validationErrorRequired")],
        ipAddress: [(value: string) => !!value || this.$t("common.validationErrorRequired")],
    };

    get showDialog(): boolean {
        return this.$store.direct.state.register.showSafeLocationDialog;
    }

    get model(): SafeLocationRestModel {
        return this.$store.direct.state.register.safeLocation;
    }

    async saveClick() {
        const valid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
        if (valid) {
            await this.$store.direct.dispatch.register.updateSafeLocation();
        }
    }

    async backClick() {
        await this.$store.direct.dispatch.register.closeSafeLocationDialog();
    }
}
