import dayjs from "dayjs";
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import i18n from "../../../assets/i18n";
import ConfirmDialog from "../../../layouts/confirmDialog/confirmDialog.vue";
import { BranchEnum } from "../../../models/emums/branchEnum";
import { Roles } from "../../../models/emums/roles";
import { SettlementDocumentRecalculatingTypeEnum } from "../../../models/emums/settlement/settlementDocumentRecalculatingTypeEnum";
import { SettlementPaymentStatusEnum } from "../../../models/emums/settlement/settlementPaymentStatusEnum";
import ListItemNumberModel from "../../../models/listItemNumberModel";
import ContractorSearchItemModel from "../../../models/rest/contractor/contractorSearchItemModel";
import CostTypeRestModel from "../../../models/rest/register/costTypeRestModel";
import CountryRestModel from "../../../models/rest/register/countryRestModel";
import CurrencyRestModel from "../../../models/rest/register/currencyRestModel";
import SettlementCostDocumentBankAccountRestModel from "../../../models/rest/settlement/cost-document/settlementCostDocumentBankAccountRestModel";
import SettlementCostDocumentItemRestModel from "../../../models/rest/settlement/cost-document/settlementCostDocumentItemRestModel";
import SettlementCostDocumentListItemRestModel from "../../../models/rest/settlement/cost-document/settlementCostDocumentListItemRestModel";
import SettlementCostDocumentListRequestModel from "../../../models/rest/settlement/cost-document/settlementCostDocumentListRequestModel";
import SettlementCostDocumentRestModel from "../../../models/rest/settlement/cost-document/settlementCostDocumentRestModel";
import SettlementPaymentCostDocumentRestModel from "../../../models/rest/settlement/payment/settlementPaymentCostDocumentRestModel";
import SettlementPaymentRestModel from "../../../models/rest/settlement/payment/settlementPaymentRestModel";
import SettlementAmountSummaryRestModel from "../../../models/rest/settlement/settlementAmountSummaryRestModel";
import SettlementDocumentStatusRestModel from "../../../models/rest/settlement/settlementDocumentStatusRestModel";
import SupervisorForSettlementRestModel from "../../../models/rest/supervisor/supervisorForSettlementRestModel";
import SupervisorRestModel from "../../../models/rest/supervisor/supervisorRestModel";
import CostPaymentDialog from "../cost-payment/cost-payment-dialog.vue";
import SettlementAmountSummaryRow from "../settlement-amount-summary-row.vue";

@Component({
    components: {
        "confirm-dialog": ConfirmDialog,
        "cost-payment-dialog": CostPaymentDialog,
        "settlement-amount-summary-row": SettlementAmountSummaryRow,
    }
})


export default class CostDocumentList extends Vue {
    usersLabel = this.$t("common.users");
    costTypeLabel= this.$t("costDocument.costType");
    paymentTypeLabel = this.$t("costDocument.paymentType");
    documentCountryLabel = this.$t("costDocument.documentCountry");
    createdAtDateFromLabel = this.$t("common.createdAtFrom");
    createdAtDateToLabel = this.$t("common.createdAtTo");
    entryDateFromLabel = this.$t("costDocument.entryDateFrom");
    entryDateToLabel = this.$t("costDocument.entryDateTo");
    paymentTermDateFromLabel = this.$t("costDocument.paymentTermDateFrom");
    paymentTermDateToLabel = this.$t("costDocument.paymentTermDateTo");
    paymentDateFromLabel = this.$t("costDocument.paymentDateFrom");
    paymentDateToLabel = this.$t("costDocument.paymentDateTo");
    exportedToOptimaDateFromLabel = this.$t("costDocument.exportedToOptimaDateFrom");
    exportedToOptimaDateToLabel = this.$t("costDocument.exportedToOptimaDateTo");
    contractorSearch = "";
    costPaymentListPagerOptions = {
        itemsPerPage: -1,
    };
    supervisorSearch = "";
    issueDateFromLabel = this.$t("costDocument.issueDateFrom");
    issueDateToLabel = this.$t("costDocument.issueDateTo");
    saleDateFromLabel = this.$t("costDocument.saleDateFrom");
    saleDateToLabel = this.$t("costDocument.saleDateTo");
    receivedDateFromLabel = this.$t("costDocument.receivedDateFrom");
    receivedDateToLabel = this.$t("costDocument.receivedDateTo");
    mainCheckboxIsSelected = false;
    showAmountSummaryRowForCalculatedSelectedList = false;
    showFiltersMenu = false;

    costDocumentListHeaders = [
        {
            align: "start",
            value: "checkbox",
            width: "1%",
            sortable: false,
        },
        {
            text: "Id",
            align: "start",
            value: "id",
            width: "1%",
            sortable: true,
        },
        {
            text: this.$t("costDocument.documentNumber"),
            align: "start",
            value: "documentNumber",
            sortable: true,
        },
        {
            text: this.$t("contractor.branch"),
            align: "start",
            value: "branchId",
            sortable: true,
        },
        {
            text: this.$t("costDocument.status"),
            align: "start",
            value: "costDocumentStatusName",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.correction"),
            align: "start",
            value: "documentCorrectionDocumentNumber",
            sortable: true,
        },
        {
            text: this.$t("costDocument.documentCountry"),
            align: "start",
            value: "documentCountry",
            width: "1%",
            sortable: true,
        },
        {
            text: this.$t("supervisor.orderId"),
            align: "start",
            value: "supervisorOrderIdcsString",
            sortable: true,
        },
        {
            text: this.$t("costDocument.contractorName"),
            align: "start",
            value: "contractorName",
            sortable: true,
        },
        {
            text: this.$t("costDocument.contractorTinWithPrefix"),
            align: "start",
            value: "contractorTinWithPrefix",
            sortable: true,
        },
        {
            text: this.$t("costDocument.issueDate"),
            align: "start",
            value: "issueDate",
            sortable: true,
        },
        {
            text: this.$t("costDocument.saleDate"),
            align: "start",
            value: "saleDate",
            sortable: true,
        },
        {
            text: this.$t("costDocument.receivedDate"),
            align: "start",
            value: "receivedDate",
            sortable: true,
        },
        {
            text: this.$t("costDocument.entryDate"),
            align: "start",
            value: "entryDate",
            sortable: true,
        },
        {
            text: this.$t("costDocument.paymentTermDate"),
            align: "start",
            value: "paymentTermDate",
            sortable: true,
        },
        {
            text: this.$t("costDocument.paymentDaysLeft"),
            align: "start",
            value: "paymentDaysLeft",
            width: "1%",
            sortable: true,
        },
        {
            text: this.$t("costDocument.paymentDate"),
            align: "start",
            value: "paymentDate",
            sortable: true,
        },
        {
            text: "",
            align: "center",
            value: "paymentStatus",
            sortable: true,
        },
        {
            text: this.$t("common.currency"),
            align: "center",
            value: "currencyCode",
            sortable: true,
        },
        {
            text: this.$t("costDocument.totalNetto"),
            align: "end",
            value: "totalNetto",
            sortable: true,
        },
        {
            text: this.$t("costDocument.totalTax"),
            align: "end",
            value: "totalTax",
            sortable: true,
        },
        {
            text: this.$t("costDocument.totalBrutto"),
            align: "end",
            value: "totalBrutto",
            sortable: true,
        },
        {
            text: this.$t("costDocument.totalNettoPLN"),
            align: "end",
            value: "totalNettoPln",
            sortable: true,
        },
        {
            text: this.$t("costDocument.totalTaxPLN"),
            align: "end",
            value: "totalTaxPln",
            sortable: true,
        },
        {
            text: this.$t("costDocument.totalBruttoPLN"),
            align: "end",
            value: "totalBruttoPln",
            sortable: true,
        },
        {
            text: this.$t("costDocument.isExportedToOptima"),
            align: "end",
            value: "isExportedToOptima",
            sortable: false,
        },
        {
            text: this.$t("common.attachment"),
            align: "center",
            value: "hasCostDocumentFiles",
            sortable: false,
        },
        {
            text: this.$t("common.actions"),
            align: "right",
            value: "actions",
            width: "1%",
            sortable: false,
        }
    ];

    costPaymentListHeaders = [
        {
            text: "Id",
            align: "start",
            value: "id",
            width: "1%",
            sortable: true,
        },
        {
            text: this.$t("costDocument.paymentType"),
            align: "start",
            value: "paymentTypeId",
            sortable: false,
        },
        {
            text: this.$t("payment.amount"),
            align: "start",
            value: "paymentAmount",
            sortable: false,
        },
        {
            text: this.$t("payment.currency"),
            align: "start",
            value: "currencyCode",
            sortable: false,
        },
        {
            text: this.$t("common.createdBy"),
            align: "start",
            value: "createdByUserName",
            sortable: false,
        },
        {
            text: this.$t("payment.paymentDate"),
            align: "start",
            value: "paymentDate",
            sortable: false,
        },
    ];

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get costDocumentListLoading(): boolean {
        return this.$store.direct.state.settlementCostDocument.costDocumentListLoading;
    }

    get costDocumentList(): SettlementCostDocumentListItemRestModel[] {
        return this.$store.direct.state.settlementCostDocument.costDocumentList;
    }

    get costDocumentListTotalCount(): number {
        return this.$store.direct.state.settlementCostDocument.costDocumentListTotalCount;
    }

    get costDocumentListAmountSummaryList(): SettlementAmountSummaryRestModel[] {
        let amountSummary = this.$store.direct.state.settlementCostDocument.costDocumentListAmountSummaryList;

        if (amountSummary == undefined) {
            amountSummary = [] as SettlementAmountSummaryRestModel[];
        }

        return amountSummary;
    }

    get costDocumentListPagerOptions(): any {
        return this.$store.direct.state.settlementCostDocument.costDocumentListPagerOptions;
    }

    set costDocumentListPagerOptions(value: any) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentListPagerOptions(value);
    }

    get costDocumentListSearchText(): string {
        return this.$store.direct.state.settlementCostDocument.costDocumentListSearchText;
    }

    set costDocumentListSearchText(value: string) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentListSearchText(value);
    }

    get costDocumentListSelectedBranch(): number {
        return this.$store.direct.state.settlementCostDocument.costDocumentListSelectedBranch;
    }

    set costDocumentListSelectedBranch(value: number) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentListSelectedBranch(value);
    }

    get costDocumentListSelectedDocumentStatusIdcs(): number[] {
        return this.$store.direct.state.settlementCostDocument.costDocumentListSelectedDocumentStatusIdcs;
    }

    set costDocumentListSelectedDocumentStatusIdcs(value: number[]) {
        this.$store.direct.commit.settlementCostDocument.setDocumentListSelectedDocumentStatusIdcs(value);
    }

    get searchSupervisor(): SupervisorRestModel {
        return this.$store.direct.state.settlementCostDocument.costDocumentListSearchSupervisor;
    }

    set searchSupervisor(value: SupervisorRestModel) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentListSearchSupervisor(value);
    }

    get searchContractors(): ContractorSearchItemModel[] {
        return this.$store.direct.state.settlementCostDocument.costDocumentListSearchContractors;
    }

    set searchContractors(value: ContractorSearchItemModel[]) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentListSearchContractors(value);
    }

    get searchContractorIdcs(): number[] {
        return this.$store.direct.state.settlementCostDocument.costDocumentListSearchContractorIdcs;
    }

    set searchContractorIdcs(value: number[]) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentListSearchContractorIdcs(value);
    }

    get selectedCountryCodes(): string[] {
        return this.$store.direct.state.settlementCostDocument.costDocumentListSearchCountryCode;
    }

    set selectedCountryCodes(value: string[]) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentListSearchCountryCode(value);
    }

    get isPaid(): number {
        return this.$store.direct.state.settlementCostDocument.costDocumentListSearchIsPaid;
    }

    set isPaid(value: number) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentListSearchIsPaid(value);
    }

    get isExportedToOptima(): number {
        return this.$store.direct.state.settlementCostDocument.costDocumentListSearchIsExportedToOptima;
    }

    set isExportedToOptima(value: number) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentListSearchIsExportedToOptima(value);
    }

    get searchCreatedAtFromDate(): string {
        return this.$store.direct.state.settlementCostDocument.costDocumentListSearchCreatedAtFromDate;
    }

    set searchCreatedAtFromDate(value: string) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentListSearchCreatedAtFromDate(value);
    }

    get searchCreatedAtToDate(): string {
        return this.$store.direct.state.settlementCostDocument.costDocumentListSearchCreatedAtToDate;
    }

    set searchCreatedAtToDate(value: string) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentListSearchCreatedAtToDate(value);
    }

    get searchEntryDateFromDate(): string {
        return this.$store.direct.state.settlementCostDocument.costDocumentListSearchEntryDateFromDate;
    }

    set searchEntryDateFromDate(value: string) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentListSearchEntryDateFromDate(value);
    }

    get searchEntryDateToDate(): string {
        return this.$store.direct.state.settlementCostDocument.costDocumentListSearchEntryDateToDate;
    }

    set searchEntryDateToDate(value: string) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentListSearchEntryDateToDate(value);
    }

    get searchIssueDateFrom(): string {
        return this.$store.direct.state.settlementCostDocument.costDocumentListSearchIssueDateFrom;
    }

    set searchIssueDateFrom(value: string) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentListSearchIssueDateFrom(value);
    }

    get searchIssueDateTo(): string {
        return this.$store.direct.state.settlementCostDocument.costDocumentListSearchIssueDateTo;
    }

    set searchIssueDateTo(value: string) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentListSearchIssueDateTo(value);
    }

    get searchReceivedDateFrom(): string {
        return this.$store.direct.state.settlementCostDocument.costDocumentListSearchReceivedDateFrom;
    }

    set searchReceivedDateFrom(value: string) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentListSearchReceivedDateFrom(value);
    }

    get searchReceivedDateTo(): string {
        return this.$store.direct.state.settlementCostDocument.costDocumentListSearchReceivedDateTo;
    }

    set searchReceivedDateTo(value: string) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentListSearchReceivedDateTo(value);
    }

    get searchSaleDateFrom(): string {
        return this.$store.direct.state.settlementCostDocument.costDocumentListSearchSaleDateFrom;
    }

    set searchSaleDateFrom(value: string) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentListSearchSaleDateFrom(value);
    }

    get searchSaleDateTo(): string {
        return this.$store.direct.state.settlementCostDocument.costDocumentListSearchSaleDateTo;
    }

    set searchSaleDateTo(value: string) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentListSearchSaleDateTo(value);
    }

    get searchPaymentTermDateFrom(): string {
        return this.$store.direct.state.settlementCostDocument.costDocumentListSearchPaymentTermDateFrom;
    }

    set searchPaymentTermDateFrom(value: string) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentListSearchPaymentTermDateFrom(value);
    }

    get searchPaymentTermDateTo(): string {
        return this.$store.direct.state.settlementCostDocument.costDocumentListSearchPaymentTermDateTo;
    }

    set searchPaymentTermDateTo(value: string) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentListSearchPaymentTermDateTo(value);
    }

    get searchPaymentDateFrom(): string {
        return this.$store.direct.state.settlementCostDocument.costDocumentListSearchPaymentDateFrom;
    }

    set searchPaymentDateFrom(value: string) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentListSearchPaymentDateFrom(value);
    }

    get searchPaymentDateTo(): string {
        return this.$store.direct.state.settlementCostDocument.costDocumentListSearchPaymentDateTo;
    }

    set searchPaymentDateTo(value: string) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentListSearchPaymentDateTo(value);
    }

    get searchExportedToOptimaDateFrom(): string {
        return this.$store.direct.state.settlementCostDocument.costDocumentListSearchExportedToOptimaDateFrom;
    }

    set searchExportedToOptimaDateFrom(value: string) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentListSearchExportedToOptimaDateFrom(value);
    }

    get searchExportedToOptimaDateTo(): string {
        return this.$store.direct.state.settlementCostDocument.costDocumentListSearchExportedToOptimaDateTo;
    }

    set searchExportedToOptimaDateTo(value: string) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentListSearchExportedToOptimaDateTo(value);
    }

    get searchCostType(): number[] {
        return this.$store.direct.state.settlementCostDocument.costDocumentListSearchCostType;
    }

    set searchCostType(value: number[]) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentListSearchCostType(value);
    }

    get searchPaymentType(): number[] {
        return this.$store.direct.state.settlementCostDocument.costDocumentListSearchPaymentType;
    }

    set searchPaymentType(value: number[]) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentListSearchPaymentType(value);
    }

    get footerPropsForSettlement(): any {
        return this.$store.direct.state.settlementCommon.footerPropsForSettlement;
    }

    get searchAccountantIdList(): any {
        return this.$store.direct.state.settlementCommon.searchAccountantIdList;
    }

    set searchAccountantIdList(value: any) {
        this.$store.direct.commit.settlementCommon.setSearchAccountantIdList(value);
    }

    get accountantListSorted(): any {
        return this.$store.direct.state.settlementCommon.settlementAssets.accountantUsers?.sort((prev, next) => (prev.text > next.text ? 1 : -1));
    }

    get searchSelectedCurrency(): CurrencyRestModel {
        return this.$store.direct.state.settlementCostDocument.costDocumentListSearchSelectedCurrency;
    }

    set searchSelectedCurrency(value: CurrencyRestModel) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentListSearchSelectedCurrency(value);
    }

    get currencies(): CurrencyRestModel[] {
        const currencies = this.$store.direct.state.contractor.contractorAssets.currencies;
        if (currencies == undefined) {
            this.$store.direct.dispatch.contractor.loadContractorAssets();
        }
        else {
            currencies.push({ code: i18n.t("common.all"), name: "" } as CurrencyRestModel)
        }

        return currencies;
    }

    get branchList(): ListItemNumberModel[] {
        const branchList = this.$store.direct.state.contractor.contractorAssets.branches;
        let clonedBranchList = [] as ListItemNumberModel[];
        if (branchList == undefined) {
            this.$store.direct.dispatch.contractor.loadContractorAssets();
        }
        else {
            clonedBranchList = [...branchList];
            clonedBranchList.push({ value: 0, text: i18n.t("common.all") } as ListItemNumberModel);
        }
        return clonedBranchList;
    }

    get costDocumentStatuses(): SettlementDocumentStatusRestModel[] {
        const statusesList = this.$store.direct.state.settlementCommon.settlementAssets.costDocumentStatuses;
        if (statusesList == undefined) {
            this.$store.direct.dispatch.settlementCommon.loadSettlementAssets();
        }
        return statusesList;
    }

    get statusList(): ListItemNumberModel[] {
        const branchList = this.$store.direct.state.contractor.contractorAssets.branches;
        let clonedBranchList = [] as ListItemNumberModel[];
        if (branchList == undefined) {
            this.$store.direct.dispatch.contractor.loadContractorAssets();
        }
        else {
            clonedBranchList = [...branchList];
            clonedBranchList.push({ value: 0, text: i18n.t("common.all") } as ListItemNumberModel);
        }
        return clonedBranchList;
    }

    get hasOrHasNotOrAllTypeList(): ListItemNumberModel[] {
        return this.$store.direct.state.common.hasOrHasNotOrAllTypeList;
    }

    get supervisorFound(): SupervisorRestModel[] {
        return this.$store.direct.state.supervisor.supervisorSearch;
    }

    get contractorFound(): ContractorSearchItemModel[] {
        return this.$store.direct.state.common.contractorSearch;
    }

    get branches(): ListItemNumberModel[] {
        const branches = this.$store.direct.state.contractor.contractorAssets.branches;
        if (branches == undefined) {
            this.$store.direct.dispatch.contractor.loadContractorAssets();
        }
        return branches;
    }

    get costTypeList(): CostTypeRestModel[] {
        return this.$store.direct.state.settlementCommon.settlementAssets.costTypes;
    }

    get countryList(): CountryRestModel[] {
        return this.$store.direct.state.contractor.contractorAssets.countries;
    }

    get paymentTypeList(): ListItemNumberModel[] {
        return this.$store.direct.state.settlementCommon.settlementAssets.paymentTypes;
    }

    get costPayment(): SettlementPaymentRestModel {
        return this.$store.direct.state.settlementPayment.payment;
    }

    set costPayment(val: SettlementPaymentRestModel) {
        this.$store.direct.commit.settlementPayment.setPayment(val);
    }

    get costDocument(): SettlementCostDocumentRestModel {
        return this.$store.direct.state.settlementCostDocument.costDocument;
    }

    set costDocument(val: SettlementCostDocumentRestModel) {
        this.$store.direct.commit.settlementCostDocument.setCostDocument(val);
    }

    get costDocumentIssueDate() {
        return this.$store.direct.state.settlementCostDocument.costDocumentIssueDate;
    }

    set costDocumentIssueDate(val: string) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentIssueDate(val);
    }

    get costDocumentSaleDate() {
        return this.$store.direct.state.settlementCostDocument.costDocumentSaleDate;
    }

    set costDocumentSaleDate(val: string) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentSaleDate(val);
    }

    get costDocumentEntryDate() {
        return this.$store.direct.state.settlementCostDocument.costDocumentEntryDate;
    }

    set costDocumentEntryDate(val: string) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentEntryDate(val);
    }

    get costDocumentReceivedDate() {
        return this.$store.direct.state.settlementCostDocument.costDocumentReceivedDate;
    }

    set costDocumentReceivedDate(val: string) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentReceivedDate(val);
    }

    get costDocumentPaymentDate() {
        return this.$store.direct.state.settlementCostDocument.costDocumentPaymentDate;
    }

    set costDocumentPaymentDate(val: string) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentPaymentDate(val);
    }

    get costDocumentSupervisorList(): SupervisorForSettlementRestModel[] {
        return this.$store.direct.state.settlementCommon.supervisorList;
    }

    set costDocumentSupervisorList(val: SupervisorForSettlementRestModel[]) {
        this.$store.direct.commit.settlementCommon.setSupervisorList(val);
    }

    get costDocumentBankAccountList(): SettlementCostDocumentBankAccountRestModel[] {
        return this.$store.direct.state.settlementCostDocument.costDocumentBankAccountList;
    }

    set costDocumentBankAccountList(val: SettlementCostDocumentBankAccountRestModel[]) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentBankAccountList(val);
    }

    get documentRecalculatingType(): SettlementDocumentRecalculatingTypeEnum {
        return this.$store.direct.state.settlementCostDocument.costDocumentDocumentRecalculatingType;
    }

    set documentRecalculatingType(val: SettlementDocumentRecalculatingTypeEnum) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentDocumentRecalculatingType(val);
    }

    get selectedCostDocumentList(): SettlementCostDocumentListItemRestModel[] {
        return this.$store.direct.state.settlementCostDocument.selectedCostDocumentList;
    }

    set selectedCostDocumentList(val: SettlementCostDocumentListItemRestModel[]) {
        this.$store.direct.commit.settlementCostDocument.setSelectedCostDocumentList(val);
    }

    get filterAsActive(): boolean {
        return this.$store.direct.state.settlementCostDocument.costDocumentListFilterAsActive;
    }

    set filterAsActive(val: boolean) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentListFilterAsActive(val);
    }

    get calculatedSelectedListForAmountSummaryRow(): SettlementAmountSummaryRestModel[] {
        return this.$store.direct.state.settlementCommon.calculatedSelectedListForAmountSummaryRow;
    }

    get currentUserRoles() {
        return this.$store.direct.state.auth.userRoles;
    }

    @Watch("costDocumentListPagerOptions", { deep: true })
    pagerOptionsHandles() {
        this.loadCostDocumentList();
    }

    @Watch("contractorSearch")
    async contractorSearchLoadItems(val: string) {
        await this.$store.direct.dispatch.common.searchContractors({ searchText: val, contractorTypes: [], contractorPropertiesEnabled: [], source: "" });
        if (this.searchContractors.length > 0) {
            this.searchContractors.forEach((x: any) => {
                if (!this.contractorFound.includes(x)) {
                    this.contractorFound.push(x);
                }
            })
        }
    }

    @Watch("supervisorSearch")
    async supervisorSearchLoadItems(val: string) {
        if (this.supervisorSearch) {
            await this.$store.direct.dispatch.supervisor.searchSupervisorsForSettlement({ searchText: val });
        }
    }

    @Watch("searchCreatedAtFromDate")
    searchCreatedAtFromDateHandler(val: string) {
        this.checkIfFilterIsActive();
    }

    @Watch("searchCreatedAtToDate")
    searchCreatedAtToDateHandler(val: string) {
        this.checkIfFilterIsActive();
    }

    @Watch("searchPaymentTermDateFrom")
    searchPaymentTermDateFromHandler(val: string) {
        this.checkIfFilterIsActive();
    }

    @Watch("searchPaymentTermDateTo")
    searchPaymentTermDateToHandler(val: string) {
        this.checkIfFilterIsActive();
    }

    @Watch("searchPaymentDateFrom")
    searchPaymentDateFromHandler(val: string) {
        this.checkIfFilterIsActive();
    }

    @Watch("searchPaymentDateTo")
    searchPaymentDateToHandler(val: string) {
        this.checkIfFilterIsActive();
    }

    @Watch("searchExportedToOptimaDateFrom")
    searchExportedToOptimaDateFromHandler(val: string) {
        this.checkIfFilterIsActive();
    }

    @Watch("searchExportedToOptimaDateTo")
    searchExportedToOptimaDateToHandler(val: string) {
        this.checkIfFilterIsActive();
    }

    @Watch("searchCostType")
    searchCostTypeHandler(val: any) {
        this.checkIfFilterIsActive();
    }

    @Watch("searchPaymentType")
    searchPaymentTypeHandler(val: any) {
        this.checkIfFilterIsActive();
    }

    @Watch("searchSelectedCurrency")
    searchSelectedCurrencyHandler(val: CurrencyRestModel) {
        this.checkIfFilterIsActive();
    }

    created() {
        this.$store.direct.dispatch.settlementCommon.loadSettlementAssets();
        this.loadCostDocumentList();
    }

    formatPaymentTypeIdToPaymentName(paymentTypeId: number) {
        if (this.paymentTypeList != undefined) {
            return this.paymentTypeList.find(x => x.value == paymentTypeId)?.text;
        }
    }

    formatBranchIdToBranchName(branchId: any) {
        return branchId == BranchEnum.DE ? "DE" : "PL";
    }

    itemRowTextStyle(item: SettlementCostDocumentListItemRestModel) {
        let style = "";
        (item.documentCorrectionDocumentNumber != undefined && item.documentCorrectionDocumentNumber != null) ? style += "font-italic " : "";
        return style;
    }

    getPaymentStatusIconColor(item: SettlementCostDocumentListItemRestModel): string {
        if (item.paymentStatusId == SettlementPaymentStatusEnum.Niezaplacona) {
            return "red--text";
        }
        if (item.paymentStatusId == SettlementPaymentStatusEnum.ZaplataCzesciowa) {
            return "blue--text";
        }
        else if (item.paymentStatusId == SettlementPaymentStatusEnum.Zaplacona) {
            return "green--text";
        }
        else if (item.paymentStatusId == SettlementPaymentStatusEnum.Nadplata) {
            return "yellow--text";
        }
        else {
            return "";
        }
    }

    formatDate(dateTime: any) {
        if (dateTime != null) {
            return dayjs(dateTime).format("YYYY-MM-DD");
        }
    }

    checkIfFilterIsActive() {
        if ((this.searchSelectedCurrency.name == undefined || this.searchSelectedCurrency.name.length == 0) &&
            this.searchAccountantIdList.length == 0 &&
            this.searchCreatedAtFromDate === "" &&
            this.searchCreatedAtToDate === "" &&
            this.searchEntryDateFromDate === "" &&
            this.searchEntryDateToDate === "" &&
            this.searchPaymentTermDateFrom === "" &&
            this.searchPaymentTermDateTo === "" &&
            this.searchPaymentDateFrom === "" &&
            this.searchPaymentDateTo === "" &&
            this.searchExportedToOptimaDateFrom === "" &&
            this.searchExportedToOptimaDateTo === "" &&
            this.searchCostType.length === 0 &&
            this.searchPaymentType.length === 0) {
            this.filterAsActive = false;
        }
        else {
            this.filterAsActive = true;
        }
    }

    userIsAdminOrAccountantAdmin(): boolean {
        if (this.currentUserRoles.includes(Roles.Admin) || this.currentUserRoles.includes(Roles.AccountantAdmin)) {
            return true;
        }
        return false;
    }

    searchClick() {
        this.loadCostDocumentList();
    }

    clearSearchContractorClick() {
        this.searchContractorIdcs = [] as number[];
        this.loadCostDocumentList();
    }

    clearSearchSupervisorClick() {
        this.searchSupervisor = {} as SupervisorRestModel;
        this.loadCostDocumentList();
    }

    clearDateTimePicker(clearableValue: string) {
        if (clearableValue == "searchCreatedAtFromDateValue") {
            this.searchCreatedAtFromDate = "";
        }
        if (clearableValue == "searchCreatedAtToDateValue") {
            this.searchCreatedAtToDate = "";
        }
        if (clearableValue == "searchEntryDateFromDateValue") {
            this.searchEntryDateFromDate = "";
        }
        if (clearableValue == "searchEntryDateToDateValue") {
            this.searchEntryDateToDate = "";
        }
        if (clearableValue == "searchReceivedDateFromValue") {
            this.searchReceivedDateFrom = "";
        }
        if (clearableValue == "searchReceivedDateToValue") {

            this.searchReceivedDateTo = "";
        }
        if (clearableValue == "searchSaleDateFromValue") {

            this.searchSaleDateFrom = "";
        }
        if (clearableValue == "searchSaleDateToValue") {

            this.searchSaleDateTo = "";
        }
        if (clearableValue == "searchIssueDateFromValue") {

            this.searchIssueDateFrom = "";
        }
        if (clearableValue == "searchIssueDateToValue") {

            this.searchIssueDateTo = "";
        }
        if (clearableValue == "searchPaymentTermDateFromValue") {

            this.searchPaymentTermDateFrom = "";
        }
        if (clearableValue == "searchPaymentTermDateToValue") {

            this.searchPaymentTermDateTo = "";
        }
        if (clearableValue == "searchPaymentDateFromValue") {

            this.searchPaymentDateFrom = "";
        }
        if (clearableValue == "searchPaymentDateToValue") {

            this.searchPaymentDateTo = "";
        }
        if (clearableValue == "searchExportedToOptimaDateFromValue") {

            this.searchExportedToOptimaDateFrom = "";
        }
        if (clearableValue == "searchExportedToOptimaDateToValue") {

            this.searchExportedToOptimaDateTo = "";
        }
        this.loadCostDocumentList();
    }

    clearAllFilters() {
        this.costDocumentListSearchText = "";
        this.costDocumentListSelectedBranch = 0;
        this.costDocumentListSelectedDocumentStatusIdcs = [] as number[];
        this.searchContractorIdcs = [] as number[];
        this.searchContractors = [] as ContractorSearchItemModel[];
        this.searchSupervisor = {} as SupervisorRestModel;
        this.searchPaymentTermDateFrom = "";
        this.searchPaymentTermDateTo = "";
        this.searchPaymentDateFrom = "";
        this.searchPaymentDateTo = "";
        this.searchExportedToOptimaDateFrom = "";
        this.searchExportedToOptimaDateTo = "";
        this.searchCreatedAtFromDate = "";
        this.searchCreatedAtToDate = "";
        this.searchEntryDateFromDate = "";
        this.searchEntryDateToDate = "";
        this.selectedCountryCodes = [] as string[];
        this.searchIssueDateFrom = "";
        this.searchIssueDateTo = "";
        this.searchSaleDateFrom = "";
        this.searchSaleDateTo = "";
        this.searchReceivedDateFrom = "";
        this.searchReceivedDateTo = "";
        this.isPaid = 0;
        this.isExportedToOptima = 0;
        this.searchCostType = [] as number[];
        this.searchPaymentType = [] as number[];
        this.searchAccountantIdList = [];
        this.searchSelectedCurrency = {} as CurrencyRestModel;

        this.loadCostDocumentList();
    }

    async loadCostDocumentList() {
        if (!this.searchSelectedCurrency.name) {
            this.searchSelectedCurrency = {} as CurrencyRestModel;
        }

        await this.$store.direct.dispatch.settlementCostDocument.loadCostDocumentList({ requestModel: this.prepareCostDocumentRequestModel() });
    }

    async createXlsReportClick() {
        if (!this.searchSelectedCurrency.name) {
            this.searchSelectedCurrency = {} as CurrencyRestModel;
        }

        await this.$store.direct.dispatch.settlementCostDocument.downloadXlsReportCostDocumentList({ requestModel: this.prepareCostDocumentRequestModel() });
    }

    prepareCostDocumentRequestModel() {
        return {
            searchText: this.costDocumentListSearchText,
            searchBranchId: this.costDocumentListSelectedBranch,
            selectedDocumentStatusIdcs: this.costDocumentListSelectedDocumentStatusIdcs,
            page: this.costDocumentListPagerOptions.page,
            pageSize: this.costDocumentListPagerOptions.itemsPerPage,
            sortBy: this.costDocumentListPagerOptions.sortBy[0],
            sortDesc: this.costDocumentListPagerOptions.sortDesc[0],
            searchContractorIdcs: (this.searchContractors != undefined && this.searchContractors.length > 0) ? this.searchContractors.map(x => x.value) : [],
            searchSupervisorId: this.searchSupervisor?.id,
            searchPaymentTermDateFrom: this.searchPaymentTermDateFrom,
            searchPaymentTermDateTo: this.searchPaymentTermDateTo,
            searchPaymentDateFrom: this.searchPaymentDateFrom,
            searchPaymentDateTo: this.searchPaymentDateTo,
            searchExportedToOptimaDateFrom: this.searchExportedToOptimaDateFrom,
            searchExportedToOptimaDateTo: this.searchExportedToOptimaDateTo,
            searchCreatedAtFromDate: this.searchCreatedAtFromDate,
            searchCreatedAtToDate: this.searchCreatedAtToDate,
            searchEntryDateFromDate: this.searchEntryDateFromDate,
            searchEntryDateToDate: this.searchEntryDateToDate,
            searchCountryCodes: this.selectedCountryCodes,
            searchIssueDateFrom: this.searchIssueDateFrom,
            searchIssueDateTo: this.searchIssueDateTo,
            searchSaleDateFrom: this.searchSaleDateFrom,
            searchSaleDateTo: this.searchSaleDateTo,
            searchReceivedDateFrom: this.searchReceivedDateFrom,
            searchReceivedDateTo: this.searchReceivedDateTo,
            isPaid: this.isPaid,
            isExportedToOptima: this.isExportedToOptima,
            idList: this.selectedCostDocumentList.map((x: SettlementCostDocumentListItemRestModel) => {
                return x.id;
            }),
            searchAccountantIdList: this.searchAccountantIdList,
            searchCostType: this.searchCostType,
            searchPaymentType: this.searchPaymentType,
            searchSelectedCurrency: this.searchSelectedCurrency.code,
        } as SettlementCostDocumentListRequestModel
    }

    async createCostDocumentClick() {
        this.$router.push({ path: "/settlement/cost-document/create" });
    }

    editCostDocumentClick(costDocumentId: number, isCostDocumentInHistoricalState: boolean) {
        if (isCostDocumentInHistoricalState) {
            return "/settlement/cost-document/edit/" + costDocumentId + "/" + "history";
        }
        return "/settlement/cost-document/edit/" + costDocumentId;
    }

    async deleteCostDocumentClick(costDocument: SettlementCostDocumentListItemRestModel) {
        this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: "Usun dokument kosztowy",
                message: "Czy na pewno chcesz usunac ten dokument kosztowy?: " + costDocument.documentNumber,
                links: [],
                options: { buttonColor: "error", layoutColor: "red" },
                buttonType: "yes/no"
            })
            .then(async confirm => {
                if (confirm) {
                    await this.$store.direct.dispatch.settlementCostDocument.deleteCostDocument({ costDocumentId: costDocument.id });
                    this.loadCostDocumentList();
                }
            });
    }

    async createPaymentClick(costDocument: SettlementCostDocumentListItemRestModel) {
        await this.$store.direct.dispatch.settlementCostDocument.loadCostDocument({ costDocumentId: costDocument.id });

        const totalPaymentAmount = this.costDocument.paymentObligationList.length == 1 ? this.costDocument.paymentObligationList[0].paymentAmount : 0;
        const paymentCurrencyCode = this.costDocument.paymentObligationList.length == 1 ? this.costDocument.paymentObligationList[0].paymentCurrencyCode : undefined;

        await this.$store.direct.dispatch.settlementPayment.loadCostPayment({ paymentGroupId: "" });

        this.costPayment.paymentAmount = totalPaymentAmount;
        if (paymentCurrencyCode != undefined) {
            this.costPayment.currencyCode = paymentCurrencyCode;
        }

        this.costPayment.paymentCostDocumentRequestList = [{ costDocument: { ...this.costDocument } as any as SettlementCostDocumentRestModel, paymentAmount: 0 } as SettlementPaymentCostDocumentRestModel];

        this.$store.direct.dispatch.settlementPayment
            .openCostPaymentDialog({
                title: `${this.$t("payment.addCostPayment")}`,
            })
            .then(async confirm => {
                if (confirm) {
                    this.loadCostDocumentList();
                }
                this.$store.direct.dispatch.settlementCostDocument.cleanCostDocumentProperties();
            })
    }

    async addOrShowCostDocumentCorrection(costDocument: SettlementCostDocumentListItemRestModel) {
        if (costDocument.hasCorrection) {
            await this.$router.push({ path: "/settlement/cost-document/correction/edit/" + costDocument.documentCorrectionId });
        }
        else {
            await this.$store.direct.dispatch.settlementCostDocument.loadCostDocument({ costDocumentId: costDocument.id });
            await this.$router.push({ path: "/settlement/cost-document/" + costDocument.id + "/correction/create" });
        }
    }

    async copyCostDocumentClick(costDocument: SettlementCostDocumentListItemRestModel) {
        await this.$router.push({ path: "/settlement/cost-document/create" });

        const temporaryCostDocument = this.$store.direct.state.settlementCostDocument.costDocument;
        await this.$store.direct.dispatch.settlementCostDocument.loadCostDocument({ costDocumentId: costDocument.id });

        this.costDocument.id = temporaryCostDocument.id;
        this.costDocument.concurrencyStamp = temporaryCostDocument.concurrencyStamp;
        this.costDocument.isExportedToOptima = false;

        this.costDocument.hasCorrection = false;
        this.costDocument.isDocumentCorrection = false;
        this.costDocument.disableEditingDueToClosedMonth = false;
        this.costDocument.hasNotCancelledTransferOrders = false;

        this.costDocument.documentNumber = undefined as any;
        this.costDocument.paymentStatusId = SettlementPaymentStatusEnum.Niezaplacona;

        this.documentRecalculatingType = this.costDocument.documentRecalculatingType;

        this.costDocument.issueDate = undefined as any;
        this.costDocumentIssueDate = undefined as any;

        this.costDocument.saleDate = undefined as any;
        this.costDocumentSaleDate = undefined as any;

        this.costDocument.entryDate = undefined as any;
        this.costDocumentEntryDate = undefined as any;

        this.costDocument.receivedDate = new Date().toString();
        this.costDocumentReceivedDate = new Date().toString();

        this.costDocument.paymentDate = undefined as any;
        this.costDocumentPaymentDate = undefined as any;

        this.costDocument.costDocumentItems.forEach((x: SettlementCostDocumentItemRestModel) => {
            x.id = 0;
        });

        this.costDocument.costDocumentBankAccounts.forEach((x: SettlementCostDocumentBankAccountRestModel) => {
            x.id = 0;
        });

        this.costDocument.costDocumentSupervisors = [] as SupervisorForSettlementRestModel[];
        this.costDocumentSupervisorList = [] as SupervisorForSettlementRestModel[];

        this.costDocumentBankAccountList = this.costDocument.costDocumentBankAccounts;
    }

    selectAllItemsOnThePage() {
        this.costDocumentList.forEach((x: SettlementCostDocumentListItemRestModel) => {
            x.isChecked = this.mainCheckboxIsSelected;
        });
        this.$forceUpdate();

        this.selectedCostDocumentList = this.costDocumentList.filter((x: SettlementCostDocumentListItemRestModel) => {
            if (x.isChecked) {
                return x.id;
            }
        });

        this.$store.direct.dispatch.settlementCommon.calculateSelectedCostOrIncomeDocumentListForAmountSummaryRow({ list: this.selectedCostDocumentList });
    }

    addSelectedCostDocumentToList() {
        this.selectedCostDocumentList = this.costDocumentList.filter((x: SettlementCostDocumentListItemRestModel) => {
            if (x.isChecked) {
                return x.id;
            }
            else {
                this.mainCheckboxIsSelected = false;
            }
        });

        this.$store.direct.dispatch.settlementCommon.calculateSelectedCostOrIncomeDocumentListForAmountSummaryRow({ list: this.selectedCostDocumentList });
    }

    hideFiltersMenuClick() {
        this.showFiltersMenu = false;
    }

    currentPageInput = this.costDocumentListPagerOptions.page;
    goToPage() {
        if (this.currentPageInput > 0) {
            this.costDocumentListPagerOptions.page = this.currentPageInput;
        }
    }

    destroyed() {
        this.selectedCostDocumentList = [] as SettlementCostDocumentListItemRestModel[];
    }
}
