import dayjs from "dayjs";
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import ConfirmDialog from "../../../layouts/confirmDialog/confirmDialog.vue";
import { BranchEnum } from "../../../models/emums/branchEnum";
import { ContractorTypes } from "../../../models/emums/contractorTypes";
import { Roles } from "../../../models/emums/roles";
import { SettlementDocumentRecalculatingTypeEnum } from "../../../models/emums/settlement/settlementDocumentRecalculatingTypeEnum";
import { SettlementPaymentStatusEnum } from "../../../models/emums/settlement/settlementPaymentStatusEnum";
import { SettlementPaymentTypeEnum } from "../../../models/emums/settlement/settlementPaymentTypeEnum";
import ListItemNumberModel from "../../../models/listItemNumberModel";
import ContractorBankAccountRestModel from "../../../models/rest/contractor/contractorBankAccountRestModel";
import ContractorDetailsForSettlementRestModel from "../../../models/rest/contractor/contractorDetailsForSettlementRestModel";
import ContractorSearchItemModel from "../../../models/rest/contractor/contractorSearchItemModel";
import CostTypeRestModel from "../../../models/rest/register/costTypeRestModel";
import CountryRestModel from "../../../models/rest/register/countryRestModel";
import CurrencyRateRestModel from "../../../models/rest/register/currencyRateRestModel";
import CurrencyRestModel from "../../../models/rest/register/currencyRestModel";
import SettlementCostDocumentAttachmentRestModel from "../../../models/rest/settlement/cost-document/settlementCostDocumentAttachmentRestModel";
import { default as CostDocumentBankAccountRestModel, default as SettlementCostDocumentBankAccountRestModel } from "../../../models/rest/settlement/cost-document/settlementCostDocumentBankAccountRestModel";
import SettlementCostDocumentItemRestModel from "../../../models/rest/settlement/cost-document/settlementCostDocumentItemRestModel";
import SettlementCostDocumentMementoItemRestModel from "../../../models/rest/settlement/cost-document/settlementCostDocumentMementoItemRestModel";
import SettlementCostDocumentRestModel from "../../../models/rest/settlement/cost-document/settlementCostDocumentRestModel";
import SettlementDocumentSaveResponseRestModel from "../../../models/rest/settlement/settlementDocumentSaveResponseRestModel";
import SettlementDocumentStatusRestModel from "../../../models/rest/settlement/settlementDocumentStatusRestModel";
import SettlementDocumentTypeRestModel from "../../../models/rest/settlement/settlementDocumentTypeRestModel";
import SupervisorForSettlementRestModel from "../../../models/rest/supervisor/supervisorForSettlementRestModel";
import SupervisorRestModel from "../../../models/rest/supervisor/supervisorRestModel";
import router from "../../../router";
import SupervisorDialog from "../supervisor-dialog.vue";
import ContractorDialog from "./../../supervisor/dialog/contractor-dialog.vue";
import AttachmentDialog from "./dialog/attachment-dialog.vue";
import BankAccountDialog from "./dialog/bank-account-dialog.vue";
import CostDocumentItemDialog from "./dialog/cost-document-item-dialog.vue";


@Component({
    components: {
        "attachment-dialog": AttachmentDialog,
        "confirm-dialog": ConfirmDialog,
        "contractor-dialog": ContractorDialog,
        "bank-account-dialog": BankAccountDialog,
        "cost-document-item-dialog": CostDocumentItemDialog,
        "supervisor-dialog": SupervisorDialog,
    }
})

export default class CostDocument extends Vue {
    SettlementPaymentStatusEnum = SettlementPaymentStatusEnum;
    SettlementDocumentRecalculatingTypeEnum = SettlementDocumentRecalculatingTypeEnum;
    isCostDocumentNew = false;
    isCostDocumentCorrectionNew = false;
    isCostDocumentCorrected = false;
    toolbarTitle = "";
    contractorSearch = "";
    entryDateLabel = this.$t("costDocument.entryDate");
    issueDateLabel = this.$t("costDocument.issueDate");
    saleDateLabel = this.$t("costDocument.saleDate");
    receivedDateLabel = this.$t("costDocument.receivedDate");
    paymentDateLabel = this.$t("costDocument.paymentDate");
    paymentTermDateLabel = this.$t("costDocument.paymentTermDateInDate");
    currencyRateDateLabel = this.$t("costDocument.rateDate");
    plnCurrency = {} as CurrencyRestModel | undefined;
    discountEnabled = false;
    costDocumentBankAccountsLengthError = null as string | null;
    costDocumentItemsLengthError = null as string | null;
    costDocumentItemPriceError = null as string | null;
    costDocumentItemNettoPlnError = null as string | null;
    costDocumentItemPricePlnError = null as string | null;
    costDocumentItemBruttoError = null as string | null;
    costDocumentItemBruttoPlnError = null as string | null;
    costDocumentItemCostTypeError = null as string | null;
    costDocumentAdditionalListPagerOptions = {
        itemsPerPage: -1,
    };
    costDocumentItemCorrectionDifferenceList = [] as SettlementCostDocumentItemRestModel[];
    assignSupervisorWasClicked = false;
    previouslyAssignedSupervisorIdcs = [] as number[];
    lastCostDocumentMementoPoint: number = 0;
    costDocumentMementoSelectedPoint: number = 0;
    paymentTermDate = "";

    rules = {
        branchId: [
            () => {
                const error = this.validationError.BranchId != undefined ? this.validationError.BranchId[0] : "";
                return error == "" || error;
            }],
        documentNumber: [
            () => {
                const error = this.validationError.DocumentNumber != undefined ? this.validationError.DocumentNumber[0] : "";
                return error == "" || error;
            }],
        documentCountry: [
            () => {
                const error = this.validationError.DocumentCountry != undefined ? this.validationError.DocumentCountry[0] : "";
                return error == "" || error;
            }],
        contractor: [
            () => {
                const error = this.validationError.Contractor != undefined ? this.validationError.Contractor[0] : "";
                return error == "" || error;
            }],
        contractorTinPrefix: [
            () => {
                const error = this.validationError.ContractorTinPrefix != undefined ? this.validationError.ContractorTinPrefix[0] : "";
                return error == "" || error;
            }],
        contractorTin: [
            () => {
                const error = this.validationError.ContractorTin != undefined ? this.validationError.ContractorTin[0] : "";
                return error == "" || error;
            }],
        contractorName: [
            () => {
                const error = this.validationError.ContractorName != undefined ? this.validationError.ContractorName[0] : "";
                return error == "" || error;
            }],
        contractorSymbol: [
            () => {
                const error = this.validationError.ContractorSymbol != undefined ? this.validationError.ContractorSymbol[0] : "";
                return error == "" || error;
            }],
        contractorStreetAndNumber: [
            () => {
                const error = this.validationError.ContractorStreetAndNumber != undefined ? this.validationError.ContractorStreetAndNumber[0] : "";
                return error == "" || error;
            }],
        contractorCountry: [
            () => {
                const error = this.validationError.ContractorCountry != undefined ? this.validationError.ContractorCountry[0] : "";
                return error == "" || error;
            }],
        contractorPostalCode: [
            () => {
                const error = this.validationError.ContractorPostalCode != undefined ? this.validationError.ContractorPostalCode[0] : "";
                return error == "" || error;
            }],
        contractorCity: [
            () => {
                const error = this.validationError.ContractorCity != undefined ? this.validationError.ContractorCity[0] : "";
                return error == "" || error;
            }],
        paymentTerm: [
            () => {
                const error = this.validationError.PaymentTerm != undefined ? this.validationError.PaymentTerm[0] : "";
                return error == "" || error;
            }],
        currencyCode: [
            () => {
                const error = this.validationError.CurrencyCode != undefined ? this.validationError.CurrencyCode[0] : "";
                return error == "" || error;
            }],
        currencyRate: [
            () => {
                const error = this.validationError.CurrencyRate != undefined ? this.validationError.CurrencyRate[0] : "";
                return error == "" || error;
            }],
        currencyRateDate: [
            () => {
                const error = this.validationError.CurrencyRateDate != undefined ? this.validationError.CurrencyRateDate[0] : "";
                return error == "" || error;
            }],
        costTypeId: [
            () => {
                const error = this.validationError.CostTypeId != undefined ? this.validationError.CostTypeId[0] : "";
                return error == "" || error;
            }],
        costDocumentStatusId: [
            () => {
                const error = this.validationError.CostDocumentStatusId != undefined ? this.validationError.CostDocumentStatusId[0] : "";
                return error == "" || error;
            }],
        documentTypeId: [
            () => {
                const error = this.validationError.DocumentTypeId != undefined ? this.validationError.DocumentTypeId[0] : "";
                return error == "" || error;
            }],
        paymentTypeId: [
            () => {
                const error = this.validationError.PaymentTypeId != undefined ? this.validationError.PaymentTypeId[0] : "";
                return error == "" || error;
            }],
        documentRecalculatingType: [
            () => {
                const error = this.validationError.DocumentRecalculatingType != undefined ? this.validationError.DocumentRecalculatingType[0] : "";
                return error == "" || error;
            }],
        issueDate: [
            () => {
                const error = this.validationError.IssueDate != undefined ? this.validationError.IssueDate[0] : "";
                return error == "" || error;
            }],
        saleDate: [
            () => {
                const error = this.validationError.SaleDate != undefined ? this.validationError.SaleDate[0] : "";
                return error == "" || error;
            }],
        entryDate: [
            () => {
                const error = this.validationError.EntryDate != undefined ? this.validationError.EntryDate[0] : "";
                return error == "" || error;
            }],
        receivedDate: [
            () => {
                const error = this.validationError.ReceivedDate != undefined ? this.validationError.ReceivedDate[0] : "";
                return error == "" || error;
            }],
        costDocumentItems: [
            () => {
                const error = this.validationError.CostDocumentItems != undefined ? this.validationError.CostDocumentItems[0] : "";
                return error == "" || error;
            }],
        costDocumentItemDescription: [
            () => {
                const error = this.validationError.Description != undefined ? this.validationError.Description[0] : "";
                return error == "" || error;
            }],
        costDocumentItemPrice: [
            () => {
                const error = this.validationError.Price != undefined ? this.validationError.Price[0] : "";
                return error == "" || error;
            }],
    }

    costDocumentBankAccountListHeaders = [
        {
            text: this.$t("costDocument.accountNumber"),
            align: "start",
            value: "bankAccountNumber",
            sortable: false,
        },
        {
            text: this.$t("common.currency"),
            align: "start",
            value: "bankAccountCurrencyCode",
            sortable: false,
        },
        {
            text: this.$t("costDocument.accountCountry"),
            align: "start",
            value: "bankAccountCountryCode",
            sortable: false,
        },
        {
            text: this.$t("costDocument.iban"),
            align: "start",
            value: "bankAccountIban",
            sortable: false,
        },
        {
            text: this.$t("costDocument.swift"),
            align: "start",
            value: "bankAccountSwift",
            sortable: false,
        },
        {
            text: this.$t("costDocument.bankName"),
            align: "start",
            value: "bankName",
            sortable: false,
        },
        {
            text: this.$t("costDocument.vatAccount"),
            align: "center",
            value: "bankAccountIsVatAccount",
            sortable: false,
        },
        {
            text: this.$t("common.actions"),
            align: "right",
            value: "actions",
            width: "1%",
            sortable: false,
        }
    ];

    costDocumentItemListHeaders = [
        {
            text: this.$t("costDocument.costType"),
            align: "start",
            value: "costType.name",
            sortable: false,
        },
        {
            text: this.$t("costDocument.itemDescriptionDetailed"),
            align: "start",
            value: "descriptionDetailed",
            sortable: false,
        },
        {
            text: this.$t("costDocument.itemQuantity"),
            align: "start",
            value: "quantity",
            sortable: false,
        },
        {
            text: this.$t("costDocument.itemUnit"),
            align: "start",
            value: "unit",
            sortable: false,
        },
        {
            text: this.$t("costDocument.itemPrice"),
            align: "right",
            value: "price",
            sortable: false,
        },
        {
            text: this.$t("costDocument.itemNettoValue"),
            align: "right",
            value: "netto",
            sortable: false,
        },
        {
            text: this.$t("costDocument.itemTaxRate"),
            align: "right",
            value: "taxRate",
            sortable: false,
        },
        {
            text: this.$t("costDocument.itemTaxValue"),
            align: "right",
            value: "tax",
            sortable: false,
        },
        {
            text: this.$t("costDocument.itemBruttoValue"),
            align: "right",
            value: "brutto",
            sortable: false,
        },
        {
            text: this.$t("costDocument.itemPriceInPln"),
            align: "right",
            value: "pricePln",
            sortable: false,
        },
        {
            text: this.$t("costDocument.itemNettoValueInPln"),
            align: "right",
            value: "nettoPln",
            sortable: false,
        },
        {
            text: this.$t("costDocument.itemTaxValueInPln"),
            align: "right",
            value: "taxPln",
            sortable: false,
        },
        {
            text: this.$t("costDocument.itemBruttoValueInPln"),
            align: "right",
            value: "bruttoPln",
            sortable: false,
        },
        {
            text: this.$t("common.actions"),
            align: "right",
            value: "actions",
            width: "1%",
            sortable: false,
        }
    ];

    costDocumentSupervisorListHeaders = [
        {
            text: this.$t("supervisor.orderId"),
            align: "start",
            value: "orderId",
            sortable: false,
        },
        {
            text: this.$t("supervisor.route"),
            align: "start",
            value: "route",
            sortable: false,
        },
        {
            text: this.$t("supervisor.accountingComment"),
            align: "start",
            value: "accountingComment",
            sortable: false,
        },
        {
            text: this.$t("common.createdBy"),
            align: "start",
            value: "username",
            sortable: false,
        },
        {
            text: this.$t("common.createdAt"),
            align: "start",
            value: "createdAt",
            sortable: false,
        },
        {
            text: this.$t("supervisor.forwarderFreight"),
            align: "start",
            value: "purchaseValueWithCurrency",
            sortable: false,
        },
        {
            text: this.$t("common.actions"),
            align: "right",
            value: "actions",
            width: "1%",
            sortable: false,
        }
    ];

    costDocumentAttachmentListHeaders = [
        {
            text: this.$t("costDocument.name"),
            align: "start",
            value: "name",
            sortable: false,
        },
        {
            text: this.$t("common.actions"),
            align: "right",
            value: "actions",
            width: "1%",
            sortable: false,
        }
    ];

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get validationError(): any {
        return this.$store.direct.state.settlementCostDocument.costDocumentValidationError;
    }

    set validationError(val: any) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentValidationError(val);
    }

    get costDocument(): SettlementCostDocumentRestModel {
        const costDocument = this.$store.direct.state.settlementCostDocument.costDocument;

        if (costDocument.contractor != undefined) {
            if (this.contractorDetailsForSettlement == undefined || Object.keys(this.contractorDetailsForSettlement).length === 0) {
                this.loadContractorDetailsForSettlement(costDocument.contractor.value);
            }

            this.contractorFound.push(costDocument.contractor);
        }

        if (costDocument.currencyRate != undefined) {
            this.currencyRate = costDocument.currencyRate;
        }

        costDocument.discount = costDocument.discount == undefined ? 0 : costDocument.discount;
        if (costDocument.discount != 0) {
            this.discountEnabled = true;
        }

        if (this.paymentTermDate == "" && costDocument.entryDate != null) {
            const entryDate = new Date(this.costDocumentEntryDate);
            entryDate.setUTCDate(entryDate.getDate() + Number(costDocument.paymentTerm));
            this.paymentTermDate = entryDate.toISOString().split('T')[0];
        }

        return costDocument;
    }

    set costDocument(val: SettlementCostDocumentRestModel) {
        this.$store.direct.commit.settlementCostDocument.setCostDocument(val);
    }

    get documentRecalculatingType(): SettlementDocumentRecalculatingTypeEnum {
        return this.$store.direct.state.settlementCostDocument.costDocumentDocumentRecalculatingType;
    }

    set documentRecalculatingType(val: SettlementDocumentRecalculatingTypeEnum) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentDocumentRecalculatingType(val);
    }

    get costDocumentIssueDate() {
        let issueDate = this.$store.direct.state.settlementCostDocument.costDocumentIssueDate;
        if (issueDate && issueDate != null && issueDate != undefined) {
            const formattedIssueDate = this.formatDate(issueDate);
            issueDate = formattedIssueDate != undefined ? formattedIssueDate : "";
        }

        return issueDate;
    }

    set costDocumentIssueDate(val: string) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentIssueDate(val);
    }

    get costDocumentPaymentDate() {
        let paymentDate = this.$store.direct.state.settlementCostDocument.costDocumentPaymentDate;
        if (paymentDate && paymentDate != null && paymentDate != undefined) {
            const formattedPaymentDate = this.formatDate(paymentDate);
            paymentDate = formattedPaymentDate != undefined ? formattedPaymentDate : "";
        }

        return paymentDate;
    }

    set costDocumentPaymentDate(val: string) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentPaymentDate(val);
    }

    get costDocumentReceivedDate() {
        let receivedDate = this.$store.direct.state.settlementCostDocument.costDocumentReceivedDate;
        if (receivedDate && receivedDate != null && receivedDate != undefined) {
            const formattedReceivedDate = this.formatDate(receivedDate);
            receivedDate = formattedReceivedDate != undefined ? formattedReceivedDate : "";
        }

        return receivedDate;
    }

    set costDocumentReceivedDate(val: string) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentReceivedDate(val);
    }

    get costDocumentEntryDate() {
        let entryDate = this.$store.direct.state.settlementCostDocument.costDocumentEntryDate;
        if (entryDate && entryDate != null && entryDate != undefined) {
            const formattedEntryDate = this.formatDate(entryDate);
            entryDate = formattedEntryDate != undefined ? formattedEntryDate : "";
        }

        return entryDate;
    }

    set costDocumentEntryDate(val: string) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentEntryDate(val);
    }

    get costDocumentSaleDate() {
        let saleDate = this.$store.direct.state.settlementCostDocument.costDocumentSaleDate;
        if (saleDate && saleDate != null && saleDate != undefined) {
            const formattedSaleDate = this.formatDate(saleDate);
            saleDate = formattedSaleDate != undefined ? formattedSaleDate : "";
        }

        return saleDate;
    }

    set costDocumentSaleDate(val: string) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentSaleDate(val);
    }

    get costDocumentBankAccount(): SettlementCostDocumentBankAccountRestModel {
        return this.$store.direct.state.settlementCostDocument.costDocumentBankAccount;
    }

    set costDocumentBankAccount(val: SettlementCostDocumentBankAccountRestModel) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentBankAccount(val);
    }

    get costDocumentBankAccountList(): SettlementCostDocumentBankAccountRestModel[] {
        let costDocumentBankAccountList = this.$store.direct.state.settlementCostDocument.costDocumentBankAccountList;
        if (costDocumentBankAccountList == undefined) {
            costDocumentBankAccountList = [] as SettlementCostDocumentBankAccountRestModel[];
        }

        return costDocumentBankAccountList;
    }

    set costDocumentBankAccountList(val: SettlementCostDocumentBankAccountRestModel[]) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentBankAccountList(val);
    }

    get costDocumentItem(): SettlementCostDocumentItemRestModel {
        return this.$store.direct.state.settlementCostDocument.costDocumentItem;
    }

    set costDocumentItem(val: SettlementCostDocumentItemRestModel) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentItem(val);
    }

    get costDocumentItemList(): SettlementCostDocumentItemRestModel[] {
        let costDocumentItemList = this.$store.direct.state.settlementCostDocument.costDocumentItemList;
        if (costDocumentItemList == undefined) {
            costDocumentItemList = [] as SettlementCostDocumentItemRestModel[];
        }

        if (!this.isCostDocumentCorrected) {
            this.recalculateCostDocumentItems(costDocumentItemList);
        }

        this.calculateItemsAmounts(costDocumentItemList);

        return costDocumentItemList;
    }

    set costDocumentItemList(val: SettlementCostDocumentItemRestModel[]) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentItemList(val);
    }

    get costDocumentItemCorrectionList(): SettlementCostDocumentItemRestModel[] {
        let costDocumentItemCorrectionList = this.$store.direct.state.settlementCostDocument.costDocumentItemCorrectionList;
        if (costDocumentItemCorrectionList == undefined) {
            costDocumentItemCorrectionList = [] as SettlementCostDocumentItemRestModel[];
        }

        if (this.isCostDocumentCorrected) {
            this.recalculateCostDocumentItems(costDocumentItemCorrectionList);

            this.assignCostDocumentItemCorrectionDifferenceList(costDocumentItemCorrectionList);
        }

        return costDocumentItemCorrectionList;
    }

    set costDocumentItemCorrectionList(val: SettlementCostDocumentItemRestModel[]) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentItemCorrectionList(val);
    }

    get costDocumentSupervisorList(): SupervisorForSettlementRestModel[] {
        let costDocumentSupervisorList = this.$store.direct.state.settlementCommon.supervisorList;

        this.calculateCostDocumentItemPriceAfterAddedNewSupervisor(costDocumentSupervisorList);

        if (costDocumentSupervisorList == undefined) {
            costDocumentSupervisorList = [] as SupervisorForSettlementRestModel[];
        }

        return costDocumentSupervisorList;
    }

    set costDocumentSupervisorList(val: SupervisorForSettlementRestModel[]) {
        this.$store.direct.commit.settlementCommon.setSupervisorList(val);
    }

    get contractorFound(): ContractorSearchItemModel[] {
        return this.$store.direct.state.common.contractorSearch;
    }

    set contractorFound(val: ContractorSearchItemModel[]) {
        this.$store.direct.commit.common.setContractorSearch(val);
    }

    get contractorDetailsForSettlement(): ContractorDetailsForSettlementRestModel {
        const contractorDetailsForSettlement = this.$store.direct.state.contractor.contractorDetailsForSettlement;
        this.showAccountingInfoDialog(contractorDetailsForSettlement);

        return contractorDetailsForSettlement;
    }

    set contractorDetailsForSettlement(val: ContractorDetailsForSettlementRestModel) {
        this.$store.direct.commit.contractor.setContractorDetailsForSettlement(val);
    }

    get countryList(): CountryRestModel[] {
        return this.$store.direct.state.contractor.contractorAssets.countries;
    }

    get costTypeList(): CostTypeRestModel[] {
        return this.$store.direct.state.settlementCommon.settlementAssets.costTypes;
    }

    get paymentStatuses(): ListItemNumberModel[] {
        return this.$store.direct.state.settlementCommon.settlementAssets.paymentStatuses;
    }

    get paymentTypeList(): ListItemNumberModel[] {
        const paymentTypes = this.$store.direct.state.settlementCommon.settlementAssets.paymentTypes;
        const mappedPaymentTypes = [] as ListItemNumberModel[];

        if (paymentTypes != undefined) {
            paymentTypes.forEach((x: ListItemNumberModel) => {
                if (x.value != 3) {
                    mappedPaymentTypes.push({ text: x.text, value: x.value, disabled: false } as any);
                }
                else {
                    mappedPaymentTypes.push({ text: x.text, value: x.value, disabled: true } as any);
                }
            })
        }

        return mappedPaymentTypes;
    }

    get documentTypes(): SettlementDocumentTypeRestModel[] {
        return this.$store.direct.state.settlementCommon.settlementAssets.documentTypes;
    }

    get costDocumentStatuses(): SettlementDocumentStatusRestModel[] {
        return this.$store.direct.state.settlementCommon.settlementAssets.costDocumentStatuses;
    }

    get currencies(): CurrencyRestModel[] {
        const currencies = this.$store.direct.state.contractor.contractorAssets.currencies;

        if (currencies != undefined) {
            this.plnCurrency = currencies.find((x: CurrencyRestModel) => {
                return x.code == "PLN"
            });
        }
        return currencies;
    }

    get currencyRate(): CurrencyRateRestModel {
        const currencyRate = this.$store.direct.state.settlementCommon.currencyRate;
        if (currencyRate.rateDate != undefined) {
            const formattedDate = this.formatDate(currencyRate.rateDate);
            currencyRate.rateDate = formattedDate != undefined ? formattedDate : "";
        }
        return currencyRate;
    }

    set currencyRate(val: CurrencyRateRestModel) {
        this.$store.direct.commit.settlementCommon.setCurrencyRate(val);
    }

    get branchList(): ListItemNumberModel[] {
        return this.$store.direct.state.contractor.contractorAssets.branches;
    }

    get costDocumentSaveResponse(): SettlementDocumentSaveResponseRestModel {
        return this.$store.direct.state.settlementCostDocument.costDocumentSaveResponse;
    }

    get costDocumentAttachmentList(): SettlementCostDocumentAttachmentRestModel[] {
        return this.$store.direct.state.settlementCostDocument.attachmentList;
    }

    get contractorBankAccounts(): ContractorBankAccountRestModel[] {
        return this.$store.direct.state.contractor.bankAccountList;
    }

    set contractorBankAccounts(val: ContractorBankAccountRestModel[]) {
        this.$store.direct.commit.contractor.setBankAccountList(val);
    }

    get previousUrlName(): string | undefined | null {
        return this.$store.direct.state.settlementCommon.previousUrlName;
    }

    get costDocumentMementoList(): SettlementCostDocumentMementoItemRestModel[] {
        return this.$store.direct.state.settlementCostDocument.costDocumentMementoList;
    }

    get costDocumentMementoListCount(): number {
        const costDocumentMementoListCount = this.$store.direct.state.settlementCostDocument.costDocumentMementoListCount - 1;
        this.lastCostDocumentMementoPoint = costDocumentMementoListCount;
        this.costDocumentMementoSelectedPoint = costDocumentMementoListCount;
        return costDocumentMementoListCount;
    }

    get isCostDocumentInHistoricalState(): boolean {
        return this.$store.direct.state.settlementCostDocument.isCostDocumentInHistoricalState;
    }

    set isCostDocumentInHistoricalState(val: boolean) {
        this.$store.direct.commit.settlementCostDocument.setIsCostDocumentInHistoricalState(val);
    }

    get currentUserRoles() {
        return this.$store.direct.state.auth.userRoles;
    }

    get userIsAdminOrAccountantAdmin(): boolean {
        return this.currentUserRoles.includes(Roles.Admin) || this.currentUserRoles.includes(Roles.AccountantAdmin);
    }

    get documentIsPaidAndUserIsNotAdminOrAccountantAdmin(): boolean {
        const result = !this.userIsAdminOrAccountantAdmin && this.costDocument.paymentStatusId != SettlementPaymentStatusEnum.Niezaplacona
        return result;
    }

    get userIsAdmin(): boolean {
        return this.currentUserRoles.includes(Roles.Admin);
    }

    get showAddContractorButton(): boolean {
        if (this.contractorFound.length == 0 && this.contractorSearch) {
            return true;
        }
        return false;
    }

    get contractorTypes(): any {
        return this.$store.direct.state.contractor.contractorTypeList;
    }

    @Watch("paymentTermDate")
    paymentTermDateHandler(newDate: any, oldDate: any) {
        if (newDate != oldDate) {
            const data1: Date = new Date(this.paymentTermDate);
            const data2: Date = new Date(this.costDocumentEntryDate);
            const differenceInMiliseconds: number = data1.getTime() - data2.getTime();
            const differenceInDays: number = differenceInMiliseconds / (1000 * 60 * 60 * 24);
            const result = Math.ceil(differenceInDays);
            this.costDocument.paymentTerm = result;
        }
    }

    paymentTermChanged() {
        const entryDate = new Date(this.costDocumentEntryDate);
        entryDate.setUTCDate(entryDate.getDate() + Number(this.costDocument.paymentTerm));
        this.paymentTermDate = entryDate.toISOString().split('T')[0];
    }

    @Watch("costDocument.branchId")
    costDocumentHandler(val: number) {
        this.setCostDocumentDocumentRecalculatingTypeByBranch();
    }

    @Watch("contractorSearch")
    async contractorSearchLoadItems(val: string) {
        if (this.contractorSearch) {
            await this.$store.direct.dispatch.common.searchContractors({ searchText: val, contractorTypes: [], contractorPropertiesEnabled: [], source: "" });
        }
    }

    @Watch("costDocumentSaleDate")
    async costDocumentSaleDateHandler(val: string) {
        await this.getCurrencyRate();
    }

    @Watch("costDocumentIssueDate")
    async costDocumentIssueDateHandler(val: string) {
        await this.getCurrencyRate();
    }

    @Watch("discountEnabled")
    async discountEnabledHandler(val: boolean) {
        if (this.discountEnabled == false) {
            this.costDocument.discount = 0;
            await this.discountChanged();
        }
    }

    @Watch("documentRecalculatingType")
    async documentRecalculatingTypeHandler(val: number) {
        if (val == 1) {
            this.costDocument.isVatInPln = true;
        } else {
            this.costDocument.isVatInPln = false;
        }
    }

    @Watch("costDocument.isVatInPln")
    async costDocumentIsVatInPlnHandler(val: number) {
        this.assignCostDocumentBankAccounts();
    }

    @Watch("costDocumentBankAccountList", { deep: true })
    async costDocumentBankAccountListHandler(val: SettlementCostDocumentBankAccountRestModel[]) {
        if (val.length != 1) {
            this.costDocument.isSplitPayment = false;
        }
    }

    @Watch("validationError.CostDocumentBankAccounts")
    async validationErrorCostDocumentBankAccountsHandler(val: any) {
        if (this.validationError.CostDocumentBankAccounts == undefined) {
            this.costDocumentBankAccountsLengthError = null;
        }
        else {
            this.costDocumentBankAccountsLengthError = this.validationError.CostDocumentBankAccounts[0];
            setTimeout(() => {
                this.costDocumentBankAccountsLengthError = null;
            }, 3000)
        }
    }

    @Watch("validationError.CostDocumentItems")
    async validationErrorCostDocumentItemsHandler(val: any) {
        if (this.validationError.CostDocumentItems == undefined) {
            this.costDocumentItemsLengthError = null;
        }
        else {
            this.costDocumentItemsLengthError = this.validationError.CostDocumentItems[0];
            setTimeout(() => {
                this.costDocumentItemsLengthError = null;
            }, 3000)
        }
    }

    @Watch("validationError.Price")
    async validationErrorCostDocumentItemPriceHandler(val: any) {
        if (this.validationError.Price == undefined) {
            this.costDocumentItemPriceError = null;
        }
        else {
            this.costDocumentItemPriceError = this.validationError.Price[0];
            setTimeout(() => {
                this.costDocumentItemPriceError = null;
            }, 3000)
        }
    }

    @Watch("validationError.NettoPln")
    async validationErrorCostDocumentItemNettoPlnHandler(val: any) {
        if (this.validationError.NettoPln == undefined) {
            this.costDocumentItemNettoPlnError = null;
        }
        else {
            this.costDocumentItemNettoPlnError = this.validationError.NettoPln[0];
            setTimeout(() => {
                this.costDocumentItemNettoPlnError = null;
            }, 3000)
        }
    }

    @Watch("validationError.PricePln")
    async validationErrorCostDocumentItemPricePlnHandler(val: any) {
        if (this.validationError.PricePln == undefined) {
            this.costDocumentItemPricePlnError = null;
        }
        else {
            this.costDocumentItemPricePlnError = this.validationError.PricePln[0];
            setTimeout(() => {
                this.costDocumentItemPricePlnError = null;
            }, 3000)
        }
    }

    @Watch("validationError.Brutto")
    async validationErrorCostDocumentItemBruttoHandler(val: any) {
        if (this.validationError.Brutto == undefined) {
            this.costDocumentItemBruttoError = null;
        }
        else {
            this.costDocumentItemBruttoError = this.validationError.Brutto[0];
            setTimeout(() => {
                this.costDocumentItemBruttoError = null;
            }, 3000)
        }
    }

    @Watch("validationError.BruttoPln")
    async validationErrorCostDocumentItemBruttoPlnHandler(val: any) {
        if (this.validationError.BruttoPln == undefined) {
            this.costDocumentItemBruttoPlnError = null;
        }
        else {
            this.costDocumentItemBruttoPlnError = this.validationError.BruttoPln[0];
            setTimeout(() => {
                this.costDocumentItemBruttoPlnError = null;
            }, 3000)
        }
    }

    @Watch("validationError.CostType")
    async validationErrorCostDocumentItemCostTypeHandler(val: any) {
        if (this.validationError.CostType == undefined) {
            this.costDocumentItemCostTypeError = null;
        }
        else {
            this.costDocumentItemCostTypeError = this.validationError.CostType[0];
            setTimeout(() => {
                this.costDocumentItemCostTypeError = null;
            }, 3000)
        }
    }

    async created() {
        if (this.$route.name == "Create cost document") {
            this.isCostDocumentNew = true;
            this.isCostDocumentCorrected = false;
            this.costDocument.isDocumentCorrection = false;
            this.toolbarTitle = this.$t("costDocument.addCostDocument").toString();
        }
        if (this.$route.name == "Edit cost document") {
            this.isCostDocumentNew = false;
            this.isCostDocumentCorrected = false;
            this.costDocument.isDocumentCorrection = false;
            this.toolbarTitle = this.$t("costDocument.editCostDocument").toString();
        }
        if (this.$route.name == "Create cost document correction") {
            this.isCostDocumentCorrected = true;
            this.isCostDocumentCorrectionNew = true;
            this.costDocument.isDocumentCorrection = true;
            this.toolbarTitle = this.$t("costDocument.addCostDocumentCorrection").toString() + " - do: " + this.costDocument.correctedDocumentNumber;;
        }
        if (this.$route.name == "Edit cost document correction") {
            this.isCostDocumentCorrected = true;
            this.isCostDocumentCorrectionNew = false;
            this.costDocument.isDocumentCorrection = true;
            this.toolbarTitle = this.$t("costDocument.editCostDocumentCorrection").toString() + " - do: " + this.costDocument.correctedDocumentNumber;;
        }

        await this.$store.direct.dispatch.contractor.loadContractorAssets();
        await this.$store.direct.dispatch.settlementCommon.loadSettlementAssets();
        this.$store.direct.dispatch.settlementCostDocument.loadCostDocumentAttachmentList();

        if (this.$route.params.id == undefined) {
            this.costDocument.costTypeId = 9;
            this.costDocument.costDocumentStatusId = 1;
            this.costDocument.documentTypeId = 1;
            this.costDocument.paymentStatusId = SettlementPaymentStatusEnum.Niezaplacona;
            this.costDocument.paymentTypeId = SettlementPaymentTypeEnum.PRZELEW;
            this.costDocumentReceivedDate = dayjs().format("YYYY-MM-DD");
            this.costDocumentEntryDate = dayjs().format("YYYY-MM-DD");

            this.isCostDocumentNew = true;

            if (this.costDocumentSupervisorList.length == 0) {
                this.costDocument.currencyCode = "EUR";
            }
        }
        else {
            this.documentRecalculatingType = this.costDocument.documentRecalculatingType;
            this.loadCountryRateList();
        }

        this.setCostDocumentDocumentRecalculatingTypeByBranch();

        await this.$root.$on('loadContractorBankAccountListAndAssignCostDocumentBankAccounts', async () => {
            await this.loadContractorBankAccountListAndAssignCostDocumentBankAccounts();
        });
    }

    async loadContractorDetailsForSettlement(contractorId: number) {
        await this.$store.direct.dispatch.contractor.getContractorDetailsForSettlement({ contractorId: contractorId });
    }

    async showAccountingInfoDialog(contractorDetailsForSettlement: ContractorDetailsForSettlementRestModel) {
        if (contractorDetailsForSettlement.accountingInfo != undefined) {
            await this.$store.direct.dispatch.confirm
                .openConfirmDialog({
                    showConfirmDialog: true,
                    title: `${this.$t("contractor.accountingInfo")}` + ": " + contractorDetailsForSettlement.name,
                    message: contractorDetailsForSettlement.accountingInfo,
                    links: [],
                    options: { buttonColor: "error", layoutColor: "red" },
                    buttonType: "ok"
                });
        }
    }

    setCostDocumentDocumentRecalculatingTypeByBranch() {
        if (this.costDocument.branchId == BranchEnum.DE) {
            this.costDocument.isVatInPln = false;
            this.documentRecalculatingType = SettlementDocumentRecalculatingTypeEnum.DocumentInForeignCurrency;
        }
    }

    async getCurrencyRate() {
        if (this.costDocument.currencyCode != undefined
            && this.costDocumentSaleDate
            && this.costDocumentIssueDate
            && this.costDocument.currencyCode != this.plnCurrency?.code) {
            const date = dayjs(this.costDocumentSaleDate) < dayjs(this.costDocumentIssueDate) ? this.costDocumentSaleDate : this.costDocumentIssueDate;
            await this.$store.direct.dispatch.settlementCommon.getCurrencyRate({ currencyCode: this.costDocument.currencyCode, date: date });
        }
    }

    async currencyCodeChanged(currencyCode: string) {
        if (this.plnCurrency != undefined && currencyCode == this.plnCurrency.code) {
            this.currencyRate = { rate: undefined, rateDate: undefined, id: 0 };

            this.documentRecalculatingType = 1;
            this.costDocument.isVatInPln = true;

            this.costDocumentItemList.forEach(async (x: SettlementCostDocumentItemRestModel) => {
                x.pricePln = x.price;
                await this.calculateNettoBruttoAndTaxValueInPln(x);
            });
        }
        await this.getCurrencyRate();
    }

    currencyRateValueChanged() {
        if (!this.isCostDocumentCorrected) {
            this.recalculateCostDocumentItems(this.costDocumentItemList);
        }
        else if (this.isCostDocumentCorrected) {
            this.recalculateCostDocumentItems(this.costDocumentItemCorrectionList);
        }

        this.currencyRate.id = undefined;
    }

    currencyRateDateChanged() {
        this.currencyRate.id = undefined;
    }

    recalculateCostDocumentItems(items: SettlementCostDocumentItemRestModel[]) {
        items.forEach(async (x: SettlementCostDocumentItemRestModel) => {
            if (this.currencyRate?.rate != undefined) {
                x.pricePln = this.costDocument.currencyCode == this.plnCurrency?.code ? x.price : await this.$store.direct.dispatch.settlementCommon.roundValueToTwoPlaces(x.price * this.currencyRate?.rate);
            }
            await this.calculateNettoBruttoAndTaxValue(x);
            await this.calculateNettoBruttoAndTaxValueInPln(x);
        });
    }

    assignCostDocumentItemCorrectionDifferenceList(costDocumentItemCorrectionList: SettlementCostDocumentItemRestModel[]) {
        const priceBefore = this.costDocumentItemList.reduce((sum, current) => sum + current.price, 0);
        const pricePlnBefore = this.costDocumentItemList.reduce((sum, current) => sum + current.pricePln, 0);
        const nettoBefore = this.costDocumentItemList.reduce((sum, current) => sum + current.netto, 0);
        const nettoPlnBefore = this.costDocumentItemList.reduce((sum, current) => sum + current.nettoPln, 0);
        const bruttoBefore = this.costDocumentItemList.reduce((sum, current) => sum + current.brutto, 0);
        const bruttoPlnBefore = this.costDocumentItemList.reduce((sum, current) => sum + current.bruttoPln, 0);
        const taxBefore = this.costDocumentItemList.reduce((sum, current) => sum + current.tax, 0);
        const taxPlnBefore = this.costDocumentItemList.reduce((sum, current) => sum + current.taxPln, 0);

        const priceAfter = costDocumentItemCorrectionList.reduce((sum, current) => sum + current.price, 0);
        const pricePlnAfter = costDocumentItemCorrectionList.reduce((sum, current) => sum + current.pricePln, 0);
        const nettoAfter = costDocumentItemCorrectionList.reduce((sum, current) => sum + current.netto, 0);
        const nettoPlnAfter = costDocumentItemCorrectionList.reduce((sum, current) => sum + current.nettoPln, 0);
        const bruttoAfter = costDocumentItemCorrectionList.reduce((sum, current) => sum + current.brutto, 0);
        const bruttoPlnAfter = costDocumentItemCorrectionList.reduce((sum, current) => sum + current.bruttoPln, 0);
        const taxAfter = costDocumentItemCorrectionList.reduce((sum, current) => sum + current.tax, 0);
        const taxPlnAfter = costDocumentItemCorrectionList.reduce((sum, current) => sum + current.taxPln, 0);

        const item = {
            price: this.roundValueToTwoPlaces(priceAfter - priceBefore),
            pricePln: this.roundValueToTwoPlaces(pricePlnAfter - pricePlnBefore),
            netto: this.roundValueToTwoPlaces(nettoAfter - nettoBefore),
            nettoPln: this.roundValueToTwoPlaces(nettoPlnAfter - nettoPlnBefore),
            brutto: this.roundValueToTwoPlaces(bruttoAfter - bruttoBefore),
            bruttoPln: this.roundValueToTwoPlaces(bruttoPlnAfter - bruttoPlnBefore),
            tax: this.roundValueToTwoPlaces(taxAfter - taxBefore),
            taxPln: this.roundValueToTwoPlaces(taxPlnAfter - taxPlnBefore),

        } as SettlementCostDocumentItemRestModel;

        this.costDocumentItemCorrectionDifferenceList = [item];
    }

    roundValueToTwoPlaces(value: number) {
        return Math.round(value * 100) / 100;
    }

    documentCountryChanged() {
        this.loadCountryRateList();

        if (this.costDocumentItemList != undefined) {
            this.costDocumentItemList.forEach((x: SettlementCostDocumentItemRestModel) => {
                x.taxRate = undefined as any;
                x.tax = undefined as any;
                x.taxPln = undefined as any;
                x.brutto = undefined as any;
                x.bruttoPln = undefined as any;
            });
        };
    }

    async discountChanged() {
        this.costDocument.discount = this.changeCommaToDot(this.costDocument.discount);
        this.costDocumentItemList.forEach(async (x: SettlementCostDocumentItemRestModel) => {
            await this.calculateNettoBruttoAndTaxValue(x);
            await this.calculateNettoBruttoAndTaxValueInPln(x);
        });
    }

    async calculateItemsAmounts(costDocumentItemList: SettlementCostDocumentItemRestModel[]) {
        if (!this.isCostDocumentCorrected) {
            await costDocumentItemList.forEach(async (x: SettlementCostDocumentItemRestModel) => {
                await this.calculateNettoBruttoAndTaxValue(x);
                await this.calculateNettoBruttoAndTaxValueInPln(x);
            });
            this.$forceUpdate();
        }
    }

    loadCountryRateList() {
        this.$store.direct.commit.register.setCountry({ code: this.costDocument.documentCountry } as CountryRestModel);
        this.$store.direct.dispatch.common.loadCountryTaxRateList();
    }

    allowNumbersOnly(evt: any) {
        return this.$store.direct.dispatch.settlementCommon.allowNumbersOnly(evt);
    }

    changeCommaToDot(val: any): number {
        return parseFloat(val.toString().replace(",", "."));
    }

    async calculateNettoBruttoAndTaxValue(item: SettlementCostDocumentItemRestModel) {
        await this.$store.direct.dispatch.settlementCommon.calculateNettoBruttoAndTaxValue({ item: item, parentComponentName: "cost-document" });
        await this.$forceUpdate();
    }

    async calculateNettoBruttoAndTaxValueInPln(item: SettlementCostDocumentItemRestModel) {
        await this.$store.direct.dispatch.settlementCommon.calculateNettoBruttoAndTaxValueInPln({ item: item, parentComponentName: "cost-document" });
        await this.$forceUpdate();
    }

    async selectedContractorChanged(costDocument: SettlementCostDocumentRestModel | undefined = undefined) {
        if (costDocument == undefined) {
            costDocument = this.costDocument;
        }

        if (costDocument.contractor != null) {
            const contractorId = costDocument.contractor.value;

            await this.loadContractorDetailsForSettlement(contractorId);

            costDocument.contractorName = this.contractorDetailsForSettlement.name;
            costDocument.contractorSymbol = this.contractorDetailsForSettlement.symbol;
            costDocument.contractorCountry = this.contractorDetailsForSettlement.country;
            costDocument.contractorCity = this.contractorDetailsForSettlement.city;
            costDocument.contractorPostalCode = this.contractorDetailsForSettlement.postalCode;
            costDocument.contractorStreetAndNumber = this.contractorDetailsForSettlement.streetAndNumber;
            costDocument.contractorTin = this.contractorDetailsForSettlement.tin;
            costDocument.contractorTinPrefix = this.contractorDetailsForSettlement.tinPrefix;
            costDocument.documentCountry = this.contractorDetailsForSettlement.country;

            await this.loadContractorBankAccountList();
            this.assignCostDocumentBankAccounts();
        }
        this.$forceUpdate();

        this.loadCountryRateList();
    }

    assignCostDocumentBankAccounts() {
        this.costDocumentBankAccountList = [];
        if (this.contractorBankAccounts != undefined && this.contractorBankAccounts.length > 0) {
            const defaultRegularContractorBankAccount = this.contractorBankAccounts.find(x => x.isDefault === true && x.isVatAccount === false);
            const defaultVatContractorBankAccount = this.contractorBankAccounts.find(x => x.isDefault === true && x.isVatAccount === true);

            if (defaultRegularContractorBankAccount != undefined) {
                this.costDocumentBankAccountList.push(this.assignCostDocumentBankAccount(defaultRegularContractorBankAccount));
            }

            if (this.documentRecalculatingType == SettlementDocumentRecalculatingTypeEnum.DocumentInForeignCurrency && this.costDocument.isVatInPln && defaultVatContractorBankAccount != undefined) {
                this.costDocumentBankAccountList.push(this.assignCostDocumentBankAccount(defaultVatContractorBankAccount));
            }
        }
    }

    assignCostDocumentBankAccount(bankAccount: ContractorBankAccountRestModel): SettlementCostDocumentBankAccountRestModel {
        const costDocumentBankAccount = {
            bankAccountContractorId: bankAccount?.contractorId,
            bankAccountNumber: bankAccount?.accountNumber,
            bankAccountCurrencyCode: bankAccount?.currencyCode,
            bankAccountCountryCode: bankAccount?.countryCode,
            bankAccountIban: bankAccount?.iban,
            bankAccountIsVatAccount: bankAccount?.isVatAccount,
            bankAccountSwift: bankAccount?.swift,
            bankName: bankAccount?.bankName,
        } as SettlementCostDocumentBankAccountRestModel;

        return costDocumentBankAccount;
    }

    formatDate(dateTime: any) {
        if (dateTime != null) {
            return dayjs(dateTime).format("YYYY-MM-DD");
        }
    }

    async loadContractorBankAccountList() {
        if (this.costDocument.contractor != null && this.costDocument.contractor.value != undefined) {
            await this.$store.direct.dispatch.contractor.loadContractorBankAccountList({ contractorId: this.costDocument.contractor.value });
        }
    }

    async loadContractorBankAccountListAndAssignCostDocumentBankAccounts() {
        await this.loadContractorBankAccountList();
        this.assignCostDocumentBankAccounts();
    }

    async addCostDocumentBankAccount() {
        if (this.costDocument.contractor?.value != undefined) {
            await this.loadContractorBankAccountList();
        }

        this.costDocumentBankAccount = {} as SettlementCostDocumentBankAccountRestModel;
        await this.$store.direct.dispatch.settlementCostDocument
            .openCostDocumentBankAccountDialog({
                title: `${this.$t("costDocument.bankAccount")}`
            })
    }

    async editCostDocumentBankAccount(item: SettlementCostDocumentBankAccountRestModel) {
        if (this.costDocument.contractor?.value != undefined) {
            await this.loadContractorBankAccountList();
        }

        this.$store.direct.commit.settlementCostDocument.setCostDocumentBankAccount(item);
        await this.$store.direct.dispatch.settlementCostDocument
            .openCostDocumentBankAccountDialog({
                title: `${this.$t("costDocument.bankAccount")}`
            })
    }

    deleteCostDocumentBankAccount(item: SettlementCostDocumentBankAccountRestModel) {
        this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: `${this.$t("costDocument.removeCostDocumentBankAccountTitle")}`,
                message: `${this.$t("costDocument.removeCostDocumentBankAccountMessage")}` + item.bankAccountNumber,
                links: [],
                options: { buttonColor: "error", layoutColor: "red" },
                buttonType: "yes/no"
            })
            .then(confirm => {
                if (confirm) {
                    const foundBankAccount = this.costDocumentBankAccountList.find((x: SettlementCostDocumentBankAccountRestModel) => {
                        return x.bankAccountNumber == item.bankAccountNumber;
                    });
                    if (foundBankAccount != undefined) {
                        const index = this.costDocumentBankAccountList.indexOf(foundBankAccount);
                        if (index != undefined && index > -1) {
                            this.costDocumentBankAccountList.splice(index, 1);
                        }
                    }
                }
            })
    }

    async addCostDocumentItem() {
        this.costDocumentItem = {
            costType: {} as CostTypeRestModel, quantity: 1, unit: "SZT."
        } as SettlementCostDocumentItemRestModel;
        await this.$store.direct.dispatch.settlementCostDocument
            .openCostDocumentItemDialog({
                title: `${this.$t("costDocument.item")}`,
                isFromCorrection: false,
            })
    }

    async editCostDocumentItem(item: SettlementCostDocumentItemRestModel) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentItem(item);
        await this.$store.direct.dispatch.settlementCostDocument
            .openCostDocumentItemDialog({
                title: `${this.$t("costDocument.item")}`,
                isFromCorrection: false,
            })
    }

    deleteCostDocumentItem(item: SettlementCostDocumentItemRestModel) {
        this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: `${this.$t("costDocument.removeCostDocumentItemTitle")}`,
                message: `${this.$t("costDocument.removeCostDocumentItemMessage")}` + item.costType.name,
                links: [],
                options: { buttonColor: "error", layoutColor: "red" },
                buttonType: "yes/no"
            })
            .then(confirm => {
                if (confirm) {
                    const foundItem = this.costDocumentItemList.find(x => {
                        return x.price == item.price && x.taxRate == item.taxRate;
                    });
                    if (foundItem != undefined) {
                        const index = this.costDocumentItemList.indexOf(foundItem);
                        if (index != undefined && index > -1) {
                            this.costDocumentItemList.splice(index, 1);
                        }
                    }
                }
            })
    }

    async addCostDocumentItemCorrection() {
        this.costDocumentItem = {
            costType: {} as CostTypeRestModel, quantity: 1, unit: "SZT."
        } as SettlementCostDocumentItemRestModel;
        await this.$store.direct.dispatch.settlementCostDocument
            .openCostDocumentItemDialog({
                title: `${this.$t("costDocument.item")}`,
                isFromCorrection: true,
            })
    }

    async editCostDocumentItemCorrection(item: SettlementCostDocumentItemRestModel) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentItem(item);
        await this.$store.direct.dispatch.settlementCostDocument
            .openCostDocumentItemDialog({
                title: `${this.$t("costDocument.item")}`,
                isFromCorrection: true,
            })
    }

    deleteCostDocumentItemCorrection(item: SettlementCostDocumentItemRestModel) {
        this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: `${this.$t("costDocument.removeCostDocumentItemTitle")}`,
                message: `${this.$t("costDocument.removeCostDocumentItemMessage")}` + item.costType.name,
                links: [],
                options: { buttonColor: "error", layoutColor: "red" },
                buttonType: "yes/no"
            })
            .then(confirm => {
                if (confirm) {
                    const foundItem = this.costDocumentItemCorrectionList.find(x => {
                        return x.price == item.price;
                    });
                    if (foundItem != undefined) {
                        const index = this.costDocumentItemCorrectionList.indexOf(foundItem);
                        if (index != undefined && index > -1) {
                            this.costDocumentItemCorrectionList.splice(index, 1);
                        }
                    }
                }
            })
    }

    calculateCostDocumentItemPriceAfterAddedNewSupervisor(costDocumentSupervisorList: SupervisorForSettlementRestModel[]) {
        let allAssignedSupervisorsHasTheSameCurrency = true;
        costDocumentSupervisorList.forEach((x: SupervisorForSettlementRestModel) => {
            if (x.purchaseValueCurrency != this.costDocument.currencyCode) {
                allAssignedSupervisorsHasTheSameCurrency = false;
            }
        });

        if (costDocumentSupervisorList.length > 1 && this.assignSupervisorWasClicked && this.costDocumentItemList.length == 1 && allAssignedSupervisorsHasTheSameCurrency) {
            const totalPriceOfNewAssignedSupervisors = costDocumentSupervisorList.filter((x: SupervisorForSettlementRestModel) => {
                if (!this.previouslyAssignedSupervisorIdcs.includes(x.id) && x.purchaseValue != undefined) {
                    return x.purchaseValue;
                }
            }).reduce((sum, current) => sum + current.purchaseValue, 0);

            this.costDocumentItemList[0].price = this.costDocumentItemList[0].price + totalPriceOfNewAssignedSupervisors;
        }

        this.assignSupervisorWasClicked = false;
        this.previouslyAssignedSupervisorIdcs = [] as number[];
    }

    async assignCostDocumentSupervisor() {
        this.previouslyAssignedSupervisorIdcs = this.costDocumentSupervisorList.map((x: SupervisorForSettlementRestModel) => {
            return x.id;
        });

        await this.$store.direct.dispatch.settlementCommon
            .openAssignSupervisorDialog({
                title: `${this.$t("costDocument.assignCostDocumentSupervisor")}`
            }).then(async confirm => {
                if (await confirm) {
                    this.assignSupervisorWasClicked = true;
                    this.costDocumentSupervisorList;
                }
                this.assignSupervisorWasClicked = false;
                this.previouslyAssignedSupervisorIdcs = [] as number[];
            });
    }

    showCostDocumentSupervisor(item: SupervisorRestModel) {
        return "/supervisor/edit/" + item.id;
    }

    async addNewContractor(contractorType: ContractorTypes) {
        await this.$store.direct.dispatch.contractor.loadContractor({ types: [contractorType] });

        if (contractorType == ContractorTypes.Carrier) {
            await this.$store.direct.dispatch.contractor.loadPolicy();
        }

        this.$store.direct.dispatch.supervisor
            .openContractorDialog({
                showDialog: true,
                title: `${this.$t("contractor.createNewContractorTitle")}`,
                contractorTypes: [contractorType],
                isContractorEdited: false,
            })
            .then(async confirm => {
                if (confirm) {
                    await this.$store.direct.dispatch.contractor.saveContractor();
                    this.contractorSearchLoadItems(this.contractorSearch);
                }
            });
    }

    deleteCostDocumentSupervisor(item: SupervisorRestModel) {
        this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: `${this.$t("costDocument.removeSupervisorAssignmentTitle")}`,
                message: `${this.$t("costDocument.removeSupervisorAssignmentMessage")}` + item.orderId,
                links: [],
                options: { buttonColor: "error", layoutColor: "red" },
                buttonType: "yes/no"
            })
            .then(confirm => {
                if (confirm) {
                    const foundSupervisor = this.costDocumentSupervisorList.find(x => {
                        return x.id == item.id;
                    });
                    if (foundSupervisor != undefined) {
                        const index = this.costDocumentSupervisorList.indexOf(foundSupervisor);
                        if (index != undefined && index > -1) {
                            this.costDocumentSupervisorList.splice(index, 1);
                        }
                    }
                }
            })
    }

    async copyCostDocumentClick() {
        const previousCostDocumentId = this.costDocument.id;
        await this.$router.push({ path: "/settlement/cost-document/create" });

        const temporaryCostDocument = this.$store.direct.state.settlementCostDocument.costDocument;
        await this.$store.direct.dispatch.settlementCostDocument.loadCostDocument({ costDocumentId: previousCostDocumentId });

        this.costDocument.id = temporaryCostDocument.id;
        this.costDocument.concurrencyStamp = temporaryCostDocument.concurrencyStamp;

        this.costDocument.documentNumber = undefined as any;
        this.costDocument.costDocumentStatusId = 1;
        this.costDocument.paymentStatusId = SettlementPaymentStatusEnum.Niezaplacona;
        this.costDocument.hasNotCancelledTransferOrders = false;

        this.costDocument.issueDate = undefined as any;
        this.costDocumentIssueDate = undefined as any;

        this.costDocument.saleDate = undefined as any;
        this.costDocumentSaleDate = undefined as any;

        this.costDocument.entryDate = undefined as any;
        this.costDocumentEntryDate = undefined as any;

        this.costDocument.receivedDate = new Date().toString();
        this.costDocumentReceivedDate = new Date().toString();

        this.costDocument.paymentDate = undefined as any;
        this.costDocumentPaymentDate = undefined as any;

        this.costDocument.costDocumentBankAccounts.forEach((x: SettlementCostDocumentBankAccountRestModel) => {
            x.id = 0;
        });

        this.costDocument.costDocumentItems.forEach((x: SettlementCostDocumentItemRestModel) => {
            x.id = 0;
        });

        this.costDocument.costDocumentSupervisors = [] as SupervisorForSettlementRestModel[];
        this.costDocumentSupervisorList = [] as SupervisorForSettlementRestModel[];

        this.costDocumentBankAccountList = this.costDocument.costDocumentBankAccounts;
    }

    cancelClick() {
        this.backToList();
    }

    async saveClick() {
        if (this.isCostDocumentCorrected) {
            this.costDocument.costDocumentItems = this.costDocumentItemCorrectionList;
            this.costDocument.isDocumentCorrection = true;
        }
        else {
            this.costDocument.costDocumentItems = this.costDocumentItemList;
            this.costDocument.isDocumentCorrection = false;
        }

        this.costDocument.costDocumentBankAccounts = this.costDocumentBankAccountList;
        this.costDocument.costDocumentSupervisors = this.costDocumentSupervisorList;

        if (Object.keys(this.currencyRate).length > 0) {
            this.costDocument.currencyRate = this.currencyRate;
        }

        this.costDocument.issueDate = this.costDocumentIssueDate === "" ? undefined as any : this.costDocumentIssueDate;
        this.costDocument.receivedDate = this.costDocumentReceivedDate === "" ? undefined as any : this.costDocumentReceivedDate;
        this.costDocument.entryDate = this.costDocumentEntryDate === "" ? undefined as any : this.costDocumentEntryDate;
        this.costDocument.saleDate = this.costDocumentSaleDate === "" ? undefined as any : this.costDocumentSaleDate;
        this.costDocument.paymentDate = this.costDocumentPaymentDate === "" ? undefined as any : this.costDocumentPaymentDate;
        this.costDocument.paymentTerm = (this.costDocument.paymentTerm == null || this.costDocument.paymentTerm.toString().length == 0) ? undefined as any : this.costDocument.paymentTerm;
        this.costDocument.discount = this.costDocument.discount.toString().length == 0 ? 0 : this.costDocument.discount;
        this.costDocument.documentRecalculatingType = this.documentRecalculatingType;

        this.costDocument.costDocumentItems?.forEach((x: SettlementCostDocumentItemRestModel) => {
            x.price = x.price.toString().length == 0 ? 0 : x.price;
            if (x.taxRate != undefined) {
                x.taxRate = x.taxRate.toString().length == 0 ? 0 : x.taxRate;
            }
        });

        if (this.isCostDocumentCorrected) {
            await this.$store.direct.dispatch.settlementCostDocument.saveCostDocumentCorrection();
        }
        else {
            await this.$store.direct.dispatch.settlementCostDocument.saveCostDocument();
        }


        if (this.$refs.form != undefined) {
            const valid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
        }

        if (Object.keys(this.validationError).length === 0 && this.costDocumentSaveResponse.isSaved == true) {
            this.costDocument = {} as SettlementCostDocumentRestModel;

            this.backToList();
        }
    }

    async attachmentCreateClick() {
        await this.$store.direct.dispatch.settlementCostDocument.openAttachmentDialog()
            .then(async confirm => {
                if (confirm) {
                    await this.$store.direct.dispatch.settlementCostDocument.saveAttachments();
                }
            });
    }

    async downloadCostDocumentFile(costDocumentFile: SettlementCostDocumentAttachmentRestModel) {
        await this.$store.direct.dispatch.settlementCostDocument.downloadCostDocumentFile({ costDocumentId: this.costDocument.id, costDocumentFileId: costDocumentFile.id });
    }

    async deleteCostDocumentFile(item: SettlementCostDocumentAttachmentRestModel) {
        await this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: this.$t("costDocument.deleteFileTitle").toString(),
                message: this.$t("costDocument.deletetFileMessage").toString() + item.name,
                links: [],
                options: { buttonColor: "error", layoutColor: "red" },
                buttonType: "yes/no"
            })
            .then(async confirm => {
                if (confirm) {
                    await this.$store.direct.dispatch.settlementCostDocument.deleteCostDocumentFile({ fileId: item.id });
                    await this.$store.direct.dispatch.settlementCostDocument.loadCostDocumentAttachmentList();
                }
            })
    }

    getCostDocumentMementoItem(value: number): SettlementCostDocumentMementoItemRestModel {
        if (value > -1 && this.costDocumentMementoList.length > 0) {
            return this.costDocumentMementoList[value];
        }
        return {} as SettlementCostDocumentMementoItemRestModel;
    }

    async costDocumentMementoPointChanged(historyPoint: number) {
        if (historyPoint != this.lastCostDocumentMementoPoint) {
            this.setCostDocumentItemsAndSupervisorsEmpty();

            if (historyPoint >= 0) {
                this.isCostDocumentInHistoricalState = true;
                await this.$store.direct.dispatch.settlementCostDocument.getCostDocumentMementoPoint({ mementoPoint: historyPoint });
                await this.$store.direct.dispatch.settlementCostDocument.loadCostDocumentAttachmentList({ mementoPoint: historyPoint });
            }
            this.lastCostDocumentMementoPoint = historyPoint;
        }
    }

    setCostDocumentItemsAndSupervisorsEmpty() {
        this.costDocument.costDocumentItems = [] as SettlementCostDocumentItemRestModel[];
        this.costDocument.costDocumentSupervisors = [] as SupervisorForSettlementRestModel[];
        this.costDocumentItemList = [] as SettlementCostDocumentItemRestModel[];
        this.costDocumentSupervisorList = [] as SupervisorForSettlementRestModel[];
    }

    get bankAccount(): CostDocumentBankAccountRestModel {
        return this.$store.direct.state.settlementCostDocument.costDocumentBankAccount;
    }

    set bankAccount(val: CostDocumentBankAccountRestModel) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentBankAccount(val);
    }

    destroyed() {
        this.$store.direct.dispatch.settlementCostDocument.cleanCostDocumentProperties();
        this.costDocument.contractor = {} as ContractorSearchItemModel;
        this.contractorFound = [] as ContractorSearchItemModel[];
        this.currencyRate = {} as CurrencyRateRestModel;
        this.validationError = {} as any;
        this.$store.direct.commit.register.setCountry({} as CountryRestModel);
        this.isCostDocumentInHistoricalState = false;
        this.contractorBankAccounts = [] as ContractorBankAccountRestModel[];
        this.contractorBankAccounts.length = 0;
        this.costDocumentBankAccountList = [] as SettlementCostDocumentBankAccountRestModel[];
        this.costDocumentBankAccountList.length = 0;
        this.contractorDetailsForSettlement = {} as ContractorDetailsForSettlementRestModel;
    }

    backToList() {
        if (this.isCostDocumentCorrected && this.previousUrlName != "Cost payment obligation list") {
            router.push({ path: "/settlement/cost-document/correction/list" });
        }
        else {
            if (this.previousUrlName != null && this.previousUrlName != undefined) {
                if (this.previousUrlName == "Edit cost document" && this.$route.name == "Create cost document") {
                    router.push({ path: "/settlement/cost-document/list" });
                }
                else {
                    router.push({ name: this.previousUrlName });
                }
            }
            else {
                router.push({ path: "/settlement/cost-document/list" });
            }
        }
    }
}
