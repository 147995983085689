import dayjs from "dayjs";
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import i18n from "../../../assets/i18n";
import ConfirmDialog from "../../../layouts/confirmDialog/confirmDialog.vue";
import { SettlementPaymentTypeEnum } from "../../../models/emums/settlement/settlementPaymentTypeEnum";
import ListItemNumberModel from "../../../models/listItemNumberModel";
import ContractorSearchItemModel from "../../../models/rest/contractor/contractorSearchItemModel";
import CurrencyRestModel from "../../../models/rest/register/currencyRestModel";
import SettlementPaymentCostDocumentRestModel from "../../../models/rest/settlement/payment/settlementPaymentCostDocumentRestModel";
import SettlementPaymentIncomeDocumentRestModel from "../../../models/rest/settlement/payment/settlementPaymentIncomeDocumentRestModel";
import SettlementPaymentListRequestModel from "../../../models/rest/settlement/payment/settlementPaymentListRequestModel";
import SettlementPaymentRestModel from "../../../models/rest/settlement/payment/settlementPaymentRestModel";
import SettlementAmountSummaryRestModel from "../../../models/rest/settlement/settlementAmountSummaryRestModel";
import SupervisorRestModel from "../../../models/rest/supervisor/supervisorRestModel";
import SettlementAmountSummaryRow from "../settlement-amount-summary-row.vue";
import CostPaymentDialog from "../cost-payment/cost-payment-dialog.vue";
import EditPaymentOutsideTheSystemDialog from "./dialog/edit-payment-outside-the-system-dialog.vue";


@Component({
    components: {
        "cost-payment-dialog": CostPaymentDialog,
        "confirm-dialog": ConfirmDialog,
        "edit-payment-outside-the-system-dialog": EditPaymentOutsideTheSystemDialog,
        "settlement-amount-summary-row": SettlementAmountSummaryRow,
    }
})

export default class CostPaymentList extends Vue {
    SettlementPaymentTypeEnum = SettlementPaymentTypeEnum;
    selectedCurrency = {} as CurrencyRestModel;
    paymentDateFromLabel = this.$t("payment.paymentDateFrom");
    paymentDateToLabel = this.$t("payment.paymentDateTo");
    paymentTypeLabel = this.$t("costDocument.paymentType");
    issueDateFromLabel = this.$t("costDocument.issueDateFrom");
    issueDateToLabel = this.$t("costDocument.issueDateTo");
    searchDocumentNumber = "";
    searchPaymentDateFrom = "";
    searchPaymentDateTo = "";
    searchIssueDateFrom = "";
    searchIssueDateTo = "";
    searchBranchId = 0;
    searchPaymentType = [] as number[];
    contractorSearch = "";
    supervisorSearch = "";
    supervisor = {} as SupervisorRestModel;
    mainCheckboxIsSelected = false;
    showAmountSummaryRowForCalculatedSelectedList = false;

    paymentListHeaders = [
        {
            align: "start",
            value: "checkbox",
            width: "1%",
            sortable: false,
        },
        {
            text: "Id",
            align: "center",
            value: "id",
            width: "1%",
            sortable: true,
        },
        {
            text: this.$t("costDocument.paymentType"),
            align: "start",
            value: "paymentTypeId",
            sortable: true,
        },
        {
            text: this.$t("payment.costDocumentNumber"),
            align: "start",
            value: "costDocumentNumberListString",
            sortable: true,
        },
        {
            text: this.$t("payment.incomeDocumentNumber"),
            align: "start",
            value: "incomeDocumentNumberListString",
            sortable: true,
        },
        {
            text: this.$t("supervisor.orderId"),
            align: "start",
            value: "supervisorOrderIdListString",
            sortable: true,
        },
        {
            text: this.$t("contractor.branch"),
            align: "start",
            value: "branchId",
            sortable: true,
        },
        {
            text: this.$t("costDocument.contractorName"),
            align: "start",
            value: "contractorName",
            sortable: true,
        },
        {
            text: this.$t("payment.amount"),
            align: "end",
            value: "paymentAmount",
            sortable: true,
        },
        {
            text: this.$t("payment.currency"),
            align: "start",
            value: "currencyCode",
            sortable: true,
        },
        {
            text: this.$t("costDocument.comment"),
            align: "start",
            value: "comment",
            sortable: true,
        },
        {
            text: this.$t("common.createdBy"),
            align: "start",
            value: "createdByUserName",
            sortable: false,
        },
        {
            text: this.$t("payment.paymentDate"),
            align: "start",
            value: "paymentDate",
            sortable: true,
        },
        {
            text: this.$t("common.actions"),
            align: "right",
            value: "actions",
            width: "1%",
            sortable: false,
        }
    ];

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get paymentListLoading(): boolean {
        return this.$store.direct.state.settlementPayment.paymentListLoading;
    }

    get paymentList(): SettlementPaymentRestModel[] {
        return this.$store.direct.state.settlementPayment.paymentList;
    }

    get paymentListTotalCount(): number {
        return this.$store.direct.state.settlementPayment.paymentListTotalCount;
    }

    get pagerOptions(): any {
        return this.$store.direct.state.settlementPayment.paymentListPagerOptions;
    }

    set pagerOptions(value: any) {
        this.$store.direct.commit.settlementPayment.setPaymentListPagerOptions(value);
    }

    get searchContractors(): ContractorSearchItemModel[] {
        return this.$store.direct.state.settlementPayment.costPaymentListSearchContractors;
    }

    set searchContractors(value: ContractorSearchItemModel[]) {
        this.$store.direct.commit.settlementPayment.setCostPaymentListSearchContractors(value);
    }

    get searchContractorIdcs(): number[] {
        return this.$store.direct.state.settlementPayment.costPaymentListSearchContractorIdcs;
    }

    set searchContractorIdcs(value: number[]) {
        this.$store.direct.commit.settlementPayment.setCostPaymentListSearchContractorIdcs(value);
    }

    get footerPropsForSettlement(): any {
        return this.$store.direct.state.settlementCommon.footerPropsForSettlement;
    }

    get paymentListAmountSummaryList(): SettlementAmountSummaryRestModel[] {
        let amountSummary = this.$store.direct.state.settlementPayment.paymentListAmountSummaryList;

        if (amountSummary == undefined) {
            amountSummary = [] as SettlementAmountSummaryRestModel[];
        }
        return amountSummary;
    }

    get currencies(): CurrencyRestModel[] {
        const currencies = this.$store.direct.state.contractor.contractorAssets.currencies;
        if (currencies == undefined) {
            this.$store.direct.dispatch.contractor.loadContractorAssets();
        }
        else {
            currencies.push({ code: i18n.t("common.all"), name: "" } as CurrencyRestModel)
        }

        return currencies;
    }

    get branchList(): ListItemNumberModel[] {
        const branchList = this.branches;
        let clonedBranchList = [] as ListItemNumberModel[];
        if (branchList == undefined) {
            this.$store.direct.dispatch.contractor.loadContractorAssets();
        }
        else {
            clonedBranchList = [...branchList];
            clonedBranchList.push({ value: 0, text: i18n.t("common.all") } as ListItemNumberModel);
        }
        return clonedBranchList;
    }

    get branches(): ListItemNumberModel[] {
        const branches = this.$store.direct.state.contractor.contractorAssets.branches;
        if (branches == undefined) {
            this.$store.direct.dispatch.contractor.loadContractorAssets();
        }
        return branches;
    }

    get paymentTypeList(): ListItemNumberModel[] {
        return this.$store.direct.state.settlementCommon.settlementAssets.paymentTypes;
    }

    get contractorFound(): ContractorSearchItemModel[] {
        return this.$store.direct.state.common.contractorSearch;
    }

    get supervisorFound(): SupervisorRestModel[] {
        return this.$store.direct.state.supervisor.supervisorSearch;
    }

    get selectedPaymentList(): SettlementPaymentRestModel[] {
        return this.$store.direct.state.settlementPayment.selectedPaymentList;
    }

    set selectedPaymentList(val: SettlementPaymentRestModel[]) {
        this.$store.direct.commit.settlementPayment.setSelectedPaymentList(val);
    }

    get allowSelectionOfAllItemsOnPageWithCheckbox(): boolean {
        const allowSelection = this.$store.direct.state.settlementCommon.allowSelectionOfAllItemsOnPageWithCheckbox;
        if (!allowSelection) {
            this.mainCheckboxIsSelected = false;
            this.selectedPaymentList = [] as SettlementPaymentRestModel[];
            this.paymentList.forEach((x: SettlementPaymentRestModel) => {
                x.isChecked = false;
            });
            this.calculatedSelectedListForAmountSummaryRow = [] as SettlementAmountSummaryRestModel[];
        }
        return allowSelection;
    }

    set allowSelectionOfAllItemsOnPageWithCheckbox(val: boolean) {
        this.$store.direct.commit.settlementCommon.setAllowSelectionOfAllItemsOnPageWithCheckbox(val);
    }

    get calculatedSelectedListForAmountSummaryRow(): SettlementAmountSummaryRestModel[] {
        return this.$store.direct.state.settlementCommon.calculatedSelectedListForAmountSummaryRow;
    }

    set calculatedSelectedListForAmountSummaryRow(val: SettlementAmountSummaryRestModel[]) {
        this.$store.direct.commit.settlementCommon.setCalculatedSelectedListForAmountSummaryRow(val);
    }

    @Watch("pagerOptions", { deep: true })
    pagerOptionsHandles() {
        this.loadPaymentList();
    }

    @Watch("contractorSearch")
    async contractorSearchLoadItems(val: string) {
        await this.$store.direct.dispatch.common.searchContractors({ searchText: val, contractorTypes: [], contractorPropertiesEnabled: [], source: "" });
        if (this.searchContractors.length > 0) {
            this.searchContractors.forEach((x: any) => {
                if (!this.contractorFound.includes(x)) {
                    this.contractorFound.push(x);
                }
            })
        }
    }

    @Watch("supervisorSearch")
    async supervisorSearchLoadItems(val: string) {
        if (this.supervisorSearch) {
            await this.$store.direct.dispatch.supervisor.searchSupervisorsForSettlement({ searchText: val });
        }
    }

    async created() {
        this.$store.direct.dispatch.contractor.loadContractorAssets();
        this.$store.direct.dispatch.settlementCommon.loadSettlementAssets();
        this.loadPaymentList();
    }

    formatPaymentTypeIdToPaymentName(paymentTypeId: any) {
        if (this.paymentTypeList != undefined) {
            return this.paymentTypeList.find(x => x.value == paymentTypeId)?.text;
        }
    }

    formatDateTime(dateTime: any) {
        if (dateTime != null) {
            return dayjs(dateTime).format("YYYY-MM-DD");
        }
    }

    formatBranchIdToBranchName(branchId: any) {
        if (this.branches != undefined) {
            return this.branches.find(x => x.value == branchId)?.text;
        }
    }

    clearDateTimePicker(clearableValue: string) {
        if (clearableValue == "searchPaymentDateFromValue") {
            this.searchPaymentDateFrom = "";
        }
        if (clearableValue == "searchPaymentDateToValue") {

            this.searchPaymentDateTo = "";
        }
        if (clearableValue == "searchIssueDateFromValue") {

            this.searchIssueDateFrom = "";
        }
        if (clearableValue == "searchIssueDateToValue") {

            this.searchIssueDateTo = "";
        }
        this.loadPaymentList();
    }

    clearSearchContractorClick() {
        this.searchContractorIdcs = [] as number[];
        this.loadPaymentList();
    }

    clearSearchSupervisorClick() {
        this.supervisor = {} as SupervisorRestModel;
        this.loadPaymentList();
    }

    clearAllFilters() {
        this.selectedCurrency = {} as CurrencyRestModel;
        this.searchDocumentNumber = "";
        this.searchPaymentDateFrom = "";
        this.searchPaymentDateTo = "";
        this.searchBranchId = 0;
        this.searchContractorIdcs = [] as number[];
        this.searchContractors = [] as ContractorSearchItemModel[];
        this.supervisor = {} as SupervisorRestModel;
        this.searchPaymentType = [] as number[];
        this.searchIssueDateFrom = "";
        this.searchIssueDateTo = "";

        this.loadPaymentList();
    }

    searchClick() {
        this.loadPaymentList();
    }

    async createPaymentClick() {
        await this.$store.direct.commit.settlementPayment.setPaymentCostDocumentRequestList([{} as SettlementPaymentCostDocumentRestModel]);
        await this.$store.direct.commit.settlementPayment.setPaymentIncomeDocumentRequestList([{} as SettlementPaymentIncomeDocumentRestModel]);
        await this.$store.direct.dispatch.settlementPayment.loadCostPayment({ paymentGroupId: "" });

        this.$store.direct.dispatch.settlementPayment
            .openCostPaymentDialog({
                title: `${this.$t("payment.addCostPayment")}`,
            })
            .then(async confirm => {
                if (confirm) {
                    this.loadPaymentList();
                }
            })
    }

    async editPayment(item: SettlementPaymentRestModel) {
        await this.$store.direct.dispatch.settlementPayment.loadCostPayment({ paymentGroupId: item.groupId });
        this.$store.direct.dispatch.settlementPayment
            .openCostPaymentDialog({
                title: `${this.$t("payment.editCostPayment")}`,
            })
            .then(async confirm => {
                if (confirm) {
                    this.loadPaymentList();
                }
            })
    }

    async deletePayment(item: SettlementPaymentRestModel) {
        this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: "Delete payment",
                message: "Are you sure you want to delete this payment?: " + item.id,
                links: [],
                options: { buttonColor: "error", layoutColor: "red" },
                buttonType: "yes/no"
            })
            .then(async confirm => {
                if (confirm) {
                    await this.$store.direct.dispatch.settlementPayment.deletePayment({ groupId: item.groupId });
                    this.loadPaymentList();
                }
            })
    }

    async loadPaymentList() {
        if (!this.selectedCurrency.name) {
            this.selectedCurrency = {} as CurrencyRestModel;
        }

        await this.$store.direct.dispatch.settlementPayment.loadCostPaymentList({
            requestModel: this.prepareCostPaymentRequestModel()
        });
    }

    createXlsReportClick() {
        this.$store.direct.dispatch.settlementPayment.downloadXlsReportSettlementPaymentList({
            requestModel: this.prepareCostPaymentRequestModel(),
            paymentListType: "cost-payment-list"
        });
    }

    prepareCostPaymentRequestModel(): SettlementPaymentListRequestModel {
        return {
            page: this.pagerOptions.page,
            pageSize: this.pagerOptions.itemsPerPage,
            sortBy: this.pagerOptions.sortBy[0],
            sortDesc: this.pagerOptions.sortDesc[0],
            searchCurrencyCode: this.selectedCurrency.code,
            searchDocumentNumber: this.searchDocumentNumber,
            searchPaymentDateFrom: this.searchPaymentDateFrom,
            searchPaymentDateTo: this.searchPaymentDateTo,
            searchBranchId: this.searchBranchId,
            searchContractorIdcs: (this.searchContractors != undefined && this.searchContractors.length > 0) ? this.searchContractors.map(x => x.value) : [],
            searchSupervisorId: this.supervisor?.id,
            idList: this.selectedPaymentList.map((x: SettlementPaymentRestModel) => {
                return x.id;
            }),
            searchPaymentType: this.searchPaymentType,
            searchIssueDateFrom: this.searchIssueDateFrom,
            searchIssueDateTo: this.searchIssueDateTo,
        } as SettlementPaymentListRequestModel;
    }

    async editPaymentList() {
        this.$store.direct.dispatch.settlementPayment
            .openEditPaymentOutsideTheSystemDialog({
                title: `${this.$t("common.edit")}`,
            })
            .then(async confirm => {
                if (confirm) {
                    await this.$store.direct.dispatch.settlementPayment.saveEditedCostPaymentList();
                    this.selectedPaymentList = [] as SettlementPaymentRestModel[];
                    this.mainCheckboxIsSelected = false;
                    this.loadPaymentList();
                }
            });
    }

    addPaymentToSelectedPaymentList() {
        this.selectedPaymentList = this.paymentList.filter((x: SettlementPaymentRestModel) => {
            if (x.isChecked) {
                return x.id;
            }
            else {
                this.mainCheckboxIsSelected = false;
            }
        });

        this.$store.direct.dispatch.settlementCommon.calculateSelectedListWithCurrencyCodeAndPaymentAmountValuesForAmountSummaryRow({ list: this.selectedPaymentList });
    }

    selectAllItemsOnThePage() {
        this.paymentList.forEach((x: SettlementPaymentRestModel) => {
            if (x.paymentTypeId == SettlementPaymentTypeEnum.PLATNOSCPOZASYSTEMEM || this.allowSelectionOfAllItemsOnPageWithCheckbox) {
                x.isChecked = this.mainCheckboxIsSelected;
            }
        });
        this.$forceUpdate();

        this.selectedPaymentList = this.paymentList.filter((x: SettlementPaymentRestModel) => {
            if (x.isChecked) {
                return x.id;
            }
        });

        this.$store.direct.dispatch.settlementCommon.calculateSelectedListWithCurrencyCodeAndPaymentAmountValuesForAmountSummaryRow({ list: this.selectedPaymentList });
    }

    currentPageInput = this.pagerOptions.page;
    goToPage() {
        if (this.currentPageInput > 0) {
            this.pagerOptions.page = this.currentPageInput;
        }
    }

    destroyed() {
        this.selectedPaymentList = [] as SettlementPaymentRestModel[];
    }
}
