import { defineModule } from "direct-vuex";
import { moduleActionContext } from "@/store/index";
import { AxiosResponse, AxiosError } from "axios";
import { axiosInstance } from "../../plugins/axios";
import VehicleRestModel from "../../models/rest/vehicle/vehicleRestModel";
import router from "../../router";

export interface VehicleModuleStateInterface {
    vehicle: VehicleRestModel;
    yourVehicleList: VehicleRestModel[];
    yourVehicleListLoading: false | boolean;
    yourVehicleListTotalCount: number;
    yourVehiclesPagerOptions: any;
    yourVehiclesSearchText: string;
    vehicleMapList: VehicleRestModel[];
    vehicleMapListLoading: boolean;
    showVehicleMapInfoDialog: boolean;
    vehicleMapInfoDialogMessage: string;
    vehicleModifiedTime: string,
    showVehicleDialog: boolean;
    vehicleDialogTitle: string;
    vehicleDialogResolve: any;
}

const vehicleModule = defineModule({
    namespaced: true,
    state: {
        vehicle: {} as VehicleRestModel,
        yourVehicleList: [] as VehicleRestModel[],
        yourVehicleListLoading: false,
        yourVehicleListTotalCount: 0,
        yourVehiclesPagerOptions: {
            page: 1,
            itemsPerPage: 10,
        },
        yourVehiclesSearchText: "",
        vehicleMapList: {} as VehicleRestModel[],
        vehicleMapListLoading: false,
        showVehicleMapInfoDialog: false,
        vehicleMapInfoDialogMessage: "",
        vehicleModifiedTime: "",
        showVehicleDialog: false,
        vehicleDialogTitle: "",
        vehicleDialogResolve: null,
    } as VehicleModuleStateInterface,
    mutations: {
        setVehicle(state, value: VehicleRestModel) {
            state.vehicle = value;
        },
        setYourVehicleList(state, value: VehicleRestModel[]) {
            state.yourVehicleList = value;
        },
        setYourVehicleListLoading(state, value: boolean) {
            state.yourVehicleListLoading = value;
        },
        setYourVehicleListTotalCount(state, value: number) {
            state.yourVehicleListTotalCount = value;
            if ((state.yourVehiclesPagerOptions.page * state.yourVehiclesPagerOptions.itemsPerPage) >= (value + state.yourVehiclesPagerOptions.itemsPerPage)) {
                state.yourVehiclesPagerOptions.page = 1;
            }
        },
        setYourVehiclesSearchText(state, value: string) {
            state.yourVehiclesSearchText = value;
        },
        setVehicleMapList(state, value: VehicleRestModel[]) {
            state.vehicleMapList = value;
        },
        setVehicleMapListLoading(state, value: boolean) {
            state.vehicleMapListLoading = value;
        },
        setShowVehicleMapInfoDialog(state, value: boolean) {
            state.showVehicleMapInfoDialog = value;
        },
        setVehicleMapInfoDialogMessage(state, value: string) {
            state.vehicleMapInfoDialogMessage = value;
        },
        setVehicleModifiedTime(state, value: string) {
            state.vehicleModifiedTime = value;
        },
        setShowVehicleDialog(state, value: boolean) {
            state.showVehicleDialog = value;
        },
        setVehicleDialogTitle(state, value: string) {
            state.vehicleDialogTitle = value;
        },
        setVehicleDialogResolve(state, value: any) {
            state.vehicleDialogResolve = value;
        },
        setYourVehiclesPagerOptions(state, value: any) {
            state.yourVehiclesPagerOptions = value;
        }
    },
    actions: {
        yourVehicleListCleanFilters(context) {
            const { commit } = _moduleActionContext(context);
            const filterOptions = {
                page: 1,
                itemsPerPage: 10,
            }
            commit.setYourVehiclesSearchText("");
            commit.setYourVehiclesPagerOptions(filterOptions);
        },
        loadYourVehicleList(
            context,
            payload: {
                page: number;
                itemsPerPage: number;
                searchText: string;
            }
        ) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                commit.setYourVehicleListLoading(true);
                const params = {
                    page: payload.page,
                    pageSize: payload.itemsPerPage,
                    searchText: payload.searchText,
                };
                axiosInstance
                    .get("/api/vehicle/yourVehicleList", { params })
                    .then((resp: AxiosResponse) => {
                        commit.setYourVehicleList(resp.data.items);
                        commit.setYourVehicleListTotalCount(resp.data.totalItemsCount);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setYourVehicleListLoading(false);
                    });
            });
        },
        saveVehicle(context) {
            const { state } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/vehicle/save", state.vehicle)
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                    }).
                    catch((err: AxiosError) => reject(err));
            })
        },
        loadVehicle(context, payload: { id: number }) {
            const { commit } = _moduleActionContext(context)
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/vehicle/" + payload.id)
                    .then((resp: AxiosResponse) => {
                        commit.setVehicle(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        deleteVehicle(context, payload: { id: number }) {
            const { state, dispatch } = _moduleActionContext(context)
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/vehicle/delete/" + payload.id)
                    .then((resp: AxiosResponse) => {
                        dispatch.loadYourVehicleList({
                            searchText: state.yourVehiclesSearchText,
                            page: state.yourVehiclesPagerOptions.page,
                            itemsPerPage: state.yourVehiclesPagerOptions.itemsPerPage
                        });
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        removeNotify(context, payload: { id: number }) {
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/vehicle/removeNotify/", { id: payload.id })
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        reportNotify(context, payload: { id: number }) {
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/vehicle/reportNotify/", { id: payload.id })
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        changeStatus(context, payload: { id: number }) {
            const { state } = _moduleActionContext(context)
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/vehicle/changeStatus/" + payload.id, state.vehicle)
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data)
                        router.push({ path: "/vehicle/your-vehicles" });
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        loadVehicleMapList(context) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                commit.setVehicleMapListLoading(true);
                axiosInstance
                    .get("/api/vehicle/vehicleMapList")
                    .then((resp: AxiosResponse) => {
                        commit.setVehicleMapList(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setVehicleMapListLoading(false)
                    })
            })
        },
        holdOn(context) {
            const { state, dispatch } = _moduleActionContext(context)
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/vehicle/holdOn/", state.vehicle)
                    .then((resp: AxiosResponse) => {
                        dispatch.loadVehicleMapList();
                        resolve(resp.data)
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        holdOff(context) {
            const { state, dispatch } = _moduleActionContext(context)
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/vehicle/holdOff/", state.vehicle)
                    .then((resp: AxiosResponse) => {
                        dispatch.loadVehicleMapList();
                        resolve(resp.data)
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        showVehicleMapInfoDialog(context, payload: { showDialog: boolean; message: string; }) {
            const { commit } = _moduleActionContext(context)
            commit.setShowVehicleMapInfoDialog(payload.showDialog);
            commit.setVehicleMapInfoDialogMessage(payload.message);
        },
        async checkChanges(context) {
            const { state, commit } = _moduleActionContext(context)
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/vehicle/checkChanges")
                    .then((resp: AxiosResponse) => {
                        if (state.vehicleModifiedTime != resp.data) {
                            commit.setVehicleModifiedTime(resp.data)
                        }
                        resolve(resp.data)
                    })
                    .catch((err: AxiosError) => reject(err))
            })

        },
        isConfirmedVehicleDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.vehicleDialogResolve(true);
            commit.setShowVehicleDialog(false);
        },

        isNotConfirmedVehicleDialog(context) {
            const { commit } = _moduleActionContext(context);
            commit.setShowVehicleDialog(false);
        },
        openVehicleDialog(
            context,
            payload: {
                showDialog: boolean;
                title: string;
            }
        ) {
            const { commit } = _moduleActionContext(context);
            commit.setShowVehicleDialog(payload.showDialog);
            commit.setVehicleDialogTitle(payload.title);
            return new Promise(resolve => {
                commit.setVehicleDialogResolve(resolve);
            });
        }
    }
});

export default vehicleModule;
const _moduleActionContext = (context: any) => moduleActionContext(context, vehicleModule);
