import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import ContractorOperationRestModel from "@/models/rest/contractor/contractorOperationRestModel";
import ListItemStringModel from "@/models/listItemStringModel";
import VForm from "../../../models/v-form";

@Component
export default class OperationDialog extends Vue {
    showReminderCallendar: boolean = false;
    priorityList = [
        { text: "1 - low", value: 1 },
        { text: "2", value: 2 },
        { text: "3", value: 3 },
        { text: "4", value: 4 },
        { text: "5 - high ", value: 5 }
    ];
    tagIsLoading = false;
    tagSearch = "";

    get loading(): boolean {
        return this.$store.direct.state.loading;
    }

    requiredRule = (value: string) => !!value || this.$t("common.validationErrorRequired");

    updated() {
        this.$nextTick(function () {
            if (this.$refs.form) (this.$refs.form as VForm).resetValidation();
        });
    }

    get contractorAssets() {
        return this.$store.direct.state.contractor.contractorAssets;
    }

    get operation(): ContractorOperationRestModel {
        const operation = this.$store.direct.state.contractor.operation;
        if (operation.endDate == undefined || operation.endDate.includes("undefined")) {
            operation.endDate = "";
        }

        return operation;
    }

    get categoryListSorted(): any {
        return this.operation.categoryList?.sort((prev, next) => (prev.text > next.text ? 1 : -1));
    }

    get userListSorted(): ListItemStringModel[] {
        return this.contractorAssets?.crmUsers?.slice().sort((prev, next) => (prev.text > next.text ? 1 : -1));
    }

    get contactPersonListSorted(): any {
        const contactPersonList = this.$store.direct.state.contractor.contactPersonList;
        const contactPersonListMapped = contactPersonList
            .filter(v => !v.isDisabled)
            .map(v => {
                return { text: v.firstName + " " + v.lastName, value: v.id, model: v };
            });
        return contactPersonListMapped.sort((prev, next) => (prev.text > next.text ? 1 : -1));
    }

    get tags() {
        return this.operation.tags;
    }
    set tags(val: string[]) {
        this.operation.tags = val.map((currentValue, index) => {
            if (typeof currentValue === "string") {
                return {
                    value: 0,
                    text: currentValue
                };
            }

            return currentValue;
        });
    }

    get tagFound() {
        return this.$store.direct.state.contractor.operationTagSearch;
    }

    @Watch("tagSearch")
    tagSearchLoadItems(val: string) {
        this.$store.direct.dispatch.contractor.searchTags(val);
    }

    get showDialog(): boolean {
        return this.$store.direct.state.contractor.showOperationsDialog;
    }

    async saveClick(): Promise<void> {
        if ((this.$refs.form as VForm).validate()) {
            await this.$store.direct.dispatch.contractor.saveOperation();
        }
    }
    closeClick(): void {
        this.$store.direct.dispatch.contractor.closeOperationDialog();
    }
}
