import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Roles } from "../../../models/emums/roles";
import VForm from "../../../models/v-form";

@Component
export default class DocumentDialog extends Vue {
    requiredRule = (value: string) => !!value || this.$t("common.validationErrorRequired");
    files = [];
    isProffOfDelivery = false;

    updated() {
        this.$nextTick(function () {
            if (this.$refs.form) (this.$refs.form as VForm).resetValidation();
        });
    }

    get showDialog(): boolean {
        return this.$store.direct.state.transportOrder.showDocumentsDialog;
    }

    get currentUserRoles() {
        return this.$store.direct.state.auth.userRoles;
    }

    showProofOfDelivery(): boolean {
        if (this.currentUserRoles.includes(Roles.Disponent) || this.currentUserRoles.includes(Roles.DisponentAdmin) || this.currentUserRoles.includes(Roles.Admin)) {
            return true;
        }
        else {
            return false
        }
    }

    async saveClick(): Promise<void> {
        if ((this.$refs.form as VForm).validate()) {
            await this.$store.direct.dispatch.transportOrder.saveDocument({ files: this.files, isProofOfDelivery: this.isProffOfDelivery });
        }
    }
    closeClick(): void {
        this.$store.direct.dispatch.transportOrder.closeDocumentsDialog();
    }
}
