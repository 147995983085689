import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import ListItemNumberModel from "@/models/listItemNumberModel";
import PersonRoleDialog from "./person-role-dialog.vue";

@Component({
    components: {
        "person-role-dialog": PersonRoleDialog
    }
})
export default class Admin extends Vue {
    get items(): ListItemNumberModel[] {
        return this.$store.direct.state.register.personRoles;
    }

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    itemsHeaders = [
        { text: this.$t("common.name"), value: "text" },
        { text: this.$t("common.actions"), align: "right", value: "actions", width: "1%" }
    ];

    pagerOptions = {
        page: 1,
        itemsPerPage: 10
    };

    mounted() {
        this.$store.direct.dispatch.register.loadPersonRoles();
    }

    itemCreateClick() {
        this.$store.direct.dispatch.register.showPersonRoleDialog({ text: "", value: 0 });
    }

    itemEditClick(item: ListItemNumberModel) {
        this.$store.direct.dispatch.register.showPersonRoleDialog(item);
    }
}
