import { defineModule } from "direct-vuex";
import { moduleActionContext } from "@/store/index";
import UserRestModel from "@/models/rest/admin/userRestModel";
import UserListItemRestModel from "../../models/rest/admin/userListItemRestModel";
import UserBranchListItem from "../../models/rest/admin/userBranchListItem";
import { AxiosResponse, AxiosError } from "axios";
import { axiosInstance } from "../../plugins/axios";
import router from "@/router";
import ListItemNumberModel from "../../models/listItemNumberModel";
import AdminSettingModel from "../../models/rest/admin/adminSettingModel";

export interface AdminModuleStateInterface {
    user: UserRestModel;
    userBranches: UserBranchListItem[];
    userLoading: boolean;
    userSaveEnabled: boolean;
    userIsNew: boolean;
    userList: UserListItemRestModel[];
    userListLoading: false | boolean;
    userListTotalCount: number;
    userEmailvalidationError: string;
    userRolesValidationError: string;
    userInitialsValidationError: string;
    contractorSearch: ListItemNumberModel[];
    settings: AdminSettingModel;
}

const mutationTypes = {
    SET_USER_LIST: "SET_USER_LIST",
    SET_USER_LIST_LOADING: "SET_USER_LIST_LOADING",
    SET_USER_LIST_TOTAL_COUNT: "SET_USER_LIST_TOTAL_COUNT"
};

// eslint-disable-next-line
const adminModule = defineModule({
    namespaced: true,
    state: {
        user: {} as UserRestModel,
        userBranches: [] as UserBranchListItem[],
        userLoading: false,
        userSaveEnabled: false,
        userIsNew: true,
        userList: [] as UserListItemRestModel[],
        userListLoading: false,
        userListTotalCount: 0,
        userEmailvalidationError: "",
        userRolesValidationError: "",
        userInitialsValidationError: "",
        contractorSearch: [] as ListItemNumberModel[],
        settings: {} as AdminSettingModel,
    } as AdminModuleStateInterface,
    mutations: {
        setUser(state, value: UserRestModel) {
            state.user = value;
        },
        setUserBranches(state, value: UserBranchListItem[]) {
            state.userBranches = value;
        },
        setUserLoading(state, value: boolean) {
            state.userLoading = value;
        },
        setUserSaveEnabled(state, value: boolean) {
            state.userSaveEnabled = value;
        },
        setUserIsNew(state, value: boolean) {
            state.userIsNew = value;
        },
        [mutationTypes.SET_USER_LIST]: (state: AdminModuleStateInterface, userList: UserListItemRestModel[]) => {
            state.userList = userList;
        },
        [mutationTypes.SET_USER_LIST_LOADING]: (state: AdminModuleStateInterface, userListLoading: boolean) => {
            state.userListLoading = userListLoading;
        },
        [mutationTypes.SET_USER_LIST_TOTAL_COUNT]: (state: AdminModuleStateInterface, userListTotalCount: number) => {
            state.userListTotalCount = userListTotalCount;
        },
        setUserValidationError(state, value: any) {
            state.userEmailvalidationError = "";
            state.userRolesValidationError = "";
            state.userInitialsValidationError = "";
            if (value && value.Email) {
                state.userEmailvalidationError = value.Email[0];
            }
            if (value && value.Roles) {
                state.userRolesValidationError = value.Roles[0];
            }
            if (value && value.Initials) {
                state.userInitialsValidationError = value.Initials[0];
            }
        },
        setSettings(state, value: AdminSettingModel) {
            state.settings = value;
        },
    },
    actions: {
        loadUser(context, payload: { id: string }) {
            const { commit, dispatch } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                commit.setUserLoading(true);
                commit.setUserSaveEnabled(false);
                if (payload.id !== undefined) {
                    axiosInstance
                        .get("/api/admin/user/" + payload.id)
                        .then((resp: AxiosResponse) => {
                            commit.setUser(resp.data);
                            commit.setUserIsNew(false);
                            dispatch.loadUserBranches();
                            resolve(resp.data);
                        })
                        .catch((err: AxiosError) => reject(err));
                } else {
                    const user = { id: "00000000-0000-0000-0000-000000000000", defaultDepartmentId: 0 } as UserRestModel;
                    commit.setUser(user);
                    commit.setUserIsNew(true);
                    dispatch.loadUserBranches();
                    resolve(user);
                }
            });
        },
        loadUserBranches(context) {
            const { state, commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/admin/user/" + state.user.id + "/branches")
                    .then((resp: AxiosResponse) => {
                        commit.setUserBranches(resp.data);
                        commit.setUserLoading(false);
                        commit.setUserSaveEnabled(true);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        saveUser(context) {
            const { state, commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                commit.setUserLoading(true);
                commit.setUserSaveEnabled(false);
                axiosInstance
                    .post("/api/admin/user/save", { user: state.user, branches: state.userBranches })
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                        commit.setUserValidationError(null);
                        router.push("/admin/user/list");
                    })
                    .catch((err: AxiosError) => {
                        if (err.response && err.response.data.errors) {
                            commit.setUserValidationError(err.response.data.errors);
                            resolve(err.response.data.errors);
                        }
                        reject(err);
                    })
                    .finally(() => {
                        commit.setUserLoading(false);
                        commit.setUserSaveEnabled(true);
                    });
            });
        },
        loadUserList: ({ commit },
            payload: {
                searchText: string;
                searchUserRoleList: string[];
                page: number;
                itemsPerPage: number
            }) => {
            return new Promise((resolve, reject) => {
                commit(mutationTypes.SET_USER_LIST_LOADING, true);
                const params = {
                    searchText: payload.searchText,
                    searchRoles: payload.searchUserRoleList,
                    page: payload.page,
                    pageSize: payload.itemsPerPage
                };
                axiosInstance
                    .get("/api/admin/user/list", { params })
                    .then((resp: AxiosResponse) => {
                        commit(mutationTypes.SET_USER_LIST, resp.data.items);
                        commit(mutationTypes.SET_USER_LIST_TOTAL_COUNT, resp.data.totalItemsCount);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit(mutationTypes.SET_USER_LIST_LOADING, false);
                    });
            });
        },
        loadSettings(context) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/admin/other/get-settings")
                    .then((resp: AxiosResponse) => {
                        commit.setSettings(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        saveSettings(context) {
            const { state, commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/admin/other/save-settings", state.settings)
                    .then((resp: AxiosResponse) => {
                        commit.setSettings(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
    }
});

export default adminModule;
const _moduleActionContext = (context: any) => moduleActionContext(context, adminModule);
