import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component

export default class Settlement extends Vue {

    get costDocumentList() {
        return {
            route: "/settlement/cost-document/list",
            label: this.$i18n.t("costDocument.costDocumentList"),
            icon: "mdi-application-import"
        }
    }

    get costDocumentCorrectionList() {
        return {
            route: "/settlement/cost-document/correction/list",
            label: this.$i18n.t("costDocument.costDocumentCorrectionList"),
            icon: "mdi-application-export"
        }
    }

    get costPaymentObligationList() {
        return {
            route: "/settlement/cost-payment-obligation/list",
            label: this.$i18n.t("paymentObligation.costPaymentObligationList"),
            icon: "mdi-credit-card-outline"
        }
    }

    get transferOrderList() {
        return {
            route: "/settlement/transfer-order/list",
            label: this.$i18n.t("transferOrder.transferOrderList"),
            icon: "mdi-swap-vertical"
        }
    }

    get transferOrderPackageList() {
        return {
            route: "/settlement/transfer-order-package/list",
            label: this.$i18n.t("transferOrderPackage.transferOrderPackageList"),
            icon: "mdi-format-list-numbered"
        }
    }

    get costDocumentPayment() {
        return {
            route: "/settlement/payment/cost-payment/list",
            label: this.$i18n.t("payment.costPaymentList"),
            icon: "mdi-clipboard-list-outline"
        }
    }

    get incomeDocumentList() {
        return {
            route: "/settlement/income-document/list",
            label: this.$i18n.t("incomeDocument.incomeDocumentList"),
            icon: "mdi-application-export"
        }
    }

    get incomeDocumentCorrectionList() {
        return {
            route: "/settlement/income-document/correction/list",
            label: this.$i18n.t("incomeDocument.incomeDocumentCorrectionList"),
            icon: "mdi-application-export"
        }
    }

    get incomePaymentObligationList() {
        return {
            route: "/settlement/income-payment-obligation/list",
            label: this.$i18n.t("paymentObligation.incomePaymentObligationList"),
            icon: "mdi-credit-card-outline"
        }
    }

    get registerExportList() {
        return {
            route: "/settlement/register-export/list",
            label: "Export",
            icon: "mdi-export"
        }
    }

    get incomeDocumentPayment() {
        return {
            route: "/settlement/payment/income-payment/list",
            label: this.$i18n.t("payment.incomePaymentList"),
            icon: "mdi-clipboard-list-outline"
        }
    }

    get closedMonth() {
        return {
            route: "/settlement/closedMonth/list",
            label: this.$i18n.t("closedMonth.closedMonthList"),
            icon: "mdi-calendar-lock-outline"
        }
    }
}
