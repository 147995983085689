import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { Loader } from "@googlemaps/js-api-loader";
import { } from "google-maps";
import InfoWindow from "./info-window.vue";
import VehicleRestModel from "../../models/rest/vehicle/vehicleRestModel";
import { MarkerManager } from "@googlemaps/markermanager"


@Component({
    components: {
        "info-window": InfoWindow
    }
})

export default class Map extends Vue {
    map: google.maps.Map | undefined;
    showInfoWindow = false;
    currentVehicleMarkers: any = {};
    infoWindowElement = document.getElementById("info-window") as HTMLElement;
    vMarkers: any[] = [];
    previewVehicleKey: number | null = null;
    currentInfoWindow: any = {};
    directionsService: google.maps.DirectionsService | undefined;
    directionsRenderer: google.maps.DirectionsRenderer | undefined;
    waypts: google.maps.DirectionsWaypoint[] = [];
    computedDistance: string = "";
    computedTime: string = "";
    interval: number | undefined = 0;

    loader = new Loader({
        apiKey: this.googleMapsApiKey,
        libraries: ["places"]
    });

    get vehicleModifiedTime(): string {
        return this.$store.direct.state.vehicle.vehicleModifiedTime;
    }

    get googleMapsApiKey(): string {
        return this.$store.direct.state.auth.googleMapsApiKey;
    }

    get showInfoDialog(): boolean {
        return this.$store.direct.state.vehicle.showVehicleMapInfoDialog;
    }

    get infoDialogMessage(): string {
        return this.$store.direct.state.vehicle.vehicleMapInfoDialogMessage;
    }
    get vehicleMapList(): VehicleRestModel[] {
        return this.$store.direct.state.vehicle.vehicleMapList;
    }

    get vehicle(): VehicleRestModel {
        return this.$store.direct.state.vehicle.vehicle;
    }

    @Watch("vehicleModifiedTime")
    async vehicleModifiedTimeChanged() {
        await this.$store.direct.dispatch.vehicle.loadVehicleMapList();
        this.initialMarkers();
    }

    async created() {
        await this.$store.direct.dispatch.vehicle.loadVehicleMapList();
        await this.loadMap()
        this.interval = setInterval(async () => await this.$store.direct.dispatch.vehicle.checkChanges(), 30000)
    }

    destroyed() {
        clearInterval(this.interval);
        this.interval = undefined;
    }

    async holdOnOffChildClick(vehicleId: number) {
        const vehicleIcon = await this.getVehicleIcon(this.vehicle)
        const vehicleMarker = await this.currentVehicleMarkers[vehicleId]
        await vehicleMarker.marker.setIcon(vehicleIcon)
    }

    async unavailableChildClick(vehicleId: number) {
        await this.currentVehicleMarkers[vehicleId].infoWindow.close();
        await this.currentVehicleMarkers[vehicleId].marker.setMap(null);
        this.currentVehicleMarkers[vehicleId].marker = null;
        delete this.currentVehicleMarkers[vehicleId]
    }

    async getDirectionClick(waystations: any[], avoidHighways: boolean, avoidTolls: boolean) {
        this.waypts = [];
        waystations.forEach((station: any) => {
            let latLng: google.maps.LatLng;

            if (station.fullPlaceName.lat == undefined) {
                latLng = new google.maps.LatLng(station.lat, station.lng)
            }
            else {
                latLng = new google.maps.LatLng(station.fullPlaceName.lat, station.fullPlaceName.lng)
            }
            this.waypts.push({ location: latLng })
        })

        const request: google.maps.DirectionsRequest = {
            origin: this.waypts[0] as google.maps.LatLng,
            destination: this.waypts[this.waypts.length - 1] as google.maps.LatLng,
            waypoints: this.waypts,
            travelMode: google.maps.TravelMode.DRIVING,
            avoidHighways: avoidHighways,
            avoidTolls: avoidTolls,
        };

        this.directionsService?.route(request, (result: google.maps.DirectionsResult, status: google.maps.DirectionsStatus) => {
            if (status == google.maps.DirectionsStatus.OK) {
                this.directionsRenderer?.setDirections(result);
                this.directionsRenderer?.setOptions({
                    markerOptions: {
                        visible: false
                    } as google.maps.MarkerOptions
                });
                this.computeTotalDistance(result);
                this.computeTotalTime(result);
            }
        })
    }

    computeTotalDistance(result: google.maps.DirectionsResult) {
        let total = 0;
        const myroute = result.routes[0];
        for (let i = 0; i < myroute.legs.length; i++) {
            total += myroute.legs[i].distance.value;
        }
        total = Math.round(total / 1000);
        this.computedDistance = total + " km";
    }

    computeTotalTime(result: google.maps.DirectionsResult) {
        let hours = 0;
        let minutes = 0;
        let totalTime = 0;
        const myroute = result.routes[0];
        for (let i = 0; i < myroute.legs.length; i++) {
            totalTime += myroute.legs[i].duration.value;
        }
        minutes = Math.round(totalTime / 60);
        hours = Math.floor(minutes / 60);
        minutes = (minutes % 60);
        this.computedTime = hours + ' h, ' + minutes + ' min'
    }

    vehicleExist(vehicle: any, vehicleCollecion: any) {
        const vehicleId = vehicle.id.toString()
        const hasVehicle = Object.keys(vehicleCollecion).includes(vehicleId)
        if (hasVehicle) {
            return vehicleCollecion[parseInt(vehicleId)];
        }
        else {
            return null;
        }
    }

    setupMarkers(vehicleMarkers: any) {
        let i = 0

        Object.keys(this.currentVehicleMarkers).forEach(async key => {
            const currentVehicle = this.currentVehicleMarkers[parseInt(key)];
            const vehicleExist = this.vehicleExist(currentVehicle, vehicleMarkers)

            if (vehicleExist == null) {
                currentVehicle.marker.setMap(null);
                currentVehicle.marker = null;
                delete this.currentVehicleMarkers[parseInt(key)]
            }
        })

        Object.keys(vehicleMarkers).forEach(async key => {
            const vehicle = vehicleMarkers[parseInt(key)];
            const pos = new google.maps.LatLng(vehicle.lat, vehicle.lng);
            const markerImage = await this.getVehicleIcon(vehicle);
            const title = `${vehicle.registrationNumber} (${vehicle.palletCount}) Available from: ${vehicle.availableFrom}`;
            let marker: any = null;
            const returnedVehicle = this.vehicleExist(vehicle, this.currentVehicleMarkers)
            const oldMarker = returnedVehicle != null ? returnedVehicle as VehicleRestModel : null;

            if (oldMarker != null) {
                marker = oldMarker.marker;
                marker.setPosition(pos);
                marker.setIcon(markerImage);
                marker.setTitle(title);
            }
            else {
                const markerOptions =
                {
                    title: title,
                    icon: markerImage,
                    position: pos,
                    map: this.map
                };
                marker = new google.maps.Marker(markerOptions);
                vehicle.marker = marker;
                const infoWindow = new google.maps.InfoWindow();
                this.createMyInfoWindow(infoWindow, marker, vehicle);
                vehicle.infoWindow = infoWindow;
                this.currentVehicleMarkers[vehicle.id] = vehicle
            }

            this.vMarkers[i] = [];
            this.vMarkers[i][0] = marker;
            this.vMarkers[i][1] = 0;
            this.vMarkers[i][2] = marker.getPosition()?.lat();
            this.vMarkers[i][3] = marker.getPosition()?.lng();

            for (let l = 0; l < i; l++) {
                if ((this.vMarkers[l][2] == this.vMarkers[i][2]) && (this.vMarkers[l][3] == this.vMarkers[i][3])) {
                    this.vMarkers[i][1]++;
                }
            }
            i++
        })
        new MarkerManager(this.map as google.maps.Map, this.vMarkers);
    }

    createMyInfoWindow(infoWindow: any, marker: any, vehicle: any) {
        const that = this;
        google.maps.event.clearListeners(marker, 'click');
        google.maps.event.addListener(marker, 'click', async function () {
            infoWindow.close();
            const currentInfoWindowElement = document.getElementById("info-window") as HTMLElement;

            if (Object.keys(that.currentInfoWindow).length != 0) {
                that.currentInfoWindow.close();
            }

            if (that.infoWindowElement == undefined) {
                that.infoWindowElement = currentInfoWindowElement;
            }

            await that.$store.direct.dispatch.vehicle.loadVehicle({ id: vehicle.id });
            that.showInfoWindow = true;
            infoWindow.setContent(that.infoWindowElement);
            infoWindow.open(that.map, marker);
            that.currentInfoWindow = infoWindow
        });
    }

    async initialMarkers() {
        const vehicleMarkers: any = {};
        await this.vehicleMapList.forEach((item: any) => {
            vehicleMarkers[item.id] = {
                id: item.id,
                availableFrom: item.availableFrom,
                isSpecial: item.isSpecial,
                lat: item.lat,
                lng: item.lng,
                palletCount: item.palletCount,
                registrationNumber: item.registrationNumber,
                vehicleTypeId: item.vehicleTypeId,
                vehicleState: item.vehicleState,
            }
        })
        this.setupMarkers(vehicleMarkers);
    }

    async loadMap() {
        await this.loader.load().then(async () => {
            this.map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
                center: {
                    lat: 50,
                    lng: 15
                },
                zoom: 5,
                mapTypeControl: true
            });
            await this.initialMarkers()

            this.directionsService = new google.maps.DirectionsService();
            this.directionsRenderer = new google.maps.DirectionsRenderer();
            this.directionsRenderer.setMap(this.map);
        })
    }

    get vehicleIcons(): any {
        return this.$store.direct.state.common.vehicleIcons;
    }

    async getVehicleIcon(vehicle: VehicleRestModel) {
        const vehicleTypeId = vehicle.vehicleTypeId;
        const vehicleState = vehicle.vehicleState;
        const iconKey = vehicleTypeId + '_' + vehicleState;
        const icon = this.vehicleIcons[iconKey]

        if (icon == undefined) {
            await this.$store.direct.dispatch.common.loadVehicleIcon({ typeId: vehicleTypeId, state: vehicleState, key: iconKey });
        }

        return this.vehicleIcons[iconKey]
    }
}
