import { moduleActionContext } from "@/store/index";
import { AxiosError, AxiosResponse } from "axios";
import { defineModule } from "direct-vuex";
import ContractorSearchItemModel from "../../models/rest/contractor/contractorSearchItemModel";
import SupervisorAllOrdersItemRestModel from "../../models/rest/supervisor/supervisorAllOrdersItemRestModel";
import SupervisorAllOrdersListRequestModel from "../../models/rest/supervisor/supervisorAllOrdersListRequestModel";
import SupervisorItemRestModel from "../../models/rest/supervisor/supervisorItemRestModel";
import { axiosInstance } from "../../plugins/axios";

export interface SupervisorAllOrdersModuleStateInterface {
    supervisorAllOrdersListLoading: boolean;
    supervisorAllOrdersList: SupervisorAllOrdersItemRestModel[],
    supervisorAllOrdersListTotalCount: number,
    supervisorAllOrdersListSearchText: string,
    supervisorAllOrdersListSearchLoadingPlaceCountry: string,
    supervisorAllOrdersListSearchUnloadingPlaceCountry: string,
    supervisorAllOrdersListSearchLoadingPlacePostalCode: string,
    supervisorAllOrdersListSearchUnloadingPlacePostalCode: string,
    supervisorAllOrdersListSearchLoadingPlaceCity: string,
    supervisorAllOrdersListSearchUnloadingPlaceCity: string,
    supervisorAllOrdersListSearchCustomerIdcs: number[],
    supervisorAllOrdersListSearchShippingCompanyIdcs: number[],
    supervisorAllOrdersListSearchLoadingDateStartDate: string,
    supervisorAllOrdersListSearchLoadingDateEndDate: string,
    supervisorAllOrdersListSearchUnloadingDateStartDate: string,
    supervisorAllOrdersListSearchUnloadingDateEndDate: string,
    supervisorAllOrdersListPagerOptions: any,
    selectedContractorSenderListForFilter: ContractorSearchItemModel[];
    selectedContractorCarrierListForFilter: ContractorSearchItemModel[];
    contractorSenderFound: ContractorSearchItemModel[];
    contractorCarrierFound: ContractorSearchItemModel[];
    backToSupervisorAllOrdersList: boolean;
}

const supervisorModule = defineModule({
    namespaced: true,
    state: {
        supervisorAllOrdersListLoading: false,
        supervisorAllOrdersList: [] as SupervisorItemRestModel[],
        supervisorAllOrdersListTotalCount: 0,
        supervisorAllOrdersListSearchText: "",
        supervisorAllOrdersListSearchLoadingPlaceCountry: "",
        supervisorAllOrdersListSearchUnloadingPlaceCountry: "",
        supervisorAllOrdersListSearchLoadingPlacePostalCode: "",
        supervisorAllOrdersListSearchUnloadingPlacePostalCode: "",
        supervisorAllOrdersListSearchLoadingPlaceCity: "",
        supervisorAllOrdersListSearchUnloadingPlaceCity: "",
        supervisorAllOrdersListSearchCustomerIdcs: [] as number[],
        supervisorAllOrdersListSearchShippingCompanyIdcs: [] as number[],
        supervisorAllOrdersListSearchLoadingDateStartDate: "",
        supervisorAllOrdersListSearchLoadingDateEndDate: "",
        supervisorAllOrdersListSearchUnloadingDateStartDate: "",
        supervisorAllOrdersListSearchUnloadingDateEndDate: "",
        supervisorAllOrdersListPagerOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ["identificationData"],
            sortDesc: [true]
        },
        selectedContractorSenderListForFilter: [] as ContractorSearchItemModel[],
        selectedContractorCarrierListForFilter: [] as ContractorSearchItemModel[],
        contractorSenderFound: [] as ContractorSearchItemModel[],
        contractorCarrierFound: [] as ContractorSearchItemModel[],
        backToSupervisorAllOrdersList: false,
    } as SupervisorAllOrdersModuleStateInterface,
    mutations: {
        setSupervisorAllOrdersListLoading(state, value: boolean) {
            state.supervisorAllOrdersListLoading = value;
        },
        setSupervisorAllOrdersList(state, value: SupervisorItemRestModel[]) {
            state.supervisorAllOrdersList = value;
        },
        setSuperVisorAllOrdersListTotalCount(state, value: number) {
            state.supervisorAllOrdersListTotalCount = value;
            if ((state.supervisorAllOrdersListPagerOptions.page * state.supervisorAllOrdersListPagerOptions.itemsPerPage) >= (value + state.supervisorAllOrdersListPagerOptions.itemsPerPage)) {
                state.supervisorAllOrdersListPagerOptions.page = 1;
            }
        },
        setSupervisorAllOrdersListSearchText(state, value: string) {
            state.supervisorAllOrdersListSearchText = value;
        },
        setSupervisorAllOrdersListSearchLoadingPlaceCountry(state, value: string) {
            state.supervisorAllOrdersListSearchLoadingPlaceCountry = value;
        },
        setSupervisorAllOrdersListSearchUnloadingPlaceCountry(state, value: string) {
            state.supervisorAllOrdersListSearchUnloadingPlaceCountry = value;
        },
        setSupervisorAllOrdersListSearchLoadingPlacePostalCode(state, value: string) {
            state.supervisorAllOrdersListSearchLoadingPlacePostalCode = value;
        },
        setSupervisorAllOrdersListSearchUnloadingPlacePostalCode(state, value: string) {
            state.supervisorAllOrdersListSearchUnloadingPlacePostalCode = value;
        },
        setSupervisorAllOrdersListSearchLoadingPlaceCity(state, value: string) {
            state.supervisorAllOrdersListSearchLoadingPlaceCity = value;
        },
        setSupervisorAllOrdersListSearchUnloadingPlaceCity(state, value: string) {
            state.supervisorAllOrdersListSearchUnloadingPlaceCity = value;
        },
        setSupervisorAllOrdersListSearchCustomerIdcs(state, value: number[]) {
            state.supervisorAllOrdersListSearchCustomerIdcs = value;
        },
        setSupervisorAllOrdersListSearchShippingCompanyIdcs(state, value: number[]) {
            state.supervisorAllOrdersListSearchShippingCompanyIdcs = value;
        },
        setSupervisorAllOrdersListSearchLoadingDateStartDate(state, value: string) {
            state.supervisorAllOrdersListSearchLoadingDateStartDate = value;
        },
        setSupervisorAllOrdersListSearchLoadingDateEndDate(state, value: string) {
            state.supervisorAllOrdersListSearchLoadingDateEndDate = value;
        },
        setSupervisorAllOrdersListSearchUnloadingDateStartDate(state, value: string) {
            state.supervisorAllOrdersListSearchUnloadingDateStartDate = value;
        },
        setSupervisorAllOrdersListSearchUnloadingDateEndDate(state, value: string) {
            state.supervisorAllOrdersListSearchUnloadingDateEndDate = value;
        },
        setSupervisorAllOrdersListPagerOptions(state, value) {
            state.supervisorAllOrdersListPagerOptions = value;
        },
        setSelectedContractorSenderListForFilter(state, value: ContractorSearchItemModel[]) {
            state.selectedContractorSenderListForFilter = value;
        },
        setSelectedContractorCarrierListForFilter(state, value: ContractorSearchItemModel[]) {
            state.selectedContractorCarrierListForFilter = value;
        },
        setContractorSenderFound(state, value: ContractorSearchItemModel[]) {
            state.contractorSenderFound = value;
        },
        setContractorCarrierFound(state, value: ContractorSearchItemModel[]) {
            state.contractorCarrierFound = value;
        },
        setBackToSupervisorAllOrdersList(state, value: boolean) {
            state.backToSupervisorAllOrdersList = value;
        },
    },
    actions: {
        loadSupervisorAllOrdersList(context, payload: { requestModel: SupervisorAllOrdersListRequestModel }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                commit.setSupervisorAllOrdersListLoading(true);
                axiosInstance
                    .post("/api/supervisor/all-orders-list", payload.requestModel)
                    .then((resp: AxiosResponse) => {
                        commit.setSupervisorAllOrdersList(resp.data.supervisorAllItems);
                        commit.setSuperVisorAllOrdersListTotalCount(resp.data.supervisorAllItemsTotalCount)
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setSupervisorAllOrdersListLoading(false);
                    })
            })
        },
    }
});

export default supervisorModule;
const _moduleActionContext = (context: any) => moduleActionContext(context, supervisorModule)