import { defineModule } from "direct-vuex";
import { moduleActionContext } from "@/store/index";
import { AxiosResponse, AxiosError } from "axios";
import { axiosInstance } from "../../plugins/axios";
import TransportOrderRestModel from "../../models/rest/transport-order/transportOrderRestModel";
import TransportOrderDocumentRestModel from "../../models/rest/transport-order/cransportOrderDocumentRestModel";
import router from "@/router";


export interface TransportOrderModuleStateInterface {
    transportOrder: TransportOrderRestModel;
    transportOrderList: TransportOrderRestModel[];
    transportOrderListLoading: false | boolean;
    transportOrderListTotalCount: number;
    showTransportOrderAlert: boolean;
    transportOrderCardTitle: string;
    loadingAddressesFound: [];
    unloadingAddressesFound: [];
    showDocumentsDialog: boolean;
    documentList: TransportOrderDocumentRestModel[];
    transportOrderListPagerOptions: any;
    transportOrderListSearchText: string;
}

const transportOrderModule = defineModule({
    namespaced: true,
    state: {
        transportOrder: {} as TransportOrderRestModel,
        transportOrderList: [] as TransportOrderRestModel[],
        transportOrderListLoading: false,
        transportOrderListTotalCount: 0,
        transportOrderCardTitle: "",
        loadingAddressesFound: [],
        unloadingAddressesFound: [],
        showDocumentsDialog: false,
        documentList: [] as TransportOrderDocumentRestModel[],
        transportOrderListPagerOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ["createdAt"],
            sortDesc: [true]
        },
        transportOrderListSearchText: "",
    } as TransportOrderModuleStateInterface,
    mutations: {
        setTransportOrder(state, value: TransportOrderRestModel) {
            state.transportOrder = value;
        },
        setTransportOrderList(state, value: TransportOrderRestModel[]) {
            state.transportOrderList = value;
        },
        setTransportOrderListLoading(state, value: boolean) {
            state.transportOrderListLoading = value;
        },
        setTransportOrderListTotalCount(state, value: number) {
            state.transportOrderListTotalCount = value;
            if ((state.transportOrderListPagerOptions.page * state.transportOrderListPagerOptions.itemsPerPage) >= (value + state.transportOrderListPagerOptions.itemsPerPage)) {
                state.transportOrderListPagerOptions.page = 1;
            }
        },
        setLoadingAddressesFound(state, value: []) {
            state.loadingAddressesFound = value;
        },
        setUnloadingAddressesFound(state, value: []) {
            state.unloadingAddressesFound = value;
        },
        setTransportOrderCardTitle(state, value: string) {
            state.transportOrderCardTitle = value;
        },
        setShowDocumentsDialog(state, value: boolean) {
            state.showDocumentsDialog = value;
        },
        setDocumentList(state, value: TransportOrderDocumentRestModel[]) {
            state.documentList = value;
        },
        setTransportOrderListPagerOptions(state, value: any) {
            state.transportOrderListPagerOptions = value;
        },
        setTransportOrderListSearchText(state, value: string) {
            state.transportOrderListSearchText = value;
        }
    },
    actions: {
        transportOrderListCleanFilters(context) {
            const { commit } = _moduleActionContext(context);
            const filterOptions = {
                page: 1,
                itemsPerPage: 10,
                sortBy: ["createdAt"],
                sortDesc: [true]
            }
            commit.setTransportOrderListSearchText("");
            commit.setTransportOrderListPagerOptions(filterOptions);
        },
        saveTransportOrder(context) {
            const { state } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/transportOrder/save", state.transportOrder)
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                        router.push({ path: "/transport-order/list" });
                    }).
                    catch((err: AxiosError) => reject(err));
            });
        },
        loadTemporaryTransportOrder(context) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/transportOrder/temporary/")
                    .then((resp: AxiosResponse) => {
                        commit.setDocumentList([]);
                        commit.setTransportOrder(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        duplicateTransportOrder(context, payload: { id: number }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/transportOrder/" + payload.id + "/duplicate")
                    .then((resp: AxiosResponse) => {
                        commit.setTransportOrder(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        loadTransportOrderList(
            context,
            payload: {
                page: number;
                itemsPerPage: number;
                searchText: string;
                sortBy: string;
                sortDesc: boolean;
            }
        ) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                commit.setTransportOrderListLoading(true);
                const params = {
                    page: payload.page,
                    pageSize: payload.itemsPerPage,
                    searchText: payload.searchText,
                    sortBy: payload.sortBy,
                    sortDesc: payload.sortDesc
                };
                axiosInstance
                    .get("/api/transportOrder/list", { params })
                    .then((resp: AxiosResponse) => {
                        commit.setTransportOrderList(resp.data.items);
                        commit.setTransportOrderListTotalCount(resp.data.totalItemsCount);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setTransportOrderListLoading(false);
                    });
            });
        },
        loadTransportOrder(context, payload: { id: number }) {
            const { commit, dispatch } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/transportOrder/" + payload.id)
                    .then((resp: AxiosResponse) => {
                        commit.setTransportOrder(resp.data);
                        dispatch.loadDocumentList();
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        downloadFile(context, payload: { id: number }) {
            return new Promise((reject) => {
                axiosInstance
                    .get("/api/transportOrder/" + payload.id + "/pdf/download/", {
                        responseType: "blob"
                    })
                    .then((resp: AxiosResponse) => {
                        const blob = new Blob([resp.data], {
                            type: resp.headers["content-type"]
                        });
                        const contentDisposition = resp.headers["content-disposition"];
                        const filename = contentDisposition
                            .split(";")[1]
                            .split("=")[1]
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement("a");
                        a.style.display = "none";
                        document.body.appendChild(a);
                        a.download = filename;
                        a.href = url;
                        a.click();
                        window.URL.revokeObjectURL(url);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        createDocument(context) {
            const { commit } = _moduleActionContext(context);
            commit.setShowDocumentsDialog(true);
        },
        closeDocumentsDialog(context) {
            const { commit } = _moduleActionContext(context);
            commit.setShowDocumentsDialog(false);
        },
        saveDocument(context, payload) {
            const { state, commit, dispatch } = _moduleActionContext(context);
            const formData = new FormData();

            for (const index in payload.files) {
                formData.append("files", payload.files[index]);
                formData.append("isProofOfDelivery", payload.isProofOfDelivery);
            }

            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/transportOrder/" + state.transportOrder.id + "/document/save", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                    .then((resp: AxiosResponse) => {
                        commit.setShowDocumentsDialog(false);
                        dispatch.loadDocumentList();
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        loadDocumentList(context) {
            const { state, commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/transportOrder/" + state.transportOrder.id + "/document/list")
                    .then((resp: AxiosResponse) => {
                        commit.setDocumentList(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        downloadDocument(context, payload?: { id: number }) {
            const { state, commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/transportOrder/" + state.transportOrder.id + "/document/download/" + payload?.id, {
                        responseType: "arraybuffer"
                    })
                    .then((resp: AxiosResponse) => {
                        const blob = new Blob([resp.data], {
                            type: resp.headers["content-type"]
                        });
                        const contentDisposition = resp.headers["content-disposition"];
                        const filename = contentDisposition
                            .split(";")[1]
                            .split("=")[1]
                            .replace('"', "")
                            .replace('"', "");

                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement("a");
                        a.style.display = "none";
                        document.body.appendChild(a);
                        a.download = filename;
                        a.href = url;
                        a.click();
                        window.URL.revokeObjectURL(url);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        saveTrackingData(context, payload: { transportOrderId: number, trackingUrl: string, trackingLoginAccessData: string }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/transportOrder/tracking/save/", { id: payload.transportOrderId, trackingUrl: payload.trackingUrl, trackingLoginAccessData: payload.trackingLoginAccessData })
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                        router.push({ path: "/transport-order/list" });
                    }).
                    catch((err: AxiosError) => reject(err));
            })
        },
        searchAddress(context, payload: { searchText: string, searchTextType: string }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/transportOrder/address/search", { searchText: payload.searchText, searchTextType: payload.searchTextType })
                    .then((resp: AxiosResponse) => {
                        if (resp.data.searchTextType == "loadingAddress") {
                            commit.setLoadingAddressesFound(resp.data.itemsFound);
                        }
                        else if (resp.data.searchTextType == "unloadingAddress") {
                            commit.setUnloadingAddressesFound(resp.data.itemsFound);
                        }
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        }
    }
});

export default transportOrderModule;
const _moduleActionContext = (context: any) => moduleActionContext(context, transportOrderModule);
