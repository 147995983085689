import { render, staticRenderFns } from "./layout.vue?vue&type=template&id=2bbdf8e7"
import script from "./layout.ts?vue&type=script&lang=js&external"
export * from "./layout.ts?vue&type=script&lang=js&external"
import style0 from "./layout.vue?vue&type=style&index=0&id=2bbdf8e7&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VApp,VContainer,VMain,VProgressLinear})
