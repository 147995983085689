import i18n from "@/assets/i18n";
import { Roles } from "@/models/emums/roles";
import router from "@/router";
import axios from "axios";
import { defineModule } from "direct-vuex";
import TokenRestModel from "../../models/rest/user/tokenRestModel";

export interface AuthInterface {
    isLogged: false | boolean;
    error: 0 | number;
    errorMsg: "" | string;
    allRoles: string[];
    userRoles: string[];
    userRolesReady: boolean;
    currentUserEmail: string;
    currentUserId: string;
    currentUserName: string;
    currentUserFirstName: string;
    currentUserLastName: string;
    currentUserContractorId: number;
    currentUserContractorName: string;
    currentUserDefaultDepartmentId: number;
    currentUserDepartments: number[];
    googleMapsApiKey: string;
    show2FADialog: boolean;
    resolve2FADialog: null;
    code2FA: string;
    temporaryUserEmailFor2FACode: string;
}

const types = {
    SET_IS_LOGGED: "SET_IS_LOGGED",
    SET_ROLES: "SET_ROLES",
    SET_ROLES_READY: "SET_ROLES_READY"
};

const authModule = defineModule({
    namespaced: true,
    state: {
        isLogged: false,
        allRoles: [
            Roles.AccountantAdmin,
            Roles.Accountant,
            Roles.Admin,
            Roles.Client,
            Roles.CrmAdmin,
            Roles.CrmLower,
            Roles.Crm,
            Roles.Disponent,
            Roles.DisponentAdmin,
            Roles.FullEditingDisponentAdmin,
            Roles.HigherDisponentAdmin,
            Roles.Partner,
            Roles.TrackingClient,
            Roles.TrackingClientAdmin,
            Roles.User
        ],
        userRoles: [] as string[],
        userRolesReady: false,
        currentUserEmail: "",
        currentUserId: "",
        currentUserName: "",
        currentUserFirstName: "",
        currentUserLastName: "",
        currentUserContractorId: 0,
        currentUserContractorName: "",
        currentUserDefaultDepartmentId: 0,
        currentUserDepartments: [] as number[],
        googleMapsApiKey: "",
        show2FADialog: false,
        resolve2FADialog: null,
        code2FA: "",
        temporaryUserEmailFor2FACode: "",
    } as AuthInterface,
    mutations: {
        [types.SET_IS_LOGGED]: (state: AuthInterface, isLogged: boolean) => {
            state.isLogged = isLogged;
        },
        [types.SET_ROLES]: (state: AuthInterface, roles: string[]) => {
            state.userRoles = roles;
        },
        [types.SET_ROLES_READY]: (state: AuthInterface, ready: boolean) => {
            state.userRolesReady = ready;
        },
        setCurrentUserEmail(state, value: string) {
            state.currentUserEmail = value;
        },
        setCurrentUserId(state, value: string) {
            state.currentUserId = value;
        },
        setCurrentUserName(state, value: string) {
            state.currentUserName = value;
        },
        setCurrentUserFirstName(state, value: string) {
            state.currentUserFirstName = value;
        },
        setCurrentUserLastName(state, value: string) {
            state.currentUserLastName = value;
        },
        setCurrentUserContractorId(state, value: number) {
            state.currentUserContractorId = value;
        },
        setCurrentUserContractorName(state, value: string) {
            state.currentUserContractorName = value;
        },
        setCurrentUserDefaultDepartmentId(state, value: number) {
            state.currentUserDefaultDepartmentId = value;
        },
        setCurrentUserDepartments(state, value: number[]) {
            state.currentUserDepartments = value;
        },
        setGoogleMapsApiKey(state, value: string) {
            state.googleMapsApiKey = value;
        },
        setShow2FADialog(state, value: boolean) {
            state.show2FADialog = value;
        },
        setResolve2FADialog(state, value: any) {
            state.resolve2FADialog = value;
        },
        setCode2FA(state, value: string) {
            state.code2FA = value;
        },
        setTemporaryUserEmailFor2FACode(state, value: string) {
            state.temporaryUserEmailFor2FACode = value;
        },
    },
    actions: {
        login: ({ state, commit, dispatch }, payload: { email: string; password: string }) => {
            return new Promise((resolve, reject) => {
                axios.post("/api/user/login", payload)
                    .then(response => {
                        if (response.data.require2FA == true) {
                            commit("setCode2FA", "");
                            commit("setTemporaryUserEmailFor2FACode", payload.email);
                            dispatch("openDialog2FADialog", { showDialog: true });
                            reject(i18n.t("login.varificationCodeRequired"));
                        }
                        else {
                            const tokenModel = response.data as TokenRestModel;
                            dispatch("saveTokenDataToLocalStorageAndLoadUserProfile", { tokenModel: tokenModel });
                            resolve(response.data);
                        }
                    })
                    .catch(error => {
                        if (error.response.status == 401) {
                            reject(i18n.t("login.invalidAccess"));
                        }
                        if (error.response.status == 404) {
                            reject(i18n.t("login.timeLock"));
                        }
                        reject(error.response.status);
                    });
            });
        },
        verify2FA: ({ state, commit, dispatch }, payload: { code: string }) => {
            const model = { email: state.temporaryUserEmailFor2FACode, code: payload.code };
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/user/verify-2fa", model)
                    .then(response => {
                        const tokenModel = response.data as TokenRestModel;
                        dispatch("saveTokenDataToLocalStorageAndLoadUserProfile", { tokenModel: tokenModel });
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error.response.status);
                    })
                    .finally(() => {
                        commit("setCode2FA", "");
                        commit("setTemporaryUserEmailFor2FACode", "");
                    });
            });
        },
        saveTokenDataToLocalStorageAndLoadUserProfile: ({ state, commit, dispatch }, payload: { tokenModel: TokenRestModel }) => {
            localStorage.setItem("data", JSON.stringify(payload.tokenModel.accessToken));
            localStorage.setItem("dataExpiration", JSON.stringify(payload.tokenModel.accessTokenExpiration));
            localStorage.setItem("refreshToken", JSON.stringify(payload.tokenModel.refreshToken));
            localStorage.setItem("refreshTokenExpiration", JSON.stringify(payload.tokenModel.refreshTokenExpiration));

            commit(types.SET_IS_LOGGED, true);
            dispatch("loadUserProfile");
        },
        loadUserProfile: ({ commit }) => {
            return new Promise((resolve, reject) => {
                const token = JSON.parse(localStorage.getItem("data") || "{}");
                const bearer = `Bearer ${token}`;
                axios.defaults.headers.common["Authorization"] = bearer;

                axios
                    .get("/api/user/profile")
                    .then(async response => {
                        const data = response.data;
                        commit(types.SET_IS_LOGGED, true);
                        commit(types.SET_ROLES, data.roles);
                        commit(types.SET_ROLES_READY, true);
                        resolve(response.data);
                        commit("setCurrentUserEmail", response.data.email);
                        commit("setCurrentUserId", response.data.id);
                        commit("setCurrentUserName", response.data.name);
                        commit("setCurrentUserFirstName", response.data.firstName);
                        commit("setCurrentUserLastName", response.data.lastName);
                        commit("setCurrentUserContractorId", response.data.contractorId);
                        commit("setCurrentUserContractorName", response.data.contractorName);
                        commit("setCurrentUserDefaultDepartmentId", response.data.defaultDepartmentId);
                        commit("setCurrentUserDepartments", response.data.departments);
                        commit("setGoogleMapsApiKey", response.data.googleMapsApiKey);
                    })
                    .catch(error => {
                        commit(types.SET_ROLES_READY, true);
                        reject(error.response.status);
                    });
            });
        },
        logout: ({ commit }) => {
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/user/logout")
                    .then(response => {
                        localStorage.removeItem("data");
                        localStorage.removeItem("dataExpiration");
                        localStorage.removeItem("refreshToken");
                        localStorage.removeItem("refreshTokenExpiration");
                        commit(types.SET_IS_LOGGED, false);
                        commit(types.SET_ROLES, []);
                        commit(types.SET_ROLES_READY, true);
                        commit("setCurrentUserContractorId", null);
                        commit("setCurrentUserContractorName", null);
                        commit("setCurrentUserDefaultDepartmentId", 0);
                        commit("setCurrentUserDefaultDepartmentId", []);
                        if (router.currentRoute.path != "/login") {
                            router.push({ path: "/login" });
                        }
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error.response.status);
                    });
            });
        },
        isConfirmed2FADialog: ({ commit }) => {
            commit("setResolve2FADialog", true);
            commit("setShow2FADialog", false);
        },

        isNotConfirmed2FADialog: ({ commit }) => {
            commit("setShow2FADialog", false);
        },
        openDialog2FADialog: ({ commit }, payload: { showDialog: boolean }) => {
            commit("setShow2FADialog", payload.showDialog);
            return new Promise(resolve => {
                commit("setResolve2FADialog", resolve);
            })
        }
    }
});

export default authModule;
