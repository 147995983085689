import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component
export default class ProjectTab extends Vue {
  employeListHeaders = [
    { text: this.$t("contractor.title"), align: "start", value: "name" },
    { text: this.$t("contractor.supervisor"), align: "start", value: "name" },
    { text: this.$t("contractor.actions"), align: "right", value: "actions", width: "1%" }
  ];
}
