import Vue from "vue";
import { Component } from "vue-property-decorator";
import SafeLocationDialog from "./safe-location-dialog.vue";
import ConfirmDialog from "../../../../layouts/confirmDialog/confirmDialog.vue";
import SafeLocationRestModel from "../../../../models/rest/register/safeLocationRestModel";

@Component({
    components: {
        "safe-location-dialog": SafeLocationDialog,
        "confirm-dialog": ConfirmDialog,
    }
})
export default class SafeLocationList extends Vue {
    get items(): SafeLocationRestModel[] {
        return this.$store.direct.state.register.safeLocationList;
    }

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    itemsHeaders = [
        {
            text: this.$t("contractor.id"),
            align: "center",
            value: "id",
            sortable: false
        },
        {
            text: this.$t("common.safeLocationName"),
            align: "center",
            value: "name",
            sortable: false
        },
        {
            text: this.$t("common.safeLocationIpAddress"),
            align: "center",
            value: "ipAddress",
            sortable: false
        },
        {
            text: this.$t("common.edit"),
            align: "center",
            value: "edit",
            width: "1%",
            sortable: false
        },
        {
            text: this.$t("common.delete"),
            align: "center",
            value: "delete",
            width: "1%",
            sortable: false
        }
    ];

    pagerOptions = {
        page: 1,
        itemsPerPage: 10
    };

    mounted() {
        this.$store.direct.dispatch.register.loadSafeLocationList();
    }

    itemCreateClick() {
        this.$store.direct.dispatch.register.showSafeLocationDialog({} as SafeLocationRestModel);
    }

    itemEditClick(item: SafeLocationRestModel) {
        this.$store.direct.dispatch.register.showSafeLocationDialog(item);
    }

    itemDeleteClick(item: SafeLocationRestModel) {
        this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: "Delete safe location",
                message: "Are you sure you want to delete this safe location?: " + item.name,
                links: [],
                options: { buttonColor: "error", layoutColor: "red" },
                buttonType: "yes/no"
            })
            .then(confirm => {
                if (confirm) {
                    this.$store.direct.dispatch.register.deleteSafeLocation(item);
                }
            })
    }
}
