import Vue from "vue";
import { Component } from "vue-property-decorator";
import ConfirmDialog from "../../../../layouts/confirmDialog/confirmDialog.vue";
import ListItemNumberModel from "../../../../models/listItemNumberModel";
import TagDialog from "./tag-dialog.vue";

@Component({
    components: {
        "tag-dialog": TagDialog,
        "confirm-dialog": ConfirmDialog,
    }
})
export default class TagList extends Vue {
    get items(): ListItemNumberModel[] {
        return this.$store.direct.state.register.tags;
    }

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    itemsHeaders = [
        {
            text: "Id",
            value: "value"
        },
        {
            text: this.$t("common.name"),
            value: "text"
        },
        {
            text: this.$t("common.edit"),
            align: "center",
            value: "edit",
            width: "1%",
            sortable: false
        },
        {
            text: this.$t("common.delete"),
            align: "center",
            value: "delete",
            width: "1%",
            sortable: false
        }
    ];

    pagerOptions = {
        page: 1,
        itemsPerPage: 10
    };

    mounted() {
        this.$store.direct.dispatch.register.loadTags();
    }

    itemCreateClick() {
        this.$store.direct.dispatch.register.showTagDialog({} as ListItemNumberModel);
    }

    itemEditClick(item: ListItemNumberModel) {
        this.$store.direct.dispatch.register.showTagDialog(item);
    }

    itemDeleteClick(item: ListItemNumberModel) {
        this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: "Delete tag",
                message: "Are you sure you want to delete this tag?: " + item.value + " - " + item.text,
                links: [],
                options: { buttonColor: "error", layoutColor: "red" },
                buttonType: "yes/no"
            })
            .then(confirm => {
                if (confirm) {
                    this.$store.direct.dispatch.register.deleteTag(item);
                }
            })
    }
}
