import { render, staticRenderFns } from "./multiple-select.vue?vue&type=template&id=56c5e1d2&scoped=true"
import script from "./multiple-select.ts?vue&type=script&lang=js&external"
export * from "./multiple-select.ts?vue&type=script&lang=js&external"
import style0 from "./multiple-select.vue?vue&type=style&index=0&id=56c5e1d2&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56c5e1d2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VCheckbox,VChip,VDivider,VListItem,VListItemContent,VListItemTitle,VSelect})
