import Vue from "vue";
import { Component } from "vue-property-decorator";
import router from "../../../router";

@Component
export default class Code2FADialog extends Vue {

    get show2FADialog(): boolean {
        return this.$store.direct.state.auth.show2FADialog;
    }

    get code2FA(): string {
        return this.$store.direct.state.auth.code2FA;
    }

    set code2FA(val: string) {
        this.$store.direct.commit.auth.setCode2FA(val);
    }

    created() {
        window.addEventListener('keydown', (e) => {
            if (e.key == 'Enter') {
                this.yesClick();
            };
        });
    }

    async yesClick() {
        if (this.show2FADialog == true) {
            await this.$store.direct.dispatch.auth.isConfirmed2FADialog().then(async () => {
                await this.$store.direct.dispatch.auth.verify2FA({ code: this.code2FA }).then(() => {
                    router.push({ path: "/dashboards" });
                })
            });
        }
    }

    async noClick() {
        await this.$store.direct.dispatch.auth.isNotConfirmed2FADialog();
    }
}
