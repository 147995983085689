import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import SupervisorEncoryIntegrationRestModel from "../../../models/rest/supervisor/supervisorEncoryIntegrationRestModel";

@Component
export default class EncoryIntegrationDialog extends Vue {
    integrations: SupervisorEncoryIntegrationRestModel[] = [];

    validation = {
        loadCarrierNumber: [
            (value: string) => !!value || this.$t("common.validationErrorRequired")
        ],
    }

    get encoryIntegrationStates(): any[] {
        return this.$store.direct.state.supervisorEncory.encoryIntegrationStates;
    }

    get showDialog(): boolean {
        return this.$store.direct.state.supervisorEncory.showEncoryIntegrationDialog;
    }

    get title(): string {
        return this.$store.direct.state.supervisorEncory.encoryIntegrationDialogTitle;
    }

    get supervisorEncoryIntegrations(): SupervisorEncoryIntegrationRestModel[] {
        return this.$store.direct.state.supervisorEncory.supervisorEncoryIntegrations;
    }

    set supervisorEncoryIntegrations(value: SupervisorEncoryIntegrationRestModel[]) {
        this.supervisorEncoryIntegrations = value;
    }

    @Watch("supervisorEncoryIntegrations", { deep: true })
    supervisorEncoryIntegrationsHandles() {
        this.integrations = this.supervisorEncoryIntegrations;
    }

    addNewSupervisorEncoryIntegration() {
        this.supervisorEncoryIntegrations.push({} as SupervisorEncoryIntegrationRestModel);
    }

    deleteSupervisorEncoryIntegration(index: number) {
        this.supervisorEncoryIntegrations.splice(index, 1);
    }

    cancelClick() {
        this.$store.direct.dispatch.supervisorEncory.isNotConfirmedEncoryIntegrationDialog();
    }

    async saveClick() {
        const valid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
        if (valid) {
            await this.$store.direct.dispatch.supervisorEncory.isConfirmedEncoryIntegrationDialog();
            this.$store.direct.commit.supervisorEncory.setSupervisorEncoryIntegrations(this.supervisorEncoryIntegrations);
        }
    }
}
