import { moduleActionContext } from "@/store/index";
import { AxiosError, AxiosResponse } from "axios";
import { defineModule } from "direct-vuex";
import { saveFile } from "../../assets/file-saver";
import ContractorSearchItemModel from "../../models/rest/contractor/contractorSearchItemModel";
import CurrencyRestModel from "../../models/rest/register/currencyRestModel";
import SettlementCostDocumentPaymentObligationListItemRestModel from "../../models/rest/settlement/cost-document/payment-obligation/settlementCostDocumentPaymentObligationListItemRestModel";
import SettlementCostDocumentPaymentObligationListRequestModel from "../../models/rest/settlement/cost-document/payment-obligation/settlementCostDocumentPaymentObligationListRequestModel";
import SettlementAmountSummaryRestModel from "../../models/rest/settlement/settlementAmountSummaryRestModel";
import { axiosInstance } from "../../plugins/axios";

export interface SettlementCostDocumentPaymentObligationModuleStateInterface {
    paymentObligationList: SettlementCostDocumentPaymentObligationListItemRestModel[],
    paymentObligationListLoading: boolean;
    paymentObligationListTotalCount: number,
    paymentObligationListAmountSummaryList: SettlementAmountSummaryRestModel[],
    paymentObligationListSearchText: string,
    paymentObligationListSearchBranchId: number,
    paymentObligationListSearchDocumentStatusIdcs: number[],
    paymentObligationListSearchCurrency: CurrencyRestModel,
    paymentObligationListSearchContractors: ContractorSearchItemModel[],
    paymentObligationListSearchContractorIdcs: number[],
    paymentObligationListSearchPaymentTermDateStart: string,
    paymentObligationListSearchPaymentTermDateEnd: string,
    paymentObligationListSearchOnlyExpired: boolean,
    paymentObligationListSearchExpiredDaysSince: string,
    paymentObligationListSearchExpiredDaysTo: string,
    paymentObligationListPagerOptions: any,
    selectedPaymentObligationList: SettlementCostDocumentPaymentObligationListItemRestModel[],
}

const settlementCostDocumentPaymentObligationModule = defineModule({
    namespaced: true,
    state: {
        paymentObligationList: [] as SettlementCostDocumentPaymentObligationListItemRestModel[],
        paymentObligationListLoading: false,
        paymentObligationListTotalCount: 0,
        paymentObligationListAmountSummaryList: [] as SettlementAmountSummaryRestModel[],
        paymentObligationListSearchText: "",
        paymentObligationListSearchBranchId: 0,
        paymentObligationListSearchDocumentStatusIdcs: [] as number[],
        paymentObligationListSearchCurrency: {} as CurrencyRestModel,
        paymentObligationListSearchContractors: [] as ContractorSearchItemModel[],
        paymentObligationListSearchContractorIdcs: [] as number[],
        paymentObligationListSearchPaymentTermDateStart: "",
        paymentObligationListSearchPaymentTermDateEnd: "",
        paymentObligationListSearchOnlyExpired: false,
        paymentObligationListSearchExpiredDaysSince: "",
        paymentObligationListSearchExpiredDaysTo: "",
        paymentObligationListPagerOptions: {
            page: 1,
            itemsPerPage: 30,
            sortBy: ["paymentTermDate"],
            sortDesc: [false]
        },
        selectedPaymentObligationList: [] as SettlementCostDocumentPaymentObligationListItemRestModel[],
    } as SettlementCostDocumentPaymentObligationModuleStateInterface,
    mutations: {
        setPaymentObligationList(state, value: SettlementCostDocumentPaymentObligationListItemRestModel[]) {
            state.paymentObligationList = value;
        },
        setPaymentObligationListLoading(state, value: boolean) {
            state.paymentObligationListLoading = value;
        },
        setPaymentObligationListTotalCount(state, value: number) {
            state.paymentObligationListTotalCount = value;
            if ((state.paymentObligationListPagerOptions.page * state.paymentObligationListPagerOptions.itemsPerPage) >= (value + state.paymentObligationListPagerOptions.itemsPerPage)) {
                state.paymentObligationListPagerOptions.page = 1;
            }
        },
        setPaymentObligationListtAmountSummaryList(state, value: SettlementAmountSummaryRestModel[]) {
            state.paymentObligationListAmountSummaryList = value;
        },
        setPaymentObligationListSearchText(state, value: string) {
            state.paymentObligationListSearchText = value;
        },
        setPaymentObligationListSearchBranchId(state, value: number) {
            state.paymentObligationListSearchBranchId = value;
        },
        setPaymentObligationListSearchDocumentStatusIdcs(state, value: number[]) {
            state.paymentObligationListSearchDocumentStatusIdcs = value;
        },
        setPaymentObligationListSearchCurrency(state, value: CurrencyRestModel) {
            state.paymentObligationListSearchCurrency = value;
        },
        setPaymentObligationListSearchContractors(state, value: ContractorSearchItemModel[]) {
            state.paymentObligationListSearchContractors = value;
        },
        setPaymentObligationListSearchContractorIdcs(state, value: number[]) {
            state.paymentObligationListSearchContractorIdcs = value;
        },
        setPaymentObligationListSearchPaymentTermDateStart(state, value: string) {
            state.paymentObligationListSearchPaymentTermDateStart = value;
        },
        setPaymentObligationListSearchPaymentTermDateEnd(state, value: string) {
            state.paymentObligationListSearchPaymentTermDateEnd = value;
        },
        setPaymentObligationListSearchOnlyExpired(state, value: boolean) {
            state.paymentObligationListSearchOnlyExpired = value;
        },
        setPaymentObligationListSearchExpiredDaysSince(state, value: string) {
            state.paymentObligationListSearchExpiredDaysSince = value;
        },
        setPaymentObligationListSearchExpiredDaysTo(state, value: string) {
            state.paymentObligationListSearchExpiredDaysTo = value;
        },
        setPaymentObligationListPagerOptions(state, value) {
            state.paymentObligationListPagerOptions = value;
        },
        setSelectedPaymentObligationList(state, value: SettlementCostDocumentPaymentObligationListItemRestModel[]) {
            state.selectedPaymentObligationList = value;
        },
    },
    actions: {
        loadPaymentObligationList(context, payload: { requestModel: SettlementCostDocumentPaymentObligationListRequestModel }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                commit.setPaymentObligationListLoading(true);
                axiosInstance
                    .post("/api/settlementCostDocument/cost-payment-obligation/list", payload.requestModel)
                    .then((resp: AxiosResponse) => {
                        commit.setPaymentObligationList(resp.data.items);
                        commit.setPaymentObligationListTotalCount(resp.data.totalItemsCount);
                        commit.setPaymentObligationListtAmountSummaryList(resp.data.amountSummaryList);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setPaymentObligationListLoading(false);
                    })
            })
        },
        downloadXlsReportSettlementCostDocumentPaymentObligationList(context,
            payload: { requestModel: SettlementCostDocumentPaymentObligationListRequestModel }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((reject) => {
                commit.setPaymentObligationListLoading(true);
                axiosInstance
                    .post("/api/settlementCostDocument/cost-payment-obligation/export", payload.requestModel, {
                        responseType: "blob"
                    })
                    .then((resp: AxiosResponse) => {
                        saveFile(resp);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setPaymentObligationListLoading(false);
                    })
            });
        },
    }
});

export default settlementCostDocumentPaymentObligationModule;
const _moduleActionContext = (context: any) => moduleActionContext(context, settlementCostDocumentPaymentObligationModule)