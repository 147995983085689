import { RouteConfig } from "vue-router";
import { Roles } from "@/models/emums/roles";

const routes: Array<RouteConfig> = [
    {
        path: "/forum",
        component: require("@/layouts/layout.vue").default,
        children: [
            {
                path: "topicList",
                name: "Forum topic list",
                component: require("@/components/forum/topicList.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.Disponent, Roles.DisponentAdmin]
                }
            },
            {
                path: "topic/:id",
                name: "Show Topic",
                component: require("@/components/forum/postList.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.Disponent, Roles.DisponentAdmin]
                }
            }
        ]
    }
];

export default routes;
