import Vue from "vue";
import { Component } from "vue-property-decorator";
import ListItemLinkModel from "../../models/listItemLinkModel";

@Component
export default class ConfirmDialog extends Vue {
    get showConfirmDialog(): boolean {
        return this.$store.direct.state.confirm.showConfirmDialog;
    }

    get titleText(): string {
        return this.$store.direct.state.confirm.titleConfirmDialog;
    }

    get showYesNo(): boolean {
        return this.$store.direct.state.confirm.buttonType == "yes/no";
    }

    get showOk(): boolean {
        return this.$store.direct.state.confirm.buttonType == "ok";
    }

    get messageText(): string {
        return this.$store.direct.state.confirm.messageConfirmDialog;
    }

    get linkList(): ListItemLinkModel[] {
        return this.$store.direct.state.confirm.linkListConfirmDialog;
    }

    get buttonColor(): string {
        return this.$store.direct.state.confirm.optionsConfirmDialog.buttonColor;
    }

    get layoutColor(): string {
        return this.$store.direct.state.confirm.optionsConfirmDialog.layoutColor;
    }

    created() {
        window.addEventListener('keydown', (e) => {
            if (e.key == 'Enter') {
                this.yesClick();
            };
        });
    }

    async yesClick() {
        if (this.showConfirmDialog == true) {
            await this.$store.direct.dispatch.confirm.isConfirmedConfirmDialog({
                isConfirmed: true
            });
        }
    }

    async noClick() {
        await this.$store.direct.dispatch.confirm.isNotConfirmedConfirmDialog();
    }

    async downloadDocument(value: number) {
        await this.$store.direct.dispatch.contractor.downloadDocument({ id: value });
    }
}
