import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { Roles } from "../../models/emums/roles";
import ListItemNumberModel from "../../models/listItemNumberModel";
import ContractorListRequestModel from "../../models/rest/contractor/contractorListRequestModel";
import ContractorRestModel from "../../models/rest/contractor/contractorRestModel";
import CountryRestModel from "../../models/rest/register/countryRestModel";

@Component
export default class ContractorList extends Vue {
    searchCity = "";
    searchPostalCode = "";
    contractorStatusLabel = this.$t("contractor.status");
    contractorTypeLabel = this.$t("contractor.type");
    documentCountryLabel = this.$t("contractor.country");
    accountManagersLabel = this.$t("contractor.accountManagers");

    get pagerOptions(): any {
        return this.$store.direct.state.contractor.contractorListPagerOptions;
    }

    set pagerOptions(value: any) {
        this.$store.direct.commit.contractor.setContractorListPagerOptions(value);
    }

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get searchText(): string {
        return this.$store.direct.state.contractor.contractorListSearchText;
    }

    set searchText(value: string) {
        this.$store.direct.commit.contractor.setContractorListSearchText(value);
    }

    get searchContractorId(): number | undefined {
        return this.$store.direct.state.contractor.contractorListSearchId;
    }

    set searchContractorId(value: number | undefined) {
        if (value?.toString().length == 0) {
            value = undefined;
        }
        this.$store.direct.commit.contractor.setContractorListSearchId(value);
    }

    get searchContractorTypeList(): any {
        return this.$store.direct.state.contractor.contractorListSearchContractorTypeList;
    }

    set searchContractorTypeList(value: any) {
        this.$store.direct.commit.contractor.setContractorListSearchContractorTypeList(value);
    }

    get searchContractorStatusList(): any {
        return this.$store.direct.state.contractor.contractorListSearchContractorStatusList;
    }

    set searchContractorStatusList(value: any) {
        this.$store.direct.commit.contractor.setContractorListSearchContractorStatusList(value);
    }

    get searchAccountManagerIdList(): any {
        return this.$store.direct.state.contractor.contractorListSearchAccountManagerIdList;
    }

    set searchAccountManagerIdList(value: any) {
        this.$store.direct.commit.contractor.setContractorListSearchAccountManagerIdList(value);
    }

    get selectedCountryCodes(): string[] {
        return this.$store.direct.state.contractor.contractorListSearchCountryCode;
    }

    set selectedCountryCodes(value: string[]) {
        this.$store.direct.commit.contractor.setContractorListSearchCountryCode(value);
    }

    get contractorList(): ContractorRestModel[] {
        return this.$store.direct.state.contractor.contractorList;
    }

    get contractorListLoading(): boolean {
        return this.$store.direct.state.contractor.contractorListLoading;
    }

    get contractorListTotalCount(): number {
        return this.$store.direct.state.contractor.contractorListTotalCount;
    }

    get contractorAssets() {
        return this.$store.direct.state.contractor.contractorAssets;
    }

    get userListSorted(): any {
        return this.contractorAssets.crmUsers?.sort((prev, next) => (prev.text > next.text ? 1 : -1));
    }

    get contractorTypeList(): any[] {
        return this.$store.direct.state.contractor.contractorTypeList;
    }

    get countryList(): CountryRestModel[] {
        return this.$store.direct.state.contractor.contractorAssets.countries;
    }

    get currentUserRoles(): string[] {
        return this.$store.direct.state.auth.userRoles;
    }

    get userIsAdminAccountantAdminOrAccountant(): boolean {
        if (this.currentUserRoles.includes(Roles.Admin) || this.currentUserRoles.includes(Roles.AccountantAdmin) || this.currentUserRoles.includes(Roles.Accountant)) {
            return true;
        }
        return false;
    }

    get contractorStatusList(): ListItemNumberModel[] {
        return this.$store.direct.state.contractor.contractorStatusList;
    }

    get showContractorTagsColumn() {
        if ([Roles.AccountantAdmin, Roles.Accountant].some(x => this.currentUserRoles.includes(x))) {
            return false;
        }
        return true;
    }

    get contractorListComputedHeaders(): any[] {
        return this.contractorListHeaders.filter(x => x.visible == true);
    }

    contractorListHeaders = [
        {
            text: this.$t("contractor.id"),
            align: "start",
            value: "id",
            width: "4%",
            visible: true
        },
        {
            text: this.$t("contractor.name"),
            align: "start",
            value: "name",
            visible: true
        },
        {
            text: this.$t("contractor.symbol"),
            align: "start",
            value: "symbol",
            visible: true
        },
        {
            text: this.$t("contractor.tin"),
            align: "start",
            value: "contractorTinWithPrefix",
            sortable: false,
            visible: true
        },
        {
            text: this.$t("contractor.postalCode"),
            align: "start",
            value: "postalCode",
            sortable: false,
            visible: true
        },
        {
            text: this.$t("contractor.city"),
            align: "start",
            value: "city",
            sortable: false,
            visible: true
        },
        {
            text: this.$t("contractor.country"),
            align: "start",
            value: "countryCode",
            sortable: false,
            visible: true
        },
        {
            text: this.$t("contractor.address"),
            align: "start",
            value: "address",
            sortable: false,
            visible: true
        },
        {
            text: this.$t("contractor.accountManagers"),
            align: "start",
            value: "accountManagers",
            sortable: false,
            visible: true
        },
        {
            text: this.$t("contractor.tags"),
            align: "start",
            value: "tags",
            sortable: false,
            visible: this.showContractorTagsColumn
        },
        {
            text: this.$t("contractor.editedAt"),
            align: "start",
            value: "editedAt",
            visible: true
        },
        {
            text: this.$t("contractor.interlan"),
            align: "start",
            value: "interlanId",
            sortable: false,
            visible: true
        },
        {
            text: this.$t("contractor.actions"),
            align: "right",
            value: "actions",
            width: "1%",
            sortable: false,
            visible: true
        }
    ];

    @Watch("pagerOptions", { deep: true })
    pagerOptionsHandles() {
        this.loadContractorList();
    }

    created() {
        this.loadContractorList();
    }

    itemRowTextColor(item: ContractorRestModel) {
        let style = ""
        if (item.inactive) {
            style = "orange--text";
        }
        if (item.isLockedAsClient || item.isLockedAsCarrier) {
            style = "red--text";
        }
        return style;
    }

    searchClick() {
        this.loadContractorList();
    }

    contractorCreateClick() {
        this.$router.push({ path: "/contractor/create" });
    }

    contractorEditRowClick(contractor: ContractorRestModel) {
        if (window.getSelection()?.toString().length === 0) {
            this.$router.push({ path: `/contractor/edit/${contractor.id}` });
        }
    }

    contractorEditClick(contractor: ContractorRestModel, isContractorInHistoricalState: boolean) {
        if (isContractorInHistoricalState) {
            return `/contractor/edit/${contractor.id}/history`;
        }
        else {
            return `/contractor/edit/${contractor.id}`;
        }
    }

    openLocationsMap() {
        return "/contractor/locations-map";
    }

    async loadContractorList() {
        this.pagerOptions.sortBy = this.pagerOptions.sortBy.length > 0 ? this.pagerOptions.sortBy : ["name"];
        this.pagerOptions.sortDesc = this.pagerOptions.sortDesc.length > 0 ? this.pagerOptions.sortDesc : [false];

        await this.$store.direct.dispatch.contractor.loadContractorList({
            requestModel: {
                page: this.pagerOptions.page,
                itemsPerPage: this.pagerOptions.itemsPerPage,
                sortBy: this.pagerOptions.sortBy,
                sortDesc: this.pagerOptions.sortDesc,
                searchText: this.searchText,
                searchContractorId: this.searchContractorId,
                searchContractorTypeList: this.searchContractorTypeList,
                searchAccountManagerIdList: this.searchAccountManagerIdList,
                searchCountryCodes: this.selectedCountryCodes,
                searchCity: this.searchCity,
                searchPostalCode: this.searchPostalCode,
                searchContractorStatusList: this.searchContractorStatusList,
            } as ContractorListRequestModel
        });
    }
}
