import Vue from "vue";
import { Component } from "vue-property-decorator";
import { stringMaxLength } from "../../../../assets/validation/common";
import CountryRestModel from "../../../../models/rest/register/countryRestModel";

@Component
export default class CountryDialog extends Vue {
    validation = {
        code: [stringMaxLength(this.$i18n, this.$t("common.code"), 2), (value: string) => !!value || this.$t("common.validationErrorRequired")],
        englishName: [stringMaxLength(this.$i18n, this.$t("common.englishName"), 128)],
        polishName: [stringMaxLength(this.$i18n, this.$t("common.polishName"), 128)],
        localName: [stringMaxLength(this.$i18n, this.$t("common.localName"), 128)],
    };

    get showDialog(): boolean {
        return this.$store.direct.state.register.showCountryDialog;
    }

    get model(): CountryRestModel {
        return this.$store.direct.state.register.country;
    }

    async saveClick() {
        const valid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
        if (valid) {
            await this.$store.direct.dispatch.register.updateCountry();
        }
    }

    async backClick() {
        await this.$store.direct.dispatch.register.closeCountryDialog();
    }
}
