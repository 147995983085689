import Vue from "vue";
import { Component } from "vue-property-decorator";
import ContractorPolicyRestModel from "../../../models/rest/contractor/contractorPolicyRestModel";

@Component
export default class PolicyTab extends Vue {
    policyListHeaders = [
        {
            text: this.$t("contractor.type"),
            align: "start",
            value: "type",
            sortable: false
        },
        {
            text: this.$t("contractor.policyRegion"),
            align: "start",
            value: "region",
            sortable: false
        },
        {
            text: this.$t("contractor.insurer"),
            align: "start",
            value: "policyIssuerName",
            sortable: false
        },
        {
            text: this.$t("contractor.value"),
            align: "start",
            value: "value",
            sortable: false
        },
        {
            text: this.$t("contractor.currency"),
            align: "start",
            value: "valueCurrency",
            sortable: false
        },
        {
            text: this.$t("contractor.expiration"),
            align: "start",
            value: "policyTerm",
            sortable: false
        },
        {
            text: this.$t("contractor.actions"),
            align: "right",
            value: "actions",
            width: "1%",
            sortable: false
        }
    ];

    get policyList(): ContractorPolicyRestModel[] {
        return this.$store.direct.state.contractor.policyList;
    }

    set policyList(val: ContractorPolicyRestModel[]) {
        this.$store.direct.commit.contractor.setPolicyList(val);
    }

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get allowContractorEdit(): boolean {
        return this.$store.direct.state.contractor.allowContractorEdit;
    }

    get isContractorNew(): boolean {
        return this.$store.direct.state.contractor.isContractorNew;
    }

    async policyCreateClick() {
        if (this.isContractorNew == false) {
            await this.$store.direct.dispatch.contractor.loadPolicy();
            this.$store.direct.commit.contractor.setShowPolicyDialog(true);
        }
        else {
            this.$store.direct.dispatch.confirm.openConfirmDialog(
                {
                    showConfirmDialog: true,
                    title: this.$t("contractor.savingContractorIsRequired").toString(),
                    message: this.$t("contractor.saveContractorIsRequiredForThisAction").toString(),
                    links: [],
                    options: { buttonColor: "error", layoutColor: "error" },
                    buttonType: "ok"
                });
        }
    }

    policyEditClick(item: ContractorPolicyRestModel) {
        this.$store.direct.dispatch.contractor.loadPolicy(item);
        this.$store.direct.commit.contractor.setShowPolicyDialog(true);
    }

    destroyed() {
        this.policyList = [] as ContractorPolicyRestModel[];
    }
}
