import dayjs from "dayjs";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import ListItemNumberModel from "../../../models/listItemNumberModel";
import SettlementClosedMonthRestModel from "../../../models/rest/settlement/settlementClosedMonthRestModel";

@Component({})

export default class ClosedMonthDialog extends Vue {
    isEdited = false;
    rules = {
        required: [(value: any) => !!value || this.$t("common.validationErrorRequired")],
    }

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get showDialog(): boolean {
        return this.$store.direct.state.settlementCommon.showClosedMonthDialog;
    }

    get title(): string {
        return this.$store.direct.state.settlementCommon.closedMonthDialogTitle;
    }

    get closedMonth(): SettlementClosedMonthRestModel {
        const closedMonth = this.$store.direct.state.settlementCommon.closedMonth;
        this.isEdited = closedMonth.id == undefined ? false : true;

        if (Object.keys(closedMonth).length == 0) {
            closedMonth.monthAndYear = dayjs().format("YYYY-MM");
        }

        if (closedMonth.isClosed == undefined) {
            closedMonth.isClosed = true;
        }

        return closedMonth;
    }

    set closedMonth(val: SettlementClosedMonthRestModel) {
        this.$store.direct.commit.settlementCommon.setClosedMonth(val);
    }

    get branchList(): ListItemNumberModel[] {
        const branches = this.$store.direct.state.contractor.contractorAssets.branches;
        if (branches == undefined) {
            this.$store.direct.dispatch.contractor.loadContractorAssets();
        }
        return branches;
    }

    async cancelClick() {
        await this.$store.direct.dispatch.settlementCommon.isNotConfirmedClosedMonthDialog();
        this.closedMonth = {} as SettlementClosedMonthRestModel;
    }

    async saveClick() {
        const valid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
        if (valid) {
            await this.$store.direct.dispatch.settlementCommon.saveClosedMonth();
            await this.$store.direct.dispatch.settlementCommon.isConfirmedClosedMonthDialog();
            this.closedMonth = {} as SettlementClosedMonthRestModel;
        }
    }

    destroyed() {
        this.closedMonth = {} as SettlementClosedMonthRestModel;
    }
}
