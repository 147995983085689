import dayjs from "dayjs";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { stringMaxLength } from "../../../assets/validation/common";
import { ContractorTypes } from "../../../models/emums/contractorTypes";
import ListItemNumberModel from "../../../models/listItemNumberModel";
import ListItemStringModel from "../../../models/listItemStringModel";
import ContractorPolicyRestModel from "../../../models/rest/contractor/contractorPolicyRestModel";
import ContractorRestModel from "../../../models/rest/contractor/contractorRestModel";
import CountryRestModel from "../../../models/rest/register/countryRestModel";

@Component
export default class ContractorDialog extends Vue {
    policyTermLabel = this.$t("contractor.expiration");

    validation = {
        symbol: [
            stringMaxLength(this.$i18n, this.$t("contractor.symbol"), 30),
            (value: string) => !!value || this.$t("common.validationErrorRequired")
        ],
        name: [
            stringMaxLength(this.$i18n, this.$t("contractor.name"), 254),
            (value: string) => !!value || this.$t("common.validationErrorRequired")
        ],
        streetAndNumber: [
            stringMaxLength(this.$i18n, this.$t("contractor.streetAndNumber"), 254),
            (value: string) => !!value || this.$t("common.validationErrorRequired")
        ],
        country: [
            stringMaxLength(this.$i18n, this.$t("contractor.country"), 40),
            (value: string) => !!value || this.$t("common.validationErrorRequired")
        ],
        postalCode: [
            stringMaxLength(this.$i18n, this.$t("contractor.postalCode"), 10),
            (value: string) => !!value || this.$t("common.validationErrorRequired")
        ],
        city: [
            stringMaxLength(this.$i18n, this.$t("contractor.city"), 100),
            (value: string) => !!value || this.$t("common.validationErrorRequired")
        ],
        email: [
            stringMaxLength(this.$i18n, this.$t("contractor.email"), 95)
        ],
        specialInfo: [
            stringMaxLength(this.$i18n, this.$t("contractor.specialInfo"), 2048)
        ],
        tin: [
            (value: string) => !!value || this.$t("common.validationErrorRequired")
        ],
        policyRegion: [
            (value: string) => value != undefined || this.$t("common.validationErrorRequired")
        ],
        policyTerm: [
            (value: string) => !!value || this.$t("common.validationErrorRequired")
        ],
    }

    policyListHeaders = [
        {
            text: this.$t("contractor.type"),
            align: "start",
            value: "type",
            sortable: false
        },
        {
            text: this.$t("contractor.policyRegion"),
            align: "start",
            value: "region",
            sortable: false
        },
        {
            text: this.$t("contractor.insurer"),
            align: "start",
            value: "policyIssuerName",
            sortable: false
        },
        {
            text: this.$t("contractor.value"),
            align: "start",
            value: "value",
            sortable: false
        },
        {
            text: this.$t("contractor.currency"),
            align: "start",
            value: "valueCurrency",
            sortable: false
        },
        {
            text: this.$t("contractor.expiration"),
            align: "start",
            value: "policyTerm",
            sortable: false
        },
        {
            text: this.$t("contractor.actions"),
            align: "right",
            value: "actions",
            width: "1%",
            sortable: false
        }
    ];

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get showDialog(): boolean {
        return this.$store.direct.state.supervisor.showContractorDialog;
    }

    get title(): string {
        return this.$store.direct.state.supervisor.contractorDialogTitle;
    }

    get contractorTypes(): string[] {
        return this.$store.direct.state.supervisor.contractorDialogContractorTypes;
    }

    get contractorIsEdited(): boolean {
        return this.$store.direct.state.supervisor.contractorDialogContractorIsEdited;
    }

    get contractor(): ContractorRestModel {
        const contractor = this.$store.direct.state.contractor.contractor as ContractorRestModel;
        if (Object.keys(contractor).length > 0) {
            contractor.typeIsCarrier = this.contractorTypes.includes(ContractorTypes.Carrier) ? true : false;
            contractor.typeIsClient = this.contractorTypes.includes(ContractorTypes.Client) ? true : false;
            contractor.paymentTermPurchase = 60;
            contractor.paymentTermSale = 30;
            contractor.purchaseValueCurrency = "EUR";
            contractor.saleValueCurrency = "EUR";
        }
        return contractor;
    }

    set contractor(val: ContractorRestModel) {
        this.$store.direct.commit.contractor.setContractor(val);
    }

    get countryList(): CountryRestModel[] {
        return this.$store.direct.state.contractor.contractorAssets.countries;
    }

    get policy(): ContractorPolicyRestModel {
        const policy = this.$store.direct.state.contractor.policy;
        this.formatSingleDateTimeValue(policy.policyTerm);
        return policy;
    }

    get policyRegions(): ListItemNumberModel[] {
        return this.$store.direct.state.contractor.policyRegions;
    }

    get selectedPolicyRegions(): ListItemNumberModel[] {
        const result: ListItemNumberModel[] = [];
        const regionNational = this.policyRegions.find(x => x.value == 0);
        const regionInternational = this.policyRegions.find(x => x.value == 1);
        const regionCabotage = this.policyRegions.find(x => x.value == 2);
        if (this.policy.isNational && regionNational != undefined)
            result.push(regionNational);
        if (this.policy.isInternational && regionInternational != undefined)
            result.push(regionInternational);
        if (this.policy.isCabotage && regionCabotage != undefined)
            result.push(regionCabotage);
        return result;
    }

    set selectedPolicyRegions(value: ListItemNumberModel[]) {
        this.policy.isNational = value.find(x => x.value == 0) != undefined;
        this.policy.isInternational = value.find(x => x.value == 1) != undefined;
        this.policy.isCabotage = value.find(x => x.value == 2) != undefined;
    }

    get policyTypes(): ListItemStringModel[] {
        return this.$store.direct.state.contractor.policyTypes;
    }

    get showPolicyListTab(): boolean {
        if (this.contractorTypes.includes(ContractorTypes.Carrier) && this.contractorIsEdited) {
            return true;
        }
        return false;
    }

    get policyList(): ContractorPolicyRestModel[] {
        return this.$store.direct.state.contractor.policyList;
    }

    get showPolicyRegion(): boolean {
        if (this.contractorTypes.includes(ContractorTypes.Carrier)) {
            return true;
        }
        return false;
    }

    policyEditClick(item: ContractorPolicyRestModel) {
        this.$store.direct.dispatch.contractor.loadPolicy(item);
        this.$store.direct.commit.contractor.setShowPolicyDialog(true);
    }

    async policyCreateClick() {
        await this.$store.direct.dispatch.contractor.loadPolicy();
        this.$store.direct.commit.contractor.setShowPolicyDialog(true);
    }

    formatSingleDateTimeValue(time: any) {
        return time != null ? dayjs(time).format("YYYY-MM-DD HH:mm") : "";
    }

    fillContractorName(val: string) {
        this.contractor.name = val;
    }

    async checkIfContractorTinExists(buttonType: string): Promise<boolean> {
        let contractorTinExists = await this.$store.direct.dispatch.contractor.checkIfContractorTinExists({ contractorTin: this.contractor.tin, contractorId: this.contractor.id });

        const buttonTypeIsYesNo = buttonType == "yes/no";

        if (contractorTinExists) {
            await this.$store.direct.dispatch.confirm
                .openConfirmDialog({
                    showConfirmDialog: true,
                    title: this.$t("contractor.contractorWithThisTinAlreadyExists").toString(),
                    message: (buttonTypeIsYesNo ? this.$t("contractor.contractorWithThisTinAlreadyExistsAreYouSureToSetUpNewOne").toString() : this.$t("contractor.contractorWithThisTinAlreadyExists").toString()) + ": " + this.contractor.tin,
                    links: [],
                    options: { buttonColor: "error", layoutColor: "error" },
                    buttonType: buttonType
                })
                .then(confirm => {
                    if (confirm && buttonTypeIsYesNo) {
                        contractorTinExists = false;
                    }
                });
        }
        return contractorTinExists;
    }

    cancelClick() {
        this.$store.direct.dispatch.supervisor.isNotConfirmedContractorDialog();
    }

    async saveClick() {
        let contractorTinExists = false;
        if (!this.contractorIsEdited && this.contractor.typeIsCarrier) {
            contractorTinExists = await this.checkIfContractorTinExists("ok");
        }
        else if (this.contractor.typeIsClient) {
            contractorTinExists = await this.checkIfContractorTinExists("yes/no");
        }

        if (this.policy.value?.toString().length == 0) {
            this.policy.value = 0 as number;
        }

        const valid = (this.$refs.form as Vue & { validate: () => boolean }).validate();

        if (valid && !contractorTinExists) {
            await this.$store.direct.dispatch.supervisor.isConfirmedContractorDialog();
            await this.$store.direct.dispatch.contractor.saveContractor();
            if (this.showPolicyRegion && !this.showPolicyListTab) {
                await this.$store.direct.dispatch.contractor.savePolicy();
            }
        }
    }
}
