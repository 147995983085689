import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { Loader } from "@googlemaps/js-api-loader";
import { } from "google-maps";
import { MarkerManager } from "@googlemaps/markermanager"
import SupervisorTrackingRestModel from "../../models/rest/tracking/supervisorTrackingRestModel";
import TrackingRestModel from "../../models/rest/tracking/trackingRestModel";
import TrackingSupervisorPlaceRestModel from "../../models/rest/tracking/trackingSupervisorPlaceRestModel";
import { Roles } from "../../models/emums/roles";
import PlaceStateRestModel from "../../models/rest/tracking/placeStateRestModel";
import ListItemNumberModel from "../../models/listItemNumberModel";
import dayjs from "dayjs";
import { SupervisorPlaceStateEnum } from "../../models/emums/supervisor/supervisorPlaceStateEnum";
import { ContractorTypes } from "../../models/emums/contractorTypes";

@Component

export default class Tracking extends Vue {
    map: google.maps.Map | undefined;
    directionsService: google.maps.DirectionsService | undefined;
    directionsRenderer: google.maps.DirectionsRenderer[] = [];
    lineSymbol: {
        path: google.maps.SymbolPath
    } | undefined;
    trackingSupervisors: SupervisorTrackingRestModel[] = [];
    infoWindow: google.maps.InfoWindow | undefined;
    directionsIcons: google.maps.Marker[] = [];
    vehicleMarkers: any[] = [];
    vMarkers: any[] = [];
    marker: any = undefined;
    contractorSearch = "";
    disableSelectContractorField = true;

    styles: any = [
        {
            "stylers": [
                { "visibility": "on" }
            ]
        },
        {
            "featureType": "landscape.natural",
            "stylers": [
                { "visibility": "simplified" },
                { "color": "#f0f0f0" }
            ]
        },
        {
            "featureType": "water",
            "stylers": [
                { "visibility": "simplified" },
                { "color": "#C2E7F5" }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
                { "visibility": "simplified" },
                { "color": "#ffffff" }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "geometry.stroke",
            "stylers": [
                { "visibility": "off" }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "labels.icon",
            "stylers": [
                { "visibility": "off" }
            ]
        },
        {
            "elementType": "labels.text.fill",
            "stylers": [
                { "visibility": "on" },
                { "color": "#646464" }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "geometry.fill",
            "stylers": [
                { "visibility": "on" },
                { "weight": 1 },
                { "color": "#ffffff" }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "geometry.fill",
            "stylers": [
                { "lightness": 90 },
                { "color": "#d7d7d7" },
                { "visibility": "off" }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "geometry",
            "stylers": [
                { "visibility": "on" },
                { "color": "#ffffff" }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "labels.text.fill",
            "stylers": [
                { "visibility": "on" },
                { "color": "#b8b8b8" }
            ]
        },
        {
            "featureType": "landscape.man_made",
            "elementType": "geometry",
            "stylers": [
                { "visibility": "on" },
                { "lightness": 60 },
                { "saturation": -90 },
                { "gamma": 0.90 }
            ]
        }
    ];

    loader = new Loader({
        apiKey: this.googleMapsApiKey,
        libraries: ["places"]
    });

    placeStateListHeaders = [
        {
            text: this.$t("tracking.companyName"),
            align: "start",
            value: "name",
            sortable: false,
        },
        {
            text: this.$t("tracking.place"),
            align: "start",
            value: "address",
            sortable: false,
        },
        {
            text: this.$t("tracking.lastUpdate"),
            align: "start",
            value: "lastUpdate",
            sortable: false,
        },
        {
            text: this.$t("tracking.state"),
            align: "center",
            value: "stateId",
            sortable: false,
        },
    ]

    placeStateHistoryListHeaders = [
        { text: this.$t("supervisor.historyDate"), value: "lastModifiedAt", align: "start" },
        { text: this.$t("supervisor.historyState"), value: "stateId", align: "start" },
        { text: this.$t("supervisor.checkpointDescription"), value: "checkpointDescription", align: "start" },
    ];

    get pagerOptions(): any {
        return this.$store.direct.state.tracking.placeStateListPagerOptions;
    }

    set pagerOptions(val: any) {
        this.$store.direct.commit.tracking.setPlaceStateListPagerOptions(val);
    }

    get googleMapsApiKey(): string {
        return this.$store.direct.state.auth.googleMapsApiKey;
    }

    get serverSupervisorArray(): SupervisorTrackingRestModel[] {
        return this.$store.direct.state.tracking.supervisorTrackingVehicleList;
    }

    get currentUserRoles(): string[] {
        return this.$store.direct.state.auth.userRoles;
    }

    get placeStateListLoading(): boolean {
        return this.$store.direct.state.tracking.placeStateListLoading;
    }

    get placeStateListTotalCount(): number {
        return this.$store.direct.state.tracking.placeStateListTotalCount;
    }

    get placeStateList(): PlaceStateRestModel[] {
        return this.$store.direct.state.tracking.placeStateList;
    }

    get currentUserContractorId(): number {
        return this.$store.direct.state.auth.currentUserContractorId;
    }

    get currentUserContractorName(): string {
        return this.$store.direct.state.auth.currentUserContractorName;
    }

    get contractorFound() {
        return this.$store.direct.state.common.contractorSearch;
    }

    get selectedContractor() {
        let selectedContractor = this.$store.direct.state.tracking.selectedContractor;
        if (Object.keys(selectedContractor).length == 0) {
            selectedContractor = { text: this.currentUserContractorName, value: this.currentUserContractorId };
        }
        return selectedContractor;
    }

    set selectedContractor(val: ListItemNumberModel) {
        this.$store.direct.commit.tracking.setSelectedContractor(val);
    }

    @Watch("contractorSearch")
    contractorSearchLoadItems(val: string) {
        this.$store.direct.dispatch.common.searchContractors({ searchText: val, contractorTypes: [ContractorTypes.Client], contractorPropertiesEnabled: ["trackingEnabled"], source: "" });
    }

    @Watch("pagerOptions", { deep: true })
    pagerOptionsHandles() {
        this.loadPlaceStateList();
    }

    @Watch("serverSupervisorArray")
    async serverSupervisorArrayChanged() {
        this.setupMarkers();
    }

    getPlaceStateString(stateId: number): string {
        switch (stateId) {
            case SupervisorPlaceStateEnum["Transport commissioned"]: {
                return this.$t("tracking.transportCommissioned").toString();
            }
            case SupervisorPlaceStateEnum["At loading point"]: {
                return this.$t("tracking.atLoadingPoint").toString();
            }
            case SupervisorPlaceStateEnum.Loaded: {
                return this.$t("tracking.loaded").toString();
            }
            case SupervisorPlaceStateEnum["At unloading point"]: {
                return this.$t("tracking.atUnloadingPoint").toString();
            }
            case SupervisorPlaceStateEnum.Unloaded: {
                return this.$t("tracking.unloaded").toString();
            }
            case SupervisorPlaceStateEnum.Checkpoint: {
                return this.$t("tracking.checkpoint").toString();
            }
            default: {
                return "";
            }
        } 
    }

    getStateBackgroundColor(stateId: number): string {
        if (stateId == SupervisorPlaceStateEnum.Loaded) {
            return '#00CAA8';
        }
        else if (stateId == SupervisorPlaceStateEnum.Unloaded) {
            return '#CA0051';
        }
        else {
            return 'white';
        }
    }

    getStateTextColor(stateId: number): string {
        if (stateId == SupervisorPlaceStateEnum.Loaded || stateId == SupervisorPlaceStateEnum.Unloaded) {
            return 'white';
        }
        else {
            return '#05103B';
        }
    }

    getStateClass(stateId: number): string {
        if (stateId == SupervisorPlaceStateEnum.Loaded || stateId == SupervisorPlaceStateEnum.Unloaded) {
            return 'pt-1 pr-4 pb-1 pl-4 rounded-pill';
        }
        else {
            return 'pt-1 pr-4 pb-1 pl-4 place-state-div';
        }
    }

    itemIsLoadedOrUnloaded(stateId: number): boolean {
        if (stateId == SupervisorPlaceStateEnum.Loaded || stateId == SupervisorPlaceStateEnum.Unloaded) {
            return true;
        }
        return false;
    }

    showHistoryTable(item: PlaceStateRestModel): boolean {
        if (item.history != undefined && item.history.length > 0) {
            return true;
        }
        return false;
    }

    formatDateTime(time: any) {
        if (time != null) {
            return dayjs(time).format("YYYY-MM-DD HH:mm");
        }
    }

    checkIfUserContainsRightRole() {
        if (!this.currentUserRoles.includes(Roles.Admin) && !this.currentUserRoles.includes(Roles.Disponent) && !this.currentUserRoles.includes(Roles.DisponentAdmin)) {
            return this.disableSelectContractorField = true;
        }
        return this.disableSelectContractorField = false;
    }

    async loadPlaceStateList() {
        await this.$store.direct.dispatch.tracking.loadPlaceStateList({
            page: this.pagerOptions.page,
            itemsPerPage: this.pagerOptions.itemsPerPage,
            contractorId: this.selectedContractor.value
        });
    }

    async created() {
        this.selectedContractor = {} as ListItemNumberModel;
        this.checkIfUserContainsRightRole();
        await this.$store.direct.dispatch.tracking.loadSupervisorTrackingVehicleList();
        await this.loadMap();
        if (this.onlyForAdminAndDisponentRole() != true) {
            setInterval(async () => await this.$store.direct.dispatch.tracking.loadSupervisorTrackingVehicleList(), 60000)
        }
    }

    onlyForAdminAndDisponentRole() {
        if ([Roles.Admin, Roles.Disponent, Roles.DisponentAdmin].some(x => this.currentUserRoles.includes(x))) {
            return true
        }
        return false;
    }

    async loadMap() {
        await this.loader.load().then(async () => {
            this.map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
                center: {
                    lat: 50,
                    lng: 15
                },
                zoom: 5,
                scaleControl: true,
            });
            const styledMap = new google.maps.StyledMapType(this.styles, {
                name: "Styled Map"
            })
            this.map.mapTypes.set('map_style', styledMap);
            this.map.setMapTypeId('map_style');
            this.directionsService = new google.maps.DirectionsService();
            this.lineSymbol = { path: google.maps.SymbolPath.FORWARD_OPEN_ARROW }
            await this.setupMarkers();
        });
    }

    async setupMarkers() {
        if (this.onlyForAdminAndDisponentRole() == true) {
            for (let i = 0; i < this.serverSupervisorArray.length; ++i) {
                const serverSupervisor = this.serverSupervisorArray[i];
                serverSupervisor.helpLines = [];
                this.trackingSupervisors.push(serverSupervisor);

                for (let supervisorPartIndex = 0; supervisorPartIndex < serverSupervisor.trackingParts.length; ++supervisorPartIndex) {
                    const currentPart = serverSupervisor.trackingParts[supervisorPartIndex];

                    if (currentPart.isCurrentPlace) {
                        const pos = new google.maps.LatLng(currentPart.lat, currentPart.lng);
                        const title = serverSupervisor.customerOrderId + ' ' + serverSupervisor.registrationNumber;
                        const markerOptions =
                            {
                                title: title,
                                icon: {
                                    url: await this.getVehicleIcon(),
                                    size: new google.maps.Size(24, 24),
                                    origin: new google.maps.Point(0, 0),
                                    anchor: new google.maps.Point(12, 12)
                                },
                                position: pos,
                                map: this.map,
                                vehicle: serverSupervisor
                            } as any;
                        const marker = new google.maps.Marker(markerOptions);
                        google.maps.event.clearListeners(marker, 'click');
                        const that = this;
                        google.maps.event.addListener(marker, 'click', async (event) => {
                            await that.getVehicleData(marker);
                        });
                    }
                    if (supervisorPartIndex < serverSupervisor.trackingParts.length - 1) {
                        const startPart = currentPart;
                        const endPart = serverSupervisor.trackingParts[supervisorPartIndex + 1];
                        const mainLine = new google.maps.Polyline({
                            path: [{ lat: startPart.lat, lng: startPart.lng },
                            { lat: endPart.lat, lng: endPart.lng }],
                            icons: [{
                                icon: this.lineSymbol,
                                offset: '100%'
                            }],
                            map: this.map,
                            strokeColor: endPart.color,
                            strokeOpacity: 0.8,
                            strokeWeight: 1
                        });
                        const line = new google.maps.Polyline({
                            path: [{ lat: startPart.lat, lng: startPart.lng },
                            { lat: endPart.lat, lng: endPart.lng }],
                            map: this.map,
                            strokeColor: endPart.color,
                            strokeOpacity: 0.001,
                            strokeWeight: 15
                        });
                        const lineData = {
                            supervisorId: serverSupervisor.id,
                            serverSupervisor: serverSupervisor,
                        };
                        serverSupervisor.helpLines.push(line);
                        google.maps.event.clearListeners(line, 'click');
                        const that = this;
                        google.maps.event.addListener(line, 'click', (event) => {
                            //clean marked help lines
                            that.trackingSupervisors.forEach((trackingSupervisor: SupervisorTrackingRestModel) => {
                                trackingSupervisor.helpLines.forEach((helpLine: google.maps.Polyline) => {
                                    helpLine.setOptions({
                                        strokeOpacity: 0.001,
                                    });
                                });
                            });
                            //show current help lines
                            serverSupervisor.helpLines.forEach((helpLine: google.maps.Polyline) => {
                                helpLine.setOptions({ strokeOpacity: 0.1 });
                            });
                            that.getLineData(lineData, event);
                        });
                    }
                }
            }
        }
        else {
            //remove old markers
            this.vehicleMarkers.forEach((element, index, array) => {
                const vehicleExist = this.serverSupervisorArray.find((vehicle: SupervisorTrackingRestModel) => {
                    return vehicle.id === element.id;
                });
                if (vehicleExist == undefined) {
                    array.splice(index, 1);
                    element.marker.setMap(null);
                }
            });
            //updare markers
            for (let i = 0; i < this.serverSupervisorArray.length; ++i) {
                const currentVehicle = this.serverSupervisorArray[i];
                const vehicleExist = this.vehicleMarkers.find((vehicle: any) => {
                    return vehicle.id === currentVehicle.id;
                });
                if (vehicleExist !== undefined) {
                    vehicleExist.marker.setPosition(new google.maps.LatLng(currentVehicle.lat, currentVehicle.lng));
                }
                else {
                    const title = currentVehicle.customerOrderId + ' ' + currentVehicle.registrationNumber;
                    const pos = new google.maps.LatLng(currentVehicle.lat, currentVehicle.lng);
                    const markerOptions =
                        {
                            title: title,
                            icon: {
                                url: await this.getVehicleIcon(),
                                size: new google.maps.Size(24, 24),
                                origin: new google.maps.Point(0, 0),
                                anchor: new google.maps.Point(12, 12)
                            },
                            position: pos,
                            map: this.map,
                            vehicle: currentVehicle
                        } as any;
                    const marker = new google.maps.Marker(markerOptions);
                    this.marker = marker;
                    google.maps.event.clearListeners(marker, 'click');
                    const that = this;
                    google.maps.event.addListener(marker, 'click', function (event) {
                        that.directionsRenderer?.forEach((element, index, array) => {
                            element.setMap(null);
                        });
                        that.directionsRenderer = [];
                        that.directionsIcons.forEach(function (element, index, array) {
                            element.setMap(null);
                        });
                        that.directionsIcons = [];
                        that.getVehicleData(marker);
                    });
                    currentVehicle.marker = marker;
                    this.vehicleMarkers.push(currentVehicle);
                }
                this.vMarkers[i] = [];
                this.vMarkers[i][0] = this.marker as google.maps.Marker;
                this.vMarkers[i][1] = 0;
                this.vMarkers[i][2] = this.marker.getPosition().lat();
                this.vMarkers[i][3] = this.marker.getPosition().lng();

                for (let l = 0; l < i; l++) {
                    if ((this.vMarkers[l][2] == this.vMarkers[i][2]) && (this.vMarkers[l][3] == this.vMarkers[i][3])) {
                        this.vMarkers[i][1]++;
                    }
                }
            }
            new MarkerManager(this.map as google.maps.Map, this.vMarkers);
        }
    }


    get vehicleIcons(): any {
        return this.$store.direct.state.common.vehicleIcons;
    }

    async getVehicleIcon() {
        const vehicleTypeId = 1;
        const vehicleState = 1;
        const iconKey = vehicleTypeId + '_' + vehicleState;
        const icon = this.vehicleIcons[iconKey]

        if (icon == undefined) {
            await this.$store.direct.dispatch.common.loadVehicleIcon({ typeId: vehicleTypeId, state: vehicleState, key: iconKey });
        }
        return this.vehicleIcons[iconKey]
    }

    get trackingVehicleData(): TrackingRestModel {
        return this.$store.direct.state.tracking.trackingVehicleData;
    }

    async getVehicleData(marker: any) {
        const currentVehicle = marker.vehicle;
        await this.$store.direct.dispatch.tracking.loadTrackingVehicleData({ id: currentVehicle.id })
        this.showVehicleData(marker, currentVehicle);
    }

    showVehicleData(marker: any, vehicleData: SupervisorTrackingRestModel) {
        if (this.trackingVehicleData !== undefined) {
            if (this.trackingVehicleData.startPlaceAvalilable === true) {
                if (this.infoWindow !== undefined) {
                    google.maps.event.clearInstanceListeners(this.infoWindow);
                    this.infoWindow.close();
                    this.infoWindow = undefined;
                }
                this.infoWindow = new google.maps.InfoWindow({
                    content: '<div>' +
                        '<h2>' + vehicleData.registrationNumber + '</h2>' +
                        '<table>' +
                        `<tr><td style="padding-right: 8px;"><b>${this.$t('tracking.customerOrderNumber')}: </b></td><td><b>` + vehicleData.customerOrderId + '</b></td></tr>' +
                        `<tr><td style="padding-right: 8px;"><b>${this.$t('tracking.lastPosition')}: </b></td><td>` + this.trackingVehicleData.currentPlaceLastModifiedAt + '</td></tr>' +
                        '</table>' +
                        '</div>'
                });
                this.infoWindow.open(this.map, marker);

                const startDirectionsRequest = {
                    origin: new google.maps.LatLng(this.trackingVehicleData.startPlaceLat, this.trackingVehicleData.startPlaceLng),
                    destination: marker.position,
                    travelMode: google.maps.TravelMode.DRIVING,
                    unitSystem: google.maps.UnitSystem.METRIC
                };

                this.directionsService?.route(
                    startDirectionsRequest, (response: google.maps.DirectionsResult, status: google.maps.DirectionsStatus) => {
                        if (status === google.maps.DirectionsStatus.OK) {
                            this.directionsRenderer?.push(new google.maps.DirectionsRenderer({
                                map: this.map,
                                preserveViewport: true,
                                directions: response,
                                suppressMarkers: true,
                                polylineOptions: {
                                    strokeColor: "green"
                                }
                            } as google.maps.DirectionsRendererOptions));
                            const leg = response.routes[0].legs[0];
                            this.directionsIcons.push(new google.maps.Marker({
                                position: leg.start_location,
                                map: this.map,
                                icon: {
                                    url: 'http://maps.google.com/mapfiles/ms/micons/green.png',
                                    size: new google.maps.Size(44, 32),
                                    origin: new google.maps.Point(0, 0),
                                    anchor: new google.maps.Point(22, 32)
                                },
                                title: "start"
                            }));
                        }
                        else {
                            alert(this.$t('tracking.unableToRetriveRoute'));
                        }
                    })
            };
        }
        if (this.trackingVehicleData.endPlaceAvalilable === true) {
            const endDirectionsRequest = {
                origin: marker.position,
                destination: new google.maps.LatLng(this.trackingVehicleData.endPlaceLat, this.trackingVehicleData.endPlaceLng),
                travelMode: google.maps.TravelMode.DRIVING,
                unitSystem: google.maps.UnitSystem.METRIC
            };
            this.directionsService?.route(
                endDirectionsRequest, (response, status) => {
                    if (status === google.maps.DirectionsStatus.OK) {
                        this.directionsRenderer?.push(new google.maps.DirectionsRenderer({
                            map: this.map,
                            preserveViewport: true,
                            directions: response,
                            suppressMarkers: true,
                            polylineOptions: {
                                strokeColor: "blue"
                            }
                        } as google.maps.DirectionsRendererOptions));
                        const leg = response.routes[0].legs[0];

                        this.directionsIcons.push(new google.maps.Marker({
                            position: leg.end_location,
                            map: this.map,
                            icon: {
                                url: 'http://maps.google.com/mapfiles/ms/micons/blue.png',
                                size: new google.maps.Size(44, 32),
                                origin: new google.maps.Point(0, 0),
                                anchor: new google.maps.Point(22, 32)
                            },
                            title: "end"
                        }));
                        this.infoWindow?.setContent('<div>' + '<h2>' + vehicleData.registrationNumber + '</h2>' +
                            '<table>' +
                            `<tr><td style="padding-right: 8px;"><b>${this.$t('tracking.customerOrderNumber')}: </b></td><td>` + vehicleData.customerOrderId + '</td></tr>' +
                            `<tr><td style="padding-right: 8px;"><b>${this.$t('tracking.leftToDelivery')}: </b></td><td>` + response.routes[0].legs[0].distance.text + '</td></tr>' +
                            `<tr><td style="padding-right: 8px;"><b>${this.$t('tracking.googleEstimation')}: </b></td><td>` + response.routes[0].legs[0].duration.text + '</td></tr>' +
                            `<tr><td style="padding-right: 8px;"><b>${this.$t('tracking.lastPosition')}: </b></td><td>` + this.trackingVehicleData.currentPlaceMinutesAgo + '</td></tr>' +
                            '</table>' + '</div>');
                    } else {
                        alert(this.$t('tracking.unableToRetriveRoute'));
                    }
                });
        }
    }

    get trackingSupervisorData(): TrackingRestModel {
        return this.$store.direct.state.tracking.trackingSupervisorData;
    }

    async getLineData(line: any, event: any) {
        const supervisorId = line.supervisorId;
        await this.$store.direct.dispatch.tracking.loadTrackingSupervisorData({ id: supervisorId })
        this.showLineData(line, event);
    }

    showLineData(line: any, event: any) {
        if (this.trackingSupervisorData !== null) {
            if (this.trackingSupervisorData.startPlaceAvalilable === true) {
                if (this.infoWindow !== undefined) {
                    google.maps.event.clearInstanceListeners(this.infoWindow);
                    this.infoWindow?.close();
                    this.infoWindow = undefined;
                }
                let infoWindowContent = `<div>` +
                    `<h2><a target="_blank" rel="noopener noreferrer" href="` + this.prepareUrlForOpenSupervisor(line.supervisorId) + `">` + this.trackingSupervisorData.orderNumber + `</a></h2>` +
                    '<table>' +
                    `<tr><td style="padding-right: 8px;"><b>${this.$t('tracking.customerOrderNumber')}: </b></td><td>` + this.trackingSupervisorData.customerOrderNumber + '</td></tr>' +
                    `<tr><td style="padding-right: 8px;"><b>${this.$t('tracking.from')}: </b></td><td>` + this.trackingSupervisorData.transportFrom + '</td></tr>' +
                    `<tr><td style="padding-right: 8px;"><b>${this.$t('tracking.to')}: </b></td><td>` + this.trackingSupervisorData.transportTo + '</td></tr>';

                this.trackingSupervisorData.trackingSupervisorPlaces.forEach((trackingSupervisorPlace: TrackingSupervisorPlaceRestModel) => {
                    infoWindowContent += `<tr><td style="padding-right: 8px;"><b>` + ((trackingSupervisorPlace.type === 1) ? `${this.$t('tracking.load')}: </b></td><td>` : `${this.$t('tracking.unload')}: </b></td><td>`) + trackingSupervisorPlace.cargo + ` [` + trackingSupervisorPlace.time + `]</td></tr>`;
                });
                infoWindowContent += `<tr><td style="padding-right: 8px;"><b>${this.$t('tracking.lastPosition')}: </b></td><td>` + this.trackingSupervisorData.currentPlaceLastModifiedAt + '</td></tr>' + '</table>' + '</div>';
                this.infoWindow = new google.maps.InfoWindow({
                    content: infoWindowContent
                });
                this.infoWindow.open(this.map);
                this.infoWindow.setPosition(event.latLng);
            }
        }
    }

    prepareUrlForOpenSupervisor(id: number) {
        return window.location.origin + "/supervisor/edit/" + id;
    }
}
