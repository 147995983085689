import Vue from "vue";
import { Component } from "vue-property-decorator";
import ConfirmDialog from "../../../layouts/confirmDialog/confirmDialog.vue";
import OutstandingRegisterExportDialog from "./dialog/outstanding-register-export-dialog.vue";
import RegisterExportDialog from "./dialog/register-export-dialog.vue";

@Component({
    components: {
        "register-export-dialog": RegisterExportDialog,
        "outstanding-register-export-dialog": OutstandingRegisterExportDialog,
        "confirm-dialog": ConfirmDialog,
    }
})

export default class RegisterExportList extends Vue {
    registerExportListHeaders = [];

    get registerExportListLoading(): boolean {
        return this.$store.direct.state.settlementCommon.registerExportListLoading;
    }

    get outstandingRegisterExportListLoading(): boolean {
        return this.$store.direct.state.settlementCommon.outstandingRegisterExportListLoading;
    }

    get registerExportList(): any[] {
        return [];
    }

    get registerExportListTotalCount(): number {
        return 0;
    }

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get footerPropsForSettlement(): any {
        return this.$store.direct.state.settlementCommon.footerPropsForSettlement;
    }

    async createRegisterExportClick(registerType: string) {
        const titlePart = registerType == "sales" ? `${this.$t("registerExport.sale")}` : `${this.$t("registerExport.purchase")}`
        await this.$store.direct.dispatch.settlementCommon
            .openRegisterExportDialog({
                title: "Export: " + titlePart,
                registerType: registerType,
            });
    }

     createOutstandingRegisterExportClick() {
         this.$store.direct.dispatch.settlementCommon.openOutstandingRegisterExportDialog();
    }
}