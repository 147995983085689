import "@fortawesome/fontawesome-free/css/all.css";
import "font-awesome/css/font-awesome.min.css";
import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify";
import { VuetifyPreset } from "vuetify";

const vuetifyConfig = {
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: "#2F4969",
        secondary: "#81909e",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#6fb673", //"rgba(150, 254, 150, 0.4)", //"#4CAF50",
        warning: "#FFC107"
      }
    }
  },
  lang: {
    //t: (key, ...params) => i18n.t(key, params)
  },
  icons: {
    iconfont: "mdi"
  }
} as VuetifyPreset;

Vue.use(Vuetify);

export default new Vuetify(vuetifyConfig);
