import Vue from "vue";
import { Component } from "vue-property-decorator";
import ContractorContactPersonRestModel from "@/models/rest/contractor/contractorContactPersonRestModel";
import ListItemNumberModel from "@/models/listItemNumberModel";
import VForm from "../../../models/v-form";
import { stringMaxLength } from "@/assets/validation/common";

@Component
export default class ContactPersonDialog extends Vue {
    validation = {
        firstAndLastName: [stringMaxLength(this.$i18n, this.$t("contractor.employeName"), 75), (value: string) => !!value || this.$t("common.validationErrorRequired")],
        email: [stringMaxLength(this.$i18n, this.$t("contractor.email"), 150)],
        position: [stringMaxLength(this.$i18n, this.$t("contractor.telephone"), 150)],
        telephone: [stringMaxLength(this.$i18n, this.$t("contractor.telephone"), 150)],
        skype: [stringMaxLength(this.$i18n, this.$t("contractor.skype"), 150)],
        role:[(value: string) => !!value || this.$t("common.validationErrorRequired")]
    };

    updated() {
        this.$nextTick(function () {
            if (this.$refs.form != undefined) {
                (this.$refs.form as VForm).resetValidation();
            }
        });
    }

    get contactPerson(): ContractorContactPersonRestModel {
        return this.$store.direct.state.contractor.contactPerson;
    }

    set contactPerson(val: ContractorContactPersonRestModel) {
        this.$store.direct.commit.contractor.setContactPerson(val);
    }

    get roleListSorted(): ListItemNumberModel[] {
        return this.$store.direct.state.contractor.contractorAssets.personRoles;
    }

    get showDialog(): boolean {
        return this.$store.direct.state.contractor.showContactPersonDialog;
    }

    async saveClick(): Promise<void> {
        if ((this.$refs.form as VForm).validate()) {
            await this.$store.direct.dispatch.contractor.saveContactPerson();
            this.contactPerson = {} as ContractorContactPersonRestModel
        }
    }
    async closeClick(): Promise<void> {
        await this.$store.direct.dispatch.contractor.closeContactPersonDialog();
        this.contactPerson = {} as ContractorContactPersonRestModel
    }
}
