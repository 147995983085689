import { RouteConfig } from "vue-router";
import { Roles } from "../models/emums/roles";
import store from "../store";

const routes: Array<RouteConfig> = [
    {
        path: "/supervisor",
        component: require("@/layouts/layout.vue").default,
        children: [
            {
                path: "list/:code?",
                name: "Supervisor list",
                component: require("@/components/supervisor/supervisor-list.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.Disponent, Roles.DisponentAdmin, Roles.Accountant, Roles.AccountantAdmin]
                },
                beforeEnter: (async (to, from, next) => {
                    const transEuAuthCode = to.query.code;
                    const transEuAuthState = to.query.state;

                    if (transEuAuthCode != undefined && store.state.freightExchange.isAuthToTransEu == false) {
                        store.commit.freightExchange.setIsAuthToTransEu(true);
                        await store.dispatch.freightExchange.getTransEuToken({ transEuAuthCode: transEuAuthCode, transEuAuthState: transEuAuthState });
                    };

                    next();
                })
            },
            {
                path: "create/:oldSupervisorId?/:selectedPlacesIdcsArray?",
                name: "Create supervisor",
                component: require("@/components/supervisor/supervisor.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.Disponent, Roles.DisponentAdmin]
                },
                beforeEnter: (async (to, from, next) => {
                    const supervisorId = Number.parseInt(to.params.id);
                    const oldSupervisorId = to.query.oldSupervisorId as string | null;
                    const selectedPlacesIdcsArray = to.query.selectedPlacesIdcsArray as string;

                    await store.dispatch.supervisor.loadSupervisor({ id: supervisorId, oldSupervisorId: oldSupervisorId, selectedPlacesIdcsArray: selectedPlacesIdcsArray });
                    next();
                })
            },
            {
                path: "edit/:id/:isInHistoricalState?",
                name: "Edit supervisor",
                component: require("@/components/supervisor/supervisor.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.Disponent, Roles.DisponentAdmin, Roles.Accountant, Roles.AccountantAdmin]
                },
                beforeEnter: (async (to, from, next) => {
                    const supervisorId = Number.parseInt(to.params.id);
                    if (to.params.isInHistoricalState != undefined && to.params.isInHistoricalState != "undefined" && to.params.isInHistoricalState == "history") {
                        store.commit.supervisor.setIsSupervisorInHistoricalState(true);
                        store.dispatch.supervisor.getSupervisorMementoList({ supervisorId: supervisorId });
                    }
                    else {
                        store.commit.supervisor.setIsSupervisorInHistoricalState(false);
                    }

                    await store.dispatch.supervisor.loadSupervisor({ id: supervisorId });
                    next();
                })
            },
            {
                path: "attachment-list/:id",
                name: "Supervisor attachment list",
                component: require("@/components/supervisor/attachment-list.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.DisponentAdmin, Roles.Disponent, Roles.Accountant, Roles.AccountantAdmin]
                },
            },
            {
                path: "duplicate/:id/:fullDuplication",
                name: "Duplicate supervisor",
                component: require("@/components/supervisor/supervisor.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.Disponent, Roles.DisponentAdmin]
                },
                beforeEnter: (async (to, from, next) => {
                    store.commit.supervisor.setIsSupervisorCopied(true);
                    const supervisorId = Number.parseInt(to.params.id);
                    const fullDuplication = to.params.fullDuplication === "true" ? true : false;
                    await store.dispatch.supervisor.duplicateSupervisor({ id: supervisorId, fullDuplication: fullDuplication });
                    next();
                })
            },
            {
                path: "archive-list",
                name: "Supervisor archive list",
                component: require("@/components/supervisor/supervisor-archive-list.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.Disponent, Roles.DisponentAdmin, Roles.AccountantAdmin]
                }
            },
            {
                path: "archive-list-for-settlement",
                name: "Supervisor archive list for settlement",
                component: require("@/components/supervisor/supervisor-archive-list-for-settlement.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.Accountant, Roles.AccountantAdmin]
                }
            },
            {
                path: "report",
                name: "Supervisor report",
                component: require("@/components/supervisor-report/supervisor-report.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.CrmAdmin]
                }
            },
            {
                path: "all-orders-list",
                name: "Supervisor all orders list",
                component: require("@/components/supervisor-all-orders/supervisor-all-orders-list.vue").default,
                meta: {
                    roles: [Roles.Admin]
                }
            }
        ]
    }
];

export default routes;
