import { saveFile } from "@/assets/file-saver";
import { moduleActionContext } from "@/store/index";
import { AxiosError, AxiosResponse } from "axios";
import { defineModule } from "direct-vuex";
import SettlementCostDocumentBankAccountRestModel from "models/rest/settlement/cost-document/settlementCostDocumentBankAccountRestModel";
import SettlementCostDocumentListRequestModel from "models/rest/settlement/cost-document/settlementCostDocumentListRequestModel";
import i18n from "../../assets/i18n";
import { SettlementDocumentRecalculatingTypeEnum } from "../../models/emums/settlement/settlementDocumentRecalculatingTypeEnum";
import ContractorSearchItemModel from "../../models/rest/contractor/contractorSearchItemModel";
import SaveAttachmentResponseModel from "../../models/rest/saveAttachmentResponseModel";
import SettlementCostDocumentAttachmentRestModel from "../../models/rest/settlement/cost-document/settlementCostDocumentAttachmentRestModel";
import SettlementCostDocumentCorrectionListItemRestModel from "../../models/rest/settlement/cost-document/settlementCostDocumentCorrectionListItemRestModel";
import SettlementCostDocumentItemRestModel from "../../models/rest/settlement/cost-document/settlementCostDocumentItemRestModel";
import SettlementCostDocumentListItemRestModel from "../../models/rest/settlement/cost-document/settlementCostDocumentListItemRestModel";
import SettlementCostDocumentRestModel from "../../models/rest/settlement/cost-document/settlementCostDocumentRestModel";
import SettlementAmountSummaryRestModel from "../../models/rest/settlement/settlementAmountSummaryRestModel";
import SettlementDocumentSaveResponseRestModel from "../../models/rest/settlement/settlementDocumentSaveResponseRestModel";
import SupervisorRestModel from "../../models/rest/supervisor/supervisorRestModel";
import { axiosInstance } from "../../plugins/axios";
import SettlementCostDocumentMementoItemRestModel from "../../models/rest/settlement/cost-document/settlementCostDocumentMementoItemRestModel";
import SupervisorForSettlementRestModel from "../../models/rest/supervisor/supervisorForSettlementRestModel";
import CurrencyRestModel from "../../models/rest/register/currencyRestModel";

export interface SettlementCostDocumentModuleStateInterface {
    costDocumentList: SettlementCostDocumentListItemRestModel[],
    costDocumentListLoading: boolean;
    costDocumentListTotalCount: number,
    costDocumentListAmountSummaryList: SettlementAmountSummaryRestModel[],
    costDocumentListSearchText: string,
    costDocumentListSelectedBranch: number,
    costDocumentListSelectedDocumentStatusIdcs: number[],
    costDocumentListSearchContractors: ContractorSearchItemModel[],
    costDocumentListSearchContractorIdcs: number[],
    costDocumentListSearchSupervisor: SupervisorRestModel,
    costDocumentListSearchCountryCode: string[],
    costDocumentListSearchIsPaid: number,
    costDocumentListSearchIsExportedToOptima: number,
    costDocumentListSearchCreatedAtFromDate: string,
    costDocumentListSearchCreatedAtToDate: string,
    costDocumentListSearchEntryDateFromDate: string,
    costDocumentListSearchEntryDateToDate: string,
    costDocumentListSearchIssueDateFrom: string,
    costDocumentListSearchIssueDateTo: string,
    costDocumentListSearchReceivedDateFrom: string,
    costDocumentListSearchReceivedDateTo: string,
    costDocumentListSearchSaleDateFrom: string,
    costDocumentListSearchSaleDateTo: string,
    costDocumentListSearchPaymentTermDateFrom: string,
    costDocumentListSearchPaymentTermDateTo: string,
    costDocumentListSearchPaymentDateFrom: string,
    costDocumentListSearchPaymentDateTo: string,
    costDocumentListSearchExportedToOptimaDateFrom: string,
    costDocumentListSearchExportedToOptimaDateTo: string,
    costDocumentListSearchCostType: number[],
    costDocumentListSearchPaymentType: number[],
    costDocumentListSearchSelectedCurrency: CurrencyRestModel,
    costDocumentListPagerOptions: any,
    costDocumentCorrectionList: SettlementCostDocumentCorrectionListItemRestModel[],
    costDocumentCorrectionListLoading: boolean;
    costDocumentCorrectionListTotalCount: number,
    costDocumentCorrectionListSearchText: string,
    costDocumentCorrectionListAmountSummaryList: SettlementAmountSummaryRestModel[],
    costDocumentCorrectionListPagerOptions: any,
    costDocumentCorrectionListSelectedBranch: number,
    costDocumentCorrectionListSelectedDocumentStatusIdcs: number[],
    costDocumentCorrectionListSearchContractors: ContractorSearchItemModel[],
    costDocumentCorrectionListSearchContractorIdcs: number[],
    costDocument: SettlementCostDocumentRestModel,
    costDocumentDocumentRecalculatingType: SettlementDocumentRecalculatingTypeEnum,
    costDocumentIssueDate: string,
    costDocumentPaymentDate: string,
    costDocumentReceivedDate: string,
    costDocumentEntryDate: string,
    costDocumentSaleDate: string,
    costDocumentValidationError: any
    costDocumentBankAccount: SettlementCostDocumentBankAccountRestModel,
    costDocumentBankAccountList: SettlementCostDocumentBankAccountRestModel[],
    costDocumentBankAccountValidationError: any
    showCostDocumentBankAccountDialog: boolean,
    costDocumentBankAccountDialogTitle: string,
    costDocumentBankAccountDialogResolve: any;
    costDocumentItem: SettlementCostDocumentItemRestModel,
    costDocumentItemList: SettlementCostDocumentItemRestModel[],
    costDocumentItemCorrectionList: SettlementCostDocumentItemRestModel[],
    costDocumentItemValidationError: any
    showCostDocumentItemDialog: boolean,
    costDocumentItemDialogTitle: string,
    costDocumentItemDialogIsFromCorrection: boolean,
    costDocumentItemDialogResolve: any,
    supervisorCostDocumentList: SettlementCostDocumentRestModel[],
    supervisorCostDocumentListLoading: boolean,
    costDocumentSearch: SettlementCostDocumentRestModel[],
    costDocumentSaveResponse: SettlementDocumentSaveResponseRestModel,
    attachmentList: SettlementCostDocumentAttachmentRestModel[];
    attachmentDialogAttachments: any[],
    showAttachmentDialog: false | boolean;
    attachmentDialogResolve: any;
    selectedCostDocumentList: SettlementCostDocumentListItemRestModel[],
    costDocumentListFilterAsActive: boolean,
    selectedCostDocumentCorrectionList: SettlementCostDocumentCorrectionListItemRestModel[],
    isCostDocumentInHistoricalState: boolean;
    costDocumentMementoListCount: number;
    costDocumentMementoList: SettlementCostDocumentMementoItemRestModel[];
}

const settlementCostDocumentModule = defineModule({
    namespaced: true,
    state: {
        costDocumentList: [] as SettlementCostDocumentListItemRestModel[],
        costDocumentListLoading: false,
        costDocumentListTotalCount: 0,
        costDocumentListAmountSummaryList: [] as SettlementAmountSummaryRestModel[],
        costDocumentListSearchText: "",
        costDocumentListSelectedBranch: 0,
        costDocumentListSelectedDocumentStatusIdcs: [] as number[],
        costDocumentListSearchContractors: [] as ContractorSearchItemModel[],
        costDocumentListSearchContractorIdcs: [] as number[],
        costDocumentListSearchSupervisor: {} as SupervisorRestModel,
        costDocumentListSearchCountryCode: [] as string[],
        costDocumentListSearchIsPaid: 0,
        costDocumentListSearchIsExportedToOptima: 0,
        costDocumentListSearchCreatedAtFromDate: "",
        costDocumentListSearchCreatedAtToDate: "",
        costDocumentListSearchEntryDateFromDate: "",
        costDocumentListSearchEntryDateToDate: "",
        costDocumentListSearchIssueDateFrom: "",
        costDocumentListSearchIssueDateTo: "",
        costDocumentListSearchReceivedDateFrom: "",
        costDocumentListSearchReceivedDateTo: "",
        costDocumentListSearchSaleDateFrom: "",
        costDocumentListSearchSaleDateTo: "",
        costDocumentListSearchPaymentTermDateFrom: "",
        costDocumentListSearchPaymentTermDateTo: "",
        costDocumentListSearchPaymentDateFrom: "",
        costDocumentListSearchExportedToOptimaDateFrom: "",
        costDocumentListSearchExportedToOptimaDateTo: "",
        costDocumentListSearchPaymentDateTo: "",
        costDocumentListSearchCostType: [] as number[],
        costDocumentListSearchPaymentType: [] as number[],
        costDocumentListSearchSelectedCurrency: {} as CurrencyRestModel,
        costDocumentListPagerOptions: {
            page: 1,
            itemsPerPage: 30,
            sortBy: ["id"],
            sortDesc: [true]
        },
        costDocumentCorrectionList: [] as SettlementCostDocumentCorrectionListItemRestModel[],
        costDocumentCorrectionListLoading: false,
        costDocumentCorrectionListTotalCount: 0,
        costDocumentCorrectionListSearchText: "",
        costDocumentCorrectionListPagerOptions: {
            page: 1,
            itemsPerPage: 30,
            sortBy: ["id"],
            sortDesc: [true]
        },
        costDocumentCorrectionListAmountSummaryList: [] as SettlementAmountSummaryRestModel[],
        costDocumentCorrectionListSelectedBranch: 0,
        costDocumentCorrectionListSelectedDocumentStatusIdcs: [] as number[],
        costDocumentCorrectionListSearchContractorIdcs: [] as number[],
        costDocumentCorrectionListSearchContractors: [] as ContractorSearchItemModel[],
        costDocument: {} as SettlementCostDocumentRestModel,
        costDocumentDocumentRecalculatingType: SettlementDocumentRecalculatingTypeEnum.DocumentInForeignCurrency,
        costDocumentIssueDate: "",
        costDocumentPaymentDate: "",
        costDocumentReceivedDate: "",
        costDocumentEntryDate: "",
        costDocumentSaleDate: "",
        costDocumentValidationError: {} as any,
        costDocumentBankAccount: {} as SettlementCostDocumentBankAccountRestModel,
        costDocumentBankAccountList: [] as SettlementCostDocumentBankAccountRestModel[],
        costDocumentBankAccountValidationError: {} as any,
        showCostDocumentBankAccountDialog: false,
        costDocumentBankAccountDialogTitle: "",
        costDocumentBankAccountDialogResolve: null,
        costDocumentItem: {} as SettlementCostDocumentItemRestModel,
        costDocumentItemList: [] as SettlementCostDocumentItemRestModel[],
        costDocumentItemCorrectionList: [] as SettlementCostDocumentItemRestModel[],
        costDocumentItemValidationError: {} as any,
        showCostDocumentItemDialog: false,
        costDocumentItemDialogTitle: "",
        costDocumentItemDialogIsFromCorrection: false,
        costDocumentItemDialogResolve: null,
        supervisorCostDocumentList: [] as SettlementCostDocumentRestModel[],
        supervisorCostDocumentListLoading: false,
        costDocumentSearch: [] as SettlementCostDocumentRestModel[],
        costDocumentSaveResponse: {} as SettlementDocumentSaveResponseRestModel,
        attachmentList: [] as SettlementCostDocumentAttachmentRestModel[],
        attachmentDialogAttachments: [] as any[],
        showAttachmentDialog: false,
        attachmentDialogResolve: null,
        selectedCostDocumentList: [] as SettlementCostDocumentListItemRestModel[],
        costDocumentListFilterAsActive: false,
        selectedCostDocumentCorrectionList: [] as SettlementCostDocumentCorrectionListItemRestModel[],
        isCostDocumentInHistoricalState: false,
        costDocumentMementoListCount: 0,
        costDocumentMementoList: [] as SettlementCostDocumentMementoItemRestModel[],
    } as SettlementCostDocumentModuleStateInterface,
    mutations: {
        setCostDocumentList(state, value: SettlementCostDocumentListItemRestModel[]) {
            state.costDocumentList = value;
        },
        setCostDocumentListLoading(state, value: boolean) {
            state.costDocumentListLoading = value;
        },
        setCostDocumentListTotalCount(state, value: number) {
            state.costDocumentListTotalCount = value;
            if ((state.costDocumentListPagerOptions.page * state.costDocumentListPagerOptions.itemsPerPage) >= (value + state.costDocumentListPagerOptions.itemsPerPage)) {
                state.costDocumentListPagerOptions.page = 1;
            }
        },
        setCostDocumentListAmountSummaryList(state, value: SettlementAmountSummaryRestModel[]) {
            state.costDocumentListAmountSummaryList = value;
        },
        setCostDocumentListSearchText(state, value: string) {
            state.costDocumentListSearchText = value;
        },
        setCostDocumentListSelectedBranch(state, value: number) {
            state.costDocumentListSelectedBranch = value;
        },
        setDocumentListSelectedDocumentStatusIdcs(state, value: number[]) {
            state.costDocumentListSelectedDocumentStatusIdcs = value;
        },
        setCostDocumentListSearchContractors(state, value: ContractorSearchItemModel[]) {
            state.costDocumentListSearchContractors = value;
        },
        setCostDocumentListSearchContractorIdcs(state, value: number[]) {
            state.costDocumentListSearchContractorIdcs = value;
        },
        setCostDocumentListSearchSupervisor(state, value: SupervisorRestModel) {
            state.costDocumentListSearchSupervisor = value;
        },
        setCostDocumentListSearchCountryCode(state, value: string[]) {
            state.costDocumentListSearchCountryCode = value;
        },
        setCostDocumentListSearchCreatedAtFromDate(state, value: string) {
            state.costDocumentListSearchCreatedAtFromDate = value;
        },
        setCostDocumentListSearchCreatedAtToDate(state, value: string) {
            state.costDocumentListSearchCreatedAtToDate = value;
        },
        setCostDocumentListSearchEntryDateFromDate(state, value: string) {
            state.costDocumentListSearchEntryDateFromDate = value;
        },
        setCostDocumentListSearchEntryDateToDate(state, value: string) {
            state.costDocumentListSearchEntryDateToDate = value;
        },
        setCostDocumentListSearchIssueDateFrom(state, value: string) {
            state.costDocumentListSearchIssueDateFrom = value;
        },
        setCostDocumentListSearchIssueDateTo(state, value: string) {
            state.costDocumentListSearchIssueDateTo = value;
        },
        setCostDocumentListSearchReceivedDateFrom(state, value: string) {
            state.costDocumentListSearchReceivedDateFrom = value;
        },
        setCostDocumentListSearchReceivedDateTo(state, value: string) {
            state.costDocumentListSearchReceivedDateTo = value;
        },
        setCostDocumentListSearchSaleDateFrom(state, value: string) {
            state.costDocumentListSearchSaleDateFrom = value;
        },
        setCostDocumentListSearchSaleDateTo(state, value: string) {
            state.costDocumentListSearchSaleDateTo = value;
        },
        setCostDocumentListSearchPaymentTermDateFrom(state, value: string) {
            state.costDocumentListSearchPaymentTermDateFrom = value;
        },
        setCostDocumentListSearchPaymentTermDateTo(state, value: string) {
            state.costDocumentListSearchPaymentTermDateTo = value;
        },
        setCostDocumentListSearchPaymentDateFrom(state, value: string) {
            state.costDocumentListSearchPaymentDateFrom = value;
        },
        setCostDocumentListSearchExportedToOptimaDateFrom(state, value: string) {
            state.costDocumentListSearchExportedToOptimaDateFrom = value;
        },
        setCostDocumentListSearchExportedToOptimaDateTo(state, value: string) {
            state.costDocumentListSearchExportedToOptimaDateTo = value;
        },
        setCostDocumentListSearchPaymentDateTo(state, value: string) {
            state.costDocumentListSearchPaymentDateTo = value;
        },
        setCostDocumentListSearchCostType(state, value: number[]) {
            state.costDocumentListSearchCostType = value;
        },
        setCostDocumentListSearchPaymentType(state, value: number[]) {
            state.costDocumentListSearchPaymentType = value;
        },
        setCostDocumentListSearchSelectedCurrency(state, value: CurrencyRestModel) {
            state.costDocumentListSearchSelectedCurrency = value;
        },
        setCostDocumentListSearchIsPaid(state, value: number) {
            state.costDocumentListSearchIsPaid = value;
        },
        setCostDocumentListSearchIsExportedToOptima(state, value: number) {
            state.costDocumentListSearchIsExportedToOptima = value;
        },
        setCostDocumentListPagerOptions(state, value) {
            state.costDocumentListPagerOptions = value;
        },
        setCostDocumentCorrectionList(state, value: SettlementCostDocumentCorrectionListItemRestModel[]) {
            state.costDocumentCorrectionList = value;
        },
        setCostDocumentCorrectionListLoading(state, value: boolean) {
            state.costDocumentCorrectionListLoading = value;
        },
        setCostDocumentCorrectionListTotalCount(state, value: number) {
            state.costDocumentCorrectionListTotalCount = value;
            if ((state.costDocumentCorrectionListPagerOptions.page * state.costDocumentCorrectionListPagerOptions.itemsPerPage) >= (value + state.costDocumentCorrectionListPagerOptions.itemsPerPage)) {
                state.costDocumentCorrectionListPagerOptions.page = 1;
            }
        },
        setCostDocumentCorrectionListAmountSummaryList(state, value: SettlementAmountSummaryRestModel[]) {
            state.costDocumentCorrectionListAmountSummaryList = value;
        },
        setCostDocumentCorrectionListSearchText(state, value: string) {
            state.costDocumentCorrectionListSearchText = value;
        },
        setCostDocumentCorrectionListPagerOptions(state, value) {
            state.costDocumentCorrectionListPagerOptions = value;
        },
        setCostDocumentCorrectionListSelectedBranch(state, value: number) {
            state.costDocumentCorrectionListSelectedBranch = value;
        },
        setCostDocumentCorrectionListSelectedDocumentStatusIdcs(state, value: number[]) {
            state.costDocumentCorrectionListSelectedDocumentStatusIdcs = value;
        },
        setCostDocumentCorrectionListSearchContractors(state, value: ContractorSearchItemModel[]) {
            state.costDocumentCorrectionListSearchContractors = value;
        },
        setCostDocumentCorrectionListSearchContractorIdcs(state, value: number[]) {
            state.costDocumentCorrectionListSearchContractorIdcs = value;
        },
        setCostDocument(state, value: SettlementCostDocumentRestModel) {
            state.costDocument = value;
        },
        setCostDocumentDocumentRecalculatingType(state, value: SettlementDocumentRecalculatingTypeEnum) {
            state.costDocumentDocumentRecalculatingType = value;
        },
        setCostDocumentIssueDate(state, value: string) {
            state.costDocumentIssueDate = value;
        },
        setCostDocumentPaymentDate(state, value: string) {
            state.costDocumentPaymentDate = value;
        },
        setCostDocumentReceivedDate(state, value: string) {
            state.costDocumentReceivedDate = value;
        },
        setCostDocumentEntryDate(state, value: string) {
            state.costDocumentEntryDate = value;
        },
        setCostDocumentSaleDate(state, value: string) {
            state.costDocumentSaleDate = value;
        },
        setCostDocumentValidationError(state, value: any) {
            state.costDocumentValidationError = value;
        },
        setCostDocumentBankAccountList(state, value: SettlementCostDocumentBankAccountRestModel[]) {
            state.costDocumentBankAccountList = value;
        },
        setCostDocumentBankAccount(state, value: SettlementCostDocumentBankAccountRestModel) {
            state.costDocumentBankAccount = value;
        },
        setCostDocumentBankAccountValidationError(state, value: any) {
            state.costDocumentBankAccountValidationError = value;
        },
        setShowCostDocumentBankAccountDialog(state, value: boolean) {
            state.showCostDocumentBankAccountDialog = value;
        },
        setCostDocumentBankAccountDialogTitle(state, value: string) {
            state.costDocumentBankAccountDialogTitle = value;
        },
        setCostDocumentBankAccountDialogResolve(state, value: any) {
            state.costDocumentBankAccountDialogResolve = value;
        },
        setCostDocumentItem(state, value: SettlementCostDocumentItemRestModel) {
            state.costDocumentItem = value;
        },
        setCostDocumentItemList(state, value: SettlementCostDocumentItemRestModel[]) {
            state.costDocumentItemList = value;
        },
        setCostDocumentItemCorrectionList(state, value: SettlementCostDocumentItemRestModel[]) {
            state.costDocumentItemCorrectionList = value;
        },
        setCostDocumentItemValidationError(state, value: any) {
            state.costDocumentItemValidationError = value;
        },
        setShowCostDocumentItemDialog(state, value: boolean) {
            state.showCostDocumentItemDialog = value;
        },
        setCostDocumentItemDialogTitle(state, value: string) {
            state.costDocumentItemDialogTitle = value;
        },
        setCostDocumentItemDialogIsFromCorrection(state, value: boolean) {
            state.costDocumentItemDialogIsFromCorrection = value;
        },
        setCostDocumentItemDialogResolve(state, value: any) {
            state.costDocumentItemDialogResolve = value;
        },
        setSupervisorCostDocumentList(state, value: SettlementCostDocumentRestModel[]) {
            state.supervisorCostDocumentList = value;
        },
        setSupervisorCostDocumentListLoading(state, value: boolean) {
            state.supervisorCostDocumentListLoading = value;
        },
        setCostDocumentSearch(state, value: SettlementCostDocumentRestModel[]) {
            state.costDocumentSearch = value;
        },
        setCostDocumentSaveResponse(state, value: SettlementDocumentSaveResponseRestModel) {
            state.costDocumentSaveResponse = value;
        },
        setAttachmentList(state, value: SettlementCostDocumentAttachmentRestModel[]) {
            state.attachmentList = value;
        },
        setAttachmentDialogAttachments(state, value: any[]) {
            state.attachmentDialogAttachments = value;
        },
        setShowAttachmentDialog(state, value: boolean) {
            state.showAttachmentDialog = value;
        },
        setAttachmentDialogResolve(state, value: any) {
            state.attachmentDialogResolve = value;
        },
        setSelectedCostDocumentList(state, value: SettlementCostDocumentListItemRestModel[]) {
            state.selectedCostDocumentList = value;
        },
        setCostDocumentListFilterAsActive(state, value: boolean) {
            state.costDocumentListFilterAsActive = value;
        },
        setSelectedCostDocumentCorrectionList(state, value: SettlementCostDocumentCorrectionListItemRestModel[]) {
            state.selectedCostDocumentCorrectionList = value;
        },
        setIsCostDocumentInHistoricalState(state, value: boolean) {
            state.isCostDocumentInHistoricalState = value;
        },
        setCostDocumentMementoListCount(state, value: number) {
            state.costDocumentMementoListCount = value;
        },
        setCostDocumentMementoList(state, value: SettlementCostDocumentMementoItemRestModel[]) {
            state.costDocumentMementoList = value;
        },
    },
    actions: {
        loadCostDocumentList(context,
            payload: { requestModel: SettlementCostDocumentListRequestModel }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                commit.setCostDocumentListLoading(true);

                axiosInstance
                    .post("/api/settlementCostDocument/cost-document/list", payload.requestModel)
                    .then((resp: AxiosResponse) => {
                        commit.setCostDocumentList(resp.data.items);
                        commit.setCostDocumentListTotalCount(resp.data.totalItemsCount)
                        commit.setCostDocumentListAmountSummaryList(resp.data.amountSummaryList)
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setCostDocumentListLoading(false);
                    })
            })
        },
        downloadXlsReportCostDocumentList(context,
            payload: { requestModel: SettlementCostDocumentListRequestModel }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((reject) => {
                commit.setCostDocumentListLoading(true);
                axiosInstance
                    .post("/api/settlementCostDocument/cost-document/export", payload.requestModel, {
                        responseType: "blob"
                    })
                    .then((resp: AxiosResponse) => {
                        saveFile(resp);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setCostDocumentListLoading(false);
                    })
            })
        },
        loadSupervisorCostDocumentList(context, payload: { supervisorId: number }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                commit.setSupervisorCostDocumentListLoading(true);
                axiosInstance
                    .get("/api/settlementCostDocument/supervisor/" + payload.supervisorId + "/cost-document/list")
                    .then((resp: AxiosResponse) => {
                        commit.setSupervisorCostDocumentList(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setSupervisorCostDocumentListLoading(false);
                    })

            })
        },
        async loadCostDocument(context, payload: { costDocumentId: number }) {
            const { commit, rootCommit } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                if (!isNaN(payload.costDocumentId)) {
                    axiosInstance
                        .get("/api/settlementCostDocument/cost-document/" + payload.costDocumentId)
                        .then((resp: AxiosResponse) => {
                            commit.setCostDocument(resp.data);
                            commit.setCostDocumentIssueDate(resp.data.issueDate);
                            commit.setCostDocumentPaymentDate(resp.data.paymentDate);
                            commit.setCostDocumentReceivedDate(resp.data.receivedDate);
                            commit.setCostDocumentEntryDate(resp.data.entryDate);
                            commit.setCostDocumentSaleDate(resp.data.saleDate);
                            commit.setCostDocumentBankAccountList(resp.data.costDocumentBankAccounts);
                            commit.setCostDocumentItemList(resp.data.costDocumentItems);
                            commit.setCostDocumentItemCorrectionList(resp.data.costDocumentCorrectionItems)
                            rootCommit.settlementCommon.setSupervisorList(resp.data.costDocumentSupervisors);
                            resolve(resp.data);
                        })
                        .catch((err: AxiosError) => reject(err));
                }
                else {
                    axiosInstance
                        .get("/api/settlementCostDocument/cost-document/temporary")
                        .then((resp: AxiosResponse) => {
                            commit.setCostDocument(resp.data)
                            resolve(resp.data);
                        })
                        .catch((err: AxiosError) => reject(err));
                }
            })
        },
        async saveCostDocument(context) {
            const { state, commit, rootDispatch } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/settlementCostDocument/cost-document/save", state.costDocument)
                    .then(async (resp: AxiosResponse) => {
                        commit.setCostDocumentValidationError({});
                        commit.setCostDocumentSaveResponse(resp.data);

                        if (resp.data.documentId != null) {
                            state.costDocument.id = resp.data.documentId;
                        }

                        await rootDispatch.confirm.openConfirmDialog({
                            showConfirmDialog: true,
                            title: resp.data.isSaved ? i18n.t("costDocument.documentHasBeenSaved").toString() : i18n.t("costDocument.costDocumentCouldNotBeSaved").toString(),
                            message: resp.data.isSaved ? i18n.t("costDocument.documentNumber").toString() + ": " + resp.data.message : i18n.t("costDocument.costDocumentCouldNotBeSaved").toString() + ": " + resp.data.message,
                            links: [],
                            options: { buttonColor: resp.data.isSaved ? "primary" : "error", layoutColor: resp.data.isSaved ? "primary" : "error" },
                            buttonType: "ok"
                        })

                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => {
                        if (err.response && err.response.data.errors) {
                            commit.setCostDocumentValidationError(err.response.data.errors);
                            resolve(err.response.data.errors);
                        }
                        reject(err)
                    });
            })
        },
        async saveCostDocumentCorrection(context) {
            const { state, commit, rootDispatch } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/settlementCostDocument/cost-document/correction/save", state.costDocument)
                    .then(async (resp: AxiosResponse) => {
                        commit.setCostDocumentValidationError({});
                        commit.setCostDocumentSaveResponse(resp.data);

                        if (resp.data.documentId != null) {
                            state.costDocument.id = resp.data.documentId;
                        };

                        await rootDispatch.confirm.openConfirmDialog({
                            showConfirmDialog: true,
                            title: resp.data.isSaved ? i18n.t("costDocument.documentHasBeenSaved").toString() : i18n.t("costDocument.costDocumentCouldNotBeSaved").toString(),
                            message: resp.data.isSaved ? i18n.t("costDocument.documentNumber").toString() + ": " + resp.data.message : i18n.t("costDocument.costDocumentCouldNotBeSaved").toString() + ": " + resp.data.message,
                            links: [],
                            options: { buttonColor: resp.data.isSaved ? "primary" : "error", layoutColor: resp.data.isSaved ? "primary" : "error" },
                            buttonType: "ok"
                        });

                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => {
                        if (err.response && err.response.data.errors) {
                            commit.setCostDocumentValidationError(err.response.data.errors);
                            resolve(err.response.data.errors);
                        }
                        reject(err)
                    });
            })
        },
        async deleteCostDocument(context, payload: { costDocumentId: number }) {
            const { state } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/settlementCostDocument/cost-document/delete/" + payload.costDocumentId)
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err))
            })
        },
        async validateCostDocumentBankAccount(context, payload: { costDocumentBankAccount: SettlementCostDocumentBankAccountRestModel }) {
            const { commit } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/settlementCostDocument/cost-document/bank-account/validate", payload.costDocumentBankAccount)
                    .then((resp: AxiosResponse) => {
                        commit.setCostDocumentBankAccountValidationError({});
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => {
                        if (err.response && err.response.data.errors) {
                            commit.setCostDocumentBankAccountValidationError(err.response.data.errors);
                            resolve(err.response.data.errors)
                        }
                        reject(err)
                    });
            })
        },
        openCostDocumentBankAccountDialog(context, payload: { title: string; }) {
            const { commit } = _moduleActionContext(context);
            commit.setShowCostDocumentBankAccountDialog(true);
            commit.setCostDocumentBankAccountDialogTitle(payload.title);
            return new Promise(resolve => {
                commit.setCostDocumentBankAccountDialogResolve(resolve);
            });
        },
        isConfirmedCostDocumentBankAccountDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.costDocumentBankAccountDialogResolve(true);
            commit.setShowCostDocumentBankAccountDialog(false);
        },
        isNotConfirmedCostDocumentBankAccountDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.costDocumentBankAccountDialogResolve(false);
            commit.setShowCostDocumentBankAccountDialog(false);
        },
        async validateCostDocumentItem(context, payload: { item: SettlementCostDocumentItemRestModel }) {
            const { commit } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/settlementCostDocument/cost-document/item/validate", payload.item)
                    .then((resp: AxiosResponse) => {
                        commit.setCostDocumentItemValidationError({});
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => {
                        if (err.response && err.response.data.errors) {
                            commit.setCostDocumentItemValidationError(err.response.data.errors);
                            resolve(err.response.data.errors)
                        }
                        reject(err)
                    });
            })
        },
        openCostDocumentItemDialog(context, payload: { title: string, isFromCorrection: boolean }) {
            const { commit } = _moduleActionContext(context);
            commit.setShowCostDocumentItemDialog(true);
            commit.setCostDocumentItemDialogTitle(payload.title);
            commit.setCostDocumentItemDialogIsFromCorrection(payload.isFromCorrection);
            return new Promise(resolve => {
                commit.setCostDocumentItemDialogResolve(resolve);
            });
        },
        isConfirmedCostDocumentItemDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.costDocumentItemDialogResolve(true);
            commit.setShowCostDocumentItemDialog(false);
        },
        isNotConfirmedCostDocumentItemDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.costDocumentItemDialogResolve(false);
            commit.setShowCostDocumentItemDialog(false);
        },
        async searchCostDocument(context, payload: { searchText: string }) {
            const { commit } = _moduleActionContext(context)
            {
                return await new Promise((resolve, reject) => {
                    axiosInstance
                        .post("/api/settlementCostDocument/cost-document/search", { searchText: payload.searchText })
                        .then(async (resp: AxiosResponse) => {
                            commit.setCostDocumentSearch(resp.data);
                            resolve(resp.data);
                        })
                        .catch((err: AxiosError) => reject(err));
                });
            }
        },
        loadCostDocumentCorrectionList(context, payload: { requestModel: SettlementCostDocumentListRequestModel }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                commit.setCostDocumentCorrectionListLoading(true);
                axiosInstance
                    .post("/api/settlementCostDocument/cost-document/correction/list", payload.requestModel)
                    .then((resp: AxiosResponse) => {
                        commit.setCostDocumentCorrectionList(resp.data.items);
                        commit.setCostDocumentCorrectionListTotalCount(resp.data.totalItemsCount)
                        commit.setCostDocumentCorrectionListAmountSummaryList(resp.data.amountSummaryList)
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setCostDocumentCorrectionListLoading(false);
                    })
            })
        },
        downloadXlsReportCostDocumentCorrectionList(context, payload: { requestModel: SettlementCostDocumentListRequestModel }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((reject) => {
                commit.setCostDocumentCorrectionListLoading(true);
                axiosInstance
                    .post("/api/settlementCostDocument/cost-document/correction/export", payload.requestModel, {
                        responseType: "blob"
                    })
                    .then((resp: AxiosResponse) => {
                        saveFile(resp);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setCostDocumentCorrectionListLoading(false);
                    })
            })
        },
        async cancelCostDocumentCorrection(context, payload: { costDocumentCorrectionId: number }) {
            const { state } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/settlementCostDocument/cost-document/correction/cancel/" + payload.costDocumentCorrectionId)
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => {
                        reject(err)
                    });
            })
        },
        isConfirmedAttachmentDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.attachmentDialogResolve(true);
            commit.setShowAttachmentDialog(false);
        },
        isNotConfirmedAttachmentDialog(context) {
            const { commit } = _moduleActionContext(context);
            commit.setShowAttachmentDialog(false);
        },
        openAttachmentDialog(context) {
            const { commit } = _moduleActionContext(context);
            commit.setAttachmentDialogAttachments([] as any[]);
            commit.setShowAttachmentDialog(true);
            return new Promise(resolve => {
                commit.setAttachmentDialogResolve(resolve);
            })
        },
        loadCostDocumentAttachmentList(context, payload: { mementoPoint: number | null } = { mementoPoint: null }) {
            const { state, commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                const params = {
                    mementoPoint: payload.mementoPoint,
                }
                axiosInstance
                    .get("/api/settlementCostDocument/cost-document/" + state.costDocument.id + "/attachment/list", { params })
                    .then((resp: AxiosResponse) => {
                        commit.setAttachmentList(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        saveAttachments(context) {
            const { state, dispatch, rootDispatch } = _moduleActionContext(context);
            const formData = new FormData();
            for (const index in state.attachmentDialogAttachments) {
                formData.append("files", state.attachmentDialogAttachments[index]);
            }

            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/settlementCostDocument/cost-document/" + state.costDocument.id + "/attachment/save", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                    .then((resp: AxiosResponse) => {
                        const response = resp.data as SaveAttachmentResponseModel;
                        if (response.statusCode == 415) {
                            rootDispatch.confirm.openConfirmDialog({
                                showConfirmDialog: true,
                                title: i18n.t("common.wrongFileFormatTitle").toString(),
                                message: i18n.t("common.wrongFileFormatTitleMessageFirstPart").toString() + response.fileNameList.join(", ") + i18n.t("common.wrongFileFormatTitleMessageLastPart").toString(),
                                links: [],
                                options: { buttonColor: "error", layoutColor: "error" },
                                buttonType: "ok"
                            })
                        }
                        dispatch.loadCostDocumentAttachmentList();
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => {
                        reject(err)
                    });
            });
        },
        downloadCostDocumentFile(context, payload: { costDocumentId: number, costDocumentFileId: string }) {
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/settlementCostDocument/cost-document/" + payload.costDocumentId + "/attachment/" + payload.costDocumentFileId, {
                        responseType: "blob"
                    })
                    .then((resp: AxiosResponse) => {
                        const blob = new Blob([resp.data], {
                            type: resp.headers["content-type"]
                        });
                        const contentDisposition = resp.headers["content-disposition"];
                        const filename = contentDisposition
                            .split(";")[1]
                            .split("=")[1]
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement("a");
                        a.style.display = "none";
                        document.body.appendChild(a);
                        a.download = filename;
                        a.href = url;
                        a.click();
                        window.URL.revokeObjectURL(url);

                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        deleteCostDocumentFile(context, payload: { fileId: string }) {
            const { state } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/settlementCostDocument/cost-document/" + state.costDocument.id + "/attachment/delete/" + payload.fileId)
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        async getCostDocumentMementoPoint(context, payload: { mementoPoint: number }) {
            const { state, commit, rootCommit } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/settlementCostDocument/getCostDocumentMementoPoint/" + state.costDocument.id + '/' + payload.mementoPoint)
                    .then((resp: AxiosResponse) => {
                        commit.setCostDocument(resp.data);
                        commit.setCostDocumentIssueDate(resp.data.issueDate);
                        commit.setCostDocumentPaymentDate(resp.data.paymentDate);
                        commit.setCostDocumentSaleDate(resp.data.saleDate);
                        commit.setCostDocumentItemList(resp.data.costDocumentItems);
                        commit.setCostDocumentBankAccountList(resp.data.costDocumentBankAccounts);
                        commit.setCostDocumentItemCorrectionList(resp.data.costDocumentCorrectionItems)
                        rootCommit.settlementCommon.setSupervisorList(resp.data.costDocumentSupervisors);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        async getCostDocumentMementoList(context, payload: { costDocumentId: number }) {
            const { commit } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/settlementCostDocument/getCostDocumentMementoList/" + payload.costDocumentId)
                    .then((resp: AxiosResponse) => {
                        commit.setCostDocumentMementoListCount(resp.data.mementosCount)
                        commit.setCostDocumentMementoList(resp.data.mementoList)
                        resolve(resp.data);
                    })
                    .catch((error: AxiosError) => reject(error));
            })
        },
        cleanCostDocumentProperties(context) {
            const { commit, rootCommit } = _moduleActionContext(context);
            commit.setCostDocument({} as SettlementCostDocumentRestModel);
            commit.setCostDocumentIssueDate("");
            commit.setCostDocumentPaymentDate("");
            commit.setCostDocumentReceivedDate("");
            commit.setCostDocumentEntryDate("");
            commit.setCostDocumentSaleDate("");
            commit.setCostDocumentItemList([] as SettlementCostDocumentItemRestModel[]);
            commit.setCostDocumentItemCorrectionList([] as SettlementCostDocumentItemRestModel[])
            rootCommit.settlementCommon.setSupervisorList([] as SupervisorForSettlementRestModel[]);
        },
    }
});

export default settlementCostDocumentModule;
const _moduleActionContext = (context: any) => moduleActionContext(context, settlementCostDocumentModule)