import { AxiosError, AxiosResponse } from "axios";
import { defineModule } from "direct-vuex";
import { moduleActionContext } from "..";
import ListItemNumberModel from "../../models/listItemNumberModel";
import PlaceStateRestModel from "../../models/rest/tracking/placeStateRestModel";
import SupervisorTrackingRestModel from "../../models/rest/tracking/supervisorTrackingRestModel";
import TrackingRestModel from "../../models/rest/tracking/trackingRestModel";
import { axiosInstance } from "../../plugins/axios";


export interface TrackingModuleStateInterface {
    supervisorTrackingVehicleList: SupervisorTrackingRestModel[];
    supervisorTrackingVehicleListLoading: boolean;
    trackingVehicleData: TrackingRestModel;
    trackingSupervisorData: TrackingRestModel;
    placeStateList: PlaceStateRestModel[];
    placeStateListLoading: false | boolean;
    placeStateListTotalCount: number;
    selectedContractor: ListItemNumberModel;
    placeStateListPagerOptions: any;
}

const trackingModule = defineModule({
    namespaced: true,
    state: {
        supervisorTrackingVehicleList: [] as SupervisorTrackingRestModel[],
        supervisorTrackingVehicleListLoading: false,
        trackingVehicleData: {} as TrackingRestModel,
        trackingSupervisorData: {} as TrackingRestModel,
        placeStateList: [] as PlaceStateRestModel[],
        placeStateListLoading: false,
        placeStateListTotalCount: 0,
        selectedContractor: {} as ListItemNumberModel,
        placeStateListPagerOptions: {
            page: 1,
            itemsPerPage: 5,
        },
    } as TrackingModuleStateInterface,
    mutations: { 
        setSupervisorTrackingVehicleList(state, value: SupervisorTrackingRestModel[]) {
            state.supervisorTrackingVehicleList = value;
        },
        setSupervisorTrackingVehicleListLoading(state, value: boolean) {
            state.supervisorTrackingVehicleListLoading = value;
        },
        setTrackingVehicleData(state, value: TrackingRestModel) {
            state.trackingVehicleData = value;
        },
        setTrackingSupervisorData(state, value: TrackingRestModel) {
            state.trackingSupervisorData = value;
        },
        setPlaceStateList(state, value: PlaceStateRestModel[]) {
            state.placeStateList = value;
        },
        setPlaceStateListLoading(state, value: boolean) {
            state.placeStateListLoading = value;
        },
        setPlaceStateListTotalCount(state, value: number) {
            state.placeStateListTotalCount = value;
            if ((state.placeStateListPagerOptions.page * state.placeStateListPagerOptions.itemsPerPage) >= (value + state.placeStateListPagerOptions.itemsPerPage)) {
                state.placeStateListPagerOptions.page = 1;
            }
        },
        setSelectedContractor(state, value: ListItemNumberModel) {
            state.selectedContractor = value;
        },
        setPlaceStateListPagerOptions(state, value: any) {
            state.placeStateListPagerOptions = value;
        }
    },
    actions: {
        loadSupervisorTrackingVehicleList(context) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                commit.setSupervisorTrackingVehicleListLoading(true);
                axiosInstance
                    .get("/api/status/supervisorStatusVehicleList")
                    .then((resp: AxiosResponse) => {
                        commit.setSupervisorTrackingVehicleList(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setSupervisorTrackingVehicleListLoading(false)
                    })
            })
        },
        async loadTrackingVehicleData(context, payload: { id: number }) {
            const { commit } = _moduleActionContext(context)
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/status/statusVehicleData/" + payload.id)
                    .then((resp: AxiosResponse) => {
                        commit.setTrackingVehicleData(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        async loadTrackingSupervisorData(context, payload: { id: number }) {
            const { commit } = _moduleActionContext(context)
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/status/statusSupervisorData/" + payload.id)
                    .then((resp: AxiosResponse) => {
                        commit.setTrackingSupervisorData(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        placeStateListCleanFilters(context) {
            const { commit } = _moduleActionContext(context);
            const filterOptions = {
                page: 1,
                itemsPerPage: 5,
            }
            commit.setPlaceStateListPagerOptions(filterOptions);
        },
        loadPlaceStateList(
            context,
            payload: {
                page: number;
                itemsPerPage: number;
                contractorId: number;
            }
        ) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                commit.setPlaceStateListLoading(true);
                const params = {
                    page: payload.page,
                    pageSize: payload.itemsPerPage,
                    contractorId: payload.contractorId
                };
                axiosInstance
                    .get("/api/status/placeStateList", { params })
                    .then((resp: AxiosResponse) => {
                        commit.setPlaceStateList(resp.data.items);
                        commit.setPlaceStateListTotalCount(resp.data.totalItemsCount);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setPlaceStateListLoading(false);
                    });
            });
        },
    }
});

export default trackingModule;
const _moduleActionContext = (context: any) => moduleActionContext(context, trackingModule)