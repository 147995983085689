import Vue from "vue";
import { Component } from "vue-property-decorator";
import BranchRestModel from "../../../../models/rest/register/branchRestModel";
import BranchDialog from "./branch-dialog.vue";

@Component({
    components: {
        "branch-dialog": BranchDialog,
    }
})
export default class BranchList extends Vue {
    get items(): BranchRestModel[] {
        return this.$store.direct.state.register.branches;
    }

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    itemsHeaders = [
        {
            text: "Id",
            value: "id",
            align: "start",
            width: "1%",
        },
        {
            text: this.$t("contractor.branch"),
            value: "name",
            align: "start",
        },
        {
            text: this.$t("common.contractorName"),
            value: "contractor.text",
            align: "start",
        },
        {
            text: this.$t("common.contractorId"),
            value: "contractor.value",
            align: "start",
        },
        {
            text: this.$t("common.edit"),
            align: "center",
            value: "edit",
            width: "1%",
            sortable: false
        },
    ];

    pagerOptions = {
        page: 1,
        itemsPerPage: 10
    };

    created() {
        this.$store.direct.dispatch.register.loadBranchList();
    }

    itemEditClick(item: BranchRestModel) {
        this.$store.direct.dispatch.register.loadBranch({ branchId: item.id });
        this.$store.direct.dispatch.register.showBranchDialog();
    }
}
