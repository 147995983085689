import { RouteConfig } from "vue-router";
import { Roles } from "@/models/emums/roles";

const routes: Array<RouteConfig> = [
  {
    path: "/operation",
    component: require("@/layouts/layout.vue").default,
    children: [
      {
        path: "list",
        name: "Operation list",
        component: require("@/components/operation/operation-list.vue").default,
        meta: {
            roles: [Roles.Admin, Roles.CrmAdmin, Roles.Crm, Roles.CrmLower]
        }
      }
    ]
  }
];

export default routes;
