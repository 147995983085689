import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import BaseCriterionNameEnum from "../../models/baseCriterionModel";
import AutocompleteCriterion from "../filters/autocomplete-criterion.vue";
import DateCriterion from "../filters/date-criterion.vue";
import SelectCriterion from "../filters/select-criterion.vue";
import SelectMultipleCriterion from "../filters/select-multiple-criterion.vue";
import SwitchCriterion from "../filters/switch-criterion.vue";

@Component({
    props: {
        criterionList: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    components:
    {
        "autocomplete-criterion": AutocompleteCriterion,
        "date-criterion": DateCriterion,
        "select-criterion": SelectCriterion,
        "select-multiple-criterion": SelectMultipleCriterion,
        "switch-criterion": SwitchCriterion,
    }
})


export default class FilterTemplate extends Vue {
    criterionList!: BaseCriterionNameEnum[];
    criterionListMutated = [] as BaseCriterionNameEnum[];

    @Watch("criterionList", { deep: true })
    onCriterionListChanged(val: any) {
        this.criterionListMutated = this.criterionList;
    }
}
