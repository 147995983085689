export enum BaseCriterionNameEnum {
    contractorCarriers = "contractorCarriers",
    contractorSenders = "contractorSenders",
    contractorPayers = "contractorPayers",
    contractorSendersAccountManagers = "contractorSendersAccountManagers",
    dateFrom = "dateFrom",
    dateTo = "dateTo",
    dateType = "dateType",
    departmentList = "departmentList",
    includeContractorCarriers = "includeContractorCarriers",
    includeContractorSenders = "includeContractorSenders",
    includeContractorPayers = "includeContractorPayers",
}
