import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component

export default class Admin extends Vue {
    get userList() {
        return {
            route: "/admin/user/list",
            label: this.$i18n.t("user.users"),
            icon: "mdi-account"
        }
    }

    get personRoleList() {
        return {
            route: "/admin/register/person-role/list",
            label: this.$i18n.t("common.personRoles"),
            icon: "mdi-account-settings"
        }
    }

    get countryList() {
        return {
            route: "/admin/register/country/list",
            label: this.$i18n.t("common.countries"),
            icon: "mdi-flag-outline"
        }
    }

    get currencyList() {
        return {
            route: "/admin/register/currency/list",
            label: this.$i18n.t("common.currencies"),
            icon: "mdi-currency-eur"
        }
    }

    get supervisorCarrierDocumentOrderRequirementList() {
        return {
            route: "/admin/register/supervisor-carrier-document-order-requirement/list",
            label: this.$i18n.t("supervisor.carrierOrderRequirement"),
            icon: "mdi-file-document-outline"
        }
    }

    get safeLocationList() {
        return {
            route: "/admin/register/safe-location-list",
            label: this.$i18n.t("common.safeLocations"),
            icon: "mdi-lock-outline"
        }
    }

    get costTypeList() {
        return {
            route: "/admin/register/cost-type/list",
            label: this.$i18n.t("common.costTypes"),
            icon: "mdi-credit-card-settings-outline"
        }
    }

    get branchList() {
        return {
            route: "/admin/register/branch/list",
            label: this.$i18n.t("common.branches"),
            icon: "mdi-source-branch"
        }
    }

    get tagList() {
        return {
            route: "/admin/register/tag/list",
            label: this.$i18n.t("common.contractorTags"),
            icon: "mdi-tag"
        }
    }

    get other() {
        return {
            route: "/admin/other",
            label: this.$i18n.t("common.other"),
            icon: "mdi-cog"
        }
    }
}
