import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import UserRestModel from "@/models/rest/admin/userRestModel";
import UserBranchListItem from "@/models/admin/userBranchListItem";
import { stringMaxLength } from "@/assets/validation/common";
import VForm from "@/models/v-form";
import { Roles } from "../../../models/emums/roles";

@Component
export default class Admin extends Vue {
    $refs!: {
        basicForm: VForm;
        branchesAndDepartmentsForm: VForm;
        securityForm: VForm;
    };

    userActiveTab = "";
    toggleShowPassword = false;
    selectedDepartments = [] as UserBranchListItem[];
    contractorSearch = "";
    validationErrorCount = { basic: 0, branchesAndDepartments: 0, security: 0 };

    get contractor() {
        return this.user.contractor;
    }

    set contractor(val: any) {
        this.user.contractor = val;
    }

    get contractorFound() {
        return this.$store.direct.state.common.contractorSearch;
    }

    @Watch("contractorSearch")
    contractorSearchLoadItems(val: string) {
        this.$store.direct.dispatch.common.searchContractors({ searchText: val, contractorTypes: [], contractorPropertiesEnabled: [], source: "" });
    }

    get user(): UserRestModel {
        return this.$store.direct.state.admin.user;
    }

    get userBranches() {
        const branches = [] as UserBranchListItem[];
        const allowedDepartments = [] as UserBranchListItem[];

        for (const branch of this.$store.direct.state.admin.userBranches) {
            const departments = [];
            for (const department of branch.departments) {
                const departmentItem = {
                    id: department.id,
                    isLeaf: true,
                    isDefault: false,
                    name: department.name
                } as UserBranchListItem;
                if (department.isSelected) {
                    allowedDepartments.push(departmentItem);
                }
                departments.push(departmentItem);
            }
            branches.push({
                id: "branch" + branch.id,
                isLeaf: false,
                isDefault: false,
                name: branch.name,
                children: departments.sort((a, b) => {
                    return a.name > b.name ? 1 : -1;
                })
            } as UserBranchListItem);
        }
        this.selectedDepartments = allowedDepartments;

        return branches.sort((a, b) => {
            return a.name > b.name ? 1 : -1;
        });
    }

    changeDefaultDepartmentId(branchId: number) {
        this.user.defaultDepartmentId = branchId;
    }

    get roles() {
        return this.$store.direct.state.auth.allRoles;
    }

    get saveDisabled() {
        return !this.$store.direct.state.admin.userSaveEnabled;
    }

    get isNew() {
        return this.$store.direct.state.admin.userIsNew;
    }

    get selectedUserRoles(): string[] {
        return this.user.roles != undefined ? this.user.roles : [];
    }

    get userInitialsValidationError(): string {
        const error = this.$store.direct.state.admin.userInitialsValidationError;
        this.checkIfNeedToAddBasicTabErrorFromServer(error);
        return error;
    }

    get userEmailvalidationError(): string {
        const error = this.$store.direct.state.admin.userEmailvalidationError;
        this.checkIfNeedToAddBasicTabErrorFromServer(error);
        return error;
    }

    get userRolesValidationError(): string {
        const error = this.$store.direct.state.admin.userRolesValidationError;
        this.checkIfNeedToAddBasicTabErrorFromServer(error);
        return error;
    }

    rules = {
        name: [
            stringMaxLength(this.$i18n, this.$t("user.name"), 95),
            (value: string) => !!value || this.$t("common.validationErrorRequired")
        ],
        firstName: [
            stringMaxLength(this.$i18n, this.$t("user.name"), 64),
            (value: string) => !!value || this.$t("common.validationErrorRequired")
        ],
        lastName: [
            stringMaxLength(this.$i18n, this.$t("user.name"), 64),
            (value: string) => !!value || this.$t("common.validationErrorRequired")
        ],
        initials: [
            stringMaxLength(this.$i18n, this.$t("user.initials"), 3),
            (value: string) => !this.hasInitialsWhiteSpace(value) || this.$t("common.validationErrorWhiteSpaces"),
            () => {
                const userInitialsValidationError = this.userInitialsValidationError;
                return userInitialsValidationError == "" || userInitialsValidationError;
            }
        ],
        email: [
            stringMaxLength(this.$i18n, this.$t("user.email"), 95),
            (value: string) => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value) || this.$t("common.validationErrorValidEmail");
            },
            () => {
                const userEmailvalidationError = this.userEmailvalidationError;
                return userEmailvalidationError == "" || userEmailvalidationError;
            }
        ],
        password: [
            (value: string) => {
                return (
                    (!this.isNew && (!/\S/.test(value) || value === null)) ||
                    /(?=.*[A-Z])/.test(value) ||
                    this.$t("common.validationErrorMinOneBigLetter")
                );
            }, //passBigLetter
            (value: string) =>
                (!this.isNew && (!/\S/.test(value) || value === null)) ||
                /(?=.*[a-z])/.test(value) ||
                this.$t("common.validationErrorMinOneSmallLetter"), ///passSmallLetter
            (value: string) =>
                (!this.isNew && (!/\S/.test(value) || value === null)) ||
                /(?=.*\d)/.test(value) ||
                this.$t("common.validationErrorMinOneDigit"), //passDigit
            (value: string) =>
                (!this.isNew && (!/\S/.test(value) || value === null)) ||
                /([!@#$%^&*(){}:"?><|])/.test(value) ||
                this.$t("common.validationErrorMinOneSpecialChar") //passSpecialChar
        ],
        phoneNumber: [
            stringMaxLength(this.$i18n, this.$t("user.phoneNumber"), 50),
        ],
        roles: [
            (value: any[]) => !!value[0] || this.$t("common.validationErrorRequired"),
            () => {
                const userRolesValidationError = this.userRolesValidationError;
                return userRolesValidationError == "" || userRolesValidationError;
            }
        ],
        branchesAndDepartments: [(value: any) => (!(this.checkIfSpecialRoleIsSelected() && this.user.defaultDepartmentId == 0) || this.$t("common.validationErrorRequired"))]
    };

    hasInitialsWhiteSpace(initials: string | undefined) {
        if (initials != undefined) {
            return initials.indexOf(" ") >= 0;
        }
    }

    checkIfSpecialRoleIsSelected(): boolean {
        if ([Roles.Admin, Roles.CrmAdmin, Roles.Crm, Roles.CrmLower, Roles.Disponent, Roles.DisponentAdmin, Roles.Accountant, Roles.AccountantAdmin].some(x => this.selectedUserRoles.includes(x))) {
            return true;
        }
        return false;
    }

    checkIfNeedToAddBasicTabErrorFromServer(errorFromServerToCheck: string) {
        if (errorFromServerToCheck !== "") {
            this.validationErrorCount.basic += 1;
        }
    }

    validateBasicTab(): number {
        let errorCount = 0;
        this.$refs.basicForm.validate();
        this.$refs.basicForm.inputs.forEach((input: any) => {
            if (input.validate() === false) ++errorCount;
        });
        return errorCount;
    }

    validateBranchesAndDepartmentsTab(): number {
        let errorCount = 0;
        this.$refs.branchesAndDepartmentsForm.validate();
        this.$refs.branchesAndDepartmentsForm.inputs.forEach((input: any) => {
            if (input.validate() === false) ++errorCount;
        });
        return errorCount;
    }

    async saveUser() {
        this.$store.direct.commit.admin.setUserValidationError(null);

        this.validationErrorCount.basic = this.validateBasicTab();
        this.validationErrorCount.branchesAndDepartments = this.validateBranchesAndDepartmentsTab();

        if (this.validationErrorCount.basic > 0 || this.validationErrorCount.branchesAndDepartments > 0) return;

        const validBasic = (this.$refs.basicForm as Vue & { validate: () => boolean }).validate();
        const validBranchesAndDepartments = (this.$refs.branchesAndDepartmentsForm as Vue & { validate: () => boolean }).validate();
        const validSecurity = (this.$refs.securityForm as Vue & { validate: () => boolean }).validate();

        if (validBasic && validBranchesAndDepartments && validSecurity) {

            for (const branch of this.$store.direct.state.admin.userBranches) {
                for (const department of branch.departments) {
                    if (this.selectedDepartments.some(x => x.id === department.id)) {
                        department.isSelected = true;
                    } else {
                        department.isSelected = false;
                    }
                }
            };
        };

        if (this.user.contractor != undefined && this.contractorSearch != this.user.contractor.text) {
            this.user.contractor = undefined;
        }

        await this.$store.direct.dispatch.admin.saveUser();

        if (this.$refs.basicForm != undefined) {
            const validBasic = (this.$refs.basicForm as Vue & { validate: () => boolean }).validate();
        }
        if (this.$refs.branchesAndDepartmentsForm != undefined) {
            const validBranchesAndDepartments = (this.$refs.branchesAndDepartmentsForm as Vue & { validate: () => boolean }).validate();
        }
        if (this.$refs.securityForm != undefined) {
            const validSecurity = (this.$refs.securityForm as Vue & { validate: () => boolean }).validate();
        }

    }
    cancelClick() {
        this.$router.push({ path: "/admin/user/list" });
    }

    async loadUser(id: string): Promise<void> {
        await this.$store.direct.dispatch.admin.loadUser({ id: id });
    }
    async created() {
        await this.loadUser(this.$route.params["id"]);
    }
}
