import Vue from "vue";
import { Component } from "vue-property-decorator";
import ContractorDocumentRestModel from "@/models/rest/contractor/contractorDocumentRestModel";
import { Roles } from "../../../models/emums/roles";

@Component
export default class DocmunetTab extends Vue {
    documentListHeaders = [
        { text: this.$t("contractor.file"), align: "start", value: "name" },
        { text: this.$t("contractor.section"), align: "start", value: "section" },
        { text: this.$t("common.createdAt"), align: "start", value: "createdAt" },
        { text: this.$t("common.createdBy"), align: "start", value: "createdBy" },
        { text: this.$t("contractor.actions"), align: "right", value: "actions", width: "1%" }
    ];

    get isContractorNew(): boolean {
        return this.$store.direct.state.contractor.isContractorNew;
    }

    get allowContractorEdit(): boolean {
        return this.$store.direct.state.contractor.allowContractorEdit;
    }

    get currentUserRoles() {
        return this.$store.direct.state.auth.userRoles;
    }

    isUserCrmOrCrmOrCrmLowerAdmin(): boolean {
        if (![Roles.CrmAdmin, Roles.Crm, Roles.CrmLower].some(x => this.currentUserRoles.includes(x))) {
            return false;
        }
        return true;
    }

    get allowContractorOperationAddOrEdit() {
        return this.allowContractorEdit || this.isUserCrmOrCrmOrCrmLowerAdmin;
    }

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get documentList(): ContractorDocumentRestModel[] {
        return this.$store.direct.state.contractor.documentList;
    }

    set documentList(val: ContractorDocumentRestModel[]) {
        this.$store.direct.commit.contractor.setDocumentList(val);
    }

    get currentDocumentId(): number {
        return this.$store.direct.state.contractor.currentDocumentId;
    }

    async documentCreateClick() {
        if (this.isContractorNew == false) {
            await this.$store.direct.dispatch.contractor.loadDocument();
        }
        else {
            this.$store.direct.dispatch.confirm.openConfirmDialog(
                {
                    showConfirmDialog: true,
                    title: this.$t("contractor.savingContractorIsRequired").toString(),
                    message: this.$t("contractor.saveContractorIsRequiredForThisAction").toString(),
                    links: [],
                    options: { buttonColor: "error", layoutColor: "error" },
                    buttonType: "ok"
                });
        }
    }

    async documentDownloadClick(model: ContractorDocumentRestModel) {
        await this.$store.direct.dispatch.contractor.downloadDocument({ id: model.id });
    }

    deleteDocumentRequestClick(model: ContractorDocumentRestModel) {
        this.$store.direct.commit.contractor.setCurrentDocumentId(model.id);
        this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: "Delete",
                message: "Do you want to send a request to the administrator to delete the file?",
                links: [],
                options: { buttonColor: "error", layoutColor: "red" },
                buttonType: "yes/no"
            })
            .then(confirm => {
                if (confirm) {
                    this.$store.direct.dispatch.contractor.deleteDocumentRequest({ id: this.currentDocumentId });
                }
            });
    }

    destroyed() {
        this.documentList = [] as ContractorDocumentRestModel[];
    }
}
