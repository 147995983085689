import Vue from "vue";
import { Component } from "vue-property-decorator";
import SupervisorPlaceRestModel from "../../../models/rest/supervisor/supervisorPlaceRestModel";

@Component
export default class CheckPlacesDialog extends Vue {
    supervisorPlaceForCheckListHeaders = [
        {
            text: this.$t("supervisor.orderType"),
            align: "start",
            value: "typeId",
            sortable: false
        },
        {
            text: this.$t("supervisor.name"),
            align: "start",
            value: "name",
            sortable: false
        },
        {
            text: this.$t("supervisor.place"),
            align: "start",
            value: "address",
            sortable: false
        },
        {
            text: this.$t("supervisor.checked"),
            align: "right",
            value: "actions",
            width: "1%",
            sortable: false
        }
    ];

    get supervisorPlaceForCheckList(): SupervisorPlaceRestModel[] {
        const supervisorPlaceForCheckList = this.$store.direct.state.supervisor.supervisorPlaceForCheckList;
        supervisorPlaceForCheckList.map((item: SupervisorPlaceRestModel) => {
            item.arrivedToPlace = item.arrivalTime != undefined ? true : false;
        });

        return supervisorPlaceForCheckList;
    }

    get supervisorPlaceOrderType(): any[] {
        return this.$store.direct.state.supervisor.supervisorPlaceOrderType;
    }

    get isSupervisorCustomChecked(): any {
        return this.$store.direct.state.supervisor.isSupervisorCustomChecked;
    }

    get showSupervisorPlaceForCheckDialog(): boolean {
        return this.$store.direct.state.supervisor.showSupervisorPlaceForCheckDialog;
    }

    set showSupervisorPlaceForCheckDialog(value: boolean) {
        this.$store.direct.commit.supervisor.setShowSupervisorPlaceForCheckDialog(value);
    }

    async hideDialog() {
        await this.$store.direct.commit.supervisor.setShowSupervisorPlaceForCheckDialog(false);
    }

    getSupervisorPlaceOrderTypeString(typeId: number) {
        return typeId == 1 ? this.supervisorPlaceOrderType[0].text : this.supervisorPlaceOrderType[1].text
    }

    changeSupervisorPlaceArrivalTime(item: SupervisorPlaceRestModel) {
        this.$store.direct.dispatch.supervisor.changeSupervisorPlaceArrivalTime({ supervisorPlaceId: item.id as number, isChecked: item.arrivedToPlace });
    }

    async changeCustomChecked() {
        await this.$store.direct.dispatch.supervisor.changeSupervisorCustomCheck({ supervisorId: this.isSupervisorCustomChecked.supervisorId, isChecked: this.isSupervisorCustomChecked.isCustomChecked });

        await this.$emit("loadSupervisorListFromCheckPlacesDialog");
    }
}
