import dayjs from "dayjs";
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import i18n from "../../../assets/i18n";
import ConfirmDialog from "../../../layouts/confirmDialog/confirmDialog.vue";
import { SettlementPaymentStatusEnum } from "../../../models/emums/settlement/settlementPaymentStatusEnum";
import { SettlementPaymentTypeEnum } from "../../../models/emums/settlement/settlementPaymentTypeEnum";
import ListItemNumberModel from "../../../models/listItemNumberModel";
import ContractorDetailsForSettlementRestModel from "../../../models/rest/contractor/contractorDetailsForSettlementRestModel";
import ContractorSearchItemModel from "../../../models/rest/contractor/contractorSearchItemModel";
import CurrencyRestModel from "../../../models/rest/register/currencyRestModel";
import SettlementCostDocumentPaymentObligationListItemRestModel from "../../../models/rest/settlement/cost-document/payment-obligation/settlementCostDocumentPaymentObligationListItemRestModel";
import SettlementCostDocumentPaymentObligationListRequestModel from "../../../models/rest/settlement/cost-document/payment-obligation/settlementCostDocumentPaymentObligationListRequestModel";
import SettlementCostDocumentRestModel from "../../../models/rest/settlement/cost-document/settlementCostDocumentRestModel";
import SettlementPaymentCostDocumentRestModel from "../../../models/rest/settlement/payment/settlementPaymentCostDocumentRestModel";
import SettlementPaymentRestModel from "../../../models/rest/settlement/payment/settlementPaymentRestModel";
import SettlementAmountSummaryRestModel from "../../../models/rest/settlement/settlementAmountSummaryRestModel";
import SettlementDocumentStatusRestModel from "../../../models/rest/settlement/settlementDocumentStatusRestModel";
import CostPaymentDialog from "../cost-payment/cost-payment-dialog.vue";
import CreatePaymentOutsideTheSystemDialog from "../create-payment-outside-the-system-dialog.vue";
import SettlementAmountSummaryRow from "../settlement-amount-summary-row.vue";


@Component({
    components: {
        "confirm-dialog": ConfirmDialog,
        "cost-payment-dialog": CostPaymentDialog,
        "create-payment-outside-the-system-dialog": CreatePaymentOutsideTheSystemDialog,
        "settlement-amount-summary-row": SettlementAmountSummaryRow,
    }
})

export default class CostDocumentPaymentObligationList extends Vue {
    SettlementPaymentTypeEnum = SettlementPaymentTypeEnum;
    contractorSearch = "";
    paymentTermDateStartLabel = this.$t("paymentObligation.paymentTermDateStart");
    paymentTermDateEndLabel = this.$t("paymentObligation.paymentTermDateEnd");
    mainCheckboxIsSelected = false;
    disableCreateTransferOrdersButton = false;
    disableAddPaymentOutsideTheSystemButton = false;
    showAmountSummaryRowForCalculatedSelectedList = false;

    paymentObligationListHeaders = [
        {
            align: "start",
            value: "checkbox",
            width: "1%",
            sortable: false,
        },
        {
            text: "Id",
            align: "center",
            value: "id",
            width: "1%",
            sortable: true,
        },
        {
            text: this.$t("paymentObligation.documentNumber"),
            align: "start",
            value: "documentNumber",
            sortable: true,
        },
        {
            text: this.$t("paymentObligation.documentStatus"),
            align: "start",
            value: "costDocumentStatusName",
            sortable: true,
        },
        {
            text: this.$t("paymentObligation.correction"),
            align: "center",
            value: "isCorrection",
            sortable: true,
        },
        {
            text: this.$t("costDocument.paymentType"),
            align: "start",
            value: "paymentTypeId",
            sortable: true,
        },
        {
            text: this.$t("supervisor.orderId"),
            align: "start",
            value: "supervisorOrderIdcsString",
            sortable: false,
        },
        {
            text: this.$t("contractor.branch"),
            align: "start",
            value: "branchId",
            sortable: true,
        },
        {
            text: this.$t("paymentObligation.contractorName"),
            align: "start",
            value: "contractorName",
            sortable: true,
        },
        {
            text: this.$t("paymentObligation.contractorTin"),
            align: "start",
            value: "contractorTinWithPrefix",
            sortable: true,
        },
        {
            text: this.$t("paymentObligation.vatInPln"),
            align: "center",
            value: "isVatInPln",
            sortable: true,
        },
        {
            text: this.$t("paymentObligation.paymentTermDate"),
            align: "start",
            value: "paymentTermDate",
            sortable: true,
        },
        {
            text: "",
            align: "center",
            value: "paymentStatus",
            sortable: true,
        },
        {
            text: this.$t("paymentObligation.amount"),
            align: "end",
            value: "paymentAmount",
            sortable: true,
        },
        {
            text: this.$t("paymentObligation.currency"),
            align: "start",
            value: "currencyCode",
            sortable: true,
        },
        {
            text: this.$t("costDocument.paymentDaysLeft"),
            align: "start",
            value: "paymentDaysLeft",
            width: "1%",
            sortable: true,
        },
        {
            text: this.$t("paymentObligation.packageNumber"),
            align: "start",
            value: "packageNumberString",
            width: "1%",
            sortable: false,
        },
        {
            text: this.$t("common.actions"),
            align: "right",
            value: "actions",
            width: "1%",
            sortable: false,
        }
    ];

    costPaymentListHeaders = [
        {
            text: "Id",
            align: "start",
            value: "id",
            width: "1%",
            sortable: true,
        },
        {
            text: this.$t("costDocument.paymentType"),
            align: "start",
            value: "paymentTypeId",
            sortable: false,
        },
        {
            text: this.$t("payment.amount"),
            align: "start",
            value: "paymentAmount",
            sortable: false,
        },
        {
            text: this.$t("payment.currency"),
            align: "start",
            value: "currencyCode",
            sortable: false,
        },
        {
            text: this.$t("common.createdBy"),
            align: "start",
            value: "createdByUserName",
            sortable: false,
        },
        {
            text: this.$t("payment.paymentDate"),
            align: "start",
            value: "paymentDate",
            sortable: false,
        },
    ];

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get paymentObligationListLoading(): boolean {
        return this.$store.direct.state.settlementCostDocumentPaymentObligation.paymentObligationListLoading;
    }

    get paymentObligationList(): SettlementCostDocumentPaymentObligationListItemRestModel[] {
        return this.$store.direct.state.settlementCostDocumentPaymentObligation.paymentObligationList;
    }

    get paymentObligationListTotalCount(): number {
        return this.$store.direct.state.settlementCostDocumentPaymentObligation.paymentObligationListTotalCount;
    }

    get pagerOptions(): any {
        return this.$store.direct.state.settlementCostDocumentPaymentObligation.paymentObligationListPagerOptions;
    }

    set pagerOptions(value: any) {
        this.$store.direct.commit.settlementCostDocumentPaymentObligation.setPaymentObligationListPagerOptions(value);
    }

    get paymentObligationListSearchText(): string {
        return this.$store.direct.state.settlementCostDocumentPaymentObligation.paymentObligationListSearchText;
    }

    set paymentObligationListSearchText(value: string) {
        this.$store.direct.commit.settlementCostDocumentPaymentObligation.setPaymentObligationListSearchText(value);
    }

    get selectedBranchId(): number {
        return this.$store.direct.state.settlementCostDocumentPaymentObligation.paymentObligationListSearchBranchId;
    }

    set selectedBranchId(value: number) {
        this.$store.direct.commit.settlementCostDocumentPaymentObligation.setPaymentObligationListSearchBranchId(value);
    }

    get searchDocumentStatusIdcs(): number[] {
        return this.$store.direct.state.settlementCostDocumentPaymentObligation.paymentObligationListSearchDocumentStatusIdcs;
    }

    set searchDocumentStatusIdcs(value: number[]) {
        this.$store.direct.commit.settlementCostDocumentPaymentObligation.setPaymentObligationListSearchDocumentStatusIdcs(value);
    }

    get selectedCurrency(): CurrencyRestModel {
        return this.$store.direct.state.settlementCostDocumentPaymentObligation.paymentObligationListSearchCurrency;
    }

    set selectedCurrency(value: CurrencyRestModel) {
        this.$store.direct.commit.settlementCostDocumentPaymentObligation.setPaymentObligationListSearchCurrency(value);
    }

    get searchContractors(): ContractorSearchItemModel[] {
        return this.$store.direct.state.settlementCostDocumentPaymentObligation.paymentObligationListSearchContractors;
    }

    set searchContractors(value: ContractorSearchItemModel[]) {
        this.$store.direct.commit.settlementCostDocumentPaymentObligation.setPaymentObligationListSearchContractors(value);
    }

    get searchContractorIdcs(): number[] {
        return this.$store.direct.state.settlementCostDocumentPaymentObligation.paymentObligationListSearchContractorIdcs;
    }

    set searchContractorIdcs(value: number[]) {
        this.$store.direct.commit.settlementCostDocumentPaymentObligation.setPaymentObligationListSearchContractorIdcs(value);
    }

    get searchPaymentTermDateStart(): string {
        return this.$store.direct.state.settlementCostDocumentPaymentObligation.paymentObligationListSearchPaymentTermDateStart;
    }

    set searchPaymentTermDateStart(value: string) {
        this.$store.direct.commit.settlementCostDocumentPaymentObligation.setPaymentObligationListSearchPaymentTermDateStart(value);
    }

    get searchPaymentTermDateEnd(): string {
        return this.$store.direct.state.settlementCostDocumentPaymentObligation.paymentObligationListSearchPaymentTermDateEnd;
    }

    set searchPaymentTermDateEnd(value: string) {
        this.$store.direct.commit.settlementCostDocumentPaymentObligation.setPaymentObligationListSearchPaymentTermDateEnd(value);
    }

    get selectedOnlyExpired(): boolean {
        return this.$store.direct.state.settlementCostDocumentPaymentObligation.paymentObligationListSearchOnlyExpired;
    }

    set selectedOnlyExpired(value: boolean) {
        this.$store.direct.commit.settlementCostDocumentPaymentObligation.setPaymentObligationListSearchOnlyExpired(value);
    }

    get searchExpiredDaysSince(): string {
        return this.$store.direct.state.settlementCostDocumentPaymentObligation.paymentObligationListSearchExpiredDaysSince;
    }

    set searchExpiredDaysSince(value: string) {
        this.$store.direct.commit.settlementCostDocumentPaymentObligation.setPaymentObligationListSearchExpiredDaysSince(value);
    }

    get searchExpiredDaysTo(): string {
        return this.$store.direct.state.settlementCostDocumentPaymentObligation.paymentObligationListSearchExpiredDaysTo;
    }

    set searchExpiredDaysTo(value: string) {
        this.$store.direct.commit.settlementCostDocumentPaymentObligation.setPaymentObligationListSearchExpiredDaysTo(value);
    }

    get footerPropsForSettlement(): any {
        return this.$store.direct.state.settlementCommon.footerPropsForSettlement;
    }

    get costDocumentPaymentObligationListAmountSummaryList(): SettlementAmountSummaryRestModel[] {
        let amountSummary = this.$store.direct.state.settlementCostDocumentPaymentObligation.paymentObligationListAmountSummaryList;

        if (amountSummary == undefined) {
            amountSummary = [] as SettlementAmountSummaryRestModel[];
        }

        return amountSummary;
    }

    get contractorFound(): ContractorSearchItemModel[] {
        return this.$store.direct.state.common.contractorSearch;
    }

    get currencies(): CurrencyRestModel[] {
        const currencies = this.$store.direct.state.contractor.contractorAssets.currencies;
        if (currencies == undefined) {
            this.$store.direct.dispatch.contractor.loadContractorAssets();
        }
        else {
            currencies.push({ code: i18n.t("common.all"), name: "" } as CurrencyRestModel)
        }

        return currencies;
    }

    get branchList(): ListItemNumberModel[] {
        const branchList = this.branches;
        let clonedBranchList = [] as ListItemNumberModel[];
        if (branchList == undefined) {
            this.$store.direct.dispatch.contractor.loadContractorAssets();
        }
        else {
            clonedBranchList = [...branchList];
            clonedBranchList.push({ value: 0, text: i18n.t("common.all") } as ListItemNumberModel);
        }
        return clonedBranchList;
    }

    get branches(): ListItemNumberModel[] {
        const branches = this.$store.direct.state.contractor.contractorAssets.branches;
        if (branches == undefined) {
            this.$store.direct.dispatch.contractor.loadContractorAssets();
        }
        return branches;
    }

    get paymentTypeList(): ListItemNumberModel[] {
        return this.$store.direct.state.settlementCommon.settlementAssets.paymentTypes;
    }

    get costPayment(): SettlementPaymentRestModel {
        return this.$store.direct.state.settlementPayment.payment;
    }

    set costPayment(val: SettlementPaymentRestModel) {
        this.$store.direct.commit.settlementPayment.setPayment(val);
    }

    get costDocument(): SettlementCostDocumentRestModel {
        return this.$store.direct.state.settlementCostDocument.costDocument;
    }

    get selectedPaymentObligationList(): SettlementCostDocumentPaymentObligationListItemRestModel[] {
        return this.$store.direct.state.settlementCostDocumentPaymentObligation.selectedPaymentObligationList;
    }

    set selectedPaymentObligationList(val: SettlementCostDocumentPaymentObligationListItemRestModel[]) {
        this.$store.direct.commit.settlementCostDocumentPaymentObligation.setSelectedPaymentObligationList(val);
    }

    get allowSelectionOfAllItemsOnPageWithCheckbox(): boolean {
        const allowSelection = this.$store.direct.state.settlementCommon.allowSelectionOfAllItemsOnPageWithCheckbox;
        if (!allowSelection) {
            this.mainCheckboxIsSelected = false;
            this.selectedPaymentObligationList = [] as SettlementCostDocumentPaymentObligationListItemRestModel[];
            this.paymentObligationList.forEach((x: SettlementCostDocumentPaymentObligationListItemRestModel) => {
                x.isCheckedForCreatingTransferOrder = false;
            });
            this.calculatedSelectedListForAmountSummaryRow = [] as SettlementAmountSummaryRestModel[];
        }
        return allowSelection;
    }

    set allowSelectionOfAllItemsOnPageWithCheckbox(val: boolean) {
        this.$store.direct.commit.settlementCommon.setAllowSelectionOfAllItemsOnPageWithCheckbox(val);
    }

    get calculatedSelectedListForAmountSummaryRow(): SettlementAmountSummaryRestModel[] {
        return this.$store.direct.state.settlementCommon.calculatedSelectedListForAmountSummaryRow;
    }

    set calculatedSelectedListForAmountSummaryRow(val: SettlementAmountSummaryRestModel[]) {
        this.$store.direct.commit.settlementCommon.setCalculatedSelectedListForAmountSummaryRow(val);
    }

    get contractorDetailsForSettlement(): ContractorDetailsForSettlementRestModel {
        return this.$store.direct.state.contractor.contractorDetailsForSettlement;
    }

    get costDocumentStatuses(): SettlementDocumentStatusRestModel[] {
        return this.$store.direct.state.settlementCommon.settlementAssets.costDocumentStatuses;
    }

    @Watch("pagerOptions", { deep: true })
    pagerOptionsHandles() {
        this.loadPaymentObligationList();
    }

    @Watch("contractorSearch")
    async contractorSearchLoadItems(val: string) {
        await this.$store.direct.dispatch.common.searchContractors({ searchText: val, contractorTypes: [], contractorPropertiesEnabled: [], source: "" });
        if (this.searchContractors.length > 0) {
            this.searchContractors.forEach((x: any) => {
                if (!this.contractorFound.includes(x)) {
                    this.contractorFound.push(x);
                }
            })
        }
    }

    async created() {
        this.selectedPaymentObligationList = [];
        this.$store.direct.dispatch.contractor.loadContractorAssets();
        this.$store.direct.dispatch.settlementCommon.loadSettlementAssets();
        this.loadPaymentObligationList();
    }

    getListItemStyle(item: SettlementCostDocumentPaymentObligationListItemRestModel) {
        if (item.paymentDaysLeft == null) {
            return;
        }
        else if (item.paymentDaysLeft < 0) {
            return "red--text"
        }
        else if (item.paymentDaysLeft == 0) {
            return "green--text"
        }
        else if (item.paymentDaysLeft < 4) {
            return "blue--text"
        }
        else {
            return;
        }
    }

    itemRowTextStyle(item: SettlementCostDocumentPaymentObligationListItemRestModel) {
        let style = ""
        item.isCorrection == true ? style += "font-italic font-weight-black " : "";
        item.hasCorrection == true ? style += "font-italic " : "";
        return style;
    }

    formatDate(dateTime: any) {
        if (dateTime != null) {
            return dayjs(dateTime).format("YYYY-MM-DD");
        }
    }

    formatBranchIdToBranchName(branchId: any) {
        if (this.branches != undefined) {
            return this.branches.find(x => x.value == branchId)?.text;
        }
    }

    formatPaymentTypeIdToPaymentName(paymentTypeId: any) {
        if (this.paymentTypeList != undefined) {
            return this.paymentTypeList.find(x => x.value == paymentTypeId)?.text;
        }
    }

    getPaymentStatusIconColor(item: SettlementCostDocumentPaymentObligationListItemRestModel): string {
        if (item.costDocumentPaymentStatusId == SettlementPaymentStatusEnum.Niezaplacona) {
            return "red--text";
        }
        if (item.costDocumentPaymentStatusId == SettlementPaymentStatusEnum.ZaplataCzesciowa) {
            return "blue--text";
        }
        else if (item.costDocumentPaymentStatusId == SettlementPaymentStatusEnum.Zaplacona) {
            return "green--text";
        }
        else if (item.costDocumentPaymentStatusId == SettlementPaymentStatusEnum.Nadplata) {
            return "yellow--text";
        }
        else {
            return "";
        }
    }

    searchClick() {
        this.loadPaymentObligationList();
    }

    clearSearchContractorClick() {
        this.searchContractorIdcs = [] as number[];
        this.loadPaymentObligationList();
    }

    clearDateTimePicker(clearableValue: string) {
        if (clearableValue == "searchPaymentTermDateStartValue") {
            this.searchPaymentTermDateStart = "";
        }
        if (clearableValue == "searchPaymentTermDateEndValue") {

            this.searchPaymentTermDateEnd = "";
        }
        this.loadPaymentObligationList();
    }

    clearAllFilters() {
        this.paymentObligationListSearchText = "";
        this.selectedCurrency = {} as CurrencyRestModel;
        this.searchContractorIdcs = [] as number[];
        this.searchContractors = [] as ContractorSearchItemModel[];
        this.selectedBranchId = 0;
        this.searchDocumentStatusIdcs = [] as number[];
        this.selectedOnlyExpired = false;
        this.searchPaymentTermDateStart = "";
        this.searchPaymentTermDateEnd = "";
        this.searchExpiredDaysSince = "";
        this.searchExpiredDaysTo = "";

        this.loadPaymentObligationList();
    }

    async createPaymentClick(paymentObligation: SettlementCostDocumentPaymentObligationListItemRestModel) {
        await this.$store.direct.dispatch.settlementCostDocument.loadCostDocument({ costDocumentId: paymentObligation.costDocumentId });

        const totalPaymentAmount = this.costDocument.paymentObligationList.length == 1 ? this.costDocument.paymentObligationList[0].paymentAmount : 0;
        const paymentCurrencyCode = this.costDocument.paymentObligationList.length == 1 ? this.costDocument.paymentObligationList[0].paymentCurrencyCode : undefined;

        await this.$store.direct.dispatch.settlementPayment.loadCostPayment({ paymentGroupId: "" });

        this.costPayment.paymentAmount = totalPaymentAmount;
        if (paymentCurrencyCode != undefined) {
            this.costPayment.currencyCode = paymentCurrencyCode;
        }

        this.costPayment.paymentCostDocumentRequestList = [{ costDocument: { ...this.costDocument } as any as SettlementCostDocumentRestModel, paymentAmount: 0 } as SettlementPaymentCostDocumentRestModel];

        this.$store.direct.dispatch.settlementPayment
            .openCostPaymentDialog({
                title: `${this.$t("payment.addCostPayment")}`,
            })
            .then(async confirm => {
                if (confirm) {
                    this.loadPaymentObligationList();
                }
            })
    }

    showPaymentObligationCostDocument(item: SettlementCostDocumentPaymentObligationListItemRestModel) {
        if (item.isCorrection) {
            return "/settlement/cost-document/correction/edit/" + item.costDocumentId;

        }
        else {
            return "/settlement/cost-document/edit/" + item.costDocumentId;
        }
    }

    addSelectedPaymentObligationToList() {
        this.selectedPaymentObligationList = this.paymentObligationList.filter((x: SettlementCostDocumentPaymentObligationListItemRestModel) => {
            if (x.isCheckedForCreatingTransferOrder) {
                return x.id;
            }
            else {
                this.mainCheckboxIsSelected = false;
            }
        });

        this.disableAddPaymentOutsideTheSystemButton = !this.checkIfIsAllowToCreatePaymentOutsideTheSystem(this.selectedPaymentObligationList);
        this.disableCreateTransferOrdersButton = !this.checkIfIsAllowToCreateTransferOrders(this.selectedPaymentObligationList);

        this.$store.direct.dispatch.settlementCommon.calculateSelectedListWithCurrencyCodeAndPaymentAmountValuesForAmountSummaryRow({ list: this.selectedPaymentObligationList });
    }

    selectAllItemsOnThePage() {
        this.paymentObligationList.forEach((x: SettlementCostDocumentPaymentObligationListItemRestModel) => {
            if ((!x.hasNotCancelledTransferOrders && x.paymentTypeId != SettlementPaymentTypeEnum.KOMPENSATA && !x.isSplitPayment) || this.allowSelectionOfAllItemsOnPageWithCheckbox) {
                x.isCheckedForCreatingTransferOrder = this.mainCheckboxIsSelected;
            }
        });
        this.$forceUpdate();

        this.selectedPaymentObligationList = this.paymentObligationList.filter((x: SettlementCostDocumentPaymentObligationListItemRestModel) => {
            if (x.isCheckedForCreatingTransferOrder) {
                return x.id;
            }
        });

        this.$store.direct.dispatch.settlementCommon.calculateSelectedListWithCurrencyCodeAndPaymentAmountValuesForAmountSummaryRow({ list: this.selectedPaymentObligationList });
    }

    checkIfIsAllowToCreatePaymentOutsideTheSystem(list: SettlementCostDocumentPaymentObligationListItemRestModel[]): boolean {
        for (const item of list) {
            if (item.allowCreatePaymentOutsideTheSystem === false) {
                return false;
            }
        }
        return true;
    }

    checkIfIsAllowToCreateTransferOrders(list: SettlementCostDocumentPaymentObligationListItemRestModel[]): boolean {
        for (const item of list) {
            if (item.allowCreateTransferOrder === false) {
                return false;
            }
        }
        return true;
    }

    async createTransferOrdersFromPaymentObligationList() {
        const contractorIdcs = [...new Set(this.selectedPaymentObligationList.map(item => item.contractorId))];
        await this.showContractorAccountingInfoDialog(contractorIdcs);
        await this.$store.direct.dispatch.settlementCostDocumentTransferOrder.createTransferOrdersFromPaymentObligationList({ selectedPaymentObligationList: this.selectedPaymentObligationList });
        this.selectedPaymentObligationList = [] as SettlementCostDocumentPaymentObligationListItemRestModel[];
        this.loadPaymentObligationList();
    }

    async addPaymentOutsideTheSystemFromSettlementCostDocumentPaymentObligationList() {
        this.$store.direct.dispatch.settlementCommon
            .openCreatePaymentOutsideTheSystemDialog({
                title: `${this.$t("costDocument.addPaymentOutsideTheSystem")}`,
                source: "costDocumentPaymentObligationList",
            })
            .then(async confirm => {
                if (confirm) {
                    await this.$store.direct.dispatch.settlementPayment.addPaymentOutsideTheSystemFromSettlementCostDocumentPaymentObligationList({ selectedPaymentObligationList: this.selectedPaymentObligationList });
                    this.selectedPaymentObligationList = [] as SettlementCostDocumentPaymentObligationListItemRestModel[];
                    this.loadPaymentObligationList();
                }
            });
    }

    async showContractorAccountingInfoDialog(contractorIdcs: number[]) {
        for (const contractorId of contractorIdcs) {
            await this.$store.direct.dispatch.contractor.getContractorDetailsForSettlement({ contractorId: contractorId });
            if (this.contractorDetailsForSettlement.accountingInfo != null) {

                await this.$store.direct.dispatch.confirm
                    .openConfirmDialog({
                        showConfirmDialog: true,
                        title: `${this.$t("contractor.accountingInfo")}` + ": " + this.contractorDetailsForSettlement.name,
                        message: this.contractorDetailsForSettlement.accountingInfo,
                        links: [],
                        options: { buttonColor: "error", layoutColor: "red" },
                        buttonType: "ok"
                    });
            }
        }
    }

    async loadPaymentObligationList() {
        if (!this.selectedCurrency.name) {
            this.selectedCurrency = {} as CurrencyRestModel;
        }

        await this.$store.direct.dispatch.settlementCostDocumentPaymentObligation.loadPaymentObligationList({
            requestModel: this.prepareCostDocumentPaymentObligationRequestModel()
        });
    }

    createXlsReportClick() {
        this.$store.direct.dispatch.settlementCostDocumentPaymentObligation.downloadXlsReportSettlementCostDocumentPaymentObligationList({
            requestModel: this.prepareCostDocumentPaymentObligationRequestModel()
        });
    }

    prepareCostDocumentPaymentObligationRequestModel(): SettlementCostDocumentPaymentObligationListRequestModel {
        return {
            page: this.pagerOptions.page,
            pageSize: this.pagerOptions.itemsPerPage,
            sortBy: this.pagerOptions.sortBy[0],
            sortDesc: this.pagerOptions.sortDesc[0],
            searchText: this.paymentObligationListSearchText,
            searchCurrencyCode: this.selectedCurrency.code,
            searchContractorIdcs: (this.searchContractors != undefined && this.searchContractors.length > 0) ? this.searchContractors.map(x => x.value) : [],
            searchBranchId: this.selectedBranchId,
            searchDocumentStatusIdcs: this.searchDocumentStatusIdcs,
            searchOnlyExpired: this.selectedOnlyExpired,
            searchExpiredDaysSince: this.searchExpiredDaysSince,
            searchExpiredDaysTo: this.searchExpiredDaysTo,
            searchPaymentTermDateStart: this.searchPaymentTermDateStart,
            searchPaymentTermDateEnd: this.searchPaymentTermDateEnd,
            idList: this.selectedPaymentObligationList.map((x: SettlementCostDocumentPaymentObligationListItemRestModel) => {
                return x.id;
            }),
        } as SettlementCostDocumentPaymentObligationListRequestModel
    }

    currentPageInput = this.pagerOptions.page;
    goToPage() {
        if (this.currentPageInput > 0) {
            this.pagerOptions.page = this.currentPageInput;
        }
    }

    destroyed() {
        this.selectedPaymentObligationList = [] as SettlementCostDocumentPaymentObligationListItemRestModel[];
    }
}
