import { defineModule } from "direct-vuex";
import store, { moduleActionContext } from "@/store/index";
import { AxiosResponse, AxiosError } from "axios";
import { axiosInstance } from "../../plugins/axios";
import { ApexOptions } from "apexcharts";
import DashboardGraphOperationSerieModel from "../../models/rest/dashboard/dashboardGraphOperationSerieModel";
import DashboardOperationGraphItemModel from "../../models/rest/dashboard/dashboardOperationGraphItemModel";
import dayjs from "dayjs";


export interface DashboardModuleStateInterface {
    detailedSeries: DashboardGraphOperationSerieModel[];
    operationAdminGraphs: any[];
    operationAdminGraphOptions: any;
    isLogoVisible: boolean;
    isOperationAdminGraphVisible: boolean;
    xAxisInitialMinDate: string;
    xAxisInitialMaxDate: string;
    xAxisMinDate: string;
    xAxisMaxDate: string;
    dashboardOperationUsersSelected: string[];
}

const dashboardModule = defineModule({
    namespaced: true,
    state: {
        dashboardOperationUsersSelected: [] as string[],
        detailedSeries: [] as DashboardGraphOperationSerieModel[],
        operationAdminGraphs: [] as any[],
        operationAdminGraphOptions: {
            title: { text: "All" as string } as ApexTitleSubtitle,
            chart: {
                id: "operationAdminGraphAll",
                type: "bar",
                stacked: true,
                height: 200,
                events: {
                    beforeZoom: function (chart: any, options: { xaxis: any }) {
                        store.commit.dashboard.setXAxisMinDate(dayjs(new Date(options.xaxis.min)).format("YYYY-MM-DD HH:mm:ss"));
                        store.commit.dashboard.setXAxisMaxDate(dayjs(new Date(options.xaxis.max)).format("YYYY-MM-DD HH:mm:ss"));
                        store.dispatch.dashboard.operationAdminGraphUpdate();
                    },
                    beforeResetZoom: function (chart: any, options: { xaxis: any }) {
                        if (store.state.dashboard.xAxisInitialMinDate != store.state.dashboard.xAxisMinDate && store.state.dashboard.xAxisInitialMaxDate != store.state.dashboard.xAxisMaxDate) {
                            store.commit.dashboard.setXAxisMinDate(store.state.dashboard.xAxisInitialMinDate);
                            store.commit.dashboard.setXAxisMaxDate(store.state.dashboard.xAxisInitialMaxDate);
                            store.dispatch.dashboard.operationAdminGraphUpdate();
                        }
                    },
                },
                toolbar: {
                    tools: {
                        pan: false
                    }
                }
            } as ApexChart,
            yaxis: {
                min: 0,
                tickAmount: 3
            } as ApexYAxis,
            xaxis: {
                type: "datetime",
                categories: [] as any
            } as ApexXAxis,
            colors: ["#3366cc", "#dc3912", "#ff9900", "#109618", "#990099", "#0099c6", "#dd4477", "#66aa00", "#b82e2e", "#316395", "#994499"] as any[],
            tooltip: {
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                    const quantity = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
                    const serieName = w.globals.initialSeries[seriesIndex].name;
                    const detailedSeriesLocal = store.state.dashboard.detailedSeries as DashboardGraphOperationSerieModel[];
                    const xAxisCategoryValue = w.config.xaxis.categories[dataPointIndex].substring(0, w.config.xaxis.categories[dataPointIndex].length - 4);
                    const xAxisCategoryValueArray = xAxisCategoryValue.split("/");
                    const xAxisCategoryFormattedValue = xAxisCategoryValueArray[1] + '/' + xAxisCategoryValueArray[0] + '/' + xAxisCategoryValueArray[2]

                    let tooltipString = '';
                    let loopingDetailedDataString = '';

                    tooltipString += '<ul>' +
                        '<li><b>Name:</b> ' + serieName + '</li>' +
                        '<li><b>Quantity:</b> ' + quantity + '</li>' +
                        '<li><b>Date:</b> ' + xAxisCategoryFormattedValue + '</li>' +
                        '<li><b>Operations:</b></li>';

                    detailedSeriesLocal[seriesIndex].detailedData[dataPointIndex].forEach((x: DashboardOperationGraphItemModel) => {
                        const localDateString = '<ul>' +
                            '<li><p class="p-tag first-details-tag"><b>Contractor name: </b>' + x.contractorName + '</p></li>' +
                            '<p class="p-tag"><b>Operation created by: </b>' + x.createdBy + '</p>' +
                            '<p class="last-details-tag"><b>Description: </b>' + x.descriptionBeginning + '</p></ul>';

                        loopingDetailedDataString += localDateString;
                    });

                    tooltipString += loopingDetailedDataString;
                    tooltipString += '</ul>';

                    return tooltipString;
                }
            } as ApexTooltip
        } as ApexOptions,
        isLogoVisible: false,
        isOperationAdminGraphVisible: false,
        xAxisInitialMinDate: dayjs(new Date(new Date().setDate(new Date().getDate() - 7))).format("YYYY-MM-DD HH:mm:ss"),
        xAxisInitialMaxDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        xAxisMinDate: dayjs(new Date(new Date().setDate(new Date().getDate() - 7))).format("YYYY-MM-DD HH:mm:ss"),
        xAxisMaxDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss")
    } as DashboardModuleStateInterface,
    mutations: {
        setDashboardOperationUsersSelected(state, value: string[]) {
            state.dashboardOperationUsersSelected = value
        },
        setOperationAdminGraphs(state, value: any[]) {
            state.operationAdminGraphs = value
        },
        setIsLogoVisible(state, value: boolean) {
            state.isLogoVisible = value
        },
        setIsOperationAdminGraphVisible(state, value: boolean) {
            state.isOperationAdminGraphVisible = value
        },
        setDetailedSeries(state, value: DashboardGraphOperationSerieModel[]) {
            state.detailedSeries = value
        },
        setXAxisMinDate(state, value: string) {
            state.xAxisMinDate = value
        },
        setXAxisMaxDate(state, value: string) {
            state.xAxisMaxDate = value
        }
    },
    actions: {
        selectPanel(context) {
            const { commit, dispatch } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/dashboard/my-panels")
                    .then((resp: AxiosResponse) => {
                        const panels = resp.data;
                        panels.forEach((panel: any) => {
                            if (panel == "operation-admin-graph") {
                                commit.setIsOperationAdminGraphVisible(true)
                                dispatch.operationAdminGraphUpdate()
                            }
                            else if (panel == "logo") {
                                commit.setIsLogoVisible(true)
                            }
                        })
                        resolve(resp.data)
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        operationAdminGraphUpdate(context) {
            const { state, commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/dashboard/operation-admin-graph", {
                        usersSelected: state.dashboardOperationUsersSelected, xAxisMinDate: dayjs(state.xAxisMinDate).format("YYYY-MM-DD HH:mm:ss"), xAxisMaxDate: dayjs(state.xAxisMaxDate).format("YYYY-MM-DD HH:mm:ss")
                    })
                    .then((resp: AxiosResponse) => {
                        const operationAdminGraphs: any = [];
                        for (let i = 0; i < resp.data.graphs.length; i++) {
                            commit.setDetailedSeries(resp.data.graphs[i].series as DashboardGraphOperationSerieModel[]);
                            const graph = resp.data.graphs[i];
                            const newChartOptions = JSON.parse(JSON.stringify(state.operationAdminGraphOptions)) as ApexOptions;
                            newChartOptions!.xaxis!.categories = resp.data.categories as any;
                            newChartOptions!.chart!.id = state.operationAdminGraphOptions.chart.id + i;
                            newChartOptions!.title!.text = graph.graphName;
                            const currentGraph = {
                                options: newChartOptions,
                                series: [] as any[]
                            };
                            operationAdminGraphs.push(currentGraph);
                            for (let j = 0; j < graph.series.length; j++) {
                                currentGraph.series.push({ name: graph.series[j].categoryName, data: graph.series[j].data });
                                currentGraph.options.tooltip = state.operationAdminGraphOptions.tooltip;
                                currentGraph.options.chart!.events!.beforeZoom = state.operationAdminGraphOptions.chart.events.beforeZoom;
                                currentGraph.options.chart!.events!.beforeResetZoom = state.operationAdminGraphOptions.chart.events.beforeResetZoom;
                            };
                        };
                        commit.setOperationAdminGraphs(operationAdminGraphs);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
    }
});

export default dashboardModule;
const _moduleActionContext = (context: any) => moduleActionContext(context, dashboardModule);
