import dayjs from "dayjs";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { stringMaxLength } from "../../assets/validation/common";
import SettlementCostDocumentPaymentObligationListItemRestModel from "../../models/rest/settlement/cost-document/payment-obligation/settlementCostDocumentPaymentObligationListItemRestModel";
import SettlementIncomeDocumentPaymentObligationListItemRestModel from "../../models/rest/settlement/income-document/payment-obligation/settlementIncomeDocumentPaymentObligationListItemRestModel";

@Component({})

export default class CreatePaymentOutsideTheSystemDialog extends Vue {
    paymentDateLabel = this.$t("payment.paymentDate");

    rules = {
        comment: [
            stringMaxLength(this.$i18n, this.$t("user.name"), 2048),
        ],
    }

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get showDialog(): boolean {
        const showDialog = this.$store.direct.state.settlementCommon.showCreatePaymentOutsideTheSystemDialog;
        if (showDialog == true) {
            this.createPaymentOutsideTheSystemDialogPaymentDate = dayjs().format("YYYY-MM-DD");
        }
        return showDialog;
    }

    get dialogSource(): string {
        return this.$store.direct.state.settlementCommon.createPaymentOutsideTheSystemDialogSource;
    }

    get title(): string {
        return this.$store.direct.state.settlementCommon.createPaymentOutsideTheSystemDialogTitle;
    }

    get createPaymentOutsideTheSystemDialogComment(): string {
        return this.$store.direct.state.settlementCommon.createPaymentOutsideTheSystemDialogComment;
    }

    set createPaymentOutsideTheSystemDialogComment(val: string) {
        this.$store.direct.commit.settlementCommon.setCreatePaymentOutsideTheSystemDialogComment(val);
    }

    get createPaymentOutsideTheSystemDialogPaymentDate(): string {
        return this.$store.direct.state.settlementCommon.createPaymentOutsideTheSystemDialogPaymentDate;
    }

    set createPaymentOutsideTheSystemDialogPaymentDate(val: string) {
        this.$store.direct.commit.settlementCommon.setCreatePaymentOutsideTheSystemDialogPaymentDate(val);
    }

    get selectedCostPaymentObligationList(): SettlementCostDocumentPaymentObligationListItemRestModel[] {
        return this.$store.direct.state.settlementCostDocumentPaymentObligation.selectedPaymentObligationList;
    }

    set selectedCostPaymentObligationList(val: SettlementCostDocumentPaymentObligationListItemRestModel[]) {
        this.$store.direct.commit.settlementCostDocumentPaymentObligation.setSelectedPaymentObligationList(val);
    }

    get selectedIncomePaymentObligationList(): SettlementIncomeDocumentPaymentObligationListItemRestModel[] {
        return this.$store.direct.state.settlementIncomeDocumentPaymentObligation.selectedPaymentObligationList;
    }

    set selectedIncomePaymentObligationList(val: SettlementIncomeDocumentPaymentObligationListItemRestModel[]) {
        this.$store.direct.commit.settlementIncomeDocumentPaymentObligation.setSelectedPaymentObligationList(val);
    }

    get selectedPaymentObligationList(): SettlementCostDocumentPaymentObligationListItemRestModel[] | SettlementIncomeDocumentPaymentObligationListItemRestModel[] {
        return this.dialogSource == "incomeDocumentPaymentObligationList" ? this.selectedIncomePaymentObligationList : this.selectedCostPaymentObligationList
    }

    get currencyListFromSelectedPaymentObligationList(): string[] {
        if (this.dialogSource == "incomeDocumentPaymentObligationList") {
            const arrayWithDuplicates = this.selectedIncomePaymentObligationList.map(({ currencyCode }) => currencyCode);
            const arrayWithoutDuplicates = arrayWithDuplicates.filter((x: string, index: number, self: string[]) => {
                return index == self.indexOf(x);
            });
            return arrayWithoutDuplicates;
        }
        else {
            const arrayWithDuplicates = this.selectedCostPaymentObligationList.map(({ currencyCode }) => currencyCode);
            const arrayWithoutDuplicates = arrayWithDuplicates.filter((x: string, index: number, self: string[]) => {
                return index == self.indexOf(x);
            });
            return arrayWithoutDuplicates;
        }
    }

    get currencyWithAmoutListFromSelectedPaymentObligationList(): any[] {
        const currencyWithAmoutList = [] as any[];

        if (this.dialogSource == "incomeDocumentPaymentObligationList") {
            this.currencyListFromSelectedPaymentObligationList.forEach((x: string) => {
                const sum = this.selectedIncomePaymentObligationList
                    .map((y: SettlementIncomeDocumentPaymentObligationListItemRestModel) => {
                        return y.currencyCode == x ? y.paymentAmount : 0
                    })
                    .reduce(function (a, b) {
                        return a + b;
                    });

                currencyWithAmoutList.push({
                    currency: x, amount: sum
                });
            });
        }
        else {
            this.currencyListFromSelectedPaymentObligationList.forEach((x: string) => {
                const sum = this.selectedCostPaymentObligationList
                    .map((y: SettlementCostDocumentPaymentObligationListItemRestModel) => {
                        return y.currencyCode == x ? y.paymentAmount : 0
                    })
                    .reduce(function (a, b) {
                        return a + b;
                    });

                currencyWithAmoutList.push({
                    currency: x, amount: this.roundValueToTwoPlaces(sum)
                });
            });
        }
        return currencyWithAmoutList;
    }

    allowNumbersOnly(evt: any) {
        return this.$store.direct.dispatch.settlementCommon.allowNumbersOnly(evt);
    }

    roundValueToTwoPlaces(value: number) {
        return Math.round(value * 100) / 100;
    }

    changeCommaToDot(index: number) {
        if (this.dialogSource == "incomeDocumentPaymentObligationList") {
            this.selectedIncomePaymentObligationList[index].paymentAmount = parseFloat(this.selectedIncomePaymentObligationList[index].paymentAmount.toString().replace(",", "."));
        }
        else {

            this.selectedCostPaymentObligationList[index].paymentAmount = parseFloat(this.selectedCostPaymentObligationList[index].paymentAmount.toString().replace(",", "."));
        }
    }

    async cancelClick() {
        await this.$store.direct.dispatch.settlementCommon.isNotConfirmedCreatePaymentOutsideTheSystemDialog();
        this.clearCreatePaymentOutsideTheSystemDialogValues();
    }

    async saveClick() {
        const valid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
        if (valid) {
            await this.$store.direct.dispatch.settlementCommon.isConfirmedCreatePaymentOutsideTheSystemDialog();
            this.clearCreatePaymentOutsideTheSystemDialogValues();
        }
    }

    destroyed() {
        this.clearCreatePaymentOutsideTheSystemDialogValues();
    }

    clearCreatePaymentOutsideTheSystemDialogValues() {
        this.createPaymentOutsideTheSystemDialogComment = "";
        this.createPaymentOutsideTheSystemDialogPaymentDate = "";
    }
}
