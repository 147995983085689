import Vue from "vue";
import { Component } from "vue-property-decorator";
import ContractorBankAccountRestModel from "../../../../models/rest/contractor/contractorBankAccountRestModel";
import CountryRestModel from "../../../../models/rest/register/countryRestModel";
import CurrencyRestModel from "../../../../models/rest/register/currencyRestModel";
import CostDocumentBankAccountRestModel from "../../../../models/rest/settlement/cost-document/settlementCostDocumentBankAccountRestModel";
import SettlementCostDocumentRestModel from "../../../../models/rest/settlement/cost-document/settlementCostDocumentRestModel";

@Component
export default class BankAccountDialog extends Vue {
    rules = {
        accountNumber: [
            () => {
                const error = this.validationError.BankAccountNumber != undefined ? this.validationError.BankAccountNumber[0] : "";
                return error == "" || error;
            }],
        currencyCode: [
            () => {
                const error = this.validationError.BankAccountCurrencyCode != undefined ? this.validationError.BankAccountCurrencyCode[0] : "";
                return error == "" || error;
            }],
        countryCode: [
            () => {
                const error = this.validationError.BankAccountCountryCode != undefined ? this.validationError.BankAccountCountryCode[0] : "";
                return error == "" || error;
            }],
    }

    get validationError(): any {
        return this.$store.direct.state.settlementCostDocument.costDocumentBankAccountValidationError;
    }

    get showDialog(): boolean {
        return this.$store.direct.state.settlementCostDocument.showCostDocumentBankAccountDialog;
    }

    get title(): string {
        return this.$store.direct.state.settlementCostDocument.costDocumentBankAccountDialogTitle;
    }

    get bankAccount(): CostDocumentBankAccountRestModel {
        return this.$store.direct.state.settlementCostDocument.costDocumentBankAccount;
    }

    set bankAccount(val: CostDocumentBankAccountRestModel) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentBankAccount(val);
    }

    get costDocumentBankAccountList() {
        let costDocumentBankAccountList = this.$store.direct.state.settlementCostDocument.costDocumentBankAccountList;
        if (costDocumentBankAccountList == undefined) {
            costDocumentBankAccountList = [] as CostDocumentBankAccountRestModel[];
        }

        return costDocumentBankAccountList;
    }

    set costDocumentBankAccountList(val: CostDocumentBankAccountRestModel[]) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentBankAccountList(val);
    }

    get countryList(): CountryRestModel[] {
        return this.$store.direct.state.contractor.contractorAssets.countries;
    }

    get currencies(): CurrencyRestModel[] {
        return this.$store.direct.state.contractor.contractorAssets.currencies;
    }

    get contractorBankAccounts(): ContractorBankAccountRestModel[] {
        return this.$store.direct.state.contractor.bankAccountList;
    }

    set contractorBankAccounts(val: ContractorBankAccountRestModel[]) {
        this.$store.direct.commit.contractor.setBankAccountList(val);
    }

    get costDocument(): SettlementCostDocumentRestModel {
        return this.$store.direct.state.settlementCostDocument.costDocument;
    }

    selectedContractorAccountNumberChanged(item: ContractorBankAccountRestModel) {
        const contractorBankAccountFound = this.contractorBankAccounts.find(x => x.accountNumber == item.accountNumber);
        if (contractorBankAccountFound != undefined) {
            this.fillSettlementContractorBankAccountDetails(item);
        }
    }

    fillSettlementContractorBankAccountDetails(item: ContractorBankAccountRestModel) {
        setTimeout(() => {
            this.bankAccount.bankAccountContractorId = item.contractorId;
            this.bankAccount.bankAccountNumber = item.accountNumber;
            this.bankAccount.bankAccountIban = item.iban;
            this.bankAccount.bankAccountSwift = item.swift;
            this.bankAccount.bankAccountCountryCode = item.countryCode;
            this.bankAccount.bankAccountCurrencyCode = item.currencyCode;
            this.bankAccount.bankAccountIsVatAccount = item.isVatAccount;
            this.bankAccount.bankName = item.bankName;
        }, 1);
    }

    cancelClick() {
        this.$store.direct.dispatch.settlementCostDocument.isNotConfirmedCostDocumentBankAccountDialog();
        this.bankAccount = {} as CostDocumentBankAccountRestModel;
        this.contractorBankAccounts = [] as ContractorBankAccountRestModel[];
    }

    async saveClick() {
        if (this.costDocument.contractor?.value != undefined) {
            this.bankAccount.bankAccountContractorId = this.costDocument.contractor.value;
        }

        await this.$store.direct.dispatch.settlementCostDocument.validateCostDocumentBankAccount({ costDocumentBankAccount: this.bankAccount });

        const valid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
        if (Object.keys(this.validationError).length === 0) {
            if (this.$refs.form != undefined) {
                const valid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
                await this.$store.direct.dispatch.settlementCostDocument.isConfirmedCostDocumentBankAccountDialog();

                const foundBankAccount = this.costDocumentBankAccountList.find(x => {
                    return x == this.bankAccount;
                });
                if (foundBankAccount != undefined) {
                    const index = this.costDocumentBankAccountList.indexOf(foundBankAccount);
                    if (index != undefined && index > -1) {
                        this.bankAccount = foundBankAccount;
                    }
                }
                else {
                    this.costDocumentBankAccountList.push(this.bankAccount)
                }

                this.bankAccount = {} as CostDocumentBankAccountRestModel;
                this.contractorBankAccounts = [] as ContractorBankAccountRestModel[];
            }
        }
    }

    destroyed() {
        this.bankAccount = {} as CostDocumentBankAccountRestModel;
    }
}
