import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component
export default class CountryTaxRatesDialog extends Vue {
    rules = {
        taxRate: [(value: string) => {
            return (!!value || value == "0") || this.$t("common.validationErrorRequired")
        }],
    };

    get countryTaxRateArray(): number[] {
        const countryTaxRateArray = this.$store.direct.state.register.countryTaxRateArray;
        if (countryTaxRateArray.length == 0) {
            countryTaxRateArray.push(undefined as any);
        }
        return this.$store.direct.state.register.countryTaxRateArray;
    }

    set countryTaxRateArray(val: number[]) {
        this.$store.direct.commit.register.setCountryTaxRateArray(val);
    }

    get showDialog(): boolean {
        return this.$store.direct.state.register.showCountryTaxRatesDialog;
    }

    allowNumbersOnly(evt: any) {
        return this.$store.direct.dispatch.settlementCommon.allowNumbersOnly(evt);
    }

    addTaxRate() {
        this.countryTaxRateArray.push(undefined as any);
    }

    removeTaxRate(index: number) {
        this.countryTaxRateArray.splice(index, 1);
    }

    async saveClick() {
        const valid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
        if (valid) {
            await this.$store.direct.dispatch.register.updateCountryTaxRates();
        }
    }

    async backClick() {
        await this.$store.direct.dispatch.register.closeCountryTaxRatesDialog();
    }
}
