import Vue from "vue";
import { Component } from "vue-property-decorator";
import { canUserEnter } from "@/router";
import LocalStorageApplicationStateDataModel from "../../models/localStorageApplicationStateDataModel";

@Component
export default class Menu extends Vue {

    get localStorageApplicationStateData(): LocalStorageApplicationStateDataModel {
        return this.$store.direct.state.common.localStorageApplicationStateData;
    }

    expandMenuClick(event: any) {
        this.localStorageApplicationStateData.expandMenu = !this.localStorageApplicationStateData.expandMenu;
        this.$store.direct.dispatch.common.updateLocalStorageApplicationStateData();
    }

    items = [
        { title: this.$t("layout.polish"), value: "pl" },
        { title: this.$t("layout.english"), value: "en" },
        { title: this.$t("layout.german"), value: "de" }
    ];

    get isMenuExpand(): boolean {
        return !this.localStorageApplicationStateData.expandMenu;
    }

    get currentUserName(): string {
        return this.$store.direct.state.auth.currentUserName;
    }

    created() {
        this.$store.direct.dispatch.common.readLocalStorageApplicationStateData();
    }

    logout(): void {
        this.$store.direct.dispatch.auth.logout();
    }

    changeLanguage(value: string): void {
        localStorage.setItem("lang", value);
        window.location.reload();
    }

    get links() {
        return [
            {
                route: "/dashboards",
                label: this.$i18n.t("dashboard.home"),
                icon: "mdi-home"
            },
            {
                route: "/map",
                label: this.$i18n.t("map.map"),
                icon: "mdi-map"
            },
            {
                route: "/vehicle/your-vehicles",
                label: this.$i18n.t("vehicle.vehicles"),
                icon: "mdi-truck",
            },
            {
                route: "/transport-order/list",
                label: this.$i18n.t("transportOrder.transportOrder"),
                icon: "mdi-format-list-bulleted",
            },
            {
                route: "/forum/topicList",
                label: this.$i18n.t("forum.forum"),
                icon: "mdi-forum-outline"
            },
            {
                route: "/supervisor/list",
                label: this.$i18n.t("supervisor.cargoSupervisor"),
                icon: "mdi-account-supervisor-circle-outline",
            },
            {
                route: "/supervisor/archive-list",
                label: this.$i18n.t("common.archives"),
                icon: "mdi-chart-box"
            },
            {
                route: "/supervisor/archive-list-for-settlement",
                label: this.$i18n.t("common.archivesForSettlement"),
                icon: "mdi-chart-box-outline"
            },
            {
                route: "/supervisor/all-orders-list",
                label: this.$i18n.t("common.forwardingOrders"),
                icon: "mdi-all-inclusive-box"
            },
            {
                route: "/supervisor/report",
                label: this.$i18n.t("supervisor.report"),
                icon: "mdi-chart-line"
            },
            {
                route: "/settlement",
                label: this.$i18n.t("common.settlement"),
                icon: "mdi-cash-100"
            },
            {
                route: "/tracking",
                label: this.$i18n.t("tracking.tracking"),
                icon: "mdi-map-marker-check"
            },
            {
                route: "/contractor/list",
                label: this.$i18n.t("contractor.contractors"),
                icon: "mdi-domain",
            },
            {
                route: "/operation/list",
                label: this.$i18n.t("contractor.operations"),
                icon: "mdi-account-clock"
            },
            {
                route: "/admin",
                label: this.$i18n.t("common.administration"),
                icon: "mdi-cog",
            }
        ];
    }

    visibleForUser(link: any): boolean {
        const resolved = this.$router.resolve({ path: link.route });
        if (resolved) {
            return canUserEnter(resolved.route);
        }
        return true;
    }

    openPathClick(link: any) {
            switch (link.route) {
                case "/vehicle/your-vehicles": {
                    this.$store.direct.dispatch.vehicle.yourVehicleListCleanFilters();
                    break
                }
                case "/transport-order/list": {
                    this.$store.direct.dispatch.transportOrder.transportOrderListCleanFilters();
                    break
                }
                case "/forum/topicList": {
                    this.$store.direct.dispatch.forum.topicListCleanFilters();
                    break
                }
                case "/supervisor/list": {
                    this.$store.direct.dispatch.supervisor.supervisorListCleanPageOptionsForAllList();
                    break;
                }
                case "/contractor/list": {
                    this.$store.direct.dispatch.contractor.contractorListCleanFilters();
                    break
                }
                case "/tracking": {
                    this.$store.direct.dispatch.tracking.placeStateListCleanFilters();
                    break
                }
                case "/operation/list": {
                    this.$store.direct.dispatch.operation.operationListCleanFilters();
                    break
                }
                case "/supervisor/report": {
                    this.$store.direct.dispatch.supervisorReport.loadSupervisorReportList({ criterionList: [] });
                    break
                }
            }
    }
}
