import { defineModule } from "direct-vuex";
import { moduleActionContext } from "@/store/index";
import { AxiosResponse, AxiosError } from "axios";
import { axiosInstance } from "../../plugins/axios";
import TopicRestModel from "../../models/rest/forum/topicRestModel";
import PostRestModel from "../../models/rest/forum/postRestModel";

export interface ForumModuleStateInterface {
    currentTopicId: number;
    currentPostId: number;
    topic: TopicRestModel;
    topicList: TopicRestModel[];
    topicListLoading: false | boolean;
    topicListTotalCount: number;
    post: PostRestModel;
    postList: PostRestModel[];
    postListLoading: false | boolean;
    postListTotalCount: number;
    postListPagerOptions: any;
    topicListPagerOptions: any;
    searchText: string;
    showForumDialog: boolean;
    forumDialogTitle: string;
    forumDialogFieldTitle: string;
    forumDialogContent: string;
    forumDialogResolve: any;
    forumDialogTextLength: number;
}

const forumModule = defineModule({
    namespaced: true,
    state: {
        currentTopicId: 0,
        currentPostId: 0,
        topic: {} as TopicRestModel,
        topicList: [] as TopicRestModel[],
        topicListLoading: false,
        topicListTotalCount: 0,
        postListPagerOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ["createdAt"],
            sortDesc: [true]
        },
        topicListPagerOptions: {
            page: 1,
            itemsPerPage: 10,
            sortBy: ["createdAt"],
            sortDesc: [true]
        },
        post: {} as PostRestModel,
        postList: [] as PostRestModel[],
        postListLoading: false,
        postListTotalCount: 0,
        searchText: "",
        showForumDialog: false,
        forumDialogTitle: "",
        forumDialogFieldTitle: "",
        forumDialogContent: "",
        forumDialogResolve: null,
        forumDialogTextLength: 0
    } as ForumModuleStateInterface,
    mutations: {
        setCurrentTopicId(state, value: number) {
            state.currentTopicId = value;
        },
        setCurrentPostId(state, value: number) {
            state.currentPostId = value;
        },
        setTopic(state, value: TopicRestModel) {
            state.topic = value;
        },
        setTopicList(state, value: TopicRestModel[]) {
            state.topicList = value;
        },
        setTopicListLoading(state, value: boolean) {
            state.topicListLoading = value;
        },
        setTopicListTotalCount(state, value: number) {
            state.topicListTotalCount = value;
        },
        setPostListPagerOptions(state, payload) {
            state.postListPagerOptions = payload;
        },
        setTopicListPagerOptions(state, payload) {
            state.topicListPagerOptions = payload;
        },
        setPost(state, value: PostRestModel) {
            state.post = value;
        },
        setPostList(state, value: PostRestModel[]) {
            state.postList = value;
        },
        setPostListLoading(state, value: boolean) {
            state.postListLoading = value;
        },
        setPostListTotalCount(state, value: number) {
            state.postListTotalCount = value;
        },
        setSearchText(state, value: string) {
            state.searchText = value;
        },
        setShowForumDialog(state, value: boolean) {
            state.showForumDialog = value;
        },
        setForumDialogTitle(state, value: string) {
            state.forumDialogTitle = value;
        },
        setForumDialogFieldTitle(state, value: string) {
            state.forumDialogFieldTitle = value;
        },
        setForumDialogContent(state, value: string) {
            state.forumDialogContent = value;
        },
        setForumDialogResolve(state, value: any) {
            state.forumDialogResolve = value;
        },
        setForumDialogTextLength(state, value: number) {
            state.forumDialogTextLength = value;
        }
    },
    actions: {
        topicListCleanFilters(context) {
            const { commit } = _moduleActionContext(context);
            const filterOptions = {
                page: 1,
                itemsPerPage: 10,
                sortBy: ["createdAt"],
                sortDesc: [true]
            }
            commit.setSearchText("");
            commit.setTopicListPagerOptions(filterOptions);
        },
        loadTopic(context, payload: { id: number }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/forum/topic/" + payload.id)
                    .then((resp: AxiosResponse) => {
                        commit.setTopic(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        loadTopicList(
            context,
            payload: {
                page: number;
                itemsPerPage: number;
                searchText: string;
                sortBy: string;
                sortDesc: boolean;
            }
        ) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                commit.setTopicListLoading(true);
                const params = {
                    page: payload.page,
                    pageSize: payload.itemsPerPage,
                    searchText: payload.searchText,
                    sortBy: payload.sortBy,
                    sortDesc: payload.sortDesc
                };
                axiosInstance
                    .get("/api/forum", { params })
                    .then((resp: AxiosResponse) => {
                        commit.setTopicList(resp.data.items);
                        commit.setTopicListTotalCount(resp.data.totalItemsCount);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setTopicListLoading(false);
                    });
            });
        },
        addTopic(context, payload: { title: string }) {
            const { state, commit } = _moduleActionContext(context);
            commit.setTopic({
                id: 0,
                title: payload.title
            });
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/forum/topic/add", state.topic)
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        saveTopic(context) {
            const { state } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/forum/topic/save", state.topic)
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        loadPostList(
            context,
            payload: {
                page: number;
                itemsPerPage: number;
                searchText: string;
                sortBy: string;
                sortDesc: boolean;
            }
        ) {
            const { state, commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                commit.setPostListLoading(true);
                const params = {
                    page: payload.page,
                    pageSize: payload.itemsPerPage,
                    searchText: payload.searchText,
                    sortBy: payload.sortBy,
                    sortDesc: payload.sortDesc
                };
                axiosInstance
                    .get("/api/forum/topic/" + state.currentTopicId + "/post/list", { params })
                    .then((resp: AxiosResponse) => {
                        commit.setPostList(resp.data.items);
                        commit.setPostListTotalCount(resp.data.totalItemsCount);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setPostListLoading(false);
                    });
            });
        },
        addPost(context, payload: { postContent: string }) {
            const { state, commit } = _moduleActionContext(context);
            commit.setPost({
                id: 0,
                topicId: state.currentTopicId,
                content: payload.postContent,
                createdByUserEmail: ""
            });
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/forum/topic/" + state.currentTopicId + "/post/add", state.post)
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        loadPost(context, payload: { id: number }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/forum/topic/post/" + payload.id)
                    .then((resp: AxiosResponse) => {
                        commit.setPost(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        savePost(context) {
            const { state } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/forum/topic/" + state.post.topicId + "/post/save", state.post)
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        deletePost(context, payload: { id: number }) {
            const { state, dispatch } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/forum/topic/" + state.currentTopicId + "/post/delete/" + payload.id)
                    .then((resp: AxiosResponse) => {
                        dispatch.loadPostList(state.postListPagerOptions);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        isConfirmedForumDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.forumDialogResolve(true);
            commit.setShowForumDialog(false);
        },

        isNotConfirmedForumDialog(context) {
            const { commit } = _moduleActionContext(context);
            commit.setShowForumDialog(false);
        },

        openForumDialog(
            context,
            payload: {
                showDialog: boolean;
                title: string;
                fieldTitle: string;
                content: string;
            }
        ) {
            const { commit } = _moduleActionContext(context);
            commit.setShowForumDialog(payload.showDialog);
            commit.setForumDialogTitle(payload.title);
            commit.setForumDialogFieldTitle(payload.fieldTitle);
            commit.setForumDialogContent(payload.content);
            return new Promise(resolve => {
                commit.setForumDialogResolve(resolve);
            });
        }
    }
});

export default forumModule;
const _moduleActionContext = (context: any) => moduleActionContext(context, forumModule);
