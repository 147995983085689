import dayjs from "dayjs";
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import DateTimePicker from "../../components/custom/date-time-picker.vue"
import BaseCriterionModel from "../../models/baseCriterionModel";

@Component({
    props: {
        criterion: {
            type: Object,
            default() {
                return {} as BaseCriterionModel;
            }
        }
    },
    components: {
        "date-time-picker": DateTimePicker
    }
})

export default class DateCriterion extends Vue {
    criterion!: BaseCriterionModel;

    formatSingleDateTimeValue(time: any) {
        return time != null ? dayjs(time).format("YYYY-MM-DD") : "";
    }

    @Watch("criterion", { deep: true })
    onCriterionListChanged() {
        this.formatCriterionValue();
    }

    created() {
        this.formatCriterionValue();
    }

    formatCriterionValue() {
        this.criterion.value = this.formatSingleDateTimeValue(this.criterion.value);
    }
}
