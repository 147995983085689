import dayjs from "dayjs";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { stringMaxLength } from "../../../assets/validation/common";
import { SettlementPaymentTypeEnum } from "../../../models/emums/settlement/settlementPaymentTypeEnum";
import ListItemNumberModel from "../../../models/listItemNumberModel";
import CurrencyRestModel from "../../../models/rest/register/currencyRestModel";
import SettlementCostDocumentRestModel from "../../../models/rest/settlement/cost-document/settlementCostDocumentRestModel";
import SettlementIncomeDocumentRestModel from "../../../models/rest/settlement/income-document/settlementIncomeDocumentRestModel";
import SettlementPaymentCostDocumentRestModel from "../../../models/rest/settlement/payment/settlementPaymentCostDocumentRestModel";
import SettlementPaymentIncomeDocumentRestModel from "../../../models/rest/settlement/payment/settlementPaymentIncomeDocumentRestModel";
import SettlementPaymentRestModel from "../../../models/rest/settlement/payment/settlementPaymentRestModel";
import SupervisorForSettlementRestModel from "../../../models/rest/supervisor/supervisorForSettlementRestModel";

@Component({})

export default class IncomePaymentDialog extends Vue {
    SettlementPaymentTypeEnum = SettlementPaymentTypeEnum;
    costDocumentSearch = "";
    incomeDocumentSearch = "";
    isPaymentNew = false;
    costDocumentSearchArray: string[] = [];
    incomeDocumentSearchArray: string[] = [];
    timeout: number | undefined;
    paymentCostDocumentRequestListAmountSum = 0;
    paymentIncomeDocumentRequestListAmountSum = 0;
    paymentDateLabel = this.$t("payment.paymentDate");

    rules = {
        comment: [
            stringMaxLength(this.$i18n, this.$t("user.name"), 2048),
        ],
        paymentCostDocumentRequestList: [
            () => {
                const error = this.validationError.PaymentCostDocumentRequestList != undefined ? this.validationError.PaymentCostDocumentRequestList[0] : "";
                return error == "" || error;
            }],
        paymentIncomeDocumentRequestList: [
            () => {
                const error = this.validationError.PaymentIncomeDocumentRequestList != undefined ? this.validationError.PaymentIncomeDocumentRequestList[0] : "";
                return error == "" || error;
            }],
        paymentTypeId: [
            () => {
                const error = this.validationError.PaymentTypeId != undefined ? this.validationError.PaymentTypeId[0] : "";
                return error == "" || error;
            }],
        paymentAmount: [
            () => {
                const error = this.validationError.PaymentAmount != undefined ? this.validationError.PaymentAmount[0] : "";
                return error == "" || error;
            }],
        currencyCode: [
            () => {
                const error = this.validationError.CurrencyCode != undefined ? this.validationError.CurrencyCode[0] : "";
                return error == "" || error;
            }],
    }

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get showDialog(): boolean {
        return this.$store.direct.state.settlementPayment.showIncomePaymentDialog;
    }

    get title(): string {
        return this.$store.direct.state.settlementPayment.incomePaymentDialogTitle;
    }

    get payment(): SettlementPaymentRestModel {
        const payment = this.$store.direct.state.settlementPayment.payment;

        if (Object.keys(payment).length == 0) {
            this.isPaymentNew = true;
            this.paymentDate = dayjs().format("YYYY-MM-DD");
        }

        if (payment.paymentCostDocumentRequestList != undefined) {
            this.paymentCostDocumentRequestList = payment.paymentCostDocumentRequestList;
            this.paymentCostDocumentRequestList.forEach((x: SettlementPaymentCostDocumentRestModel) => {
                if (Object.keys(x).length != 0 && x.costDocument != undefined && Object.keys(x.costDocument).length != 0) {
                    this.costDocumentFound.push(x.costDocument);
                };
            });
        };

        if (payment.paymentIncomeDocumentRequestList != undefined) {
            this.paymentIncomeDocumentRequestList = payment.paymentIncomeDocumentRequestList;
            this.paymentIncomeDocumentRequestList.forEach((x: SettlementPaymentIncomeDocumentRestModel) => {
                if (Object.keys(x).length != 0 && x.incomeDocument != undefined && Object.keys(x.incomeDocument).length != 0) {
                    this.incomeDocumentFound.push(x.incomeDocument);
                };
            });
        }
        else if (this.paymentIncomeDocumentRequestList != undefined) {
            this.paymentIncomeDocumentRequestList.forEach((x: SettlementPaymentIncomeDocumentRestModel) => {
                if (Object.keys(x).length != 0 && x.incomeDocument != undefined && Object.keys(x.incomeDocument).length != 0) {
                    this.incomeDocumentFound.push(x.incomeDocument);
                };
            });
        }

        if (payment.paymentDate && payment.paymentDate != null && payment.paymentDate != undefined) {
            const formattedPaymentDate = this.formatDate(payment.paymentDate);
            payment.paymentDate = formattedPaymentDate != undefined ? formattedPaymentDate : "";
        }

        return payment;
    }

    set payment(val: SettlementPaymentRestModel) {
        this.$store.direct.commit.settlementPayment.setPayment(val);
    }

    get paymentDate(): string {
        let paymentDate = this.$store.direct.state.settlementPayment.paymentDate;
        if (paymentDate && paymentDate != null && paymentDate != undefined) {
            const formattedPaymentDate = this.formatDate(paymentDate);
            paymentDate = formattedPaymentDate != undefined ? formattedPaymentDate : "";
        }

        return paymentDate;
    }

    set paymentDate(val: string) {
        this.$store.direct.commit.settlementPayment.setPaymentDate(val);
    }

    get paymentCostDocumentRequestList(): SettlementPaymentCostDocumentRestModel[] {
        const paymentCostDocumentRequestList = this.$store.direct.state.settlementPayment.paymentCostDocumentRequestList;

        if (paymentCostDocumentRequestList.length == 0) {
            paymentCostDocumentRequestList.push({} as SettlementPaymentCostDocumentRestModel);
        }

        this.paymentCostDocumentRequestListAmountSum = 0;

        paymentCostDocumentRequestList.forEach((x: SettlementPaymentCostDocumentRestModel) => {
            if (x.paymentAmount != undefined && typeof (Number(x.paymentAmount)) === "number") {
                this.paymentCostDocumentRequestListAmountSum += +x.paymentAmount;
            }
        });

        return paymentCostDocumentRequestList
    }

    set paymentCostDocumentRequestList(val: SettlementPaymentCostDocumentRestModel[]) {
        this.$store.direct.commit.settlementPayment.setPaymentCostDocumentRequestList(val);
    }

    get paymentIncomeDocumentRequestList(): SettlementPaymentIncomeDocumentRestModel[] {
        const paymentIncomeDocumentRequestList = this.$store.direct.state.settlementPayment.paymentIncomeDocumentRequestList;

        if (paymentIncomeDocumentRequestList.length == 0) {
            paymentIncomeDocumentRequestList.push({} as SettlementPaymentIncomeDocumentRestModel);
        }

        this.paymentIncomeDocumentRequestListAmountSum = 0;

        paymentIncomeDocumentRequestList.forEach((x: SettlementPaymentIncomeDocumentRestModel) => {
            if (x.paymentAmount != undefined && typeof (Number(x.paymentAmount)) === "number") {
                this.paymentIncomeDocumentRequestListAmountSum += +x.paymentAmount;
            }
        });

        return paymentIncomeDocumentRequestList;
    }

    set paymentIncomeDocumentRequestList(val: SettlementPaymentIncomeDocumentRestModel[]) {
        this.$store.direct.commit.settlementPayment.setPaymentIncomeDocumentRequestList(val);
    }

    get paymentTypeList(): ListItemNumberModel[] {
        let paymentTypeList = this.$store.direct.state.settlementCommon.settlementAssets.paymentTypes;

        if (paymentTypeList != undefined) {
            paymentTypeList = paymentTypeList.filter((x: ListItemNumberModel) => {
                return x.value !== SettlementPaymentTypeEnum.PLATNOSCPOZASYSTEMEM;
            });
        }

        return paymentTypeList;
    }

    get currencies(): CurrencyRestModel[] {
        return this.$store.direct.state.contractor.contractorAssets.currencies;
    }

    get costDocumentFound(): SettlementCostDocumentRestModel[] {
        return this.$store.direct.state.settlementCostDocument.costDocumentSearch;
    }

    get incomeDocumentFound(): SettlementIncomeDocumentRestModel[] {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentSearch;
    }

    get validationError(): any {
        return this.$store.direct.state.settlementPayment.paymentValidationError;
    }

    async created() {
        await this.$store.direct.dispatch.contractor.loadContractorAssets();
        await this.$store.direct.dispatch.settlementCommon.loadSettlementAssets();
    }

    costDocumentSearchLoadItems(index: number) {
        if (this.costDocumentSearchArray[index] != null && this.costDocumentSearchArray[index].length > 0) {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.$store.direct.dispatch.settlementCostDocument.searchCostDocument({ searchText: this.costDocumentSearchArray[index] });
            }, 200);
        }
    }

    incomeDocumentSearchLoadItems(index: number) {
        if (this.incomeDocumentSearchArray[index] != null && this.incomeDocumentSearchArray[index].length > 0) {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.$store.direct.dispatch.settlementIncomeDocument.searchIncomeDocument({ searchText: this.incomeDocumentSearchArray[index] });
            }, 200);
        }
    }

    allowNumbersOnly(evt: any) {
        return this.$store.direct.dispatch.settlementCommon.allowNumbersOnly(evt);
    }

    changeCommaToDotInPaymentCostDocumentRequestList(index: number) {
        this.paymentCostDocumentRequestList[index].paymentAmount = parseFloat(this.paymentCostDocumentRequestList[index].paymentAmount.toString().replace(",", "."));
    }

    changeCommaToDotInPaymenIncomeDocumentRequestList(index: number) {
        this.paymentIncomeDocumentRequestList[index].paymentAmount = parseFloat(this.paymentIncomeDocumentRequestList[index].paymentAmount.toString().replace(",", "."));
    }

    changeCommaToDot(val: any) {
        this.payment.paymentAmount = parseFloat(val.toString().replace(",", "."));
    }

    formatDate(dateTime: any) {
        if (dateTime != null) {
            return dayjs(dateTime).format("YYYY-MM-DD");
        }
    }

    async cancelClick() {
        await this.$store.direct.dispatch.settlementPayment.isNotConfirmedIncomePaymentDialog();
        this.payment = {} as SettlementPaymentRestModel;
        this.$store.direct.commit.settlementCommon.setSupervisorList([] as SupervisorForSettlementRestModel[]);
    }

    async saveClick() {
        if (this.paymentIncomeDocumentRequestList[0].incomeDocument != null) {
            this.payment.paymentIncomeDocumentRequestList = this.paymentIncomeDocumentRequestList;
        }
        if (this.paymentCostDocumentRequestList[0] != undefined && this.paymentCostDocumentRequestList[0].costDocument?.id != null) {
            this.payment.paymentCostDocumentRequestList = this.paymentCostDocumentRequestList;
        }
        else {
            this.payment.paymentCostDocumentRequestList = undefined as any;
        }

        this.payment.paymentDate = this.paymentDate;

        await this.$store.direct.dispatch.settlementPayment.saveIncomePayment();

        if (this.$refs.form != undefined) {
            const valid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
        }

        if (Object.keys(this.validationError).length === 0) {
            await this.$store.direct.dispatch.settlementPayment.isConfirmedIncomePaymentDialog();
            this.payment = {} as SettlementPaymentRestModel;
            this.$store.direct.commit.settlementCommon.setSupervisorList([] as SupervisorForSettlementRestModel[]);
        }
    }

    destroyed() {
        this.payment = {} as SettlementPaymentRestModel;
    }

    addCostDocument() {
        this.paymentCostDocumentRequestList.push({} as SettlementPaymentCostDocumentRestModel);
    }

    removeCostDocument(index: number) {
        this.paymentCostDocumentRequestList.splice(index, 1);
    }

    addIncomeDocument() {
        this.paymentIncomeDocumentRequestList.push({} as SettlementPaymentIncomeDocumentRestModel);
    }

    removeIncomeDocument(index: number) {
        this.paymentIncomeDocumentRequestList.splice(index, 1);
    }
}
