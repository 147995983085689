import { moduleActionContext } from "@/store/index";
import { defineModule } from "direct-vuex";
import ListItemLinkModel from "../../models/listItemLinkModel";

export interface ConfirmModuleStateInterface {
    showConfirmDialog: boolean;
    titleConfirmDialog: string;
    messageConfirmDialog: string;
    linkListConfirmDialog: ListItemLinkModel[];
    optionsConfirmDialog: any;
    buttonType: string;
    resolve: any;
}

const confirmModule = defineModule({
    namespaced: true,
    state: {
        showConfirmDialog: false,
        titleConfirmDialog: "Default title",
        messageConfirmDialog: "Default message",
        linkListConfirmDialog: [] as ListItemLinkModel[],
        optionsConfirmDialog: {
            buttonColor: "submit",
            layoutColor: "red"
        },
        buttonType: "yes/no",
        resolve: null
    } as ConfirmModuleStateInterface,
    mutations: {
        setShowConfirmDialog(state, value: boolean) {
            state.showConfirmDialog = value;
        },
        setTitleConfirmDialog(state, value: string) {
            state.titleConfirmDialog = value;
        },
        setMessageConfirmDialog(state, value: string) {
            state.messageConfirmDialog = value;
        },
        setLinkListConfirmDialog(state, value: ListItemLinkModel[]) {
            state.linkListConfirmDialog = value;
        },
        setOptionsConfirmDialog(state, payload) {
            state.optionsConfirmDialog.buttonColor = payload.options.buttonColor;
            state.optionsConfirmDialog.layoutColor = payload.options.layoutColor;
        },
        setButtonTypeConfirmDialog(state, payload) {
            state.buttonType= payload;
        },
        setResolve(state, value: any) {
            state.resolve = value;
        }
    },
    actions: {
        isConfirmedConfirmDialog(context, payload: { isConfirmed: boolean }) {
            const { state, commit } = _moduleActionContext(context);
            state.resolve(true);
            commit.setShowConfirmDialog(false);
        },

        isNotConfirmedConfirmDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.resolve(false);
            commit.setShowConfirmDialog(false);
        },
        openConfirmDialog(
            context,
            payload: {
                showConfirmDialog: boolean;
                title: string;
                message: string;
                links: ListItemLinkModel[];
                options: any;
                buttonType: string;
            }
        ) {
            const { commit } = _moduleActionContext(context);
            commit.setShowConfirmDialog(payload.showConfirmDialog);
            commit.setTitleConfirmDialog(payload.title);
            commit.setMessageConfirmDialog(payload.message);
            commit.setLinkListConfirmDialog(payload.links);
            commit.setOptionsConfirmDialog(payload);
            commit.setButtonTypeConfirmDialog(payload.buttonType);
            return new Promise((resolve, reject) => {
                commit.setResolve(resolve);
            });
        }
    }
});

export default confirmModule;
const _moduleActionContext = (context: any) => moduleActionContext(context, confirmModule);
