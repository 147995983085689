import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import CostTypeRestModel from "../../../../models/rest/register/costTypeRestModel";
import SettlementCostDocumentItemRestModel from "../../../../models/rest/settlement/cost-document/settlementCostDocumentItemRestModel";
import SettlementCostDocumentRestModel from "../../../../models/rest/settlement/cost-document/settlementCostDocumentRestModel";

@Component({
    props: {
        currencyRate: {
            default: undefined
        },
        parentComponentName: {
            default: ""
        },
    }
})

export default class CostDocumentItemDialog extends Vue {
    currencyRate!: number | undefined;
    parentComponentName!: string;

    rules = {
        costType: [
            () => {
                const error = this.validationError.CostType != undefined ? this.validationError.CostType[0] : "";
                return error == "" || error;
            }],
        descriptionDetailed: [
            () => {
                const error = this.validationError.DescriptionDetailed != undefined ? this.validationError.DescriptionDetailed[0] : "";
                return error == "" || error;
            }],
        itemQuantity: [
            () => {
                const error = this.validationError.Quantity != undefined ? this.validationError.Quantity[0] : "";
                return error == "" || error;
            }],
        itemUnit: [
            () => {
                const error = this.validationError.Unit != undefined ? this.validationError.Unit[0] : "";
                return error == "" || error;
            }],
        itemTaxRate: [
            () => {
                const error = this.validationError.TaxRate != undefined ? this.validationError.TaxRate[0] : "";
                return error == "" || error;
            }],
        itemPrice: [
            () => {
                const error = this.validationError.Price != undefined ? this.validationError.Price[0] : "";
                return error == "" || error;
            }],
        itemPricePln: [
            () => {
                const error = this.validationError.PricePln != undefined ? this.validationError.PricePln[0] : "";
                return error == "" || error;
            }],
        itemNetto: [
            () => {
                const error = this.validationError.Netto != undefined ? this.validationError.Netto[0] : "";
                return error == "" || error;
            }],
        itemNettoPln: [
            () => {
                const error = this.validationError.NettoPln != undefined ? this.validationError.NettoPln[0] : "";
                return error == "" || error;
            }],
        itemBrutto: [
            () => {
                const error = this.validationError.Brutto != undefined ? this.validationError.Brutto[0] : "";
                return error == "" || error;
            }],
        itemBruttoPln: [
            () => {
                const error = this.validationError.BruttoPln != undefined ? this.validationError.BruttoPln[0] : "";
                return error == "" || error;
            }],
        itemTax: [
            () => {
                const error = this.validationError.Tax != undefined ? this.validationError.Tax[0] : "";
                return error == "" || error;
            }],
        itemTaxPln: [
            () => {
                const error = this.validationError.TaxPln != undefined ? this.validationError.TaxPln[0] : "";
                return error == "" || error;
            }],
    }

    get validationError(): any {
        return this.$store.direct.state.settlementCostDocument.costDocumentItemValidationError;
    }

    get showDialog(): boolean {
        return this.$store.direct.state.settlementCostDocument.showCostDocumentItemDialog;
    }

    get title(): string {
        return this.$store.direct.state.settlementCostDocument.costDocumentItemDialogTitle;
    }

    get isFromCorrection(): boolean {
        return this.$store.direct.state.settlementCostDocument.costDocumentItemDialogIsFromCorrection;
    }

    get item(): SettlementCostDocumentItemRestModel {
        const item = this.$store.direct.state.settlementCostDocument.costDocumentItem;
        if (item.price != undefined) {
            this.assignItemPricePln(item);
        }

        return item;
    }

    set item(val: SettlementCostDocumentItemRestModel) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentItem(val);
    }

    get itemList() {
        if (this.isFromCorrection) {
            return this.$store.direct.state.settlementCostDocument.costDocumentItemCorrectionList;
        }
        return this.$store.direct.state.settlementCostDocument.costDocumentItemList;
    }

    set itemList(val: SettlementCostDocumentItemRestModel[]) {
        if (this.isFromCorrection) {
            this.$store.direct.commit.settlementCostDocument.setCostDocumentItemCorrectionList(val);
        }
        this.$store.direct.commit.settlementCostDocument.setCostDocumentItemList(val);
    }

    get costTypeList(): CostTypeRestModel[] {
        return this.$store.direct.state.settlementCommon.settlementAssets.costTypes;
    }

    get countryTaxRates(): number[] {
        return this.$store.direct.state.register.countryTaxRateArray;
    }

    get costDocument(): SettlementCostDocumentRestModel {
        return this.$store.direct.state.settlementCostDocument.costDocument;
    }

    @Watch("showDialog")
    async showDialogHandler(val: any) {
        if (this.showDialog == true && this.item.costType.id == undefined) {
            const carrierFreightCostType = this.costTypeList.find((x: CostTypeRestModel) => {
                return x.id == 9; // Fracht przewo�nika
            })
            this.item.costType = { ...carrierFreightCostType } as CostTypeRestModel;
        }
    }

    async created() {
        await this.$store.direct.dispatch.settlementCommon.loadSettlementAssets();
    }

    async assignItemPricePln(item: SettlementCostDocumentItemRestModel) {
        if (this.currencyRate != undefined) {
            item.pricePln = this.roundValueToTwoPlaces(item.price * this.currencyRate);
        }
        else {
            item.pricePln = item.price;
        }
        this.calculateNettoBruttoAndTaxValueInPln(item);
    }

    roundValueToTwoPlaces(value: number) {
        return Math.round(value * 100) / 100;
    }

    allowNumbersOnly(evt: any) {
        return this.$store.direct.dispatch.settlementCommon.allowNumbersOnly({ evt: evt });
    }

    changeCommaToDot(val: any): number {
        return parseFloat(val.toString().replace(",", "."));
    }

    async calculateNettoBruttoAndTaxValue() {
        if (this.item.price != undefined) {
            this.item.price = this.changeCommaToDot(this.item.price);
        }

        await this.$store.direct.dispatch.settlementCommon.calculateNettoBruttoAndTaxValue({ item: this.item, parentComponentName: this.parentComponentName });
        this.calculateNettoBruttoAndTaxValueInPln(this.item);        
        await this.$forceUpdate();
    }

    async calculateNettoBruttoAndTaxValueInPln(item: SettlementCostDocumentItemRestModel) {
        await this.$store.direct.dispatch.settlementCommon.calculateNettoBruttoAndTaxValueInPln({ item: item, parentComponentName: this.parentComponentName });
        await this.$forceUpdate();
    }

    cancelClick() {
        this.$store.direct.dispatch.settlementCostDocument.isNotConfirmedCostDocumentItemDialog();
        this.item = {} as SettlementCostDocumentItemRestModel;
    }

    async saveClick() {
        this.item.isDocumentCorrection = this.costDocument.isDocumentCorrection;

        this.item.taxRate = (this.item.taxRate == undefined || this.item.taxRate.toString().length == 0) ? 0 : this.item.taxRate as any;
        this.$forceUpdate();

        await this.$store.direct.dispatch.settlementCostDocument.validateCostDocumentItem({ item: this.item });

        const valid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
        if (Object.keys(this.validationError).length === 0) {
            if (this.$refs.form != undefined) {
                const valid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
                await this.$store.direct.dispatch.settlementCostDocument.isConfirmedCostDocumentItemDialog();

                const foundItem = this.itemList.find(x => {
                    return x == this.item;
                });
                if (foundItem != undefined) {
                    const index = this.itemList.indexOf(foundItem);
                    if (index != undefined && index > -1) {
                        this.item = foundItem;
                    }
                }
                else {
                    this.itemList.push(this.item)
                }

                this.item = {} as SettlementCostDocumentItemRestModel;
            }
        }
    }
}
