import { RouteConfig } from "vue-router";
import { Roles } from "@/models/emums/roles";
import store from "@/store";

const routes: Array<RouteConfig> = [
    {
        path: "/transport-order",
        component: require("@/layouts/layout.vue").default,
        children: [
            {
                path: "create",
                name: "Transport order create",
                component: require("@/components/transport-order/transport-order.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.Client, Roles.TrackingClientAdmin]
                },
                beforeEnter: ((to, from, next) => {
                    store.dispatch.transportOrder.loadTemporaryTransportOrder();
                    next();
                })
            },
            {
                path: "show/:id",
                name: "Show transport order",
                component: require("@/components/transport-order/transport-order.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.Client, Roles.TrackingClientAdmin, Roles.Disponent, Roles.DisponentAdmin]
                },
                beforeEnter: (async (to, from, next) => {
                    const transportOrderId = Number.parseInt(to.params.id);
                    await store.dispatch.transportOrder.loadTransportOrder({ id: transportOrderId });
                    next();
                })
            },            
            {
                path: "duplicate/:id",
                name: "Duplicate transport order",
                component: require("@/components/transport-order/transport-order.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.Client, Roles.TrackingClientAdmin]
                },
                beforeEnter: (async (to, from, next) => {
                    const transportOrderId = Number.parseInt(to.params.id);
                    await store.dispatch.transportOrder.duplicateTransportOrder({ id: transportOrderId });
                    next();
                })
            },
            {
                path: "list",
                name: "Transport order list",
                component: require("@/components/transport-order/transport-order-list.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.Client, Roles.TrackingClientAdmin, Roles.Disponent, Roles.DisponentAdmin]
                }
            }
        ]
    }
];

export default routes;
