import dayjs from "dayjs";
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import i18n from "../../../assets/i18n";
import ConfirmDialog from "../../../layouts/confirmDialog/confirmDialog.vue";
import { SettlementPaymentStatusEnum } from "../../../models/emums/settlement/settlementPaymentStatusEnum";
import ListItemNumberModel from "../../../models/listItemNumberModel";
import ContractorSearchItemModel from "../../../models/rest/contractor/contractorSearchItemModel";
import SettlementIncomeDocumentCorrectionListItemRestModel from "../../../models/rest/settlement/income-document/settlementIncomeDocumentCorrectionListItemRestModel";
import SettlementIncomeDocumentListRequestModel from "../../../models/rest/settlement/income-document/settlementIncomeDocumentListRequestModel";
import SettlementIncomeDocumentRestModel from "../../../models/rest/settlement/income-document/settlementIncomeDocumentRestModel";
import SettlementPaymentIncomeDocumentRestModel from "../../../models/rest/settlement/payment/settlementPaymentIncomeDocumentRestModel";
import SettlementPaymentRestModel from "../../../models/rest/settlement/payment/settlementPaymentRestModel";
import SettlementAmountSummaryRestModel from "../../../models/rest/settlement/settlementAmountSummaryRestModel";
import SupervisorForSettlementRestModel from "../../../models/rest/supervisor/supervisorForSettlementRestModel";
import SettlementAmountSummaryRow from "../settlement-amount-summary-row.vue";
import IncomePaymentDialog from "../income-payment/income-payment-dialog.vue";
import { Roles } from "../../../models/emums/roles";
import { BranchEnum } from "../../../models/emums/branchEnum";
import SettlementIncomeDocumentItemRestModel from "../../../models/rest/settlement/income-document/settlementIncomeDocumentItemRestModel";

@Component({
    components: {
        "income-payment-dialog": IncomePaymentDialog,
        "confirm-dialog": ConfirmDialog,
        "settlement-amount-summary-row": SettlementAmountSummaryRow,
    }
})

export default class IncomeDocumentCorrectionList extends Vue {
    paymentTermDateLabel = this.$t("incomeDocument.paymentTermDate");
    searchPaymentTermDate = "";
    contractorSearch = "";
    incomePaymentListPagerOptions = {
        itemsPerPage: -1,
    };
    mainCheckboxIsSelected = false;
    showAmountSummaryRowForCalculatedSelectedList = false;
    issueDateFromLabel = this.$t("costDocument.issueDateFrom");
    issueDateToLabel = this.$t("costDocument.issueDateTo");
    saleDateFromLabel = this.$t("costDocument.saleDateFrom");
    saleDateToLabel = this.$t("costDocument.saleDateTo");

    incomeDocumentCorrectionListHeaders = [
        {
            align: "start",
            value: "checkbox",
            width: "1%",
            sortable: false,
        },
        {
            text: "Id",
            align: "center",
            value: "id",
            width: "1%",
        },
        {
            text: this.$t("contractor.branch"),
            align: "start",
            value: "branchId",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.incomeDocumentType"),
            align: "start",
            value: "incomeDocumentTypeCode",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.documentNumber"),
            align: "center",
            value: "documentNumber",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.correctedDocumentNumber"),
            align: "center",
            value: "correctedDocumentNumber",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.status"),
            align: "start",
            value: "incomeDocumentStatusName",
            sortable: true,
        },
        {
            text: this.$t("supervisor.orderId"),
            align: "start",
            value: "supervisorOrderIdcsString",
            sortable: false,
        },
        {
            text: this.$t("incomeDocument.customerOrderId"),
            align: "start",
            value: "supervisorCustomerOrderIdcsString",
            sortable: false,
        },
        {
            text: this.$t("incomeDocument.contractorName"),
            align: "start",
            value: "contractorName",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.contractorTinWithPrefix"),
            align: "start",
            value: "contractorTinWithPrefix",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.saleDate"),
            align: "start",
            value: "saleDate",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.issueDate"),
            align: "start",
            value: "issueDate",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.paymentTermDate"),
            align: "start",
            value: "paymentTermDate",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.paymentDaysLeft"),
            align: "start",
            value: "paymentDaysLeft",
            width: "1%",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.paymentDate"),
            align: "start",
            value: "paymentDate",
            sortable: true,
        },
        {
            text: "",
            align: "center",
            value: "paymentStatus",
            sortable: true,
        },
        {
            text: this.$t("common.currency"),
            align: "center",
            value: "currencyCode",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.totalNetto"),
            align: "end",
            value: "totalNettoDifference",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.totalTax"),
            align: "end",
            value: "totalTaxDifference",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.totalBrutto"),
            align: "end",
            value: "totalBruttoDifference",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.totalNettoPLN"),
            align: "end",
            value: "totalNettoPlnDifference",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.totalTaxPLN"),
            align: "end",
            value: "totalTaxPlnDifference",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.totalBruttoPLN"),
            align: "end",
            value: "totalBruttoPlnDifference",
            sortable: true,
        },
        {
            text: this.$t("common.actions"),
            align: "right",
            value: "actions",
            width: "1%",
            sortable: false,
        }
    ];

    incomePaymentListHeaders = [
        {
            text: "Id",
            align: "start",
            value: "id",
            width: "1%",
            sortable: true,
        },
        {
            text: this.$t("costDocument.paymentType"),
            align: "start",
            value: "paymentTypeId",
            sortable: false,
        },
        {
            text: this.$t("payment.amount"),
            align: "start",
            value: "paymentAmount",
            sortable: false,
        },
        {
            text: this.$t("payment.currency"),
            align: "start",
            value: "currencyCode",
            sortable: false,
        },
        {
            text: this.$t("common.createdBy"),
            align: "start",
            value: "createdByUserName",
            sortable: false,
        },
        {
            text: this.$t("payment.paymentDate"),
            align: "start",
            value: "paymentDate",
            sortable: false,
        },
    ];

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get incomeDocumentCorrectionListLoading(): boolean {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentCorrectionListLoading;
    }

    get incomeDocumentCorrectionList(): SettlementIncomeDocumentCorrectionListItemRestModel[] {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentCorrectionList;
    }

    get incomeDocumentCorrectionListTotalCount(): number {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentCorrectionListTotalCount;
    }

    get incomeDocumentCorrectionListPagerOptions(): any {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentCorrectionListPagerOptions;
    }

    set incomeDocumentCorrectionListPagerOptions(value: any) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentCorrectionListPagerOptions(value);
    }

    get incomeDocumentCorrectionListAmountSummaryList(): SettlementAmountSummaryRestModel[] {
        let amountSummary = this.$store.direct.state.settlementIncomeDocument.incomeDocumentCorrectionListAmountSummaryList;
        if (amountSummary == undefined) {
            amountSummary = [] as SettlementAmountSummaryRestModel[];
        }

        return amountSummary;
    }

    get incomeDocumentCorrectionListSearchText(): string {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentCorrectionListSearchText;
    }

    set incomeDocumentCorrectionListSearchText(value: string) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentCorrectionListSearchText(value);
    }

    get incomeDocumentCorrectionListSelectedBranch(): number {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentCorrectionListSelectedBranch;
    }

    set incomeDocumentCorrectionListSelectedBranch(value: number) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentCorrectionListSelectedBranch(value);
    }

    get searchContractors(): ContractorSearchItemModel[] {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentCorrectionListSearchContractors;
    }

    set searchContractors(value: ContractorSearchItemModel[]) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentCorrectionListSearchContractors(value);
    }

    get searchContractorIdcs(): number[] {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentCorrectionListSearchContractorIdcs;
    }

    set searchContractorIdcs(value: number[]) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentCorrectionListSearchContractorIdcs(value);
    }

    get footerPropsForSettlement(): any {
        return this.$store.direct.state.settlementCommon.footerPropsForSettlement;
    }

    get branches(): ListItemNumberModel[] {
        const branches = this.$store.direct.state.contractor.contractorAssets.branches;
        if (branches == undefined) {
            this.$store.direct.dispatch.contractor.loadContractorAssets();
        }
        return branches;
    }

    get branchList(): ListItemNumberModel[] {
        const branchList = this.$store.direct.state.contractor.contractorAssets.branches;
        let clonedBranchList = [] as ListItemNumberModel[];
        if (branchList == undefined) {
            this.$store.direct.dispatch.contractor.loadContractorAssets();
        }
        else {
            clonedBranchList = [...branchList];
            clonedBranchList.push({ value: 0, text: i18n.t("common.all") } as ListItemNumberModel);
        }
        return clonedBranchList;
    }

    get contractorFound(): ContractorSearchItemModel[] {
        return this.$store.direct.state.common.contractorSearch;
    }

    get paymentTypeList(): ListItemNumberModel[] {
        return this.$store.direct.state.settlementCommon.settlementAssets.paymentTypes;
    }

    get incomeDocument(): SettlementIncomeDocumentRestModel {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocument;
    }

    set incomeDocument(val: SettlementIncomeDocumentRestModel) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocument(val);
    }

    get incomeDocumentIssueDate() {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentIssueDate;
    }

    set incomeDocumentIssueDate(val: string) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentIssueDate(val);
    }

    get incomeDocumentSaleDate() {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentSaleDate;
    }

    set incomeDocumentSaleDate(val: string) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentSaleDate(val);
    }

    get incomeDocumentPaymentDate() {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentPaymentDate;
    }

    set incomeDocumentPaymentDate(val: string) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentPaymentDate(val);
    }

    get incomeDocumentSupervisorList(): SupervisorForSettlementRestModel[] {
        return this.$store.direct.state.settlementCommon.supervisorList;
    }

    set incomeDocumentSupervisorList(val: SupervisorForSettlementRestModel[]) {
        this.$store.direct.commit.settlementCommon.setSupervisorList(val);
    }

    get incomePayment(): SettlementPaymentRestModel {
        return this.$store.direct.state.settlementPayment.payment;
    }

    set incomePayment(val: SettlementPaymentRestModel) {
        this.$store.direct.commit.settlementPayment.setPayment(val);
    }

    get selectedIncomeDocumentCorrectionList(): SettlementIncomeDocumentCorrectionListItemRestModel[] {
        return this.$store.direct.state.settlementIncomeDocument.selectedIncomeDocumentCorrectionList;
    }

    set selectedIncomeDocumentCorrectionList(val: SettlementIncomeDocumentCorrectionListItemRestModel[]) {
        this.$store.direct.commit.settlementIncomeDocument.setSelectedIncomeDocumentCorrectionList(val);
    }

    get calculatedSelectedListForAmountSummaryRow(): SettlementAmountSummaryRestModel[] {
        return this.$store.direct.state.settlementCommon.calculatedSelectedListForAmountSummaryRow;
    }

    get currentUserRoles() {
        return this.$store.direct.state.auth.userRoles;
    }

    get searchCorrectionIssueDateFrom(): string {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentCorrectionListSearchIssueDateFrom;
    }

    set searchCorrectionIssueDateFrom(value: string) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentCorrectionListSearchIssueDateFrom(value);
    }

    get searchCorrectionIssueDateTo(): string {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentCorrectionListSearchIssueDateTo;
    }

    set searchCorrectionIssueDateTo(value: string) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentCorrectionListSearchIssueDateTo(value);
    }

    get searchCorrectionSaleDateFrom(): string {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentCorrectionListSearchSaleDateFrom;
    }

    set searchCorrectionSaleDateFrom(value: string) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentCorrectionListSearchSaleDateFrom(value);
    }

    get searchCorrectionSaleDateTo(): string {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentCorrectionListSearchSaleDateTo;
    }

    set searchCorrectionSaleDateTo(value: string) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentCorrectionListSearchSaleDateTo(value);
    }

    @Watch("incomeDocumentCorrectionListPagerOptions", { deep: true })
    pagerOptionsHandles() {
        this.loadIncomeDocumentCorrectionList();
    }

    @Watch("contractorSearch")
    async contractorSearchLoadItems(val: string) {
        await this.$store.direct.dispatch.common.searchContractors({ searchText: val, contractorTypes: [], contractorPropertiesEnabled: [], source: "" });
        if (this.searchContractors.length > 0) {
            this.searchContractors.forEach((x: any) => {
                if (!this.contractorFound.includes(x)) {
                    this.contractorFound.push(x);
                }
            })
        }
    }

    created() {
        this.loadIncomeDocumentCorrectionList();
    }

    formatPaymentTypeIdToPaymentName(paymentTypeId: number) {
        if (this.paymentTypeList != undefined) {
            return this.paymentTypeList.find(x => x.value == paymentTypeId)?.text;
        }
    }

    itemRowTextColor(item: SettlementIncomeDocumentCorrectionListItemRestModel) {
        let style = ""
        item.paymentDaysLeft < 0 ? style += "red--text " : "";
        item.isCancelled ? style += "text-decoration-line-through " : "";
        return style;
    }

    formatBranchIdToBranchName(branchId: any) {
        if (this.branches != undefined) {
            return this.branches.find(x => x.value == branchId)?.text;
        }
    }

    formatDate(dateTime: any) {
        if (dateTime != null) {
            return dayjs(dateTime).format("YYYY-MM-DD");
        }
    }

    getPaymentStatusIconColor(item: SettlementIncomeDocumentCorrectionListItemRestModel): string {
        if (item.paymentStatusId == SettlementPaymentStatusEnum.Niezaplacona) {
            return "red--text";
        }
        if (item.paymentStatusId == SettlementPaymentStatusEnum.ZaplataCzesciowa) {
            return "blue--text";
        }
        else if (item.paymentStatusId == SettlementPaymentStatusEnum.Zaplacona) {
            return "green--text";
        }
        else if (item.paymentStatusId == SettlementPaymentStatusEnum.Nadplata) {
            return "yellow--text";
        }
        else {
            return "";
        }
    }

    userIsAdminOrAccountantAdmin(): boolean {
        if (this.currentUserRoles.includes(Roles.Admin) || this.currentUserRoles.includes(Roles.AccountantAdmin)) {
            return true;
        }
        return false;
    }

    searchClick() {
        this.loadIncomeDocumentCorrectionList();
    }

    clearSearchContractorClick() {
        this.searchContractorIdcs = [] as number[];
        this.loadIncomeDocumentCorrectionList();
    }

    clearDateTimePicker(clearableValue: string) {
        if (clearableValue == "searchCorrectionIssueDateFromValue") {
            this.searchCorrectionIssueDateFrom = "";
        }
        if (clearableValue == "searchCorrectionIssueDateToValue") {
            this.searchCorrectionIssueDateTo = "";
        }
        if (clearableValue == "searchCorrectionSaleDateFromValue") {
            this.searchCorrectionSaleDateFrom = "";
        }
        if (clearableValue == "searchCorrectionSaleDateToValue") {
            this.searchCorrectionSaleDateTo = "";
        }
        if (clearableValue == "searchCorrectionPaymentTermDateValue") {
            this.searchPaymentTermDate = "";
        }

        this.loadIncomeDocumentCorrectionList();
    }

    clearAllFilters() {
        this.incomeDocumentCorrectionListSearchText = "";
        this.incomeDocumentCorrectionListSelectedBranch = 0;
        this.searchContractorIdcs = [] as number[];
        this.searchContractors = [] as ContractorSearchItemModel[];
        this.searchCorrectionIssueDateFrom = "";
        this.searchCorrectionIssueDateTo = "";
        this.searchCorrectionSaleDateFrom = "";
        this.searchCorrectionSaleDateTo = "";
        this.searchPaymentTermDate = "";

        this.loadIncomeDocumentCorrectionList();
    }

    async loadIncomeDocumentCorrectionList() {
        await this.$store.direct.dispatch.settlementIncomeDocument.loadIncomeDocumentCorrectionList({ requestModel: this.prepareIncomeDocumentCorrectionRequestModel() });
    }

    async createXlsReportClick() {
        await this.$store.direct.dispatch.settlementIncomeDocument.downloadXlsReportIncomeDocumentCorrectionList({ requestModel: this.prepareIncomeDocumentCorrectionRequestModel() });
    }

    prepareIncomeDocumentCorrectionRequestModel(): SettlementIncomeDocumentListRequestModel {
        return {
            page: this.incomeDocumentCorrectionListPagerOptions.page,
            pageSize: this.incomeDocumentCorrectionListPagerOptions.itemsPerPage,
            sortBy: this.incomeDocumentCorrectionListPagerOptions.sortBy[0],
            sortDesc: this.incomeDocumentCorrectionListPagerOptions.sortDesc[0],
            searchText: this.incomeDocumentCorrectionListSearchText,
            searchBranchId: this.incomeDocumentCorrectionListSelectedBranch,
            searchContractorIdcs: (this.searchContractors != undefined && this.searchContractors.length > 0) ? this.searchContractors.map(x => x.value) : [],
            searchIncomeDocumentStatusIdcs: [] as number[],
            searchDocumentTypeIdcs: [] as number[],
            searchIncomeDocumentTypeId: 0,
            searchSupervisorId: 0,
            searchIssueDateFrom: this.searchCorrectionIssueDateFrom,
            searchIssueDateTo: this.searchCorrectionIssueDateTo,
            searchSaleDateFrom: this.searchCorrectionSaleDateFrom,
            searchSaleDateTo: this.searchCorrectionSaleDateTo,
            searchPaymentTermDateFrom: this.searchPaymentTermDate,
            searchPaymentTermDateTo: this.searchPaymentTermDate,
            searchPaymentDateFrom: undefined,
            searchPaymentDateTo: undefined,
            searchExpiredDaysSince: undefined,
            searchExpiredDaysTo: undefined,
            isPaid: 0,
            searchSelectedCurrency: undefined,
            idList: this.selectedIncomeDocumentCorrectionList.map((x: SettlementIncomeDocumentCorrectionListItemRestModel) => {
                return x.id;
            }),
        } as SettlementIncomeDocumentListRequestModel;
    }

    editIncomeDocumentClick(incomeDocumentId: number) {
        return "/settlement/income-document/correction/edit/" + incomeDocumentId;
    }

    async generateAndGetIncomeDocumentCorrectionFile(incomeDocument: SettlementIncomeDocumentCorrectionListItemRestModel) {
        await this.$store.direct.dispatch.settlementIncomeDocument.loadIncomeDocument({ incomeDocumentId: incomeDocument.id });

        await this.$store.direct.dispatch.settlementIncomeDocument.generateAndGetIncomeDocumentCorrectionFile({ incomeDocumentCorrectionId: incomeDocument.id });

        if ((this.incomeDocument.branchId == BranchEnum.DE && this.incomeDocument.documentLanguage != "DE") || (this.incomeDocument.branchId == BranchEnum.PL && this.incomeDocument.documentLanguage != "PL")) {
            await this.$store.direct.dispatch.settlementIncomeDocument.generateAndGetIncomeDocumentCorrectionFile({ incomeDocumentCorrectionId: incomeDocument.id, documentLanguage: this.incomeDocument.documentLanguage });
        }

        this.cleanIncomeDocumentAndItsChildren();
    }

    cleanIncomeDocumentAndItsChildren() {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocument({} as SettlementIncomeDocumentRestModel);
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentIssueDate("");
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentPaymentDate("");
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentSaleDate("");
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentItemList([] as SettlementIncomeDocumentItemRestModel[]);
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentItemCorrectionList([] as SettlementIncomeDocumentItemRestModel[]);
        this.$store.direct.commit.settlementCommon.setSupervisorList([] as SupervisorForSettlementRestModel[]);
    }

    async cancelIncomeDocumentCorrection(incomeDocument: SettlementIncomeDocumentCorrectionListItemRestModel) {
        this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: "Anuluj korekte",
                message: "Czy jestes pewien, ze chcesz anulowac ta korekte?: " + incomeDocument.documentNumber,
                links: [],
                options: { buttonColor: "error", layoutColor: "red" },
                buttonType: "yes/no"
            })
            .then(async confirm => {
                if (confirm) {
                    await this.$store.direct.dispatch.settlementIncomeDocument.cancelIncomeDocumentCorrection({ incomeDocumentCorrectionId: incomeDocument.id });
                    this.loadIncomeDocumentCorrectionList();
                }
            })
    }

    async deleteIncomeDocumentCorrectionClick(incomeDocumentCorrection: SettlementIncomeDocumentCorrectionListItemRestModel) {
        this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: "Usun korekte dokumentu przychodowego",
                message: "Czy na pewno chcesz usunac ta korekte dokumentu przychodowego?: " + incomeDocumentCorrection.documentNumber,
                links: [],
                options: { buttonColor: "error", layoutColor: "red" },
                buttonType: "yes/no"
            })
            .then(async confirm => {
                if (confirm) {
                    const isIncomeDocumentDeleted = await this.$store.direct.dispatch.settlementIncomeDocument.deleteIncomeDocument({ incomeDocumentId: incomeDocumentCorrection.id, isDocumentCorrection: true });

                    if (!isIncomeDocumentDeleted) {
                        this.$store.direct.dispatch.confirm
                            .openConfirmDialog({
                                showConfirmDialog: true,
                                title: "Dokument przychodowy nie moze zostac usuniety",
                                message: "Dokument przychodowy nie moze zostac usuniety: " + incomeDocumentCorrection.documentNumber,
                                links: [],
                                options: { buttonColor: "error", layoutColor: "error" },
                                buttonType: "ok"
                            });
                    }
                    else {
                        this.loadIncomeDocumentCorrectionList();
                    }
                }
            });
    }

    async createPaymentClick(incomeDocument: SettlementIncomeDocumentCorrectionListItemRestModel) {
        await this.$store.direct.dispatch.settlementIncomeDocument.loadIncomeDocument({ incomeDocumentId: incomeDocument.id });

        const totalPaymentAmount = this.incomeDocument.paymentObligationList.length == 1 ? this.incomeDocument.paymentObligationList[0].paymentAmount : 0;
        const paymentCurrencyCode = this.incomeDocument.paymentObligationList.length == 1 ? this.incomeDocument.paymentObligationList[0].paymentCurrencyCode : undefined;

        await this.$store.direct.dispatch.settlementPayment.loadIncomePayment({ paymentGroupId: "" });

        this.incomePayment.paymentAmount = totalPaymentAmount;
        if (paymentCurrencyCode != undefined) {
            this.incomePayment.currencyCode = paymentCurrencyCode;
        }

        this.incomePayment.paymentIncomeDocumentRequestList = [{ incomeDocument: { ...this.incomeDocument } as any as SettlementIncomeDocumentRestModel, paymentAmount: 0 } as SettlementPaymentIncomeDocumentRestModel];

        this.$store.direct.dispatch.settlementPayment
            .openIncomePaymentDialog({
                title: `${this.$t("payment.addIncomePayment")}`,
            })
            .then(async confirm => {
                if (confirm) {
                    this.loadIncomeDocumentCorrectionList();
                }
            })
    }

    selectAllItemsOnThePage() {
        this.incomeDocumentCorrectionList.forEach((x: SettlementIncomeDocumentCorrectionListItemRestModel) => {
            x.isChecked = this.mainCheckboxIsSelected;
        });
        this.$forceUpdate();

        this.selectedIncomeDocumentCorrectionList = this.incomeDocumentCorrectionList.filter((x: SettlementIncomeDocumentCorrectionListItemRestModel) => {
            if (x.isChecked) {
                return x.id;
            }
        });

        this.$store.direct.dispatch.settlementCommon.calculateSelectedCostOrIncomeDocumentCorrectionListForAmountSummaryRow({ list: this.selectedIncomeDocumentCorrectionList });
    }

    addSelectedIncomeDocumentCorrectionToList() {
        this.selectedIncomeDocumentCorrectionList = this.incomeDocumentCorrectionList.filter((x: SettlementIncomeDocumentCorrectionListItemRestModel) => {
            if (x.isChecked) {
                return x.id;
            }
            else {
                this.mainCheckboxIsSelected = false;
            }
        });

        this.$store.direct.dispatch.settlementCommon.calculateSelectedCostOrIncomeDocumentCorrectionListForAmountSummaryRow({ list: this.selectedIncomeDocumentCorrectionList });
    }

    currentPageInput = this.incomeDocumentCorrectionListPagerOptions.page;
    goToPage() {
        if (this.currentPageInput > 0) {
            this.incomeDocumentCorrectionListPagerOptions.page = this.currentPageInput;
        }
    }

    destroyed() {
        this.selectedIncomeDocumentCorrectionList = [] as SettlementIncomeDocumentCorrectionListItemRestModel[];
    }
}
