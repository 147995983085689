import Vue from "vue";
import { Component } from "vue-property-decorator";
import DepartmentItemRestModel from "../../../../models/rest/register/departmentItemRestModel";
import SupervisorCarrierDocumentOrderRequirementRestModel from "../../../../models/rest/supervisor/supervisorCarrierDocumentOrderRequirementRestModel";

@Component
export default class SupervisorCarrierDocumentOrderRequirementDialog extends Vue {
    validation = [(value: string) => !!value || this.$t("common.validationErrorRequired")];

    get showDialog(): boolean {
        return this.$store.direct.state.register.showSupervisorCarrierDocumentOrderRequirementDialog;
    }

    get model(): SupervisorCarrierDocumentOrderRequirementRestModel {
        return this.$store.direct.state.register.supervisorCarrierDocumentOrderRequirement;
    }

    get departments(): DepartmentItemRestModel[] {
        return this.$store.direct.state.contractor.contractorAssets.departments
    }

    async saveClick() {
        if (this.model.departmentIdcs == undefined) {
            this.model.departmentIdcs = [];
        }
        const valid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
        if (valid) {
            await this.$store.direct.dispatch.register.updateSupervisorCarrierDocumentOrderRequirement();
            this.$store.direct.dispatch.supervisor.getSupervisorCarrierDocumentOrderRequirementList({ forSupervisor: false });
            this.$store.direct.commit.contractor.setContractorAssetsLoaded(false);
            this.$store.direct.dispatch.contractor.loadContractorAssets();
            this.$store.direct.dispatch.supervisor.loadSupervisorAssets();
        }
    }

    async backClick() {
        await this.$store.direct.dispatch.register.closeSupervisorCarrierDocumentOrderRequirementDialog();
    }
}
