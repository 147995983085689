import ContractorContactPersonRestModel from "@/models/rest/contractor/contractorContactPersonRestModel";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Roles } from "../../../models/emums/roles";
import ListItemNumberModel from "../../../models/listItemNumberModel";
import ContractorRestModel from "../../../models/rest/contractor/contractorRestModel";

@Component

export default class ContactTab extends Vue {
    contactPersonListHeaders = [
        { text: this.$t("contractor.lastName"), align: "start", value: "lastName" },
        { text: this.$t("contractor.firstName"), align: "start", value: "firstName" },
        { text: this.$t("contractor.employeRole"), align: "start", value: "role" },
        { text: this.$t("common.position"), align: "start", value: "position" },
        { text: this.$t("contractor.email"), align: "start", value: "email" },
        { text: this.$t("contractor.telephone"), align: "start", value: "telephone" },
        { text: this.$t("contractor.secondphone"), align: "start", value: "secondphone" },
        { text: this.$t("contractor.emergencyphone"), align: "start", value: "emergencyphone" },
        { text: this.$t("contractor.skype"), align: "start", value: "skype" },
        { text: this.$t("contractor.disabled"), align: "start", value: "isDisabled" },
        { text: this.$t("contractor.actions"), align: "right", value: "actions", width: "1%" }
    ];
    emailAddressListHeaders = [
        { text: this.$t("contractor.email"), align: "start", value: "text" },
        { text: this.$t("contractor.actions"), align: "right", value: "actions", width: "1%" }
    ];

    get isContractorNew(): boolean {
        return this.$store.direct.state.contractor.isContractorNew;
    }

    get currentUserRoles() {
        return this.$store.direct.state.auth.userRoles;
    }

    isUserCrmOrCrmLowerOrCrmAdmin(): boolean {
        if (![Roles.CrmAdmin, Roles.Crm, Roles.Crm].some(x => this.currentUserRoles.includes(x))) {
            return false;
        }
        return true;
    }

    get allowContractorEdit(): boolean {
        return this.$store.direct.state.contractor.allowContractorEdit;
    }

    get allowContractorContactPersonAddOrEdit() {
        return this.allowContractorEdit || this.isUserCrmOrCrmLowerOrCrmAdmin;
    }

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get contractor(): ContractorRestModel {
        return this.$store.direct.state.contractor.contractor;
    }

    get contactPersonList(): ContractorContactPersonRestModel[] {
        return this.$store.direct.state.contractor.contactPersonList;
    }

    set contactPersonList(val: ContractorContactPersonRestModel[]) {
        this.$store.direct.commit.contractor.setContactPersonList(val);
    }

    get emailAddressList(): ListItemNumberModel[] {
        return this.$store.direct.state.contractor.emailAddressList;
    }

    set emailAddressList(val: ListItemNumberModel[]) {
        this.$store.direct.commit.contractor.setEmailAddressList(val);
    }

    itemRowTextColor(item: ContractorContactPersonRestModel) {
        let style = ""
        if (item.isDisabled) {
            style = "red--text";
        }
        return style;
    }

    async contactPersonCreateClick() {
        if (this.isContractorNew == false) {
            await this.$store.direct.dispatch.contractor.loadContactPerson();
        }
        else {
            this.$store.direct.dispatch.confirm.openConfirmDialog(
                {
                    showConfirmDialog: true,
                    title: this.$t("contractor.savingContractorIsRequired").toString(),
                    message: this.$t("contractor.saveContractorIsRequiredForThisAction").toString(),
                    links: [],
                    options: { buttonColor: "error", layoutColor: "error" },
                    buttonType: "ok"
                });
        }
    }

    async contactPersonEditClick(model: ContractorContactPersonRestModel) {
        await this.$store.direct.dispatch.contractor.loadContactPerson({ id: model.id });
    }

    async emailAddressEditClick(model: ListItemNumberModel) {
        await this.$store.direct.dispatch.contractor.loadEmailAddress({ id: model.value });
    }

    emailAddressDeleteClick(emailAddress: ListItemNumberModel) {
        this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: `${this.$t("common.delete")}`,
                message: `${this.$t("contractor.emailAddressDeleteMessage")}`,
                links: [],
                options: { buttonColor: "error", layoutColor: "red" },
                buttonType: "yes/no"
            })
            .then(confirm => {
                if (confirm) {
                    this.$store.direct.dispatch.contractor.deleteEmailAddress({ id: emailAddress.value });
                }
            });
    }

    destroyed() {
        this.contactPersonList = [] as ContractorContactPersonRestModel[];
    }
}
