import dayjs from "dayjs";
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import i18n from "../../../assets/i18n";
import ConfirmDialog from "../../../layouts/confirmDialog/confirmDialog.vue";
import { SettlementPaymentStatusEnum } from "../../../models/emums/settlement/settlementPaymentStatusEnum";
import ListItemNumberModel from "../../../models/listItemNumberModel";
import ContractorSearchItemModel from "../../../models/rest/contractor/contractorSearchItemModel";
import CountryRestModel from "../../../models/rest/register/countryRestModel";
import SettlementCostDocumentCorrectionListItemRestModel from "../../../models/rest/settlement/cost-document/settlementCostDocumentCorrectionListItemRestModel";
import SettlementCostDocumentListRequestModel from "../../../models/rest/settlement/cost-document/settlementCostDocumentListRequestModel";
import SettlementCostDocumentRestModel from "../../../models/rest/settlement/cost-document/settlementCostDocumentRestModel";
import SettlementIncomeDocumentCorrectionListItemRestModel from "../../../models/rest/settlement/income-document/settlementIncomeDocumentCorrectionListItemRestModel";
import SettlementPaymentCostDocumentRestModel from "../../../models/rest/settlement/payment/settlementPaymentCostDocumentRestModel";
import SettlementPaymentRestModel from "../../../models/rest/settlement/payment/settlementPaymentRestModel";
import SettlementAmountSummaryRestModel from "../../../models/rest/settlement/settlementAmountSummaryRestModel";
import SettlementDocumentStatusRestModel from "../../../models/rest/settlement/settlementDocumentStatusRestModel";
import SupervisorRestModel from "../../../models/rest/supervisor/supervisorRestModel";
import SettlementAmountSummaryRow from "../settlement-amount-summary-row.vue";
import CostPaymentDialog from "../cost-payment/cost-payment-dialog.vue";

@Component({
    components: {
        "confirm-dialog": ConfirmDialog,
        "cost-payment-dialog": CostPaymentDialog,
        "settlement-amount-summary-row": SettlementAmountSummaryRow,
    }
})


export default class CostDocumentCorrectionList extends Vue {
    createdAtDateLabel = this.$t("common.createdAt");
    paymentTermDateLabel = this.$t("costDocument.paymentTermDate");
    documentCountryLabel = this.$t("costDocument.documentCountry");
    searchPaymentTermDate = "";
    searchCreatedAtDate = "";
    contractorSearch = "";
    costPaymentListPagerOptions = {
        itemsPerPage: -1,
    };
    supervisorSearch = "";
    supervisor = {} as SupervisorRestModel;
    selectedCountryCodes = [] as string[];
    mainCheckboxIsSelected = false;
    showAmountSummaryRowForCalculatedSelectedList = false;

    costDocumentCorrectionListHeaders = [
        {
            align: "start",
            value: "checkbox",
            width: "1%",
            sortable: false,
        },
        {
            text: "Id",
            align: "start",
            value: "id",
            width: "1%",
            sortable: true,
        },
        {
            text: this.$t("costDocument.documentNumber"),
            align: "start",
            value: "documentNumber",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.correctedDocumentNumber"),
            align: "center",
            value: "correctedDocumentNumber",
            sortable: true,
        },
        {
            text: this.$t("contractor.branch"),
            align: "start",
            value: "branchId",
            sortable: true,
        },
        {
            text: this.$t("costDocument.status"),
            align: "start",
            value: "costDocumentStatusName",
            sortable: true,
        },
        {
            text: this.$t("costDocument.documentType"),
            align: "start",
            value: "documentTypeCode",
            sortable: true,
        },
        {
            text: this.$t("costDocument.documentCountry"),
            align: "start",
            value: "documentCountry",
            width: "1%",
            sortable: true,
        },
        {
            text: this.$t("supervisor.orderId"),
            align: "start",
            value: "supervisorOrderIdcsString",
            sortable: false,
        },
        {
            text: this.$t("costDocument.contractorName"),
            align: "start",
            value: "contractorName",
            sortable: true,
        },
        {
            text: this.$t("costDocument.contractorTinWithPrefix"),
            align: "start",
            value: "contractorTinWithPrefix",
            sortable: true,
        },
        {
            text: this.$t("costDocument.saleDate"),
            align: "start",
            value: "saleDate",
            sortable: true,
        },
        {
            text: this.$t("costDocument.issueDate"),
            align: "start",
            value: "issueDate",
            sortable: true,
        },
        {
            text: this.$t("costDocument.receivedDate"),
            align: "start",
            value: "receivedDate",
            sortable: true,
        },
        {
            text: this.$t("costDocument.entryDate"),
            align: "start",
            value: "entryDate",
            sortable: true,
        },
        {
            text: this.$t("costDocument.paymentTermDate"),
            align: "start",
            value: "paymentTermDate",
            sortable: true,
        },
        {
            text: this.$t("costDocument.paymentDaysLeft"),
            align: "start",
            value: "paymentDaysLeft",
            width: "1%",
            sortable: true,
        },
        {
            text: this.$t("costDocument.paymentDate"),
            align: "start",
            value: "paymentDate",
            sortable: true,
        },
        {
            text: "",
            align: "center",
            value: "paymentStatus",
            sortable: true,
        },
        {
            text: this.$t("common.currency"),
            align: "center",
            value: "currencyCode",
            sortable: true,
        },
        {
            text: this.$t("costDocument.totalNetto"),
            align: "end",
            value: "totalNettoDifference",
            sortable: true,
        },
        {
            text: this.$t("costDocument.totalTax"),
            align: "end",
            value: "totalTaxDifference",
            sortable: true,
        },
        {
            text: this.$t("costDocument.totalBrutto"),
            align: "end",
            value: "totalBruttoDifference",
            sortable: true,
        },
        {
            text: this.$t("costDocument.totalNettoPLN"),
            align: "end",
            value: "totalNettoPlnDifference",
            sortable: true,
        },
        {
            text: this.$t("costDocument.totalTaxPLN"),
            align: "end",
            value: "totalTaxPlnDifference",
            sortable: true,
        },
        {
            text: this.$t("costDocument.totalBruttoPLN"),
            align: "end",
            value: "totalBruttoPlnDifference",
            sortable: true,
        },
        {
            text: this.$t("costDocument.isExportedToOptima"),
            align: "end",
            value: "isExportedToOptima",
            sortable: false,
        },
        {
            text: this.$t("common.actions"),
            align: "right",
            value: "actions",
            width: "1%",
            sortable: false,
        }
    ];

    costPaymentListHeaders = [
        {
            text: "Id",
            align: "start",
            value: "id",
            width: "1%",
            sortable: true,
        },
        {
            text: this.$t("costDocument.paymentType"),
            align: "start",
            value: "paymentTypeId",
            sortable: false,
        },
        {
            text: this.$t("payment.amount"),
            align: "start",
            value: "paymentAmount",
            sortable: false,
        },
        {
            text: this.$t("payment.currency"),
            align: "start",
            value: "currencyCode",
            sortable: false,
        },
        {
            text: this.$t("common.createdBy"),
            align: "start",
            value: "createdByUserName",
            sortable: false,
        },
        {
            text: this.$t("payment.paymentDate"),
            align: "start",
            value: "paymentDate",
            sortable: false,
        },
    ];

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get costDocumentCorrectionListLoading(): boolean {
        return this.$store.direct.state.settlementCostDocument.costDocumentCorrectionListLoading;
    }

    get costDocumentCorrectionList(): SettlementCostDocumentCorrectionListItemRestModel[] {
        return this.$store.direct.state.settlementCostDocument.costDocumentCorrectionList;
    }

    get costDocumentCorrectionListTotalCount(): number {
        return this.$store.direct.state.settlementCostDocument.costDocumentCorrectionListTotalCount;
    }

    get costDocumentCorrectionListPagerOptions(): any {
        return this.$store.direct.state.settlementCostDocument.costDocumentCorrectionListPagerOptions;
    }

    set costDocumentCorrectionListPagerOptions(value: any) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentCorrectionListPagerOptions(value);
    }

    get costDocumentCorrectionListAmountSummaryList(): SettlementAmountSummaryRestModel[] {
        let amountSummary = this.$store.direct.state.settlementCostDocument.costDocumentCorrectionListAmountSummaryList;

        if (amountSummary == undefined) {
            amountSummary = [] as SettlementAmountSummaryRestModel[];
        }
        return amountSummary;
    }

    get costDocumentCorrectionListSearchText(): string {
        return this.$store.direct.state.settlementCostDocument.costDocumentCorrectionListSearchText;
    }

    set costDocumentCorrectionListSearchText(value: string) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentCorrectionListSearchText(value);
    }

    get costDocumentCorrectionListSelectedBranch(): number {
        return this.$store.direct.state.settlementCostDocument.costDocumentCorrectionListSelectedBranch;
    }

    set costDocumentCorrectionListSelectedBranch(value: number) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentCorrectionListSelectedBranch(value);
    }

    get costDocumentCorrectionListSelectedDocumentStatusIdcs(): number[] {
        return this.$store.direct.state.settlementCostDocument.costDocumentCorrectionListSelectedDocumentStatusIdcs;
    }

    set costDocumentCorrectionListSelectedDocumentStatusIdcs(value: number[]) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentCorrectionListSelectedDocumentStatusIdcs(value);
    }

    get searchContractors(): ContractorSearchItemModel[] {
        return this.$store.direct.state.settlementCostDocument.costDocumentCorrectionListSearchContractors;
    }

    set searchContractors(value: ContractorSearchItemModel[]) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentCorrectionListSearchContractors(value);
    }

    get searchContractorIdcs(): number[] {
        return this.$store.direct.state.settlementCostDocument.costDocumentCorrectionListSearchContractorIdcs;
    }

    set searchContractorIdcs(value: number[]) {
        this.$store.direct.commit.settlementCostDocument.setCostDocumentCorrectionListSearchContractorIdcs(value);
    }

    get footerPropsForSettlement(): any {
        return this.$store.direct.state.settlementCommon.footerPropsForSettlement;
    }

    get branchList(): ListItemNumberModel[] {
        const branchList = this.$store.direct.state.contractor.contractorAssets.branches;
        let clonedBranchList = [] as ListItemNumberModel[];
        if (branchList == undefined) {
            this.$store.direct.dispatch.contractor.loadContractorAssets();
        }
        else {
            clonedBranchList = [...branchList];
            clonedBranchList.push({ value: 0, text: i18n.t("common.all") } as ListItemNumberModel);
        }
        return clonedBranchList;
    }

    get costDocumentStatuses(): SettlementDocumentStatusRestModel[] {
        const statusesList = this.$store.direct.state.settlementCommon.settlementAssets.costDocumentStatuses;
        if (statusesList == undefined) {
            this.$store.direct.dispatch.settlementCommon.loadSettlementAssets();
        }
        return statusesList;
    }

    get contractorFound(): ContractorSearchItemModel[] {
        return this.$store.direct.state.common.contractorSearch;
    }

    get supervisorFound(): SupervisorRestModel[] {
        return this.$store.direct.state.supervisor.supervisorSearch;
    }

    set supervisorFound(val: SupervisorRestModel[]) {
        this.$store.direct.commit.supervisor.setSupervisorSearch(val);
    }

    get branches(): ListItemNumberModel[] {
        const branches = this.$store.direct.state.contractor.contractorAssets.branches;
        if (branches == undefined) {
            this.$store.direct.dispatch.contractor.loadContractorAssets();
        }
        return branches;
    }

    get countryList(): CountryRestModel[] {
        return this.$store.direct.state.contractor.contractorAssets.countries;
    }

    get paymentTypeList(): ListItemNumberModel[] {
        return this.$store.direct.state.settlementCommon.settlementAssets.paymentTypes;
    }

    get costPayment(): SettlementPaymentRestModel {
        return this.$store.direct.state.settlementPayment.payment;
    }

    set costPayment(val: SettlementPaymentRestModel) {
        this.$store.direct.commit.settlementPayment.setPayment(val);
    }

    get costDocument(): SettlementCostDocumentRestModel {
        return this.$store.direct.state.settlementCostDocument.costDocument;
    }

    get selectedCostDocumentCorrectionList(): SettlementCostDocumentCorrectionListItemRestModel[] {
        return this.$store.direct.state.settlementCostDocument.selectedCostDocumentCorrectionList;
    }

    set selectedCostDocumentCorrectionList(val: SettlementCostDocumentCorrectionListItemRestModel[]) {
        this.$store.direct.commit.settlementCostDocument.setSelectedCostDocumentCorrectionList(val);
    }

    get calculatedSelectedListForAmountSummaryRow(): SettlementAmountSummaryRestModel[] {
        return this.$store.direct.state.settlementCommon.calculatedSelectedListForAmountSummaryRow;
    }

    @Watch("costDocumentCorrectionListPagerOptions", { deep: true })
    pagerOptionsHandles() {
        this.loadCostDocumentCorrectionList();
    }

    @Watch("contractorSearch")
    async contractorSearchLoadItems(val: string) {
        await this.$store.direct.dispatch.common.searchContractors({ searchText: val, contractorTypes: [], contractorPropertiesEnabled: [], source: "" });
        if (this.searchContractors.length > 0) {
            this.searchContractors.forEach((x: any) => {
                if (!this.contractorFound.includes(x)) {
                    this.contractorFound.push(x);
                }
            })
        }
    }

    @Watch("supervisorSearch")
    async supervisorSearchLoadItems(val: string) {
        if (this.supervisorSearch) {
            await this.$store.direct.dispatch.supervisor.searchSupervisorsForSettlement({ searchText: val });
        }
    }

    created() {
        this.$store.direct.dispatch.settlementCommon.loadSettlementAssets();
        this.loadCostDocumentCorrectionList();
    }

    formatPaymentTypeIdToPaymentName(paymentTypeId: number) {
        if (this.paymentTypeList != undefined) {
            return this.paymentTypeList.find(x => x.value == paymentTypeId)?.text;
        }
    }

    formatBranchIdToBranchName(branchId: any) {
        if (this.branches != undefined) {
            return this.branches.find(x => x.value == branchId)?.text;
        }
    }

    getPaymentStatusIconColor(item: SettlementCostDocumentCorrectionListItemRestModel): string {
        if (item.paymentStatusId == SettlementPaymentStatusEnum.Niezaplacona) {
            return "red--text";
        }
        if (item.paymentStatusId == SettlementPaymentStatusEnum.ZaplataCzesciowa) {
            return "blue--text";
        }
        else if (item.paymentStatusId == SettlementPaymentStatusEnum.Zaplacona) {
            return "green--text";
        }
        else if (item.paymentStatusId == SettlementPaymentStatusEnum.Nadplata) {
            return "yellow--text";
        }
        else {
            return "";
        }
    }

    itemRowTextColor(item: SettlementIncomeDocumentCorrectionListItemRestModel) {
        let style = ""
        item.paymentDaysLeft < 0 ? style += "red--text " : "";
        item.isCancelled ? style += "text-decoration-line-through " : "";
        return style;
    }

    formatDate(dateTime: any) {
        if (dateTime != null) {
            return dayjs(dateTime).format("YYYY-MM-DD");
        }
    }

    searchClick() {
        this.loadCostDocumentCorrectionList();
    }

    clearSearchContractorClick() {
        this.searchContractorIdcs = [] as number[];
        this.loadCostDocumentCorrectionList();
    }

    clearSearchSupervisorClick() {
        this.supervisor = {} as SupervisorRestModel;
        this.loadCostDocumentCorrectionList();
    }

    clearSearchPaymentTermDate() {
        this.searchPaymentTermDate = "";
        this.loadCostDocumentCorrectionList();
    }

    clearSearchCreatedAtDate() {
        this.searchCreatedAtDate = "";
        this.loadCostDocumentCorrectionList();
    }

    clearSelectedCountryCode() {
        this.selectedCountryCodes = [];
        this.loadCostDocumentCorrectionList();
    }

    clearAllFilters() {
        this.costDocumentCorrectionListSearchText = "";
        this.costDocumentCorrectionListSelectedBranch = 0;
        this.costDocumentCorrectionListSelectedDocumentStatusIdcs = [] as number[];
        this.searchContractorIdcs = [] as number[];
        this.searchContractors = [] as ContractorSearchItemModel[];
        this.supervisor = undefined as any;
        this.searchPaymentTermDate = "";
        this.searchCreatedAtDate = "";
        this.selectedCountryCodes = [];

        this.loadCostDocumentCorrectionList();
    }

    async loadCostDocumentCorrectionList() {
        await this.$store.direct.dispatch.settlementCostDocument.loadCostDocumentCorrectionList({ requestModel: this.prepareCostDocumentCorrectionRequestModel() });
    }

    async createXlsReportClick() {
        await this.$store.direct.dispatch.settlementCostDocument.downloadXlsReportCostDocumentCorrectionList({ requestModel: this.prepareCostDocumentCorrectionRequestModel() });
    }

    prepareCostDocumentCorrectionRequestModel(): SettlementCostDocumentListRequestModel {
        return {
            searchText: this.costDocumentCorrectionListSearchText,
            searchBranchId: this.costDocumentCorrectionListSelectedBranch,
            selectedDocumentStatusIdcs: this.costDocumentCorrectionListSelectedDocumentStatusIdcs,
            page: this.costDocumentCorrectionListPagerOptions.page,
            pageSize: this.costDocumentCorrectionListPagerOptions.itemsPerPage,
            sortBy: this.costDocumentCorrectionListPagerOptions.sortBy[0],
            sortDesc: this.costDocumentCorrectionListPagerOptions.sortDesc[0],
            searchContractorIdcs: (this.searchContractors != undefined && this.searchContractors.length > 0) ? this.searchContractors.map(x => x.value) : [],
            searchSupervisorId: this.supervisor?.id,
            searchPaymentTermDateFrom: this.searchPaymentTermDate,
            searchCreatedAtFromDate: this.searchCreatedAtDate,
            searchCreatedAtToDate: this.searchCreatedAtDate,
            searchCountryCodes: this.selectedCountryCodes,
            idList: this.selectedCostDocumentCorrectionList.map((x: SettlementCostDocumentCorrectionListItemRestModel) => {
                return x.id;
            }),
            searchSelectedCurrency: undefined,
        } as SettlementCostDocumentListRequestModel;
    }

    editCostDocumentClick(costDocumentId: number) {
        return "/settlement/cost-document/correction/edit/" + costDocumentId;
    }

    async cancelCostDocumentCorrection(costDocument: SettlementCostDocumentCorrectionListItemRestModel) {
        this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: "Anuluj korekte",
                message: "Czy jestes pewien, ze chcesz anulowac ta korekte?: " + costDocument.documentNumber,
                links: [],
                options: { buttonColor: "error", layoutColor: "red" },
                buttonType: "yes/no"
            })
            .then(async confirm => {
                if (confirm) {
                    await this.$store.direct.dispatch.settlementCostDocument.cancelCostDocumentCorrection({ costDocumentCorrectionId: costDocument.id });
                    this.loadCostDocumentCorrectionList();
                }
            })
    }

    async createPaymentClick(costDocument: SettlementCostDocumentCorrectionListItemRestModel) {
        await this.$store.direct.dispatch.settlementCostDocument.loadCostDocument({ costDocumentId: costDocument.id });

        const totalPaymentAmount = this.costDocument.paymentObligationList.length == 1 ? this.costDocument.paymentObligationList[0].paymentAmount : 0;
        const paymentCurrencyCode = this.costDocument.paymentObligationList.length == 1 ? this.costDocument.paymentObligationList[0].paymentCurrencyCode : undefined;

        await this.$store.direct.dispatch.settlementPayment.loadCostPayment({ paymentGroupId: "" });

        this.costPayment.paymentAmount = totalPaymentAmount;
        if (paymentCurrencyCode != undefined) {
            this.costPayment.currencyCode = paymentCurrencyCode;
        }

        this.costPayment.paymentCostDocumentRequestList = [{ costDocument: { ...this.costDocument } as any as SettlementCostDocumentRestModel, paymentAmount: 0 } as SettlementPaymentCostDocumentRestModel];

        this.$store.direct.dispatch.settlementPayment
            .openCostPaymentDialog({
                title: `${this.$t("payment.addCostPayment")}`,
            })
            .then(async confirm => {
                if (confirm) {
                    this.loadCostDocumentCorrectionList();
                }
            })
    }

    selectAllItemsOnThePage() {
        this.costDocumentCorrectionList.forEach((x: SettlementCostDocumentCorrectionListItemRestModel) => {
            x.isChecked = this.mainCheckboxIsSelected;
        });
        this.$forceUpdate();

        this.selectedCostDocumentCorrectionList = this.costDocumentCorrectionList.filter((x: SettlementCostDocumentCorrectionListItemRestModel) => {
            if (x.isChecked) {
                return x.id;
            }
        });

        this.$store.direct.dispatch.settlementCommon.calculateSelectedCostOrIncomeDocumentCorrectionListForAmountSummaryRow
            ({ list: this.selectedCostDocumentCorrectionList });
    }

    addSelectedCostDocumentCorrectionToList() {
        this.selectedCostDocumentCorrectionList = this.costDocumentCorrectionList.filter((x: SettlementCostDocumentCorrectionListItemRestModel) => {
            if (x.isChecked) {
                return x.id;
            }
            else {
                this.mainCheckboxIsSelected = false;
            }
        });

        this.$store.direct.dispatch.settlementCommon.calculateSelectedCostOrIncomeDocumentCorrectionListForAmountSummaryRow({ list: this.selectedCostDocumentCorrectionList });
    }

    currentPageInput = this.costDocumentCorrectionListPagerOptions.page;
    goToPage() {
        if (this.currentPageInput > 0) {
            this.costDocumentCorrectionListPagerOptions.page = this.currentPageInput;
        }
    }
    destroyed() {
        this.selectedCostDocumentCorrectionList = [] as SettlementCostDocumentCorrectionListItemRestModel[];
    }
}
