import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { stringMaxLength } from "../../../assets/validation/common";
import { ContractorTypes } from "../../../models/emums/contractorTypes";
import { Roles } from "../../../models/emums/roles";
import VehicleRestModel from "../../../models/rest/vehicle/vehicleRestModel";
import VehicleTypeRestModel from "../../../models/rest/vehicle/vehicleTypeRestModel";

@Component
export default class VehicleDialog extends Vue {
    validation = {
        registrationNumber: [stringMaxLength(this.$i18n, this.$t("vehicle.registrationNumber"), 20), (value: string) => !!value || this.$t("common.validationErrorRequired")],
        palletCount: [(value: string) => !!value || this.$t("common.validationErrorRequired")],
        maxLoad: [stringMaxLength(this.$i18n, this.$t("vehicle.maxLoad"), 50), (value: string) => !!value || this.$t("common.validationErrorRequired")],
        vehicleTypeId: [(value: string) => !!value || this.$t("common.validationErrorRequired")],
        contractor: [(value: string) => !!value || this.$t("common.validationErrorRequired")],
        freightForwarder: [
            (value: string) => !!value || this.$t("common.validationErrorRequired")
        ],
    }

    contractorSearch = "";
    disableSelectContractorField = true;
    palletCount: string[] = Array.from({ length: 34 }, (_, i) => (i + 1).toString());

    @Watch("contractorSearch")
    contractorSearchLoadItems(val: string) {
        this.$store.direct.dispatch.common.searchContractors({ searchText: val, contractorTypes: [ContractorTypes.Carrier], contractorPropertiesEnabled: [], source: "" });
    }

    get currentUserRoles(): string[] {
        return this.$store.state.auth.userRoles;
    }

    get showDialog(): boolean {
        return this.$store.direct.state.vehicle.showVehicleDialog;
    }

    get title(): string {
        return this.$store.direct.state.vehicle.vehicleDialogTitle;
    }

    get contractorFound() {
        return this.$store.direct.state.common.contractorSearch;
    }

    get vehicle(): VehicleRestModel {
        const vehicle = this.$store.direct.state.vehicle.vehicle;
        if (vehicle.contractor != undefined) {
            this.contractorFound.push({ text: vehicle.contractor.text, value: vehicle.contractor.value, additionalInfo: "" });
        }
        return vehicle;
    }

    set vehicle(val: VehicleRestModel) {
        this.vehicle = val;
    }

    get vehicleTypeList(): VehicleTypeRestModel[] {
        return this.$store.direct.state.common.vehicleTypeListForMap;
    }

    created() {
        this.$store.direct.dispatch.common.loadVehicleTypeList({ source: "map" });
        this.checkIfUserContainsRightRole();
    }

    checkIfUserContainsRightRole() {
        if (!this.currentUserRoles.includes(Roles.Admin) && !this.currentUserRoles.includes(Roles.Disponent) && !this.currentUserRoles.includes(Roles.DisponentAdmin)) {
            return this.disableSelectContractorField = true;
        }
        return this.disableSelectContractorField = false;
    }

    cancelClick() {
        this.$store.direct.dispatch.vehicle.isNotConfirmedVehicleDialog();
    }

    async saveVehicle() {
        const valid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
        if (valid) {
            await this.$store.direct.dispatch.vehicle.isConfirmedVehicleDialog();
        }
    }
}
