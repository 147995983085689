import { RouteConfig } from "vue-router";
import { Roles } from "../models/emums/roles";

const routes: Array<RouteConfig> = [
    {
        path: "/tracking",
        component: require("@/layouts/layout.vue").default,
        children: [
            {
                path: "",
                name: "Tracking map",
                component: require("@/components/tracking/tracking.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.Disponent, Roles.DisponentAdmin, Roles.TrackingClientAdmin]
                }
            }
        ]
    }
];

export default routes;