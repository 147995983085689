import { moduleActionContext } from "@/store/index";
import { AxiosError, AxiosResponse } from "axios";
import { defineModule } from "direct-vuex";
import CurrencyRestModel from "../../models/rest/register/currencyRestModel";
import SettlementCostDocumentTransferOrderPackageListRequestModel from "../../models/rest/settlement/cost-document/transfer-order-package/settlementCostDocumentTransferOrderPackageListRequestModel";
import SettlementCostDocumentTransferOrderPackageRestModel from "../../models/rest/settlement/cost-document/transfer-order-package/settlementCostDocumentTransferOrderPackageRestModel";
import SettlementAmountSummaryRestModel from "../../models/rest/settlement/settlementAmountSummaryRestModel";
import { axiosInstance } from "../../plugins/axios";

export interface SettlementCostDocumentTransferOrderPackageModuleStateInterface {

    transferOrderPackageList: SettlementCostDocumentTransferOrderPackageRestModel[],
    transferOrderPackageListLoading: boolean;
    transferOrderPackageListTotalCount: number,
    transferOrderPackageListSearchBranchId: number,
    transferOrderPackageListSelectedCurrency: CurrencyRestModel,
    transferOrderPackageListSearchPackageNumber: string,
    transferOrderPackageListSearchCreatedAtFromDate: string,
    transferOrderPackageListSearchCreatedAtToDate: string,
    transferOrderPackageListSearchSentFromDate: string,
    transferOrderPackageListSearchSentToDate: string,
    transferOrderPackageListPagerOptions: any,
    transferOrderPackageListAmountSummaryList: SettlementAmountSummaryRestModel[],
}

const settlementCostDocumentTransferOrderPackageModule = defineModule({
    namespaced: true,
    state: {
        transferOrderPackageList: [] as SettlementCostDocumentTransferOrderPackageRestModel[],
        transferOrderPackageListLoading: false,
        transferOrderPackageListTotalCount: 0,
        transferOrderPackageListSearchBranchId: 0,
        transferOrderPackageListSelectedCurrency: {} as CurrencyRestModel,
        transferOrderPackageListSearchPackageNumber: "",
        transferOrderPackageListSearchCreatedAtFromDate: "",
        transferOrderPackageListSearchCreatedAtToDate: "",
        transferOrderPackageListSearchSentFromDate: "",
        transferOrderPackageListSearchSentToDate: "",
        transferOrderPackageListPagerOptions: {
            page: 1,
            itemsPerPage: 30,
            sortBy: ["id"],
            sortDesc: [true]
        },
        transferOrderPackageListAmountSummaryList: [] as SettlementAmountSummaryRestModel[],
    } as SettlementCostDocumentTransferOrderPackageModuleStateInterface,
    mutations: {
        setTransferOrderPackageList(state, value: SettlementCostDocumentTransferOrderPackageRestModel[]) {
            state.transferOrderPackageList = value;
        },
        setTransferOrderPackageListLoading(state, value: boolean) {
            state.transferOrderPackageListLoading = value;
        },
        setTransferOrderPackageListTotalCount(state, value: number) {
            state.transferOrderPackageListTotalCount = value;
            if ((state.transferOrderPackageListPagerOptions.page * state.transferOrderPackageListPagerOptions.itemsPerPage) >= (value + state.transferOrderPackageListPagerOptions.itemsPerPage)) {
                state.transferOrderPackageListPagerOptions.page = 1;
            }
        },
        setTransferOrderPackageListSearchBranchId(state, value: number) {
            state.transferOrderPackageListSearchBranchId = value;
        },
        setTransferOrderPackageListSelectedCurrency(state, value: CurrencyRestModel) {
            state.transferOrderPackageListSelectedCurrency = value;
        },
        setTransferOrderPackageListSearchPackageNumber(state, value: string) {
            state.transferOrderPackageListSearchPackageNumber = value;
        },
        setTransferOrderPackageListSearchCreatedAtFromDate(state, value: string) {
            state.transferOrderPackageListSearchCreatedAtFromDate = value;
        },
        setTransferOrderPackageListSearchCreatedAtToDate(state, value: string) {
            state.transferOrderPackageListSearchCreatedAtToDate = value;
        },
        setTransferOrderPackageListSearchSentFromDate(state, value: string) {
            state.transferOrderPackageListSearchSentFromDate = value;
        },
        setTransferOrderPackageListSearchSentToDate(state, value: string) {
            state.transferOrderPackageListSearchSentToDate = value;
        },
        setTransferOrderPackageListPagerOptions(state, value) {
            state.transferOrderPackageListPagerOptions = value;
        },
        setTransferOrderPackageListAmountSummaryList(state, value: SettlementAmountSummaryRestModel[]) {
            state.transferOrderPackageListAmountSummaryList = value;
        },
    },
    actions: {
        loadTransferOrderPackageList(context, payload: { requestModel: SettlementCostDocumentTransferOrderPackageListRequestModel }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                commit.setTransferOrderPackageListLoading(true);
                axiosInstance
                    .post("/api/settlementCostDocument/transfer-order-package/list", payload.requestModel)
                    .then((resp: AxiosResponse) => {
                        commit.setTransferOrderPackageList(resp.data.items);
                        commit.setTransferOrderPackageListTotalCount(resp.data.totalItemsCount)
                        commit.setTransferOrderPackageListAmountSummaryList(resp.data.amountSummaryList)
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setTransferOrderPackageListLoading(false);
                    })
            })
        },
        downloadTransferOrderPackageFile(context, payload: { id: number }) {
            const { state } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/settlementCostDocument/transfer-order-package/download/" + payload.id, {
                        responseType: "arraybuffer"
                    })
                    .then((resp: AxiosResponse) => {
                        const blob = new Blob([resp.data], {
                            type: resp.headers["content-type"]
                        });
                        const contentDisposition = resp.headers["content-disposition"];
                        const filename = contentDisposition
                            .split(";")[1]
                            .split("=")[1]
                            .replace('"', "")
                            .replace('"', "");

                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement("a");
                        a.style.display = "none";
                        document.body.appendChild(a);
                        a.download = filename;
                        a.href = url;
                        a.click();
                        window.URL.revokeObjectURL(url);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        async setTransferOrderPackageListAsSent(context, payload: { transferOrderPackageToSetAsSentList: SettlementCostDocumentTransferOrderPackageRestModel[] }) {
            const { commit } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/settlementCostDocument/transfer-order-package/set-package-list-as-sent", payload.transferOrderPackageToSetAsSentList)
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err))
            })
        },
        async deleteTransferOrderPackage(context, payload: { transferOrderPackageId: number }) {
            const { state } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/settlementCostDocument/transfer-order-package/delete/" + payload.transferOrderPackageId)
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err))
            })
        },
    }
});

export default settlementCostDocumentTransferOrderPackageModule;
const _moduleActionContext = (context: any) => moduleActionContext(context, settlementCostDocumentTransferOrderPackageModule)