import Vue from "vue";
import { Component } from "vue-property-decorator";
import SupervisorAttachmentRestModel from "../../models/rest/supervisor/supervisorAttachmentRestModel";
import SupervisorRestModel from "../../models/rest/supervisor/supervisorRestModel";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';
import { Roles } from "../../models/emums/roles";
import ConfirmDialog from "../../layouts/confirmDialog/confirmDialog.vue";

@Component({
    components: {
        VuePdfEmbed,
        "confirm-dialog": ConfirmDialog,
    },
})

export default class SupervisorAttachmentList extends Vue {
    supervisorAttachmentListHeaders = [
        {
            text: this.$t("common.attachment"),
            align: "start",
            value: "name",
            sortable: false,
            visible: true
        },
        {
            text: "CMR",
            align: "center",
            value: "isCmr",
            sortable: false,
            visible: true
        },
        {
            text: this.$t("supervisor.setAsCmr"),
            align: "center",
            value: "setAsCmr",
            sortable: false,
            visible: this.userIsAdminOrAccountant,
        },
        {
            text: this.$t("supervisor.actions"),
            align: "center",
            value: "actions",
            width: "1%",
            sortable: false,
            visible: true
        }
    ];

    isDocumentLoading = false;
    showAttachmentViewCard = false;
    showAlert = false;
    pagerOptions = {
        page: 1,
        itemsPerPage: 100
    };

    get currentAttachment(): string {
        const currentAttachment = this.$store.direct.state.supervisor.currentAttachment;
        if (typeof (currentAttachment) === "string") {
            this.showAttachmentViewCard = true;
            return 'data:application/pdf;base64,' + currentAttachment;
        }
        return "";
    }

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get computedHeaders(): any[] {
        return this.supervisorAttachmentListHeaders.filter(x => x.visible == true);
    }

    get attachmentList(): SupervisorAttachmentRestModel[] {
        return this.$store.direct.state.supervisor.attachmentList;
    }

    get supervisor(): SupervisorRestModel {
        return this.$store.direct.state.supervisor.supervisor;
    }

    get currentUserRoles(): string[] {
        return this.$store.direct.state.auth.userRoles;
    }

    get userIsAdminOrAccountant(): boolean {
        if (this.currentUserRoles.includes(Roles.Admin) || this.currentUserRoles.includes(Roles.Accountant) || this.currentUserRoles.includes(Roles.AccountantAdmin)) {
            return true;
        }
        return false;
    }

    handleDocumentLoad() { // start loading document
        this.isDocumentLoading = true
    }

    handleDocumentRender() { // stop loading document
        this.isDocumentLoading = false
        this.showAlert = false;
    }

    async created() {
        const supervisorId = Number.parseInt(this.$route.params.id);
        await this.$store.direct.dispatch.supervisor.loadSupervisor({ id: supervisorId });
        await this.$store.direct.dispatch.supervisor.loadSupervisorAttachmentList();
    }

    async setFileAsCmr(item: SupervisorAttachmentRestModel) {
        await this.$store.direct.dispatch.supervisor.setFileAsCmr({ fileId: item.id, isChecked: !item.isCmr });
        await this.$store.direct.dispatch.supervisor.loadSupervisorAttachmentList();
    }

    async downloadFile(item: SupervisorAttachmentRestModel) {
        await this.$store.direct.dispatch.supervisor.downloadFile({ fileId: item.id, forSupervisorMemento: false });
    }

    async deleteSuperviorAttachment(item: SupervisorAttachmentRestModel) {
        await this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: this.$t("supervisor.deleteSupervisorAttachmentTitle").toString(),
                message: this.$t("supervisor.deleteSupervisorAttachmentMessage").toString() + ": " + item.name,
                links: [],
                options: { buttonColor: "error", layoutColor: "red" },
                buttonType: "yes/no"
            })
            .then(async confirm => {
                if (confirm) {
                    await this.$store.direct.dispatch.supervisor.deleteSuperviorAttachment({ fileId: item.id });
                    await this.$store.direct.dispatch.supervisor.loadSupervisorAttachmentList();
                }
            })
    }

    async showFile(item: SupervisorAttachmentRestModel) {
        if (this.isDocumentLoading == false) {
            await this.$store.direct.dispatch.supervisor.getFileBytes({ fileId: item.id });
        }
        else {
            this.showAlert = true;
            setTimeout(() => {
                this.showAlert = false;
            }, 3000)
        }
    }

    showSupervisor() {
        return "/supervisor/edit/" + this.supervisor.id;
    }
}
