import router from "@/router";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import ConfirmDialog from "../../layouts/confirmDialog/confirmDialog.vue";
import { ContractorTypes } from "../../models/emums/contractorTypes";
import { Roles } from "../../models/emums/roles";
import ContractorMementoItemRestModel from "../../models/rest/contractor/contractorMementoItemRestModel";
import ContractorRestModel from "../../models/rest/contractor/contractorRestModel";
import BankAccountDialog from "./dialog/bankAccount.vue";
import ContactPersonDialog from "./dialog/contactPerson.vue";
import DocumentDialog from "./dialog/document.vue";
import EmailAddressDialog from "./dialog/emailAddress.vue";
import KpiGeneratingDialog from "./dialog/kpiGenerating.vue";
import LocationDialog from "./dialog/location.vue";
import NoteDialog from "./dialog/note.vue";
import OperationDialog from "./dialog/operation.vue";
import PolicyDialog from "./dialog/policy.vue";
import BasicTab from "./tab/basic.vue";
import ContactTab from "./tab/contact.vue";
import DocumentTab from "./tab/document.vue";
import FinanceTab from "./tab/finance.vue";
import NoteTab from "./tab/note.vue";
import OperationTab from "./tab/operation.vue";
import PolicyTab from "./tab/policy.vue";
import ProjectTab from "./tab/project.vue";

@Component({
    components: {
        "bank-account-dialog": BankAccountDialog,
        "basic-tab": BasicTab,
        "confirm-dialog": ConfirmDialog,
        "contact-person-dialog": ContactPersonDialog,
        "contact-tab": ContactTab,
        "document-dialog": DocumentDialog,
        "document-tab": DocumentTab,
        "email-address-dialog": EmailAddressDialog,
        "finance-tab": FinanceTab,
        "kpi-generating-dialog": KpiGeneratingDialog,
        "location-dialog": LocationDialog,
        "note-tab": NoteTab,
        "operation-dialog": OperationDialog,
        "operation-tab": OperationTab,
        "note-dialog": NoteDialog,
        "policy-dialog": PolicyDialog,
        "policy-tab": PolicyTab,
        "project-tab": ProjectTab,
    }
})
export default class Contractor extends Vue {
    $refs!: {
        basicTab: BasicTab & {
            validate: () => number;
        };
        financeTab: FinanceTab & {
            validate: () => number;
        };
    };
    validationErrorCount = { basic: 0, finances: 0 };
    contractorActiveTab = "";
    lastContractorMementoPoint: number = 0;
    contractorMementoSelectedPoint: number = 0;

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get contractor(): ContractorRestModel {
        return this.$store.direct.state.contractor.contractor;
    }

    get contractorIsNew(): boolean {
        return this.$store.direct.state.contractor.isContractorNew;
    }

    set contractorIsNew(value: boolean) {
        this.$store.direct.commit.contractor.setIsContractorNew(value);
    }

    get currentUserRoles() {
        return this.$store.direct.state.auth.userRoles;
    }

    get allowContractorEdit(): boolean {
        return this.$store.direct.state.contractor.allowContractorEdit;
    }

    set allowContractorEdit(value: boolean) {
        this.$store.direct.commit.contractor.setAllowContractorEdit(value);
    }

    get contractorMementoListCount(): number {
        const contractorMementoListCount = this.$store.direct.state.contractor.contractorMementoListCount - 1;
        this.lastContractorMementoPoint = contractorMementoListCount;
        this.contractorMementoSelectedPoint = contractorMementoListCount;
        return contractorMementoListCount;
    }

    get contractorMementoList(): ContractorMementoItemRestModel[] {
        return this.$store.direct.state.contractor.contractorMementoList;
    }

    get isContractorInHistoricalState(): boolean {
        return this.$store.direct.state.contractor.isContractorInHistoricalState;
    }

    set isContractorInHistoricalState(val: boolean) {
        this.$store.direct.commit.contractor.setIsContractorInHistoricalState(val);
    }

    hasUserAdminCrmAdminOrCrmOrCrmLowerRole(): boolean {
        if (![Roles.Admin, Roles.CrmAdmin, Roles.Crm, Roles.CrmLower].some(x => this.currentUserRoles.includes(x))) {
            return false;
        }
        return true;
    }

    isVisibleContactTab(): boolean {
        if (![Roles.Admin, Roles.CrmAdmin, Roles.Crm, Roles.CrmLower].some(x => this.currentUserRoles.includes(x)) && this.checkIfContractorIsSelectedType(this.contractor, true)) {
            return false;
        }
        return true;
    }

    isVisibleNoteTab(): boolean {
        if (![Roles.Admin, Roles.CrmAdmin, Roles.Crm, Roles.CrmLower].some(x => this.currentUserRoles.includes(x)) && this.checkIfContractorIsSelectedType(this.contractor, true)) {
            return false;
        }
        return true;
    }

    allowEdit(): boolean {
        if (this.contractor.contractorHasCostOrIncomeDocumentAssigned) {
            if (!(this.currentUserRoles.includes(Roles.Admin) || this.currentUserRoles.includes(Roles.AccountantAdmin) || this.currentUserRoles.includes(Roles.Accountant))) {
                if (this.currentUserRoles.includes(Roles.FullEditingDisponentAdmin) && this.contractor.typeIsCarrier) {
                    this.allowContractorEdit = true;
                    return true;
                }
                if (this.contractor.typeIsClient || this.contractor.typeIsOther || this.contractor.typeIsPartner) {
                    this.allowContractorEdit = false;
                    return false;
                }
                if (!this.contractor.typeIsCarrier || !(this.currentUserRoles.includes(Roles.DisponentAdmin) || this.currentUserRoles.includes(Roles.CrmAdmin))) {
                    this.allowContractorEdit = false;
                    return false;
                }
            }
        }

        if ((this.currentUserRoles.includes(Roles.Disponent) && this.contractor.typeIsCarrier) || (this.currentUserRoles.includes(Roles.DisponentAdmin) && (this.contractor.typeIsCarrier || this.contractor.typeIsClient))) {
            this.allowContractorEdit = true;
            return true;
        }

        if (![Roles.Admin, Roles.CrmAdmin, Roles.Crm, Roles.CrmLower, Roles.Accountant, Roles.AccountantAdmin].some(x => this.currentUserRoles.includes(x))) {
            this.allowContractorEdit = false;
            return false;
        }

        this.allowContractorEdit = true;
        return true;
    }

    checkIfContractorIsSelectedType(contractor: ContractorRestModel, checkIsClient: boolean = false, checkIsCarrier: boolean = false, checkIsPartner: boolean = false, checkIsOther: boolean = false): boolean {
        const contractorIsClient = contractor.typeIsClient;
        const contractorIsCarrier = contractor.typeIsCarrier;
        const contractorIsPartner = contractor.typeIsPartner;
        const contractorIsOther = contractor.typeIsOther;

        const contractorTypes: string[] = [];
        const typesToCheck: string[] = [];

        if (contractorIsClient) {
            contractorTypes.push(ContractorTypes.Client);
        }
        if (contractorIsCarrier) {
            contractorTypes.push(ContractorTypes.Carrier);
        }
        if (contractorIsPartner) {
            contractorTypes.push(ContractorTypes.Partner);
        }
        if (contractorIsOther) {
            contractorTypes.push(ContractorTypes.Other);
        }

        if (checkIsClient) {
            typesToCheck.push(ContractorTypes.Client);
        }
        if (checkIsCarrier) {
            typesToCheck.push(ContractorTypes.Carrier);
        }
        if (checkIsPartner) {
            typesToCheck.push(ContractorTypes.Partner);
        }
        if (checkIsOther) {
            typesToCheck.push(ContractorTypes.Other);
        }

        let hasContractorRole = false;

        typesToCheck.forEach((type: string) => {
            if (contractorTypes.includes(type)) {
                hasContractorRole = true;
            }
        })

        if (hasContractorRole && contractorTypes.length <= typesToCheck.length) {
            return true;
        }
        return false;
    }

    async created(): Promise<void> {
        this.contractorIsNew = this.$route.name == "Contractor create" ? true : false

        if (this.$route.params.deleteDocument == "delete-document") {
            this.$store.direct.dispatch.confirm
                .openConfirmDialog({
                    showConfirmDialog: true,
                    title: "Delete document",
                    message: "Are you sure you want to delete this document?: " + this.$route.params.fileName,
                    links: [
                        {
                            text: "Download document",
                            value: this.$route.params.fileId
                        }
                    ],
                    options: { buttonColor: "error", layoutColor: "red" },
                    buttonType: "yes/no"
                })
                .then(confirm => {
                    if (confirm) {
                        this.$store.direct.dispatch.contractor.deleteDocumentAccept({ id: this.$route.params.fileId });
                        router.push({ name: "Contractor edit" });
                    }
                });
        }
    }

    getContractorMementoItem(value: number): ContractorMementoItemRestModel {
        if (value > -1 && this.contractorMementoList.length > 0) {
            return this.contractorMementoList[value];
        }
        return {} as ContractorMementoItemRestModel;
    }

    async contractorMementoPointChanged(historyPoint: number) {
        if (historyPoint != this.lastContractorMementoPoint) {
            if (historyPoint >= 0) {
                this.isContractorInHistoricalState = true;
                await this.$store.direct.dispatch.contractor.getContractorMementoPoint({ mementoPoint: historyPoint });
            }
            this.lastContractorMementoPoint = historyPoint;
        }
    }

    async checkIfContractorTinExists(): Promise<boolean> {
        const contractorTinExists = await this.$store.direct.dispatch.contractor.checkIfContractorTinExists({ contractorTin: this.contractor.tin, contractorId: this.contractor.id });

        if (contractorTinExists) {
            this.$store.direct.dispatch.confirm
                .openConfirmDialog({
                    showConfirmDialog: true,
                    title: this.$t("contractor.contractorWithThisTinAlreadyExists").toString(),
                    message: this.$t("contractor.contractorWithThisTinAlreadyExists").toString() + ": " + this.contractor.tin,
                    links: [],
                    options: { buttonColor: "error", layoutColor: "error" },
                    buttonType: "ok"
                });
        }

        return contractorTinExists;
    }

    async saveClick(): Promise<void> {
        this.validationErrorCount.basic = this.$refs.basicTab.validate();
        this.validationErrorCount.finances = this.$refs.financeTab.validate();
        if (this.validationErrorCount.basic > 0 || this.validationErrorCount.finances > 0) return;

        let contractorTinExists = await this.checkIfContractorTinExists();

        if (contractorTinExists && (this.contractor.typeIsClient || this.contractor.typeIsOther || this.contractor.typeIsPartner)) {
            await this.$store.direct.dispatch.confirm
                .openConfirmDialog({
                    showConfirmDialog: true,
                    title: this.$t("contractor.contractorWithThisTinAlreadyExists").toString(),
                    message: this.$t("contractor.contractorWithThisTinAlreadyExistsAreYouSureToSetUpNewOne").toString() + ": " + this.contractor.tin,
                    links: [],
                    options: { buttonColor: "error", layoutColor: "error" },
                    buttonType: "yes/no"
                })
                .then(confirm => {
                    if (confirm) {
                        contractorTinExists = false;
                    }
                })
        }

        if (!contractorTinExists) {
            await this.$store.direct.dispatch.contractor.saveContractor();
            router.push({ name: "Contractor list" });
        }
    }
    async backClick(): Promise<void> {
        await router.push({ name: "Contractor list" });
    }
}
