import Vue from "vue";
import { Component } from "vue-property-decorator";
import BaseCriterionModel from "../../models/baseCriterionModel";

@Component({
    props: {
        criterion: {
            type: Object,
            default() {
                return {} as BaseCriterionModel;
            }
        }
    },
})

export default class SelectMultipleCriterion extends Vue {
    criterion!: BaseCriterionModel;
}
