import { moduleActionContext } from "@/store/index";
import { defineModule } from "direct-vuex";

export interface LocationDialogModuleStateInterface {
    showDialog: boolean;
    title: string;
    resolve: any;
}

const LocationDialogModule = defineModule({
    namespaced: true,
    state: {
        showDialog: false,
        title: "",
        resolve: null,
    } as LocationDialogModuleStateInterface,
    mutations: {
        setShowDialog(state, value: boolean) {
            state.showDialog = value;
        },
        setTitle(state, value: string) {
            state.title = value;
        },
        setResolve(state, value: any) {
            state.resolve = value;
        }
    },
    actions: {
        isConfirmed(context) {
            const { state, commit } = _moduleActionContext(context);
            state.resolve(true);
            commit.setShowDialog(false);
        },
        isNotConfirmed(context) {
            const { commit } = _moduleActionContext(context);
            commit.setShowDialog(false);
        },
        openDialog(
            context,
            payload: {
                showDialog: boolean;
                title: string;
            }
        ) {
            const { commit } = _moduleActionContext(context);
            commit.setShowDialog(payload.showDialog);
            commit.setTitle(payload.title);
            return new Promise(resolve => {
                commit.setResolve(resolve);
            });
        }
    }
});

export default LocationDialogModule;
const _moduleActionContext = (context: any) => moduleActionContext(context, LocationDialogModule);
