import Vue from "vue";
import VueRouter, { RouteConfig, Route } from "vue-router";
import store from "@/store";
import contractorRoutes from "./contractor";
import supervisorRoutes from "./supervisor";
import operationRoutes from "./operation";
import dashboards from "./dashboards";
import login from "./login";
import map from "./map";
import onlineTracking from "./online-tracking";
import admin from "./admin";
import forum from "./forum";
import settlement from "./settlement";
import tracking from "./tracking";
import transportOrder from "./transport-order";
import vehicle from "./vehicle";

const defaultRoute: RouteConfig = { path: "/", redirect: "/dashboards" };
const routes: Array<RouteConfig> = [defaultRoute].concat(
    admin,
    contractorRoutes,
    supervisorRoutes,
    dashboards,
    forum,
    login,
    map,
    onlineTracking,
    operationRoutes,
    settlement,
    tracking,
    transportOrder,
    vehicle
);

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    routes
});

router.beforeEach((to, from, next) => {
    if (to.name == "Show online tracking") {
        next();
    }
    else {
        if (!store.state.auth.userRolesReady) {
            store.dispatch.auth.loadUserProfile().then(() => {
                if (canUserEnter(to)) {
                    next();
                } else {
                    return next({ path: "/login" });
                }
            });
        } else {
            if (canUserEnter(to)) {
                next();
            } else {
                return next({ path: "/login" });
            }
        }
    }

});

export function canUserEnter(to: Route): boolean {
    if (to.meta != undefined && to.meta.roles != undefined) {
        const allowRoles = to.meta.roles as string[];
        const userRoles = store.state.auth.userRoles;

        if (allowRoles) {
            if (!allowRoles.some(allowRole => userRoles.includes(allowRole))) {
                return false;
            }
        }
    }
    return true;
}

export default router;
