import dayjs from "dayjs";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import ConfirmDialog from "../../../layouts/confirmDialog/confirmDialog.vue";
import ListItemNumberModel from "../../../models/listItemNumberModel";
import ListItemStringModel from "../../../models/listItemStringModel";
import ContractorDocumentRestModel from "../../../models/rest/contractor/contractorDocumentRestModel";
import ContractorPolicyRestModel from "../../../models/rest/contractor/contractorPolicyRestModel";
import VForm from "../../../models/v-form";

@Component({
    components: {
        "confirm-dialog": ConfirmDialog,
    }
})

export default class PolicyDialog extends Vue {
    policyTermLabel = this.$t("contractor.expiration");
    files = [];

    policyDocumentListHeaders = [
        {
            text: this.$t("contractor.file"),
            align: "start",
            value: "name"
        },
        {
            text: this.$t("common.createdAt"),
            align: "start",
            value: "createdAt"
        },
        {
            text: this.$t("common.createdBy"),
            align: "start",
            value: "createdBy"
        },
        {
            text: this.$t("common.actions"),
            align: "right",
            value: "actions",
            width: "1%",
            sortable: false,
        }
    ];

    validation = {
        region: [(value: string) => value != undefined || this.$t("common.validationErrorRequired")],
        policyTerm: [(value: string) => !!value || this.$t("common.validationErrorRequired")],
    };

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get showDialog(): boolean {
        return this.$store.direct.state.contractor.showPolicyDialog;
    }

    get policy(): ContractorPolicyRestModel {
        const policy = this.$store.direct.state.contractor.policy;
        policy.policyTerm = this.formatSingleDateTimeValue(policy.policyTerm);

        return policy;
    }

    get selectedPolicyRegions(): ListItemNumberModel[] {
        const result: ListItemNumberModel[] = [];
        const regionNational = this.policyRegions.find(x => x.value == 0);
        const regionInternational = this.policyRegions.find(x => x.value == 1);
        const regionCabotage = this.policyRegions.find(x => x.value == 2);
        if (this.policy.isNational && regionNational != undefined)
            result.push(regionNational);
        if (this.policy.isInternational && regionInternational != undefined)
            result.push(regionInternational);
        if (this.policy.isCabotage && regionCabotage != undefined)
            result.push(regionCabotage);
        return result;
    }

    set selectedPolicyRegions(value: ListItemNumberModel[]) {
        this.policy.isNational = value.find(x => x.value == 0) != undefined;
        this.policy.isInternational = value.find(x => x.value == 1) != undefined;
        this.policy.isCabotage = value.find(x => x.value == 2) != undefined;
    }

    get policyDocumentList(): ContractorDocumentRestModel[] {
        return this.$store.direct.state.contractor.policyDocumentList;
    }

    get policyRegions(): ListItemNumberModel[] {
        return this.$store.direct.state.contractor.policyRegions;
    }

    get policyTypes(): ListItemStringModel[] {
        return this.$store.direct.state.contractor.policyTypes;
    }

    formatSingleDateTimeValue(time: any) {
        return time != null ? dayjs(time).format("YYYY-MM-DD HH:mm") : "";
    }

    async policyDocumentDownloadClick(document: ContractorDocumentRestModel) {
        await this.$store.direct.dispatch.contractor.downloadPolicyDocument({ id: document.id });
    }

    async deleteFile(item: any) {
        await this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: this.$t("contractor.deleteFileTitle").toString(),
                message: this.$t("contractor.deletetFileMessage").toString() + item.name,
                links: [],
                options: { buttonColor: "error", layoutColor: "red" },
                buttonType: "yes/no"
            })
            .then(async confirm => {
                if (confirm) {
                    await this.$store.direct.dispatch.contractor.deletePolicyDocument({ id: item.id });
                }
            })
    }

    async saveClick(): Promise<void> {
        if ((this.$refs.form as VForm).validate()) {

            if (this.policy.value.toString().length == 0) {
                this.policy.value = 0 as number;
            }

            await this.$store.direct.dispatch.contractor.savePolicy();
            if (this.files.length > 0) {
                await this.$store.direct.dispatch.contractor.savePolicyDocument(this.files);
                this.files = [];
            }
            this.$store.direct.commit.contractor.setShowPolicyDialog(false);
            this.$store.direct.commit.contractor.setPolicy({} as ContractorPolicyRestModel);
        }
    }

    closeClick(): void {
        (this.$refs.form as VForm).resetValidation();
        this.files = []
        this.$store.direct.commit.contractor.setShowPolicyDialog(false);
        this.$store.direct.commit.contractor.setPolicy({} as ContractorPolicyRestModel);
    }
}
