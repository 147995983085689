import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import i18n from "../../../assets/i18n";
import ListItemNumberModel from "../../../models/listItemNumberModel";
import CurrencyRestModel from "../../../models/rest/register/currencyRestModel";
import SettlementCostDocumentTransferOrderPackageRestModel from "../../../models/rest/settlement/cost-document/transfer-order-package/settlementCostDocumentTransferOrderPackageRestModel";
import ConfirmDialog from "../../../layouts/confirmDialog/confirmDialog.vue"
import SettlementCostDocumentTransferOrderPackageListRequestModel from "../../../models/rest/settlement/cost-document/transfer-order-package/settlementCostDocumentTransferOrderPackageListRequestModel";
import SettlementAmountSummaryRow from "../settlement-amount-summary-row.vue";
import SettlementAmountSummaryRestModel from "../../../models/rest/settlement/settlementAmountSummaryRestModel";

@Component({
    components: {
        "confirm-dialog": ConfirmDialog,
        "settlement-amount-summary-row": SettlementAmountSummaryRow,
    }
})

export default class CostDocumentTransferOrderPackageList extends Vue {
    transferOrderPackageToSetAsSentList = [] as SettlementCostDocumentTransferOrderPackageRestModel[];
    createdAtFromDateLabel = this.$t("common.createdAtFrom");
    createdAtToDateLabel = this.$t("common.createdAtTo");
    sentFromDateLabel = this.$t("transferOrderPackage.sendAtFrom");
    sentToDateLabel = this.$t("transferOrderPackage.sendAtTo");
    mainCheckboxIsSelected = false;
    showAmountSummaryRowForCalculatedSelectedList = false;

    transferOrderPackageListHeaders = [
        {
            align: "start",
            value: "checkbox",
            width: "1%",
            sortable: false,
        },
        {
            text: "Id",
            align: "center",
            value: "id",
            width: "1%",
            sortable: true,
        },
        {
            text: this.$t("transferOrderPackage.number"),
            align: "start",
            value: "packageNumber",
            sortable: true,
        },
        {
            text: this.$t("transferOrderPackage.bankName"),
            align: "start",
            value: "bankName",
            sortable: true,
        },
        {
            text: this.$t("transferOrderPackage.senderBankAccountNumber"),
            align: "start",
            value: "senderBankAccountNumber",
            sortable: true,
        },
        {
            text: this.$t("transferOrderPackage.currency"),
            align: "start",
            value: "currencyCode",
            sortable: true,
        },
        {
            text: this.$t("transferOrderPackage.numberOfOperations"),
            align: "start",
            value: "numberOfOperations",
            sortable: true,
        },
        {
            text: this.$t("transferOrderPackage.allOperationsValue"),
            align: "end",
            value: "allOperationsValue",
            sortable: true,
        },
        {
            text: this.$t("transferOrderPackage.packageFileType"),
            align: "start",
            value: "packageFileType",
            sortable: true,
        },
        {
            text: this.$t("transferOrderPackage.createdAt"),
            align: "start",
            value: "createdAt",
            sortable: true,
        },
        {
            text: this.$t("transferOrderPackage.sendAt"),
            align: "start",
            value: "sendAt",
            sortable: true,
        },
        {
            text: this.$t("common.actions"),
            align: "right",
            value: "actions",
            width: "1%",
            sortable: false,
        }
    ]; 

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get transferOrderPackageListLoading(): boolean {
        return this.$store.direct.state.settlementCostDocumentTransferOrderPackage.transferOrderPackageListLoading;
    }

    get transferOrderPackageList(): SettlementCostDocumentTransferOrderPackageRestModel[] {
        return this.$store.direct.state.settlementCostDocumentTransferOrderPackage.transferOrderPackageList;
    }

    get transferOrderPackageListTotalCount(): number {
        return this.$store.direct.state.settlementCostDocumentTransferOrderPackage.transferOrderPackageListTotalCount;
    }

    get searchBranchId(): number {
        return this.$store.direct.state.settlementCostDocumentTransferOrderPackage.transferOrderPackageListSearchBranchId;
    }

    set searchBranchId(value: number) {
        this.$store.direct.commit.settlementCostDocumentTransferOrderPackage.setTransferOrderPackageListSearchBranchId(value);
    }

    get selectedCurrency(): CurrencyRestModel {
        return this.$store.direct.state.settlementCostDocumentTransferOrderPackage.transferOrderPackageListSelectedCurrency;
    }

    set selectedCurrency(value: CurrencyRestModel) {
        this.$store.direct.commit.settlementCostDocumentTransferOrderPackage.setTransferOrderPackageListSelectedCurrency(value);
    }

    get searchPackageNumber(): string {
        return this.$store.direct.state.settlementCostDocumentTransferOrderPackage.transferOrderPackageListSearchPackageNumber;
    }

    set searchPackageNumber(value: string) {
        this.$store.direct.commit.settlementCostDocumentTransferOrderPackage.setTransferOrderPackageListSearchPackageNumber(value);
    }

    get searchCreatedAtFromDate(): string {
        return this.$store.direct.state.settlementCostDocumentTransferOrderPackage.transferOrderPackageListSearchCreatedAtFromDate;
    }

    set searchCreatedAtFromDate(value: string) {
        this.$store.direct.commit.settlementCostDocumentTransferOrderPackage.setTransferOrderPackageListSearchCreatedAtFromDate(value);
    }

    get searchCreatedAtToDate(): string {
        return this.$store.direct.state.settlementCostDocumentTransferOrderPackage.transferOrderPackageListSearchCreatedAtToDate;
    }

    set searchCreatedAtToDate(value: string) {
        this.$store.direct.commit.settlementCostDocumentTransferOrderPackage.setTransferOrderPackageListSearchCreatedAtToDate(value);
    }

    get searchSentFromDate(): string {
        return this.$store.direct.state.settlementCostDocumentTransferOrderPackage.transferOrderPackageListSearchSentFromDate;
    }

    set searchSentFromDate(value: string) {
        this.$store.direct.commit.settlementCostDocumentTransferOrderPackage.setTransferOrderPackageListSearchSentFromDate(value);
    }

    get searchSentToDate(): string {
        return this.$store.direct.state.settlementCostDocumentTransferOrderPackage.transferOrderPackageListSearchSentToDate;
    }

    set searchSentToDate(value: string) {
        this.$store.direct.commit.settlementCostDocumentTransferOrderPackage.setTransferOrderPackageListSearchSentToDate(value);
    }

    get pagerOptions(): any {
        return this.$store.direct.state.settlementCostDocumentTransferOrderPackage.transferOrderPackageListPagerOptions;
    }

    set pagerOptions(value: any) {
        this.$store.direct.commit.settlementCostDocumentTransferOrderPackage.setTransferOrderPackageListPagerOptions(value);
    }

    get transferOrderPackageListAmountSummaryList(): SettlementAmountSummaryRestModel[] {
        let amountSummary = this.$store.direct.state.settlementCostDocumentTransferOrderPackage.transferOrderPackageListAmountSummaryList;

        if (amountSummary == undefined) {
            amountSummary = [] as SettlementAmountSummaryRestModel[];
        }
        return amountSummary;
    }

    get footerPropsForSettlement(): any {
        return this.$store.direct.state.settlementCommon.footerPropsForSettlement;
    }

    get branches(): ListItemNumberModel[] {
        const branches = this.$store.direct.state.contractor.contractorAssets.branches;
        if (branches == undefined) {
            this.$store.direct.dispatch.contractor.loadContractorAssets();
        }
        return branches;
    }

    get branchList(): ListItemNumberModel[] {
        let clonedBranchList = [] as ListItemNumberModel[];
        if (this.branches == undefined) {
            this.$store.direct.dispatch.contractor.loadContractorAssets();
        }
        else {
            clonedBranchList = [...this.branches];
            clonedBranchList.push({ value: 0, text: i18n.t("common.all") } as ListItemNumberModel);
        }
        return clonedBranchList;
    }

    get currencies(): CurrencyRestModel[] {
        const currencies = this.$store.direct.state.contractor.contractorAssets.currencies;
        if (currencies == undefined) {
            this.$store.direct.dispatch.contractor.loadContractorAssets();
        }
        else {
            currencies.push({ code: i18n.t("common.all"), name: "" } as CurrencyRestModel)
        }

        return currencies;
    }

    get allowSelectionOfAllItemsOnPageWithCheckbox(): boolean {
        const allowSelection = this.$store.direct.state.settlementCommon.allowSelectionOfAllItemsOnPageWithCheckbox;
        if (!allowSelection) {
            this.mainCheckboxIsSelected = false;
            this.transferOrderPackageToSetAsSentList = [] as SettlementCostDocumentTransferOrderPackageRestModel[];
            this.transferOrderPackageList.forEach((x: SettlementCostDocumentTransferOrderPackageRestModel) => {
                x.isCheckedForSetAsSent = false;
            });

            this.calculatedSelectedListForAmountSummaryRow = [] as SettlementAmountSummaryRestModel[];
        }
        return allowSelection;
    }

    set allowSelectionOfAllItemsOnPageWithCheckbox(val: boolean) {
        this.$store.direct.commit.settlementCommon.setAllowSelectionOfAllItemsOnPageWithCheckbox(val);
    }

    get calculatedSelectedListForAmountSummaryRow(): SettlementAmountSummaryRestModel[] {
        return this.$store.direct.state.settlementCommon.calculatedSelectedListForAmountSummaryRow;
    }

    set calculatedSelectedListForAmountSummaryRow(val: SettlementAmountSummaryRestModel[]) {
        this.$store.direct.commit.settlementCommon.setCalculatedSelectedListForAmountSummaryRow(val);
    }

    @Watch("pagerOptions", { deep: true })
    pagerOptionsHandles() {
        this.loadTransferOrderPackageList();
    }

    async created() {
        this.$store.direct.dispatch.contractor.loadContractorAssets();
        this.loadTransferOrderPackageList();
    }

    formatSenderBankAccountNumber(senderBankAccountNumber: any) {
        if (senderBankAccountNumber != undefined) {
            const firstChars = senderBankAccountNumber.substring(0, 4);
            const lastChars = senderBankAccountNumber.substr(senderBankAccountNumber.length - 4);
            return firstChars + "..." + lastChars;
        }
    }

    clearDateTimePicker(clearableValue: string) {
        if (clearableValue == "searchCreatedAtFromDateValue") {
            this.searchCreatedAtFromDate = "";
        }
        if (clearableValue == "searchCreatedAtToDateValue") {
            this.searchCreatedAtToDate = "";
        }
        if (clearableValue == "searchSentFromDateValue") {
            this.searchSentFromDate = "";
        }
        if (clearableValue == "searchSentToDateValue") {
            this.searchSentToDate = "";
        }
        this.loadTransferOrderPackageList();
    }

    clearAllFilters() {
        this.searchPackageNumber = "";
        this.searchBranchId = 0;
        this.selectedCurrency = {} as CurrencyRestModel;
        this.searchCreatedAtFromDate = "";
        this.searchCreatedAtToDate = "";
        this.searchSentFromDate = "";
        this.searchSentToDate = "";

        this.loadTransferOrderPackageList();
    }

    searchClick() {
        this.loadTransferOrderPackageList();
    }

    async downloadTransferOrderPackageClick(transferOrderPackage: SettlementCostDocumentTransferOrderPackageRestModel) {
        await this.$store.direct.dispatch.settlementCostDocumentTransferOrderPackage.downloadTransferOrderPackageFile({ id: transferOrderPackage.id });
    }

    async deleteTransferOrderPackageClick(transferOrderPackage: SettlementCostDocumentTransferOrderPackageRestModel) {
        this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: this.$t("transferOrderPackage.deletePackageTitle").toString() + transferOrderPackage.packageNumber,
                message: this.$t("transferOrderPackage.deletePackageMessage").toString(),
                links: [],
                options: { buttonColor: "error", layoutColor: "red" },
                buttonType: "yes/no"
            })
            .then(async confirm => {
                if (confirm) {
                    await this.$store.direct.dispatch.settlementCostDocumentTransferOrderPackage.deleteTransferOrderPackage({ transferOrderPackageId: transferOrderPackage.id });
                    this.loadTransferOrderPackageList();
                }
            });
    }

    addTransferOrderPackageToSetAsSentList() {
        this.transferOrderPackageToSetAsSentList = this.transferOrderPackageList.filter((x: SettlementCostDocumentTransferOrderPackageRestModel) => {
            if (x.isCheckedForSetAsSent) {
                return x.id;
            }
        });

        this.$store.direct.dispatch.settlementCommon.calculateSelectedCostTransferOrderPackageListForAmountSummaryRow({ list: this.transferOrderPackageToSetAsSentList });
    }

    async setTransferOrderPackageListAsSent() {
        await this.$store.direct.dispatch.settlementCostDocumentTransferOrderPackage.setTransferOrderPackageListAsSent({ transferOrderPackageToSetAsSentList: this.transferOrderPackageToSetAsSentList })
        this.transferOrderPackageToSetAsSentList = [] as SettlementCostDocumentTransferOrderPackageRestModel[];
        this.loadTransferOrderPackageList();
    }

    async loadTransferOrderPackageList() {
        if (!this.selectedCurrency.name) {
            this.selectedCurrency = {} as CurrencyRestModel;
        }

        await this.$store.direct.dispatch.settlementCostDocumentTransferOrderPackage.loadTransferOrderPackageList({
            requestModel: this.prepareTransferOrderPackageListRequestModel()
        });
    }

    prepareTransferOrderPackageListRequestModel(): SettlementCostDocumentTransferOrderPackageListRequestModel {
        return {
            page: this.pagerOptions.page,
            pageSize: this.pagerOptions.itemsPerPage,
            sortBy: this.pagerOptions.sortBy[0],
            sortDesc: this.pagerOptions.sortDesc[0],
            searchPackageNumber: this.searchPackageNumber,
            searchBranchId: this.searchBranchId,
            searchCurrencyCode: this.selectedCurrency.code,
            searchCreatedAtFromDate: this.searchCreatedAtFromDate,
            searchCreatedAtToDate: this.searchCreatedAtToDate,
            searchSentFromDate: this.searchSentFromDate,
            searchSentToDate: this.searchSentToDate,
        } as SettlementCostDocumentTransferOrderPackageListRequestModel;
    }

    selectAllItemsOnThePage() {
        this.transferOrderPackageList.forEach((x: SettlementCostDocumentTransferOrderPackageRestModel) => {
            if (x.sendAt == null || this.allowSelectionOfAllItemsOnPageWithCheckbox) {
                x.isCheckedForSetAsSent = this.mainCheckboxIsSelected;
            }
        });
        this.$forceUpdate();

        this.transferOrderPackageToSetAsSentList = this.transferOrderPackageList.filter((x: SettlementCostDocumentTransferOrderPackageRestModel) => {
            if (x.isCheckedForSetAsSent) {
                return x.id;
            }
        });

        this.$store.direct.dispatch.settlementCommon.calculateSelectedCostTransferOrderPackageListForAmountSummaryRow({ list: this.transferOrderPackageToSetAsSentList });
    }

    currentPageInput = this.pagerOptions.page;
    goToPage() {
        if (this.currentPageInput > 0) {
            this.pagerOptions.page = this.currentPageInput;
        }
    }

    destroyed() {
        this.transferOrderPackageToSetAsSentList = [] as SettlementCostDocumentTransferOrderPackageRestModel[];
    }
}
