import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { Loader } from "@googlemaps/js-api-loader";
import ContractorRestModel from "../../../models/rest/contractor/contractorRestModel";

@Component
export default class LocationDialog extends Vue {
    addressSearch = "";
    address: any = null;
    initialZoom: number = 5;
    locationSetZoom: number = 14;
    initialCoordinates = { lat: 50, lng: 15 }
    timeout: number | undefined;

    map: google.maps.Map | undefined;
    marker: google.maps.Marker | undefined;

    loader = new Loader({
        apiKey: this.googleMapsApiKey,
        libraries: ["places"]
    });

    get googleMapsApiKey(): string {
        return this.$store.direct.state.auth.googleMapsApiKey;
    }

    get showDialog(): boolean {
        return this.$store.direct.state.locationDialog.showDialog;
    }

    get title(): string {
        return this.$store.direct.state.locationDialog.title;
    }

    get addressFound() {
        if (this.$store.direct.state.common.addressFound == null) {
            return [];
        }
        return this.$store.direct.state.common.addressFound;
    }

    get contractor(): ContractorRestModel {
        return this.$store.direct.state.contractor.contractor;
    }

    @Watch("addressSearch")
    addressSearchLoadItems(val: string) {
        if (val != null && val.length > 3) {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.$store.direct.dispatch.common.searchAddresses({ searchText: val });
            }, 1000);
        }
    }

    @Watch("showDialog")
    showDialogHandles(val: boolean) {
        if (val == true) {
            this.loadMap();
            if (this.contractor.postalCode != null && this.contractor.city != null) {
                this.address = this.contractor.postalCode + " " + this.contractor.city;
            }
        }
    }

    getMap(callback: (arg0: google.maps.Map<Element>) => void): void {
        const self = this;
        function checkForMap(): void {
            if (self.map) callback(self.map);
            else setTimeout(checkForMap, 200);
        }
        checkForMap();
    }

    setMarker() {
        this.marker?.setMap(null);
        this.marker?.setPosition(null);
        if (!isNaN(this.address.lat) && !isNaN(this.address.lng)) {
            this.marker = new google.maps.Marker({
                position: { lat: this.address.lat, lng: this.address.lng },
                map: this.map,
                draggable: true,
            });
            this.map?.setCenter({ lat: this.address.lat, lng: this.address.lng });
            this.map?.setZoom(this.locationSetZoom);
        }
    }

    loadMap() {
        this.loader.load().then(() => {
            this.map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
                center: this.initialCoordinates,
                zoom: this.initialZoom,
                mapTypeControl: true
            });
            if (this.contractor.lat != null && this.contractor.lng != null) {
                this.marker = new google.maps.Marker({
                    position: { lat: this.contractor.lat, lng: this.contractor.lng },
                    map: this.map,
                    draggable: true,
                });
                this.map?.setCenter({ lat: this.contractor.lat, lng: this.contractor.lng });
                this.map?.setZoom(this.locationSetZoom);
            }
        });
    }

    removeMarker() {
        this.marker?.setMap(null);
        this.marker?.setPosition(null);
        this.address = null;
        this.map?.setCenter(this.initialCoordinates);
        this.map?.setZoom(this.initialZoom);
    }

    cancelClick() {
        this.$store.direct.dispatch.locationDialog.isNotConfirmed();
    }

    async saveLocation() {
        const markerLat = this.marker?.getPosition()?.lat()
        const markerLng = this.marker?.getPosition()?.lng()

        this.contractor.lat = markerLat;
        this.contractor.lng = markerLng;

        await this.$store.direct.dispatch.locationDialog.isConfirmed();
    }
}
