import UserListItemRestModel from "@/models/rest/admin/userListItemRestModel";
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { Roles } from "../../../models/emums/roles";

@Component
export default class Admin extends Vue {
    searchText = "";
    searchUserRoleList: string[] = []
    userRoleList = Object.entries(Roles).map(([key, value]) => ({text: value, value: value}))

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get userList(): UserListItemRestModel[] {
        return this.$store.direct.state.admin.userList;
    }

    get userListLoading(): boolean {
        return this.$store.direct.state.admin.userListLoading;
    }

    get userListTotalCount(): number {
        return this.$store.direct.state.admin.userListTotalCount;
    }

    userListHeaders = [
        { text: this.$t("user.name"), value: "name" },
        { text: this.$t("user.firstName"), value: "firstName" },
        { text: this.$t("user.lastName"), value: "lastName" },
        { text: this.$t("user.email"), value: "email" },
        { text: this.$t("user.roles"), value: "roles" },
        { text: this.$t("contractor.actions"), align: "right", value: "actions", width: "1%" }
    ];

    pagerOptions = {
        page: 1,
        itemsPerPage: 10
    };

    @Watch("pagerOptions", { deep: true })
    pagerOptionsHandles() {
        this.loadUserList();
    }

    searchClick() {
        this.loadUserList();
    }

    async loadUserList() {
        await this.$store.direct.dispatch.admin.loadUserList({
            searchText: this.searchText,
            searchUserRoleList: this.searchUserRoleList,
            page: this.pagerOptions.page,
            itemsPerPage: this.pagerOptions.itemsPerPage
        });
    }

    userCreateClick() {
        this.$router.push({ path: "/admin/user/create" });
    }

    userEditRowClick(user: UserListItemRestModel) {
        this.$router.push({ path: `/admin/user/edit/${user.id}` });
    }

    userEditClick(user: UserListItemRestModel) {
        return `/admin/user/edit/${user.id}`;
    }
}
