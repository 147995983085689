import Vue from "vue";
import { Component } from "vue-property-decorator";
import TransferOrderRestModel from "../../../../models/rest/settlement/cost-document/transfer-order/settlementCostDocumentTransferOrderRestModel";

@Component
export default class CancelDialog extends Vue {
    rules = {
        transferOrderCancelDialogComment: [(value: string) => !!value || this.$t("common.validationErrorRequired")],
    };

    get showTransferOrderCancelDialog(): boolean {
        return this.$store.direct.state.settlementCostDocumentTransferOrder.showTransferOrderCancelDialog;
    }

    get transferOrderCancelDialogComment(): string {
        return this.$store.direct.state.settlementCostDocumentTransferOrder.transferOrderCancelDialogComment;
    }

    set transferOrderCancelDialogComment(value: string) {
        this.$store.direct.commit.settlementCostDocumentTransferOrder.setTransferOrderCancelDialogComment(value);
    }

    get transferOrder(): TransferOrderRestModel {
        return this.$store.direct.state.settlementCostDocumentTransferOrder.transferOrder;
    }

    set transferOrder(value: TransferOrderRestModel) {
        this.$store.direct.commit.settlementCostDocumentTransferOrder.setTransferOrder(value);
    }

    async yesClick() {
        const valid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
        if (valid) {
            await this.$store.direct.dispatch.settlementCostDocumentTransferOrder.isConfirmedTransferOrderCancelDialog({ isConfirmed: true });
        }
    }

    async noClick() {
        await this.$store.direct.dispatch.settlementCostDocumentTransferOrder.isNotConfirmedTransferOrderCancelDialog();
    }
}
