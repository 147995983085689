import Vue from "vue";
import { Component } from "vue-property-decorator";
import SupervisorIntegrationRestModel from "../../models/rest/supervisor/supervisorIntegrationRestModel";

@Component
export default class OnlineTracking extends Vue {
    onlineTrackingListHeaders = [
        { text: "History date", value: "lastModifiedAt", align: "center" },
        { text: "History state", value: "stateId", align: "center" },
    ];

    get integrationStates(): any[] {
        return this.$store.direct.state.supervisor.integrationStates;
    }

    get integration(): SupervisorIntegrationRestModel {
        const integration = this.$store.direct.state.onlineTracking.integration;
        if (integration.state != undefined) {
            integration.stateString = this.getIntegrationStateString(integration.state);
        }

        return integration;
    }

    get onlineTrackingTitle(): string {
        if (this.integration) {
            return "Online tracking";
        }
        return "Online tracking - not found";
    }

    get showContentTab(): boolean {
        if (this.integration) {
            return true;
        }
        return false;
    }

    get showHistoryTable(): boolean {
        if (this.integration.history != undefined && this.integration.history.length > 0) {
            return true;
        }
        return false;
    }

    getIntegrationStateString(stateId: number) {
        return this.integrationStates[stateId].state;
    }
}
