import { stringMaxLength } from "@/assets/validation/common";
import ListItemNumberModel from "@/models/listItemNumberModel";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import VForm from "../../../models/v-form";

@Component
export default class EmailAddressDialog extends Vue {
    validation = {
        email: [stringMaxLength(this.$i18n, this.$t("contractor.email"), 150)],
    };

    updated() {
        this.$nextTick(function () {
            if (this.$refs.form != undefined) {
                (this.$refs.form as VForm).resetValidation();
            }
        });
    }

    get emailAddress(): ListItemNumberModel{
        return this.$store.direct.state.contractor.emailAddress;
    }

    get showDialog(): boolean {
        return this.$store.direct.state.contractor.showEmailAddressDialog;
    }

    async saveClick(): Promise<void> {
        if ((this.$refs.form as VForm).validate()) {
            await this.$store.direct.dispatch.contractor.saveEmailAddress();
        }
    }
    closeClick(): void {
        this.$store.direct.dispatch.contractor.closeEmailAddressDialog();
    }
}
