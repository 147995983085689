import { saveFile } from "@/assets/file-saver";
import { moduleActionContext } from "@/store/index";
import { ApexOptions } from "apexcharts";
import { AxiosError, AxiosResponse } from "axios";
import { defineModule } from "direct-vuex";
import BaseCriterionModel from "../../models/baseCriterionModel";
import SupervisorReportRestModel from "../../models/rest/supervisor-report/supervisorReportRestModel";
import { axiosInstance } from "../../plugins/axios";

export interface SupervisorReportModuleStateInterface {
    supervisorCriterionList: BaseCriterionModel[];
    supervisorReportList: SupervisorReportRestModel[];
    supervisorReportListLoading: false | boolean;
    supervisorReportListTotalCount: number;
    supervisorReportGraphs: any[];
    supervisorReportGraphOptions: any;
    allowSearchContractorCarriers: boolean;
}

const supervisorReportModule = defineModule({
    namespaced: true,
    state: {
        supervisorCriterionList: [] as BaseCriterionModel[],
        supervisorReportList: [] as SupervisorReportRestModel[],
        supervisorReportListLoading: false,
        supervisorReportListTotalCount: 0,
        supervisorReportGraphs: [] as any[],
        supervisorReportGraphOptions: {
            title: { text: "All" as string } as ApexTitleSubtitle,
            chart: {
                id: "supervisorReportGraphAll",
                type: "line",
                height: 200,
                toolbar: {
                    tools: {
                        pan: false,
                        reset: false,
                        zoom: false,
                        zoomin: false,
                        zoomout: false,
                    }
                }
            } as ApexChart,
            stroke: {
                curve: "smooth",
                width: 3,
            },
            yaxis: {
                tickAmount: 3
            } as ApexYAxis,
            xaxis: {
                type: "datetime",
                categories: [] as any,
                labels: {
                    format: 'dd/MMM/yy'
                },
                tooltip: {
                    enabled: false,
                },
            } as ApexXAxis,
            colors: ["#3366cc", "#dc3912", "#ff9900", "#109618", "#990099", "#0099c6", "#dd4477", "#66aa00", "#b82e2e", "#316395", "#994499"] as any[],
        } as ApexOptions,
        allowSearchContractorCarriers: true,
    } as SupervisorReportModuleStateInterface,
    mutations: {
        setSupervisorCriterionList(state, value: BaseCriterionModel[]) {
            state.supervisorCriterionList = value;
        },
        setSupervisorReportList(state, value: SupervisorReportRestModel[]) {
            state.supervisorReportList = value;
        },
        setSupervisorReportListLoading(state, value: boolean) {
            state.supervisorReportListLoading = value;
        },
        setSupervisorReportListTotalCount(state, value: number) {
            state.supervisorReportListTotalCount = value;
        },
        setSupervisorReportGraphs(state, value: any[]) {
            state.supervisorReportGraphs = value
        },
        setAllowSearchContractorCarriers(state, value: boolean) {
            state.allowSearchContractorCarriers = value
        },
    },
    actions: {
        loadSupervisorReportList(context, payload: { criterionList: BaseCriterionModel[] }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                commit.setSupervisorReportListLoading(true);
                axiosInstance
                    .post("/api/supervisor/getSupervisorReport", payload?.criterionList)
                    .then((resp: AxiosResponse) => {
                        commit.setSupervisorCriterionList(resp.data.criterionList);
                        commit.setSupervisorReportList(resp.data.reportList.items);
                        commit.setSupervisorReportListTotalCount(resp.data.reportList.totalItemsCount);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setSupervisorReportListLoading(false);
                    })
            })
        },
        supervisorReportGraphUpdate(context, payload: { criterionList: BaseCriterionModel[] }) {
            const { state, commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/supervisor/supervisorReportGraph", payload?.criterionList)
                    .then((resp: AxiosResponse) => {
                        const supervisorReportGraphs: any = [];
                        for (let i = 0; i < resp.data.graphs.length; i++) {
                            const graph = resp.data.graphs[i];
                            const newChartOptions = JSON.parse(JSON.stringify(state.supervisorReportGraphOptions)) as ApexOptions;
                            newChartOptions!.xaxis!.categories = resp.data.categories as any;

                            if (newChartOptions.xaxis?.labels) {
                                if (resp.data.numberOfMonths < 6) {
                                    newChartOptions.xaxis.type = "datetime";
                                    newChartOptions!.xaxis!.labels.format = 'dd/MMM' as string;
                                }
                                else {
                                    newChartOptions.xaxis.tickPlacement = 'between';
                                    newChartOptions.xaxis.type = "category";
                                    newChartOptions!.xaxis!.labels.format = 'MMM/yy' as string;
                                }
                            }

                            newChartOptions!.chart!.id = state.supervisorReportGraphOptions.chart.id + i;
                            newChartOptions!.title!.text = graph.graphName;
                            const currentGraph = {
                                options: newChartOptions,
                                series: [] as any[]
                            };
                            supervisorReportGraphs.push(currentGraph);
                            for (let j = 0; j < graph.series.length; j++) {
                                currentGraph.series.push({ name: graph.series[j].serieName, data: graph.series[j].data });
                            };
                        };
                        commit.setSupervisorReportGraphs(supervisorReportGraphs);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        getSupervisorReportXlsExport(context, payload: { criterionList: BaseCriterionModel[] }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((reject) => {
                commit.setSupervisorReportListLoading(true);
                axiosInstance
                    .post("/api/supervisor/getSupervisorReportXlsExport", payload?.criterionList, {
                        responseType: "blob"
                    })
                    .then((resp: AxiosResponse) => {
                        saveFile(resp);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setSupervisorReportListLoading(false);
                    })
            })
        },
    }
});

export default supervisorReportModule;
const _moduleActionContext = (context: any) => moduleActionContext(context, supervisorReportModule)