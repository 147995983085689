import Vue from "vue";
import { Component } from "vue-property-decorator";
import SettlementAmountSummaryRestModel from "../../models/rest/settlement/settlementAmountSummaryRestModel";
import SupervisorAmountSummaryRestModel from "../../models/rest/supervisor/supervisorAmountSummaryRestModel";

@Component({
    props: {
        amountSummaryList: {
            default: [] as SupervisorAmountSummaryRestModel[]
        },
    }
})

export default class SupervisorAmountSummaryRow extends Vue {
    amountSummaryList!: SettlementAmountSummaryRestModel[];
}
