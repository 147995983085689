import Vue from "vue";
import VueI18n from "vue-i18n";
import commonEn from "./common-en.json";
import commonPl from "./common-pl.json";
import commonDe from "./common-de.json";
import closedMonthEn from "./settlement/closed-month-en.json";
import closedMonthPl from "./settlement/closed-month-pl.json";
import closedMonthDe from "./settlement/closed-month-de.json";
import contractorEn from "./contractor/contractor-en.json";
import contractorPl from "./contractor/contractor-pl.json";
import contractorDe from "./contractor/contractor-de.json";
import costDocumentEn from "./settlement/cost-document-en.json";
import costDocumentPl from "./settlement/cost-document-pl.json";
import costDocumentDe from "./settlement/cost-document-de.json";
import costDocumentTransferOrderEn from "./settlement/cost-document-transfer-order-en.json";
import costDocumentTransferOrderPl from "./settlement/cost-document-transfer-order-pl.json";
import costDocumentTransferOrderDe from "./settlement/cost-document-transfer-order-de.json";
import costDocumentTransferOrderPackageEn from "./settlement/cost-document-transfer-order-package-en.json";
import costDocumentTransferOrderPackagePl from "./settlement/cost-document-transfer-order-package-pl.json";
import costDocumentTransferOrderPackageDe from "./settlement/cost-document-transfer-order-package-de.json";
import dashboardEn from "./dashboard/dashboard-en.json";
import dashboardPl from "./dashboard/dashboard-pl.json";
import dashboardDe from "./dashboard/dashboard-de.json";
import forumEn from "./forum/forum-en.json";
import forumPl from "./forum/forum-pl.json";
import forumDe from "./forum/forum-de.json";
import incomeDocumentEn from "./settlement/income-document-en.json";
import incomeDocumentPl from "./settlement/income-document-pl.json";
import incomeDocumentDe from "./settlement/income-document-de.json";
import layoutEn from "./layout/layout-en.json";
import layoutPl from "./layout/layout-pl.json";
import layoutDe from "./layout/layout-de.json";
import loginEn from "./login/login-en.json";
import loginPl from "./login/login-pl.json";
import loginDe from "./login/login-de.json";
import mapEn from "./map/map-en.json";
import mapPl from "./map/map-pl.json";
import mapDe from "./map/map-de.json";
import paymentEn from "./settlement/payment-en.json";
import paymentPl from "./settlement/payment-pl.json";
import paymentDe from "./settlement/payment-de.json";
import paymentObligationEn from "./settlement/payment-obligation-en.json";
import paymentObligationPl from "./settlement/payment-obligation-pl.json";
import paymentObligationDe from "./settlement/payment-obligation-de.json";
import registerExportEn from "./settlement/register-export-en.json";
import registerExportPl from "./settlement/register-export-pl.json";
import registerExportDe from "./settlement/register-export-de.json";
import supervisorEn from "./supervisor/supervisor-en.json";
import supervisorPl from "./supervisor/supervisor-pl.json";
import supervisorDe from "./supervisor/supervisor-de.json";
import supervisorTransportDocumentEn from "./supervisor-transport-document/supervisor-transport-document-en.json";
import supervisorTransportDocumentPl from "./supervisor-transport-document/supervisor-transport-document-pl.json";
import supervisorTransportDocumentDe from "./supervisor-transport-document/supervisor-transport-document-de.json";
import trackingEn from "./tracking/tracking-en.json";
import trackingPl from "./tracking/tracking-pl.json";
import trackingDe from "./tracking/tracking-de.json";
import freightExchangeEn from "./freightExchange/freight-exchange-en.json";
import freightExchangePl from "./freightExchange/freight-exchange-pl.json";
import freightExchangeDe from "./freightExchange/freight-exchange-de.json";
import transportOrderEn from "./transport-order/transport-order-en.json";
import transportOrderPl from "./transport-order/transport-order-pl.json";
import transportOrderDe from "./transport-order/transport-order-de.json";
import userEn from "./user/user-en.json";
import userPl from "./user/user-pl.json";
import userDe from "./user/user-de.json";
import vehicleEn from "./vehicle/vehicle-en.json";
import vehiclePl from "./vehicle/vehicle-pl.json";
import vehicleDe from "./vehicle/vehicle-de.json";

Vue.use(VueI18n);

//remember sort a-z
const i18n = new VueI18n({
    locale: localStorage.getItem("lang") || "en",
    messages: {
        en: {
            closedMonth: closedMonthEn,
            contractor: contractorEn,
            common: commonEn,
            costDocument: costDocumentEn,
            dashboard: dashboardEn,
            forum: forumEn,
            incomeDocument: incomeDocumentEn,
            layout: layoutEn,
            login: loginEn,
            map: mapEn,
            payment: paymentEn,
            paymentObligation: paymentObligationEn,
            registerExport: registerExportEn,
            supervisor: supervisorEn,
            supervisorTransportDocument: supervisorTransportDocumentEn,
            tracking: trackingEn,
            transferOrder: costDocumentTransferOrderEn,
            transferOrderPackage: costDocumentTransferOrderPackageEn,
            freightExchange: freightExchangeEn,
            transportOrder: transportOrderEn,
            user: userEn,
            vehicle: vehicleEn
        },
        pl: {
            closedMonth: closedMonthPl,
            contractor: contractorPl,
            common: commonPl,
            costDocument: costDocumentPl,
            dashboard: dashboardPl,
            forum: forumPl,
            incomeDocument: incomeDocumentPl,
            layout: layoutPl,
            login: loginPl,
            map: mapPl,
            payment: paymentPl,
            paymentObligation: paymentObligationPl,
            registerExport: registerExportPl,
            supervisor: supervisorPl,
            supervisorTransportDocument: supervisorTransportDocumentPl,
            tracking: trackingPl,
            transferOrder: costDocumentTransferOrderPl,
            transferOrderPackage: costDocumentTransferOrderPackagePl,
            freightExchange: freightExchangePl,
            transportOrder: transportOrderPl,
            user: userPl,
            vehicle: vehiclePl
        },
        de: {
            closedMonth: closedMonthDe,
            contractor: contractorDe,
            common: commonDe,
            costDocument: costDocumentDe,
            dashboard: dashboardDe,
            forum: forumDe,
            incomeDocument: incomeDocumentDe,
            layout: layoutDe,
            login: loginDe,
            map: mapDe,
            payment: paymentDe,
            paymentObligation: paymentObligationDe,
            registerExport: registerExportDe,
            supervisor: supervisorDe,
            supervisorTransportDocument: supervisorTransportDocumentDe,
            tracking: trackingDe,
            transferOrder: costDocumentTransferOrderDe,
            transferOrderPackage: costDocumentTransferOrderPackageDe,
            freightExchange: freightExchangeDe,
            transportOrder: transportOrderDe,
            user: userDe,
            vehicle: vehicleDe
        }
    }
});

export default i18n;
