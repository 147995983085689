import dayjs from "dayjs";
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";

@Component({
    props: {
        dateTime: {
            default: ""
        },
        enabled: {
            default: true
        },
        filled: {
            default: false
        },
        label: {
            default: "Date"
        },
        doNotOpenDialog: {
            default: false
        },
        rules: {
            type: Array,
            default: () => []
        },
        showTimePicker: {
            default: true
        },
        isClearable: {
            default: false
        },
        clearableValue: {
            default: ""
        },
        closePickerAfterNowButtonClick: {
            default: true
        },
        openWithConfirmationDialog: {
            default: false
        },
        confirmationDialogTitle: {
            default: ""
        },
        confirmationDialogMessage: {
            default: ""
        },
        showDateTextField: {
            default: false
        },
    }
})
export default class DateTimePicker extends Vue {
    $refs!: {
        timer: any;
    };
    showDateTimePicker = false;

    date: null | string = null;
    time: null | string = null;

    lang = localStorage.getItem("lang");

    clearableValue!: string;
    closePickerAfterNowButtonClick!: boolean;
    openWithConfirmationDialog!: boolean;
    confirmationDialogTitle!: string;
    confirmationDialogMessage!: string;
    dateTime!: string;
    enabled!: boolean;
    filled!: boolean;
    doNotOpenDialog!: boolean;
    showTimePicker!: boolean;
    showDateTextField!: boolean;
    dateTimeMutated: string = "";

    created() {
        this.dateTimeMutated = this.dateTime;
        this.splitDateTime(this.dateTimeMutated);
    }

    allowedStep(m: number) {
        return m % 5 === 0;
    }

    @Watch("dateTime")
    dateTimeHandler(val: string) {
        this.dateTimeMutated = val;
        this.splitDateTime(this.dateTimeMutated);
    }

    splitDateTime(dateTimeMutated: string) {
        if (dateTimeMutated != null) {
            const splitedDateTime = dateTimeMutated.split(' ');
            this.date = splitedDateTime[0];
            this.time = splitedDateTime[1];
        }
    }

    openDatePicker() {
        if (this.doNotOpenDialog == false) {
            if (this.openWithConfirmationDialog) {
                this.$store.direct.dispatch.confirm
                    .openConfirmDialog({
                        showConfirmDialog: true,
                        title: this.confirmationDialogTitle,
                        message: this.confirmationDialogMessage,
                        links: [],
                        options: { buttonColor: "error", layoutColor: "red" },
                        buttonType: "yes/no"
                    })
                    .then(confirm => {
                        if (confirm) {
                            this.showDateTimePicker = true;
                        }
                    })
            }
            else {
                this.showDateTimePicker = true;
            }
        }
    }

    acceptDateTime() {
        if (this.showTimePicker == false && this.date != undefined) {
            this.$emit("update:dateTime", this.date);
        }
        else if (this.date != undefined && this.time != undefined) {
            this.$emit("update:dateTime", this.date + " " + this.time);
        }
        this.$emit('change');
        this.showDateTimePicker = false;
    }

    setNowTime() {
        this.time = dayjs().format("HH:mm");
        this.date = dayjs().format("YYYY-MM-DD");

        if (this.closePickerAfterNowButtonClick == true) {
            this.acceptDateTime();
        }
    }

    clearDateTimePicker() {
        this.$emit("clearDateTimePickerFromDateTimePickerComponent", this.clearableValue);
    }
}
