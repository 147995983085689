import dayjs from "dayjs";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import i18n from "../../../../assets/i18n";
import { BranchEnum } from "../../../../models/emums/branchEnum";
import ListItemNumberModel from "../../../../models/listItemNumberModel";

@Component
export default class OutstandingRegisterExportDialog extends Vue {
    monthAndYear = dayjs().format("YYYY-MM");
    branchId = BranchEnum.PL;
    showDatesError = false;

    get showDialog(): boolean {
        return this.$store.direct.state.settlementCommon.showOutstandingRegisterExportDialog;
    }

    get isLoading(): boolean {
        return this.$store.direct.state.settlementCommon.outstandingRegisterExportListLoading;
    }

    get branchList(): ListItemNumberModel[] {
        const branchList = this.$store.direct.state.contractor.contractorAssets.branches;
        let clonedBranchList = [] as ListItemNumberModel[];
        if (branchList == undefined) {
            this.$store.direct.dispatch.contractor.loadContractorAssets();
        }
        else {
            clonedBranchList = [...branchList];
            clonedBranchList.push({ value: 0, text: i18n.t("common.all") } as ListItemNumberModel);
        }
        return clonedBranchList;
    }

    async saveClick() {
        await this.$store.direct.dispatch.settlementCommon.saveOutstandingRegisterExport({ monthAndYear: this.monthAndYear });
        this.$store.direct.dispatch.settlementCommon.isConfirmedOutstandingRegisterExportDialog();
    }

    cancelClick() {
        this.$store.direct.dispatch.settlementCommon.isNotConfirmedOutstandingRegisterExportDialog();
    }
}
