import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import ConfirmDialog from "../../layouts/confirmDialog/confirmDialog.vue";
import { Roles } from "../../models/emums/roles";
import VehicleRestModel from "../../models/rest/vehicle/vehicleRestModel";
import VehicleDialog from "./dialog/vehicle.vue";

@Component({
    components: {
        "confirm-dialog": ConfirmDialog,
        "vehicle-dialog": VehicleDialog
    }
})
export default class YourVehicles extends Vue {

    get pagerOptions():any {
        return this.$store.direct.state.vehicle.yourVehiclesPagerOptions;
    }

    set pagerOptions(val: any) {
        this.$store.direct.commit.vehicle.setYourVehiclesPagerOptions(val);
    }

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get searchText(): string {
        return this.$store.direct.state.vehicle.yourVehiclesSearchText;
    }

    set searchText(val: string) {
        this.$store.direct.commit.vehicle.setYourVehiclesSearchText(val);
    }

    get yourVehicleList(): VehicleRestModel[] {
        return this.$store.direct.state.vehicle.yourVehicleList;
    }

    get yourVehicleListLoading(): boolean {
        return this.$store.direct.state.vehicle.yourVehicleListLoading;
    }

    get yourVehicleListTotalCount(): number {
        return this.$store.direct.state.vehicle.yourVehicleListTotalCount;
    }

    get currentUserRoles(): string[] {
        return this.$store.state.auth.userRoles;
    }

    get currentUserContractorId(): number {
        return this.$store.direct.state.auth.currentUserContractorId;
    }

    get currentUserContractorName(): string {
        return this.$store.direct.state.auth.currentUserContractorName;
    }

    get vehicle(): VehicleRestModel {
        return this.$store.direct.state.vehicle.vehicle;
    }

    yourVehicleListHeaders = [
        {
            text: this.$t("vehicle.registrationNumber"),
            align: "start",
            value: "registrationNumber",
            sortable: false
        },
        {
            text: this.$t("vehicle.type"),
            align: "start",
            value: "type",
            sortable: false
        },
        {
            text: this.$t("vehicle.status"),
            align: "start",
            value: "vehicleState",
            sortable: false
        },
        {
            text: this.$t("vehicle.availableFrom"),
            align: "start",
            value: "availableFrom",
            sortable: false
        },
        {
            text: this.$t("vehicle.reportedIn"),
            align: "start",
            value: "reportedIn",
            sortable: false
        },
        {
            text: this.$t("contractor.contractor"),
            align: "start",
            value: "contractorName",
            sortable: false
        },
        {
            text: this.$t("vehicle.changeStatus"),
            align: "center",
            value: "changeStatus",
            width: "1%",
            sortable: false
        },
        {
            text: this.$t("vehicle.removeNotify"),
            align: "center",
            value: "removeNotify",
            width: "1%",
            sortable: false
        },
        {
            text: this.$t("common.edit"),
            align: "center",
            value: "edit",
            width: "1%",
            sortable: false
        },
        {
            text: this.$t("common.delete"),
            align: "center",
            value: "delete",
            width: "1%",
            sortable: false
        },
        {
            text: this.$t("vehicle.48H"),
            align: "center",
            value: "reportBack",
            width: "1%",
            sortable: false
        }
    ];

    @Watch("pagerOptions", { deep: true })
    pagerOptionsHandles() {
        this.loadYourVehicleList();
    }

    searchClick() {
        this.loadYourVehicleList();
    }

    loadYourVehicleList() {
        this.$store.direct.dispatch.vehicle.loadYourVehicleList({
            searchText: this.searchText,
            page: this.pagerOptions.page,
            itemsPerPage: this.pagerOptions.itemsPerPage
        });
    }

    async createVehicleClick() {
        await this.$store.direct.commit.vehicle.setVehicle({} as VehicleRestModel)
        if (!this.currentUserRoles.includes(Roles.Admin) && !this.currentUserRoles.includes(Roles.Disponent) && !this.currentUserRoles.includes(Roles.DisponentAdmin)) {
            this.vehicle.contractor = { text: this.currentUserContractorName, value: this.currentUserContractorId };
        }
        this.$store.direct.dispatch.vehicle
            .openVehicleDialog({
                showDialog: true,
                title: `${this.$t("vehicle.addVehicle")}`
            })
            .then(async confirm => {
                if (confirm) {
                    await this.$store.direct.dispatch.vehicle.saveVehicle();
                }
                await this.loadYourVehicleList()
            });
    }

    async editVehicleClick(vehicle: VehicleRestModel) {
        await this.$store.direct.dispatch.vehicle.loadVehicle({ id: vehicle.id });
        this.$store.direct.dispatch.vehicle
            .openVehicleDialog({
                showDialog: true,
                title: `${this.$t("vehicle.editVehicle")}`
            })
            .then(async confirm => {
                if (confirm) {
                    await this.$store.direct.dispatch.vehicle.saveVehicle();
                }
                await this.loadYourVehicleList();
            });
    }

    deleteVehicleClick(vehicle: VehicleRestModel) {
        this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: `${this.$t("common.delete")}: "${vehicle.registrationNumber}"`,
                message: `${this.$t("vehicle.deleteMessage")}`,
                links: [],
                options: { buttonColor: "error", layoutColor: "red" },
                buttonType: "yes/no"
            })
            .then(confirm => {
                if (confirm) {
                    this.$store.direct.dispatch.vehicle.deleteVehicle({ id: vehicle.id })
                }
            });
    }

    async removeNotifyClick(vehicle: VehicleRestModel) {
        await this.$store.direct.dispatch.vehicle.removeNotify({ id: vehicle.id })
        this.loadYourVehicleList()
    }

    async reportBackClick(vehicle: VehicleRestModel) {
        await this.$store.direct.dispatch.vehicle.reportNotify({ id: vehicle.id })
        this.loadYourVehicleList()
    }

    changeStatus(vehicle: VehicleRestModel) {
        this.$router.push({ path: "/vehicle/change-status/" + vehicle.id });
    }
}
