import dayjs from "dayjs";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { stringMaxLength } from "../../../../assets/validation/common";
import SettlementPaymentRestModel from "../../../../models/rest/settlement/payment/settlementPaymentRestModel";

@Component({})

export default class EditPaymentOutsideTheSystemDialog extends Vue {
    paymentDateLabel = this.$t("payment.paymentDate");

    rules = {
        comment: [
            stringMaxLength(this.$i18n, this.$t("user.name"), 2048),
        ],
    }

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get showDialog(): boolean {
        return this.$store.direct.state.settlementPayment.showEditPaymentOutsideTheSystemDialog;
    }

    get title(): string {
        return this.$store.direct.state.settlementPayment.editPaymentOutsideTheSystemDialogTitle;
    }

    get editPaymentOutsideTheSystemDialogComment(): string {
        return this.$store.direct.state.settlementPayment.editPaymentOutsideTheSystemDialogComment;
    }

    set editPaymentOutsideTheSystemDialogComment(val: string) {
        this.$store.direct.commit.settlementPayment.setEditPaymentOutsideTheSystemDialogComment(val);
    }

    get editPaymentOutsideTheSystemDialogPaymentDate(): string {
        return this.$store.direct.state.settlementPayment.editPaymentOutsideTheSystemDialogPaymentDate;
    }

    set editPaymentOutsideTheSystemDialogPaymentDate(val: string) {
        this.$store.direct.commit.settlementPayment.setEditPaymentOutsideTheSystemDialogPaymentDate(val);
    }

    get selectedPaymentList(): SettlementPaymentRestModel[] {
        const selectedPaymentList = this.$store.direct.state.settlementPayment.selectedPaymentList;

        selectedPaymentList.forEach(x => {
            if (x.paymentDate != undefined) {
                const formattedDateTime = this.formatDateTime(x.paymentDate);
                x.paymentDate = formattedDateTime != undefined ? formattedDateTime : x.paymentDate;
            }
        })

        return selectedPaymentList;
    }

    set selectedPaymentList(val: SettlementPaymentRestModel[]) {
        this.$store.direct.commit.settlementPayment.setSelectedPaymentList(val);
    }

    get currencyListFromSelectedPaymentList(): string[] {
        const arrayWithDuplicates = this.selectedPaymentList.map(({ currencyCode }) => currencyCode);
        const arrayWithoutDuplicates = arrayWithDuplicates.filter((x: string, index: number, self: string[]) => {
            return index == self.indexOf(x);
        });
        return arrayWithoutDuplicates;
    }

    get currencyWithAmoutListFromSelectedPaymentList(): any[] {
        const currencyWithAmoutList = [] as any[];
        this.currencyListFromSelectedPaymentList.forEach((x: string) => {
            const sum = this.selectedPaymentList
                .map((y: SettlementPaymentRestModel) => {
                    return y.currencyCode == x ? y.paymentAmount : 0
                })
                .reduce(function (a, b) {
                    return a + b;
                });

            currencyWithAmoutList.push({
                currency: x, amount: sum
            });
        });
        return currencyWithAmoutList;
    }

    formatDateTime(dateTime: any) {
        if (dateTime != null) {
            return dayjs(dateTime).format("YYYY-MM-DD");
        }
    }

    async cancelClick() {
        await this.$store.direct.dispatch.settlementPayment.isNotConfirmedEditPaymentOutsideTheSystemDialog();
        this.clearEditPaymentOutsideTheSystemDialogValues();
    }

    async saveClick() {
        const valid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
        if (valid) {
            await this.$store.direct.dispatch.settlementPayment.isConfirmedEditPaymentOutsideTheSystemDialog();
            this.clearEditPaymentOutsideTheSystemDialogValues();
        }
    }

    destroyed() {
        this.clearEditPaymentOutsideTheSystemDialogValues();
    }

    clearEditPaymentOutsideTheSystemDialogValues() {
        this.editPaymentOutsideTheSystemDialogComment = "";
        this.editPaymentOutsideTheSystemDialogPaymentDate = "";
    }
}
