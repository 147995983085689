import Vue from "vue";
import { Component } from "vue-property-decorator";
import ConfirmDialog from "../../../../layouts/confirmDialog/confirmDialog.vue";
import CurrencyRestModel from "../../../../models/rest/register/currencyRestModel";
import CurrencyDialog from "./currency-dialog.vue";

@Component({
    components: {
        "currency-dialog": CurrencyDialog,
        "confirm-dialog": ConfirmDialog,
    }
})
export default class CurrencyList extends Vue {
    get items(): CurrencyRestModel[] {
        return this.$store.direct.state.register.currencies;
    }

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    itemsHeaders = [
        { text: this.$t("common.code"), value: "code" },
        { text: this.$t("common.name"), value: "name" },
    ];

    pagerOptions = {
        page: 1,
        itemsPerPage: 10
    };

    mounted() {
        this.$store.direct.dispatch.register.loadCurrencies();
    }

    itemCreateClick() {
        this.$store.direct.dispatch.register.showCurrencyDialog({} as CurrencyRestModel);
    }
}
