import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { Loader } from "@googlemaps/js-api-loader";
import VehicleRestModel from "../../models/rest/vehicle/vehicleRestModel";
import router from "../../router";

@Component
export default class ChangeStatus extends Vue {
    addressSearch = "";
    address: any = null;
    markers: any = [];
    availableFromTemporary: string = "";
    timeout: number | undefined;

    map: google.maps.Map | undefined;

    loader = new Loader({
        apiKey: this.googleMapsApiKey,
        libraries: ["places"]
    });

    get googleMapsApiKey(): string {
        return this.$store.direct.state.auth.googleMapsApiKey;
    }

    validation = {
        address: [(value: string) => !!value || this.$t("common.validationErrorRequired")],
    }

    numberOfDrivers = [
        { text: "1", value: "1" },
        { text: "2", value: "2" },
        { text: "3", value: "3" },
        { text: "4", value: "4" },
        { text: "5", value: "5" },
        { text: "6", value: "6" },
        { text: "7", value: "7" },
        { text: "8", value: "8" },
        { text: "9", value: "9" },
        { text: "10", value: "10" },
    ]

    get addressFound() {
        return this.$store.direct.state.common.addressFound;
    }

    get vehicle(): VehicleRestModel {
        return this.$store.direct.state.vehicle.vehicle;
    }

    @Watch("addressSearch")
    addressSearchLoadItems(val: string) {
        if (val != null && val.length > 3) {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.$store.direct.dispatch.common.searchAddresses({ searchText: val });
            }, 1000);

        }
    }

    async created() {
        this.$store.direct.commit.vehicle.setVehicle({} as VehicleRestModel)
        this.availableFromTemporary = this.getCurrentDateTime();
        await this.loadMap();
    }

    getCurrentDateTime() {
        const date = new Date();
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hour = String(date.getHours()).padStart(2, '0');
        const minute = String(date.getMinutes()).padStart(2, '0');
        const strDate = year + "-" + month + "-" + day + " " + hour + ":" + minute;

        return strDate;
    }

    updateMarker() {
        this.vehicle.country = this.address.countryCode;

        if (this.address.postalCode != null) {
            this.vehicle.city = `${this.address.postalCode} ${this.address.city}`;
        }
        else {
            if (typeof (this.address) == "string") {
                this.vehicle.city = undefined;
            }
            else {
                this.vehicle.city = `${this.address.city}`;
            }
        }
        this.setMarker();
    }

    getMap(callback: (arg0: google.maps.Map<Element>) => void): void {
        const self = this;
        function checkForMap(): void {
            if (self.map) callback(self.map);
            else setTimeout(checkForMap, 200);
        }
        checkForMap();
    }

    deleteMarkers() {
        if (this.markers.length != 0) {
            this.markers[0].setMap(null);
            this.markers = [];
        }
    }

    setMarker() {
        this.deleteMarkers();

        if (!isNaN(this.address.lat) && !isNaN(this.address.lng)) {
            this.markers.push(new google.maps.Marker({ position: { lat: this.address.lat, lng: this.address.lng }, map: this.map }));
            this.map?.setCenter({ lat: this.address.lat, lng: this.address.lng });
        }
    }

    loadMap() {
        this.loader.load().then(() => {
            this.map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
                center: {
                    lat: 50,
                    lng: 15
                },
                zoom: 5,
                mapTypeControl: true
            });
        })
    }

    async cancelClick(): Promise<void> {
        await router.push({ name: "Your vehicles" });
    }

    saveStatus() {
        this.vehicle.lat = this.address?.lat;
        this.vehicle.lng = this.address?.lng;
        this.vehicle.availableFrom = this.availableFromTemporary;

        const valid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
        if (valid) {
            const vehicleId = Number.parseInt(this.$route.params.id);
            this.$store.direct.dispatch.vehicle.changeStatus({ id: vehicleId });
        }
    }
}
