import Vue from "vue";
import { Component } from "vue-property-decorator";
import router from "@/router";
import Code2FADialog from "./code2FADialog/code2FADialog.vue";

@Component({
    components: {
        "code2FA-dialog": Code2FADialog,
    }
})

export default class Login extends Vue {
    credential = {
        EmailAddress: "",
        Password: ""
    };
    isError = false;
    loginButtonDisabled = false;
    errorMsg = "";
    toggleShowPassword = false;

    closeError() {
        this.isError = false;
    }

    get isLogged(): boolean {
        return this.$store.direct.state.auth.isLogged;
    }

    created() {
        if (this.isLogged) {
            router.push({ path: "/dashboards" });
        }
    }

    rules = {
        required: (value: string) => !!value || this.$t("common.validationErrorRequired"),
        email: (value: string) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || this.$t("common.validationErrorValidEmail");
        }
    };

    login() {
        this.closeError();
        const error = (this.$refs.form as Vue & { validate: () => boolean }).validate();
        if (error) {
            (this.$refs.form as Vue & { resetValidation: () => boolean }).resetValidation();

            this.loginButtonDisabled = true;
            this.$store.direct.dispatch.auth
                .login({ email: this.credential.EmailAddress, password: this.credential.Password })
                .then(() => {
                    this.isError = false;
                    router.push({ path: "/dashboards" });
                })
                .catch(reason => {
                    this.isError = true;
                    this.errorMsg = reason;
                })
                .finally(() => {
                    this.loginButtonDisabled = false;
                });
        }
    }

    destroyed() {
        this.closeError();
        this.credential.EmailAddress = "";
        this.credential.Password = "";
    }
}
