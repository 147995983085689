import Vue from "vue";
import { Component } from "vue-property-decorator";
import SettlementAmountSummaryRestModel from "../../models/rest/settlement/settlementAmountSummaryRestModel";

@Component({
    props: {
        amountSummaryList: {
            default: [] as SettlementAmountSummaryRestModel[]
        },
        showCurrencyFieldLabel: {
            default: true
        },
    }
})

export default class SettlementAmountSummaryRow extends Vue {
    amountSummaryList!: SettlementAmountSummaryRestModel[];
    showCurrencyFieldLabel!: boolean;
}
