import { moduleActionContext } from "@/store/index";
import { AxiosError, AxiosResponse } from "axios";
import { defineModule } from "direct-vuex";
import { axiosInstance } from "../../plugins/axios";
import SupervisorEncoryIntegrationRestModel from "../../models/rest/supervisor/supervisorEncoryIntegrationRestModel";


export interface SupervisorEncoryModuleStateInterface {
    activeSupervisorsPlacesType: number,
    supervisorEncoryIntegrations: SupervisorEncoryIntegrationRestModel[],
    showEncoryIntegrationDialog: boolean,
    encoryIntegrationDialogTitle: string,
    encoryIntegrationDialogResolve: any;
    encoryIntegrationStates: any[];
}

const supervisorEncoryModule = defineModule({
    namespaced: true,
    state: {
        supervisorEncoryIntegrations: [] as SupervisorEncoryIntegrationRestModel[],
        showEncoryIntegrationDialog: false,
        encoryIntegrationDialogTitle: "",
        encoryIntegrationDialogResolve: null,
        encoryIntegrationStates: [
            { id: 0, state: "Not set" },
            { id: 2, state: "Scanned ad dealer" },
        ],

    } as SupervisorEncoryModuleStateInterface,
    mutations: {
        setSupervisorEncoryIntegrations(state, value: SupervisorEncoryIntegrationRestModel[]) {
            state.supervisorEncoryIntegrations = value;
        },
        setShowEncoryIntegrationDialog(state, value: boolean) {
            state.showEncoryIntegrationDialog = value;
        },
        setEncoryIntegrationDialogTitle(state, value: string) {
            state.encoryIntegrationDialogTitle = value;
        },
        setEncoryIntegrationDialogResolve(state, value: any) {
            state.encoryIntegrationDialogResolve = value;
        },
    },
    actions: {
        saveSupervisorEncoryDealerAddressesFile(context, payload) {
            const { state } = _moduleActionContext(context);
            const formData = new FormData();

            for (const index in payload) {
                formData.append("files", payload[index]);
            }

            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/supervisorEncory/upload-dealer-addresses-file", formData)
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        saveSupervisorEncoryPartsFile(context, payload) {
            const { state } = _moduleActionContext(context);
            const formData = new FormData();

            for (const index in payload) {
                formData.append("files", payload[index]);
            }

            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/supervisorEncory/upload-parts-file", formData)
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        downloadHandlingUnitsAndSendEmails(context) {
            const { state, commit, dispatch } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/supervisorEncory/download-handling-units-and-send-emails")
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        getSupervisorEncoryIntegrations(context, payload: { supervisorId: number, forSupervisorMemento: boolean }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/supervisorEncory/encory-integration-by-supervisor-id/" + payload.supervisorId + "/" + payload.forSupervisorMemento)
                    .then((resp: AxiosResponse) => {
                        commit.setSupervisorEncoryIntegrations(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        updateSupervisorEncoryIntegrations(context) {
            const { state, rootState, rootDispatch } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/supervisorEncory/encory-integration-by-supervisor-id/update/" + rootState.supervisor.supervisor.id, state.supervisorEncoryIntegrations)
                    .then((resp: AxiosResponse) => {
                        if (resp.data == false) {
                            rootDispatch.confirm.openConfirmDialog({
                                showConfirmDialog: true,
                                title: "BŁĄD",
                                message: "Wystąpił błąd.",
                                links: [],
                                options: { buttonColor: "error", layoutColor: "error" },
                                buttonType: "ok"
                            })
                        }
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => {
                            rootDispatch.confirm.openConfirmDialog({
                                showConfirmDialog: true,
                                title: "BŁĄD",
                                message: err.message + ": " + err.response?.data,
                                links: [],
                                options: { buttonColor: "error", layoutColor: "error" },
                                buttonType: "ok"
                            })
                        reject(err);
                    });
            })
        },
        isConfirmedEncoryIntegrationDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.encoryIntegrationDialogResolve(true);
            commit.setShowEncoryIntegrationDialog(false);
        },
        isNotConfirmedEncoryIntegrationDialog(context) {
            const { commit } = _moduleActionContext(context);
            commit.setShowEncoryIntegrationDialog(false);
        },
        openEncoryIntegrationDialog(
            context,
            payload: {
                showDialog: boolean;
                title: string;
            }
        ) {
            const { commit } = _moduleActionContext(context);
            commit.setShowEncoryIntegrationDialog(payload.showDialog);
            commit.setEncoryIntegrationDialogTitle(payload.title);
            return new Promise(resolve => {
                commit.setEncoryIntegrationDialogResolve(resolve);
            });
        },
        async getHandlingUnitDetails(context, payload: { handlingUnitId: number }) {
            const { commit } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/supervisorEncory/get-handling-unit-details/" + payload.handlingUnitId)
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                        return resp.data
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
    }
});

export default supervisorEncoryModule;
const _moduleActionContext = (context: any) => moduleActionContext(context, supervisorEncoryModule)