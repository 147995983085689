import Vue from "vue";
import { Component } from "vue-property-decorator";
import router from "../../../router";
import BasicTab from "./tab/basic.vue";
import SupervisorEncoryTab from "./tab/supervisor-encory.vue";

@Component({
    components: {
        "basic-tab": BasicTab,
        "supervisor-encory-tab": SupervisorEncoryTab,
    }
})

export default class Other extends Vue {
    otherActiveTab = "";

    created() {
        this.loadSettings();
    }

    loadSettings() {
        this.$store.direct.dispatch.admin.loadSettings();
    }

    async saveClick(): Promise<void> {
        this.$store.direct.dispatch.admin.saveSettings();
        router.push({ name: "Admin page" });
    }

    async backClick(): Promise<void> {
        await router.push({ name: "Admin page" });
    }
}
