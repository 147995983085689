import { moduleActionContext } from "@/store/index";
import { AxiosError, AxiosResponse } from "axios";
import { defineModule } from "direct-vuex";
import SettlementCostDocumentPaymentObligationListItemRestModel from "../../models/rest/settlement/cost-document/payment-obligation/settlementCostDocumentPaymentObligationListItemRestModel";
import SettlementPaymentCostDocumentRestModel from "../../models/rest/settlement/payment/settlementPaymentCostDocumentRestModel";
import SettlementPaymentIncomeDocumentRestModel from "../../models/rest/settlement/payment/settlementPaymentIncomeDocumentRestModel";
import SettlementPaymentListRequestModel from "../../models/rest/settlement/payment/settlementPaymentListRequestModel";
import SettlementPaymentRestModel from "../../models/rest/settlement/payment/settlementPaymentRestModel";
import { axiosInstance } from "../../plugins/axios";
import { saveFile } from "@/assets/file-saver";
import SettlementIncomeDocumentPaymentObligationListItemRestModel from "../../models/rest/settlement/income-document/payment-obligation/settlementIncomeDocumentPaymentObligationListItemRestModel";
import SettlementAmountSummaryRestModel from "../../models/rest/settlement/settlementAmountSummaryRestModel";
import ContractorSearchItemModel from "../../models/rest/contractor/contractorSearchItemModel";

export interface SettlementPaymentModuleStateInterface {
    payment: SettlementPaymentRestModel,
    paymentDate: string,
    paymentList: SettlementPaymentRestModel[],
    paymentListLoading: boolean;
    paymentListTotalCount: number,
    paymentListSearchText: string,
    paymentListPagerOptions: any,
    costPaymentListSearchContractors: ContractorSearchItemModel[],
    costPaymentListSearchContractorIdcs: number[],
    incomePaymentListSearchContractors: ContractorSearchItemModel[],
    incomePaymentListSearchContractorIdcs: number[],
    paymentListAmountSummaryList: SettlementAmountSummaryRestModel[],
    paymentValidationError: any
    showCostPaymentDialog: boolean,
    costPaymentDialogTitle: string,
    costPaymentDialogResolve: any,
    showIncomePaymentDialog: boolean,
    incomePaymentDialogTitle: string,
    incomePaymentDialogResolve: any,
    paymentCostDocumentRequestList: SettlementPaymentCostDocumentRestModel[],
    paymentIncomeDocumentRequestList: SettlementPaymentIncomeDocumentRestModel[],
    showEditPaymentOutsideTheSystemDialog: boolean,
    editPaymentOutsideTheSystemDialogComment: string,
    editPaymentOutsideTheSystemDialogPaymentDate: string,
    editPaymentOutsideTheSystemDialogTitle: string,
    editPaymentOutsideTheSystemDialogResolve: any,
    selectedPaymentList: SettlementPaymentRestModel[],
    setPaymentListForEditing: SettlementPaymentRestModel[],
}

const settlementPaymentModule = defineModule({
    namespaced: true,
    state: {
        payment: {} as SettlementPaymentRestModel,
        paymentDate: "",
        paymentList: [] as SettlementPaymentRestModel[],
        paymentListLoading: false,
        paymentListTotalCount: 0,
        paymentListSearchText: "",
        paymentListPagerOptions: {
            page: 1,
            itemsPerPage: 30,
            sortBy: ["id"],
            sortDesc: [true]
        },
        costPaymentListSearchContractors: [] as ContractorSearchItemModel[],
        costPaymentListSearchContractorIdcs: [] as number[],
        incomePaymentListSearchContractors: [] as ContractorSearchItemModel[],
        incomePaymentListSearchContractorIdcs: [] as number[],
        paymentListAmountSummaryList: [] as SettlementAmountSummaryRestModel[],
        paymentValidationError: {} as any,
        showCostPaymentDialog: false,
        costPaymentDialogTitle: "",
        costPaymentDialogResolve: null,
        showIncomePaymentDialog: false,
        incomePaymentDialogTitle: "",
        incomePaymentDialogResolve: null,
        paymentCostDocumentRequestList: [] as SettlementPaymentCostDocumentRestModel[],
        paymentIncomeDocumentRequestList: [] as SettlementPaymentIncomeDocumentRestModel[],
        showEditPaymentOutsideTheSystemDialog: false,
        editPaymentOutsideTheSystemDialogComment: "",
        editPaymentOutsideTheSystemDialogPaymentDate: "",
        editPaymentOutsideTheSystemDialogTitle: "",
        editPaymentOutsideTheSystemDialogResolve: null,
        selectedPaymentList: [] as SettlementPaymentRestModel[],
        setPaymentListForEditing: [] as SettlementPaymentRestModel[],
    } as SettlementPaymentModuleStateInterface,
    mutations: {
        setPayment(state, value: SettlementPaymentRestModel) {
            state.payment = value;
        },
        setPaymentDate(state, value: string) {
            state.paymentDate = value;
        },
        setPaymentList(state, value: SettlementPaymentRestModel[]) {
            state.paymentList = value;
        },
        setPaymentListLoading(state, value: boolean) {
            state.paymentListLoading = value;
        },
        setPaymentListTotalCount(state, value: number) {
            state.paymentListTotalCount = value;
            if ((state.paymentListPagerOptions.page * state.paymentListPagerOptions.itemsPerPage) >= (value + state.paymentListPagerOptions.itemsPerPage)) {
                state.paymentListPagerOptions.page = 1;
            }
        },
        setCostPaymentListSearchContractors(state, value: ContractorSearchItemModel[]) {
            state.costPaymentListSearchContractors = value;
        },
        setCostPaymentListSearchContractorIdcs(state, value: number[]) {
            state.costPaymentListSearchContractorIdcs = value;
        },
        setIncomePaymentListSearchContractors(state, value: ContractorSearchItemModel[]) {
            state.incomePaymentListSearchContractors = value;
        },
        setIncomePaymentListSearchContractorIdcs(state, value: number[]) {
            state.incomePaymentListSearchContractorIdcs = value;
        },
        setPaymentListSearchText(state, value: string) {
            state.paymentListSearchText = value;
        },
        setPaymentListPagerOptions(state, value) {
            state.paymentListPagerOptions = value;
        },
        setPaymentListAmountSummaryList(state, value: SettlementAmountSummaryRestModel[]) {
            state.paymentListAmountSummaryList = value;
        },
        setPaymentValidationError(state, value: any) {
            state.paymentValidationError = value;
        },
        setShowCostPaymentDialog(state, value: boolean) {
            state.showCostPaymentDialog = value;
        },
        setCostPaymentDialogTitle(state, value: string) {
            state.costPaymentDialogTitle = value;
        },
        setCostPaymentDialogResolve(state, value: any) {
            state.costPaymentDialogResolve = value;
        },
        setShowIncomePaymentDialog(state, value: boolean) {
            state.showIncomePaymentDialog = value;
        },
        setIncomePaymentDialogTitle(state, value: string) {
            state.incomePaymentDialogTitle = value;
        },
        setIncomePaymentDialogResolve(state, value: any) {
            state.incomePaymentDialogResolve = value;
        },
        setPaymentCostDocumentRequestList(state, value: SettlementPaymentCostDocumentRestModel[]) {
            state.paymentCostDocumentRequestList = value;
        },
        setPaymentIncomeDocumentRequestList(state, value: SettlementPaymentIncomeDocumentRestModel[]) {
            state.paymentIncomeDocumentRequestList = value;
        },
        setShowEditPaymentOutsideTheSystemDialog(state, value: boolean) {
            state.showEditPaymentOutsideTheSystemDialog = value;
        },
        setEditPaymentOutsideTheSystemDialogComment(state, value: string) {
            state.editPaymentOutsideTheSystemDialogComment = value;
        },
        setEditPaymentOutsideTheSystemDialogPaymentDate(state, value: string) {
            state.editPaymentOutsideTheSystemDialogPaymentDate = value;
        },
        setEditPaymentOutsideTheSystemDialogTitle(state, value: string) {
            state.editPaymentOutsideTheSystemDialogTitle = value;
        },
        setEditPaymentOutsideTheSystemDialogResolve(state, value: any) {
            state.editPaymentOutsideTheSystemDialogResolve = value;
        },
        setSelectedPaymentList(state, value: SettlementPaymentRestModel[]) {
            state.selectedPaymentList = value;
        },
        setPaymentListForEditing(state, value: SettlementPaymentRestModel[]) {
            state.selectedPaymentList = value;
        },
    },
    actions: {
        loadCostPaymentList(context, payload: { requestModel: SettlementPaymentListRequestModel }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                commit.setPaymentListLoading(true);

                axiosInstance
                    .post("/api/settlementPayment/payment/cost-payment/list", payload.requestModel)
                    .then((resp: AxiosResponse) => {
                        commit.setPaymentList(resp.data.items);
                        commit.setPaymentListTotalCount(resp.data.totalItemsCount);
                        commit.setPaymentListAmountSummaryList(resp.data.amountSummaryList);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setPaymentListLoading(false);
                    })
            })
        },
        async loadCostPayment(context, payload: { paymentGroupId: string }) {
            const { commit } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                if (payload.paymentGroupId.length != 0) {
                    axiosInstance
                        .get("/api/settlementPayment/payment/cost-payment/" + payload.paymentGroupId)
                        .then((resp: AxiosResponse) => {
                            commit.setPayment(resp.data);
                            commit.setPaymentDate(resp.data.paymentDate);
                            resolve(resp.data);
                        })
                        .catch((err: AxiosError) => reject(err));
                }
                else {
                    axiosInstance
                        .post("/api/settlementPayment/payment/cost-payment/temporary")
                        .then((resp: AxiosResponse) => {
                            commit.setPayment(resp.data);
                            commit.setPaymentValidationError({});
                            resolve(resp.data);
                        })
                        .catch((err: AxiosError) => reject(err));
                }
            })
        },
        async saveCostPayment(context) {
            const { state, commit } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/settlementPayment/payment/cost-payment/save", state.payment)
                    .then((resp: AxiosResponse) => {
                        commit.setPaymentValidationError({});
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => {
                        if (err.response && err.response.data.errors) {
                            commit.setPaymentValidationError(err.response.data.errors);
                            resolve(err.response.data.errors);
                        }
                        reject(err);
                    });
            })
        },
        async addPaymentOutsideTheSystemFromSettlementCostDocumentPaymentObligationList(context, payload: { selectedPaymentObligationList: SettlementCostDocumentPaymentObligationListItemRestModel[] }) {
            const { rootState, rootCommit } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/settlementPayment/payment/cost-payment/add-payment-outside-the-system-from-settlement-payment-obligation-list",
                        {
                            costPaymentObligationList: payload.selectedPaymentObligationList,
                            comment: rootState.settlementCommon.createPaymentOutsideTheSystemDialogComment,
                            paymentDate: rootState.settlementCommon.createPaymentOutsideTheSystemDialogPaymentDate,
                        }
                    )
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                        rootCommit.settlementCommon.setCreatePaymentOutsideTheSystemDialogComment("");
                        rootCommit.settlementCommon.setCreatePaymentOutsideTheSystemDialogPaymentDate("");
                    })
                    .catch((err: AxiosError) => {
                        if (err.response && err.response.data.errors) {
                            resolve(err.response.data.errors)
                        }
                        reject(err)
                    });
            })
        },
        async addPaymentOutsideTheSystemFromSettlementIncomeDocumentPaymentObligationList(context, payload: { selectedPaymentObligationList: SettlementIncomeDocumentPaymentObligationListItemRestModel[] }) {
            const { rootState, rootCommit } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/settlementPayment/payment/income-payment/add-payment-outside-the-system-from-settlement-payment-obligation-list",
                        {
                            incomePaymentObligationList: payload.selectedPaymentObligationList,
                            comment: rootState.settlementCommon.createPaymentOutsideTheSystemDialogComment,
                            paymentDate: rootState.settlementCommon.createPaymentOutsideTheSystemDialogPaymentDate,
                        }
                    )
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                        rootCommit.settlementCommon.setCreatePaymentOutsideTheSystemDialogComment("");
                        rootCommit.settlementCommon.setCreatePaymentOutsideTheSystemDialogPaymentDate("");
                    })
                    .catch((err: AxiosError) => {
                        if (err.response && err.response.data.errors) {
                            resolve(err.response.data.errors)
                        }
                        reject(err)
                    });
            })
        },
        loadIncomePaymentList(context, payload: { requestModel: SettlementPaymentListRequestModel }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                commit.setPaymentListLoading(true);
                axiosInstance
                    .post("/api/settlementPayment/payment/income-payment/list", payload.requestModel)
                    .then((resp: AxiosResponse) => {
                        commit.setPaymentList(resp.data.items);
                        commit.setPaymentListTotalCount(resp.data.totalItemsCount);
                        commit.setPaymentListAmountSummaryList(resp.data.amountSummaryList);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setPaymentListLoading(false);
                    })
            })
        },
        downloadXlsReportSettlementPaymentList(context,
            payload: { requestModel: SettlementPaymentListRequestModel, paymentListType: string }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((reject) => {
                commit.setPaymentListLoading(true);
                axiosInstance
                    .post("/api/settlementPayment/payment/" + payload.paymentListType + "/export", payload.requestModel, {
                        responseType: "blob"
                    })
                    .then((resp: AxiosResponse) => {
                        saveFile(resp);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setPaymentListLoading(false);
                    })
            });
        },
        async loadIncomePayment(context, payload: { paymentGroupId: string }) {
            const { commit } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                if (payload.paymentGroupId.length != 0) {
                    axiosInstance
                        .get("/api/settlementPayment/payment/income-payment/" + payload.paymentGroupId)
                        .then((resp: AxiosResponse) => {
                            commit.setPayment(resp.data);
                            commit.setPaymentDate(resp.data.paymentDate);
                            resolve(resp.data);
                        })
                        .catch((err: AxiosError) => reject(err));
                }
                else {
                    axiosInstance
                        .post("/api/settlementPayment/payment/income-payment/temporary")
                        .then((resp: AxiosResponse) => {
                            commit.setPayment(resp.data);
                            commit.setPaymentValidationError({});
                            resolve(resp.data);
                        })
                        .catch((err: AxiosError) => reject(err));
                }
            })
        },
        async saveIncomePayment(context) {
            const { state, commit } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/settlementPayment/payment/income-payment/save", state.payment)
                    .then((resp: AxiosResponse) => {
                        commit.setPaymentValidationError({});
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => {
                        if (err.response && err.response.data.errors) {
                            commit.setPaymentValidationError(err.response.data.errors);
                            resolve(err.response.data.errors);
                        }
                        reject(err);
                    });
            })
        },
        openCostPaymentDialog(context, payload: { title: string; }) {
            const { commit } = _moduleActionContext(context);
            commit.setShowCostPaymentDialog(true);
            commit.setCostPaymentDialogTitle(payload.title);
            return new Promise(resolve => {
                commit.setCostPaymentDialogResolve(resolve);
            });
        },
        isConfirmedCostPaymentDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.costPaymentDialogResolve(true);
            commit.setShowCostPaymentDialog(false);
        },
        isNotConfirmedCostPaymentDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.costPaymentDialogResolve(false);
            commit.setShowCostPaymentDialog(false);
        },
        openIncomePaymentDialog(context, payload: { title: string; }) {
            const { commit } = _moduleActionContext(context);
            commit.setShowIncomePaymentDialog(true);
            commit.setIncomePaymentDialogTitle(payload.title);
            return new Promise(resolve => {
                commit.setIncomePaymentDialogResolve(resolve);
            });
        },
        isConfirmedIncomePaymentDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.incomePaymentDialogResolve(true);
            commit.setShowIncomePaymentDialog(false);
        },
        isNotConfirmedIncomePaymentDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.incomePaymentDialogResolve(false);
            commit.setShowIncomePaymentDialog(false);
        },
        deletePayment(context, payload: { groupId: string }) {
            const { state } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/settlementPayment/payment/delete/" + payload.groupId)
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        openEditPaymentOutsideTheSystemDialog(context, payload: { title: string; }) {
            const { commit } = _moduleActionContext(context);
            commit.setShowEditPaymentOutsideTheSystemDialog(true);
            commit.setEditPaymentOutsideTheSystemDialogTitle(payload.title);
            return new Promise(resolve => {
                commit.setEditPaymentOutsideTheSystemDialogResolve(resolve);
            });
        },
        isConfirmedEditPaymentOutsideTheSystemDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.editPaymentOutsideTheSystemDialogResolve(true);
            commit.setShowEditPaymentOutsideTheSystemDialog(false);
        },
        isNotConfirmedEditPaymentOutsideTheSystemDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.editPaymentOutsideTheSystemDialogResolve(false);
            commit.setShowEditPaymentOutsideTheSystemDialog(false);
        },
        async saveEditedCostPaymentList(context) {
            const { state, commit } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/settlementPayment/payment/cost-payment/edit-payment-outside-the-system", {
                        paymentList: state.selectedPaymentList,
                        paymentDate: state.editPaymentOutsideTheSystemDialogPaymentDate,
                        comment: state.editPaymentOutsideTheSystemDialogComment
                    })
                    .then((resp: AxiosResponse) => {
                        commit.setEditPaymentOutsideTheSystemDialogComment("");
                        commit.setEditPaymentOutsideTheSystemDialogPaymentDate("");
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
    }
});

export default settlementPaymentModule;
const _moduleActionContext = (context: any) => moduleActionContext(context, settlementPaymentModule)