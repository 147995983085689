import { saveFile } from "@/assets/file-saver";
import { moduleActionContext } from "@/store/index";
import { AxiosError, AxiosResponse } from "axios";
import { defineModule } from "direct-vuex";
import i18n from "../../assets/i18n";
import { SettlementDocumentRecalculatingTypeEnum } from "../../models/emums/settlement/settlementDocumentRecalculatingTypeEnum";
import ContractorSearchItemModel from "../../models/rest/contractor/contractorSearchItemModel";
import CurrencyRestModel from "../../models/rest/register/currencyRestModel";
import SaveAttachmentResponseModel from "../../models/rest/saveAttachmentResponseModel";
import SettlementIncomeDocumentAttachmentRestModel from "../../models/rest/settlement/income-document/settlementIncomeDocumentAttachmentRestModel";
import SettlementIncomeDocumentCorrectionListItemRestModel from "../../models/rest/settlement/income-document/settlementIncomeDocumentCorrectionListItemRestModel";
import SettlementIncomeDocumentItemRestModel from "../../models/rest/settlement/income-document/settlementIncomeDocumentItemRestModel";
import SettlementIncomeDocumentItemServiceTypeRestModel from "../../models/rest/settlement/income-document/settlementIncomeDocumentItemServiceTypeRestModel";
import SettlementIncomeDocumentListItemRestModel from "../../models/rest/settlement/income-document/settlementIncomeDocumentListItemRestModel";
import SettlementIncomeDocumentListRequestModel from "../../models/rest/settlement/income-document/settlementIncomeDocumentListRequestModel";
import SettlementIncomeDocumentMementoItemRestModel from "../../models/rest/settlement/income-document/settlementIncomeDocumentMementoItemRestModel";
import SettlementIncomeDocumentRestModel from "../../models/rest/settlement/income-document/settlementIncomeDocumentRestModel";
import SettlementAmountSummaryRestModel from "../../models/rest/settlement/settlementAmountSummaryRestModel";
import SettlementDocumentSaveResponseRestModel from "../../models/rest/settlement/settlementDocumentSaveResponseRestModel";
import SupervisorForSettlementRestModel from "../../models/rest/supervisor/supervisorForSettlementRestModel";
import { default as SupervisorRestModel } from "../../models/rest/supervisor/supervisorRestModel";
import { axiosInstance } from "../../plugins/axios";

export interface SettlementIncomeDocumentModuleStateInterface {
    incomeDocumentList: SettlementIncomeDocumentListItemRestModel[],
    incomeDocumentListLoading: boolean;
    incomeDocumentListTotalCount: number,
    incomeDocumentListAmountSummaryList: SettlementAmountSummaryRestModel[],
    incomeDocumentListSearchText: string,
    incomeDocumentListPagerOptions: any,
    incomeDocumentListSearchBranchId: number,
    incomeDocumentListSearchDocumentStatusIdcs: number[],
    incomeDocumentListSearchDocumentTypeIdcs: number[],
    incomeDocumentListSearchIncomeDocumentTypeId: number,
    incomeDocumentListSearchContractors: ContractorSearchItemModel[],
    incomeDocumentListSearchContractorIdcs: number[],
    incomeDocumentListSearchSupervisor: SupervisorRestModel,
    incomeDocumentListSearchIsPaid: number,
    incomeDocumentListSearchIssueDateFrom: string,
    incomeDocumentListSearchIssueDateTo: string,
    incomeDocumentListSearchSaleDateFrom: string,
    incomeDocumentListSearchSaleDateTo: string,
    incomeDocumentListSearchPaymentTermDateFrom: string,
    incomeDocumentListSearchPaymentTermDateTo: string,
    incomeDocumentListSearchPaymentDateFrom: string,
    incomeDocumentListSearchPaymentDateTo: string,
    incomeDocumentListSearchExpiredDaysSince: string,
    incomeDocumentListSearchExpiredDaysTo: string,
    incomeDocumentListSearchSelectedCurrency: CurrencyRestModel,
    incomeDocumentListSearchItemServiceTypes: SettlementIncomeDocumentItemServiceTypeRestModel[],
    incomeDocumentListSearchCountryCode: string[],
    incomeDocumentCorrectionList: SettlementIncomeDocumentCorrectionListItemRestModel[],
    incomeDocumentCorrectionListLoading: boolean;
    incomeDocumentCorrectionListTotalCount: number,
    incomeDocumentCorrectionListSearchText: string,
    incomeDocumentCorrectionListPagerOptions: any,
    incomeDocumentCorrectionListAmountSummaryList: SettlementAmountSummaryRestModel[],
    incomeDocumentCorrectionListSelectedBranch: number,
    incomeDocumentCorrectionListSearchContractors: ContractorSearchItemModel[],
    incomeDocumentCorrectionListSearchContractorIdcs: number[],
    incomeDocument: SettlementIncomeDocumentRestModel,
    incomeDocumentDocumentRecalculatingType: SettlementDocumentRecalculatingTypeEnum,
    incomeDocumentIssueDate: string,
    incomeDocumentPaymentDate: string,
    incomeDocumentSaleDate: string,
    incomeDocumentValidationError: any,
    incomeDocumentItem: SettlementIncomeDocumentItemRestModel,
    incomeDocumentItemList: SettlementIncomeDocumentItemRestModel[],
    incomeDocumentItemCorrectionList: SettlementIncomeDocumentItemRestModel[],
    incomeDocumentItemValidationError: any
    showIncomeDocumentItemDialog: boolean,
    incomeDocumentItemDialogTitle: string,
    incomeDocumentItemDialogIsFromCorrection: boolean,
    incomeDocumentItemDialogResolve: any,
    supervisorIncomeDocumentList: SettlementIncomeDocumentRestModel[],
    supervisorIncomeDocumentListLoading: boolean,
    attachmentList: SettlementIncomeDocumentAttachmentRestModel[];
    attachmentDialogAttachments: any[],
    showAttachmentDialog: false | boolean;
    attachmentDialogResolve: any;
    incomeDocumentSearch: SettlementIncomeDocumentRestModel[],
    incomeDocumentSaveResponse: SettlementDocumentSaveResponseRestModel,
    selectedIncomeDocumentList: SettlementIncomeDocumentListItemRestModel[],
    selectedIncomeDocumentCorrectionList: SettlementIncomeDocumentCorrectionListItemRestModel[],
    isIncomeDocumentInHistoricalState: boolean;
    incomeDocumentMementoListCount: number;
    incomeDocumentMementoList: SettlementIncomeDocumentMementoItemRestModel[];
    incomeDocumentCorrectionListSearchIssueDateFrom: string,
    incomeDocumentCorrectionListSearchIssueDateTo: string,
    incomeDocumentCorrectionListSearchSaleDateFrom: string,
    incomeDocumentCorrectionListSearchSaleDateTo: string,
    incomeDocumentListFilterAsActive: boolean,
}

const settlementIncomeDocumentModule = defineModule({
    namespaced: true,
    state: {
        incomeDocumentList: [] as SettlementIncomeDocumentListItemRestModel[],
        incomeDocumentListLoading: false,
        incomeDocumentListTotalCount: 0,
        incomeDocumentListAmountSummaryList: [] as SettlementAmountSummaryRestModel[],
        incomeDocumentListSearchText: "",
        incomeDocumentListPagerOptions: {
            page: 1,
            itemsPerPage: 30,
            sortBy: ["id"],
            sortDesc: [true]
        },
        incomeDocumentListSearchBranchId: 0,
        incomeDocumentListSearchDocumentStatusIdcs: [] as number[],
        incomeDocumentListSearchDocumentTypeIdcs: [],
        incomeDocumentListSearchIncomeDocumentTypeId: 0,
        incomeDocumentListSearchContractors: [] as ContractorSearchItemModel[],
        incomeDocumentListSearchContractorIdcs: [] as number[],
        incomeDocumentListSearchSupervisor: {} as SupervisorRestModel,
        incomeDocumentListSearchIsPaid: 0,
        incomeDocumentListSearchIssueDateFrom: "",
        incomeDocumentListSearchIssueDateTo: "",
        incomeDocumentListSearchSaleDateFrom: "",
        incomeDocumentListSearchSaleDateTo: "",
        incomeDocumentListSearchPaymentTermDateFrom: "",
        incomeDocumentListSearchPaymentTermDateTo: "",
        incomeDocumentListSearchPaymentDateFrom: "",
        incomeDocumentListSearchPaymentDateTo: "",
        incomeDocumentListSearchExpiredDaysSince: "",
        incomeDocumentListSearchExpiredDaysTo: "",
        incomeDocumentListSearchSelectedCurrency: {} as CurrencyRestModel,
        incomeDocumentListSearchItemServiceTypes: [] as SettlementIncomeDocumentItemServiceTypeRestModel[],
        incomeDocumentListSearchCountryCode: [] as string[],
        incomeDocumentCorrectionList: [] as SettlementIncomeDocumentCorrectionListItemRestModel[],
        incomeDocumentCorrectionListLoading: false,
        incomeDocumentCorrectionListTotalCount: 0,
        incomeDocumentCorrectionListSearchText: "",
        incomeDocumentCorrectionListPagerOptions: {
            page: 1,
            itemsPerPage: 30,
            sortBy: ["id"],
            sortDesc: [true]
        },
        incomeDocumentCorrectionListAmountSummaryList: [] as SettlementAmountSummaryRestModel[],
        incomeDocumentCorrectionListSelectedBranch: 0,
        incomeDocumentCorrectionListSearchContractorIdcs: [] as number[],
        incomeDocumentCorrectionListSearchContractors: [] as ContractorSearchItemModel[],
        incomeDocument: {} as SettlementIncomeDocumentRestModel,
        incomeDocumentDocumentRecalculatingType: SettlementDocumentRecalculatingTypeEnum.DocumentInForeignCurrency,
        incomeDocumentIssueDate: "",
        incomeDocumentPaymentDate: "",
        incomeDocumentSaleDate: "",
        incomeDocumentValidationError: {} as any,
        incomeDocumentItem: {} as SettlementIncomeDocumentItemRestModel,
        incomeDocumentItemList: [] as SettlementIncomeDocumentItemRestModel[],
        incomeDocumentItemCorrectionList: [] as SettlementIncomeDocumentItemRestModel[],
        incomeDocumentItemValidationError: {} as any,
        showIncomeDocumentItemDialog: false,
        incomeDocumentItemDialogTitle: "",
        incomeDocumentItemDialogIsFromCorrection: false,
        incomeDocumentItemDialogResolve: null,
        supervisorIncomeDocumentList: [] as SettlementIncomeDocumentRestModel[],
        supervisorIncomeDocumentListLoading: false,
        attachmentList: [] as SettlementIncomeDocumentAttachmentRestModel[],
        attachmentDialogAttachments: [] as any[],
        showAttachmentDialog: false,
        attachmentDialogResolve: null,
        incomeDocumentSearch: [] as SettlementIncomeDocumentRestModel[],
        incomeDocumentSaveResponse: {} as SettlementDocumentSaveResponseRestModel,
        selectedIncomeDocumentList: [] as SettlementIncomeDocumentListItemRestModel[],
        selectedIncomeDocumentCorrectionList: [] as SettlementIncomeDocumentCorrectionListItemRestModel[],
        isIncomeDocumentInHistoricalState: false,
        incomeDocumentMementoListCount: 0,
        incomeDocumentMementoList: [] as SettlementIncomeDocumentMementoItemRestModel[],
        incomeDocumentCorrectionListSearchIssueDateFrom: "",
        incomeDocumentCorrectionListSearchIssueDateTo: "",
        incomeDocumentCorrectionListSearchSaleDateFrom: "",
        incomeDocumentCorrectionListSearchSaleDateTo: "",
        incomeDocumentListFilterAsActive: false,
    } as SettlementIncomeDocumentModuleStateInterface,
    mutations: {
        setIncomeDocumentList(state, value: SettlementIncomeDocumentListItemRestModel[]) {
            state.incomeDocumentList = value;
        },
        setIncomeDocumentListLoading(state, value: boolean) {
            state.incomeDocumentListLoading = value;
        },
        setIncomeDocumentListTotalCount(state, value: number) {
            state.incomeDocumentListTotalCount = value;
            if ((state.incomeDocumentListPagerOptions.page * state.incomeDocumentListPagerOptions.itemsPerPage) >= (value + state.incomeDocumentListPagerOptions.itemsPerPage)) {
                state.incomeDocumentListPagerOptions.page = 1;
            }
        },
        setIncomeDocumentListAmountSummaryList(state, value: SettlementAmountSummaryRestModel[]) {
            state.incomeDocumentListAmountSummaryList = value;
        },
        setIncomeDocumentListSearchText(state, value: string) {
            state.incomeDocumentListSearchText = value;
        },
        setIncomeDocumentListPagerOptions(state, value) {
            state.incomeDocumentListPagerOptions = value;
        },
        setIncomeDocumentListSearchBranchId(state, value: number) {
            state.incomeDocumentListSearchBranchId = value;
        },
        setIncomeDocumentListSearchDocumentStatusIdcs(state, value: number[]) {
            state.incomeDocumentListSearchDocumentStatusIdcs = value;
        },
        setIncomeDocumentListSearchDocumentTypeIdcs(state, value: number[]) {
            state.incomeDocumentListSearchDocumentTypeIdcs = value;
        },
        setIncomeDocumentListSearchIncomeDocumentTypeId(state, value: number) {
            state.incomeDocumentListSearchIncomeDocumentTypeId = value;
        },
        setIncomeDocumentListSearchContractors(state, value: ContractorSearchItemModel[]) {
            state.incomeDocumentListSearchContractors = value;
        },
        setIncomeDocumentListSearchContractorIdcs(state, value: number[]) {
            state.incomeDocumentListSearchContractorIdcs = value;
        },
        setIncomeDocumentListSearchSupervisor(state, value: SupervisorRestModel) {
            state.incomeDocumentListSearchSupervisor = value;
        },
        setIncomeDocumentListSearchIsPaid(state, value: number) {
            state.incomeDocumentListSearchIsPaid = value;
        },
        setIncomeDocumentListSearchIssueDateFrom(state, value: string) {
            state.incomeDocumentListSearchIssueDateFrom = value;
        },
        setIncomeDocumentListSearchIssueDateTo(state, value: string) {
            state.incomeDocumentListSearchIssueDateTo = value;
        },
        setIncomeDocumentListSearchSaleDateFrom(state, value: string) {
            state.incomeDocumentListSearchSaleDateFrom = value;
        },
        setIncomeDocumentListSearchSaleDateTo(state, value: string) {
            state.incomeDocumentListSearchSaleDateTo = value;
        },
        setIncomeDocumentListSearchPaymentTermDateFrom(state, value: string) {
            state.incomeDocumentListSearchPaymentTermDateFrom = value;
        },
        setIncomeDocumentListSearchPaymentTermDateTo(state, value: string) {
            state.incomeDocumentListSearchPaymentTermDateTo = value;
        },
        setIncomeDocumentListSearchPaymentDateFrom(state, value: string) {
            state.incomeDocumentListSearchPaymentDateFrom = value;
        },
        setIncomeDocumentListSearchPaymentDateTo(state, value: string) {
            state.incomeDocumentListSearchPaymentDateTo = value;
        },
        setIncomeDocumentListSearchExpiredDaysSince(state, value: string) {
            state.incomeDocumentListSearchExpiredDaysSince = value;
        },
        setIncomeDocumentListSearchExpiredDaysTo(state, value: string) {
            state.incomeDocumentListSearchExpiredDaysTo = value;
        },
        setIncomeDocumentListSearchSelectedCurrency(state, value: CurrencyRestModel) {
            state.incomeDocumentListSearchSelectedCurrency = value;
        },
        setIncomeDocumentListSearchItemServiceTypes(state, value: SettlementIncomeDocumentItemServiceTypeRestModel[]) {
            state.incomeDocumentListSearchItemServiceTypes = value;
        },
        setIncomeDocumentListSearchCountryCode(state, value: string[]) {
            state.incomeDocumentListSearchCountryCode = value;
        },
        setIncomeDocumentCorrectionList(state, value: SettlementIncomeDocumentCorrectionListItemRestModel[]) {
            state.incomeDocumentCorrectionList = value;
        },
        setIncomeDocumentCorrectionListLoading(state, value: boolean) {
            state.incomeDocumentCorrectionListLoading = value;
        },
        setIncomeDocumentCorrectionListTotalCount(state, value: number) {
            state.incomeDocumentCorrectionListTotalCount = value;
            if ((state.incomeDocumentCorrectionListPagerOptions.page * state.incomeDocumentCorrectionListPagerOptions.itemsPerPage) >= (value + state.incomeDocumentCorrectionListPagerOptions.itemsPerPage)) {
                state.incomeDocumentCorrectionListPagerOptions.page = 1;
            }
        },
        setIncomeDocumentCorrectionListSearchText(state, value: string) {
            state.incomeDocumentCorrectionListSearchText = value;
        },
        setIncomeDocumentCorrectionListPagerOptions(state, value) {
            state.incomeDocumentCorrectionListPagerOptions = value;
        },
        setIncomeDocumentCorrectionListAmountSummaryList(state, value: SettlementAmountSummaryRestModel[]) {
            state.incomeDocumentCorrectionListAmountSummaryList = value;
        },
        setIncomeDocumentCorrectionListSelectedBranch(state, value: number) {
            state.incomeDocumentCorrectionListSelectedBranch = value;
        },
        setIncomeDocumentCorrectionListSearchContractors(state, value: ContractorSearchItemModel[]) {
            state.incomeDocumentCorrectionListSearchContractors = value;
        },
        setIncomeDocumentCorrectionListSearchContractorIdcs(state, value: number[]) {
            state.incomeDocumentCorrectionListSearchContractorIdcs = value;
        },
        setIncomeDocument(state, value: SettlementIncomeDocumentRestModel) {
            state.incomeDocument = value;
        },
        setIncomeDocumentDocumentRecalculatingType(state, value: SettlementDocumentRecalculatingTypeEnum) {
            state.incomeDocumentDocumentRecalculatingType = value;
        },
        setIncomeDocumentIssueDate(state, value: string) {
            state.incomeDocumentIssueDate = value;
        },
        setIncomeDocumentPaymentDate(state, value: string) {
            state.incomeDocumentPaymentDate = value;
        },
        setIncomeDocumentSaleDate(state, value: string) {
            state.incomeDocumentSaleDate = value;
        },
        setIncomeDocumentValidationError(state, value: any) {
            state.incomeDocumentValidationError = value;
        },
        setIncomeDocumentItem(state, value: SettlementIncomeDocumentItemRestModel) {
            state.incomeDocumentItem = value;
        },
        setIncomeDocumentItemList(state, value: SettlementIncomeDocumentItemRestModel[]) {
            state.incomeDocumentItemList = value;
        },
        setIncomeDocumentItemCorrectionList(state, value: SettlementIncomeDocumentItemRestModel[]) {
            state.incomeDocumentItemCorrectionList = value;
        },
        setIncomeDocumentItemValidationError(state, value: any) {
            state.incomeDocumentItemValidationError = value;
        },
        setShowIncomeDocumentItemDialog(state, value: boolean) {
            state.showIncomeDocumentItemDialog = value;
        },
        setIncomeDocumentItemDialogTitle(state, value: string) {
            state.incomeDocumentItemDialogTitle = value;
        },
        setIncomeDocumentItemDialogIsFromCorrection(state, value: boolean) {
            state.incomeDocumentItemDialogIsFromCorrection = value;
        },
        setIncomeDocumentItemDialogResolve(state, value: any) {
            state.incomeDocumentItemDialogResolve = value;
        },
        setSupervisorIncomeDocumentList(state, value: SettlementIncomeDocumentRestModel[]) {
            state.supervisorIncomeDocumentList = value;
        },
        setSupervisorIncomeDocumentListLoading(state, value: boolean) {
            state.supervisorIncomeDocumentListLoading = value;
        },
        setAttachmentList(state, value: SettlementIncomeDocumentAttachmentRestModel[]) {
            state.attachmentList = value;
        },
        setAttachmentDialogAttachments(state, value: any[]) {
            state.attachmentDialogAttachments = value;
        },
        setShowAttachmentDialog(state, value: boolean) {
            state.showAttachmentDialog = value;
        },
        setAttachmentDialogResolve(state, value: any) {
            state.attachmentDialogResolve = value;
        },
        setIncomeDocumentSearch(state, value: SettlementIncomeDocumentRestModel[]) {
            state.incomeDocumentSearch = value;
        },
        setIncomeDocumentSaveResponse(state, value: SettlementDocumentSaveResponseRestModel) {
            state.incomeDocumentSaveResponse = value;
        },
        setSelectedIncomeDocumentList(state, value: SettlementIncomeDocumentListItemRestModel[]) {
            state.selectedIncomeDocumentList = value;
        },
        setSelectedIncomeDocumentCorrectionList(state, value: SettlementIncomeDocumentCorrectionListItemRestModel[]) {
            state.selectedIncomeDocumentCorrectionList = value;
        },
        setIsIncomeDocumentInHistoricalState(state, value: boolean) {
            state.isIncomeDocumentInHistoricalState = value;
        },
        setIncomeDocumentMementoListCount(state, value: number) {
            state.incomeDocumentMementoListCount = value;
        },
        setIncomeDocumentMementoList(state, value: SettlementIncomeDocumentMementoItemRestModel[]) {
            state.incomeDocumentMementoList = value;
        },
        setIncomeDocumentCorrectionListSearchIssueDateFrom(state, value: string) {
            state.incomeDocumentCorrectionListSearchIssueDateFrom = value;
        },
        setIncomeDocumentCorrectionListSearchIssueDateTo(state, value: string) {
            state.incomeDocumentCorrectionListSearchIssueDateTo = value;
        },
        setIncomeDocumentCorrectionListSearchSaleDateFrom(state, value: string) {
            state.incomeDocumentCorrectionListSearchSaleDateFrom = value;
        },
        setIncomeDocumentCorrectionListSearchSaleDateTo(state, value: string) {
            state.incomeDocumentCorrectionListSearchSaleDateTo = value;
        },
        setIncomeDocumentListFilterAsActive(state, value: boolean) {
            state.incomeDocumentListFilterAsActive = value;
        },
    },
    actions: {
        loadIncomeDocumentList(context, payload: { requestModel: SettlementIncomeDocumentListRequestModel }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                commit.setIncomeDocumentListLoading(true);
                axiosInstance
                    .post("/api/settlementIncomeDocument/income-document/list", payload.requestModel)
                    .then((resp: AxiosResponse) => {
                        commit.setIncomeDocumentList(resp.data.items);
                        commit.setIncomeDocumentListTotalCount(resp.data.totalItemsCount)
                        commit.setIncomeDocumentListAmountSummaryList(resp.data.amountSummaryList)
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setIncomeDocumentListLoading(false);
                    })
            })
        },
        async loadIncomeDocument(context, payload: { incomeDocumentId: number }) {
            const { commit, rootCommit } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                if (!isNaN(payload.incomeDocumentId)) {
                    axiosInstance
                        .get("/api/settlementIncomeDocument/income-document/" + payload.incomeDocumentId)
                        .then((resp: AxiosResponse) => {
                            commit.setIncomeDocument(resp.data);
                            commit.setIncomeDocumentIssueDate(resp.data.issueDate);
                            commit.setIncomeDocumentPaymentDate(resp.data.paymentDate);
                            commit.setIncomeDocumentSaleDate(resp.data.saleDate);
                            commit.setIncomeDocumentItemList(resp.data.incomeDocumentItems);
                            commit.setIncomeDocumentItemCorrectionList(resp.data.incomeDocumentCorrectionItems)
                            rootCommit.settlementCommon.setSupervisorList(resp.data.incomeDocumentSupervisors);
                            resolve(resp.data);
                        })
                        .catch((err: AxiosError) => reject(err));
                }
                else {
                    axiosInstance
                        .get("/api/settlementIncomeDocument/income-document/temporary")
                        .then((resp: AxiosResponse) => {
                            commit.setIncomeDocument(resp.data)
                            resolve(resp.data);
                        })
                        .catch((err: AxiosError) => reject(err));
                }
            })
        },
        async saveIncomeDocument(context) {
            const { state, commit, rootDispatch } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/settlementIncomeDocument/income-document/save", state.incomeDocument)
                    .then(async (resp: AxiosResponse) => {
                        commit.setIncomeDocumentValidationError({});
                        commit.setIncomeDocumentSaveResponse(resp.data);

                        if (resp.data.documentId != null) {
                            state.incomeDocument.id = resp.data.documentId;
                        }

                        await rootDispatch.confirm.openConfirmDialog({
                            showConfirmDialog: true,
                            title: resp.data.isSaved ? i18n.t("incomeDocument.documentHasBeenSaved").toString() : i18n.t("incomeDocument.incomeDocumentCouldNotBeSaved").toString(),
                            message: resp.data.isSaved ? i18n.t("incomeDocument.documentNumber").toString() + ": " + resp.data.message : i18n.t("incomeDocument.incomeDocumentCouldNotBeSaved").toString() + ": " + resp.data.message,
                            links: [],
                            options: { buttonColor: resp.data.isSaved ? "primary" : "error", layoutColor: resp.data.isSaved ? "primary" : "error" },
                            buttonType: "ok"
                        });

                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => {
                        if (err.response && err.response.data.errors) {
                            commit.setIncomeDocumentValidationError(err.response.data.errors);
                            resolve(err.response.data.errors);
                        }
                        reject(err)
                    });
            })
        },
        async saveIncomeDocumentCorrection(context) {
            const { state, commit, rootDispatch } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/settlementIncomeDocument/income-document/correction/save", state.incomeDocument)
                    .then(async (resp: AxiosResponse) => {
                        commit.setIncomeDocumentValidationError({});
                        commit.setIncomeDocumentSaveResponse(resp.data);

                        if (resp.data.documentId != null) {
                            state.incomeDocument.id = resp.data.documentId;
                        }

                        await rootDispatch.confirm.openConfirmDialog({
                            showConfirmDialog: true,
                            title: resp.data.isSaved ? i18n.t("incomeDocument.documentHasBeenSaved").toString() : i18n.t("incomeDocument.incomeDocumentCouldNotBeSaved").toString(),
                            message: resp.data.isSaved ? i18n.t("incomeDocument.documentNumber").toString() + ": " + resp.data.message : i18n.t("incomeDocument.incomeDocumentCouldNotBeSaved").toString() + ": " + resp.data.message,
                            links: [],
                            options: { buttonColor: resp.data.isSaved ? "primary" : "error", layoutColor: resp.data.isSaved ? "primary" : "error" },
                            buttonType: "ok"
                        });

                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => {
                        if (err.response && err.response.data.errors) {
                            commit.setIncomeDocumentValidationError(err.response.data.errors);
                            resolve(err.response.data.errors);
                        }
                        reject(err)
                    });
            })
        },
        async cancelIncomeDocumentCorrection(context, payload: { incomeDocumentCorrectionId: number }) {
            const { state } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/settlementIncomeDocument/income-document/correction/cancel/" + payload.incomeDocumentCorrectionId)
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => {
                        reject(err)
                    });
            })
        },
        isConfirmedAttachmentDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.attachmentDialogResolve(true);
            commit.setShowAttachmentDialog(false);
        },
        isNotConfirmedAttachmentDialog(context) {
            const { commit } = _moduleActionContext(context);
            commit.setShowAttachmentDialog(false);
        },
        openAttachmentDialog(context) {
            const { commit } = _moduleActionContext(context);
            commit.setAttachmentDialogAttachments([] as any[]);
            commit.setShowAttachmentDialog(true);
            return new Promise(resolve => {
                commit.setAttachmentDialogResolve(resolve);
            })
        },
        loadIncomeDocumentAttachmentList(context, payload: { mementoPoint: number | null } = { mementoPoint: null }) {
            const { state, commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                const params = {
                    mementoPoint: payload.mementoPoint,
                }
                axiosInstance
                    .get("/api/settlementIncomeDocument/income-document/" + state.incomeDocument.id + "/attachment/list", { params })
                    .then((resp: AxiosResponse) => {
                        commit.setAttachmentList(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        saveAttachments(context) {
            const { state, dispatch, rootDispatch } = _moduleActionContext(context);
            const formData = new FormData();
            for (const index in state.attachmentDialogAttachments) {
                formData.append("attachments", state.attachmentDialogAttachments[index]);
            }

            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/settlementIncomeDocument/income-document/" + state.incomeDocument.id + "/attachment/save", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                    .then((resp: AxiosResponse) => {
                        const response = resp.data as SaveAttachmentResponseModel;
                        if (response.statusCode == 415) {
                            rootDispatch.confirm.openConfirmDialog({
                                showConfirmDialog: true,
                                title: i18n.t("common.wrongFileFormatTitle").toString(),
                                message: i18n.t("common.wrongFileFormatTitleMessageFirstPart").toString() + response.fileNameList.join(", ") + i18n.t("common.wrongFileFormatTitleMessageLastPart").toString(),
                                links: [],
                                options: { buttonColor: "error", layoutColor: "error" },
                                buttonType: "ok"
                            })
                        }
                        dispatch.loadIncomeDocumentAttachmentList();
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => {
                        reject(err)
                    });
            });
        },
        downloadIncomeDocumentAttachment(context, payload: { incomeDocumentId: number, incomeDocumentAttachmentId: string }) {
            return new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/settlementIncomeDocument/income-document/" + payload.incomeDocumentId + "/attachment/" + payload.incomeDocumentAttachmentId, {
                        responseType: "blob"
                    })
                    .then((resp: AxiosResponse) => {
                        const blob = new Blob([resp.data], {
                            type: resp.headers["content-type"]
                        });
                        const contentDisposition = resp.headers["content-disposition"];
                        const attachmentname = contentDisposition
                            .split(";")[1]
                            .split("=")[1]
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement("a");
                        a.style.display = "none";
                        document.body.appendChild(a);
                        a.download = attachmentname;
                        a.href = url;
                        a.click();
                        window.URL.revokeObjectURL(url);

                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        deleteIncomeDocumentFile(context, payload: { fileId: string }) {
            const { state } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/settlementIncomeDocument/income-document/" + state.incomeDocument.id + "/attachment/delete/" + payload.fileId)
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        async validateIncomeDocumentItem(context, payload: { item: SettlementIncomeDocumentItemRestModel }) {
            const { state, commit } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                payload.item.incomeDocumentDocumentTypeId = state.incomeDocument.documentTypeId;
                axiosInstance
                    .post("/api/settlementIncomeDocument/income-document/item/validate", payload.item)
                    .then((resp: AxiosResponse) => {
                        commit.setIncomeDocumentItemValidationError({});
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => {
                        if (err.response && err.response.data.errors) {
                            commit.setIncomeDocumentItemValidationError(err.response.data.errors);
                            resolve(err.response.data.errors)
                        }
                        reject(err)
                    });
            })
        },
        openIncomeDocumentItemDialog(context, payload: { title: string, isFromCorrection: boolean }) {
            const { commit } = _moduleActionContext(context);
            commit.setShowIncomeDocumentItemDialog(true);
            commit.setIncomeDocumentItemDialogTitle(payload.title);
            commit.setIncomeDocumentItemDialogIsFromCorrection(payload.isFromCorrection);
            return new Promise(resolve => {
                commit.setIncomeDocumentItemDialogResolve(resolve);
            });
        },
        isConfirmedIncomeDocumentItemDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.incomeDocumentItemDialogResolve(true);
            commit.setShowIncomeDocumentItemDialog(false);
        },
        isNotConfirmedIncomeDocumentItemDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.incomeDocumentItemDialogResolve(false);
            commit.setShowIncomeDocumentItemDialog(false);
        },
        loadSupervisorIncomeDocumentList(context, payload: { supervisorId: number }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                commit.setSupervisorIncomeDocumentListLoading(true);
                axiosInstance
                    .get("/api/settlementIncomeDocument/supervisor/" + payload.supervisorId + "/income-document/list")
                    .then((resp: AxiosResponse) => {
                        commit.setSupervisorIncomeDocumentList(resp.data);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setSupervisorIncomeDocumentListLoading(false);
                    })
            })
        },
        async searchIncomeDocument(context, payload: { searchText: string }) {
            const { commit } = _moduleActionContext(context)
            {
                return await new Promise((resolve, reject) => {
                    axiosInstance
                        .post("/api/settlementIncomeDocument/income-document/search", { searchText: payload.searchText })
                        .then(async (resp: AxiosResponse) => {
                            commit.setIncomeDocumentSearch(resp.data);
                            resolve(resp.data);
                        })
                        .catch((err: AxiosError) => reject(err));
                });
            }
        },
        generateAndGetIncomeDocumentFile(context, payload: { incomeDocumentId: number, documentLanguage?: string | undefined, needGeneratingCollectiveStatement?: boolean }) {
            return new Promise((resolve, reject) => {
                payload.documentLanguage = payload.documentLanguage == undefined ? "default" : payload.documentLanguage;
                payload.needGeneratingCollectiveStatement = payload.needGeneratingCollectiveStatement == undefined ? false : true;
                axiosInstance
                    .get("/api/settlementIncomeDocument/income-document/" + payload.incomeDocumentId + "/generate-and-get-pdf-file/" + payload.documentLanguage + "/" + payload.needGeneratingCollectiveStatement, {
                        responseType: "blob"
                    })
                    .then((resp: AxiosResponse) => {
                        const blob = new Blob([resp.data], {
                            type: resp.headers["content-type"]
                        });
                        const contentDisposition = resp.headers["content-disposition"];
                        const filename = contentDisposition
                            .split(";")[1]
                            .split("=")[1]
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement("a");
                        a.style.display = "none";
                        document.body.appendChild(a);
                        a.download = filename;
                        a.href = url;
                        a.click();
                        window.URL.revokeObjectURL(url);

                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        loadIncomeDocumentCorrectionList(context,
            payload: {
                requestModel: SettlementIncomeDocumentListRequestModel
            }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                commit.setIncomeDocumentCorrectionListLoading(true);
                axiosInstance
                    .post("/api/settlementIncomeDocument/income-document/correction/list", payload.requestModel)
                    .then((resp: AxiosResponse) => {
                        commit.setIncomeDocumentCorrectionList(resp.data.items);
                        commit.setIncomeDocumentCorrectionListTotalCount(resp.data.totalItemsCount)
                        commit.setIncomeDocumentCorrectionListAmountSummaryList(resp.data.amountSummaryList)
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setIncomeDocumentCorrectionListLoading(false);
                    })
            })
        },
        downloadXlsReportIncomeDocumentCorrectionList(context,
            payload: {
                requestModel: SettlementIncomeDocumentListRequestModel
            }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((reject) => {
                commit.setIncomeDocumentCorrectionListLoading(true);
                axiosInstance
                    .post("/api/settlementIncomeDocument/income-document/correction/export", payload.requestModel, {
                        responseType: "blob"
                    })
                    .then((resp: AxiosResponse) => {
                        saveFile(resp);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setIncomeDocumentCorrectionListLoading(false);
                    })
            })
        },
        generateAndGetIncomeDocumentCorrectionFile(context, payload: { incomeDocumentCorrectionId: number, documentLanguage?: string | undefined }) {
            return new Promise((resolve, reject) => {
                payload.documentLanguage = payload.documentLanguage == undefined ? "default" : payload.documentLanguage;
                axiosInstance
                    .get("/api/settlementIncomeDocument/income-document/correction/" + payload.incomeDocumentCorrectionId + "/generate-and-get-pdf-file/" + payload.documentLanguage, {
                        responseType: "blob"
                    })
                    .then((resp: AxiosResponse) => {
                        const blob = new Blob([resp.data], {
                            type: resp.headers["content-type"]
                        });
                        const contentDisposition = resp.headers["content-disposition"];
                        const filename = contentDisposition
                            .split(";")[1]
                            .split("=")[1]
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement("a");
                        a.style.display = "none";
                        document.body.appendChild(a);
                        a.download = filename;
                        a.href = url;
                        a.click();
                        window.URL.revokeObjectURL(url);

                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            });
        },
        async deleteIncomeDocument(context, payload: { incomeDocumentId: number, isDocumentCorrection: boolean }) {
            const { state } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/settlementIncomeDocument/income-document/delete/" + payload.incomeDocumentId + "/" + payload.isDocumentCorrection)
                    .then(async (resp: AxiosResponse) => {
                        resolve(resp.data);
                        return resp.data;
                    })
                    .catch((err: AxiosError) => {
                        reject(err)
                    });
            })
        },
        downloadXlsReportSettlementIncomeDocumentList(context,
            payload: { requestModel: SettlementIncomeDocumentListRequestModel }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((reject) => {
                commit.setIncomeDocumentListLoading(true);
                axiosInstance
                    .post("/api/settlementIncomeDocument/income-document/export", payload.requestModel, {
                        responseType: "blob"
                    })
                    .then((resp: AxiosResponse) => {
                        saveFile(resp);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setIncomeDocumentListLoading(false);
                    })
            });
        },
        downloadXlsPaymentReportSettlementIncomeDocumentList(context,
            payload: { requestModel: SettlementIncomeDocumentListRequestModel }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((reject) => {
                commit.setIncomeDocumentListLoading(true);
                axiosInstance
                    .post("/api/settlementIncomeDocument/income-document/payment-export", payload.requestModel, {
                        responseType: "blob"
                    })
                    .then((resp: AxiosResponse) => {
                        saveFile(resp);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setIncomeDocumentListLoading(false);
                    })
            });
        },
        downloadXlsReportForCollectiveDocument(context,
            payload: { incomeDocument: SettlementIncomeDocumentListItemRestModel }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((reject) => {
                commit.setIncomeDocumentListLoading(true);
                axiosInstance
                    .get("/api/settlementIncomeDocument/income-document/exportForCollectiveDocument/" + payload.incomeDocument.id, {
                        responseType: "blob"
                    })
                    .then((resp: AxiosResponse) => {
                        saveFile(resp);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setIncomeDocumentListLoading(false);
                    })
            });
        },
        async getIncomeDocumentMementoPoint(context, payload: { mementoPoint: number }) {
            const { state, commit, rootCommit } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/settlementIncomeDocument/getIncomeDocumentMementoPoint/" + state.incomeDocument.id + '/' + payload.mementoPoint)
                    .then((resp: AxiosResponse) => {
                        commit.setIncomeDocument(resp.data);
                        commit.setIncomeDocumentIssueDate(resp.data.issueDate);
                        commit.setIncomeDocumentPaymentDate(resp.data.paymentDate);
                        commit.setIncomeDocumentSaleDate(resp.data.saleDate);
                        commit.setIncomeDocumentItemList(resp.data.incomeDocumentItems);
                        commit.setIncomeDocumentItemCorrectionList(resp.data.incomeDocumentCorrectionItems)
                        rootCommit.settlementCommon.setSupervisorList(resp.data.incomeDocumentSupervisors);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        async getIncomeDocumentMementoList(context, payload: { incomeDocumentId: number }) {
            const { commit } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/settlementIncomeDocument/getIncomeDocumentMementoList/" + payload.incomeDocumentId)
                    .then((resp: AxiosResponse) => {
                        commit.setIncomeDocumentMementoListCount(resp.data.mementosCount)
                        commit.setIncomeDocumentMementoList(resp.data.mementoList)
                        resolve(resp.data);
                    })
                    .catch((error: AxiosError) => reject(error));
            })
        },
        cleanIncomeDocumentProperties(context) {
            const { commit, rootCommit } = _moduleActionContext(context);
            commit.setIncomeDocument({} as SettlementIncomeDocumentRestModel);
            commit.setIncomeDocumentIssueDate("");
            commit.setIncomeDocumentPaymentDate("");
            commit.setIncomeDocumentSaleDate("");
            commit.setIncomeDocumentItemList([] as SettlementIncomeDocumentItemRestModel[]);
            commit.setIncomeDocumentItemCorrectionList([] as SettlementIncomeDocumentItemRestModel[])
            rootCommit.settlementCommon.setSupervisorList([] as SupervisorForSettlementRestModel[]);
        },
    }
});

export default settlementIncomeDocumentModule;
const _moduleActionContext = (context: any) => moduleActionContext(context, settlementIncomeDocumentModule)