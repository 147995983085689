import Vue from "vue";
import { Component } from "vue-property-decorator";
import { stringMaxLength } from "../../../../assets/validation/common";
import ListItemNumberModel from "../../../../models/listItemNumberModel";

@Component
export default class TagDialog extends Vue {
    validation = {
        code: [stringMaxLength(this.$i18n, this.$t("common.code"), 3), (value: string) => !!value || this.$t("common.validationErrorRequired")],
        name: [stringMaxLength(this.$i18n, this.$t("common.name"), 128), (value: string) => !!value || this.$t("common.validationErrorRequired")],
    };

    get showDialog(): boolean {
        return this.$store.direct.state.register.showTagDialog;
    }

    get model(): ListItemNumberModel {
        return this.$store.direct.state.register.tag;
    }

    async saveClick() {
        const valid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
        if (valid) {
            await this.$store.direct.dispatch.register.updateTag();
        }
    }

    async backClick() {
        await this.$store.direct.dispatch.register.closeTagDialog();
    }
}
