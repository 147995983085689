import dayjs from "dayjs";
import Vue from "vue";
import { Prop, Component, Watch } from "vue-property-decorator";
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import Highlight from '@tiptap/extension-highlight';
import TextAlign from '@tiptap/extension-text-align';

@Component({
    components: {
        EditorContent
    }
})

export default class Tiptap extends Vue {
    @Prop({ default: '' }) value!: string;
    @Prop({ default: 'Default Label' }) label!: string;
    @Prop({ default: false }) readonly!: boolean;

    editor: Editor | null = null;
    showTextEditor: boolean = false;

    @Watch('value')
    onValueChange(newValue: string) {
        const isSame = this.editor?.getHTML() === newValue;

        if (!isSame && this.editor) {
            this.editor.commands.setContent(newValue, false);
        }
    }

    mounted() {
        this.editor = new Editor({
            content: this.value,
            extensions: [
                StarterKit,
                TextAlign.configure({
                    types: ['heading', 'paragraph'],
                }),
                Highlight,
            ],
            onUpdate: () => {
                if (!this.readonly) {
                    this.$emit('input', this.editor?.getHTML());
                }
            },
        });
    }

    beforeDestroy() {
        this.editor?.destroy();
    }
}
