import { RouteConfig } from "vue-router";
import { Roles } from "@/models/emums/roles";
import store from "../store";

const routes: Array<RouteConfig> = [
    {
        path: "/contractor",
        component: require("@/layouts/layout.vue").default,
        children: [
            {
                path: "list",
                name: "Contractor list",
                component: require("@/components/contractor/contractor-list.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.Disponent, Roles.DisponentAdmin, Roles.Accountant, Roles.AccountantAdmin, Roles.CrmAdmin, Roles.Crm, Roles.CrmLower]
                }
            },
            {
                path: "create",
                name: "Contractor create",
                component: require("@/components/contractor/contractor.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.Disponent, Roles.DisponentAdmin, Roles.Accountant, Roles.AccountantAdmin, Roles.CrmAdmin, Roles.Crm, Roles.CrmLower]
                },
                beforeEnter: (async (to, from, next) => {
                    await store.dispatch.contractor.loadContractor({ id: "" });

                    next();
                }),
            },
            {
                path: "edit/:id/:isInHistoricalState?",
                name: "Contractor edit",
                component: require("@/components/contractor/contractor.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.Disponent, Roles.DisponentAdmin, Roles.Accountant, Roles.AccountantAdmin, Roles.CrmAdmin, Roles.Crm, Roles.CrmLower]
                },
                beforeEnter: (async (to, from, next) => {
                    const contractorId = Number.parseInt(to.params.id);

                    if (to.params.isInHistoricalState != undefined && to.params.isInHistoricalState != "undefined" && to.params.isInHistoricalState == "history") {
                        await store.dispatch.contractor.getContractorMementoList({ contractorId: contractorId });
                        store.commit.contractor.setIsContractorInHistoricalState(true);
                    }
                    else {
                        store.commit.contractor.setIsContractorInHistoricalState(false);
                    }

                    await store.dispatch.contractor.loadContractor({ id: contractorId.toString() });
                    store.dispatch.contractor.loadContractorBankAccountList();
                    store.dispatch.contractor.loadOperationList();
                    store.dispatch.contractor.loadContactPersonList();
                    store.dispatch.contractor.loadContractorDocumentList();
                    store.dispatch.contractor.loadEmailAddressList();
                    store.dispatch.contractor.loadPolicyList();
                    store.dispatch.contractor.loadNoteList();

                    next();
                }),
                children: [
                    {
                        path: ":deleteDocument/:fileId/:fileName",
                        name: "Contractor document delete confirm",
                        component: require("@/components/contractor/contractor.vue").default,
                        meta: {
                            roles: [Roles.Admin]
                        }
                    }
                ]
            },
            {
                path: "locations-map",
                name: "Contractors location map",
                component: require("@/components/contractor/locations-map.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.Disponent, Roles.DisponentAdmin, Roles.Accountant, Roles.AccountantAdmin, Roles.CrmAdmin, Roles.Crm, Roles.CrmLower]
                }
            }
        ]
    }
];

export default routes;
