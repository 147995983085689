import Vue from "vue";
import { Component } from "vue-property-decorator";
import ConfirmDialog from "../../../layouts/confirmDialog/confirmDialog.vue";
import VForm from "../../../models/v-form";

@Component({
    components: {
        "confirm-dialog": ConfirmDialog,
    }
})

export default class KpiGeneratingDialog extends Vue {
    selectedMonth = "";

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get showDialog(): boolean {
        return this.$store.direct.state.contractor.showKpiGeneratingDialog;
    }

    async saveClick(): Promise<void> {
        if ((this.$refs.form as VForm).validate() && this.selectedMonth.length > 0) {
            this.$store.direct.dispatch.contractor.generateKpiReport({ selectedMonth: this.selectedMonth });
            this.$store.direct.commit.contractor.setShowKpiGeneratingDialog(false);
        }
    }

    closeClick(): void {
        (this.$refs.form as VForm).resetValidation();
        this.$store.direct.commit.contractor.setShowKpiGeneratingDialog(false);
        this.selectedMonth = "";
    }
}
