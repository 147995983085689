import { moduleActionContext } from "@/store/index";
import { AxiosError, AxiosResponse } from "axios";
import { defineModule } from "direct-vuex";
import ContractorSearchItemModel from "../../models/rest/contractor/contractorSearchItemModel";
import CurrencyRestModel from "../../models/rest/register/currencyRestModel";
import SettlementCostDocumentPaymentObligationListItemRestModel from "../../models/rest/settlement/cost-document/payment-obligation/settlementCostDocumentPaymentObligationListItemRestModel";
import SettlementCostDocumentTransferOrderListRequestModel from "../../models/rest/settlement/cost-document/transfer-order/settlementCostDocumentTransferOrderListRequestModel";
import SettlementCostDocumentTransferOrderRestModel from "../../models/rest/settlement/cost-document/transfer-order/settlementCostDocumentTransferOrderRestModel";
import SettlementAmountSummaryRestModel from "../../models/rest/settlement/settlementAmountSummaryRestModel";
import { axiosInstance } from "../../plugins/axios";

export interface SettlementCostDocumentTransferOrderModuleStateInterface {
    transferOrderList: SettlementCostDocumentTransferOrderRestModel[],
    transferOrderListLoading: boolean;
    transferOrderListTotalCount: number,
    transferOrderListSearchBranchId: number,
    transferOrderListSearchContractors: ContractorSearchItemModel[],
    transferOrderListSearchContractorIdcs: number[],
    transferOrderListSelectedCurrency: CurrencyRestModel,
    transferOrderListSearchPackageNumber: string,
    transferOrderListSearchTransferNumber: string,
    transferOrderListSearchCostDocumentNumber: string,
    transferOrderListPagerOptions: any,
    costDocumentTransferOrderListAmountSummaryList: SettlementAmountSummaryRestModel[],
    transferOrder: SettlementCostDocumentTransferOrderRestModel,
    transferOrderValidationError: any
    transferOrderListValidationError: any,
    contractorSenderFound: ContractorSearchItemModel[];
    contractorRecipientFound: ContractorSearchItemModel[];
    showTransferOrderCancelDialog: boolean;
    resolveTransferOrderCancelDialog: any;
    transferOrderCancelDialogComment: string;
}

const settlementCostDocumentTransferOrderModule = defineModule({
    namespaced: true,
    state: {
        transferOrderList: [] as SettlementCostDocumentTransferOrderRestModel[],
        transferOrderListLoading: false,
        transferOrderListTotalCount: 0,
        transferOrderListSearchBranchId: 0,
        transferOrderListSearchContractors: [] as ContractorSearchItemModel[],
        transferOrderListSearchContractorIdcs: [] as number[],
        transferOrderListSelectedCurrency: {} as CurrencyRestModel,
        transferOrderListSearchPackageNumber: "",
        transferOrderListSearchTransferNumber: "",
        transferOrderListSearchCostDocumentNumber: "",
        transferOrderListPagerOptions: {
            page: 1,
            itemsPerPage: 30,
            sortBy: ["id"],
            sortDesc: [true]
        },
        costDocumentTransferOrderListAmountSummaryList: [] as SettlementAmountSummaryRestModel[],
        transferOrder: {} as SettlementCostDocumentTransferOrderRestModel,
        transferOrderValidationError: {} as any,
        transferOrderListValidationError: {} as any,
        contractorSenderFound: [] as ContractorSearchItemModel[],
        contractorRecipientFound: [] as ContractorSearchItemModel[],
        showTransferOrderCancelDialog: false,
        resolveTransferOrderCancelDialog: null,
        transferOrderCancelDialogComment: "",
    } as SettlementCostDocumentTransferOrderModuleStateInterface,
    mutations: {
        setTransferOrderList(state, value: SettlementCostDocumentTransferOrderRestModel[]) {
            state.transferOrderList = value;
        },
        setTransferOrderListLoading(state, value: boolean) {
            state.transferOrderListLoading = value;
        },
        setTransferOrderListTotalCount(state, value: number) {
            state.transferOrderListTotalCount = value;
            if ((state.transferOrderListPagerOptions.page * state.transferOrderListPagerOptions.itemsPerPage) >= (value + state.transferOrderListPagerOptions.itemsPerPage)) {
                state.transferOrderListPagerOptions.page = 1;
            }
        },
        setTransferOrderListSearchBranchId(state, value: number) {
            state.transferOrderListSearchBranchId = value;
        },
        setTransferOrderListSearchContractors(state, value: ContractorSearchItemModel[]) {
            state.transferOrderListSearchContractors = value;
        },
        setTransferOrderListSearchContractorIdcs(state, value: number[]) {
            state.transferOrderListSearchContractorIdcs = value;
        },
        setTransferOrderListSelectedCurrency(state, value: CurrencyRestModel) {
            state.transferOrderListSelectedCurrency = value;
        },
        setTransferOrderListSearchPackageNumber(state, value: string) {
            state.transferOrderListSearchPackageNumber = value;
        },
        setTransferOrderListSearchTransferNumber(state, value: string) {
            state.transferOrderListSearchTransferNumber = value;
        },
        setTransferOrderListSearchCostDocumentNumber(state, value: string) {
            state.transferOrderListSearchCostDocumentNumber = value;
        },
        setTransferOrderListPagerOptions(state, value) {
            state.transferOrderListPagerOptions = value;
        },
        setCostDocumentTransferOrderListAmountSummaryList(state, value: SettlementAmountSummaryRestModel[]) {
            state.costDocumentTransferOrderListAmountSummaryList = value;
        },
        setTransferOrder(state, value: SettlementCostDocumentTransferOrderRestModel) {
            state.transferOrder = value;
        },
        setTransferOrderValidationError(state, value: any) {
            state.transferOrderValidationError = value;
        },
        setTransferOrderListValidationError(state, value: any) {
            state.transferOrderListValidationError = value;
        },
        setContractorSenderFound(state, value: ContractorSearchItemModel[]) {
            state.contractorSenderFound = value;
        },
        setContractorRecipientFound(state, value: ContractorSearchItemModel[]) {
            state.contractorRecipientFound = value;
        },
        setShowTransferOrderCancelDialog(state, value: boolean) {
            state.showTransferOrderCancelDialog = value;
        },
        setResolveTransferOrderCancelDialog(state, value: any) {
            state.resolveTransferOrderCancelDialog = value;
        },
        setTransferOrderCancelDialogComment(state, value: string) {
            state.transferOrderCancelDialogComment = value;
        }
    },
    actions: {
        loadTransferOrderList(context, payload: { requestModel: SettlementCostDocumentTransferOrderListRequestModel }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                commit.setTransferOrderListLoading(true);
                axiosInstance
                    .post("/api/settlementCostDocument/transfer-order/list", payload.requestModel)
                    .then((resp: AxiosResponse) => {
                        commit.setTransferOrderList(resp.data.items);
                        commit.setTransferOrderListTotalCount(resp.data.totalItemsCount)
                        commit.setCostDocumentTransferOrderListAmountSummaryList(resp.data.amountSummaryList)
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setTransferOrderListLoading(false);
                    })
            })
        },
        async loadTransferOrder(context, payload: { transferOrderId: number }) {
            const { commit } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                if (!isNaN(payload.transferOrderId)) {
                    axiosInstance
                        .get("/api/settlementCostDocument/transfer-order/" + payload.transferOrderId)
                        .then((resp: AxiosResponse) => {
                            commit.setTransferOrder(resp.data);
                            resolve(resp.data);
                        })
                        .catch((err: AxiosError) => reject(err))
                }
                else {
                    axiosInstance
                        .get("/api/settlementCostDocument/transfer-order/temporary")
                        .then((resp: AxiosResponse) => {
                            commit.setTransferOrder(resp.data)
                            resolve(resp.data);
                        })
                        .catch((err: AxiosError) => reject(err));
                }
            })
        },
        async saveTransferOrder(context) {
            const { state, commit } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/settlementCostDocument/transfer-order/save", state.transferOrder)
                    .then((resp: AxiosResponse) => {
                        commit.setTransferOrderValidationError({});
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => {
                        if (err.response && err.response.data.errors) {
                            commit.setTransferOrderValidationError(err.response.data.errors);
                            resolve(err.response.data.errors)
                        }
                        reject(err)
                    });
            })
        },
        async createTransferOrdersFromPaymentObligationList(context, payload: { selectedPaymentObligationList: SettlementCostDocumentPaymentObligationListItemRestModel[] }) {
            const { commit } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/settlementCostDocument/transfer-order/create-transfer-orders-from-payment-obligation-list", payload.selectedPaymentObligationList)
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => {
                        if (err.response && err.response.data.errors) {
                            resolve(err.response.data.errors)
                        }
                        reject(err)
                    });
            })
        },
        async createPackageFromTransferOrderList(context, payload: { transferOrderListForCreatingPackage: SettlementCostDocumentTransferOrderRestModel[], createPackageForOneContractor: boolean }) {
            const { commit } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/settlementCostDocument/transfer-order/create-package-from-transfer-order-list/" + payload.createPackageForOneContractor, payload.transferOrderListForCreatingPackage)
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err))
            })
        },
        async cancelTransferOrder(context) {
            const { state } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/settlementCostDocument/transfer-order/cancel", state.transferOrder)
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err))
            })
        },
        isConfirmedTransferOrderCancelDialog(context, payload: { isConfirmed: boolean }) {
            const { state, commit } = _moduleActionContext(context);
            state.resolveTransferOrderCancelDialog(true);
            commit.setShowTransferOrderCancelDialog(false);
        },

        isNotConfirmedTransferOrderCancelDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.resolveTransferOrderCancelDialog(false);
            commit.setShowTransferOrderCancelDialog(false);
        },
        openTransferOrderCancelDialog(context) {
            const { commit } = _moduleActionContext(context);
            commit.setShowTransferOrderCancelDialog(true);
            return new Promise((resolve, reject) => {
                commit.setResolveTransferOrderCancelDialog(resolve);
            });
        },
        async validateTransferOrderListForCreatingPackage(context, payload: { transferOrderListForCreatingPackage: SettlementCostDocumentTransferOrderRestModel[] }) {
            const { state, commit } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/settlementCostDocument/transfer-order/list-for-creating-package/validate", payload.transferOrderListForCreatingPackage)
                    .then((resp: AxiosResponse) => {
                        commit.setTransferOrderListValidationError({});
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => {
                        if (err.response && err.response.data.errors) {
                            commit.setTransferOrderListValidationError(err.response.data.errors);
                            resolve(err.response.data.errors)
                        }
                        reject(err)
                    });
            })
        },
    }
});

export default settlementCostDocumentTransferOrderModule;
const _moduleActionContext = (context: any) => moduleActionContext(context, settlementCostDocumentTransferOrderModule)