import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import ContractorOperationRestModel from "../../models/rest/contractor/contractorOperationRestModel";
import ListItemStringModel from "@/models/listItemStringModel";

@Component
export default class OperationList extends Vue {
    tagIsLoading = false;
    tagSearch = "";

    get tags(): ListItemStringModel[] {
        return this.$store.direct.state.operation.operationListTags;
    }

    set tags(val: ListItemStringModel[]) {
        this.$store.direct.commit.operation.setOperationListPagerTags(val);
    }

    get pagerOptions(): any {
        return this.$store.direct.state.operation.operationListPagerOptions;
    }

    set pagerOptions(val: any) {
        this.$store.direct.commit.operation.setOperationListPagerOptions(val);
    }

    get searchText(): string {
        return this.$store.direct.state.operation.operationListSearchText;
    }

    set searchText(val: string) {
        this.$store.direct.commit.operation.setOperationListSearchText(val);
    }

    get tagFound() {
        return this.$store.direct.state.contractor.operationTagSearch;
    }

    @Watch("tagSearch")
    tagSearchLoadItems(val: string) {
        this.$store.direct.dispatch.contractor.searchTags(val);
    }

    get operationList(): ContractorOperationRestModel[] {
        return this.$store.direct.state.operation.operationList;
    }

    get operationListLoading(): boolean {
        return this.$store.direct.state.operation.operationListLoading;
    }

    get operationListTotalCount(): number {
        return this.$store.direct.state.operation.operationListTotalCount;
    }

    get searchAccountManagerIdList(): any {
        return this.$store.direct.state.operation.operationListSearchAccountManagerIdList;
    }

    set searchAccountManagerIdList(value: any) {
        this.$store.direct.commit.operation.setOperationListSearchAccountManagerIdList(value);
    }

    get searchAssignedToUserIdList(): any {
        return this.$store.direct.state.operation.operationListSearchAssignedToUserIdList;
    }

    set searchAssignedToUserIdList(value: any) {
        this.$store.direct.commit.operation.setOperationListSearchAssignedToUserIdList(value);
    }

    get contractorAssets() {
        return this.$store.direct.state.contractor.contractorAssets;
    }

    get userListSorted(): any {
        return this.contractorAssets.crmUsers?.sort((prev, next) => (prev.text > next.text ? 1 : -1));
    }

    operationListHeaders = [
        {
            text: this.$t("contractor.priority"),
            align: "start",
            value: "priority"
        },
        {
            text: this.$t("contractor.ready"),
            align: "start",
            value: "isReady"
        },
        {
            text: this.$t("contractor.assignedToUserName"),
            align: "start",
            value: "assignedToUserName",
            sortable: false
        },
        {
            text: this.$t("contractor.description"),
            align: "start",
            value: "description",
            sortable: false
        },
        {
            text: this.$t("contractor.category"),
            align: "start",
            value: "categoryText",
            sortable: false
        },
        {
            text: this.$t("contractor.contractor"),
            align: "start",
            value: "contractorName"
        },
        {
            text: this.$t("contractor.contactPerson"),
            align: "start",
            value: "contactPerson"
        },
        { text: this.$t("contractor.nextContact"), align: "start", value: "reminderDate" },
        { text: this.$t("common.createdAt"), align: "start", value: "createdAt" },
        {
            text: this.$t("common.createdBy"),
            align: "start",
            value: "createdBy",
            sortable: false
        },
        { text: this.$t("common.editedAt"), align: "start", value: "editedAt" },
        {
            text: this.$t("contractor.actions"),
            align: "right",
            value: "actions",
            width: "1%",
            sortable: false
        }
    ];

    @Watch("pagerOptions", { deep: true })
    pagerOptionsHandles() {
        this.loadOperationList();
    }

    operationRowClick() {
        this.$router.push({ path: "/operation/list" });
    }

    searchClick() {
        this.loadOperationList();
    }

    async operationEditClick(operation: ContractorOperationRestModel) {
        await this.$store.direct.dispatch.contractor.loadContractor({ id: operation.contractorId.toString() });
        this.$store.direct.dispatch.contractor.loadOperation({ id: operation.id });
        this.$router.push({ path: `/contractor/edit/${operation.contractorId}` });
    }

    async loadOperationList() {
        await this.$store.direct.dispatch.operation.loadOperationList({
            searchText: this.searchText,
            searchTags: this.tags.map(currentValue => {
                return currentValue.text;
            }),
            page: this.pagerOptions.page,
            sortBy: this.pagerOptions.sortBy[0],
            sortDesc: this.pagerOptions.sortDesc[0],
            itemsPerPage: this.pagerOptions.itemsPerPage,
            searchAccountManagerIdList: this.searchAccountManagerIdList,
            searchAssignedToUserIdList: this.searchAssignedToUserIdList
        });
    }
}
