import { RouteConfig } from "vue-router";
import { Roles } from "../models/emums/roles";

const routes: Array<RouteConfig> = [
    {
        path: "/admin",
        component: require("@/layouts/layout.vue").default,
        children: [
            {
                path: "",
                name: "Admin page",
                component: require("@/components/admin/admin.vue").default,
                meta: {
                    roles: [Roles.Admin]
                }
            },
            {

                path: "user/list",
                name: "User list",
                component: require("@/components/admin/user/user-list.vue").default,
                meta: {
                    roles: [Roles.Admin]
                },
            },
            {
                path: "user/create",
                name: "User create",
                component: require("@/components/admin/user/user.vue").default,
                meta: {
                    roles: [Roles.Admin]
                }
            },
            {
                path: "user/edit/:id",
                name: "User edit",
                component: require("@/components/admin/user/user.vue").default,
                meta: {
                    roles: [Roles.Admin]
                }
            },
            {
                path: "register/person-role/list",
                name: "Person role list",
                component: require("@/components/admin/register/person-role/person-role-list.vue").default,
                meta: {
                    roles: [Roles.Admin]
                }
            },
            {
                path: "register/country/list",
                name: "Country list",
                component: require("@/components/admin/register/country/country-list.vue").default,
                meta: {
                    roles: [Roles.Admin]
                }
            },
            {
                path: "register/cost-type/list",
                name: "Cost type list",
                component: require("@/components/admin/register/cost-type/cost-type-list.vue").default,
                meta: {
                    roles: [Roles.Admin]
                }
            },
            {
                path: "register/currency/list",
                name: "Currency list",
                component: require("@/components/admin/register/currency/currency-list.vue").default,
                meta: {
                    roles: [Roles.Admin]
                }
            },
            {
                path: "register/supervisor-carrier-document-order-requirement/list",
                name: "Supervisor carrier document order requirement list",
                component: require("@/components/admin/register/supervisor-carrier-document-order-requirement/supervisor-carrier-document-order-requirement-list.vue").default,
                meta: {
                    roles: [Roles.Admin]
                }
            },
            {
                path: "register/safe-location-list",
                name: "Safe location list",
                component: require("@/components/admin/register/safe-location/safe-location-list.vue").default,
                meta: {
                    roles: [Roles.Admin]
                }
            },
            {
                path: "register/branch/list",
                name: "Branch list",
                component: require("@/components/admin/register/branch/branch-list.vue").default,
                meta: {
                    roles: [Roles.Admin]
                }
            },
            {
                path: "other",
                name: "Other",
                component: require("@/components/admin/other/other.vue").default,
                meta: {
                    roles: [Roles.Admin]
                }
            },
            {
                path: "register/tag/list",
                name: "TagList",
                component: require("@/components/admin/register/tag/tag-list.vue").default,
                meta: {
                    roles: [Roles.Admin]
                }
            }
        ]
    }
];

export default routes;
