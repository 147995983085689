import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import ContractorRestModel from "../../../models/rest/contractor/contractorRestModel";
import ListItemNumberModel from "@/models/listItemNumberModel";
import VForm from "@/models/v-form";
import ListItemStringModel from "../../../models/listItemStringModel";
import { stringMaxLength } from "../../../assets/validation/common";
import { Roles } from "../../../models/emums/roles";
import { ContractorTypes } from "../../../models/emums/contractorTypes";
import CountryRestModel from "../../../models/rest/register/countryRestModel";

@Component
export default class BasicTab extends Vue {
    $refs!: {
        form: VForm;
    };

    validation = {
        customInfoToSupervisor: [stringMaxLength(this.$i18n, this.$t("contractor.specialInfo"), 2048)],
        symbol: [
            stringMaxLength(this.$i18n, this.$t("contractor.symbol"), 60),
            (value: string) => !!value || this.$t("common.validationErrorRequired")
        ],
        name: [stringMaxLength(this.$i18n, this.$t("contractor.name"), 512)],
        contractorType: [(value: string[]) => (value as string[]).length > 0 || this.$t("common.validationErrorRequired")],
        comments: [stringMaxLength(this.$i18n, this.$t("contractor.comments"), 512)],
        specialInfo: [stringMaxLength(this.$i18n, this.$t("contractor.specialInfo"), 2048)],
        isLockedAsClientComment: [stringMaxLength(this.$i18n, this.$t("contractor.comments"), 256)],
        postalCode: [
            stringMaxLength(this.$i18n, this.$t("contractor.postalCode"), 10),
            (value: string) => {
                if (this.contractor.isPrivatePerson) {
                    return !!value || this.$t("common.validationErrorRequired");
                }
                return true;
            }

        ],
        streetAndNumber: [
            (value: string) => {
                if (this.contractor.isPrivatePerson) {
                    return !!value || this.$t("common.validationErrorRequired");
                }
                return true;
            }
        ],
        country: [
            (value: string) => {
                if (this.contractor.isPrivatePerson) {
                    return !!value || this.$t("common.validationErrorRequired");
                }
                return true;
            }
        ],
        city: [
            (value: string) => {
                if (this.contractor.isPrivatePerson) {
                    return !!value || this.$t("common.validationErrorRequired");
                }
                return true;
            }
        ],
    };

    get contractorTypeList(): ListItemStringModel[] {
        return this.$store.direct.state.contractor.contractorTypeList;
    }

    contractorDefaultLangForTransportOrders = [
        { text: "pl", value: "pl" },
        { text: "de", value: "de" },
        { text: "en", value: "en" },
    ]

    get contractorAssets() {
        return this.$store.direct.state.contractor.contractorAssets;
    }

    get branchList(): ListItemNumberModel[] {
        return this.contractorAssets.branches;
    }

    get departmentList(): ListItemNumberModel[] {
        return this.contractorAssets.departments;
    }

    get userListSorted(): any {
        return this.contractorAssets.crmUsers?.sort((prev, next) => (prev.text > next.text ? 1 : -1));
    }

    get contractor(): ContractorRestModel {

        return this.$store.direct.state.contractor.contractor;
    }

    get countryList(): CountryRestModel[] {
        return this.$store.direct.state.contractor.contractorAssets.countries;
    }

    get allowContractorEdit(): boolean {
        return this.$store.direct.state.contractor.allowContractorEdit;
    }

    get isContractorNew(): boolean {
        return this.$store.direct.state.contractor.isContractorNew;
    }

    get currentUserRoles(): string[] {
        return this.$store.direct.state.auth.userRoles;
    }

    get isAdmin(): boolean {
        return this.currentUserRoles.includes(Roles.Admin);
    }

    get isCrmAdmin(): boolean {
        return this.currentUserRoles.includes(Roles.CrmAdmin);
    }

    get isAccountantAdmin(): boolean {
        return this.currentUserRoles.includes(Roles.AccountantAdmin);
    }

    @Watch("contractor")
    onContractorChanged() {
        this.$nextTick(function () {
            this.$refs.form.resetValidation();
        });
    }

    @Watch("this.$store.direct.state.contractor.contractor")
    get contractorTypeSelectedList(): string[] {
        const result = [] as string[];
        const contractor = this.$store.direct.state.contractor.contractor;

        if (contractor.typeIsCarrier) result.push(ContractorTypes.Carrier);
        if (contractor.typeIsOther) result.push(ContractorTypes.Other);
        if (contractor.typeIsPartner) result.push(ContractorTypes.Partner);
        if (contractor.typeIsClient) result.push(ContractorTypes.Client);

        return result;
    }

    set contractorTypeSelectedList(value: string[]) {
        const contractor = this.$store.direct.state.contractor.contractor;

        contractor.typeIsCarrier = value.indexOf(ContractorTypes.Carrier) > -1;
        contractor.typeIsOther = value.indexOf(ContractorTypes.Other) > -1;
        contractor.typeIsPartner = value.indexOf(ContractorTypes.Partner) > -1;
        contractor.typeIsClient = value.indexOf(ContractorTypes.Client) > -1;
    }

    get locationIsSaved(): boolean {
        if (this.contractor.lat != null && this.contractor.lng != null) {
            return true;
        }
        return false;
    }

    fillContractorName(val: string) {
        if (this.isContractorNew == true) {
            this.contractor.name = val;
        }
    }

    validate(): number {
        let errorCount = 0;
        this.$refs.form.validate();
        this.$refs.form.inputs.forEach((input: any) => {
            if (input.validate() === false) ++errorCount;
        });
        return errorCount;
    }

    openLocationDialog() {
        this.$store.direct.dispatch.locationDialog
            .openDialog({
                showDialog: true,
                title: `${this.$t("contractor.setLocation")}`
            })
            .then(async confirm => {
                if (confirm) {
                    await this.$store.direct.dispatch.contractor.saveLocation();
                }
            });
    }

    generateKpiReportFile() {
        this.$store.direct.commit.contractor.setShowKpiGeneratingDialog(true);
    }
}
