import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import ConfirmDialog from "../../../layouts/confirmDialog/confirmDialog.vue";
import ListItemNumberModel from "../../../models/listItemNumberModel";
import SettlementClosedMonthRestModel from "../../../models/rest/settlement/settlementClosedMonthRestModel";
import ClosedMonthDialog from "./closed-month-dialog.vue";

@Component({
    components: {
        "closed-month-dialog": ClosedMonthDialog,
        "confirm-dialog": ConfirmDialog,
    }
})

export default class ClosedMonthList extends Vue {

    closedMonthListHeaders = [
        {
            text: "Id",
            align: "center",
            value: "id",
            width: "1%",
            sortable: true,
        },
        {
            text: this.$t("closedMonth.month"),
            align: "start",
            value: "monthAndYear",
            sortable: false,
        },
        {
            text: this.$t("contractor.branch"),
            align: "start",
            value: "branchId",
            sortable: false,
        },
        {
            text: this.$t("closedMonth.closed"),
            align: "center",
            value: "isClosed",
            sortable: false,
        },
        {
            text: this.$t("common.actions"),
            align: "right",
            value: "actions",
            width: "1%",
            sortable: false,
        }
    ];

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get closedMonthList(): SettlementClosedMonthRestModel[] {
        return this.$store.direct.state.settlementCommon.closedMonthList;
    }

    get closedMonthListLoading(): boolean {
        return this.$store.direct.state.settlementCommon.closedMonthListLoading;
    }

    get closedMonthListTotalCount(): number {
        return this.$store.direct.state.settlementCommon.closedMonthListTotalCount;
    }

    get pagerOptions(): any {
        return this.$store.direct.state.settlementCommon.closedMonthListPagerOptions;
    }

    set pagerOptions(value: any) {
        this.$store.direct.commit.settlementCommon.setClosedMonthListPagerOptions(value);
    }

    get footerPropsForSettlement(): any {
        return this.$store.direct.state.settlementCommon.footerPropsForSettlement;
    }

    get branchList(): ListItemNumberModel[] {
        const branches = this.$store.direct.state.contractor.contractorAssets.branches;
        if (branches == undefined) {
            this.$store.direct.dispatch.contractor.loadContractorAssets();
        }
        return branches;
    }

    @Watch("pagerOptions", { deep: true })
    pagerOptionsHandles() {
        this.loadClosedMonthList();
    }

    async created() {
        this.loadClosedMonthList();
    }

    formatBranchIdToBranchName(branchId: any) {
        if (this.branchList != undefined) {
            return this.branchList.find(x => x.value == branchId)?.text;
        }
    }

    searchClick() {
        this.loadClosedMonthList();
    }

    async createClosedMonthClick() {
        this.$store.direct.dispatch.settlementCommon
            .openClosedMonthDialog({
                title: `${this.$t("closedMonth.closedMonth")}`,
            })
            .then(async confirm => {
                if (confirm) {
                    this.loadClosedMonthList();
                }
            })
    }

    async editClosedMonth(item: SettlementClosedMonthRestModel) {
        await this.$store.direct.dispatch.settlementCommon.loadClosedMonth({ closedMonthId: item.id });

        this.$store.direct.dispatch.settlementCommon
            .openClosedMonthDialog({
                title: `${this.$t("closedMonth.closedMonth")}`,
            })
            .then(async confirm => {
                if (confirm) {
                    this.loadClosedMonthList();
                }
            })
    }

    async loadClosedMonthList() {
        await this.$store.direct.dispatch.settlementCommon.loadClosedMonthList({
            page: this.pagerOptions.page,
            itemsPerPage: this.pagerOptions.itemsPerPage,
        });
    }
}
