import dayjs from "dayjs";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import i18n from "../../../../assets/i18n";
import { BranchEnum } from "../../../../models/emums/branchEnum";
import ListItemNumberModel from "../../../../models/listItemNumberModel";

@Component
export default class RegisterExportDialog extends Vue {
    datesRange = [] as string[];
    branchId = BranchEnum.PL;
    showDatesError = false;
    registerExportDatesError = this.$t("registerExport.registerExportDatesError");

    rules = {
        dates: [
            (value: any[]) => !!value[0] || this.$t("common.validationErrorRequired"),
        ],
    };

    get showDialog(): boolean {
        return this.$store.direct.state.settlementCommon.showRegisterExportDialog;
    }

    get registerExportDialogTitle(): string {
        return this.$store.direct.state.settlementCommon.registerExportDialogTitle;
    }

    get registerExportDialogRegisterType(): string {
        return this.$store.direct.state.settlementCommon.registerExportDialogRegisterType;
    }

    get isLoading(): boolean {
        return this.$store.direct.state.settlementCommon.registerExportListLoading;
    }

    get branchList(): ListItemNumberModel[] {
        const branchList = this.$store.direct.state.contractor.contractorAssets.branches;
        let clonedBranchList = [] as ListItemNumberModel[];
        if (branchList == undefined) {
            this.$store.direct.dispatch.contractor.loadContractorAssets();
        }
        else {
            clonedBranchList = [...branchList];
            clonedBranchList.push({ value: 0, text: i18n.t("common.all") } as ListItemNumberModel);
        }
        return clonedBranchList;
    }

    get dateRangeText(): string {
        return this.datesRange.join(' ~ ')
    }

    async saveClick() {
        if (this.datesRange.length != 2) {
            this.registerExportDatesError = this.$t("registerExport.registerExportDatesError");
            this.showDatesError = true;
            setTimeout(() => {
                this.showDatesError = false;
            }, 3000);
        }
        else {
            const firstDateMonth = dayjs(this.datesRange[0]).toDate().getMonth() + 1;
            const secondDateMonth = dayjs(this.datesRange[1]).toDate().getMonth() + 1;

            if (firstDateMonth != secondDateMonth) {
                this.registerExportDatesError = this.$t("registerExport.registerExportDatesSameMonthsError");
                this.showDatesError = true;
                setTimeout(() => {
                    this.showDatesError = false;
                }, 3000);
            }
            else {
                await this.$store.direct.dispatch.settlementCommon.saveRegisterExport({ datesRange: this.datesRange, registerType: this.registerExportDialogRegisterType });
                this.$store.direct.dispatch.settlementCommon.isConfirmedRegisterExportDialog();
                this.datesRange = [];
            }
        }
    }

    cancelClick() {
        this.$store.direct.dispatch.settlementCommon.isNotConfirmedRegisterExportDialog();
        this.datesRange = [];
    }
}
