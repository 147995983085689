import { moduleActionContext } from "@/store/index";
import { AxiosError, AxiosResponse } from "axios";
import { defineModule } from "direct-vuex";
import i18n from "../../assets/i18n";
import ListItemNumberModel from "../../models/listItemNumberModel";
import ListItemStringModel from "../../models/listItemStringModel";
import CourierNetFreightRestModel from "../../models/rest/freight-exchange/courier-net/courierNetFreightRestModel";
import TransEuFreightRestModel from "../../models/rest/freight-exchange/transeu/transEuFreightRestModel";
import TokenRestModel from "../../models/rest/user/tokenRestModel";
import { axiosInstance } from "../../plugins/axios";

export interface FreightExchangeModuleStateInterface {
    isAuthToTransEu: boolean;
    showSendFreightToFreightExchangesDialog: boolean;
    sendFreightToFreightExchangesDialogResolve: any;
    transEuFreight: TransEuFreightRestModel,
    transEuVehicleBodyTypes: ListItemStringModel[],
    transEuVehicleSizeTypes: ListItemStringModel[],
    transEuRequiredWaysOfLoadingTypes: ListItemStringModel[],
    transEuOtherRequirementTypes: ListItemStringModel[],
    courierNetVehicleTypes: ListItemNumberModel[],
    courierNetPackageTypes: ListItemStringModel[],
    courierNetDangerousGoods: ListItemStringModel[],
    courierNetFreight: CourierNetFreightRestModel,
}

const supervisorModule = defineModule({
    namespaced: true,
    state: {
        transEuVehicleBodyTypes: [
            { text: i18n.t("freightExchange.curtainsider").toString(), value: "curtainsider" },
            { text: i18n.t("freightExchange.standardTent").toString(), value: "standard-tent" },
            { text: i18n.t("freightExchange.openBox").toString(), value: "open-box" },
            { text: i18n.t("freightExchange.box").toString(), value: "box" },
            { text: i18n.t("freightExchange.cooler").toString(), value: "cooler" },
            { text: i18n.t("freightExchange.meathanging").toString(), value: "meathanging" },
            { text: i18n.t("freightExchange.isotherm").toString(), value: "isotherm" },
            { text: i18n.t("freightExchange.tanker").toString(), value: "tanker" },
            { text: i18n.t("freightExchange.other").toString(), value: "other" },
        ] as ListItemStringModel[],
        transEuVehicleSizeTypes: [
            { text: i18n.t("freightExchange.bus").toString(), value: "bus" },
            { text: i18n.t("freightExchange.solo").toString(), value: "solo" },
            { text: i18n.t("freightExchange.lorry").toString(), value: "lorry" },
            { text: i18n.t("freightExchange.anySize").toString(), value: "any_size" },
        ] as ListItemStringModel[],
        transEuRequiredWaysOfLoadingTypes: [
            { text: i18n.t("freightExchange.side").toString(), value: "side" },
            { text: i18n.t("freightExchange.top").toString(), value: "top" },
            { text: i18n.t("freightExchange.back").toString(), value: "back" },
        ] as ListItemStringModel[],
        transEuOtherRequirementTypes: [
            { text: "HDS", value: "1_hds" },
            { text: "Winda", value: "3_lift" },
            { text: "Linka celna", value: "4_custom_rope" },
            { text: "Kosz paletowy", value: "5_pallet_basket" },
            { text: "Przedstawienie do oclenia", value: "6_goods_to_declare" },
            { text: "ADR", value: "7_adr" },
            { text: "Karnet TIR", value: "8_carnet_tir" },
            { text: "Karnet ATA", value: "9_carnet_ata" },
            { text: "Certyfikat XL", value: "10_xl_certificate" },
            { text: "Tablica A - odpady przemysłowe", value: "11_a-sign" },
            { text: "Podwójna podłoga", value: "12_doppel_decker" },
            { text: "Certyfikat GMP+", value: "13_gmp_certificate" },
            { text: "Maty antypoślizgowe", value: "14_non-slip_mats" },
            { text: "2 kierowców", value: "15_double_cast" },
            { text: "Multitemperatura", value: "16_multi_temperature" },
            { text: "Wysokość rampowa", value: "17_ramp_height" },
            { text: "Pasy transportowe", value: "18_transport_lines" },
        ] as ListItemStringModel[],
        courierNetVehicleTypes: [
            { text: "PKW / Kombi (Car)".toString(), value: 1 },
            { text: "Planenbus bis 3,5 t zGG (Curtain van max. 2.5 t gvw)".toString(), value: 13 },
            { text: "Caddy bis 2,5 t (Small van up to 2.5 t)".toString(), value: 15 },
            { text: "Transporter bis 3,5 t (Van up to 3.5 t)".toString(), value: 16 },
            { text: "LKW bis 7,5 t (Truck up to 7.5 t)".toString(), value: 17 },
            { text: "LKW über 7,5 t (Truck over 7.5 t)".toString(), value: 18 },
        ] as ListItemNumberModel[],
        courierNetPackageTypes: [
            { text: "Others (see comments)*", value: "SEE_REFERENCE" },
            { text: "Carton", value: "CARTON" },
            { text: "Case", value: "CASE" },
            { text: "Euro pallet", value: "EUROPALLET" },
            { text: "Grid box", value: "GRIDBOX" },
        ] as ListItemStringModel[],
        courierNetDangerousGoods: [
            { text: "no", value: "NO" },
            { text: "Requires labelling", value: "REQUIRE_LABEL" },
            { text: "Limited quantity", value: "LIMITED_QUANTITY" },
        ] as ListItemStringModel[],
        isAuthToTransEu: false,
        showSendFreightToFreightExchangesDialog: false,
        sendFreightToFreightExchangesDialogTitle: "",
        sendFreightToFreightExchangesDialogResolve: null,
        transEuFreight: {} as TransEuFreightRestModel,
        courierNetFreight: {} as CourierNetFreightRestModel,
    } as FreightExchangeModuleStateInterface,
    mutations: {
        setIsAuthToTransEu(state, value: boolean) {
            state.isAuthToTransEu = value;
        },
        setShowSendFreightToFreightExchangesDialog(state, value: boolean) {
            state.showSendFreightToFreightExchangesDialog = value;
        },
        setSendFreightToFreightExchangesDialogResolve(state, value: any) {
            state.sendFreightToFreightExchangesDialogResolve = value;
        },
        setTransEuFreight(state, value: TransEuFreightRestModel) {
            state.transEuFreight = value;
        },
        setCourierNetFreight(state, value: CourierNetFreightRestModel) {
            state.courierNetFreight = value;
        },
    },
    actions: {
        async sendFreightToFreightExchanges(context, payload: { sendFreightToTransEu: boolean, sendFreightToCourierNet: boolean }): Promise<boolean> {
            const { state, rootDispatch } = _moduleActionContext(context);

            let transEuAccessToken = await JSON.parse(localStorage.getItem("transEuAccessToken") || "{}");
            let courierNetAccessToken = await JSON.parse(localStorage.getItem("courierNetAccessToken") || "{}");

            if (Object.keys(transEuAccessToken).length == 0) {
                transEuAccessToken = null;
            }
            if (Object.keys(courierNetAccessToken).length == 0) {
                courierNetAccessToken = null;
            }

            return await new Promise((resolve, reject) => {
                const body = {
                    sendFreightToTransEu: payload.sendFreightToTransEu,
                    transeEuFreightData: state.transEuFreight,
                    transEuTokenData: { accessToken: transEuAccessToken } as TokenRestModel,
                    sendFreightToCourierNet: payload.sendFreightToCourierNet,
                    courierNetFreightData: state.courierNetFreight,
                    courierNetTokenData: { accessToken: courierNetAccessToken } as TokenRestModel
                };
                axiosInstance
                    .post("/api/freightExchange/send-freight-to-freight-exchanges/", body)
                    .then(async (resp: AxiosResponse) => {
                        if (resp.data.isSuccess == false) {
                            await rootDispatch.confirm.openConfirmDialog({
                                showConfirmDialog: true,
                                title: "Error during send freight",
                                message: resp.data.errorMessage,
                                links: [],
                                options: { buttonColor: "error", layoutColor: "error" },
                                buttonType: "ok"
                            });
                            return false;
                        }
                        else {
                            await rootDispatch.confirm.openConfirmDialog({
                                showConfirmDialog: true,
                                title: "Freight was sent",
                                message: resp.data.errorMessage,
                                links: [],
                                options: { buttonColor: "primary", layoutColor: "primary" },
                                buttonType: "ok"
                            });
                            resolve(resp.data);
                            return true;
                        }
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        async getTransEuAuthUrl(context) {
            return await new Promise((resolve, reject) => {
                const { commit } = _moduleActionContext(context);
                commit.setIsAuthToTransEu(false);
                axiosInstance
                    .post("/api/freightExchange/get-transeu-auth-url/")
                    .then(async (resp: AxiosResponse) => {
                        const url = resp.data;
                        window.open(url, '_blank');
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        async getTransEuToken(context, payload: { transEuAuthCode: string | (string | null)[], transEuAuthState: string | (string | null)[] }) {
            return await new Promise((resolve, reject) => {
                const params = {
                    code: payload.transEuAuthCode,
                    state: payload.transEuAuthState
                };
                axiosInstance
                    .get("/api/freightExchange/get-transeu-token", { params })
                    .then(async (resp: AxiosResponse) => {
                        await localStorage.setItem("transEuAccessToken", JSON.stringify(resp.data.currentObject.access_token));

                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        async checkIfUserIsLoggedToTransEu(context): Promise<string> {
            const { state } = _moduleActionContext(context);
            const transEuAccessToken = await JSON.parse(localStorage.getItem("transEuAccessToken") || "{}");

            if (Object.keys(transEuAccessToken).length == 0) {
                return "Unauthorized";
            }
            else {
                return await new Promise((resolve, reject) => {
                    const body = {
                        transEuTokenData: { accessToken: transEuAccessToken } as TokenRestModel
                    };
                    axiosInstance
                        .post("/api/freightExchange/check-if-user-is-logged-to-transeu/", body)
                        .then(async (resp: AxiosResponse) => {
                            resolve(resp.data);
                            return resp.data;
                        })
                        .catch((err: AxiosError) => reject(err));
                });
            }
        },
        async getCourierNetToken(context) {
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .get("/api/freightExchange/get-couriernet-token")
                    .then(async (resp: AxiosResponse) => {
                        await localStorage.setItem("courierNetAccessToken", JSON.stringify(resp.data.currentObject.authtoken));
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err));
            })
        },
        async checkIfUserIsLoggedToCourierNet(context): Promise<string> {
            const { state } = _moduleActionContext(context);
            const courierNetAccessToken = await JSON.parse(localStorage.getItem("courierNetAccessToken") || "{}");

            if (Object.keys(courierNetAccessToken).length == 0) {
                return "Unauthorized";
            }
            else {
                return await new Promise((resolve, reject) => {
                    const body = {
                        courierNetTokenData: { accessToken: courierNetAccessToken } as TokenRestModel
                    };
                    axiosInstance
                        .post("/api/freightExchange/check-if-user-is-logged-to-couriernet/", body)
                        .then(async (resp: AxiosResponse) => {
                            resolve(resp.data);
                            return resp.data;
                        })
                        .catch((err: AxiosError) => reject(err));
                });
            }
        },
        isConfirmedSendFreightToFreightExchangesDialog(context) {
            const { state, commit } = _moduleActionContext(context);
            state.sendFreightToFreightExchangesDialogResolve(true);
            commit.setShowSendFreightToFreightExchangesDialog(false);
        },

        isNotConfirmedSendFreightToFreightExchangesDialog(context) {
            const { commit } = _moduleActionContext(context);
            commit.setShowSendFreightToFreightExchangesDialog(false);
        },
        openSendFreightToFreightExchangesDialog(
            context,
            payload: {
                showDialog: boolean;
            }
        ) {
            const { commit } = _moduleActionContext(context);
            commit.setShowSendFreightToFreightExchangesDialog(payload.showDialog);
            return new Promise(resolve => {
                commit.setSendFreightToFreightExchangesDialogResolve(resolve);
            });
        }
    }
});

export default supervisorModule;
const _moduleActionContext = (context: any) => moduleActionContext(context, supervisorModule)