import { saveFile } from "@/assets/file-saver";
import { moduleActionContext } from "@/store/index";
import { AxiosError, AxiosResponse } from "axios";
import { defineModule } from "direct-vuex";
import ContractorSearchItemModel from "../../models/rest/contractor/contractorSearchItemModel";
import CurrencyRestModel from "../../models/rest/register/currencyRestModel";
import SettlementIncomeDocumentPaymentObligationListItemRestModel from "../../models/rest/settlement/income-document/payment-obligation/settlementIncomeDocumentPaymentObligationListItemRestModel";
import SettlementIncomeDocumentPaymentObligationListRequestModel from "../../models/rest/settlement/income-document/settlementIncomeDocumentPaymentObligationListRequestModel";
import SettlementAmountSummaryRestModel from "../../models/rest/settlement/settlementAmountSummaryRestModel";
import { axiosInstance } from "../../plugins/axios";

export interface SettlementIncomeDocumentPaymentObligationModuleStateInterface {
    paymentObligationList: SettlementIncomeDocumentPaymentObligationListItemRestModel[],
    paymentObligationListLoading: boolean;
    paymentObligationListTotalCount: number,
    paymentObligationListAmountSummaryList: SettlementAmountSummaryRestModel[],
    paymentObligationListSearchText: string,
    paymentObligationListSearchCurrency: CurrencyRestModel,
    paymentObligationListSearchContractors: ContractorSearchItemModel[],
    paymentObligationListSearchContractorIdcs: number[],
    paymentObligationListSearchPaymentTermDateFrom: string,
    paymentObligationListSearchPaymentTermDateTo: string,
    paymentObligationListSearchBranchId: number,
    paymentObligationListSearchDocumentStatusIdcs: number[],
    paymentObligationListSearchIssueDateFrom: string,
    paymentObligationListSearchIssueDateTo: string,
    paymentObligationListSearchSaleDateFrom: string,
    paymentObligationListSearchSaleDateTo: string,
    paymentObligationListSearchExpiredDaysSince: string,
    paymentObligationListSearchExpiredDaysTo: string
    paymentObligationListPagerOptions: any,
    selectedPaymentObligationList: SettlementIncomeDocumentPaymentObligationListItemRestModel[],
}

const settlementIncomeDocumentPaymentObligationModule = defineModule({
    namespaced: true,
    state: {
        paymentObligationList: [] as SettlementIncomeDocumentPaymentObligationListItemRestModel[],
        paymentObligationListLoading: false,
        paymentObligationListTotalCount: 0,
        paymentObligationListAmountSummaryList: [] as SettlementAmountSummaryRestModel[],
        paymentObligationListSearchText: "",
        paymentObligationListSearchCurrency: {} as CurrencyRestModel,
        paymentObligationListSearchContractors: [] as ContractorSearchItemModel[],
        paymentObligationListSearchContractorIdcs: [] as number[],
        paymentObligationListSearchPaymentTermDateFrom: "",
        paymentObligationListSearchPaymentTermDateTo: "",
        paymentObligationListSearchBranchId: 0,
        paymentObligationListSearchDocumentStatusIdcs: [] as number[],
        paymentObligationListSearchIssueDateFrom: "",
        paymentObligationListSearchIssueDateTo: "",
        paymentObligationListSearchSaleDateFrom: "",
        paymentObligationListSearchSaleDateTo: "",
        paymentObligationListSearchExpiredDaysSince: "",
        paymentObligationListSearchExpiredDaysTo: "",
        paymentObligationListPagerOptions: {
            page: 1,
            itemsPerPage: 30,
            sortBy: ["paymentTermDate"],
            sortDesc: [false]
        },
        selectedPaymentObligationList: [] as SettlementIncomeDocumentPaymentObligationListItemRestModel[],
    } as SettlementIncomeDocumentPaymentObligationModuleStateInterface,
    mutations: {
        setPaymentObligationList(state, value: SettlementIncomeDocumentPaymentObligationListItemRestModel[]) {
            state.paymentObligationList = value;
        },
        setPaymentObligationListLoading(state, value: boolean) {
            state.paymentObligationListLoading = value;
        },
        setPaymentObligationListTotalCount(state, value: number) {
            state.paymentObligationListTotalCount = value;
            if ((state.paymentObligationListPagerOptions.page * state.paymentObligationListPagerOptions.itemsPerPage) >= (value + state.paymentObligationListPagerOptions.itemsPerPage)) {
                state.paymentObligationListPagerOptions.page = 1;
            }
        },
        setPaymentObligationListtAmountSummaryList(state, value: SettlementAmountSummaryRestModel[]) {
            state.paymentObligationListAmountSummaryList = value;
        },
        setPaymentObligationListSearchText(state, value: string) {
            state.paymentObligationListSearchText = value;
        },
        setPaymentObligationListSearchCurrency(state, value: CurrencyRestModel) {
            state.paymentObligationListSearchCurrency = value;
        },
        setPaymentObligationListSearchContractors(state, value: ContractorSearchItemModel[]) {
            state.paymentObligationListSearchContractors = value;
        },
        setPaymentObligationListSearchContractorIdcs(state, value: number[]) {
            state.paymentObligationListSearchContractorIdcs = value;
        },
        setPaymentObligationListSearchPaymentTermDateFrom(state, value: string) {
            state.paymentObligationListSearchPaymentTermDateFrom = value;
        },
        setPaymentObligationListSearchPaymentTermDateTo(state, value: string) {
            state.paymentObligationListSearchPaymentTermDateTo = value;
        },
        setPaymentObligationListSearchBranchId(state, value: number) {
            state.paymentObligationListSearchBranchId = value;
        },
        setPaymentObligationListSearchDocumentStatusIdcs(state, value: number[]) {
            state.paymentObligationListSearchDocumentStatusIdcs = value;
        },
        setPaymentObligationListSearchIssueDateFrom(state, value: string) {
            state.paymentObligationListSearchIssueDateFrom = value;
        },
        setPaymentObligationListSearchIssueDateTo(state, value: string) {
            state.paymentObligationListSearchIssueDateTo = value;
        },
        setPaymentObligationListSearchSaleDateFrom(state, value: string) {
            state.paymentObligationListSearchSaleDateFrom = value;
        },
        setPaymentObligationListSearchSaleDateTo(state, value: string) {
            state.paymentObligationListSearchSaleDateTo = value;
        },
        setPaymentObligationListSearchExpiredDaysSince(state, value: string) {
            state.paymentObligationListSearchExpiredDaysSince = value;
        },
        setPaymentObligationListSearchExpiredDaysTo(state, value: string) {
            state.paymentObligationListSearchExpiredDaysTo = value;
        },
        setPaymentObligationListPagerOptions(state, value) {
            state.paymentObligationListPagerOptions = value;
        },
        setSelectedPaymentObligationList(state, value: SettlementIncomeDocumentPaymentObligationListItemRestModel[]) {
            state.selectedPaymentObligationList = value;
        },
    },
    actions: {
        loadPaymentObligationList(context, payload: { requestModel: SettlementIncomeDocumentPaymentObligationListRequestModel }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((resolve, reject) => {
                commit.setPaymentObligationListLoading(true);
                axiosInstance
                    .post("/api/settlementIncomeDocument/income-payment-obligation/list", payload.requestModel)
                    .then((resp: AxiosResponse) => {
                        commit.setPaymentObligationList(resp.data.items);
                        commit.setPaymentObligationListTotalCount(resp.data.totalItemsCount);
                        commit.setPaymentObligationListtAmountSummaryList(resp.data.amountSummaryList);
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setPaymentObligationListLoading(false);
                    })
            })
        },
        downloadXlsReportSettlementIncomeDocumentPaymentObligationList(context,
            payload: { requestModel: SettlementIncomeDocumentPaymentObligationListRequestModel }) {
            const { commit } = _moduleActionContext(context);
            return new Promise((reject) => {
                commit.setPaymentObligationListLoading(true);
                axiosInstance
                    .post("/api/settlementIncomeDocument/income-payment-obligation/export", payload.requestModel, {
                        responseType: "blob"
                    })
                    .then((resp: AxiosResponse) => {
                        saveFile(resp);
                    })
                    .catch((err: AxiosError) => reject(err))
                    .finally(() => {
                        commit.setPaymentObligationListLoading(false);
                    })
            });
        },
        async createPaymentsFromPaymentObligationList(context, payload: { selectedPaymentObligationList: SettlementIncomeDocumentPaymentObligationListItemRestModel[] }) {
            const { commit } = _moduleActionContext(context);
            return await new Promise((resolve, reject) => {
                axiosInstance
                    .post("/api/settlementIncomeDocument/income-payment-obligation/create-payments-from-payment-obligation-list", payload.selectedPaymentObligationList)
                    .then((resp: AxiosResponse) => {
                        resolve(resp.data);
                    })
                    .catch((err: AxiosError) => {
                        if (err.response && err.response.data.errors) {
                            resolve(err.response.data.errors)
                        }
                        reject(err)
                    });
            })
        },
    }
});

export default settlementIncomeDocumentPaymentObligationModule;
const _moduleActionContext = (context: any) => moduleActionContext(context, settlementIncomeDocumentPaymentObligationModule)