import { RouteConfig } from "vue-router";
import { Roles } from "../models/emums/roles";

const routes: Array<RouteConfig> = [
    {
        path: "/map",
        component: require("@/layouts/layout.vue").default,
        children: [
            {
                path: "",
                name: "Map",
                component: require("@/components/map/map.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.User, Roles.Disponent, Roles.DisponentAdmin]
                }
            }
        ]
    }
];

export default routes;
