import Vue from "vue";
import { Component } from "vue-property-decorator";
import VForm from "../../../../models/v-form";

@Component
export default class SettlementIncomeDocumentAttachmentDialog extends Vue {
    requiredRule = (value: string) => !!value || this.$t("common.validationErrorRequired");

    get attachmentDialogAttachments(): any[] {
        return this.$store.direct.state.settlementIncomeDocument.attachmentDialogAttachments;
    }

    set attachmentDialogAttachments(value: any[]) {
        this.$store.direct.commit.settlementIncomeDocument.setAttachmentDialogAttachments(value);
    }

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get showDialog(): boolean {
        return this.$store.direct.state.settlementIncomeDocument.showAttachmentDialog;
    }

    updated() {
        this.$nextTick(function () {
            if (this.$refs.form) (this.$refs.form as VForm).resetValidation();
        });
    }

    async saveClick(): Promise<void> {
        if ((this.$refs.form as VForm).validate()) {
            await this.$store.direct.dispatch.settlementIncomeDocument.isConfirmedAttachmentDialog();
            this.$store.direct.commit.settlementIncomeDocument.setAttachmentDialogAttachments(this.attachmentDialogAttachments);
        }
    }
    closeClick(): void {
        this.$store.direct.dispatch.settlementIncomeDocument.isNotConfirmedAttachmentDialog();
    }
}
