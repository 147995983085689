import Vue from "vue";
import { Component } from "vue-property-decorator";
import ConfirmDialog from "../../layouts/confirmDialog/confirmDialog.vue";
import VehicleRestModel from "../../models/rest/vehicle/vehicleRestModel";

@Component({
    props: {
        currentInfoWindow: {},
        totalDistance: {
            default: ""
        },
        totalTime: {
            default: ""
        }
    },
    components: {
        "confirm-dialog": ConfirmDialog
    }
})

export default class InfoWindow extends Vue {
    tab = "";
    pagerOptions = this.$store.direct.state.vehicle.yourVehiclesPagerOptions;
    departureDateLabel = this.$t("map.departureDate");
    currentInfoWindow!: any;
    avoidHighways: boolean = false;
    avoidTolls: boolean = false;
    isHold = false;
    waystations: any[] = [];
    isNew: boolean = true;
    previousVehicleId: number | undefined;
    timeout: number | undefined;
    addressSearchArray: string[] = [];

    validation = {
        freightForwarder: [
            (value: string) => !!value || this.$t("common.validationErrorRequired")
        ],
    };

    get addressFound() {
        if (this.$store.direct.state.common.addressFound == null) {
            return [];
        }
        return this.$store.direct.state.common.addressFound;
    }

    get vehicle(): VehicleRestModel {
        const vehicle = this.$store.direct.state.vehicle.vehicle;
        if ((this.isNew || this.previousVehicleId != vehicle.id) && Object.keys(vehicle).length != 0) {
            this.previousVehicleId = vehicle.id;
            this.waystations = [];
            this.waystations.push({ lat: vehicle.lat, lng: vehicle.lng, fullPlaceName: vehicle.city }, {});
            this.isNew = false;
        }
        return vehicle;
    }

    addressSearchLoadItems(index: number) {
        if (this.addressSearchArray[index] != null && this.addressSearchArray[index].length > 3) {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                this.$store.direct.dispatch.common.searchAddresses({ searchText: this.addressSearchArray[index] });
            }, 1000);
        }
    }

    updated() {
        this.isHold = this.vehicle.holdedBy == null ? false : true;
    }

    async loadYourVehicleList() {
        await this.$store.direct.dispatch.vehicle.loadYourVehicleList({
            searchText: "",
            page: this.pagerOptions.page,
            itemsPerPage: this.pagerOptions.itemsPerPage
        });
    }

    async changeSwitchState() {
        if (this.isHold) {
            await this.$store.direct.dispatch.vehicle.holdOn();
        }
        else {
            await this.$store.direct.dispatch.vehicle.holdOff();
        }
        await this.$store.direct.dispatch.vehicle.loadVehicle({ id: this.vehicle.id })
        this.$emit("holdOnOffClick", this.vehicle.id)
        this.showInfoDialog("Vehicle status changed")
    }

    showInfoDialog(msg: string) {
        this.$store.direct.dispatch.vehicle.showVehicleMapInfoDialog({ showDialog: true, message: msg })
        setTimeout(() => {
            this.$store.direct.commit.vehicle.setShowVehicleMapInfoDialog(false);
        }, 2000);
    }

    async unavailableClick() {
        this.$store.direct.dispatch.confirm.openConfirmDialog({
            showConfirmDialog: true,
            title: `${this.$t("map.unavailable")}: "${this.vehicle.registrationNumber}"`,
            message: `${this.$t("map.unavailableMessage")}`,
            links: [],
            options: { buttonColor: "primary", layoutColor: "primary" },
            buttonType: 'yes/no'
        })
            .then(async confirm => {
                if (confirm) {
                    await this.$store.direct.dispatch.vehicle.removeNotify({ id: this.vehicle.id });
                    await this.$store.direct.dispatch.vehicle.loadVehicle({ id: this.vehicle.id })

                    this.showInfoDialog("Action 'UNAVAILABLE' was successful")
                    this.$emit("unavailableClick", this.vehicle.id)
                }
            })
    }

    addDestinationClick() {
        this.waystations.push({});
        this.addressSearchArray.push("");
    }

    getDirectionClick() {
        this.$emit("getDirectionClick", this.waystations, this.avoidHighways, this.avoidTolls)
    }

    removeDestination(index: number) {
        this.waystations.splice(index, 1);
        this.addressSearchArray.splice(index, 1);
    }

    async editClick() {
        await this.loadYourVehicleList();
        await this.$router.push({ path: "/vehicle/your-vehicles" })
        await this.$store.direct.dispatch.vehicle.loadVehicle({ id: this.vehicle.id });
        this.$store.direct.dispatch.vehicle
            .openVehicleDialog({
                showDialog: true,
                title: `${this.$t("vehicle.editVehicle")}`
            })
            .then(async confirm => {
                if (confirm) {
                    await this.$store.direct.dispatch.vehicle.saveVehicle();
                }
                await this.loadYourVehicleList();
            });
    }

    saveClick() {
        const valid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
        if (valid) {
            this.$store.direct.dispatch.vehicle.saveVehicle();
            this.showInfoDialog("Data has been saved");
        }
    }

    closeInfoWindowClick() {
        this.currentInfoWindow.close();
    }
}