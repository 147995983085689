import { RouteConfig } from "vue-router";

const routes: Array<RouteConfig> = [
  {
    path: "/login",
    component: require("@/layouts/layout.vue").default,
    children: [
      {
        path: "",
        name: "Login",
        component: require("@/components/login/login.vue").default
      }
    ]
  }
];

export default routes;
