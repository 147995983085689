import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import i18n from "../../../assets/i18n";
import ConfirmDialog from "../../../layouts/confirmDialog/confirmDialog.vue";
import { BranchEnum } from "../../../models/emums/branchEnum";
import ListItemNumberModel from "../../../models/listItemNumberModel";
import ContractorSearchItemModel from "../../../models/rest/contractor/contractorSearchItemModel";
import CurrencyRestModel from "../../../models/rest/register/currencyRestModel";
import SettlementCostDocumentTransferOrderListRequestModel from "../../../models/rest/settlement/cost-document/transfer-order/settlementCostDocumentTransferOrderListRequestModel";
import SettlementCostDocumentTransferOrderRestModel from "../../../models/rest/settlement/cost-document/transfer-order/settlementCostDocumentTransferOrderRestModel";
import SettlementAmountSummaryRestModel from "../../../models/rest/settlement/settlementAmountSummaryRestModel";
import SettlementAmountSummaryRow from "../settlement-amount-summary-row.vue";
import CancelDialog from "./dialog/cancel-dialog.vue";

@Component({
    components: {
        "cancel-dialog": CancelDialog,
        "confirm-dialog": ConfirmDialog,
        "settlement-amount-summary-row": SettlementAmountSummaryRow,
    }
})


export default class CostDocumentTransferOrderList extends Vue {
    packageCreatedAtFromDateLabel = this.$t("costDocument.packageCreatedAtFrom");
    packageCreatedAtToDateLabel = this.$t("costDocument.packageCreatedAtTo");
    transferCreatedAtFromDateLabel = this.$t("costDocument.transferCreatedAtFrom");
    transferCreatedAtToDateLabel = this.$t("costDocument.transferCreatedAtTo");
    searchPackageCreatedAtFromDate = "";
    searchPackageCreatedAtToDate = "";
    searchTransferCreatedAtFromDate = "";
    searchTransferCreatedAtToDate = "";
    createPackageForOneContractor = false;
    contractorSearch = "";
    transferOrderListForCreatingPackage = [] as SettlementCostDocumentTransferOrderRestModel[];
    transferOrderListForCreatingPackageBranchId = 0;
    transferOrderListForCreatingPackageCurrency = "";
    transferOrderListForCreatingPackageSenderBankAccountNumber = "";
    transferOrderListForCreatingPackageSenderId = 0;
    transferOrderListForCreatingPackageRecipientBankAccountNumber = "";
    transferOrderListForCreatingPackageRecipientId = 0;
    transferOrderListForCreatingPackageError = null as string | null;
    mainCheckboxIsSelected = false;
    showAmountSummaryRowForCalculatedSelectedList = false;

    transferOrderListHeaders = [
        {
            align: "start",
            value: "checkbox",
            width: "1%",
            sortable: false,
        },
        {
            text: "Id",
            align: "center",
            value: "id",
            width: "1%",
            sortable: true,
        },
        {
            text: this.$t("contractor.branch"),
            align: "start",
            value: "branchId",
            sortable: true,
        },
        {
            text: this.$t("transferOrder.number"),
            align: "start",
            value: "transferNumber",
            sortable: true,
        },
        {
            text: this.$t("transferOrder.date"),
            align: "start",
            value: "transferDate",
            sortable: true,
        },
        {
            text: this.$t("transferOrder.isForeign"),
            align: "center",
            value: "isForeignTransfer",
            sortable: true,
        },
        {
            text: this.$t("transferOrder.senderBankAccountNumber"),
            align: "start",
            value: "senderBankAccountNumber",
            sortable: true,
        },
        {
            text: this.$t("transferOrder.recipientName"),
            align: "start",
            value: "recipientName",
            sortable: true,
        },
        {
            text: this.$t("transferOrder.recipientBankAccountNumber"),
            align: "start",
            value: "recipientBankAccountNumber",
            sortable: true,
        },
        {
            text: this.$t("transferOrder.amount"),
            align: "end",
            value: "transferAmount",
            sortable: true,
        },
        {
            text: this.$t("transferOrder.currency"),
            align: "start",
            value: "transferCurrencyCode",
            sortable: true,
        },
        {
            text: this.$t("transferOrder.transferTitle"),
            align: "start",
            value: "transferTitle",
            sortable: true,
        },
        {
            text: this.$t("transferOrder.packageDate"),
            align: "start",
            value: "packageDate",
            sortable: true,
        },
        {
            text: this.$t("transferOrder.packageNumber"),
            align: "start",
            value: "packageNumber",
            sortable: true,
        },
        {
            text: this.$t("transferOrder.user"),
            align: "start",
            value: "createdByUserName",
            sortable: false,
        },
        {
            text: this.$t("common.actions"),
            align: "right",
            value: "actions",
            width: "1%",
            sortable: false,
        }
    ];

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get transferOrderListLoading(): boolean {
        return this.$store.direct.state.settlementCostDocumentTransferOrder.transferOrderListLoading;
    }

    get transferOrderList(): SettlementCostDocumentTransferOrderRestModel[] {
        return this.$store.direct.state.settlementCostDocumentTransferOrder.transferOrderList;
    }

    get transferOrderListTotalCount(): number {
        return this.$store.direct.state.settlementCostDocumentTransferOrder.transferOrderListTotalCount;
    }

    get searchBranchId(): number {
        return this.$store.direct.state.settlementCostDocumentTransferOrder.transferOrderListSearchBranchId;
    }

    set searchBranchId(value: number) {
        this.$store.direct.commit.settlementCostDocumentTransferOrder.setTransferOrderListSearchBranchId(value);
    }

    get searchContractors(): ContractorSearchItemModel[] {
        return this.$store.direct.state.settlementCostDocumentTransferOrder.transferOrderListSearchContractors;
    }

    set searchContractors(value: ContractorSearchItemModel[]) {
        this.$store.direct.commit.settlementCostDocumentTransferOrder.setTransferOrderListSearchContractors(value);
    }

    get searchContractorIdcs(): number[] {
        return this.$store.direct.state.settlementCostDocumentTransferOrder.transferOrderListSearchContractorIdcs;
    }

    set searchContractorIdcs(value: number[]) {
        this.$store.direct.commit.settlementCostDocumentTransferOrder.setTransferOrderListSearchContractorIdcs(value);
    }

    get selectedCurrency(): CurrencyRestModel {
        return this.$store.direct.state.settlementCostDocumentTransferOrder.transferOrderListSelectedCurrency;
    }

    set selectedCurrency(value: CurrencyRestModel) {
        this.$store.direct.commit.settlementCostDocumentTransferOrder.setTransferOrderListSelectedCurrency(value);
    }

    get searchPackageNumber(): string {
        return this.$store.direct.state.settlementCostDocumentTransferOrder.transferOrderListSearchPackageNumber;
    }

    set searchPackageNumber(value: string) {
        this.$store.direct.commit.settlementCostDocumentTransferOrder.setTransferOrderListSearchPackageNumber(value);
    }

    get searchTransferNumber(): string {
        return this.$store.direct.state.settlementCostDocumentTransferOrder.transferOrderListSearchTransferNumber;
    }

    set searchTransferNumber(value: string) {
        this.$store.direct.commit.settlementCostDocumentTransferOrder.setTransferOrderListSearchTransferNumber(value);
    }

    get searchCostDocumentNumber(): string {
        return this.$store.direct.state.settlementCostDocumentTransferOrder.transferOrderListSearchCostDocumentNumber;
    }

    set searchCostDocumentNumber(value: string) {
        this.$store.direct.commit.settlementCostDocumentTransferOrder.setTransferOrderListSearchCostDocumentNumber(value);
    }

    get pagerOptions(): any {
        return this.$store.direct.state.settlementCostDocumentTransferOrder.transferOrderListPagerOptions;
    }

    set pagerOptions(value: any) {
        this.$store.direct.commit.settlementCostDocumentTransferOrder.setTransferOrderListPagerOptions(value);
    }

    get costDocumentTransferOrderListAmountSummaryList(): SettlementAmountSummaryRestModel[] {
        let amountSummary = this.$store.direct.state.settlementCostDocumentTransferOrder.costDocumentTransferOrderListAmountSummaryList;

        if (amountSummary == undefined) {
            amountSummary = [] as SettlementAmountSummaryRestModel[];
        }
        return amountSummary;
    }

    get footerPropsForSettlement(): any {
        return this.$store.direct.state.settlementCommon.footerPropsForSettlement;
    }

    get branches(): ListItemNumberModel[] {
        const branches = this.$store.direct.state.contractor.contractorAssets.branches;
        if (branches == undefined) {
            this.$store.direct.dispatch.contractor.loadContractorAssets();
        }
        return branches;
    }

    get branchList(): ListItemNumberModel[] {
        let clonedBranchList = [] as ListItemNumberModel[];
        if (this.branches == undefined) {
            this.$store.direct.dispatch.contractor.loadContractorAssets();
        }
        else {
            clonedBranchList = [...this.branches];
            clonedBranchList.push({ value: 0, text: i18n.t("common.all") } as ListItemNumberModel);
        }
        return clonedBranchList;
    }

    get currencies(): CurrencyRestModel[] {
        const currencies = this.$store.direct.state.contractor.contractorAssets.currencies;
        if (currencies == undefined) {
            this.$store.direct.dispatch.contractor.loadContractorAssets();
        }
        else {
            currencies.push({ code: i18n.t("common.all"), name: "" } as CurrencyRestModel)
        }

        return currencies;
    }

    get validationError(): any {
        return this.$store.direct.state.settlementCostDocumentTransferOrder.transferOrderListValidationError;
    }

    set validationError(val: any) {
        this.$store.direct.commit.settlementCostDocumentTransferOrder.setTransferOrderListValidationError(val);
    }

    get contractorFound(): ContractorSearchItemModel[] {
        return this.$store.direct.state.common.contractorSearch;
    }

    get allowSelectionOfAllItemsOnPageWithCheckbox(): boolean {
        const allowSelection = this.$store.direct.state.settlementCommon.allowSelectionOfAllItemsOnPageWithCheckbox;
        if (!allowSelection) {
            this.mainCheckboxIsSelected = false;
            this.transferOrderListForCreatingPackage = [] as SettlementCostDocumentTransferOrderRestModel[];
            this.transferOrderList.forEach((x: SettlementCostDocumentTransferOrderRestModel) => {
                x.isCheckedForCreatingPackage = false;
            });
            this.calculatedSelectedListForAmountSummaryRow = [] as SettlementAmountSummaryRestModel[];
        }
        return allowSelection;
    }

    set allowSelectionOfAllItemsOnPageWithCheckbox(val: boolean) {
        this.$store.direct.commit.settlementCommon.setAllowSelectionOfAllItemsOnPageWithCheckbox(val);
    }

    get calculatedSelectedListForAmountSummaryRow(): SettlementAmountSummaryRestModel[] {
        return this.$store.direct.state.settlementCommon.calculatedSelectedListForAmountSummaryRow;
    }

    set calculatedSelectedListForAmountSummaryRow(val: SettlementAmountSummaryRestModel[]) {
        this.$store.direct.commit.settlementCommon.setCalculatedSelectedListForAmountSummaryRow(val);
    }

    @Watch("pagerOptions", { deep: true })
    pagerOptionsHandles() {
        this.loadTransferOrderList();
    }

    @Watch("createPackageForOneContractor")
    createPackageForOneContractorHandles() {
        this.transferOrderList.forEach((x: SettlementCostDocumentTransferOrderRestModel) => {
            x.isCheckedForCreatingPackage = false;
        });
        this.$forceUpdate();

        this.transferOrderListForCreatingPackage = [] as SettlementCostDocumentTransferOrderRestModel[];
        this.$store.direct.dispatch.settlementCommon.calculateSelectedCostTransferOrderListForAmountSummaryRow({ list: this.transferOrderListForCreatingPackage });
    }

    @Watch("validationError.TransferOrderListForCreatingPackage")
    async validationErrorIncomeDocumentItemsHandler(val: any) {
        if (this.validationError.TransferOrderListForCreatingPackage == undefined) {
            this.transferOrderListForCreatingPackageError = null;
        }
        else {
            this.transferOrderListForCreatingPackageError = this.validationError.TransferOrderListForCreatingPackage[0];
            setTimeout(() => {
                this.transferOrderListForCreatingPackageError = null;
            }, 3000)
        }
    }

    @Watch("transferOrderListForCreatingPackage", { deep: true })
    transferOrderListForCreatingPackageHandler() {
        if (this.createPackageForOneContractor == true) {
            const transferTitleJoinedString = this.transferOrderListForCreatingPackage.map(x => x.transferTitle).join(", ");

            if (transferTitleJoinedString.length > 140) {
                this.$store.direct.dispatch.confirm
                    .openConfirmDialog({
                        showConfirmDialog: true,
                        title: this.$t("transferOrder.titleOfTransferCannotHaveMoreThan140Characters").toString(),
                        message: this.$t("transferOrder.titleOfTransferCannotHaveMoreThan140Characters").toString(),
                        links: [],
                        options: { buttonColor: "error", layoutColor: "error" },
                        buttonType: "ok"
                    })
            }
        }
    }

    @Watch("contractorSearch")
    async contractorSearchLoadItems(val: string) {
        await this.$store.direct.dispatch.common.searchContractors({ searchText: val, contractorTypes: [], contractorPropertiesEnabled: [], source: "" });
        if (this.searchContractors.length > 0) {
            this.searchContractors.forEach((x: any) => {
                if (!this.contractorFound.includes(x)) {
                    this.contractorFound.push(x);
                }
            })
        }
    }

    async created() {
        this.$store.direct.dispatch.contractor.loadContractorAssets();
        this.$store.direct.dispatch.settlementCommon.loadSettlementAssets();
        this.loadTransferOrderList();
    }

    setCheckboxAsReadonly(item: SettlementCostDocumentTransferOrderRestModel): boolean {
        let result = false;
        if ((this.transferOrderListForCreatingPackageCurrency.length != 0
            && this.transferOrderListForCreatingPackageBranchId != 0
            && (this.transferOrderListForCreatingPackageCurrency != item.transferCurrencyCode
                || this.transferOrderListForCreatingPackageBranchId != item.branchId
                || this.transferOrderListForCreatingPackageSenderBankAccountNumber != item.senderBankAccountNumber
                || this.transferOrderListForCreatingPackageSenderId != item.senderId
            ))
            && !this.allowSelectionOfAllItemsOnPageWithCheckbox) {
            result = true;
        }

        if (this.transferOrderListForCreatingPackageRecipientBankAccountNumber != "" && this.transferOrderListForCreatingPackageRecipientId != 0 && (this.transferOrderListForCreatingPackageRecipientBankAccountNumber != item.recipientBankAccountNumber || this.transferOrderListForCreatingPackageRecipientId != item.recipientId) && this.createPackageForOneContractor == true) {
            result = true;
        }

        return result;
    }

    setCheckboxAsReadonlyDueToNegativeTransferAmount(item: SettlementCostDocumentTransferOrderRestModel): boolean {
        const result = (!this.createPackageForOneContractor && item.transferAmount < 0)
        return result;
    }

    setCreatePackageButtonAsReadonlyDueToNegativeTransferAmount(): boolean {
        const totalAmount = this.transferOrderListForCreatingPackage.reduce((sum, current) => sum + current.transferAmount, 0);
        return totalAmount <= 0;
    }

    itemRowTextColor(item: SettlementCostDocumentTransferOrderRestModel) {
        let style = ""
        item.isCancelled ? style += "text-decoration-line-through " : ""
        item.branchId == BranchEnum.DE ? style += "grey--text " : "";
        return style;
    }

    searchClick() {
        this.loadTransferOrderList();
    }

    formatBranchIdToBranchName(branchId: any) {
        return branchId == BranchEnum.DE ? "DE" : "PL";
    }

    formatSenderBankAccountNumber(senderBankAccountNumber: any) {
        if (senderBankAccountNumber != undefined) {
            const firstChars = senderBankAccountNumber.substring(0, 4);
            const lastChars = senderBankAccountNumber.substr(senderBankAccountNumber.length - 4);
            return firstChars + "..." + lastChars;
        }
    }

    clearSearchContractorClick() {
        this.searchContractorIdcs = [] as number[];
        this.loadTransferOrderList();
    }

    clearSearchPackageCreatedAtFromDate() {
        this.searchPackageCreatedAtFromDate = "";
        this.loadTransferOrderList();
    }
    
    clearSearchPackageCreatedAtToDate() {
        this.searchPackageCreatedAtToDate = "";
        this.loadTransferOrderList();
    }

    clearSearchTransferPackageCreatedAtFromDate() {
        this.searchTransferCreatedAtFromDate = "";
        this.loadTransferOrderList();
    }

    clearSearchTransferPackageCreatedAtToDate() {
        this.searchTransferCreatedAtToDate = "";
        this.loadTransferOrderList();
    }

    clearDateTimePicker(clearableValue: string) {
        if (clearableValue == "searchPackageCreatedAtFromDate") {
            this.searchPackageCreatedAtFromDate = "";
        }
        if (clearableValue == "searchPackageCreatedAtToDate") {
            this.searchPackageCreatedAtToDate = "";
        }
        if (clearableValue == "searchTransferCreatedAtFromDate") {

            this.searchTransferCreatedAtFromDate = "";
        }
        if (clearableValue == "searchTransferCreatedAtToDate") {

            this.searchTransferCreatedAtToDate = "";
        }
        this.loadTransferOrderList();
    }

    clearAllFilters() {
        this.searchCostDocumentNumber = "";
        this.searchTransferNumber = "";
        this.searchPackageNumber = "";
        this.searchPackageCreatedAtFromDate = "";
        this.searchPackageCreatedAtToDate = "";
        this.searchTransferCreatedAtFromDate = "";
        this.searchTransferCreatedAtToDate = "";
        this.searchBranchId = 0;
        this.selectedCurrency = {} as CurrencyRestModel;
        this.searchContractorIdcs = [] as number[];
        this.searchContractors = [] as ContractorSearchItemModel[];

        this.loadTransferOrderList();
    }

    addTransferOrderForCreatePackageList(item: SettlementCostDocumentTransferOrderRestModel) {
        this.transferOrderListForCreatingPackage = this.transferOrderList.filter((x: SettlementCostDocumentTransferOrderRestModel) => {
            if (x.isCheckedForCreatingPackage) {
                return x.id;
            }
        })
        if (this.transferOrderListForCreatingPackage.length == 0) {
            this.transferOrderListForCreatingPackageBranchId = 0;
            this.transferOrderListForCreatingPackageCurrency = "";
            this.transferOrderListForCreatingPackageSenderBankAccountNumber = "";
            this.transferOrderListForCreatingPackageSenderId = 0;
            this.transferOrderListForCreatingPackageRecipientBankAccountNumber = "";
            this.transferOrderListForCreatingPackageRecipientId = 0;
        }
        else {
            this.transferOrderListForCreatingPackageBranchId = this.transferOrderListForCreatingPackage[0].branchId;
            this.transferOrderListForCreatingPackageCurrency = this.transferOrderListForCreatingPackage[0].transferCurrencyCode;
            this.transferOrderListForCreatingPackageSenderBankAccountNumber = this.transferOrderListForCreatingPackage[0].senderBankAccountNumber;
            this.transferOrderListForCreatingPackageSenderId = this.transferOrderListForCreatingPackage[0].senderId;
            this.transferOrderListForCreatingPackageRecipientBankAccountNumber = this.transferOrderListForCreatingPackage[0].recipientBankAccountNumber;
            this.transferOrderListForCreatingPackageRecipientId = this.transferOrderListForCreatingPackage[0].recipientId;
        }

        this.$store.direct.dispatch.settlementCommon.calculateSelectedCostTransferOrderListForAmountSummaryRow({ list: this.transferOrderListForCreatingPackage });
    }

    async createPackageFromTransferOrderList() {
        if (this.createPackageForOneContractor) {
            const transferTitleJoinedString = this.transferOrderListForCreatingPackage.map(x => x.transferTitle).join(", ");

            if (transferTitleJoinedString.length < 140) {
                await this.$store.direct.dispatch.settlementCostDocumentTransferOrder.validateTransferOrderListForCreatingPackage({ transferOrderListForCreatingPackage: this.transferOrderListForCreatingPackage });

                if (Object.keys(this.validationError).length == 0) {

                    await this.$store.direct.dispatch.settlementCostDocumentTransferOrder.createPackageFromTransferOrderList({ transferOrderListForCreatingPackage: this.transferOrderListForCreatingPackage, createPackageForOneContractor: this.createPackageForOneContractor });

                    this.transferOrderListForCreatingPackage = [];

                    this.loadTransferOrderList();
                }
            }
            else {
                this.$store.direct.dispatch.confirm
                    .openConfirmDialog({
                        showConfirmDialog: true,
                        title: this.$t("transferOrder.titleOfTransferCannotHaveMoreThan140Characters").toString(),
                        message: this.$t("transferOrder.titleOfTransferCannotHaveMoreThan140Characters").toString(),
                        links: [],
                        options: { buttonColor: "error", layoutColor: "error" },
                        buttonType: "ok"
                    })
            }
        }
        else {
            await this.$store.direct.dispatch.settlementCostDocumentTransferOrder.createPackageFromTransferOrderList({ transferOrderListForCreatingPackage: this.transferOrderListForCreatingPackage, createPackageForOneContractor: this.createPackageForOneContractor });

            this.transferOrderListForCreatingPackage = [] as SettlementCostDocumentTransferOrderRestModel[];

            this.loadTransferOrderList();
        }
    }

    async createTransferOrderClick() {
        this.$router.push({ path: "/settlement/transfer-order/create" });
    }

    editTransferOrderClick(transferId: number) {
        return "/settlement/transfer-order/edit/" + transferId;
    }

    async cancelTransferOrderClick(transferOrder: SettlementCostDocumentTransferOrderRestModel) {
        await this.$store.direct.dispatch.settlementCostDocumentTransferOrder.loadTransferOrder({ transferOrderId: transferOrder.id });
        this.$store.direct.dispatch.settlementCostDocumentTransferOrder
            .openTransferOrderCancelDialog()
            .then(async confirm => {
                if (confirm) {
                    await this.$store.direct.dispatch.settlementCostDocumentTransferOrder.cancelTransferOrder();
                    this.loadTransferOrderList();
                }
            });
    }

    async loadTransferOrderList() {
        if (!this.selectedCurrency.name) {
            this.selectedCurrency = {} as CurrencyRestModel;
        }

        await this.$store.direct.dispatch.settlementCostDocumentTransferOrder.loadTransferOrderList({
            requestModel: this.prepareCostDocumentTransferOrderRequestModel()
        });
    }

    prepareCostDocumentTransferOrderRequestModel(): SettlementCostDocumentTransferOrderListRequestModel {
        return {
            page: this.pagerOptions.page,
            pageSize: this.pagerOptions.itemsPerPage,
            sortBy: this.pagerOptions.sortBy[0],
            sortDesc: this.pagerOptions.sortDesc[0],
            searchTransferNumber: this.searchTransferNumber,
            searchCostDocumentNumber: this.searchCostDocumentNumber,
            searchPackageNumber: this.searchPackageNumber,
            searchBranchId: this.searchBranchId,
            searchPackageCreatedAtFromDate: this.searchPackageCreatedAtFromDate,
            searchPackageCreatedAtToDate: this.searchPackageCreatedAtToDate,
            searchTransferCreatedAtFromDate: this.searchTransferCreatedAtFromDate,
            searchTransferCreatedAtToDate: this.searchTransferCreatedAtToDate,
            searchCurrencyCode: this.selectedCurrency.code,
            searchContractorIdcs: (this.searchContractors != undefined && this.searchContractors.length > 0) ? this.searchContractors.map(x => x.value) : [],
        } as SettlementCostDocumentTransferOrderListRequestModel;
    }

    selectAllItemsOnThePage() {
        this.transferOrderList.forEach((x: SettlementCostDocumentTransferOrderRestModel) => {
            if (this.allowSelectionOfAllItemsOnPageWithCheckbox) {
                x.isCheckedForCreatingPackage = this.mainCheckboxIsSelected;
            }
        });
        this.$forceUpdate();

        this.transferOrderListForCreatingPackage = this.transferOrderList.filter((x: SettlementCostDocumentTransferOrderRestModel) => {
            if (x.isCheckedForCreatingPackage) {
                return x.id;
            }
        });

        this.$store.direct.dispatch.settlementCommon.calculateSelectedCostTransferOrderListForAmountSummaryRow({ list: this.transferOrderListForCreatingPackage });
    }

    currentPageInput = this.pagerOptions.page;
    goToPage() {
        if (this.currentPageInput > 0) {
            this.pagerOptions.page = this.currentPageInput;
        }
    }

    destroyed() {
        this.transferOrderListForCreatingPackage = [] as SettlementCostDocumentTransferOrderRestModel[];
    }
}
