import { RouteConfig } from "vue-router";
import { Roles } from "../models/emums/roles";
import store from "../store";

const routes: Array<RouteConfig> = [
    {
        path: "/settlement",
        component: require("@/layouts/layout.vue").default,
        children: [
        //COST
            {
                path: "",
                name: "Settlement",
                component: require("@/components/settlement/settlement.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.Accountant, Roles.AccountantAdmin]
                }
            },
            {
                path: "cost-document/list",
                name: "Cost document list",
                component: require("@/components/settlement/cost-document/cost-document-list.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.Accountant, Roles.AccountantAdmin]
                }
            },
            {
                path: "cost-document/create",
                name: "Create cost document",
                component: require("@/components/settlement/cost-document/cost-document.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.Accountant, Roles.AccountantAdmin]
                },
                beforeEnter: (async (to, from, next) => {
                    store.commit.settlementCommon.setPreviousUrlName(from.name)
                    const costDocumentId = Number.parseInt(to.params.id);
                    await store.dispatch.settlementCostDocument.loadCostDocument({ costDocumentId: costDocumentId });
                    next();
                })
            },
            {
                path: "cost-document/edit/:id/:isInHistoricalState?",
                name: "Edit cost document",
                component: require("@/components/settlement/cost-document/cost-document.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.Accountant, Roles.AccountantAdmin]
                },
                beforeEnter: (async (to, from, next) => {
                    store.commit.settlementCommon.setPreviousUrlName(from.name)
                    const costDocumentId = Number.parseInt(to.params.id);

                    if (to.params.isInHistoricalState != undefined && to.params.isInHistoricalState != "undefined" && to.params.isInHistoricalState == "history") {
                        store.commit.settlementCostDocument.setIsCostDocumentInHistoricalState(true);
                        store.dispatch.settlementCostDocument.getCostDocumentMementoList({ costDocumentId: costDocumentId });
                    }
                    else {
                        store.commit.settlementCostDocument.setIsCostDocumentInHistoricalState(false);
                    }

                    await store.dispatch.settlementCostDocument.loadCostDocument({ costDocumentId: costDocumentId });
                    next();
                })
            },
            {
                path: "cost-document/:id/correction/create",
                name: "Create cost document correction",
                component: require("@/components/settlement/cost-document/cost-document.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.Accountant, Roles.AccountantAdmin]
                },
                beforeEnter: (async (to, from, next) => {
                    store.commit.settlementCommon.setPreviousUrlName(from.name)
                    const costDocumentId = Number.parseInt(to.params.id);
                    await store.dispatch.settlementCostDocument.loadCostDocument({ costDocumentId: costDocumentId });

                    const itemList = JSON.parse(JSON.stringify(store.state.settlementCostDocument.costDocumentItemList));
                    store.commit.settlementCostDocument.setCostDocumentItemCorrectionList(itemList);

                    next();
                })
            },
            {
                path: "cost-document/correction/edit/:id/",
                name: "Edit cost document correction",
                component: require("@/components/settlement/cost-document/cost-document.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.Accountant, Roles.AccountantAdmin]
                },
                beforeEnter: (async (to, from, next) => {
                    store.commit.settlementCommon.setPreviousUrlName(from.name)
                    const costDocumentCorrectionId = Number.parseInt(to.params.id);
                    await store.dispatch.settlementCostDocument.loadCostDocument({ costDocumentId: costDocumentCorrectionId });
                    next();
                })
            },
            {
                path: "cost-document/correction/list",
                name: "Cost document correction list",
                component: require("@/components/settlement/cost-document/cost-document-correction-list.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.Accountant, Roles.AccountantAdmin]
                }
            },
            {
                path: "cost-payment-obligation/list",
                name: "Cost payment obligation list",
                component: require("@/components/settlement/cost-document-payment-obligation/cost-document-payment-obligation-list.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.Accountant, Roles.AccountantAdmin]
                }
            },
            {
                path: "transfer-order/list",
                name: "Transfer order list",
                component: require("@/components/settlement/cost-document-transfer-order/cost-document-transfer-order-list.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.Accountant, Roles.AccountantAdmin]
                }
            },
            {
                path: "transfer-order/create",
                name: "Create transfer order",
                component: require("@/components/settlement/cost-document-transfer-order/cost-document-transfer-order.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.Accountant, Roles.AccountantAdmin]
                },
                beforeEnter: (async (to, from, next) => {
                    store.commit.settlementCommon.setPreviousUrlName(from.name)
                    const transferOrderId = Number.parseInt(to.params.id);
                    await store.dispatch.settlementCostDocumentTransferOrder.loadTransferOrder({ transferOrderId: transferOrderId });
                    next();
                })
            },
            {
                path: "transfer-order/edit/:id/",
                name: "Edit transfer order",
                component: require("@/components/settlement/cost-document-transfer-order/cost-document-transfer-order.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.Accountant, Roles.AccountantAdmin]
                },
                beforeEnter: (async (to, from, next) => {
                    store.commit.settlementCommon.setPreviousUrlName(from.name)
                    const transferOrderId = Number.parseInt(to.params.id);
                    await store.dispatch.settlementCostDocumentTransferOrder.loadTransferOrder({ transferOrderId: transferOrderId });
                    next();
                })
            },
            {
                path: "transfer-order-package/list",
                name: "Transfer order package list",
                component: require("@/components/settlement/cost-document-transfer-order-package/cost-document-transfer-order-package-list.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.Accountant, Roles.AccountantAdmin]
                }
            },
            {
                path: "payment/cost-payment/list",
                name: "Cost payment list",
                component: require("@/components/settlement/cost-payment/cost-payment-list.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.Accountant, Roles.AccountantAdmin]
                }
            },


        //INCOME
            {
                path: "income-document/list",
                name: "Income document list",
                component: require("@/components/settlement/income-document/income-document-list.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.Accountant, Roles.AccountantAdmin]
                }
            },
            {
                path: "income-document/create",
                name: "Create income document",
                component: require("@/components/settlement/income-document/income-document.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.Accountant, Roles.AccountantAdmin]
                },
                beforeEnter: (async (to, from, next) => {
                    store.commit.settlementCommon.setPreviousUrlName(from.name)
                    const incomeDocumentId = Number.parseInt(to.params.id);
                    await store.dispatch.settlementIncomeDocument.loadIncomeDocument({ incomeDocumentId: incomeDocumentId });
                    next();
                })
            },
            {
                path: "income-document/edit/:id/:isInHistoricalState?",
                name: "Edit income document",
                component: require("@/components/settlement/income-document/income-document.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.Accountant, Roles.AccountantAdmin]
                },
                beforeEnter: (async (to, from, next) => {
                    store.commit.settlementCommon.setPreviousUrlName(from.name)
                    const incomeDocumentId = Number.parseInt(to.params.id);

                    if (to.params.isInHistoricalState != undefined && to.params.isInHistoricalState != "undefined" && to.params.isInHistoricalState == "history") {
                        store.commit.settlementIncomeDocument.setIsIncomeDocumentInHistoricalState(true);
                        store.dispatch.settlementIncomeDocument.getIncomeDocumentMementoList({ incomeDocumentId: incomeDocumentId});
                    }
                    else {
                        store.commit.settlementIncomeDocument.setIsIncomeDocumentInHistoricalState(false);
                    }

                    await store.dispatch.settlementIncomeDocument.loadIncomeDocument({ incomeDocumentId: incomeDocumentId });
                    next();
                })
            },
            {
                path: "income-document/:id/correction/create",
                name: "Create income document correction",
                component: require("@/components/settlement/income-document/income-document.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.Accountant, Roles.AccountantAdmin]
                },
                beforeEnter: (async (to, from, next) => {
                    store.commit.settlementCommon.setPreviousUrlName(from.name)
                    const incomeDocumentId = Number.parseInt(to.params.id);
                    await store.dispatch.settlementIncomeDocument.loadIncomeDocument({ incomeDocumentId: incomeDocumentId });

                    const itemList = JSON.parse(JSON.stringify(store.state.settlementIncomeDocument.incomeDocumentItemList));
                    store.commit.settlementIncomeDocument.setIncomeDocumentItemCorrectionList(itemList);

                    next();
                })
            },
            {
                path: "income-document/correction/edit/:id/",
                name: "Edit income document correction",
                component: require("@/components/settlement/income-document/income-document.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.Accountant, Roles.AccountantAdmin]
                },
                beforeEnter: (async (to, from, next) => {
                    store.commit.settlementCommon.setPreviousUrlName(from.name)
                    const incomeDocumentCorrectionId = Number.parseInt(to.params.id);
                    await store.dispatch.settlementIncomeDocument.loadIncomeDocument({ incomeDocumentId: incomeDocumentCorrectionId });
                    next();
                })
            },
            {
                path: "income-document/correction/list",
                name: "Income document correction list",
                component: require("@/components/settlement/income-document/income-document-correction-list.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.Accountant, Roles.AccountantAdmin]
                }
            },
            {
                path: "income-payment-obligation/list",
                name: "Income payment obligation list",
                component: require("@/components/settlement/income-document-payment-obligation/income-document-payment-obligation-list.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.Accountant, Roles.AccountantAdmin]
                }
            },
            {
                path: "payment/income-payment/list",
                name: "Income payment list",
                component: require("@/components/settlement/income-payment/income-payment-list.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.Accountant, Roles.AccountantAdmin]
                }
            },
            //COMMON
            {
                path: "closedMonth/list",
                name: "Closed month list",
                component: require("@/components/settlement/closed-month/closed-month-list.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.AccountantAdmin]
                }
            },
            {
                path: "register-export/list",
                name: "Register export list",
                component: require("@/components/settlement/register-export/register-export-list.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.Accountant, Roles.AccountantAdmin]
                }
            },
        ]
    }
];

export default routes;
