import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { stringMaxLength } from "../../../../assets/validation/common";
import ContractorSearchItemModel from "../../../../models/rest/contractor/contractorSearchItemModel";
import BranchRestModel from "../../../../models/rest/register/branchRestModel";

@Component
export default class BranchDialog extends Vue {
    contractorSearch = "";

    validation = {
        code: [stringMaxLength(this.$i18n, this.$t("common.code"), 2), (value: string) => !!value || this.$t("common.validationErrorRequired")],
    };

    get showDialog(): boolean {
        return this.$store.direct.state.register.showBranchDialog;
    }

    get branch(): BranchRestModel {
        const branch = this.$store.direct.state.register.branch
        if (branch.contractor != undefined) {
            this.contractorFound.push({ text: branch.contractor.text, value: branch.contractor.value } as ContractorSearchItemModel);
        }
        return branch;
    }

    get contractorFound(): ContractorSearchItemModel[] {
        return this.$store.direct.state.common.contractorSearch;
    }

    set contractorFound(val: ContractorSearchItemModel[]) {
        this.$store.direct.commit.common.setContractorSearch(val);
    }

    @Watch("contractorSearch")
    async contractorSearchLoadItems(val: string) {
        if (this.contractorSearch) {
            await this.$store.direct.dispatch.common.searchContractors({ searchText: val, contractorTypes: [], contractorPropertiesEnabled: [], source: "" });
        }
    }

    async saveClick() {
        const valid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
        if (valid) {
            await this.$store.direct.dispatch.register.updateBranch();
        }
    }

    async backClick() {
        await this.$store.direct.dispatch.register.closeBranchDialog();
    }
}
