import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import router from "@/router";
import store from "../store/index";
import dayjs from "dayjs";
import { Mutex } from 'async-mutex';
import TokenRestModel from "../models/rest/user/tokenRestModel";

axios.interceptors.request.use(
    async function (config) {

        if (config.url === "/api/user/refreshtoken") {
            return config;
        }

        const currentAccessToken = await getLocalAccessToken();
        if (typeof currentAccessToken != 'object') {

            const accessTokenExpirationFormatted = await dayjs.unix(await getLocalAccessTokenExpiration());

            if (accessTokenExpirationFormatted.add(-1, 'h') < dayjs()) {
                await refreshToken();
                return config;
            }
        }

        const url = config.url;
        store.dispatch.request(url);
        return config;
    },
    function (error) {
        const url = error.config.url;
        store.dispatch.response(url);
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    function (response) {
        const url = response.config.url;
        store.dispatch.response(url);
        return response;
    },
    function (error) {
        const url = error.response.config.url;
        store.dispatch.response(url);

        if (error.response.status === 401 && router.currentRoute.path != "/login") {
            store.dispatch.auth.logout();
        }
        return Promise.reject(error);
    }
);

const refreshTokenMutex = new Mutex();
async function refreshToken() {
    const release = await refreshTokenMutex.acquire();
    try {
        const accessToken = await getLocalAccessToken();
        const refreshToken = await getLocalRefreshToken();
        const accessTokenExpiration = await getLocalAccessTokenExpiration();
        const refreshTokenExpiration = await getLocalRefreshTokenExpiration();

        const payload = { accessToken: accessToken, accessTokenExpiration: accessTokenExpiration, refreshToken: refreshToken, refreshTokenExpiration: refreshTokenExpiration } as TokenRestModel;
        const response = await axios.post("/api/user/refreshtoken", payload)

        await updateLocalAccessToken(response.data.accessToken)
        await updateLocalRefreshToken(response.data.refreshToken)
        await updateLocalAccessTokenExpiration(response.data.accessTokenExpiration)
        await updateLocalRefreshTokenExpiration(response.data.refreshTokenExpiration)

        setBearer()
    }
    catch (e) {
        store.dispatch.auth.logout();
    }
    finally {
        release();
    }
}

async function getLocalRefreshToken() {
    return await JSON.parse(localStorage.getItem("refreshToken") || "");
}
async function updateLocalRefreshToken(refreshToken: string) {
    await localStorage.setItem("refreshToken", JSON.stringify(refreshToken));
}
async function getLocalRefreshTokenExpiration() {
    return await JSON.parse(localStorage.getItem("refreshTokenExpiration") || "");
}
async function updateLocalRefreshTokenExpiration(refreshTokenExpirationTime: string) {
    await localStorage.setItem("refreshTokenExpiration", JSON.stringify(refreshTokenExpirationTime));
}
async function getLocalAccessToken() {
    return await JSON.parse(localStorage.getItem("data") || "{}");
}
async function updateLocalAccessToken(token: string) {
    await localStorage.setItem("data", JSON.stringify(token));
}
async function getLocalAccessTokenExpiration() {
    return await JSON.parse(localStorage.getItem("dataExpiration") || "{}");
}
async function updateLocalAccessTokenExpiration(tokenExpiration: string) {
    await localStorage.setItem("dataExpiration", JSON.stringify(tokenExpiration));
}

function setBearer(): void {
    const token = JSON.parse(localStorage.getItem("data") || "{}");
    const bearer = `Bearer ${token}`;
    axios.defaults.headers.common["Authorization"] = bearer;
}
//Initial Bearer
setBearer();

Vue.use(VueAxios, axios);

export { axios as axiosInstance, setBearer };
export default axios;
