import { mixins } from "vue-class-component/lib/util";
import { VDataTable } from "vuetify/lib";
import i18n from "@/assets/i18n";

export default mixins(VDataTable).extend({
    name: "default-data-table",
    props: {
        loadingText: {
            type: String,
            default: i18n.t("common.loadingText")
        },
        footerProps: {
            type: Object,
            default() {
                return {
                    itemsPerPageText: i18n.t("common.itemsPerPage"),
                    itemsPerPageAllText: i18n.t("common.all"),
                    footerSlot: 'page-navigation'
                };
            }
        },
        options: {
            type: Object,
            default() {
                return {
                    page: 1,
                    itemsPerPage: 10,
                }
            }
        }
    },
    watch: {
        pagination: {
            handler(newVal) {
                this.$emit('update:pagination', newVal);
            },
            deep: true
        }
    },
});
