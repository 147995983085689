import Vue from "vue";
import { Component } from "vue-property-decorator";
import ListItemNumberModel from "../../../models/listItemNumberModel";

@Component
export default class TransportDocumentDialog extends Vue {
    transportDocumentTypeId = null;
    validation = {
        transportDocumentType: [(value: number) => !!value || this.$t("common.validationErrorRequired")],
    }

    get showDialog(): boolean {
        return this.$store.direct.state.supervisorTransportDocument.showDialog;
    }

    get title(): string {
        return this.$store.direct.state.supervisorTransportDocument.title;
    }

    get transportDocumentTypeList(): ListItemNumberModel[] {
        return this.$store.direct.state.supervisorTransportDocument.transportDocumentTypeList;
    }

    get currentSupervisorLoadingPlaceId(): undefined | number {
        return this.$store.direct.state.supervisorTransportDocument.currentSupervisorLoadingPlaceId;
    }

    set currentSupervisorLoadingPlaceId(val: undefined | number) {
        this.$store.direct.commit.supervisorTransportDocument.setCurrentSupervisorLoadingPlaceId(val);
    }

    get currentSupervisorUnloadingPlaceId(): undefined | number {
        return this.$store.direct.state.supervisorTransportDocument.currentSupervisorUnloadingPlaceId;
    }

    set currentSupervisorUnloadingPlaceId(val: undefined | number) {
        this.$store.direct.commit.supervisorTransportDocument.setCurrentSupervisorUnloadingPlaceId(val);
    }

    created() {
        this.$store.direct.dispatch.supervisorTransportDocument.loadTransportDocumentTypeList();
    }

    cancelClick() {
        this.$store.direct.dispatch.supervisorTransportDocument.isNotConfirmed();
    }

    async saveClick() {
        const valid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
        if (valid) {
            this.$store.direct.dispatch.supervisorTransportDocument.generateAndGetSupervisorTransporDocumentFile({ transportDocumentTypeId: this.transportDocumentTypeId });
            await this.$store.direct.dispatch.supervisorTransportDocument.isConfirmed();

            this.currentSupervisorLoadingPlaceId = undefined;
            this.currentSupervisorUnloadingPlaceId= undefined;
        }
    }
}
