import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import TopicRestModel from "../../models/rest/forum/topicRestModel";
import PostRestModel from "../../models/rest/forum/postRestModel";
import ForumDialog from "./dialog/forum.vue";
import ConfirmDialog from "../../layouts/confirmDialog/confirmDialog.vue";
import router from "../../router";
import { Roles } from "../../models/emums/roles";

@Component({
    components: {
        "forum-dialog": ForumDialog,
        "confirm-dialog": ConfirmDialog
    }
})
export default class Forum extends Vue {
    searchText = "";
    pagerOptions = this.$store.direct.state.forum.postListPagerOptions;

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get topic(): TopicRestModel {
        return this.$store.direct.state.forum.topic;
    }

    get post(): PostRestModel {
        return this.$store.direct.state.forum.post;
    }

    get forumDialogContent(): string {
        return this.$store.direct.state.forum.forumDialogContent;
    }

    get postList(): PostRestModel[] {
        return this.$store.direct.state.forum.postList;
    }

    get postListLoading(): boolean {
        return this.$store.direct.state.forum.postListLoading;
    }

    get postListTotalCount(): number {
        return this.$store.direct.state.forum.postListTotalCount;
    }

    get currentUserEmail(): string {
        return this.$store.direct.state.auth.currentUserEmail;
    }

    get currentPostId(): number {
        return this.$store.direct.state.forum.currentPostId;
    }

    get currentUserRoles() {
        return this.$store.direct.state.auth.userRoles;
    }

    postListHeaders = [
        {
            text: this.$t("forum.content"),
            align: "start",
            value: "content",
            width: "60%"
        },
        {
            text: this.$t("common.createdAt"),
            align: "start",
            value: "createdAt"
        },
        {
            text: this.$t("common.createdBy"),
            align: "start",
            value: "createdByUserName",
            sortable: false
        },
        {
            text: this.$t("common.editedAt"),
            align: "start",
            value: "editedAt"
        },
        {
            text: this.$t("common.editedBy"),
            align: "start",
            value: "editedByUserName",
            sortable: false
        },
        {
            text: this.$t("contractor.actions"),
            align: "center",
            value: "actions",
            width: "1%",
            sortable: false
        }
    ];

    @Watch("pagerOptions", { deep: true })
    pagerOptionsHandles() {
        this.loadPostList();
    }

    created() {
        this.$store.direct.commit.forum.setForumDialogTextLength(10240);
    }

    allowDeletePost(item: PostRestModel) {
        return (this.currentUserRoles.includes(Roles.Admin) || this.currentUserEmail == item.createdByUserEmail) ? true : false;
    }

    searchClick() {
        this.loadPostList();
    }

    backClick() {
        router.push({
            path: "/forum/topicList"
        });
    }

    deletePostClick(post: PostRestModel) {
        this.$store.direct.commit.forum.setCurrentPostId(post.id);
        this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: `${this.$t("common.delete")}: "${post.content}"`,
                message: `${this.$t("forum.deleteMessage")}`,
                links: [],
                options: { buttonColor: "error", layoutColor: "red" },
                buttonType: "yes/no"
            })
            .then(confirm => {
                if (confirm) {
                    this.$store.direct.dispatch.forum.deletePost({ id: this.currentPostId });
                }
            });
    }

    createPostClick() {
        this.$store.direct.dispatch.forum
            .openForumDialog({
                showDialog: true,
                title: `${this.$t("forum.createPost")}`,
                fieldTitle: `${this.$t("forum.content")}`,
                content: ""
            })
            .then(async confirm => {
                if (confirm) {
                    await this.$store.direct.dispatch.forum.addPost({
                        postContent: this.forumDialogContent
                    });
                    this.loadPostList();
                }
            });
    }

    async editPostClick(post: PostRestModel) {
        await this.$store.direct.dispatch.forum.loadPost({ id: post.id });
        this.$store.direct.dispatch.forum
            .openForumDialog({
                showDialog: true,
                title: `${this.$t("forum.editPost")}`,
                fieldTitle: `${this.$t("forum.content")}`,
                content: this.post.content
            })
            .then(async confirm => {
                if (confirm) {
                    await this.$store.direct.commit.forum.setPost({
                        id: post.id,
                        topicId: parseInt(this.$route.params["id"]),
                        content: this.forumDialogContent,
                        createdByUserEmail: post.createdByUserEmail
                    });
                    await this.$store.direct.dispatch.forum.savePost();
                    await this.loadPostList();
                }
            });
    }

    async loadPostList() {
        await this.$store.direct.dispatch.forum.loadTopic({ id: parseInt(this.$route.params["id"]) });
        await this.$store.direct.commit.forum.setCurrentTopicId(this.topic.id);
        await this.$store.direct.dispatch.forum.loadPostList({
            searchText: this.searchText,
            page: this.pagerOptions.page,
            sortBy: this.pagerOptions.sortBy[0],
            sortDesc: this.pagerOptions.sortDesc[0],
            itemsPerPage: this.pagerOptions.itemsPerPage
        });
    }
}
