import Vue from "vue";
import { Component } from "vue-property-decorator";


@Component

export default class SupervisorEncoryTab extends Vue {
    supervisorEncoryDealerAddressesFiles = [];
    supervisorEncoryPartsFiles = [];

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    async saveSupervisorEncoryDealerAddressesFile(): Promise<void> {
        if (this.supervisorEncoryDealerAddressesFiles.length > 0) {
            await this.$store.direct.dispatch.supervisorEncory.saveSupervisorEncoryDealerAddressesFile(this.supervisorEncoryDealerAddressesFiles);
            this.supervisorEncoryDealerAddressesFiles = [];
        }
    }

    async saveSupervisorEncoryPartsFile(): Promise<void> {
        if (this.supervisorEncoryPartsFiles.length > 0) {
            await this.$store.direct.dispatch.supervisorEncory.saveSupervisorEncoryPartsFile(this.supervisorEncoryPartsFiles);
            this.supervisorEncoryPartsFiles = [];
        }
    }

    async downloadHandlingUnitsAndSendEmails(): Promise<void> {
        await this.$store.direct.dispatch.supervisorEncory.downloadHandlingUnitsAndSendEmails();
    }
}
