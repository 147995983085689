import Vue from "vue";
import { Component } from "vue-property-decorator";
import AdminSettingModel from "../../../../models/rest/admin/adminSettingModel";


@Component

export default class BasicTab extends Vue {

    get settings(): AdminSettingModel {
        return this.$store.direct.state.admin.settings;
    }

    set settings(val: AdminSettingModel) {
        this.$store.direct.commit.admin.setSettings(val);
    }

    async callUpdateSha1ContentInSupervisorFilesStoredProcedure(): Promise<void> {
        await this.$store.direct.dispatch.supervisor.callUpdateSha1ContentInSupervisorFilesStoredProcedure();
    }

    async callUpdateSha1ContentInSupervisorFileMementosStoredProcedure(): Promise<void> {
        await this.$store.direct.dispatch.supervisor.callUpdateSha1ContentInSupervisorFileMementosStoredProcedure();
    }

    async callUpdateSupervisorFileIdInSupervisorFileMementosByStoredProcedure(): Promise<void> {
        setInterval(async () => {
            await this.$store.direct.dispatch.supervisor.callUpdateSupervisorFileIdInSupervisorFileMementosByStoredProcedure();
        }, 10000);
    }
}
