import { RouteConfig } from "vue-router";
import { Roles } from "@/models/emums/roles";

const routes: Array<RouteConfig> = [
    {
        path: "/vehicle",
        component: require("@/layouts/layout.vue").default,
        children: [
            {
                path: "your-vehicles",
                name: "Your vehicles",
                component: require("@/components/vehicle/your-vehicles.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.User, Roles.Disponent, Roles.DisponentAdmin]
                }
            },
            {
                path: "change-status/:id",
                name: "Vehicne change status",
                component: require("@/components/vehicle/change-status.vue").default,
                meta: {
                    roles: [Roles.Admin, Roles.User, Roles.Disponent, Roles.DisponentAdmin]
                }
            }
        ]
    }
];

export default routes;