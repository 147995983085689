import { RouteConfig } from "vue-router";
import store from "@/store";

const routes: Array<RouteConfig> = [
    {
        path: "",
        component: require("@/layouts/layout.vue").default,
        children: [
            {
                path: "online-tracking/:id",
                name: "Show online tracking",
                component: require("@/components/online-tracking/online-tracking.vue").default,
                beforeEnter: ((to, from, next) => {
                    const id = to.params.id;
                    store.dispatch.onlineTracking.getIntegraionByGuid(id);
                    next();
                })
            }
        ]
    }
];

export default routes;
