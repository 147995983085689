import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import TransportOrderRestModel from "../../models/rest/transport-order/transportOrderRestModel";

@Component
export default class TransportOrderList extends Vue {
    get pagerOptions(): any {
        return this.$store.direct.state.transportOrder.transportOrderListPagerOptions;
    }

    set pagerOptions(val: any) {
        this.$store.direct.commit.transportOrder.setTransportOrderListPagerOptions(val);
    }

    get searchText(): any {
        return this.$store.direct.state.transportOrder.transportOrderListSearchText;
    }

    set searchText(val: any) {
        this.$store.direct.commit.transportOrder.setTransportOrderListSearchText(val);
    }

    get transportOrderList(): TransportOrderRestModel[] {
        return this.$store.direct.state.transportOrder.transportOrderList;
    }

    get transportOrderListLoading(): boolean {
        return this.$store.direct.state.transportOrder.transportOrderListLoading;
    }

    get transportOrderListTotalCount(): number {
        return this.$store.direct.state.transportOrder.transportOrderListTotalCount;
    }

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    transportOrderListHeaders = [
        {
            text: this.$t("transportOrder.login"),
            align: "start",
            value: "login",
            sortable: false
        },
        {
            text: this.$t("transportOrder.place"),
            align: "start",
            value: "loadingUnloadingAddress",
            sortable: false
        },
        {
            text: this.$t("transportOrder.dangerousGoods"),
            align: "start",
            value: "dangerousGoods"
        },
        {
            text: this.$t("transportOrder.netPrice"),
            align: "start",
            value: "netPrice",
            sortable: false
        },
        {
            text: this.$t("transportOrder.files"),
            align: "center",
            value: "download",
            width: "1%",
            sortable: false
        },
        {
            text: this.$t("transportOrder.duplicate"),
            align: "center",
            value: "duplicate",
            width: "1%",
            sortable: false
        },
        {
            text: this.$t("transportOrder.show"),
            align: "center",
            value: "show",
            width: "1%",
            sortable: false
        }
    ];

    @Watch("pagerOptions", { deep: true })
    pagerOptionsHandles() {
        this.loadTransportOrderList();
    }

    searchClick() {
        this.loadTransportOrderList();
    }

    createTransportOrder() {
        return "/transport-order/create"
    }

    async loadTransportOrderList() {
        await this.$store.direct.dispatch.transportOrder.loadTransportOrderList({
            searchText: this.searchText,
            page: this.pagerOptions.page,
            sortBy: this.pagerOptions.sortBy[0],
            sortDesc: this.pagerOptions.sortDesc[0],
            itemsPerPage: this.pagerOptions.itemsPerPage
        });
    }

    duplicateTransportOrderClick(transportOrder: TransportOrderRestModel) {
        return "/transport-order/duplicate/" + transportOrder.id;
    }

    async transportOrderFileDownloadClick(transportOrder: TransportOrderRestModel) {
        await this.$store.direct.dispatch.transportOrder.downloadFile({ id: transportOrder.id });
    }

    showTransportOrderClick(transportOrder: TransportOrderRestModel) {
        return "/transport-order/show/" + transportOrder.id;
    }
}
