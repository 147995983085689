import Vue from "vue";
import { Component } from "vue-property-decorator";
import ConfirmDialog from "../../../../layouts/confirmDialog/confirmDialog.vue";
import CostTypeRestModel from "../../../../models/rest/register/costTypeRestModel";
import CostTypeDialog from "./cost-type-dialog.vue";

@Component({
    components: {
        "cost-type-dialog": CostTypeDialog,
        "confirm-dialog": ConfirmDialog,
    }
})
export default class CostTypeList extends Vue {
    get items(): CostTypeRestModel[] {
        return this.$store.direct.state.register.costTypes;
    }

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    itemsHeaders = [
        {
            text: "Id",
            value: "id"
        },
        {
            text: this.$t("common.name"),
            value: "name"
        },
        {
            text: this.$t("common.accountingAccountNumber"),
            value: "accountingAccountNumber"
        },
        {
            text: this.$t("registerExport.optimaCategoryName"),
            value: "optimaCostCategoryName"
        },
        {
            text: this.$t("registerExport.optimaCategoryId"),
            value: "optimaCostCategoryId"
        },
        {
            text: this.$t("common.edit"),
            align: "center",
            value: "edit",
            width: "1%",
            sortable: false
        },
        {
            text: this.$t("common.delete"),
            align: "center",
            value: "delete",
            width: "1%",
            sortable: false
        }
    ];

    pagerOptions = {
        page: 1,
        itemsPerPage: 10
    };

    mounted() {
        this.$store.direct.dispatch.register.loadCostTypes();
    }

    itemCreateClick() {
        this.$store.direct.dispatch.register.showCostTypeDialog({} as CostTypeRestModel);
    }

    itemEditClick(item: CostTypeRestModel) {
        this.$store.direct.dispatch.register.showCostTypeDialog(item);
    }

    itemDeleteClick(item: CostTypeRestModel) {
        this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: "Delete cost type",
                message: "Are you sure you want to delete this cost type?: " + item.id + " - " + item.name,
                links: [],
                options: { buttonColor: "error", layoutColor: "red" },
                buttonType: "yes/no"
            })
            .then(confirm => {
                if (confirm) {
                    this.$store.direct.dispatch.register.deleteCostType(item);
                }
            })
    }
}
