import Vue from "vue";
import { Component } from "vue-property-decorator";
import VForm from "../../../models/v-form";
import ContractorNoteRestModel from "../../../models/rest/contractor/contractorNoteRestModel";
import { stringMaxLength } from "../../../assets/validation/common";
import ListItemStringModel from "../../../models/listItemStringModel";

@Component
export default class NoteDialog extends Vue {
    description = [(value: string) => !!value || this.$t("common.validationErrorRequired"),
    stringMaxLength(this.$i18n, this.$t("contractor.note"), 4096)
    ];

    get note(): ContractorNoteRestModel {
        return this.$store.direct.state.contractor.note;
    }

    set note(val: ContractorNoteRestModel) {
        this.$store.direct.commit.contractor.setNote(val);
    }

    get showDialog(): boolean {
        return this.$store.direct.state.contractor.showNoteDialog;
    }

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get contractorAssets() {
        return this.$store.direct.state.contractor.contractorAssets;
    }

    get userListSorted(): ListItemStringModel[] {
        return this.contractorAssets?.crmUsers?.slice().sort((prev, next) => (prev.text > next.text ? 1 : -1));
    }

    async saveClick(): Promise<void> {
        if ((this.$refs.form as VForm).validate()) {
            const response = await this.$store.direct.dispatch.contractor.saveNote();
            if (response) {
                this.$store.direct.dispatch.confirm.openConfirmDialog({
                    showConfirmDialog: true,
                    title: "Note has been saved and sent.",
                    message: "Note has been saved and sent to user e-mail: " + response,
                    links: [],
                    options: { buttonColor: "primary", layoutColor: "primary" },
                    buttonType: "ok"
                })
            }
        }
    }

    closeClick(): void {
        this.$store.direct.dispatch.contractor.closeNoteDialog();
    }
}
