import Vue from "vue";
import { Component } from "vue-property-decorator";
import VForm from "../../../models/v-form";

@Component
export default class DocumentDialog extends Vue {
    requiredRule = (value: string) => !!value || this.$t("common.validationErrorRequired");
    files = [];

    updated() {
        this.$nextTick(function () {
            if (this.$refs.form) (this.$refs.form as VForm).resetValidation();
        });
    }

    get showDialog(): boolean {
        return this.$store.direct.state.contractor.showDocumentsDialog;
    }

    async saveClick(): Promise<void> {
        if ((this.$refs.form as VForm).validate()) {
            await this.$store.direct.dispatch.contractor.saveDocument(this.files);
        }
    }
    closeClick(): void {
        this.$store.direct.dispatch.contractor.closeDocumentDialog();
    }
}
