import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import TopicRestModel from "../../models/rest/forum/topicRestModel";
import ForumDialog from "./dialog/forum.vue";

@Component({
    components: {
        "forum-dialog": ForumDialog
    }
})
export default class Forum extends Vue {

    get pagerOptions(): any {
        return this.$store.direct.state.forum.topicListPagerOptions;
    }

    set pagerOptions(val: any) {
        this.$store.direct.commit.forum.setTopicListPagerOptions(val);
    }

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get topic(): TopicRestModel {
        return this.$store.direct.state.forum.topic;
    }

    get forumDialogContent(): string {
        return this.$store.direct.state.forum.forumDialogContent;
    }

    get topicList(): TopicRestModel[] {
        return this.$store.direct.state.forum.topicList;
    }

    get topicListLoading(): boolean {
        return this.$store.direct.state.forum.topicListLoading;
    }

    get topicListTotalCount(): number {
        return this.$store.direct.state.forum.topicListTotalCount;
    }

    get searchText(): string {
        return this.$store.direct.state.forum.searchText;
    }

    set searchText(value: string) {
        this.$store.direct.commit.forum.setSearchText(value);
    }

    topicListHeaders = [
        {
            text: this.$t("forum.title"),
            align: "start",
            value: "title",
            width: "60%"
        },
        {
            text: this.$t("common.createdAt"),
            align: "start",
            value: "createdAt"
        },
        {
            text: this.$t("common.createdBy"),
            align: "start",
            value: "createdByUserName",
            sortable: false
        },
        {
            text: this.$t("common.editedAt"),
            align: "start",
            value: "editedAt"
        },
        {
            text: this.$t("common.editedBy"),
            align: "start",
            value: "editedByUserName",
            sortable: false
        },
        {
            text: this.$t("forum.showPosts"),
            align: "center",
            value: "show",
            width: "1%",
            sortable: false
        },
        {
            text: this.$t("contractor.actions"),
            align: "center",
            value: "actions",
            width: "1%",
            sortable: false
        }
    ];

    @Watch("pagerOptions", { deep: true })
    pagerOptionsHandles() {
        this.loadTopicList();
    }

    created() {
        this.$store.direct.commit.forum.setForumDialogTextLength(512);
    }

    expandedList() {
        const list = this.topicList.filter((x: any) => {
            if (x.searchedPosts != null && x.searchedPosts.length > 0)
                return x;
        })
        return list;
    }

    searchClick() {
        this.loadTopicList();
    }

    createTopicClick() {
        this.$store.direct.dispatch.forum
            .openForumDialog({
                showDialog: true,
                title: `${this.$t("forum.createTopic")}`,
                fieldTitle: `${this.$t("forum.title")}`,
                content: ""
            })
            .then(async confirm => {
                if (confirm) {
                    await this.$store.direct.dispatch.forum.addTopic({
                        title: this.forumDialogContent
                    });
                    this.loadTopicList();
                }
            });
    }

    async editTopicClick(topic: TopicRestModel) {
        await this.$store.direct.dispatch.forum.loadTopic({ id: topic.id });

        this.$store.direct.dispatch.forum
            .openForumDialog({
                showDialog: true,
                title: `${this.$t("forum.editTopic")}`,
                fieldTitle: `${this.$t("forum.title")}`,
                content: this.topic.title
            })
            .then(async confirm => {
                if (confirm) {
                    await this.$store.direct.commit.forum.setTopic({
                        id: topic.id,
                        title: this.forumDialogContent
                    });
                    await this.$store.direct.dispatch.forum.saveTopic();
                    await this.loadTopicList();
                }
            });
    }

    openTopicClick(topic: TopicRestModel) {
        return `/forum/topic/${topic.id}`;
    }

    async loadTopicList() {
        await this.$store.direct.dispatch.forum.loadTopicList({
            searchText: this.searchText,
            page: this.pagerOptions.page,
            sortBy: this.pagerOptions.sortBy[0],
            sortDesc: this.pagerOptions.sortDesc[0],
            itemsPerPage: this.pagerOptions.itemsPerPage
        });
    }
}
