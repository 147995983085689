import dayjs from "dayjs";
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import i18n from "../../../assets/i18n";
import ConfirmDialog from "../../../layouts/confirmDialog/confirmDialog.vue";
import { BranchEnum } from "../../../models/emums/branchEnum";
import { Roles } from "../../../models/emums/roles";
import { SettlementDocumentRecalculatingTypeEnum } from "../../../models/emums/settlement/settlementDocumentRecalculatingTypeEnum";
import { SettlementPaymentStatusEnum } from "../../../models/emums/settlement/settlementPaymentStatusEnum";
import ListItemNumberModel from "../../../models/listItemNumberModel";
import ContractorSearchItemModel from "../../../models/rest/contractor/contractorSearchItemModel";
import CountryRestModel from "../../../models/rest/register/countryRestModel";
import CurrencyRestModel from "../../../models/rest/register/currencyRestModel";
import SettlementIncomeDocumentItemRestModel from "../../../models/rest/settlement/income-document/settlementIncomeDocumentItemRestModel";
import SettlementIncomeDocumentItemServiceTypeRestModel from "../../../models/rest/settlement/income-document/settlementIncomeDocumentItemServiceTypeRestModel";
import SettlementIncomeDocumentListItemRestModel from "../../../models/rest/settlement/income-document/settlementIncomeDocumentListItemRestModel";
import SettlementIncomeDocumentListRequestModel from "../../../models/rest/settlement/income-document/settlementIncomeDocumentListRequestModel";
import SettlementIncomeDocumentRestModel from "../../../models/rest/settlement/income-document/settlementIncomeDocumentRestModel";
import SettlementPaymentIncomeDocumentRestModel from "../../../models/rest/settlement/payment/settlementPaymentIncomeDocumentRestModel";
import SettlementPaymentRestModel from "../../../models/rest/settlement/payment/settlementPaymentRestModel";
import SettlementAmountSummaryRestModel from "../../../models/rest/settlement/settlementAmountSummaryRestModel";
import SettlementDocumentStatusRestModel from "../../../models/rest/settlement/settlementDocumentStatusRestModel";
import SettlementDocumentTypeRestModel from "../../../models/rest/settlement/settlementDocumentTypeRestModel";
import SupervisorForSettlementRestModel from "../../../models/rest/supervisor/supervisorForSettlementRestModel";
import SupervisorRestModel from "../../../models/rest/supervisor/supervisorRestModel";
import IncomePaymentDialog from "../income-payment/income-payment-dialog.vue";
import SettlementAmountSummaryRow from "../settlement-amount-summary-row.vue";

@Component({
    components: {
        "income-payment-dialog": IncomePaymentDialog,
        "confirm-dialog": ConfirmDialog,
        "settlement-amount-summary-row": SettlementAmountSummaryRow,
    }
})

export default class IncomeDocumentList extends Vue {
    usersLabel = this.$t("common.users");
    paymentTermDateLabel = this.$t("incomeDocument.paymentTermDate");
    itemServiceTypeLabel = this.$t("incomeDocument.itemServiceType");
    documentTypeLabel = this.$t("incomeDocument.documentType");
    documentCountryLabel = this.$t("incomeDocument.contractorCountry");
    contractorSearch = "";
    incomePaymentListPagerOptions = {
        itemsPerPage: -1,
    };
    supervisorSearch = "";
    issueDateFromLabel = this.$t("costDocument.issueDateFrom");
    issueDateToLabel = this.$t("costDocument.issueDateTo");
    saleDateFromLabel = this.$t("costDocument.saleDateFrom");
    saleDateToLabel = this.$t("costDocument.saleDateTo");
    paymentTermDateFromLabel = this.$t("costDocument.paymentTermDateFrom");
    paymentTermDateToLabel = this.$t("costDocument.paymentTermDateTo");
    paymentDateFromLabel = this.$t("costDocument.paymentDateFrom");
    paymentDateToLabel = this.$t("costDocument.paymentDateTo");
    mainCheckboxIsSelected = false;
    showAmountSummaryRowForCalculatedSelectedList = false;
    showFiltersMenu = false;

    incomeDocumentListHeaders = [
        {
            align: "start",
            value: "checkbox",
            width: "1%",
            sortable: false,
        },
        {
            text: "Id",
            align: "center",
            value: "id",
            width: "1%",
        },
        {
            text: this.$t("contractor.branch"),
            align: "start",
            value: "branchId",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.incomeDocumentType"),
            align: "start",
            value: "incomeDocumentTypeCode",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.documentNumber"),
            align: "center",
            value: "documentNumber",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.status"),
            align: "start",
            value: "incomeDocumentStatusName",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.correction"),
            align: "start",
            value: "documentCorrectionDocumentNumber",
            sortable: true,
        },
        {
            text: this.$t("supervisor.orderId"),
            align: "start",
            value: "supervisorOrderIdcsString",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.customerOrderId"),
            align: "start",
            value: "supervisorCustomerOrderIdcsString",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.contractorName"),
            align: "start",
            value: "contractorName",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.contractorTinWithPrefix"),
            align: "start",
            value: "contractorTinWithPrefix",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.saleDate"),
            align: "start",
            value: "saleDate",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.issueDate"),
            align: "start",
            value: "issueDate",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.paymentTermDate"),
            align: "start",
            value: "paymentTermDate",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.paymentDaysLeft"),
            align: "start",
            value: "paymentDaysLeft",
            width: "1%",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.paymentDate"),
            align: "start",
            value: "paymentDate",
            sortable: true,
        },
        {
            text: "",
            align: "center",
            value: "paymentStatus",
            sortable: true,
        },
        {
            text: this.$t("common.currency"),
            align: "center",
            value: "currencyCode",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.totalNetto"),
            align: "end",
            value: "totalNetto",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.totalTax"),
            align: "end",
            value: "totalTax",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.totalBrutto"),
            align: "end",
            value: "totalBrutto",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.totalNettoPLN"),
            align: "end",
            value: "totalNettoPln",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.totalTaxPLN"),
            align: "end",
            value: "totalTaxPln",
            sortable: true,
        },
        {
            text: this.$t("incomeDocument.totalBruttoPLN"),
            align: "end",
            value: "totalBruttoPln",
            sortable: true,
        },
        {
            text: this.$t("common.actions"),
            align: "right",
            value: "actions",
            width: "1%",
            sortable: false,
        }
    ];

    incomePaymentListHeaders = [
        {
            text: "Id",
            align: "start",
            value: "id",
            width: "1%",
            sortable: true,
        },
        {
            text: this.$t("costDocument.paymentType"),
            align: "start",
            value: "paymentTypeId",
            sortable: false,
        },
        {
            text: this.$t("payment.amount"),
            align: "start",
            value: "paymentAmount",
            sortable: false,
        },
        {
            text: this.$t("payment.currency"),
            align: "start",
            value: "currencyCode",
            sortable: false,
        },
        {
            text: this.$t("common.createdBy"),
            align: "start",
            value: "createdByUserName",
            sortable: false,
        },
        {
            text: this.$t("payment.paymentDate"),
            align: "start",
            value: "paymentDate",
            sortable: false,
        },
    ];

    get isLoading(): boolean {
        return this.$store.direct.state.loading;
    }

    get incomeDocumentListLoading(): boolean {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentListLoading;
    }

    get incomeDocumentList(): SettlementIncomeDocumentListItemRestModel[] {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentList;
    }

    get incomeDocumentListTotalCount(): number {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentListTotalCount;
    }

    get incomeDocumentListAmountSummaryList(): SettlementAmountSummaryRestModel[] {
        let amountSummary = this.$store.direct.state.settlementIncomeDocument.incomeDocumentListAmountSummaryList;

        if (amountSummary == undefined) {
            amountSummary = [] as SettlementAmountSummaryRestModel[];
        }

        return amountSummary;
    }

    get incomeDocumentListPagerOptions(): any {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentListPagerOptions;
    }

    set incomeDocumentListPagerOptions(value: any) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentListPagerOptions(value);
    }

    get incomeDocumentListSearchText(): string {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentListSearchText;
    }

    set incomeDocumentListSearchText(value: string) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentListSearchText(value);
    }

    get incomeDocumentListSearchBranchId(): number {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentListSearchBranchId;
    }

    set incomeDocumentListSearchBranchId(value: number) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentListSearchBranchId(value);
    }

    get searchIncomeDocumentStatusIdcs(): number[] {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentListSearchDocumentStatusIdcs;
    }

    set searchIncomeDocumentStatusIdcs(value: number[]) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentListSearchDocumentStatusIdcs(value);
    }

    get searchDocumentTypeIdcs(): number[] {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentListSearchDocumentTypeIdcs;
    }

    set searchDocumentTypeIdcs(value: number[]) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentListSearchDocumentTypeIdcs(value);
    }

    get searchIncomeDocumentTypeId(): number {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentListSearchIncomeDocumentTypeId;
    }

    set searchIncomeDocumentTypeId(value: number) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentListSearchIncomeDocumentTypeId(value);
    }

    get searchContractors(): ContractorSearchItemModel[] {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentListSearchContractors;
    }

    set searchContractors(value: ContractorSearchItemModel[]) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentListSearchContractors(value);
    }

    get searchContractorIdcs(): number[] {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentListSearchContractorIdcs;
    }

    set searchContractorIdcs(value: number[]) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentListSearchContractorIdcs(value);
    }

    get searchSupervisor(): SupervisorRestModel {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentListSearchSupervisor;
    }

    set searchSupervisor(value: SupervisorRestModel) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentListSearchSupervisor(value);
    }

    get searchIsPaid(): number {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentListSearchIsPaid;
    }

    set searchIsPaid(value: number) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentListSearchIsPaid(value);
    }

    get searchIssueDateFrom(): string {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentListSearchIssueDateFrom;
    }

    set searchIssueDateFrom(value: string) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentListSearchIssueDateFrom(value);
    }

    get searchIssueDateTo(): string {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentListSearchIssueDateTo;
    }

    set searchIssueDateTo(value: string) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentListSearchIssueDateTo(value);
    }

    get searchSaleDateFrom(): string {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentListSearchSaleDateFrom;
    }

    set searchSaleDateFrom(value: string) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentListSearchSaleDateFrom(value);
    }

    get searchSaleDateTo(): string {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentListSearchSaleDateTo;
    }

    set searchSaleDateTo(value: string) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentListSearchSaleDateTo(value);
    }

    get searchPaymentTermDateFrom(): string {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentListSearchPaymentTermDateFrom;
    }

    set searchPaymentTermDateFrom(value: string) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentListSearchPaymentTermDateFrom(value);
    }

    get searchPaymentTermDateTo(): string {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentListSearchPaymentTermDateTo;
    }

    set searchPaymentTermDateTo(value: string) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentListSearchPaymentTermDateTo(value);
    }

    get searchPaymentDateFrom(): string {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentListSearchPaymentDateFrom;
    }

    set searchPaymentDateFrom(value: string) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentListSearchPaymentDateFrom(value);
    }

    get searchPaymentDateTo(): string {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentListSearchPaymentDateTo;
    }

    set searchPaymentDateTo(value: string) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentListSearchPaymentDateTo(value);
    }

    get searchExpiredDaysSince(): string {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentListSearchExpiredDaysSince;
    }

    set searchExpiredDaysSince(value: string) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentListSearchExpiredDaysSince(value);
    }

    get searchExpiredDaysTo(): string {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentListSearchExpiredDaysTo;
    }

    set searchExpiredDaysTo(value: string) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentListSearchExpiredDaysTo(value);
    }

    get searchSelectedCurrency(): CurrencyRestModel {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentListSearchSelectedCurrency;
    }

    set searchSelectedCurrency(value: CurrencyRestModel) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentListSearchSelectedCurrency(value);
    }

    get searchItemServiceTypes(): SettlementIncomeDocumentItemServiceTypeRestModel[] {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentListSearchItemServiceTypes;
    }

    set searchItemServiceTypes(value: SettlementIncomeDocumentItemServiceTypeRestModel[]) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentListSearchItemServiceTypes(value);
    }

    get selectedCountryCodes(): string[] {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentListSearchCountryCode;
    }

    set selectedCountryCodes(value: string[]) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentListSearchCountryCode(value);
    }

    get footerPropsForSettlement(): any {
        return this.$store.direct.state.settlementCommon.footerPropsForSettlement;
    }

    get searchAccountantIdList(): any {
        return this.$store.direct.state.settlementCommon.searchAccountantIdList;
    }

    set searchAccountantIdList(value: any) {
        this.$store.direct.commit.settlementCommon.setSearchAccountantIdList(value);
    }

    get accountantListSorted(): any {
        return this.$store.direct.state.settlementCommon.settlementAssets.accountantUsers?.sort((prev, next) => (prev.text > next.text ? 1 : -1));
    }

    get branches(): ListItemNumberModel[] {
        const branches = this.$store.direct.state.contractor.contractorAssets.branches;
        if (branches == undefined) {
            this.$store.direct.dispatch.contractor.loadContractorAssets();
        }
        return branches;
    }

    get branchList(): ListItemNumberModel[] {
        const branchList = this.$store.direct.state.contractor.contractorAssets.branches;
        let clonedBranchList = [] as ListItemNumberModel[];
        if (branchList == undefined) {
            this.$store.direct.dispatch.contractor.loadContractorAssets();
        }
        else {
            clonedBranchList = [...branchList];
            clonedBranchList.push({ value: 0, text: i18n.t("common.all") } as ListItemNumberModel);
        }
        return clonedBranchList;
    }

    get countryList(): CountryRestModel[] {
        return this.$store.direct.state.contractor.contractorAssets.countries;
    }

    get incomeDocumentStatuses(): SettlementDocumentStatusRestModel[] {
        const statusesList = this.$store.direct.state.settlementCommon.settlementAssets.incomeDocumentStatuses;
        if (statusesList == undefined) {
            this.$store.direct.dispatch.settlementCommon.loadSettlementAssets();
        }
        return statusesList
    }

    get settlementDocumentTypes(): SettlementDocumentTypeRestModel[] {
        return this.$store.direct.state.settlementCommon.settlementAssets.documentTypes;
    }

    get settlementIncomeDocumentTypes(): ListItemNumberModel[] {
        const statusesList = this.$store.direct.state.settlementCommon.settlementAssets.incomeDocumentTypes;

        let clonedStatusesList = [] as ListItemNumberModel[];
        if (statusesList == undefined) {
            this.$store.direct.dispatch.settlementCommon.loadSettlementAssets();
        }
        else {
            clonedStatusesList = [...statusesList];
            clonedStatusesList.push({ value: 0, text: i18n.t("common.all") } as ListItemNumberModel);
        }
        return clonedStatusesList;
    }

    get currencies(): CurrencyRestModel[] {
        const currencies = this.$store.direct.state.contractor.contractorAssets.currencies;
        if (currencies == undefined) {
            this.$store.direct.dispatch.contractor.loadContractorAssets();
        }
        else {
            currencies.push({ code: i18n.t("common.all"), name: "" } as CurrencyRestModel)
        }

        return currencies;
    }

    get incomeDocumentItemServiceTypes(): SettlementIncomeDocumentItemServiceTypeRestModel[] {
        const incomeDocumentItemServiceTypes = this.$store.direct.state.settlementCommon.settlementAssets.incomeDocumentItemServiceTypes;
        if (incomeDocumentItemServiceTypes == undefined) {
            this.$store.direct.dispatch.settlementCommon.loadSettlementAssets();
        }
        else {
            incomeDocumentItemServiceTypes.push({ description: "Opis spoza systemu", id: -1 } as SettlementIncomeDocumentItemServiceTypeRestModel)
            incomeDocumentItemServiceTypes.push({ description: i18n.t("common.all"), id: 0 } as SettlementIncomeDocumentItemServiceTypeRestModel)
        }
        return incomeDocumentItemServiceTypes;
    }

    get hasOrHasNotOrAllTypeList(): ListItemNumberModel[] {
        return this.$store.direct.state.common.hasOrHasNotOrAllTypeList;
    }

    get supervisorFound(): SupervisorRestModel[] {
        return this.$store.direct.state.supervisor.supervisorSearch;
    }

    get contractorFound(): ContractorSearchItemModel[] {
        return this.$store.direct.state.common.contractorSearch;
    }

    get paymentTypeList(): ListItemNumberModel[] {
        return this.$store.direct.state.settlementCommon.settlementAssets.paymentTypes;
    }

    get incomeDocument(): SettlementIncomeDocumentRestModel {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocument;
    }

    set incomeDocument(val: SettlementIncomeDocumentRestModel) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocument(val);
    }

    get incomeDocumentIssueDate() {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentIssueDate;
    }

    set incomeDocumentIssueDate(val: string) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentIssueDate(val);
    }

    get incomeDocumentSaleDate() {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentSaleDate;
    }

    set incomeDocumentSaleDate(val: string) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentSaleDate(val);
    }

    get incomeDocumentPaymentDate() {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentPaymentDate;
    }

    set incomeDocumentPaymentDate(val: string) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentPaymentDate(val);
    }

    get incomeDocumentSupervisorList(): SupervisorForSettlementRestModel[] {
        return this.$store.direct.state.settlementCommon.supervisorList;
    }

    set incomeDocumentSupervisorList(val: SupervisorForSettlementRestModel[]) {
        this.$store.direct.commit.settlementCommon.setSupervisorList(val);
    }

    get incomePayment(): SettlementPaymentRestModel {
        return this.$store.direct.state.settlementPayment.payment;
    }

    set incomePayment(val: SettlementPaymentRestModel) {
        this.$store.direct.commit.settlementPayment.setPayment(val);
    }

    get selectedIncomeDocumentList(): SettlementIncomeDocumentListItemRestModel[] {
        return this.$store.direct.state.settlementIncomeDocument.selectedIncomeDocumentList;
    }

    set selectedIncomeDocumentList(val: SettlementIncomeDocumentListItemRestModel[]) {
        this.$store.direct.commit.settlementIncomeDocument.setSelectedIncomeDocumentList(val);
    }

    get documentRecalculatingType(): SettlementDocumentRecalculatingTypeEnum {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentDocumentRecalculatingType;
    }

    set documentRecalculatingType(val: SettlementDocumentRecalculatingTypeEnum) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentDocumentRecalculatingType(val);
    }

    get calculatedSelectedListForAmountSummaryRow(): SettlementAmountSummaryRestModel[] {
        return this.$store.direct.state.settlementCommon.calculatedSelectedListForAmountSummaryRow;
    }

    get currentUserRoles() {
        return this.$store.direct.state.auth.userRoles;
    }

    get filterAsActive(): boolean {
        return this.$store.direct.state.settlementIncomeDocument.incomeDocumentListFilterAsActive;
    }

    set filterAsActive(val: boolean) {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentListFilterAsActive(val);
    }

    @Watch("incomeDocumentListPagerOptions", { deep: true })
    pagerOptionsHandles() {
        this.loadIncomeDocumentList();
    }

    @Watch("contractorSearch")
    async contractorSearchLoadItems(val: string) {
        await this.$store.direct.dispatch.common.searchContractors({ searchText: val, contractorTypes: [], contractorPropertiesEnabled: [], source: "" });
        if (this.searchContractors.length > 0) {
            this.searchContractors.forEach((x: any) => {
                if (!this.contractorFound.includes(x)) {
                    this.contractorFound.push(x);
                }
            })
        }
    }

    @Watch("supervisorSearch")
    async supervisorSearchLoadItems(val: string) {
        if (this.supervisorSearch) {
            await this.$store.direct.dispatch.supervisor.searchSupervisorsForSettlement({ searchText: val });
        }
    }

    @Watch("searchSelectedCurrency")
    searchSelectedCurrencyHandler(val: CurrencyRestModel) {
        this.checkIfFilterIsActive();
    }

    @Watch("searchItemServiceTypes")
    searchItemServiceTypeHandler(val: SettlementIncomeDocumentItemServiceTypeRestModel[]) {
        this.checkIfFilterIsActive();
    }

    @Watch("searchIsPaid")
    searchIsPaidHandler(val: number) {
        this.checkIfFilterIsActive();
    }

    @Watch("searchPaymentDateFrom")
    searchPaymentDateFromHandler(val: string) {
        this.checkIfFilterIsActive();
    }

    @Watch("searchPaymentDateTo")
    searchPaymentDateToHandler(val: string) {
        this.checkIfFilterIsActive();
    }

    @Watch("searchExpiredDaysSince")
    searchExpiredDaysSinceHandler(val: string) {
        this.checkIfFilterIsActive();
    }

    @Watch("searchExpiredDaysTo")
    searchExpiredDaysToHandler(val: string) {
        this.checkIfFilterIsActive();
    }

    created() {
        this.loadIncomeDocumentList();
    }

    formatPaymentTypeIdToPaymentName(paymentTypeId: number) {
        if (this.paymentTypeList != undefined) {
            return this.paymentTypeList.find(x => x.value == paymentTypeId)?.text;
        }
    }

    itemRowTextColorAndStyle(item: SettlementIncomeDocumentListItemRestModel) {
        let style = ""
        item.paymentDaysLeft < 0 ? style += "red--text " : "";
        (item.documentCorrectionDocumentNumber != undefined && item.documentCorrectionDocumentNumber != null) ? style += "font-italic " : "";
        return style;
    }

    formatBranchIdToBranchName(branchId: any) {
        if (this.branches != undefined) {
            return this.branches.find(x => x.value == branchId)?.text;
        }
    }

    formatDate(dateTime: any) {
        if (dateTime != null) {
            return dayjs(dateTime).format("YYYY-MM-DD");
        }
    }

    checkIfFilterIsActive() {
        if ((this.searchSelectedCurrency.name == undefined || this.searchSelectedCurrency.name.length == 0) &&
            this.searchAccountantIdList.length == 0 &&
            this.searchItemServiceTypes.length == 0 &&
            this.searchIsPaid == 0 &&
            this.searchPaymentDateFrom === "" &&
            this.searchPaymentDateTo === "" &&
            this.searchExpiredDaysSince === "" &&
            this.searchExpiredDaysTo === "") {
            this.filterAsActive = false;
        }
        else {
            this.filterAsActive = true;
        }
    }

    getPaymentStatusIconColor(item: SettlementIncomeDocumentListItemRestModel): string {
        if (item.paymentStatusId == SettlementPaymentStatusEnum.Niezaplacona) {
            return "red--text";
        }
        if (item.paymentStatusId == SettlementPaymentStatusEnum.ZaplataCzesciowa) {
            return "blue--text";
        }
        else if (item.paymentStatusId == SettlementPaymentStatusEnum.Zaplacona) {
            return "green--text";
        }
        else if (item.paymentStatusId == SettlementPaymentStatusEnum.Nadplata) {
            return "yellow--text";
        }
        else {
            return "";
        }
    }

    userIsAdminOrAccountantAdmin(): boolean {
        if (this.currentUserRoles.includes(Roles.Admin) || this.currentUserRoles.includes(Roles.AccountantAdmin)) {
            return true;
        }
        return false;
    }

    searchClick() {
        this.loadIncomeDocumentList();
    }

    clearSearchContractorClick() {
        this.searchContractorIdcs = [] as number[];
        this.loadIncomeDocumentList();
    }

    clearSearchSupervisorClick() {
        this.searchSupervisor = {} as SupervisorRestModel;
        this.loadIncomeDocumentList();
    }

    clearDateTimePicker(clearableValue: string) {
        if (clearableValue == "searchIssueDateFromValue") {
            this.searchIssueDateFrom = "";
        }
        if (clearableValue == "searchIssueDateToValue") {
            this.searchIssueDateTo = "";
        }
        if (clearableValue == "searchSaleDateFromValue") {
            this.searchSaleDateFrom = "";
        }
        if (clearableValue == "searchSaleDateToValue") {

            this.searchSaleDateTo = "";
        }
        if (clearableValue == "searchPaymentTermDateFromValue") {

            this.searchPaymentTermDateFrom = "";
        }
        if (clearableValue == "searchPaymentTermDateToValue") {

            this.searchPaymentTermDateTo = "";
        }
        if (clearableValue == "searchPaymentDateFromValue") {

            this.searchPaymentDateFrom = "";
        }
        if (clearableValue == "searchPaymentDateToValue") {

            this.searchPaymentDateTo = "";
        }

        this.loadIncomeDocumentList();
    }

    clearAllFilters() {
        this.incomeDocumentListSearchText = "";
        this.incomeDocumentListSearchBranchId = 0;
        this.searchContractorIdcs = [] as number[];
        this.searchContractors = [] as ContractorSearchItemModel[];
        this.searchIncomeDocumentStatusIdcs = [] as number[];
        this.searchDocumentTypeIdcs = [];
        this.searchIncomeDocumentTypeId = 0;
        this.searchSupervisor = {} as SupervisorRestModel;
        this.searchIssueDateFrom = "";
        this.searchIssueDateTo = "";
        this.searchSaleDateFrom = "";
        this.searchSaleDateTo = "";
        this.searchPaymentTermDateFrom = "";
        this.searchPaymentTermDateTo = "";
        this.searchPaymentDateFrom = "";
        this.searchPaymentDateTo = "";
        this.searchExpiredDaysSince = "";
        this.searchExpiredDaysTo = "";
        this.searchIsPaid = 0;
        this.searchSelectedCurrency = {} as CurrencyRestModel;
        this.searchItemServiceTypes = [];
        this.searchAccountantIdList = [];
        this.selectedCountryCodes = [] as string[];

        this.loadIncomeDocumentList();
    }

    async loadIncomeDocumentList() {
        if (!this.searchSelectedCurrency.name) {
            this.searchSelectedCurrency = {} as CurrencyRestModel;
        }
        if (this.searchItemServiceTypes.length == 0) {
            this.searchItemServiceTypes = [] as SettlementIncomeDocumentItemServiceTypeRestModel[];
        }

        await this.$store.direct.dispatch.settlementIncomeDocument.loadIncomeDocumentList({
            requestModel: this.prepareIncomeDocumentRequestModel()
        });
    }

    createXlsReportClick() {
        if (!this.searchSelectedCurrency.name) {
            this.searchSelectedCurrency = {} as CurrencyRestModel;
        }
        if (this.searchItemServiceTypes.length == 0) {
            this.searchItemServiceTypes = [] as SettlementIncomeDocumentItemServiceTypeRestModel[];
        }

        this.$store.direct.dispatch.settlementIncomeDocument.downloadXlsReportSettlementIncomeDocumentList({
            requestModel: this.prepareIncomeDocumentRequestModel()
        });
    }

    createXlsPaymentReportClick() {
        if (!this.searchSelectedCurrency.name) {
            this.searchSelectedCurrency = {} as CurrencyRestModel;
        }
        if (this.searchItemServiceTypes.length == 0) {
            this.searchItemServiceTypes = [] as SettlementIncomeDocumentItemServiceTypeRestModel[];
        }

        this.$store.direct.dispatch.settlementIncomeDocument.downloadXlsPaymentReportSettlementIncomeDocumentList({
            requestModel: this.prepareIncomeDocumentRequestModel()
        });
    }

    prepareIncomeDocumentRequestModel(): SettlementIncomeDocumentListRequestModel {
        return {
            page: this.incomeDocumentListPagerOptions.page,
            pageSize: this.incomeDocumentListPagerOptions.itemsPerPage,
            sortBy: this.incomeDocumentListPagerOptions.sortBy[0],
            sortDesc: this.incomeDocumentListPagerOptions.sortDesc[0],
            searchText: this.incomeDocumentListSearchText,
            searchBranchId: this.incomeDocumentListSearchBranchId,
            searchAccountantIdList: this.searchAccountantIdList,
            searchContractorIdcs: (this.searchContractors != undefined && this.searchContractors.length > 0) ? this.searchContractors.map(x => x.value) : [],
            searchIncomeDocumentStatusIdcs: this.searchIncomeDocumentStatusIdcs,
            searchDocumentTypeIdcs: this.searchDocumentTypeIdcs,
            searchIncomeDocumentTypeId: this.searchIncomeDocumentTypeId,
            searchSupervisorId: this.searchSupervisor?.id,
            searchIssueDateFrom: this.searchIssueDateFrom,
            searchIssueDateTo: this.searchIssueDateTo,
            searchSaleDateFrom: this.searchSaleDateFrom,
            searchSaleDateTo: this.searchSaleDateTo,
            searchPaymentTermDateFrom: this.searchPaymentTermDateFrom,
            searchPaymentTermDateTo: this.searchPaymentTermDateTo,
            searchPaymentDateFrom: this.searchPaymentDateFrom,
            searchPaymentDateTo: this.searchPaymentDateTo,
            searchExpiredDaysSince: this.searchExpiredDaysSince,
            searchExpiredDaysTo: this.searchExpiredDaysTo,
            isPaid: this.searchIsPaid,
            searchSelectedCurrency: this.searchSelectedCurrency.code,
            searchItemServiceTypes: this.searchItemServiceTypes,
            idList: this.selectedIncomeDocumentList.map((x: SettlementIncomeDocumentListItemRestModel) => {
                return x.id;
            }),
            searchCountryCodes: this.selectedCountryCodes,
        } as SettlementIncomeDocumentListRequestModel;
    }

    createXlsReportForCollectiveDocumentClick(incomeDocument: SettlementIncomeDocumentListItemRestModel) {
        this.$store.direct.dispatch.settlementIncomeDocument.downloadXlsReportForCollectiveDocument({
            incomeDocument: incomeDocument
        });
    }

    createIncomeDocument() {
        this.$router.push({ path: "/settlement/income-document/create" });
    }

    editIncomeDocumentClick(incomeDocumentId: number, isIncomeDocumentInHistoricalState: boolean) {
        if (isIncomeDocumentInHistoricalState) {
            return "/settlement/income-document/edit/" + incomeDocumentId + "/" + "history";
        }
        return "/settlement/income-document/edit/" + incomeDocumentId;
    }

    async generateAndGetIncomeDocumentFile(incomeDocument: SettlementIncomeDocumentListItemRestModel, needGeneratingCollectiveStatement: boolean) {
        await this.$store.direct.dispatch.settlementIncomeDocument.loadIncomeDocument({ incomeDocumentId: incomeDocument.id });

        await this.$store.direct.dispatch.settlementIncomeDocument.generateAndGetIncomeDocumentFile({ incomeDocumentId: incomeDocument.id, documentLanguage: undefined, needGeneratingCollectiveStatement: needGeneratingCollectiveStatement });

        if ((this.incomeDocument.branchId == BranchEnum.DE && this.incomeDocument.documentLanguage != "DE") || (this.incomeDocument.branchId == BranchEnum.PL && this.incomeDocument.documentLanguage != "PL")) {
            await this.$store.direct.dispatch.settlementIncomeDocument.generateAndGetIncomeDocumentFile({ incomeDocumentId: incomeDocument.id, documentLanguage: this.incomeDocument.documentLanguage, needGeneratingCollectiveStatement: needGeneratingCollectiveStatement });
        }

        this.cleanIncomeDocumentAndItsChildren();
    }

    async generateAndGetMultipleIncomeDocumentFiles() {
        await this.selectedIncomeDocumentList.forEach(async (x: SettlementIncomeDocumentListItemRestModel) => {
            await this.$store.direct.dispatch.settlementIncomeDocument.loadIncomeDocument({ incomeDocumentId: x.id });

            await this.$store.direct.dispatch.settlementIncomeDocument.generateAndGetIncomeDocumentFile({ incomeDocumentId: x.id, documentLanguage: undefined, needGeneratingCollectiveStatement: false });

            if ((this.incomeDocument.branchId == BranchEnum.DE && this.incomeDocument.documentLanguage != "DE") || (this.incomeDocument.branchId == BranchEnum.PL && this.incomeDocument.documentLanguage != "PL")) {
                await this.$store.direct.dispatch.settlementIncomeDocument.generateAndGetIncomeDocumentFile({ incomeDocumentId: x.id, documentLanguage: this.incomeDocument.documentLanguage, needGeneratingCollectiveStatement: false });
            }

            this.cleanIncomeDocumentAndItsChildren();
        })
    }

    cleanIncomeDocumentAndItsChildren() {
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocument({} as SettlementIncomeDocumentRestModel);
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentIssueDate("");
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentPaymentDate("");
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentSaleDate("");
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentItemList([] as SettlementIncomeDocumentItemRestModel[]);
        this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentItemCorrectionList([] as SettlementIncomeDocumentItemRestModel[]);
        this.$store.direct.commit.settlementCommon.setSupervisorList([] as SupervisorForSettlementRestModel[]);
    }

    async addOrShowIncomeDocumentCorrection(incomeDocument: SettlementIncomeDocumentListItemRestModel) {
        if (incomeDocument.hasCorrection) {
            await this.$router.push({ path: "/settlement/income-document/correction/edit/" + incomeDocument.documentCorrectionId });
        }
        else {
            await this.$store.direct.dispatch.settlementIncomeDocument.loadIncomeDocument({ incomeDocumentId: incomeDocument.id });
            await this.$router.push({ path: "/settlement/income-document/" + incomeDocument.id + "/correction/create" });

            this.$store.direct.state.settlementIncomeDocument.incomeDocument.documentNumber = undefined as any;
            this.$store.direct.state.settlementIncomeDocument.incomeDocument.issueDate = dayjs().format("YYYY-MM-DD");
            this.$store.direct.commit.settlementIncomeDocument.setIncomeDocumentIssueDate(dayjs().format("YYYY-MM-DD"));
        }
    }

    async cancelIncomeDocumentCorrection(incomeDocument: SettlementIncomeDocumentListItemRestModel) {
        this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: "Cancel correction",
                message: "Are you sure you want to cancel this correction?: " + incomeDocument.documentNumber,
                links: [],
                options: { buttonColor: "error", layoutColor: "red" },
                buttonType: "yes/no"
            })
            .then(async confirm => {
                if (confirm) {
                    await this.$store.direct.dispatch.settlementIncomeDocument.cancelIncomeDocumentCorrection({ incomeDocumentCorrectionId: incomeDocument.id });
                    this.loadIncomeDocumentList();
                }
            })
    }

    async createPaymentClick(incomeDocument: SettlementIncomeDocumentListItemRestModel) {
        await this.$store.direct.dispatch.settlementIncomeDocument.loadIncomeDocument({ incomeDocumentId: incomeDocument.id });

        const totalPaymentAmount = this.incomeDocument.paymentObligationList.length == 1 ? this.incomeDocument.paymentObligationList[0].paymentAmount : 0;
        const paymentCurrencyCode = this.incomeDocument.paymentObligationList.length == 1 ? this.incomeDocument.paymentObligationList[0].paymentCurrencyCode : undefined;

        await this.$store.direct.dispatch.settlementPayment.loadIncomePayment({ paymentGroupId: "" });

        this.incomePayment.paymentAmount = totalPaymentAmount;
        if (paymentCurrencyCode != undefined) {
            this.incomePayment.currencyCode = paymentCurrencyCode;
        }

        this.incomePayment.paymentIncomeDocumentRequestList = [{ incomeDocument: { ...this.incomeDocument } as any as SettlementIncomeDocumentRestModel, paymentAmount: 0 } as SettlementPaymentIncomeDocumentRestModel];

        this.$store.direct.dispatch.settlementPayment
            .openIncomePaymentDialog({
                title: `${this.$t("payment.addIncomePayment")}`,
            })
            .then(async confirm => {
                if (confirm) {
                    this.loadIncomeDocumentList();
                }
                this.$store.direct.dispatch.settlementIncomeDocument.cleanIncomeDocumentProperties();
            })
    }

    async deleteIncomeDocumentClick(incomeDocument: SettlementIncomeDocumentListItemRestModel) {
        this.$store.direct.dispatch.confirm
            .openConfirmDialog({
                showConfirmDialog: true,
                title: "Usun dokument przychodowy",
                message: "Czy na pewno chcesz usunac ten dokument przychodowy?: " + incomeDocument.documentNumber,
                links: [],
                options: { buttonColor: "error", layoutColor: "red" },
                buttonType: "yes/no"
            })
            .then(async confirm => {
                if (confirm) {
                    const isIncomeDocumentDeleted = await this.$store.direct.dispatch.settlementIncomeDocument.deleteIncomeDocument({ incomeDocumentId: incomeDocument.id, isDocumentCorrection: false });

                    if (!isIncomeDocumentDeleted) {
                        this.$store.direct.dispatch.confirm
                            .openConfirmDialog({
                                showConfirmDialog: true,
                                title: "Dokument przychodowy nie moze zostac usuniety",
                                message: "Dokument przychodowy nie moze zostac usuniety: " + incomeDocument.documentNumber,
                                links: [],
                                options: { buttonColor: "error", layoutColor: "error" },
                                buttonType: "ok"
                            });
                    }
                    else {
                        this.loadIncomeDocumentList();
                    }
                }
            });
    }

    async copyIncomeDocumentClick(incomeDocument: SettlementIncomeDocumentListItemRestModel) {
        await this.$router.push({ path: "/settlement/income-document/create" });

        const temporaryIncomeDocument = this.$store.direct.state.settlementIncomeDocument.incomeDocument;
        await this.$store.direct.dispatch.settlementIncomeDocument.loadIncomeDocument({ incomeDocumentId: incomeDocument.id });

        this.incomeDocument.id = temporaryIncomeDocument.id;
        this.incomeDocument.concurrencyStamp = temporaryIncomeDocument.concurrencyStamp;

        this.incomeDocument.hasCorrection = false;
        this.incomeDocument.documentNumber = undefined as any;
        this.incomeDocument.paymentStatusId = SettlementPaymentStatusEnum.Niezaplacona;

        this.documentRecalculatingType = this.incomeDocument.documentRecalculatingType;

        this.incomeDocument.issueDate = undefined as any;
        this.incomeDocumentIssueDate = undefined as any;

        this.incomeDocument.saleDate = undefined as any;
        this.incomeDocumentSaleDate = undefined as any;

        this.incomeDocument.paymentDate = undefined as any;
        this.incomeDocumentPaymentDate = undefined as any;

        this.incomeDocument.disableEditingDueToClosedMonth = false;

        this.incomeDocument.incomeDocumentItems.forEach((x: SettlementIncomeDocumentItemRestModel) => {
            x.id = 0;
        });

        this.incomeDocument.incomeDocumentSupervisors = [] as SupervisorForSettlementRestModel[];
        this.incomeDocumentSupervisorList = [] as SupervisorForSettlementRestModel[];
    }

    selectAllItemsOnThePage() {
        this.incomeDocumentList.forEach((x: SettlementIncomeDocumentListItemRestModel) => {
            x.isChecked = this.mainCheckboxIsSelected;
        });
        this.$forceUpdate();

        this.selectedIncomeDocumentList = this.incomeDocumentList.filter((x: SettlementIncomeDocumentListItemRestModel) => {
            if (x.isChecked) {
                return x.id;
            }
        });

        this.$store.direct.dispatch.settlementCommon.calculateSelectedCostOrIncomeDocumentListForAmountSummaryRow({ list: this.selectedIncomeDocumentList });
    }

    addSelectedIncomeDocumentToList() {
        this.selectedIncomeDocumentList = this.incomeDocumentList.filter((x: SettlementIncomeDocumentListItemRestModel) => {
            if (x.isChecked) {
                return x.id;
            }
            else {
                this.mainCheckboxIsSelected = false;
            }
        });

        this.$store.direct.dispatch.settlementCommon.calculateSelectedCostOrIncomeDocumentListForAmountSummaryRow({ list: this.selectedIncomeDocumentList });
    }

    hideFiltersMenuClick() {
        this.showFiltersMenu = false;
    }

    currentPageInput = this.incomeDocumentListPagerOptions.page;
    goToPage() {
        if (this.currentPageInput > 0) {
            this.incomeDocumentListPagerOptions.page = this.currentPageInput;
        }
    }

    destroyed() {
        this.selectedIncomeDocumentList = [] as SettlementIncomeDocumentListItemRestModel[];
    }
}
